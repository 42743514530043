import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";
import { Dialog, Icon, Intent } from "@blueprintjs/core";
import createApiClient from "api-client";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";
import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";
import * as config from "../../config";
import { getToken } from "../../app/topSlice.js";
import { dispatchToast } from "../toasts/Toasts.js";
import { Change } from "../login/icons.tsx";

const {
  actions: { consume, addCategory, rmCategory },
  selectors: { selectGraph, selectCategories },
} = appState;

const mapServerToLocal = {
  teams: "zones",
  files: "nodes",
  categories: "categories",
};

const mapVerbToSingular = {
  teams: "team",
  files: "file",
  categories: "category",
};

export default ({
  dispatch,
  active,
  close,
  id: targetNode,
  verb = "files",
}) => {
  const [name, setName] = useState();
  const [error, setError] = useState();
  const graph = useSelector(selectGraph);
  const [entity, setEntry] = useState({});
  const categories = useSelector(selectCategories);
  const [disableRenameButton, setDisableRenameButton] = useState(false);

  useEffect(() => {
    setError();
    let entity;
    if (verb !== "categories") {
      entity = graph[mapServerToLocal[verb]][targetNode] || {};
    } else {
      entity = categories.find(({ id }) => targetNode == id) || {};
    }
    setEntry(entity);
    setName((entity.name && `${entity.name}`.normalize()) || "");
    setDisableRenameButton(false);
  }, [targetNode, verb]);

  const renameCategory = async () => {
    if (200 < name.length) {
      dispatchToast(dispatch, {
        message: `Name too long.`,
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      return;
    }
    const singular = mapVerbToSingular[verb];
    const toast = await dispatchToast(dispatch, {
      message: `Renaming ${singular}.`,
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    setDisableRenameButton(true);
    try {
      const token = await dispatch(getToken());
      const api = createApiClient(config.API_URL_PREFIX);
      const result = await api.token(token)[verb](targetNode).patch({ name });
      const newEntry = { ...entity, name };
      if (verb === "categories") {
        await dispatch(rmCategory(targetNode));
        await dispatch(addCategory(newEntry));
      } else {
        const g = {
          graph: { [mapServerToLocal[verb]]: { [targetNode]: newEntry } },
        };
        //await dispatch(consume(g));
      }
      const that = singular[0].toUpperCase() + singular.slice(1);
      const message = `${that} renamed.`;
      await toast.replace({
        message,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
      close();
    } catch (e) {
      let message;
      if (e.toString().includes("already")) {
        message = `That name is already in use.`;
      } else if (e.toString().includes("adhear")) {
        message = "That name is not allowed, please try again.";
      } else {
        message = `Failed to rename ${singular}: ${e.toString()}`;
        Sentry.captureException(e);
      }
      setError(true);

      await toast.replace({
        message,
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <Change size={34} />
          <span className={styles.dialogTitle}>Rename</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <input
          autoFocus
          defaultValue={name}
          className={classNames(styles.dialogInput, error && styles.errorInput)}
          placeholder={"New Name"}
          onKeyDown={(e) =>
            name && name != entity?.name && e.key == "Enter" && renameCategory()
          }
          onChange={(e) => setName(e.target.value.normalize()) | setError()}
        />

        <div className={classNames(styles.errorMessage, error && styles.show)}>
          <span>{error}</span>
        </div>
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <button
          disabled={!name || name == entity?.name || disableRenameButton}
          className={styles.dialogButton}
          onClick={renameCategory}
        >
          Rename
        </button>
      </div>
    </Dialog>
  );
};
