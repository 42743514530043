import { useState } from "react";
import { Button, Classes, Icon, MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { classNames } from "utils";
import styles from "./Wizard.module.css";

export const Select = ({
  selectableUsers,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [value, setValue] = useState("");

  const isMemberSelected = (aUser) =>
    selectedUsers.some((bUser) => aUser.id == bUser.id);

  const handleUserDeselect = (aUser) =>
    setSelectedUsers(selectedUsers.filter((bUser) => aUser.id != bUser.id));

  const handleUserSelect = (user) => {
    setValue("");
    if (!isMemberSelected(user)) {
      user = {
        ...user,
        name: user.text[0][0],
      };
      setSelectedUsers([user, ...selectedUsers]);
    } else {
      handleUserDeselect(user);
    }
  };

  const onClear = () => {
    setSelectedUsers([]);
  };

  const clearButton = selectedUsers?.length > 0 && (
    <Button icon="cross" minimal={true} onClick={onClear} />
  );

  const getTagProps = () => ({
    minimal: true,
  });

  const items = selectableUsers?.filter((user) =>
    user.text[0].some((str) => str?.toLowerCase().includes(value.toLowerCase()))
  );

  const renderTag = (user) => user.text[0];

  const renderItem = (user) => {
    return (
      <MenuItem
        key={user.id}
        text={user.text[0]}
        icon={isMemberSelected(user) ? "tick" : "blank"}
        onClick={() => handleUserSelect(user)}
        shouldDismissPopover={false}
      />
    );
  };

  return (
    <div className={classNames(Classes.DIALOG_BODY)}>
      <div className={styles.alert}>
        <Icon icon="people" />
        <span>Only team members</span>
      </div>
      <MultiSelect
        items={items}
        itemRenderer={renderItem}
        onItemSelect={handleUserSelect}
        fill={true}
        popoverProps={{ minimal: true }}
        tagRenderer={renderTag}
        placeholder="Select team members"
        selectedItems={selectedUsers}
        tagInputProps={{
          rightElement: clearButton,
          tagProps: getTagProps,
        }}
        onRemove={handleUserDeselect}
        query={value}
        onQueryChange={setValue}
        noResults={<ul style={{ color: "grey" }}>No results.</ul>}
      />
    </div>
  );
};
