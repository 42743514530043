import Data from "../../data";
import PublicECDSAKey from "../public_ecdsa_key";

import { importWebCryptoPublicKey, exportWebCryptoPublicKey } from "./wc";
import { crypto } from "../../crypto";
import {
  assertInstanceOf,
  getClassName,
  assertObject,
  isEdge
} from "../../util";

export default class WebCryptoPublicECDSAKey extends PublicECDSAKey {
  constructor(key) {
    if (isEdge()) {
      assertObject(key, "key");
    } else if (getClassName(key) !== "CryptoKey") {
      throw new Error(
        `key must be a CryptoKey object. (got ${key.constructor.name})`
      );
    }

    super(key);
  }

  static async import(key, format) {
    return new WebCryptoPublicECDSAKey(
      await importWebCryptoPublicKey(
        key,
        format,
        {
          name: "ECDSA",
          namedCurve: "P-256"
        },
        ["verify"]
      )
    );
  }

  async export(format) {
    return exportWebCryptoPublicKey(this.key, format);
  }

  async verify(data, signature) {
    assertInstanceOf(Data, data, "verify-data");
    assertInstanceOf(Data, signature, "signature");

    return crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: { name: "SHA-256" }
      },
      this.key,
      signature.createArrayBuffer(),
      data.createArrayBuffer()
    );
  }
}
