import { Data } from "../";

import { isInstanceOf, assertInstanceOf } from "../util";

/*******************/

export default class RiksMessage {
  constructor(secret, immutable) {
    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], secret))
      secret = new Data(secret);
    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], immutable))
      immutable = new Data(immutable);

    assertInstanceOf(Data, secret, "secret-data");
    immutable && assertInstanceOf(Data, immutable, "immutable-data");

    this.secret = secret;
    this.immutable = immutable || new Data(0);
  }
}
