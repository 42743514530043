import WebCryptoPublicECDHKey from "./wc_public_ecdh_key";
import WebCryptoPrivateECDHKey from "./wc_private_ecdh_key";

import SecretKey, { TAG_LENGTH_IN_BITS } from "../secret_key";

import { Data } from "../../";

import { crypto } from "../../crypto";
import {
  getClassName,
  assertObject,
  assertInstanceOf,
  assertString,
  isEdge
} from "../../util";

export default class WebCryptoSecretKey extends SecretKey {
  constructor(key) {
    if (isEdge()) {
      assertObject(key, "key");
    } else if (getClassName(key) !== "CryptoKey") {
      throw new Error(
        `key must be a CryptoKey object. (got ${key.constructor.name})`
      );
    }

    super(key);
  }

  static async generate() {
    return new WebCryptoSecretKey(
      await crypto.subtle.generateKey(
        {
          name: "AES-GCM",
          length: 256
        },
        true,
        ["encrypt", "decrypt"]
      )
    );
  }

  static async import(key, format) {
    assertString(format, "format");
    if (format === "jwk") {
      assertObject(key, "key");
    } else {
      assertInstanceOf(Data, key, "key");
    }

    return new WebCryptoSecretKey(
      await crypto.subtle.importKey(
        format,
        key.getUint8Array(),
        {
          name: "AES-GCM"
        },
        true,
        ["encrypt", "decrypt"]
      )
    );
  }

  async export(format) {
    assertString(format, "format");

    return new Data(await crypto.subtle.exportKey(format, this.key));
  }

  static async agree(publicECDHKey, privateECDHKey) {
    assertInstanceOf(WebCryptoPublicECDHKey, publicECDHKey, "publicECDHKey");
    assertInstanceOf(WebCryptoPrivateECDHKey, privateECDHKey, "privateECDHKey");

    return new WebCryptoSecretKey(
      await crypto.subtle.deriveKey(
        {
          name: "ECDH",
          public: publicECDHKey.key
        },
        privateECDHKey.key,
        {
          name: "AES-GCM",
          length: 256
        },
        true,
        ["encrypt", "decrypt"]
      )
    );
  }

  async encrypt(data, iv, aad = new Data(0)) {
    assertInstanceOf(Data, data, "data");
    assertInstanceOf(Data, iv, "iv");
    assertInstanceOf(Data, aad, "aad");

    return new Data(
      await crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv: iv.getUint8Array(),
          additionalData: aad.getUint8Array(),
          tagLength: TAG_LENGTH_IN_BITS
        },
        this.key,
        data.getUint8Array()
      )
    );
  }

  async decrypt(data, iv, aad = new Data(0)) {
    assertInstanceOf(Data, data, "data");
    assertInstanceOf(Data, iv, "iv");
    assertInstanceOf(Data, aad, "aad");

    return new Data(
      await crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: iv.getUint8Array(),
          additionalData: aad.getUint8Array(),
          tagLength: TAG_LENGTH_IN_BITS
        },
        this.key,
        data.getUint8Array()
      )
    );
  }
}
