import { createSlice } from "@reduxjs/toolkit";
import { createSagaActions, hash, keys, values } from "utils";
import { Data } from "hyker-crypto";
import createChannel from "../../app/channel.js";
import { selectUserURI } from "../../app/topSlice.js";
import { waitFor } from "../../app/appSlice";

import { selectIdentity, getContext, getSocket } from "../../app/topSlice.js";

import { trustKit } from "../../app/trustKit.js";
import { appState } from "../../app/appState.js";

const { auxiliary: { getSymKey, cachePublicKeys } } = trustKit;
const { actions: { shareMessage } } = appState;

export const blueprint = {
  name: "chat",
  initialState: {
    messages: {},
    drafts: {}
  },
  reducers: {
    draftMessage: (state, { payload: { group, message } }) => {
      state.drafts[group] = message;
    },
    stageDraft: (state, { payload: message }) => {
      const { group, ...rest } = message;
      let id = 2 ** 50; // Almost max int but room for increments by thousand
      const prevIds = group in state.messages && keys(state.messages[group]);
      if (prevIds?.length) {
        id = Math.max(...prevIds.map(id => parseInt(id))) + 1000;
      }
      if (!(group in state.messages)) {
        state.messages[group] = {};
      }
      const draft = state.drafts[group];
      state.drafts[group] = "";
      state.messages[group][id] = {
        type: "DRAFT",
        message: draft,
        id,
        group,
        ...rest
      };
    },
    insertMessage: (state, { payload }) => {
      const { id, group, message, keyId, ...rest } = payload;
      if (!(group in state.messages)) {
        state.messages[group] = {};
      }
      state.messages[group][id] = {
        id,
        group,
        message,
        keyId,
        ...rest
      };
      const staged = values(state.messages[group]).find(
        x => x.type == "DRAFT" && x.keyId == keyId
      );
      if (staged) {
        delete state.messages[group][staged.id];
      }
    }
  },
  sagas: {
    getChannel: (() => {
      let theChannel, setChannel;
      return async ({}, dispatch, getState) => {
        if (!theChannel) {
          theChannel = new Promise(r => (setChannel = r));
          const { clientId } = getState();
          const { id: userId, org } = await dispatch(waitFor(selectIdentity));
          const ns = await hash(org);
          const buffer = [];
          const [socket, params] = dispatch(getSocket());
          const channel = createChannel("msg", ns, socket, params);
          channel.onMessage((item, topics, _meta) => {
            topics = topics.map(topic => topic.replace(/^msg\//, ""));
            dispatch(discoveredMessage({ ...item, _meta }));

            //const { rt, fin } = _meta;
            //if (rt) {
            //  dispatch(discoveredMessage({ ...item, _meta }));
            //} else {
            //  buffer.unshift({ ...item, _meta });
            //  if (fin) {
            //    for (const message of buffer) {
            //      for (const group of topics) {
            //        dispatch(discoveredMessage({ ...message, group }));
            //      }
            //    }
            //    buffer.splice(0, buffer.length);
            //  }
            //}
          });
          setChannel(channel);
        }
        return await theChannel;
      };
    })(),
    fetchHistory: (() => {
      const pages = {};
      return async ({ payload: group }, dispatch) => {
        const topic = "msg/" + group;
        const channel = await dispatch(getChannel());
        const page = pages[group] || 0;
        const options = { back: true, max: 5, page };
        const { result, more } = await channel.retrieve([topic], options);
        pages[group] = more;
        result.reverse();
        for (const { entry, ..._meta } of result) {
          dispatch(discoveredMessage({ ...entry, group, _meta }));
        }
        return !!more;
      };
    })()
    //fetchHistory: (() => {
    //  //const refs = {};
    //  return async ({ payload: topic }, dispatch) => {
    //    topic = "msg/" + topic;
    //    const channel = await dispatch(getChannel());

    //    console.log("FETCH HISTPRY!! (no implemented yet)");

    //    return {};
    //
    //    //if (topic in refs) {
    //    //  const ref = refs[topic] || 1;
    //    //  const { raf } = await channel.retrieve(topic, ref, 10, 1);
    //    //  refs[topic] = raf;
    //    //  return { raf };
    //    //} else {
    //    //  const { raf } = await channel.subscribe(topic, 1, 10);
    //    //  refs[topic] = raf;
    //    //  return { raf };
    //    //}
    //  };
    //})()
  }
};

export const chatSlice = createSlice(blueprint);

export const chatSagas = blueprint.sagas;

export const { draftMessage } = chatSlice.actions;

const { stageDraft, insertMessage } = chatSlice.actions;

export const { getChannel, fetchHistory } = createSagaActions(blueprint);

export const submitDraft = groupId => async (dispatch, getState) => {
  const { cryptoClient, credentials } = await dispatch(getContext());
  const { deviceId, privateECDSAKey } = credentials;
  const uri = selectUserURI(getState()).replace(/^[^;]*;/, "");
  const message = getState().chat.drafts[groupId] || "";

  const { keyId, symKey } = await cryptoClient.generateKey();

  dispatch(stageDraft({ group: groupId, keyId, from: uri, at: +new Date() }));

  const json = JSON.stringify(message);
  const data = Data.fromUTF8(json);

  const { secData } = await cryptoClient.encrypt(
    deviceId,
    privateECDSAKey,
    keyId,
    symKey,
    data
  );

  const content = secData.toBase64();

  const { ok } = await dispatch(shareMessage({ groupId, keyId, symKey }));

  if (ok) {
    const channel = await dispatch(getChannel());

    //const uri = "2fa:folke.hallin@gmail.com|+46709243205";

    channel.publish(
      ["msg/" + groupId],
      { type: "$chatMessage", group: groupId, content, keyId, from: uri }
      /*,{ ns }*/
    );
  }
};

export const discoveredMessage = message => async (dispatch, getState) => {
  const { cryptoClient, credentials } = await dispatch(getContext());
  const uri = selectUserURI(getState()).replace(/^[^;]*;/, "");

  // TODO seq should probably be id here
  const { group, content, from, keyId, _meta: { id, at } } = message;

  const type = from == uri ? "OUT" : "IN";

  message = { group, type, from, keyId, id, at };

  const staged = values(getState().chat.messages[group] || {}).find(
    x => x.type == "DRAFT" && x.keyId == keyId
  );

  if (!staged) {
    dispatch(insertMessage(message));
  }

  const secData = Data.fromBase64(content);

  const symKey = await dispatch(getSymKey(credentials.deviceId, keyId));

  const senderDeviceId = cryptoClient.getSenderDeviceId(secData);
  const [, publicECDSAKey] = await dispatch(cachePublicKeys(senderDeviceId));
  const { secret: data } = await cryptoClient.decrypt(
    publicECDSAKey,
    keyId,
    symKey,
    secData
  );

  const text = data.toUTF8();

  let json;

  if (text[0] == "{") {
    json = JSON.parse(text);
  } else {
    json = {
      text: text
        .replace(/^1/, "")
        .replace(/^2/, "↑ ")
        .replace(/^3/, "↓ ")
    };
  }

  dispatch(insertMessage({ ...message, message: json }));
};

export const loadNext = root => async dispatch => {
  const more = await dispatch(fetchHistory(root));
  if (more) {
    return true;
  }
  //if (page) {
  //  page.reverse();
  //  for (const item of page) {
  //    dispatch(discoveredMessage({ ...item }));
  //  }
  //  return true;
  //}
};

export const openGroup = groupId => async dispatch => {
  const channel = await dispatch(getChannel());
  channel.on("msg/" + groupId);
};

export const selectDraft = state => root => state.chat.drafts[root] || "";

export const selectMessages = state => root => state.chat.messages[root] || [];

export default chatSlice.reducer;
