import { PDFDocument, StandardFonts, rgb, degrees } from "pdf-lib";
import { createElectronicSignaturePDF } from "./createElectronicSignaturePDF.js";
import { createSignatureDetailsPDF } from "./createSignatureDetailsPDF.js";

export const appendPageToPdf = async (
  esignature,
  fileName,
  documentBlob,
  attachmentBlob
) => {
  const xmlTxt = new TextDecoder().decode(attachmentBlob);
  const startTag = '<?xml version="1.0" encoding="UTF-8"?><ltv:LtvSdo';
  const endTag = "</ltv:LtvSdo>";
  const xmlFiles = xmlTxt.split(endTag);

  const certificates = xmlFiles
    .map((xmlFile) => {
      if (xmlFile.trim().startsWith(startTag)) {
        const xmlContent = xmlFile + endTag;
        const xmlDoc = new DOMParser().parseFromString(xmlContent, "text/xml");
        const x509IssuerName = xmlDoc.querySelector("X509IssuerName");
        const x509IssuerNameValue = x509IssuerName.textContent;
        return { issuer: x509IssuerNameValue };
      }
    })
    .filter((certificate) => certificate !== undefined);

  // STEP CONVERT TO BUFFER
  const fileBuffer = await new Response(documentBlob).arrayBuffer();

  //EXTRACT CHECKSUM
  const { id, checksum } = esignature;

  // CREATE NEW PDF
  const pdfDoc = await PDFDocument.load(fileBuffer);

  // ADD STAMP TO PAGES
  const pages = pdfDoc.getPages();

  const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const ARG_DATE = [
    "en-SE",
    {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    },
  ];

  pages.map((pageDoc) => {
    const { height } = pageDoc.getSize();

    const stamp = `ID: ${
      esignature.id
    } This document is signed. Verified by HYKER ${new Date().toLocaleDateString(
      ...ARG_DATE
    )}`;

    const textSize = helvetica.widthOfTextAtSize(stamp, 8);

    pageDoc.drawText(stamp, {
      x: 15,
      y: height / 2 - textSize / 2,
      size: 8,
      font: helvetica,
      color: rgb(0.6, 0.6, 0.6),
      rotate: degrees(90),
    });
  });

  pdfDoc.attach(fileBuffer, "Original.pdf", {
    mimeType: "application/pdf",
  });

  const page = pdfDoc.addPage();

  // CREATE NEW ATTACHEMENT PDF
  const pdfAttachment = await PDFDocument.create();

  //pdfAttachment.setAuthor("konfident");
  pdfAttachment.setKeywords(["konfident " + id]);

  const attachment = pdfAttachment.addPage();

  // ATTACH XML FILE/S
  for (let i = 0; i < xmlFiles.length - 1; i++) {
    const xmlFile = xmlFiles[i];
    if (xmlFile.trim().startsWith(startTag)) {
      const xmlContent = xmlFile + endTag;
      const xmlData = new TextEncoder().encode(xmlContent);
      const fileName = `E-Signature-${i + 1}.xml`;
      await pdfDoc.attach(xmlData, fileName, {
        mimeType: "text/xml",
      });
    }
  }

  // EMBED FONTS ELECTRONIC SIGNATURE
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold, {
    subset: true,
  });

  const helveticaBoldOblique = await pdfDoc.embedFont(
    StandardFonts.HelveticaBoldOblique,
    {
      subset: true,
    }
  );

  // EMBED FONTS SIGNATURE DETAILS
  const helveticaFontDetails = await pdfAttachment.embedFont(
    StandardFonts.Helvetica
  );

  const helveticaBoldDetails = await pdfAttachment.embedFont(
    StandardFonts.HelveticaBold,
    {
      subset: true,
    }
  );
  const helveticaObliqueDetails = await pdfAttachment.embedFont(
    StandardFonts.HelveticaOblique,
    {
      subset: true,
    }
  );

  // SET FONT
  page.setFont(helveticaFont);
  attachment.setFont(helveticaFontDetails);

  // NUMBER OF PAGES
  //const totalPages = pdfDoc.getPageCount();

  // CALCULATE POSITION ON PAGE
  const calculate = (index, position, margin) => {
    return index === 0 ? position : position - margin * index;
  };

  // CALCULATE NUMBER OF PAGES
  const calculateNumberOfPages = (signatures, signaturePerPage) => {
    let pages = Math.floor(signatures.length / signaturePerPage);
    let remainder = signatures.length % signaturePerPage;
    if (remainder > 0) {
      pages += 1;
    }
    return pages;
  };

  // INCREMENT COUNTER FOR PAGES
  const incrementCounter = (counter, signaturesPerPage, array) => {
    return array.length >= counter + signaturesPerPage
      ? counter + signaturesPerPage
      : array.length;
  };

  //GET SIZE FROM BLOB
  const { size } = documentBlob;

  // ELECTRONIC SIGNATURE PDF
  createElectronicSignaturePDF(
    page,
    helveticaBold,
    helveticaBoldOblique,
    calculate,
    checksum,
    calculateNumberOfPages,
    pdfDoc,
    incrementCounter,
    esignature,
    fileName
  );

  // SIGNATURE DETAILS PDF
  createSignatureDetailsPDF(
    attachment,
    helveticaBoldDetails,
    helveticaObliqueDetails,
    calculate,
    certificates,
    calculateNumberOfPages,
    pdfAttachment,
    incrementCounter,
    esignature,
    fileName,
    size
  );

  // SAVE SIGNATURE DETAILS AND ATTACH TO DOCUMENT
  const attachMe = await pdfAttachment.save();
  pdfDoc.attach(attachMe, "Signature-Details.pdf", {
    mimeType: "application/pdf",
  });

  // CONVERT PDF DOCUMENT BACK TO BLOB AND SAVE
  const pdfBytes = await pdfDoc.save();
  let byteArray = new Uint8Array(pdfBytes);

  // PDF TO BLOB
  return new Blob([byteArray], { type: "application/pdf;base64" });
  //let file = new Blob([byteArray], { type: "application/pdf;base64" });

  //// UPDATE FILE WITH SIGNATURE
  //file.id = node.id;
  //file.name = node.name;
  //file.storageId = node.storageId;
  //file.lastModifiedDate = new Date();

  //return file;
};

export const getUserInfoByID = (id, signatures) => {
  for (const user of signatures.signicatUrl) {
    if (id === user.externalSignerId) {
      const email = user.signerInfo.email || "Not specified";
      const companyName =
        user.signerInfo.organizationInfo.companyName || "Not specified";
      const orgNo = user.signerInfo.organizationInfo.orgNo || "Not specified";
      return { email, companyName, orgNo };
    }
  }
};
