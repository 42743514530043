import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";
import { Icon, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import { createUri } from "app-state";
import KeyperProvider from "../../helpers/wrappers/KeyVaultProvider";
import * as config from "../../config";
import { getToken } from "../../app/topSlice.js";
import { PANE_USER, hidePane } from "../layout/layoutSlice";
import { appState } from "../../app/appState.js";
import createApiClient from "api-client";
import { selectIdentity } from "../../app/topSlice.js";
import { sendInviteToUser } from "../../app/realworld";
import RolePicker from "../roles/RolePicker";
import { dispatchToast } from "../toasts/Toasts.js";
import { formatUser } from "../format/format.js";
import { useRoles } from "../../app/rolesContext.js";
import { approve, setDialog } from "../../app/appSlice.js";
import store from "../../app/store.js";
import {
  WORKSPACE_TEAMMATE_REMOVE,
  WORKSPACE_ROLE_ASSIGN,
  WORKSPACE_TEAMMATE_INVITE,
} from "../../app/permissions.js";
import { validateEmail } from "../invite/email.tsx";
import styles from "./User.module.css";
import { UPGRADE_2FA } from "features/dialogs/Dialogs.js";

const {
  actions: { consume },
  selectors: { selectPermissions, selectUsers },
} = appState;

const { getState } = store;

export function User({ pane: { user } }) {
  const dispatch = useDispatch();
  const { org } = useSelector(selectIdentity);
  const [role, _setRole] = useState(user?.role?.id);
  const permissions = useSelector(selectPermissions);
  const roles = useRoles();

  const canDeleteUser = permissions[WORKSPACE_TEAMMATE_REMOVE];
  const canSetRole = permissions[WORKSPACE_ROLE_ASSIGN];
  const canInvite = permissions[WORKSPACE_TEAMMATE_INVITE];
  const canReInvite = canInvite && validateEmail(user?.email);

  useEffect(() => {
    _setRole(user?.role?.id);
  }, [user]);

  const setRole = async (newRole) => {
    const { approved } = await dispatch(approve());
    if (!approved) {
      return;
    }
    const toast = await dispatchToast(dispatch, {
      message: "Changing role...",
      icon: "people",
      intent: Intent.PRIMARY,
      timeout: 0,
    });

    try {
      const token = await dispatch(getToken());
      const api = createApiClient(config.API_URL_PREFIX);

      const changedUser = await api
        .token(token)
        .users(user.id)
        .patch({ role: { id: newRole } });

      _setRole(changedUser.role.id);

      await toast.replace({
        message: "Role changed!",
        icon: "people",
        intent: Intent.SUCCESS,
      });

      const users = Object.values(selectUsers(getState()))
        .filter((u) => u.user === user.id)
        .map((user) => ({ ...user, role: newRole }))
        .map((user) => [user.member, user]);

      dispatch(consume({ graph: { users: Object.fromEntries(users) } }));
    } catch (e) {
      Sentry.captureException(e);
      await toast.replace({
        message: "Failed to change role.",
        icon: "people",
        intent: Intent.DANGER,
      });
    }
  };

  const deleteUser = async () => {
    const { approved } = await dispatch(approve());
    if (!approved) {
      return;
    }
    const token = await dispatch(getToken());
    const api = createApiClient(config.API_URL_PREFIX);

    const toast = await dispatchToast(dispatch, {
      message: "Deleting user...",
      icon: "people",
      intent: Intent.PRIMARY,
      timeout: 0,
    });

    try {
      await api.token(token).users(user.id).delete();

      await toast.replace({
        message: "User deleted!",
        icon: "people",
        intent: Intent.SUCCESS,
      });
    } catch (e) {
      Sentry.captureException(e);
      await toast.replace({
        message: "Failed to delete user.",
        icon: "people",
        intent: Intent.DANGER,
      });
    }
    dispatch(hidePane(PANE_USER));
  };

  const resendInvite = async () => {
    const { approved } = await dispatch(approve());
    if (!approved) {
      return;
    }
    const toast = await dispatchToast(dispatch, {
      message: "Resending invite...",
      icon: "people",
      intent: Intent.PRIMARY,
      timeout: 0,
    });

    try {
      const token = await dispatch(getToken());
      await sendInviteToUser(token, user.id);
      await toast.replace({
        message: "Invite sent!",
        icon: "people",
        intent: Intent.SUCCESS,
      });
    } catch (e) {
      Sentry.captureException(e);
      await toast.replace({
        message: "Failed to reinvite user.",
        icon: "people",
        intent: Intent.DANGER,
      });
    }
  };

  if (!user) {
    return null;
  }

  let _role = {
    name: "",
    background: "#fff",
    color: "#fff",
    allowedToSet: false,
  };

  if (roles) {
    _role = roles.find((role) => role.id === user.role.id);
  }

  const uri = createUri(user);
  console.log(user);

  const [[primary, secondary = ""], detailed, identity, provider] = dispatch(
    formatUser({ ...user, deprecatedId: uri })
  );

  let name = (provider !== "bankid" && user.email?.split("@")[0]) || user.name;

  const names = name.match(/[\p{N}\p{L}\p{M}]+/gu).map((name) => name[0]);

  const initials = (names.length < 2 ? name : names).slice(0, 2);

  const providerColor =
    provider === "bankid" || provider === "2fa"
      ? {
          background: "#69f0ae",
          color: "#1b5e20",
        }
      : {
          background: "#E0F7FA",
          color: "#0097A7",
        };

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <div className={styles.header}>
          <span>User</span>
        </div>
        <div
          className={styles.header}
          onClick={() => dispatch(hidePane(PANE_USER))}
        >
          <Icon icon="cross" />
        </div>
      </div>
      <div className={styles.rest}>
        <div className={styles.card}>
          <div className={styles.circle}>
            <span className={styles.initials}>{initials}</span>
          </div>
          <br />
          <div className={styles.name}>
            <div className={styles.recipient} title={detailed}>
              <span>{primary}</span>
              {secondary && <span>{secondary}</span>}
            </div>
          </div>
          <div className={styles.params}>
            <div
              className={styles.pill}
              style={{
                backgroundColor: _role.color.background,
                color: _role.color.foreground,
              }}
            >
              <span>role</span>
              <span>{_role.name}</span>
            </div>
            <div className={styles.pill} style={providerColor}>
              <span>id</span>
              <span>
                <KeyperProvider>{provider}</KeyperProvider>
              </span>
            </div>
            <div className={styles.pill}>
              <span>org</span>
              <span>{org}</span>
            </div>
          </div>
        </div>
        <br />
        <br />
        {canSetRole && _role.allowedToSet && (
          <RolePicker onSelect={setRole} currentRole={role} />
        )}
        {canReInvite && (
          <div className={styles.button} onClick={() => resendInvite()}>
            <Icon icon="envelope" />
            <span>
              Send Invite Again{provider === "bankid" ? " by Email" : ""}
            </span>
          </div>
        )}
        {canDeleteUser && (
          <div
            className={classNames(styles.button, styles.delete)}
            onClick={deleteUser}
          >
            <Icon icon="warning-sign" />
            <span>Remove User From Workspace</span>
          </div>
        )}
      </div>
    </div>
  );
}
