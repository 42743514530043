import { request, jwtPeek, authReq, json } from "utils";
import { Data } from "hyker-crypto";
import { API_URL_PREFIX_NOTIFY } from "../config";
import { pushSubscribe } from "../service-worker-client";

export const hasWebPushSupport = async () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // all browsers that support service workers also support web push (except ios) (but safari mac dont have PushManager in window)
  return "serviceWorker" in navigator && !isIOS && !isSafari;
};

export const setupWebPush = async accessToken => {
  // Check permissions
  if (Notification.permission !== "granted") {
    await Notification.requestPermission();

    if (Notification.permission !== "granted") {
      return;
    }
  }

  // Fetch public web push key
  const { publicKey } = await request(`${API_URL_PREFIX_NOTIFY}/ppk`);

  // Subscribe to browser push service
  const subscription = await pushSubscribe({
    userVisibleOnly: true,
    applicationServerKey: Data.fromBase64URL(publicKey).getUint8Array()
  });

  // Subscribe to internal push service
  const { user } = jwtPeek(accessToken);
  await request(
    `${API_URL_PREFIX_NOTIFY}/subscribe`,
    json(
      {
        userId: user,
        endpoint: subscription.endpoint,
        pushSubscription: subscription
      },
      {
        accessToken,
        userId: user
      }
    )
  );

  return subscription;
};

export const removeWebPushSubscription = async endpoint => {
  // const userId = yield select(makeSelectUserId());
  //
  // export const unregisterServiceWorker = async () => {
  //   if ("serviceWorker" in navigator) {
  //     const registrations = await navigator.serviceWorker.getRegistrations();
  //     for (const registration of registrations) {
  //       registration.unregister();
  //     }
  //   }
  // };
  // try {
  //   yield call(
  //     authorizedRequest,
  //     `${API_URL_PREFIX_NOTIFY}/unsubscribe`,
  //     json({
  //       userId,
  //       endpoint
  //     })
  //   );
  // } catch (error) {
  //   yield put(errorAction(error));
  // }
};
