import RiksKey from "./key";
import RiksKit from "./rikskit";
import RiksMessage from "./message";

import {
  IMMUTABLE,
  KEY,
  KEY_ID,
  MESSAGE_NONCE_LENGTH_IN_BYTES,
  NONCE,
  RIKS_KEY_LENGTH_IN_BYTES,
  RIKS_KEY_ID_LENGTH_IN_BYTES,
  SECRET,
  SENDER,
  SIGNATURE,
  SIGNATURE_LENGTH_IN_BYTES,
  TYPE_DENY,
  TYPE_KEY,
  TYPE_MESSAGE,
  TYPE_REQUEST,
  TYPE,
  VERSION,
  VERSION_VALUE
} from "./constants";

/*******************/

export default RiksKit;

export {
  // Classes
  RiksMessage,
  RiksKey,
  RiksKit,
  // Constants
  IMMUTABLE,
  KEY,
  KEY_ID,
  MESSAGE_NONCE_LENGTH_IN_BYTES,
  NONCE,
  RIKS_KEY_LENGTH_IN_BYTES,
  RIKS_KEY_ID_LENGTH_IN_BYTES,
  SECRET,
  SENDER,
  SIGNATURE,
  SIGNATURE_LENGTH_IN_BYTES,
  TYPE_DENY,
  TYPE_KEY,
  TYPE_MESSAGE,
  TYPE_REQUEST,
  TYPE,
  VERSION,
  VERSION_VALUE
};
