import SJCLPrivateECDHKey from "./sjcl/sjcl_private_ecdh_key";
import SJCLPublicECDHKey from "./sjcl/sjcl_public_ecdh_key";
import SJCLPrivateECDSAKey from "./sjcl/sjcl_private_ecdsa_key";
import SJCLPublicECDSAKey from "./sjcl/sjcl_public_ecdsa_key";
import SJCLSecretKey from "./sjcl/sjcl_secret_key";
import SJCLKeyPair from "./sjcl/sjcl_key_pair";

import WebCryptoPrivateECDHKey from "./wc/wc_private_ecdh_key";
import WebCryptoPublicECDHKey from "./wc/wc_public_ecdh_key";
import WebCryptoPrivateECDSAKey from "./wc/wc_private_ecdsa_key";
import WebCryptoPublicECDSAKey from "./wc/wc_public_ecdsa_key";
import WebCryptoSecretKey from "./wc/wc_secret_key";
import WebCryptoKeyPair from "./wc/wc_key_pair";

import PrivateECDHKey from "./private_ecdh_key";
import PublicECDHKey from "./public_ecdh_key";
import PrivateECDSAKey from "./private_ecdsa_key";
import PublicECDSAKey from "./public_ecdsa_key";
import SecretKey from "./secret_key";
import KeyPair from "./key_pair";

import Certificate from "./certificate";

import { Data } from "../";
import { isEdge, assertInstanceOf } from "../util";

import * as pkijs from "pkijs";

import { sha1, hmac } from "./sha1";

import { validateCertificateChain } from "./certificate_validation";

import { berEncodeSignature, berDecodeSignature } from "./signature";

/*******************/

export {
  SJCLPrivateECDHKey,
  SJCLPublicECDHKey,
  SJCLPrivateECDSAKey,
  SJCLPublicECDSAKey,
  SJCLSecretKey,
  SJCLKeyPair,
  WebCryptoPrivateECDHKey,
  WebCryptoPublicECDHKey,
  WebCryptoPrivateECDSAKey,
  WebCryptoPublicECDSAKey,
  WebCryptoSecretKey,
  WebCryptoKeyPair,
  PrivateECDHKey,
  PublicECDHKey,
  PrivateECDSAKey,
  PublicECDSAKey,
  SecretKey,
  KeyPair,
  Certificate,
  validateCertificateChain,
  hmac,
  sha1,
  berEncodeSignature,
  berDecodeSignature,
};

export const IV_LENGTH_IN_BYTES = 32;

let _crypto = null;

if (typeof ServiceWorkerGlobalScope !== "undefined") {
  _crypto = self.crypto;
} else if (typeof window !== "undefined") {
  _crypto = window.msCrypto || window.crypto;
  if (!_crypto.subtle) {
    throw new Error("WebCrypto does not work in this browser.");
  }
} else if (typeof global !== "undefined") {
  let _engine;
  if (
    global[process.pid] &&
    global[process.pid].hyker &&
    global[process.pid].hyker.engine &&
    global[process.pid].hyker.engine.name != "none"
  ) {
    _engine = global[process.pid].hyker.engine;
  } else {
    const { Crypto: WebCrypto } = require("node-webcrypto-ossl");
    const name = "newEngine";
    const crypto = new WebCrypto();
    const subtle = new pkijs.CryptoEngine({
      name: "",
      crypto,
      subtle: crypto.subtle,
    });
    _engine = { name, crypto, subtle };
    if (!global[process.pid]) {
      global[process.pid] = { hyker: {} };
    } else if (!global[process.pid].hyker) {
      global[process.pid].hyker = {};
    }
    global[process.pid].hyker.engine = _engine;
  }
  pkijs.setEngine(_engine.name, _engine.crypto, _engine.subtle);
  _crypto = _engine.crypto;
} else {
  throw new Error("Unknown environment.");
}

export const crypto = _crypto;

export async function importPrivateECDHKey(key, format) {
  if (isEdge()) {
    return SJCLPrivateECDHKey.import(key, format);
  } else {
    return WebCryptoPrivateECDHKey.import(key, format);
  }
}

export async function importPublicECDHKey(key, format) {
  if (isEdge()) {
    return SJCLPublicECDHKey.import(key, format);
  } else {
    return WebCryptoPublicECDHKey.import(key, format);
  }
}

export async function importPrivateECDSAKey(key, format) {
  return WebCryptoPrivateECDSAKey.import(key, format);
}

export async function importPublicECDSAKey(key, format) {
  return WebCryptoPublicECDSAKey.import(key, format);
}

export async function importSecretKey(key, format) {
  if (isEdge()) {
    return SJCLSecretKey.import(key, format);
  } else {
    return WebCryptoSecretKey.import(key, format);
  }
}

export async function generateSecretKey() {
  if (isEdge()) {
    return SJCLSecretKey.generate();
  } else {
    return WebCryptoSecretKey.generate();
  }
}

export async function generateECDSAKeyPair() {
  return WebCryptoKeyPair.generateECDSAKeyPair();
}

export async function generateECDHKeyPair() {
  if (isEdge()) {
    return SJCLKeyPair.generateECDHKeyPair();
  } else {
    return WebCryptoKeyPair.generateECDHKeyPair();
  }
}

export async function agree(publicECDHKey, privateECDHKey) {
  if (isEdge()) {
    return SJCLSecretKey.agree(publicECDHKey, privateECDHKey);
  } else {
    return WebCryptoSecretKey.agree(publicECDHKey, privateECDHKey);
  }
}

export async function encrypt(data, key, aad) {
  assertInstanceOf(Data, data, "encryption-data");
  aad && assertInstanceOf(Data, aad, "aad");

  const iv = random(IV_LENGTH_IN_BYTES);
  const ciphertext = await key.encrypt(data, iv, aad);

  return Data.join([iv, ciphertext]);
}

export async function decrypt(data, key, aad) {
  assertInstanceOf(Data, data, "encryption-data");
  aad && assertInstanceOf(Data, aad, "aad");

  if (data.length <= IV_LENGTH_IN_BYTES)
    throw new Error("Encrypted data too short.");

  // Split into IV and ciphertext
  const iv = data.slice(0, IV_LENGTH_IN_BYTES);
  const ciphertext = data.slice(IV_LENGTH_IN_BYTES);

  return key.decrypt(ciphertext, iv, aad);
}

export function getDecryptedLength(encryptedLength) {
  return SecretKey.getDecryptedLength(encryptedLength - IV_LENGTH_IN_BYTES);
}

export function getEncryptedLength(dataLength) {
  return IV_LENGTH_IN_BYTES + SecretKey.getEncryptedLength(dataLength);
}

export function random(length) {
  let data = new Data(length);
  crypto.getRandomValues(data.getUint8Array());
  return data;
}

export async function sha256(data) {
  assertInstanceOf(Data, data, "sha256-data");

  return new Data(
    await crypto.subtle.digest({ name: "SHA-256" }, data.getUint8Array())
  );
}
