import {
  values,
  entries,
  assign,
  mapObj,
  createSagaActions,
  createMetaHooks,
  createControlledSagas,
  createSlice as _createSlice
} from "utils";

import abstracts from "./abstracts.js";
import reducers, { initialState } from "./state.js";
import createSelectors from "./selectors.js";
import createProducers from "./producers.js";
import createConsumers from "./consumers.js";
import createAuxiliary from "./auxiliary.js";

export { VERSION, align } from "./auxiliary.js";

export default args => {
  const {
    name,
    config,
    sagas: externals = {},
    actions: externalActions = {},
    createSlice = null
  } = args;

  const { waitFor, terms, take, takes } = createMetaHooks().metaActions;

  const module = {
    config,
    options: { name },
    meta: { waitFor, terms, take, takes }
  };

  const selectors = createSelectors(module);
  const producers = createProducers(module);
  const consumers = createConsumers(module);
  const auxiliary = createAuxiliary(module);

  const sagas = {
    [name]: {
      ...abstracts,
      ...externals
    }
  };

  const slices = {
    ["!" + name]: createSlice({
      name: "!" + name,
      initialState: {},
      sagas: {
        ...consumers
      }
    }),
    [name]: createSlice({
      name,
      initialState: {
        ...initialState
      },
      reducers: {
        ...reducers
      },
      sagas: {
        ...sagas[name]
      }
    })
  };

  const slicedReducers = mapObj(slices, slice => slice.reducer);
  //const slicedActions = values(slices).map(slice => slice.actions);

  const slicedActions = values(slices).reduce(
    (actions, slice) => ({ ...actions, ...slice.actions }),
    {}
  );

  const sagaActions = entries(sagas)
    .map(([name, sagas]) => _createSlice({ name, sagas }))
    .reduce((actions, slice) => ({ ...actions, ...slice.actions }), {});

  const consumerActions = createSagaActions({
    name: "!" + name,
    sagas: consumers
  });

  assign(module, {
    selectors,
    //reducers: {
    //  ...slicedActions
    //},
    actions: {
      ...slicedActions,
      ...sagaActions,
      ...externalActions
    },
    producers,
    consumers: consumerActions,
    auxiliary
  });

  const { acceptState } = slicedActions;
  const { registerListener, getPreState, getGroupLog, getGoodybag } = auxiliary;

  return {
    sagas: {
      ...sagas,
      ["!" + name]: {
        ...consumers
      }
    },
    reducers: slicedReducers,
    actions: {
      ...producers,
      acceptState,
      registerListener,
      getPreState,
      getGroupLog,
      getGoodybag
    },
    auxiliary,
    selectors
  };
};
