import { entries } from "utils";

export default ({ options: { name } }) => ({
  selectScope: state => state[name],
  selectAuthorities: state => (def = []) => state.aut || def,
  selectGroup: state => (groupId, def = {}) => {
    const { seq, hsh, mem, res } = state.grp[groupId] || {};
    return { sequence: seq, integrity: hsh, members: mem, resources: res };
  },
  selectHash: state => (groupId, def = "") =>
    //state.grp[groupId]?.hsh || def;
    (state.grp[groupId] && state.grp[groupId].hsh) || def,
  selectSeq: state => (groupId, def = 0) =>
    //state.grp[groupId]?.hsh || def;
    (state.grp[groupId] && state.grp[groupId].seq) || def,
  selectKey: state => (keyId, def = "") => state.key[keyId] || def,
  selectPublicKeys: state => (originId, def = null) =>
    state.pub[originId] || def,
  selectMembers: state => (groupId, def = []) =>
    //state.grp[groupId]?.mem || def;
    (state.grp[groupId] && state.grp[groupId].mem) || def,
  selectResources: state => (groupId, def = []) =>
    //state.grp[groupId]?.res || def;
    (state.grp[groupId] && state.grp[groupId].res) || def,
  selectRelation: state => (uri, def = []) => state.rel[uri] || def,
  selectReverse: state => (dev, def = "") =>
    (entries(state.rel).find(([, devs]) => devs.includes(dev)) || [])[0],
  selectKeys: state => (groupId, def = {}, nil = "") => {
    try {
      const keyIds = state.grp[groupId].res.map(([keyId]) => keyId);
      const keys = {};
      for (const keyId of keyIds) {
        keys[keyId] = state.key[keyId] || nil;
      }
      return keys;
    } catch (ignore) {
      return def;
    }
  }
});
