import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Position, Toaster, Toast, Spinner, Intent } from "@blueprintjs/core";
import { isString } from "utils";
import Verifying from "../verifying/index.tsx";
import {
  selectToasts,
  createToast,
  changeToast,
  dismissToast,
} from "../../app/appSlice.js";

export default () => {
  const dispatch = useDispatch();
  const toasts = useSelector(selectToasts);
  return (
    <Toaster position={Position.BOTTOM_RIGHT}>
      {toasts.map(({ id, message, intent, icon, timeout, className }, i) => {
        if (icon == "CHECK") {
          icon = (
            <Verifying
              size={20}
              auto={1000}
              color={"white"}
              small={true}
              fast={true}
            />
          );
          if (!className) {
            className = "k-toast-check";
          }
        } else if (icon == "SPINNER") {
          icon = <Spinner size={Spinner.SIZE_SMALL} />;
          if (!className) {
            className = "k-toast-spinner";
          }
        }
        return (
          <Toast
            key={id}
            message={message}
            intent={intent}
            icon={icon}
            timeout={timeout}
            className={className}
            onDismiss={() => dispatch(dismissToast(id))}
          />
        );
      })}
    </Toaster>
  );
};

const _createToast = (options) => (dispatch) => {
  if (isString(options)) {
    options = { message: options };
  }
  options = {
    intent: Intent.PRIMARY,
    message: "Working...",
    icon: "SPINNER",
    ...options,
  };
  const { id, timeout } = dispatch(createToast(options));
  return {
    change: (newOptions) => {
      options = {
        ...options,
        ...newOptions,
        //timeout: timeout + isNaN(newOptions.timeout) ? 0 : newOptions.timeout
      };
      dispatch(
        changeToast({
          id,
          ...options,
        })
      );
    },
    replace: (newOptions) => {
      const oldOptions = Object.keys(options).reduce(
        (acc, key) => ({ ...acc, [key]: null }),
        {}
      );
      //options = newOptions;
      //if (!isNaN(newOptions.timeout)) {
      //  options.timeout += newOptions.timeout;
      //}
      dispatch(
        changeToast({
          id,
          ...oldOptions,
          ...newOptions,
        })
      );
    },
    dismiss: (message) => {
      dispatch(dismissToast(id));
    },
  };
};

export { _createToast as createToast };

export const dispatchToast = (dispatch, options) => {
  return dispatch(_createToast(options));
};
