import React, { useState, useCallback } from "react";
import * as Sentry from "@sentry/browser";
import {
  AnchorButton,
  FileInput,
  Position,
  Popover,
  PopoverInteractionKind
} from "@blueprintjs/core";
import getCroppedImg from "./cropImage";
import Cropper from "react-easy-crop";
import styles from "./Crop.module.css";
import { changeLogo } from "./adminSlice";
import { useDispatch } from "react-redux";

const LogoResize = () => {
  const [error, setError] = useState(false);
  const [upImg, setUpImg] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [done, setDone] = useState();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const aspect = 4 / 1;

  const [croppedImage, setCroppedImage] = useState(null);
  const targetSize = { width: 200, height: 50 };
  const dispatch = useDispatch();

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file && file.type.match("image.*")) {
        reader.readAsDataURL(file);
        setError(false);
      } else {
        setError(true);
        setUpImg(null);
        console.log("not an image");
      }
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const upload = async event => {
    if (event.target.files.length > 0) {
      event.preventDefault();
      setUpImg(await toBase64(event.target.files[0]));
    } else {
      setError(false);
    }
  };

  const saveCroppedImage = async upImg => {
    try {
      const croppedImage = upImg
        ? await getCroppedImg(upImg, done, targetSize)
        : null;
      const action = changeLogo({ image: croppedImage });
      await dispatch(action);
    } catch (e) {
      Sentry.captureException(e);
      console.log("Bad input", e);
      setError(true);
      return false;
    }
  };

  const clearCroppedImage = async () => {
    try {
      setCroppedImage(null);
      setUpImg(null);
      await saveCroppedImage(null);
    } catch (e) {
      Sentry.captureException(e);
      console.log("error: " + e);
    }
  };

  return (
    <div>
      <FileInput type="file" onChange={upload} />
      <div className={styles.cropContainer}>
        <Cropper
          image={upImg}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={crop => setCrop(crop)}
          onZoomChange={zoom => setZoom(zoom)}
          onCropComplete={done => setDone(done)}
          onMediaLoaded={() => setDone(false)}
        />
      </div>
      <AnchorButton
        onClick={e => saveCroppedImage(upImg)}
        text="Save result"
        disabled={!done}
      />
      <AnchorButton
        onClick={async _ => await clearCroppedImage()}
        text="Delete logo"
      />
      {error && (
        <div>
          You have uploaded an invalid image file type. Valid file types: png,
          jpeg, jpg, gif.
        </div>
      )}
    </div>
  );
};

export default LogoResize;
