import { Data } from "../";

import { isInstanceOf, assertInstanceOf } from "../util";

import * as asn1js from "asn1js";

function toSignedData(data) {
  if (data.get(0) > 127) {
    let signedData = new Data(data.length + 1);
    signedData.set([0x00], 0);
    signedData.set(data, 1);
    return signedData;
  }
  if (data.get(0) === 0x00) {
    return toSignedData(data.slice(1));
  }
  return data;
}

function toUnsignedData(data) {
  if (data.length > 33) {
    throw new Error("Bad signature.");
  } else if (data.length > 32) {
    if (data.get(0) === 0 && data.get(1) > 127) {
      return data.slice(1);
    } else {
      throw new Error("Bad signature.");
    }
  } else {
    return Data.join([new Data(32 - data.length), data]);
  }
}

export function berDecodeSignature(signature) {
  // Sanitize argument
  assertInstanceOf(
    [Data, Uint8Array, ArrayBuffer, Array],
    signature,
    "signature"
  );
  if (isInstanceOf([Uint8Array, ArrayBuffer, Array], signature))
    signature = new Data(signature);

  const decoded_asn1 = asn1js.fromBER(signature.createArrayBuffer());

  if (decoded_asn1.offset === -1)
    throw new Error("Failed to decode signature.");

  return Data.join([
    toUnsignedData(
      new Data(decoded_asn1.result.valueBlock.value[0].valueBlock.valueHex)
    ),
    toUnsignedData(
      new Data(decoded_asn1.result.valueBlock.value[1].valueBlock.valueHex)
    ),
  ]);
}

export function berEncodeSignature(signature) {
  // Sanitize argument
  assertInstanceOf(
    [Data, Uint8Array, ArrayBuffer, Array, Buffer],
    signature,
    "signature"
  );
  if (isInstanceOf([Uint8Array, ArrayBuffer, Array, Buffer], signature))
    signature = new Data(signature);

  if (signature.length != 64)
    throw Error(
      `signature must be 64 bytes long (but was ${signature.length} bytes).`
    );

  const sequence = new asn1js.Sequence();

  sequence.valueBlock.value.push(
    new asn1js.Integer({
      isHexOnly: true,
      valueHex: toSignedData(signature.slice(0, 32)).createArrayBuffer(),
    })
  );

  sequence.valueBlock.value.push(
    new asn1js.Integer({
      isHexOnly: true,
      valueHex: toSignedData(signature.slice(32, 64)).createArrayBuffer(),
    })
  );

  return new Data(sequence.toBER(false));
}
