import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  Icon,
  Switch,
  Classes,
  Tag,
  Intent,
  Button
} from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";
import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";
import Expire from "../expire/Expire";

export default ({ dispatch, active, close, zone }) => {
  const [zoneId, setZoneId] = useState(zone);
  useEffect(() => setZoneId(zone), [zone]);

  if (!zoneId) {
    return null;
  }

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <>
          <Icon icon="time" iconSize={20} />
          <span className={styles.dialogTitle}>Expire Team Files</span>
        </>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <Expire zone={zoneId} close={close} />
      </div>
    </Dialog>
  );
};
