import React, { useContext, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Menu,
  MenuItem,
  Icon,
  Switch,
  Dialog,
  Intent,
} from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { entries, values, classNames, jwtPeek } from "utils";
import createApiClient from "api-client";
import onEnterEffect from "../../helpers/on-enter-effect";
import Button from "../../helpers/the-button";
import { Blocked } from "../login/icons.tsx";
import appContext from "../../app/appContext.js";
import { selectIdentity, getToken } from "../../app/topSlice.js";
import styles from "./Users.module.css";
import { setDialog } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { showPane, PANE_USER, PANE_ACCOUNT } from "../layout/layoutSlice";
import { formatUser } from "../format/format.js";
import { RESTRICT } from "../dialogs/Dialogs.js";
import { dispatchToast } from "../toasts/Toasts.js";
import * as config from "../../config";
import KeyperProvider from "../../helpers/wrappers/KeyVaultProvider";

import { USERS_USER, USERS_BACKGROUND } from "../contexts/Contexts.js";

import { useContextMenu } from "../contexts/Popover.js";

import {
  FOLDER_RESTRICT,
  FOLDER_UNRESTRICT,
  TEAM_RESTRICT,
  TEAM_UNRESTRICT,
  GROUP_TEAMMATE_REMOVE,
  GROUP_TEAMMATE_ADD,
  TEAM_TEAMMATE_ADD,
  WORKSPACE_TEAMMATE_INVITE,
} from "../../app/permissions.js";

const {
  derive: {
    getUsersFromCurrentZoneRootGroup,
    getUsersFromGroup,
    getGroupsFromCurrentZone,
  },
  selectors: { selectGraph, selectPermissions },
  actions: {
    //addMemberToZones,
    //restrictZone,
    //unrestrictZone,
    //restrictFolder,
    //unrestrictFolder,
    restrictMember,
    includeMember,
    //setCurrentNode
  },
} = appState;

const ORDER_OF_SORTS = {
  name: (a, b) => {
    const v = a.name.localeCompare(b.name);
    return v != 0 ? v : a.index - b.index;
  },
  id: (a, b) => {
    const v = a.provider.localeCompare(b.provider);
    return v != 0 ? v : a.index - b.index;
  },
  role: (a, b) => {
    const v = a.role.localeCompare(b.role);
    return v != 0 ? v : a.index - b.index;
  },
  index: (a, b) => {
    return a.index - b.index;
  },
};

const DEFAULT_SORT = "index";

let doingIt = false;

export const Users = React.memo(() => {
  const dispatch = useDispatch();
  //const state = useSelector(state => state);
  const graph = useSelector(selectGraph);
  const context = useContext(appContext);
  const identity = useSelector(selectIdentity);
  const {
    zoneId,
    group,
    folder,
    isRestricted,
    nodeId,
    rootNodeId,
    rootGroupId,
  } = context;
  const { id: myUserId, device: deviceId } = identity;
  const permissions = useSelector(selectPermissions);
  const { id: folderId, name } = folder || {};
  const ref = useContextMenu(USERS_BACKGROUND, { zone: { id: zoneId } });
  const [roles, setRoles] = useState({});
  const [sortOn, _setSortOn] = useState(DEFAULT_SORT);
  const [reverseSort, _setReverseSort] = useState(false);

  const setSortOn = (sortIndex) => {
    if (sortIndex != sortOn) {
      _setReverseSort(false);
      _setSortOn(sortIndex);
    } else if (!reverseSort) {
      _setReverseSort(true);
    } else {
      _setSortOn(DEFAULT_SORT);
      _setReverseSort(false);
    }
  };

  useEffect(() => {
    if (!group) return;
    (async () => {
      const token = await dispatch(getToken());
      const { org } = jwtPeek(token);

      const api = createApiClient(config.API_URL_PREFIX);
      const roles = await api.token(token).orgs(org).roles().get();

      setRoles(roles.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}));
    })();
  }, []);

  const inProgress = useRef(false);

  const groups = getGroupsFromCurrentZone(graph);
  const noAccess =
    isRestricted && groups.length == 1 && groups[0].id == rootGroupId;

  if (!group || noAccess) {
    return <Splash noAccess={noAccess} />;
  }

  const zoneUsers = getUsersFromCurrentZoneRootGroup(graph);
  const groupUsers = folder ? getUsersFromGroup(graph, folder.group) : [];

  const otherUsers = zoneUsers
    .filter((a) => !groupUsers.some((b) => a.user == b.user))
    .map((user) => ({ ...user, restricted: true }));

  let users = [...groupUsers, ...otherUsers];

  const diffUsers = (users) => {
    const dupUsers = [];
    const foundIds = {};
    for (const user of users) {
      if (user.user in foundIds) {
        dupUsers.push(user);
      } else {
        foundIds[user.user] = 1;
      }
    }
    return dupUsers;
  };

  for (const [key, val] of entries({
    zoneUsers,
    groupUsers,
    otherUsers,
    users,
  })) {
    if (diffUsers(val).length) {
      console.log(key, "contains dublets!", diffUsers(val), zoneUsers);
    }
  }

  //if (!group) {
  //  return <Splash />;
  //}

  //const doIt = async () => {
  //  const email = prompt();
  //  if (!email) {
  //    return;
  //  }
  //  const provider = "email";
  //  const wellknown = [email];
  //  const roleId = "some-role-id";
  //  const result = await dispatch(
  //    addMemberToZones({
  //      deviceId,
  //      provider,
  //      wellknown,
  //      email,
  //      roleId,
  //      zoneIds: [zoneId]
  //    })
  //  );
  //};

  const doIt = async ({ member, checked }) => {
    if (inProgress.current) {
      return;
    } else {
      inProgress.current = true;
    }
    const toast = await dispatchToast(dispatch, {
      message: `${checked ? "Denying" : "Allowing"} user.`,
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    try {
      if (checked) {
        await dispatch(
          restrictMember({
            zoneId,
            nodeId: folder.id,
            memberId: member,
          })
        );
      } else {
        await dispatch(
          includeMember({
            zoneId,
            nodeId: folder.id,
            memberId: member,
          })
        );
      }
      await toast.replace({
        message: `${checked ? "Denied" : "Allowed"} user.`,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
    } catch (e) {
      Sentry.captureException(e);
      await toast.replace({
        message: "Something went wrong, please try again.",
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
    } finally {
      inProgress.current = false;
    }
  };

  let isPermitted = false;
  if (folderId == rootNodeId) {
    if (isRestricted) {
      isPermitted = permissions[TEAM_UNRESTRICT];
    } else {
      isPermitted = permissions[TEAM_RESTRICT];
    }
  } else if (folderId == nodeId) {
    if (!folder.parent) {
      isPermitted = false;
    } else if (folder.isRestricted) {
      isPermitted = permissions[FOLDER_UNRESTRICT];
    } else {
      isPermitted = permissions[FOLDER_RESTRICT];
    }
  }

  const sortedUsers = [...users].sort((a, b) => {
    return ORDER_OF_SORTS[sortOn](a, b) * (reverseSort ? -1 : 1);
  });

  return (
    <div ref={ref} className={styles.outer}>
      <div className={styles.bar}>
        <div onClick={(e) => setSortOn("name")} className={styles.header}>
          <span>Users</span>{" "}
          {sortOn === "name" && (
            <Icon
              className={classNames(reverseSort && styles.upsideDown)}
              icon="caret-down"
            />
          )}
        </div>
        <div onClick={(e) => setSortOn("id")} className={styles.header}>
          <span>Id</span>
          {sortOn === "id" && (
            <Icon
              className={classNames(reverseSort && styles.upsideDown)}
              icon="caret-down"
            />
          )}
        </div>
        <div onClick={(e) => setSortOn("role")} className={styles.header}>
          <span>Role</span>
          {sortOn === "role" && (
            <Icon
              className={classNames(reverseSort && styles.upsideDown)}
              icon="caret-down"
            />
          )}
        </div>
        {isPermitted && (
          <div
            title="manage user access"
            className={styles.header}
            onClick={() =>
              isPermitted && dispatch(setDialog({ dialog: RESTRICT }))
            }
          >
            <Blocked />
          </div>
        )}
      </div>
      <div className={styles.rest}>
        {values(sortedUsers).map((user) => (
          <User
            key={user.user}
            myUserId={myUserId}
            permissions={permissions}
            roles={roles}
            dispatch={dispatch}
            nodeId={nodeId}
            folder={folder}
            zoneId={zoneId}
            isPermitted={isPermitted}
            doIt={doIt}
            {...user}
          />
        ))}
      </div>
    </div>
  );
});

export const Splash = ({ noAccess }) => {
  return (
    <div className={styles.splash}>
      <div className={styles.bar}>
        <div className={styles.header}>
          <span>Users</span>
        </div>
      </div>
      <div className={styles.rest}>
        {noAccess && (
          <div className={styles.empty}>
            <div>
              <Icon iconSize={44} icon="eye-off" />
            </div>
            <div>
              <div>Nothing To See Here</div>
              <div>You've got no access to selected resources</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const User = (props) => {
  const {
    dispatch,
    permissions,
    roles,
    zoneId,
    group,
    restricted,
    nodeId,
    folder,
    myUserId,
    member,
    isPermitted,
    doIt,
    ...user
  } = props;

  user.id = user.user;
  delete user.user;

  const ref = useContextMenu(USERS_USER, { zone: { id: zoneId }, user });
  const checked = !restricted;
  const permitted = checked
    ? permissions[GROUP_TEAMMATE_REMOVE]
    : permissions[GROUP_TEAMMATE_ADD];

  const itIsMe = user.id == myUserId;
  const disabled = nodeId != folder.id || !folder.isRestricted || itIsMe; //|| !permitted;

  const showUser = async (_) => {
    const { id, role, provider, wellknown, name, email } = user;

    const formattedUser = {
      id,
      role: { id: role },
      provider,
      wellknown,
      name,
      email,
    };

    if (id === myUserId) {
      await dispatch(showPane(PANE_ACCOUNT));
    } else {
      await dispatch(showPane({ ...PANE_USER, user: formattedUser }));
    }
  };

  const roleColor = user.role in roles ? roles[user.role].color : {};
  const [[primary, secondary], detailed] = dispatch(formatUser(user));
  return (
    <div ref={ref} key={user.id} onClick={showUser} className={styles.row}>
      <div
        title={detailed}
        className={classNames(
          styles.column,
          styles.user,
          checked || styles.striked
        )}
      >
        <span>{primary}</span>
        {secondary && <span>{secondary}</span>}
      </div>
      <div
        className={classNames(
          styles.column,
          styles.provider,
          styles[`provider-${user.provider}`]
        )}
      >
        <span>
          <KeyperProvider>
            {user.provider === "saml" ? "sso" : user.provider}
          </KeyperProvider>
        </span>
      </div>
      <div className={classNames(styles.column, styles.role)}>
        <span
          style={{
            backgroundColor: roleColor.background,
            borderColor: roleColor.border,
            color: roleColor.foreground,
          }}
        >
          {user.role in roles ? roles[user.role].name : "..."}
        </span>
      </div>
      {permitted && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (!folder.isRestricted) {
              if (isPermitted) {
                dispatch(setDialog({ dialog: RESTRICT }));
              }
            }
          }}
          className={classNames(styles.column, styles.check)}
        >
          <Switch
            large={true}
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              doIt({ member, checked });
              //if (checked) {
              //  dispatch(
              //    restrictMember({
              //      zoneId,
              //      nodeId: folder.id,
              //      memberId: member
              //    })
              //  );
              //} else {
              //  dispatch(
              //    includeMember({
              //      zoneId,
              //      nodeId: folder.id,
              //      memberId: member
              //    })
              //  );
              //}
            }}
          />
        </div>
      )}
    </div>
  );
};

//export const Dialogs = props => (
//  <>{props.dialog == "RESTRICT" && <RestrictDialog {...props} />}</>
//);
//
//export const RestrictDialog = ({ dispatch, dialog, closeDialog }) => {
//  const { device: deviceId } = useSelector(selectIdentity);
//  const { zoneId, nodeId, rootNodeId, folder } = useContext(appContext);
//  const { id: folderId, isRestricted, name } = folder;
//
//  useEffect(onEnterEffect(off => (off(), doIt())), []);
//
//  const doIt = async () => {
//    if (folderId == rootNodeId) {
//      if (isRestricted) {
//        await dispatch(unrestrictZone({ zoneId }));
//      } else {
//        await dispatch(restrictZone({ zoneId }));
//      }
//    } else if (folderId == nodeId) {
//      if (isRestricted) {
//        await dispatch(unrestrictFolder({ zoneId, nodeId }));
//      } else {
//        await dispatch(restrictFolder({ zoneId, nodeId }));
//      }
//      await dispatch(setCurrentNode(nodeId)); //make current(node, group) line up
//    }
//    closeDialog();
//  };
//
//  return (
//    <Dialog
//      isOpen={true}
//      onClose={closeDialog}
//      title={
//        <div className={styles.title}>
//          <AddZone size={34} />
//          <span className={styles.dialogTitle}>Access Control</span>
//        </div>
//      }
//      className={styles.restrictDialog}
//    >
//      <div className={styles.dialogBody}>
//        <p>
//          This will{" "}
//          {isRestricted ? "disable special access" : "enable special access"}{" "}
//          for <b>{name}</b>
//        </p>
//        <Button onClick={doIt}>{isRestricted ? "Disable" : "Enable"}</Button>
//      </div>
//    </Dialog>
//  );
//};
