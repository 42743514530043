import React, { memo, useState, useContext } from "react";
import Invite from "../invite/index.tsx";
import appContext from "../../app/appContext.js";
import styles from "./Dialogs.module.css";

export default ({ dispatch, active, close }) => {
  const { groupId, nodeId, zoneId, zoneName } = useContext(appContext);

  return <Invite {...{ active, close, zoneId, zoneName }} />;
};
