import { Data } from "../../";
import SecretKey, { TAG_LENGTH_IN_BITS } from "../secret_key";
import {
  getClassName,
  assertObject,
  assertInstanceOf,
  assertString,
  isEdge
} from "../../util";
import SJCLPublicECDHKey from "./sjcl_public_ecdh_key";
import SJCLPrivateECDHKey from "./sjcl_private_ecdh_key";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLSecretKey extends SecretKey {
  constructor(key) {
    super(key);

    this.aes = new sjcl.cipher.aes(key);
  }

  static async import(key, format) {
    assertString(format, "format");

    switch (format) {
      case "raw":
        assertInstanceOf(Data, key, "key");

        return new SJCLSecretKey(sjcl.codec.bytes.toBits(key.getUint8Array()));
      default:
        throw Error(
          "Unsupported import format for SJCL secret key. (" + format + ")"
        );
    }
  }

  async export(format) {
    assertString(format, "format");

    switch (format) {
      case "raw":
        return new Data(sjcl.codec.bytes.fromBits(this.key));
      default:
        throw Error(
          "Unsupported export format for SJCL secret key. (" + format + ")"
        );
    }
  }

  static async agree(publicECDHKey, privateECDHKey) {
    assertInstanceOf(SJCLPublicECDHKey, publicECDHKey, "publicECDHKey");
    assertInstanceOf(SJCLPrivateECDHKey, privateECDHKey, "privateECDHKey");

    return new SJCLSecretKey(privateECDHKey.key.dhJavaEc(publicECDHKey.key));
  }

  async encrypt(data, iv, aad = new Data(0)) {
    assertInstanceOf(Data, data, "data");
    assertInstanceOf(Data, iv, "iv");
    assertInstanceOf(Data, aad, "aad");

    return new Data(
      sjcl.codec.bytes.fromBits(
        sjcl.mode.gcm.encrypt(
          this.aes,
          sjcl.codec.bytes.toBits(data.getUint8Array()),
          sjcl.codec.bytes.toBits(iv.getUint8Array()),
          sjcl.codec.bytes.toBits(aad.getUint8Array()),
          TAG_LENGTH_IN_BITS
        )
      )
    );
  }

  async decrypt(data, iv, aad = new Data(0)) {
    assertInstanceOf(Data, data, "data");
    assertInstanceOf(Data, iv, "iv");
    assertInstanceOf(Data, aad, "aad");

    return new Data(
      sjcl.codec.bytes.fromBits(
        sjcl.mode.gcm.decrypt(
          this.aes,
          sjcl.codec.bytes.toBits(data.getUint8Array()),
          sjcl.codec.bytes.toBits(iv.getUint8Array()),
          sjcl.codec.bytes.toBits(aad.getUint8Array()),
          TAG_LENGTH_IN_BITS
        )
      )
    );
  }
}
