import {
  toPackage as toPdfXmlDsig,
  fromPackage as fromPdfXmlDsig,
  isPackage as isPackagePdfXmlDsig,
} from "./pdfXmlDsig.js";

import {
  NOT_A_PDF,
  PDF_NOT_SUPPORTED,
  PDF_NOT_A_PACKAGE,
  PACKAGE,
  ORIGINAL,
  METADATA,
  INFOPAGE,
} from "./consts.js";

export {
  NOT_A_PDF,
  PDF_NOT_SUPPORTED,
  PDF_NOT_A_PACKAGE,
  PACKAGE,
  ORIGINAL,
  METADATA,
  INFOPAGE,
};

export const isSignedButNotComplete = (esignature) => {
  return !esignature?.completed && esignature?.status == "signed";
};

export const isPackage = async (esignature, blob) => {
  if (await isPackagePdfXmlDsig(esignature, blob)) {
    return true;
  }
  return false;
};

export const choosePackager = (esignature) => {
  if (esignature && !("type" in esignature)) {
    return toPdfXmlDsig;
  }
  throw new Error("Could not choose compatible packager.");
};

export const chooseUnPackager = (esignature) => {
  if (esignature && !("type" in esignature)) {
    return fromPdfXmlDsig;
  }
  throw new Error("Could not choose compatible UNpackager.");
};

export const toPackage = async (esignature, blob, fileName, token) => {
  const toPackage = choosePackager(esignature);
  return await toPackage(esignature, blob, fileName, token);
};

export const fromPackage = async (esignature, blob) => {
  try {
    return await fromPdfXmlDsig(esignature, blob);
  } catch (e) {
    // overly verbose hoping to be helpfull
    if (
      e !== PDF_NOT_A_PACKAGE &&
      (e === NOT_A_PDF || e === PDF_NOT_SUPPORTED)
    ) {
      throw e;
    }
  }
  //try another one here

  // default case, decide pdf is not a package
  throw PDF_NOT_A_PACKAGE;
};
