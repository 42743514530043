import createApiClient from "api-client";

import {
  values,
  entries,
  assign,
  mapObj,
  createSagaActions,
  createMetaHooks,
  createControlledSagas,
  createSlice as _createSlice,
} from "utils";

import abstracts from "./abstracts.js";
import createLocal, { initialState as localState } from "./local.js";
import createGlobal, { initialState as globalState } from "./global.js";
import createSelectors from "./selectors.js";
//import createSharedSagas from "./shared.js";
import createServerSagas from "./server.js";
import createClientSagas from "./client.js";

export * as derive from "./derive.js";

export { createUri, parseUri, hints, watch } from "./queries.js";

export default (args) => {
  const {
    name,
    config = {},
    trustKit,
    sagas: externals = {},
    actions: externalActions = {},
    modeClient = false,
    createSlice = null,
  } = args;

  const { API_URL_PREFIX } = config;

  const api =
    /*modeClient ||*/ API_URL_PREFIX && createApiClient(API_URL_PREFIX);

  const { waitFor, terms, take, takes, takeRace, subscribe } =
    createMetaHooks().metaActions;

  const module = {
    config,
    options: { name },
    meta: { waitFor, terms, take, takes, takeRace, subscribe },
  };

  const initialState = modeClient ? localState : globalState;
  const reducers = modeClient ? createLocal() : createGlobal();
  const selectors = createSelectors(module, config, api);
  //const shared = createSharedSagas(module, config, api);
  const server = createServerSagas(module, config, api);
  const client = createClientSagas(module, config, api);

  const reduceActions = createSagaActions({ name, sagas: reducers });
  //const sharedActions = createSagaActions({ name, sagas: shared });
  const serverActions = createSagaActions({ name, sagas: server });
  const clientActions = createSagaActions({ name, sagas: client });

  const actionActions = createSagaActions({
    name,
    sagas: {
      ...abstracts,
      ...externals,
    },
  });

  const sagas = {
    [name]: {
      ...abstracts,
      //...shared,
      ...externals,
      ...server,
      ...(modeClient && client),
    },
  };

  let slices = {
    [name]: {
      name,
      initialState: {
        ...initialState,
      },
      reducers: {
        ...reducers,
      },
      ...(createSlice || { sagas }),
    },
  };

  slices = mapObj(slices, (slice, name) => ({ ...slice, sagas: sagas[name] }));
  slices = mapObj(slices, createSlice || _createSlice);

  const slicedReducers = mapObj(slices, (slice) => slice.reducer);
  const slicedActions = values(slices).reduce(
    (actions, slice) => ({ ...actions, ...slice.actions }),
    {}
  );

  assign(module, {
    selectors,
    actions: {
      ...reduceActions,
      ...actionActions,
      ...externalActions,
    },
    //shared: sharedActions,
    server: serverActions,
    client: clientActions,
    ...(modeClient && { trustKit }),
  });

  return {
    selectors,
    reducers: slicedReducers,
    ...(createSlice && { sagas }),
    ...(modeClient && {
      actions: {
        ...reduceActions,
        ...actionActions,
        //...sharedActions,
        ...clientActions,
      },
    }),
    ...(modeClient && { server: serverActions }),
    ...(modeClient || {
      actions: {
        ...serverActions,
        ...actionActions,
        ...reduceActions,
      },
    }),
  };
};
