import { trial, jwtPeek, isBrowser, fetch } from "utils";

export const VERSION = "v13";

export default function apiCreator(endpoint) {
  //return base(new QueryBuilder([`https://${API_URL_BACK}/api/${VERSION}`]));
  return base(new QueryBuilder(endpoint));
}

class QueryBuilder {
  constructor(endpoint, context = []) {
    this.endpoint = endpoint;
    this.context = context;
  }

  subcontext(noun, id) {
    if (
      arguments.length === 2 &&
      (!arguments[1] ||
        typeof arguments[1] !== "string" ||
        arguments[1].length === 0)
    ) {
      throw new Error(
        `ID of ${noun} must be a non-empty string. (id=${arguments[1]})`
      );
    } else if (arguments.length > 2) {
      throw new Error(
        `Subcontext takes 0 or 1 arguments, but was called with ${
          arguments.length
        }.`
      );
    }
    const sub = new QueryBuilder(this.endpoint, this.context.concat(noun, id));
    sub.accessToken = this.accessToken;
    sub.doRetry = this.doRetry;
    return sub;
  }

  token(accessToken) {
    this.accessToken = accessToken;
  }

  retry(doRetry = true) {
    this.doRetry = doRetry;
  }

  async request({ method, body, params }, url = false, raw = false) {
    if (!url) {
      url =
        /*await*/ this.endpoint +
        `/api/${VERSION}/` +
        this.context.join("/") +
        (params ? `?${params}` : "");
    }

    const headers = {
      "content-type": "application/json",
      accept: "application/json"
    };

    if (this.accessToken) {
      headers.authorization = `Bearer ${this.accessToken}`;
      const { user } = trial(jwtPeek)(this.accessToken);
      if (user) {
        headers["user-id"] = user;
      }
    }

    let response;
    while (true) {
      try {
        response = await fetch(url, {
          method,
          mode: "cors",
          headers,
          body
        });
      } catch (e) {
        if (this.doRetry) {
          await new Promise(k => setTimeout(k, 1750));
          continue;
        } else {
          throw e;
        }
      }
      break;
    }

    //const response = await fetch(url, {
    //  method,
    //  mode: "cors",
    //  headers,
    //  body
    //});

    if (response.status === 403) {
      throw "SERVER_DENIED_PERMISSION";
    }

    if (raw) {
      return await response.json();
    }

    if (response.status >= 200 && response.status <= 299) {
      return (await response.json())["result"];
    } else if (response.status === 418) {
      if (isBrowser) {
        if (!window.__reloading) {
          window.__reloading = 1;
          location.reload(true);
        }
        return new Promise(_ => {});
      }
      throw "VERSION_MISMATCH";
    } else {
      const e = new Error((await response.json())["error"]);
      e.code = response.status;
      throw e;
    }
  }

  async rawGet(url) {
    return this.raw({ url, method: "GET" });
  }

  async raw({ url, method, body, params }) {
    return this.request({ method, body, params }, url, true);
  }

  async get({ fields, filter, filtor } = {}) {
    return this.request({
      method: "GET",
      params: [
        stringifyFilters(filter),
        stringifyField(fields),
        stringifyFilters(filtor, "filtor")
      ]
        .filter(Boolean)
        .join("&")
    });
  }

  async delete() {
    return this.request({
      method: "DELETE"
    });
  }

  async post(data) {
    if (typeof data !== "object" || data === null) {
      throw new Error(`APIError: 'data' must be an object.`);
    }

    return this.request({
      method: "POST",
      body: JSON.stringify(data)
    });
  }

  async put(data) {
    if (typeof data !== "object" || data === null) {
      throw new Error(`APIError: 'data' must be an object.`);
    }

    return this.request({
      method: "PUT",
      body: JSON.stringify(data)
    });
  }

  async patch(data) {
    if (typeof data !== "object" || data === null) {
      throw new Error(`APIError: 'data' must be an object.`);
    }

    return this.request({
      method: "PATCH",
      body: JSON.stringify(data)
    });
  }
}

function stringifyFilters(filter = [], key = "filter") {
  if (!Array.isArray(filter)) {
    throw new Error(`filter must be an array of filters.`);
  }
  if (filter.length > 0) {
    return `${key}=${encodeURIComponent(filter.join(","))}`;
  } else {
    return "";
  }
}

function stringifyField(fields = {}) {
  const value = stringifyFieldRecursive(fields);
  if (value) {
    return `fields=${encodeURIComponent(value)}`;
  } else {
    return "";
  }
}

function stringifyFieldRecursive(fields) {
  return Object.entries(fields)
    .reduce((query, [key, value]) => {
      if (typeof value === "object") {
        return query.concat(`${key}(${stringifyFieldRecursive(value)})`);
      } else if (value) {
        return query.concat(`${key}`);
      }
    }, [])
    .join(",");
}

const proxy = queryBuilder =>
  new Proxy(queryBuilder, {
    get: (api, prop) => (...args) => {
      switch (prop) {
        case "get":
          return api.get(...args);
        case "post":
          return api.post(...args);
        case "put":
          return api.put(...args);
        case "patch":
          return api.patch(...args);
        case "delete":
          return api.delete(...args);
        case "rawGet":
          return api.rawGet(...args);
        case "raw":
          return api.raw(...args);
        //d;
        default:
          return proxy(api.subcontext(prop, ...args));
      }
    }
  });

const base = queryBuilder =>
  new Proxy(queryBuilder, {
    get: (api, prop) => (...args) => {
      switch (prop) {
        case "token":
          api.token(...args);
          //return proxy(api);
          return base(api);
        case "retry":
          api.retry(...args);
          //return proxy(api);
          return base(api);
        //case "setCredentials":
        //  return api.setCredentials(...args);
        //case "getToken":
        //  return api.getToken();
        case "rawGet":
          return api.rawGet(...args);
        case "raw":
          return api.raw(...args);
        default:
          return proxy(api.subcontext(prop, ...args));
      }
    }
  });
