import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//import { TransitionGroup, CSSTransition } from "react-transition-group";

import { classNames } from "utils";

import { mobile } from "../../helpers/detect-browser";

import styles from "./dnd.module.css";

export const getDraggedDom = id =>
  document.querySelector(`[data-rbd-drag-handle-draggable-id='${id}']`);

const getListStyle = isDraggingOver => {
  const extra = {};
  if (isDraggingOver) {
    extra.border = "dashed 1px #aaa";
    extra.margin = "3px";
    extra.padding = "5px";
    extra.borderRadius = "3px";
  }
  return {
    transition: "margin .2s, padding .2s",
    ...extra
  };
};

const portal = document.createElement("div");
document.body.appendChild(portal);

export default ({ droppables, onDrop }) => {
  const dispatch = useDispatch();

  const [droppableSource, setDroppableSource] = useState(null);
  const [isCombineEnabled, setIsCombineEnabled] = useState(false);
  //const [hideKidsOf, setHideKidsOf] = useState(false);

  const rootEl = useRef(null);
  const dragEl = useRef(null);
  const placeholderEl = useRef(null);
  const semaphore = useRef(false);
  const indentation = useRef(0);
  const mouseLongitude = useRef(0);

  useEffect(() => {
    const onMouseMove = e => (mouseLongitude.current = e.clientX);
    document.addEventListener("mousemove", onMouseMove, true);
    return () => document.removeEventListener("mousemove", onMouseMove, true);
  }, []);

  const handleDragEnd = result => {
    setDroppableSource(null);

    if (rootEl.current) {
      rootEl.current.classList.remove(styles.active);
    }

    semaphore.current = false;
    dragEl.current.style.transition = null;
    dragEl.current.style.transform = null;
    dragEl.current = null;

    if (placeholderEl.current) {
      placeholderEl.current.classList.remove(styles.around);
    }

    let droppableId = result.destination?.droppableId;

    if (droppableId) {
      if (droppableId == " ") {
        droppableId = "";
      }
      onDrop(result.draggableId, droppableId);
    }

    //setHideKidsOf("");

    //if (result.combine) {
    //  //dispatch(action(result));
    //}
  };

  const handleDragStart = event => {
    setDroppableSource(event.source.droppableId);

    if (rootEl.current) {
      rootEl.current.classList.add(styles.active);
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    dragEl.current = draggedDOM.firstElementChild;

    //setHideKidsOf(event.draggableId);
  };

  const handleDragUpdate = event => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    if (!event.destination) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    if (destinationIndex == sourceIndex) {
      if (placeholderEl.current) {
        placeholderEl.current.classList.remove(styles.around);
      }
      return;
    }

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    //const updatedArray = [
    //  ...childrenArray.slice(0, destinationIndex),
    //  movedItem,
    //  ...childrenArray.slice(destinationIndex + 1)
    //];

    //var clientY =
    //  parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    //  updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
    //    const style = curr.currentStyle || window.getComputedStyle(curr);
    //    const marginBottom = parseFloat(style.marginBottom);
    //    return total + curr.clientHeight + marginBottom;
    //  }, 0);

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      childrenArray /*updatedArray*/
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          return total + curr.clientHeight;
        }, 0);

    const marginLeft =
      childrenArray[destinationIndex]?.firstElementChild?.firstElementChild
        ?.style?.marginLeft;

    const clientX = marginLeft ? parseFloat(marginLeft) : 0;

    indentation.current = clientX;

    if (placeholderEl.current) {
      placeholderEl.current.classList.remove(styles.around);
      placeholderEl.current.style.top = clientY + 280 + "px";
      placeholderEl.current.style.left = clientX + "px";
      setTimeout(() => placeholderEl.current.classList.add(styles.around), 100);
    }
  };

  //let hideKidsOfAll = hideKidsOf ? [hideKidsOf] : [];
  //list = list.filter(item => {
  //  if (hideKidsOfAll.includes(item.parent)) {
  //    hideKidsOfAll.push(item.id);
  //    return false;
  //  }
  //  return true;
  //});

  return (
    <div ref={rootEl} className={styles.outer}>
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        {droppables.map(droppable => {
          const { id, comp = "div", props = {}, draggables } = droppable;
          const droppableId = id || " ";
          return (
            <Droppable
              key={id}
              droppableId={droppableId}
              isCombineEnabled={isCombineEnabled}
              isDropDisabled={droppableId == droppableSource}
            >
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classNames(
                      styles.droppable,
                      snapshot.isDraggingOver && droppableSource && styles.hover
                    )}
                  >
                    {React.createElement(comp, {
                      ...props,
                      children: open => (
                        /*<TransitionGroup>*/
                        <>
                          {draggables
                            .filter(() => open)
                            .map((draggable, index) => {
                              const {
                                id,
                                comp = "div",
                                props = {}
                              } = draggable;
                              return (
                                /*<CSSTransition
                                  key={id}
                                  classNames={{ ...styles }}
                                  timeout={250}
                                >*/
                                <Draggable
                                  key={id}
                                  draggableId={id}
                                  index={index}
                                  isDragDisabled={mobile}
                                >
                                  {(provided, snapshot) => {
                                    const {
                                      isDragging,
                                      combineTargetFor,
                                      isDropAnimating
                                    } = snapshot;
                                    if (isDragging) {
                                      if (
                                        dragEl.current &&
                                        !semaphore.current
                                      ) {
                                        semaphore.current = true;
                                        const {
                                          right
                                        } = dragEl.current.firstElementChild.getBoundingClientRect();
                                        if (right < mouseLongitude.current) {
                                          const diff =
                                            mouseLongitude.current - right + 50;
                                          dragEl.current.style.transition = `transform 250ms`;
                                          dragEl.current.style.transform = `translate(${diff}px, 0)`;
                                        }
                                      }
                                      if (dragEl.current && isDropAnimating) {
                                        dragEl.current.style.transition = `transform 200ms`;
                                        dragEl.current.style.transform = null;
                                        if (indentation.current) {
                                          const diff = indentation.current;
                                          dragEl.current.style.transform = `translate(${diff}px, 0)`;
                                          indentation.current = 0;
                                        }
                                      }
                                    }
                                    const child = (
                                      <div
                                        data-rbd-drag-handle-draggable-id={id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        {...isDragging && {
                                          ["data-dragging"]: "1"
                                        }}
                                        {...combineTargetFor && {
                                          ["data-dropping"]: "1"
                                        }}
                                        className={styles.draggable}
                                      >
                                        {React.createElement(comp, props)}
                                      </div>
                                    );
                                    if (snapshot.isDragging) {
                                      return ReactDOM.createPortal(
                                        child,
                                        portal
                                      );
                                    }
                                    return child;
                                  }}
                                </Draggable>
                                /*</CSSTransition>*/
                              );
                            })}
                          {provided.placeholder}
                        </>
                        /*</TransitionGroup>*/
                      )
                    })}
                    <div
                      ref={el => (placeholderEl.current = el)}
                      className={classNames(styles.placeholder)}
                    />
                  </div>
                );
              }}
            </Droppable>
          );
        })}
      </DragDropContext>
    </div>
  );
};
