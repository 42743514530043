export const CIPHER_TEXT = "CIPHER_TEXT";
export const VERSION = "VERSION";
export const VERSION_VALUE = "2.0.0";
export const KEY_FROM = "from";

// Public key lookup
export const ADD_TOKEN_PATH = "pki/addToken";
export const GET_ADDRESS_INFO_PATH = "pki/getUIDInfo";
export const REGISTER_ADDRESS_PATH = "pki/registerUser";
export const REVOKE_CERTIFICATE_PATH = "pki/revokeCertificate";
export const ADDRESS = "uid";
export const ERROR = "error";
export const PUBLIC_ECDH_KEY = "eck";
export const PUBLIC_ECDH_KEY_SIGNATURE = "ecks";
export const CERTIFICATE_SIGNING_REQUEST = "csr";
export const ADDRESS_CERTIFICATE = "crt";
export const KDS_CERTIFICATE = "kdc";
export const API_KEY = "apikey";
export const REVOCATION_PASS = "revpass";
export const OK_RESPONSE = "OK";
