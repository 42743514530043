//import { createSlice } from "@reduxjs/toolkit";
import { createSlice } from "utils";
import { getContext, getToken } from "../../app/topSlice.js";
import { uploadLogo } from "../../app/realworld";

const blueprint = {
  name: "admin",
  initialValue: {
    logo: "somelogo",
    admins: []
  },
  reducers: {
    setTempLogo: (state, { payload: logo }) => {
      console.log("setTempLogo", logo);
    }
  },
  sagas: {
    changeLogo: async ({ payload: { image } }, dispatch) => {
      const jwt = await dispatch(getToken());
      await uploadLogo(jwt, { image });
    }
  }
};

export const adminSlice = createSlice(blueprint);
export const adminSagas = blueprint.sagas;
export const { setTempLogo, changeLogo } = adminSlice.actions;

/*
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAdmins = state => state.admin.admins;

export default adminSlice.reducer;
