import { keys, values, entries, assign, clone } from "utils";

//import { moveNodeInGraph } from "./pure.js";

import {
  accumulate,
  getGroupsFromZone,
  getNodesFromGroup,
  getUsersFromGroup,
  moveNodeInGraph
} from "./derive";

export const initialState = {
  graph: {
    zones: {},
    nodes: {},
    users: {},
    groups: {}
  }
};

export default () => {
  return {
    accept: (state, { payload: { graph = {} } }) => {
      state.graph = graph;
    },
    consume: (state, { payload: { graph = {}, clean = {} } }) => {
      for (const [key, ids] of entries(accumulate(state.graph, clean))) {
        for (const id of ids) {
          delete state.graph[key][id];
        }
      }
      for (const key of keys(graph)) {
        if (!(key in state.graph)) {
          state.graph[key] = {};
        }
        for (const [id, entry] of entries(graph[key])) {
          state.graph[key][id] = entry;
        }
      }
    }
  };
};

//export const reduce = (graph, fresh, clean) => {
//  const before = {
//    ...(graph.zones && { zones: { ...graph.zones } }),
//    ...(graph.nodes && { nodes: { ...graph.nodes } }),
//    ...(graph.users && { users: { ...graph.users } }),
//    ...(graph.groups && { groups: { ...graph.groups } })
//  };
//
//  for (const [key, ids] of entries(accumulate(graph, clean))) {
//    for (const id of ids) {
//      delete graph[key][id];
//    }
//  }
//  for (const key of keys(fresh)) {
//    if (!(key in graph)) {
//      graph[key] = {};
//    }
//    for (const [id, entry] of entries(fresh[key])) {
//      graph[key][id] = entry;
//    }
//  }
//  return [before, graph];
//};
//
//export const accumulate = (graph, ids) => {
//  const { zones = [], nodes = [], users = [], groups = [] } = ids || {};
//  groups.push(
//    ...zones
//      .flatMap(zoneId => getGroupsFromZone(graph, zoneId))
//      .map(group => group.id)
//  );
//  nodes.push(
//    ...groups
//      .flatMap(groupId => getNodesFromGroup(graph, groupId))
//      .map(node => node.id)
//  );
//  users.push(
//    ...groups
//      .flatMap(groupId => getUsersFromGroup(graph, groupId))
//      .map(user => user.member)
//  );
//  return { zones, nodes, users, groups };
//};
