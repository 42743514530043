import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { classNames } from "utils";
import { Cancel, PinLock } from "./icons";
import {
  cancel,
  performLogin,
  sendPinToKeyper,
  selectProcess,
  selectIdentity,
  setView,
  selectView,
  selectProgress,
  setProgress,
  cancelKeyper,
} from "./loginSlice";
import { selectIsMobile } from 'features/layout/layoutSlice';
import { Spinner } from "@blueprintjs/core";
import PinInput from "./pinInput";
import styles from "./Login.module.css";

export const VALID_PIN_REGEX = new RegExp(/^[0-9a-fA-F]$/);
export const PIN_LENGTH = 6;
export const VALID_PIN_REGEX_MOBILE = new RegExp(/^[0-9a-fA-F]{6}$/);

let handleCancelPromise: Promise<void> | null = null;
interface SubmitButtonProps {
  isButtonDisabled: boolean;
  buttonColor: string;
  next: () => void;
  buttonText: string | JSX.Element;
}

export default ({ }) => {
  return (
    <div className={classNames(styles.method, styles.active)}>
      <Form />
    </div>
  );
};

const Form: React.FC = () => {
  const dispatch = useDispatch();
  const process = useSelector(selectProcess);
  const identity = useSelector(selectIdentity);
  const view = useSelector(selectView);
  const progress = useSelector(selectProgress);
  const isMobile = useSelector(selectIsMobile);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0);
  const [pinValue, setPinValue] = useState<string | string[]>(isMobile ? "" : new Array(PIN_LENGTH).fill(''));
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  let email: string = identity[0];
  let phoneNumber: string = "";

  const isPin: boolean = validatePinCode(pinValue);
  const isAttemptsExceeded: boolean = incorrectAttempts >= 3;

  const handleCancel = async () => {
    if (handleCancelPromise !== null) {
      await handleCancelPromise;
    }
    if (isLoading || progress === "WAITING") {
      handleCancelPromise = new Promise<void>((resolve) => {
        handleCancelKeyper();
        resolve();
      }).catch((error) => {
        console.log(error);
      });
    } else {
      handleCancelKeyper();
    }
  };

  const handleCancelKeyper = () => {
    if (view === "PIN2FA") {
      dispatch(cancelKeyper());
      dispatch(setView("HUB"));
    } else {
      dispatch(cancelKeyper());
      dispatch(cancel());
    }
  }

  const cancelPromise = async () => {
    if (handleCancelPromise !== null) {
      await handleCancelPromise;
      handleCancelPromise = null;
    }
  };

  const next = async () => {
    if (view === "PIN2FA") {
      setProgress("WAITING");
    } else {
      setIsLoading(true);
    }

    const pin = preparePinCode(pinValue);
    let isValidated: boolean | undefined;

    try {
      if (process === "LOGIN" && view === "PIN") {
        const { authenticated: isEmailValidated } = await dispatch(sendPinToKeyper({ email, pin }));
        isValidated = !!isEmailValidated;
      } else if (process === "LOGIN" && view === "PIN2FA") {
        phoneNumber = identity[1];
        const { authenticated: isEmailValidated } = await dispatch(sendPinToKeyper({ phoneNumber, pin }));
        isValidated = !!isEmailValidated;
      }
      if (isValidated) {
        if (handleCancelPromise !== null) {
          await cancelPromise();
          return;
        } else if (view === "PIN") {
          setIsLoading(false);
          dispatch(setView("HUB"));
        } else {
          dispatch(performLogin());
        }
      } else {
        setIncorrectAttempts(incorrectAttempts + 1);
        setIsError(true);
        setProgress("");
        setIsLoading(false);
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let buttonText: string | JSX.Element = "";
  let buttonColor: string = "";
  let validationMessage: string = "";
  const isButtonDisabled: boolean = isLoading || !isPin || isAttemptsExceeded || progress === "WAITING";

  if (!isLoading && progress !== "WAITING") {
    if (!isError) {
      buttonText = "submit";
    } else {
      buttonColor = styles.again;
      buttonText = "try again";
    }
  } else if (isLoading || progress === "WAITING") {
    buttonColor = styles.disabled;
    buttonText = <Spinner />;
  }

  if (isAttemptsExceeded && showErrorMessage) {
    validationMessage = "Maximum number of attempts reached. Please generate a new PIN.";
  } else if (showErrorMessage) {
    validationMessage = "Incorrect PIN. Please try again.";
  }

  return (
    <div className={styles.dialog}>
      <div className={styles.header}>
        <PinLock size={34} />
        <div className={styles.name}>Pin Code</div>
        <Cancel size={34} onClick={() => handleCancel()} />
      </div>
      <div className={classNames(styles.middle, isMobile && styles.mobilePinMiddle)}>
        <PinInput
          pinValue={pinValue}
          setPinValue={setPinValue}
          pinLength={PIN_LENGTH}
          isLoading={isLoading}
          isPin={isPin}
          next={next}
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          isAttemptsExceeded={isAttemptsExceeded}
        />
        <div className={styles.warning}>
          <p>
            {validationMessage}
          </p>
        </div>
      </div>
      <SubmitButton
        isButtonDisabled={isButtonDisabled}
        buttonColor={buttonColor}
        next={next}
        buttonText={buttonText}
      />
    </div>
  );
};

const SubmitButton: React.FC<SubmitButtonProps> = ({ isButtonDisabled, buttonColor, next, buttonText }) => {
  return <div className={styles.footer}>
    <div
      className={classNames(
        styles.button,
        styles.green,
        isButtonDisabled && styles.disabled,
        buttonColor
      )}
      onClick={() => next()}
    >
      <span>{buttonText}</span>
    </div>
  </div>;
};

export const validatePinCode = (pinCode: string[] | string): boolean => {
  if (typeof pinCode === "string") {
    return VALID_PIN_REGEX_MOBILE.test(pinCode) && pinCode.length === 6;
  } else {
    return pinCode.every(pinChar => VALID_PIN_REGEX.test(pinChar)) && pinCode.length === 6;
  }
};

export const preparePinCode = (pinCode: string[] | string): string => {
  if (typeof pinCode === "string") {
    const newPinCode = `${pinCode.slice(0, 3)}-${pinCode.slice(3)}`;
    return newPinCode.toUpperCase();
  } else {
    const pinCodeString = pinCode.join("");
    const newPinCodeString = `${pinCodeString.slice(0, 3)}-${pinCodeString.slice(3)}`;
    const pinCodeUppercase = newPinCodeString.toUpperCase();
    return pinCodeUppercase;
  }
};
