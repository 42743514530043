import KeyPair from "../key_pair";
import WebCryptoPublicECDSAKey from "./wc_public_ecdsa_key";
import WebCryptoPublicECDHKey from "./wc_public_ecdh_key";
import WebCryptoPrivateECDSAKey from "./wc_private_ecdsa_key";
import WebCryptoPrivateECDHKey from "./wc_private_ecdh_key";

import { crypto } from "../../crypto";

export default class WebCryptoKeyPair extends KeyPair {
  constructor(publicKey, privateKey) {
    super(publicKey, privateKey);
  }

  static async generateECDSAKeyPair() {
    const keys = await crypto.subtle.generateKey(
      {
        name: "ECDSA",
        namedCurve: "P-256"
      },
      true,
      ["sign", "verify"]
    );

    return new WebCryptoKeyPair(
      new WebCryptoPublicECDSAKey(keys.publicKey),
      new WebCryptoPrivateECDSAKey(keys.privateKey)
    );
  }

  static async generateECDHKeyPair() {
    const keys = await crypto.subtle.generateKey(
      {
        name: "ECDH",
        namedCurve: "P-256"
      },
      true,
      ["deriveKey", "deriveBits"]
    );

    return new WebCryptoKeyPair(
      new WebCryptoPublicECDHKey(keys.publicKey),
      new WebCryptoPrivateECDHKey(keys.privateKey)
    );
  }
}
