import PrivateECDSAKey from "../private_ecdsa_key";

import { importSJCLPrivateKey, exportSJCLPrivateKey } from "./sjcl";
import { Data } from "../../";
import { assertInstanceOf } from "../../util";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLPrivateECDSAKey extends PrivateECDSAKey {
  constructor(key) {
    super(key);
  }

  static async import(key, format) {
    return new SJCLPrivateECDSAKey(
      await importSJCLPrivateKey(key, format, sjcl.ecc.ecdsa)
    );
  }

  async export(format) {
    return exportSJCLPrivateKey(this.key, format);
  }

  async sign(data) {
    assertInstanceOf(Data, data, "data");

    return new Data(
      sjcl.codec.bytes.fromBits(
        this.key.sign(
          sjcl.hash.sha256.hash(sjcl.codec.bytes.toBits(data.getUint8Array()))
        )
      )
    );
  }
}
