import { Label } from "@blueprintjs/core";
import { getCountries } from "libphonenumber-js";
import { useSelector } from "react-redux";
import styles from "../BhDialogs.module.css";
import {
  selectDefaultCountryCode,
  setDefaultCountryCode,
} from "../bullhornSlice.js";

export default ({ dispatch }) => {
  const defaultCountryCode = useSelector(selectDefaultCountryCode);

  return (
    <>
      <Label className={styles.dialogHeading}>Default Country Code</Label>
      <select
        defaultValue={defaultCountryCode.countryCode}
        onChange={(e) => {
          dispatch(setDefaultCountryCode(e.target.value));
        }}
      >
        {getCountries().map((cc) => (
          <option key={cc} value={cc}>
            {cc}
          </option>
        ))}
      </select>
    </>
  );
};
