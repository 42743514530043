import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, TimePrecision } from "@blueprintjs/datetime";
import styles from "./Expire.module.css";
import { Classes, Tag, Intent } from "@blueprintjs/core";
import { ZONE } from "../../app/dictionary.js";
//import { setExpireDate } from "./expireSlice.js";
import { appState } from "../../app/appState.js";

const {
  derive: { getExpireDateFromZone, getNameFromZone },
  selectors: { selectGraph },
  actions: { setExpire }
} = appState;

const today = new Date();
const twentyYears = new Date();
twentyYears.setFullYear(today.getFullYear() + 20);

export default function Expire({ zone, close }) {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const prevDate = getExpireDateFromZone(graph, zone);
  const name = getNameFromZone(graph, zone);
  const [date, setDate] = useState(prevDate && new Date(prevDate));
  const [loading, setLoading] = useState(false);

  const doSave = async () => {
    let myDate = 0;
    if (date) {
      myDate = new Date(+date);
      myDate.setHours(23, 59, 59);
      //myDate.setHours(0, 0, 0);
      myDate = +myDate;
    }
    await dispatch(setExpire({ zoneId: zone, date: myDate }));
    close();
  };

  const zoneText = ZONE[0].toUpperCase() + ZONE.slice(1);

  return (
    <div>
      <Slits>
        <div>
          <DatePicker
            minDate={today}
            maxDate={twentyYears}
            value={date}
            className={Classes.ELEVATION_1}
            onChange={setDate}
          />
        </div>
        <div>
          <div>
            {zoneText}: {name}
          </div>
          <br />
          <div>Delete Files At:</div>
          <br />
          {date ? (
            <Tag intent={Intent.PRIMARY} onRemove={_ => setDate(null)}>
              {date.toLocaleDateString()}
            </Tag>
          ) : (
            <Tag minimal={true}>Never Expire</Tag>
          )}
        </div>
      </Slits>
      <button className={styles.dialogButton} onClick={doSave}>
        {" "}
        Change{" "}
      </button>
    </div>
  );
}

const Slits = ({ children }) => {
  return <div className={styles.slits}>{children}</div>;
};
