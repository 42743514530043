import { keys, values, entries, assign, clone } from "utils";

//import { accumulate } from "./global.js";

//import { moveNodeInGraph } from "./pure.js";

import {
  accumulate,
  getGroupsFromZone,
  getNodesFromGroup,
  getUsersFromGroup,
  //rebalanceIndices,
  moveNodeInGraph,
} from "./derive";

export const initialState = {
  initialized: false,
  categories: [],
  permissions: {},
  timeZero: 0,
  timeSkew: 0,
  graph: {
    zones: {},
    nodes: {},
    users: {},
    groups: {},
    current: {
      expandeds: {},
      zoneId: "",
      groupId: "",
      nodeId: "",
    },
  },
  zonesSortOrder: "date",
  zonesSearchTerm: "",
  nextPageParams: {},
  canLoadMore: true,
};

export default () => {
  const orphans = new Set();
  return {
    consume: (state, { payload: { clean = {}, graph = {} } } /*, ...args*/) => {
      //graph = clone(graph);
      clean = accumulate(state.graph, clean);

      for (const node of values(graph.nodes || {})) {
        if (!node.parent) {
          orphans.add(node.id);
        }
      }

      for (const key of keys(clean)) {
        for (const id of clean[key]) {
          delete state.graph[key][id];
        }
      }

      //const { zones = {}, ...theGraph } = graph;

      //for (const key of keys(theGraph)) {
      //  state.graph[key] = { ...state.graph[key], ...theGraph[key] };
      //}

      //// this is because of expire date
      //for (const id of keys(zones)) {
      //  state.graph.zones[id] = { ...state.graph.zones[id], ...zones[id] };
      //}

      for (const key of keys(graph)) {
        state.graph[key] = { ...state.graph[key], ...graph[key] };
      }

      for (const node of values(state.graph.nodes)) {
        if (orphans.has(node.id)) {
          delete node.parent;
        }
      }
    },
    resetZones: (state, {}) => {
      if (state.graph) {
        state.graph.zones = {};
      }
    },
    initialized: (
      state,
      {
        payload: {
          categories = [],
          permissions = {},
          clock = 0,
          templatesCount = 0,
          timeZero = 0,
        },
      }
    ) => {
      state.initialized = true;
      state.categories = categories;
      state.templatesCount = templatesCount;
      state.permissions = permissions;
      if (clock && state.timeZero) {
        const roundtrip = new Date() - state.timeZero;
        const offset = roundtrip * 0.25;
        state.timeSkew = new Date() - clock - offset;
      }
      if (timeZero) {
        state.timeZero = +new Date();
      }
    },
    addCategory: (state, { payload: category }) => {
      if (category && !state.categories.some(({ id }) => id == category.id)) {
        state.categories.push(category);
      }
    },
    rmCategory: (state, { payload: categoryId }) => {
      state.categories = state.categories.filter(({ id }) => id != categoryId);
    },
    setCategory: (state, { payload: { categoryId, zoneId } }) => {
      let category = null;
      for (const c of state.categories) {
        if ("zones" in c) {
          c.zones = c.zones.filter((id) => id != zoneId);
        }
        if (c.id == categoryId) {
          category = c;
        }
      }
      //const category = state.categories.find(({ id }) => id == categoryId);
      if (category) {
        if ("zones" in category) {
          category.zones.push(zoneId);
        } else {
          category.zones = [zoneId];
        }
      }
    },
    unsetCategory: (state, { payload: { categoryId, zoneId } }) => {
      let category;
      if (categoryId) {
        category = state.categories.find(({ id }) => id == categoryId);
      } else {
        category = state.categories.find(
          (category) => category.zones && category.zones.includes(zoneId)
        );
      }
      if (category && "zones" in category) {
        category.zones = category.zones.filter((id) => id != zoneId);
      }
    },
    //rebalanceIndices: (state, { payload: { nodeId, parentId, index } }) => {
    //  const siblings =
    //  rebalanceIndices(state.graph, nodeId, parentId, index);
    //},
    moveNodeTemp: (state, { payload: { nodeId, parentId, index } }) => {
      moveNodeInGraph(state.graph, nodeId, parentId, index);
    },
    setCurrentZone: (state, { payload: zoneId }) => {
      if (state.graph.current.zoneId != zoneId || !zoneId) {
        state.graph.current.groupId = "";
        state.graph.current.nodeId = "";
        state.graph.current.zoneId = zoneId;
      }
    },
    moveZoneFirst: (state, { payload: zoneId }) => {
      const zone = state.graph.zones[zoneId];
      delete state.graph.zones[zoneId];
      state.graph.zones = { [zoneId]: zone, ...state.graph.zones };
    },
    moveLastZoneFirst: (state) => {
      const [[id, zone]] = entries(state.graph.zones).slice(-1);
      delete state.graph.zones[id];
      state.graph.zones = { [id]: zone, ...state.graph.zones };
    },
    setCurrentNode: (state, { payload: nodeId }) => {
      let zoneId = state.graph.current.zoneId;
      let groupId = state.graph.current.groupId;
      if (nodeId && nodeId in state.graph.nodes) {
        groupId = state.graph.nodes[nodeId].group;
        zoneId = state.graph.groups[groupId].zone;
      } else {
        groupId = null;
      }
      state.graph.current.nodeId = nodeId;
      state.graph.current.groupId = groupId;
      state.graph.current.zoneId = zoneId;
    },
    setExpandedNode: (state, { payload }) => {
      const remove =
        "expanded" in payload
          ? !payload.expanded
          : payload.id in state.graph.current.expandeds;
      if (remove) {
        delete state.graph.current.expandeds[payload.id];
      } else {
        state.graph.current.expandeds[payload.id] = 1;
      }
    },
    resetZonesPageParams: (state, { payload }) => {
      if ("order" in payload) {
        state.zonesSortOrder = payload.order;
      }
      if ("filter" in payload) {
        state.zonesSearchTerm = payload.filter;
      }
      state.nextPageParams = {};
    },
    setNextPageParams: (state, { payload: { paramId, params } }) => {
      state.nextPageParams[paramId] = params;
    },
    setCanLoadMore: (state, { payload: canLoadMore }) => {
      state.canLoadMore = canLoadMore;
    },
  };
};
