import React from "react";

import { classNames } from "utils";

import styles from "./editors-splash-screen.module.css";

export const SplashDoc = () => (
  <div className={styles.styleDoc}>
    <div id="loading-mask" className={styles.loadmask}>
      <div className={styles.brendpanel}>
        <div>
          <div className={styles.spacer} />
          <div className={styles.rect} />
        </div>
        <div>
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
        </div>
      </div>
      <div className={styles.sktoolbar}>
        <ul>
          <li />
          <li className={styles.space} />
          <li style={{ width: "255px" }} />
          <li className={styles.space} />
          <li style={{ width: "180px" }} />
          <li className={styles.space} />
          <li style={{ width: "60px" }} />
          <li className={styles.fat} />
        </ul>
        <ul>
          <li />
          <li className={styles.space} />
          <li style={{ width: "255px" }} />
          <li className={styles.space} />
          <li style={{ width: "180px" }} />
          <li className={styles.space} />
          <li style={{ width: "60px" }} />
        </ul>
      </div>
      <div className={styles.placeholder}>
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </div>
  </div>
);

export const SplashSlides = () => (
  <div className={styles.styleSlides}>
    <div id="loading-mask" className={styles.loadmask}>
      <div className={styles.brendpanel}>
        <div>
          <div className={styles.spacer} />
          <div className={styles.rect} />
        </div>
        <div>
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <div className={styles.spacer} />
        </div>
      </div>
      <div className={styles.sktoolbar}>
        <ul>
          <li />
          <li className={styles.space} style={{ width: "78px" }} />
          <li />
          <li className={styles.space} />
          <li style={{ width: "210px" }} />
          <li className={styles.space} />
          <li style={{ width: "120px" }} />
          <li className={styles.space} style={{ width: "210px" }} />
          <li style={{ width: "100px" }} />
          <li className={styles.fat} />
        </ul>
        <ul>
          <li />
          <li className={styles.space} />
          <li className={styles.big} />
          <li />
          <li className={styles.space} />
          <li style={{ width: "210px" }} />
          <li className={styles.space} />
          <li style={{ width: "120px" }} />
          <li className={styles.space} />
          <li className={styles.big} />
          <li className={styles.big} />
          <li className={styles.big} />
          <li style={{ width: "100px" }} />
        </ul>
      </div>
      <div className={styles.placeholder}>
        <div className={styles.slideH}>
          <div className={styles.slideV}>
            <div className={styles.slideContainer}>
              <div className={styles.line} />
              <div className={classNames(styles.line, styles.empty)} />
              <div className={styles.line} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const SplashSheets = () => (
  <div className={styles.styleSheets}>
    <div id="loading-mask" className={styles.loadmask}>
      <div className={styles.brendpanel}>
        <div>
          <div className={styles.spacer} />
          <div className={styles.rect} />
        </div>
        <div>
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <span className={styles.rect} />
          <div className={styles.spacer} />
        </div>
      </div>
      <div className={styles.sktoolbar}>
        <ul>
          <li />
          <li className={styles.space} />
          <li style={{ width: "240px" }} />
          <li className={styles.space} />
          <li style={{ width: "160px" }} />
          <li className={styles.space} />
          <li style={{ width: "390px" }} />
          <li className={styles.fat} />
        </ul>
        <ul>
          <li />
          <li className={styles.space} />
          <li style={{ width: "240px" }} />
          <li className={styles.space} />
          <li style={{ width: "160px" }} />
          <li className={styles.space} />
          <li style={{ width: "390px" }} />
        </ul>
      </div>
      <div className={styles.skformula}>
        <ul>
          <li />
          <li />
        </ul>
      </div>
      <div className={styles.placeholder}>
        <div className={styles.columns} />
        <div className={styles.columns} />
      </div>
    </div>
  </div>
);
