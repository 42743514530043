import {
  PDFDocument,
  PDFName,
  PDFDict,
  PDFArray,
  PDFStream,
  decodePDFRawStream,
} from "pdf-lib";
import { trial, jwtPeek } from "utils";
import { appendPageToPdf } from "./appendPageToPdf.js";
import { appendPageToPdfExpress } from "./appendPageToPdfExpress.js";
import { API_URL_PREFIX } from "../../../config.js";
import {
  NOT_A_PDF,
  PDF_NOT_SUPPORTED,
  PDF_NOT_A_PACKAGE,
  PACKAGE,
  ORIGINAL,
  METADATA,
  INFOPAGE,
} from "./consts.js";

const fetchArtifact = async (esignature, token) => {
  const headers = {
    "content-type": "application/json",
    accept: "application/json",
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
    const { user } = trial(jwtPeek)(token);
    if (user) {
      headers["user-id"] = user;
    }
  }
  //IF EXPRESS CHANGE URL
  const url = `${API_URL_PREFIX}/esignature/artifact/${esignature.id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  if (response.status != 200) {
    console.log(url, headers);
    throw new Error("failed to fetch esignature artifacts");
  }

  //return await response.json();
  const blob = await response.arrayBuffer();

  if (blob.byteLength) {
    return blob;
  }

  return null;
};

export const toPackage = async (esignature, blob, fileName, token) => {
  let newBlob;

  const mechanism = esignature?.signicatUrl[0]?.signatureType?.mechanism;
  const EXPRESS = Boolean(mechanism === "pkisignature");

  const artifact = await fetchArtifact(esignature, token);

  if (!artifact) {
    throw new Error("no such esignature artifacts found");
  }

  if (EXPRESS) {
    newBlob = await appendPageToPdfExpress(
      esignature,
      fileName,
      blob,
      artifact
    );
  } else {
    newBlob = await appendPageToPdf(esignature, fileName, blob, artifact);
  }

  return newBlob;
};

export const isPdf = async (arr) => {
  if (arr instanceof Blob) {
    arr = await new Response(arr).arrayBuffer();
  }
  if (arr instanceof ArrayBuffer) {
    arr = new Uint8Array(arr);
  }
  if (!(arr instanceof Uint8Array)) {
    return false;
  }
  return arr[0] == 0x25 && arr[1] == 0x50 && arr[2] == 0x44 && arr[3] == 0x46;
};

export const loadPdf = async (buffer) => {
  if (buffer instanceof Blob) {
    buffer = await new Response(buffer).arrayBuffer();
  }

  try {
    return await PDFDocument.load(buffer);
  } catch (e) {
    console.log(e);
    //if (`${e}`.includes("is encrypted")) {
    //  try {
    //    return await PDFDocument.load(buffer, { ignoreEncryption: true });
    //  } catch (e) {
    //    console.log(e);
    //  }
    //} else {
    //  console.log(e);
    //}
  }

  throw PDF_NOT_SUPPORTED;
};

export const getAttachments = async (buffer) => {
  const pdfDoc = await loadPdf(buffer);

  const names = pdfDoc.catalog.lookup(PDFName.of("Names"), PDFDict);
  const embeddedFiles = names.lookup(PDFName.of("EmbeddedFiles"), PDFDict);
  const eFNames = embeddedFiles.lookup(PDFName.of("Names"), PDFArray);
  const attachments = {};

  for (let i = 0, len = eFNames.size(); i < len; i += 2) {
    const fileName = eFNames.lookup(i);
    const fileSpec = eFNames.lookup(i + 1, PDFDict);

    const stream = fileSpec
      .lookup(PDFName.of("EF"), PDFDict)
      .lookup(PDFName.of("F"), PDFStream);

    const name = fileName.decodeText();
    const data = decodePDFRawStream(stream).decode();

    attachments[name] = data;
  }
  return attachments;
};

export const fromPackage = async (esignature, blob) => {
  const buffer = await new Response(blob).arrayBuffer();

  if (!(await isPdf(buffer))) {
    throw NOT_A_PDF;
  }

  let attachments;
  try {
    attachments = await getAttachments(buffer);
  } catch (e) {
    if (e === PDF_NOT_SUPPORTED) {
      throw PDF_NOT_SUPPORTED;
    }
    throw e;
  }

  const signatureDetails = attachments["Signature-Details.pdf"];

  if (signatureDetails && (await isPdf(signatureDetails))) {
    let pdfDoc;
    try {
      pdfDoc = await loadPdf(signatureDetails);
    } catch (e) {
      console.log(e);
    }
    if (pdfDoc) {
      const keywords = pdfDoc.getKeywords();
      if (
        esignature
          ? keywords === "konfident " + esignature.id
          : keywords?.startsWith("konfident ")
      ) {
        return {
          [PACKAGE]: blob,
          [INFOPAGE]: new Blob([signatureDetails]),
          [ORIGINAL]: new Blob([attachments["Original.pdf"]]),
          [METADATA]: new Blob([attachments["ElectronicSignature.xml"]]),
        };
      }
    }
  }

  throw PDF_NOT_A_PACKAGE;
};

export const isPackage = async (esignature, blob) => {
  try {
    await fromPackage(esignature, blob);
  } catch (e) {
    return false;
  }
  return true;
};
