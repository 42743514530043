import { Classes } from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import { classNames } from "utils";
import {
  ARG_DATE,
  initializeDefaultTimestamp,
  handleMinDate,
  handleMaxDate,
  HOURS_AT_MIDNIGHT,
  MINUTES_AT_MIDNIGHT,
  SECONDS_AT_MIDNIGHT,
  MILLISECONDS_AT_MIDNIGHT,
} from "./utils";
import styles from "./Wizard.module.css";

export const Calender = ({ expire, setExpire, service }) => {
  const minDate = handleMinDate();
  const maxDate = handleMaxDate();

  const isSignatureService = service === "signature";

  const handleDateChange = (date) => {
    const selectedDate = date !== null ? new Date(date) : null;
    if (selectedDate !== null) {
      selectedDate.setHours(
        HOURS_AT_MIDNIGHT,
        MINUTES_AT_MIDNIGHT,
        SECONDS_AT_MIDNIGHT,
        MILLISECONDS_AT_MIDNIGHT
      );
    }
    setExpire(selectedDate);
  };

  let displayText;
  if (expire === null) {
    displayText = isSignatureService ? "no date" : "no deadline";
  } else {
    displayText = expire.toLocaleString(...ARG_DATE);
  }

  return (
    <div className={styles.calender}>
      <DatePicker
        className={Classes.ELEVATION_1}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        highlightCurrentDay={true}
      />
      <div className={styles.date}>
        <div
          className={classNames(
            styles.selected,
            styles.deadline,
            expire === null && styles.notSelected
          )}
        >
          <span>Selected deadline</span>
          <p> {displayText}</p>
        </div>
        {isSignatureService && (
          <div className={styles.selected}>
            <span>Default deadline</span>
            <p>{initializeDefaultTimestamp(true)}</p>
          </div>
        )}
      </div>
    </div>
  );
};
