export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const WEEK_IN_MS = 7 * DAY_IN_MS;
export const SAME_TIME_THRESHOLD = 60 * 1000;

export const ARG_TIME = [
  "en-GB",
  {
    hour: "2-digit",
    minute: "2-digit"
  }
];

export const ARG_DATE = [
  "en-GB",
  {
    month: "short",
    day: "numeric",
    year: "numeric"
  }
];

export const ARG_DATE_NO_YEAR = [
  "en-GB",
  {
    month: "short",
    day: "numeric",
    year: undefined
  }
];

export const ARG_DATE_TIME = [
  "en-GB",
  {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  }
];

export const ARG_DATE_TIME_NO_YEAR = [
  "en-GB",
  {
    month: "short",
    day: "numeric",
    year: undefined,
    hour: "2-digit",
    minute: "2-digit"
  }
];

export const ARG_MONTH = [
  "en-GB",
  {
    month: "long",
    year: "numeric"
  }
];

export const ARG_YEAR = [
  "en-GB",
  {
    year: "numeric"
  }
];

export const TIME_PERIOD_THRESHOLDS = {
  today: {
    threshold: _ => new Date().setHours(0, 0, 0, 0),
    label: _ => "Today " + new Date().toLocaleDateString(...ARG_DATE_NO_YEAR),
    format: date => date.toLocaleTimeString(...ARG_TIME)
  },
  "last week": {
    threshold: _ => new Date() - WEEK_IN_MS,
    label: _ =>
      "Last week " +
      new Date(new Date() - WEEK_IN_MS).toLocaleDateString(
        ...ARG_DATE_NO_YEAR
      ) +
      " - " +
      new Date(new Date() - DAY_IN_MS).toLocaleDateString(...ARG_DATE_NO_YEAR),
    format: date => date.toLocaleString(...ARG_DATE_TIME_NO_YEAR) //.replace(", ", " ")
  },
  "last month": {
    threshold: _ =>
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    label: _ =>
      "Last month " +
      TIME_PERIOD_THRESHOLDS["last month"]
        .threshold()
        .toLocaleDateString(...ARG_DATE_NO_YEAR) +
      " - " +
      new Date(new Date() - WEEK_IN_MS - DAY_IN_MS).toLocaleDateString(
        ...ARG_DATE_NO_YEAR
      ),
    format: date => date.toLocaleString(...ARG_DATE_TIME_NO_YEAR) //.replace(", ", " ")
  },
  "x month": {
    threshold: _ => new Date(new Date().getFullYear(), 0),
    label: date => date.toLocaleString(...ARG_MONTH),
    format: date => date.toLocaleString(...ARG_DATE_TIME_NO_YEAR) //.replace(", ", " ")
  },
  "x year": {
    threshold: _ => -1,
    label: date => date.toLocaleString(...ARG_YEAR),
    format: date => date.toLocaleString(...ARG_DATE_TIME) //.replace(", ", " ")
  }
};

export const arrange = (list, home) => {
  if (!list.length) {
    return list;
  }
  list.sort((a, b) => a.seq - b.seq);
  let monologs = [];
  let lastName = list[0].from || "";
  let lastTime = list[0].at || 0;
  const type = lastName == home ? "TIMESTAMP" : "MONOLOG";
  let monolog = [{ type, name: lastName, at: lastTime }, list[0]];
  monologs.push(monolog);
  for (let item of list.slice(1)) {
    let currName = item.from || "";
    let currTime = item.at || 0;
    if (
      lastName != currName ||
      SAME_TIME_THRESHOLD < Math.abs(lastTime - currTime)
    ) {
      const type = currName == home ? "TIMESTAMP" : "MONOLOG";
      monolog = [{ type, name: currName, at: currTime }];
      monologs.push(monolog);
      lastTime = currTime;
    }
    monolog.push(item);
    lastName = currName;
  }
  let periods = {};
  for (let [key, { threshold, label, format }] of Object.entries(
    TIME_PERIOD_THRESHOLDS
  )) {
    for (let i = monologs.length - 1; i >= 0; i--) {
      let monolog = monologs[i];
      let [{ at }] = monolog;
      if (at > threshold()) {
        monolog[0].time = format(new Date(at));
        let theLabel = label(new Date(at));
        if (!(theLabel in periods)) {
          periods[theLabel] = [
            [
              {
                type: "PERIOD",
                label: theLabel,
                order: Object.keys(periods).length
              }
            ]
          ];
        }
        periods[theLabel].splice(1, 0, monolog);
        monologs.splice(i, 1);
      }
    }
  }
  let y = Object.entries(periods);
  y.sort(([, [[{ order: a }]]], [, [[{ order: b }]]]) => b - a);
  let x = y.flatMap(([key, values]) => values.flatMap(value => value));
  return x;
};
