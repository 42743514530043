import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDialog, setDialog } from "../../app/appSlice.js";
import BhCreateZone from "./BhCreateZone";
import BhSyncAddedMembers from "./BhSyncAddedMembers.js";
import BhSyncRemovedMembers from "./BhSyncRemovedMembers.js";
import BhSyncAddedFiles from "./BhSyncAddedFiles.js";
import BhSyncRemovedFiles from "./BhSyncRemovedFiles.js";
import BhConfig from "./config/BhConfig.js";
import BhSelectZone from "./BhSelectZone.js";

export const {
  BH_SELECT_ZONE,
  BH_CREATE_ZONE,
  BH_CONFIG,
  BH_SYNC_ADDED_MEMBERS,
  BH_SYNC_ADDED_FILES,
  BH_SYNC_REMOVED_MEMBERS,
  BH_SYNC_REMOVED_FILES,
} = new Proxy(
  {},
  {
    get: (_, k) => {
      return k;
    },
  }
);

export default () => {
  const dispatch = useDispatch();
  const { dialog, ...args } = useSelector(selectDialog);
  const props = {
    ...args,
    dispatch,
    close: () => dispatch(setDialog()),
  };
  switch (dialog) {
    case BH_SELECT_ZONE:
      return <BhSelectZone active={dialog === BH_SELECT_ZONE} {...props} />;
    case BH_CREATE_ZONE:
      return <BhCreateZone active={dialog === BH_CREATE_ZONE} {...props} />;
    case BH_CONFIG:
      return <BhConfig active={dialog === BH_CONFIG} {...props} />;
    case BH_SYNC_ADDED_MEMBERS:
      return (
        <BhSyncAddedMembers
          active={dialog === BH_SYNC_ADDED_MEMBERS}
          {...props}
        />
      );
    case BH_SYNC_REMOVED_MEMBERS:
      return (
        <BhSyncRemovedMembers
          active={dialog === BH_SYNC_REMOVED_MEMBERS}
          {...props}
        />
      );
    case BH_SYNC_ADDED_FILES:
      return (
        <BhSyncAddedFiles active={dialog === BH_SYNC_ADDED_FILES} {...props} />
      );
    case BH_SYNC_REMOVED_FILES:
      return (
        <BhSyncRemovedFiles
          active={dialog === BH_SYNC_REMOVED_FILES}
          {...props}
        />
      );
    default:
      return null;
  }
};
