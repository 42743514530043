export const SERVER_DENIED_PERMISSION = "SERVER_DENIED_PERMISSION";

export const VERSION = 12; //LEGACY

export let PUBLIC_KEY_AUTH = `-----BEGIN PUBLIC KEY-----
MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEWccMivLu0YlIm82h1P/3Ag3zBWJq
9Z2wicNSx42aDyIJ1GeoMMYpCAnjhMrO3B8eqwOfX+YoK+NnEgVztieLBw==
-----END PUBLIC KEY-----`;

const productionPublic = `-----BEGIN PUBLIC KEY-----
MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEfdSlWJCxqc/JfhuzWsTg3485citD
0df0d5jUd8Z/uuGSikch2TAnj9NZUdwrMgwk/KVdb+NGq6OsnTLB68YsZA==
-----END PUBLIC KEY-----`;

let base,
  front,
  back,
  host = typeof window !== "undefined" && window.location.host,
  environment = "development",
  issuer,
  pkiHost,
  keyperHost,
  rootCertificate,
  stream_host = "konfident.lo",
  stream_pub = "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
  stream_sec = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";

if (!host || host.startsWith("localhost:")) {
  host = "demo.konfident.lo";
  host = "malmo.konfident.lo";
  host = "hykersec.konfident.lo";
}

keyperHost = "keyper.hyker.io";
rootCertificate =
  "MIIBdjCCARsCFD4Yu0BK7qBOeszwbha1nftlPo1XMAoGCCqGSM49BAMCMD0xCzAJ" +
  "BgNVBAYTAlNFMRowGAYDVQQKDBFIeWtlciBTZWN1cml0eSBBQjESMBAGA1UEAwwJ" +
  "S29uZmlkZW50MB4XDTIzMDYxNTA5MzAwNloXDTQzMDYxMDA5MzAwNlowPTELMAkG" +
  "A1UEBhMCU0UxGjAYBgNVBAoMEUh5a2VyIFNlY3VyaXR5IEFCMRIwEAYDVQQDDAlL" +
  "b25maWRlbnQwWTATBgcqhkjOPQIBBggqhkjOPQMBBwNCAATRWmGEElCe2HAn/XVH" +
  "PTlU6qxwPJzihI3tENX3FhelI6Xg2naU7bmxyfs8PY+DFr/X/Ocb7gG6ofhZPPmk" +
  "kbF9MAoGCCqGSM49BAMCA0kAMEYCIQDgqLGO8HZpaduwTtcMBZtIREy2PcPF+Eai" +
  "cgJB8EYzWwIhAJuVHXUcPTvrBqAa9/Gk5tTNfZ/G4BFIKeST6JDnSYNV";

if (host.endsWith("test.konfident.io")) {
  base = "test.konfident.io";
  back = "test.konfident.io";
  front = "test.konfident.io";
  environment = "test";
  stream_host = "test.konfident.io";
  issuer = "test.konfident.io";
  keyperHost = "keyper.test.hyker.io";
  rootCertificate =
    "MIIB2jCCAX+gAwIBAgIUS6mw+u7EIWgf59JBEiOX2PV0IYUwCgYIKoZIzj0EAwIw" +
    "QjELMAkGA1UEBhMCU0UxGjAYBgNVBAoMEUh5a2VyIFNlY3VyaXR5IEFCMRcwFQYD" +
    "VQQDDA5Lb25maWRlbnQgVGVzdDAeFw0yMzExMTcxNDE5MzVaFw0yNDExMTExNDE5" +
    "MzVaMEIxCzAJBgNVBAYTAlNFMRowGAYDVQQKDBFIeWtlciBTZWN1cml0eSBBQjEX" +
    "MBUGA1UEAwwOS29uZmlkZW50IFRlc3QwWTATBgcqhkjOPQIBBggqhkjOPQMBBwNC" +
    "AASatB+ibAt6QIfO6SYPShwf3NwJSaj/nWXdiZMvY7ePMsVqhRUZBR55X/w0uQNL" +
    "zczycjPtVeXFVnym2J4DBshCo1MwUTAdBgNVHQ4EFgQU4dPK0XOBP9qEc9J32iRU" +
    "YIRzmUMwHwYDVR0jBBgwFoAU4dPK0XOBP9qEc9J32iRUYIRzmUMwDwYDVR0TAQH/" +
    "BAUwAwEB/zAKBggqhkjOPQQDAgNJADBGAiEAh6NEk6m9pc32H0ka8KpNbalo4uZi" +
    "nXaKkNrowtXI73oCIQCbkA0lxt73hfYchWY4eWLwFmfDVvTFEwmyb6qg8B3Zjg==";
  pkiHost = "pki.test.konfident.io";
  PUBLIC_KEY_AUTH = productionPublic;
} else if (host.endsWith("canary.konfident.io")) {
  base = "canary.konfident.io";
  back = "canary.konfident.io";
  front = "canary.konfident.io";
  environment = "canary";
  stream_host = "canary.konfident.io";
  issuer = "konfident.io";
  PUBLIC_KEY_AUTH = productionPublic;
} else if (host.endsWith("konfident.io")) {
  base = "konfident.io";
  back = "konfident.io";
  front = "konfident.io";
  environment = "production";
  issuer = "konfident.io";
  stream_host = "konfident.io";
  PUBLIC_KEY_AUTH = productionPublic;
} else if (host.endsWith("secureworkspace.app")) {
  base = "secureworkspace.app";
  back = "konfident.io";
  front = "secureworkspace.app";
  environment = "production";
  issuer = "konfident.io";
  stream_host = "konfident.io";
  PUBLIC_KEY_AUTH = productionPublic;
} else if (host.endsWith("konfident.lo")) {
  base = "konfident.lo";
  back = "konfident.lo";
  front = "konfident.lo";
  environment = "development";
  stream_host = "konfident.lo";
  issuer = "konfident.lo";
  pkiHost = "pki.konfident.lo";
} else {
  throw "bad url authority";
}

export const KEYPER = {
  host: keyperHost,
  pkiHost,
  rootCertificate,
  issuer,
  securityVersion: 0,
  uniqueID: undefined,
  signerID: "sknjUD8gAjVTkLcesaNJpZ5ATn9ZCaZaReupTqI53lQ=",
  productID: "AAAAAAAAAAAAAAAAAAAAAA==",
};

export const SENTRY_ENVIRONMENT = environment;
export const ENCLAVE = "cf3ac19368994a8c8941c9e2d94001d6";
export const APP_ID = "167615f45ec04f74bd531861b8207e1a";
export const ID_SOURCES = [
  "174e04a1f46e41de965bcc1be7619d28",
  "cf3ac19368994a8c8941c9e2d94001d6",
];
export const API_URL_AUTHORITY = host;
export const API_URL_BASE = base;
export const API_URL_BACK = back;
export const API_URL_FRONT = front;
export const API_URL_TOP = base.match(/[^.]+\.[^.]+$/)[0];
export const API_URL_SUB = API_URL_AUTHORITY.slice(0, -base.length - 1);
export const API_URL_PREFIX = `https://${API_URL_BACK}`;
export const API_URL_PREFIX_AUTH = `https://auth.${API_URL_BACK}`;
export const API_URL_PREFIX_NOTIFY = `https://notify.${API_URL_BACK}`;
export const API_URL_PREFIX_NOTIFY_WS = `wss://notify.${API_URL_BACK}`;
export const KONFIDENT_URL = "https://konfident.hyker.io/";

export const STREAMDB = {
  host: stream_host,
  pub: stream_pub,
  sec: stream_sec,
};
