import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, jwtPeek, authReq } from "utils";
import { getToken } from "../../app/topSlice.js";
import { API_URL_PREFIX } from "../../config";

export const muteSlice = createSlice({
  name: "mute",
  initialState: {
    mutedTeams: [],
  },
  reducers: {
    setMutedTeams: (state, { payload }) => {
      state.mutedTeams = payload;
    },
    addMutedTeam: (state, { payload }) => {
      state.mutedTeams = [...state.mutedTeams, payload];
    },
    removeMutedTeam: (state, { payload }) => {
      state.mutedTeams = state.mutedTeams.filter((id) => id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMutedTeams.fulfilled, (state, { payload }) => {
      state.mutedTeams = payload;
    });
  },
});

export const { setMutedTeams, addMutedTeam, removeMutedTeam } =
  muteSlice.actions;

export const fetchMutedTeams = createAsyncThunk(
  "mute/fetchMutedTeams",
  async (_, { dispatch }) => {
    const accessToken = await dispatch(getToken());
    const url = `${API_URL_PREFIX}/muteteam`;
    const { user: userId } = jwtPeek(accessToken);
    return await request(
      url,
      authReq({
        accessToken,
        userId,
      })
    );
  }
);

export const selectMutedTeams = (state) => state.mute?.mutedTeams;

export default muteSlice.reducer;
