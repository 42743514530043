import { useRef, useEffect, useState } from "react";
import { classNames } from "utils";
import styles from "./main.module.css";

interface VerifyingProps {
  verified?: boolean;
  auto?: number;
  size?: number;
  color?: "blue" | "white";
  small?: boolean;
  fast?: boolean;
}

const Verifying =({ verified, auto, size, color, small, fast }: VerifyingProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isVerified, setIsVerified] = useState<boolean | undefined>(verified);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (auto) {
      timeoutRef.current = setTimeout(() => setIsVerified(true), auto);
    }
  }, [auto]);

  const style = size ? { width: `${size}px`, height: `${size}px` } : {};

  const className = classNames(
    styles.spinner,
    isVerified && styles.checked,
    small && styles.small,
    fast && styles.fast,
    color == "blue" && styles.blue,
    color == "white" && styles.white
  );

  const props = { style, className };

  return <div {...props} />;
};

export default Verifying;