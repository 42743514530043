import { request, jwtPeek, authReq, json } from "utils";
import * as Sentry from "@sentry/browser";
import {
  API_URL_PREFIX_AUTH,
  API_URL_PREFIX_NOTIFY,
  API_URL_PREFIX,
  API_URL_AUTHORITY,
  API_URL_SUB,
} from "../config";
import { crypto as cryptoApi, Data } from "hyker-crypto"; //"riks-js";
const { agree, importPublicECDHKey, importPrivateECDHKey } = cryptoApi;

const ACTION_READ = "read";
const ACTION_WRITE = "write";
const ACTION_UPDATE = "update";

const DOWNLOAD_INTENT = "FILE_DOWNLOAD";

export const requestUploadCredentials = async (accessToken, storageId) => {
  try {
    const url = `${API_URL_PREFIX_AUTH}/actions/${ACTION_WRITE}/storage/${storageId}`;
    const { user: userId } = jwtPeek(accessToken);
    return await request(url, authReq({ accessToken, userId }));
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not fetch sts token", e);
    throw e;
  }
};

export const requestUpdateCredentials = async (accessToken, nodeId) => {
  try {
    const url = `${API_URL_PREFIX_AUTH}/actions/${ACTION_UPDATE}/files/${nodeId}`;
    const { user: userId } = jwtPeek(accessToken);
    return await request(url, authReq({ accessToken, userId }));
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not fetch sts token", e);
    throw e;
  }
};

export const requestDownloadCredentials = async (
  accessToken,
  fileId,
  intent = DOWNLOAD_INTENT
) => {
  try {
    const url = `${API_URL_PREFIX_AUTH}/actions/${ACTION_READ}/files/${fileId}/${intent}`;
    const { user: userId } = jwtPeek(accessToken);
    return await request(url, authReq({ accessToken, userId }));
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not fetch sts token", e);
    throw e;
  }
};

export const uploadLogo = async (accessToken, { image }) => {
  const { user: userId, org: orgId } = jwtPeek(accessToken);

  const url = `${API_URL_PREFIX}/profile`;
  return await request(
    url,
    json(
      {
        image,
        orgId,
      },
      {
        accessToken,
        userId,
      }
    )
  );
};

export const upgradeToTwoFA = async (
  accessToken,
  { phoneNumber, email, publicToken }
) => {
  const { user: userId } = jwtPeek(accessToken);
  const url = `${API_URL_PREFIX}/upgradeToTwoFA`;
  return await fetch(
    url,
    json({ userId, phoneNumber, email, publicToken }, { accessToken, userId })
  );
};

export const sendInviteToUser = async (accessToken, userId) => {
  const { user: myUserId } = jwtPeek(accessToken);

  const url = `${API_URL_PREFIX}/invite/send`;
  await request(
    url,
    json(
      {
        userId,
      },
      {
        accessToken,
        userId: myUserId,
      }
    )
  );
};

export const fetchStickyNotifications = async (accessToken) => {
  const { user: userId } = jwtPeek(accessToken);
  const url = new URL(API_URL_PREFIX_NOTIFY);
  url.pathname = "/events/sticky";
  url.searchParams.append("userId", userId);
  return await request(url.href, authReq({ accessToken, userId }));
};

export const fetchNotifications = async (accessToken, { zoneId, lastId }) => {
  const { user: userId } = jwtPeek(accessToken);
  const url = new URL(API_URL_PREFIX_NOTIFY);
  url.pathname = "/events";
  url.searchParams.append("userId", userId);
  if (lastId) {
    url.searchParams.append("lastId", lastId);
  }
  //url.searchParams.append("start", start);
  //url.searchParams.append("end", end);
  if (zoneId) {
    url.searchParams.append("teamId", zoneId);
  }
  return await request(url.href, authReq({ accessToken, userId }));
};

export const requestAccessToken = async (privates, deviceId, targetId = "") => {
  const b642data = (...args) => Data.fromBase64(...args);
  const { privateECDHKey } = JSON.parse(privates);
  try {
    const url = targetId
      ? `${API_URL_PREFIX_AUTH}/debug/auth/${deviceId}/${targetId}`
      : `${API_URL_PREFIX_AUTH}/auth/${deviceId}`; // + "/-"
    const { cipher, iv, publicKey } = await request(url);
    const serverPublic = await importPublicECDHKey(b642data(publicKey), "spki");
    const clientPrivate = await importPrivateECDHKey(privateECDHKey, "jwk");
    const secretKey = await agree(serverPublic, clientPrivate);
    const plainBuffer = await secretKey.decrypt(b642data(cipher), b642data(iv));
    return plainBuffer.toUTF8();
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not fetch jwt token", e);
    throw e;
  }
};

export const requestKeyperAuthToken = async () => {
  try {
    const url = `${API_URL_PREFIX_AUTH}/auth-keyper/${API_URL_SUB}`;
    const { token } = await request(url);
    return token;
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not fetch keyper jwt token", e);
    throw e;
  }
};

export const getUserLogins = async (publicToken) => {
  try {
    const { authInfo, logins } = await request(
      `https://${API_URL_AUTHORITY}/logins`,
      json({ publicToken })
    );
    return { authInfo, logins };
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not get user logins", e);
    throw e;
  }
};

export const fetchIdProviders = async () => {
  try {
    const idProviders = await request(
      `https://${API_URL_AUTHORITY}/id-providers`
    );
    return idProviders;
  } catch (e) {
    Sentry.captureException(e);
    console.error("Could not get user logins", e);
    throw e;
  }
};

//export const setExpire = async (api, accessToken, zoneId, date) => {
//  let expirationDate = null;
//  if (date) {
//    expirationDate = new Date(date) / 1000;
//  }
//
//  const result = await api
//    .token(accessToken)
//    .teams(zoneId)
//    .patch({
//      expirationDate
//    });
//
//  //return result;
//
//  //const newZone = clone(selectGraph(oldState).zones[result.id]);
//  //const graph = {
//  //  zones: { [result.id]: { ...newZone, expirationDate: date } }
//  //};
//
//  //dispatch(consume({ graph }));
//}

//export const createCategory = async (accessToken, name) => {
//  const { user: userId } = trial(jwtPeek)(accessToken);
//
//  console.log(userId);
//
/////  try {
/////    const id = uid();
/////    const result = await api
/////      .token(accessToken)
/////      .categories(id)
/////      .post({
/////        id,
/////        name,
/////        teams: [],
/////        user: { id: userId }
/////      });
//
/////    const category = {
/////      ...without(result, "link"),
/////      user: result.user.id
/////    };
//
/////    return intermediate({ category, pong: ping });
/////  } catch (e) {
/////    if (e.code === 400) {
/////      return intermediate({ fail: true, name: true, pong: ping });
/////    }
/////    return error(e);
/////  }
//
//  return { reason: "what sup/////" };
//}

/*
export const findUserIdsFromDeprecatedId = async deprecatedId => {
  await new Promise(k => setTimeout(k, 333));
  return [];
};

export const permissions = (() => {
  const permissions = {
    isAllowedInTeam: (zoneId, action) => {
      return true;
    }
  };
  return () => {
    return permissions;
  };
})();
*/
