import { Data } from "hyker-crypto";
import createApiClient from "api-client";
import * as config from "../../config";
import { getToken, getContext, selectIdentity } from "../../app/topSlice.js";
import { uuid } from "utils";
import { appState } from "../../app/appState.js";
import { values } from "utils";
import {
  handleFormatExpirationDate,
  HOURS_AT_MIDNIGHT,
  MINUTES_AT_MIDNIGHT,
  SECONDS_AT_MIDNIGHT,
  MILLISECONDS_AT_MIDNIGHT,
} from "../wizard/utils";

const {
  derive: { getGroupFromNode },
  selectors: { selectGraph },
} = appState;

export const getAttestationState =
  (attestationRequest) => (dispatch, getState) => {
    const [
      {
        approvals,
        rejections,
        requester,
        signees,
        info,
        timestamp,
        deadline,
        order,
        current,
      } = {},
    ] = attestationRequest;
    const { id: me } = selectIdentity(getState());
    const { users } = selectGraph(getState());

    const iconApproved = "endorsed";
    const iconRejected = "ban-circle";
    const iconWaiting = "time";

    let stateText = "Not started yet";
    let icon = iconApproved;

    if (attestationRequest.length === 0) {
      return {
        notYetStarted: true,
        icon,
        stateText,
      };
    }

    let requesterName;
    let requesterId;

    if (requester) {
      const user = values(users).find((user) => user.user === requester);
      requesterName = user ? user.name : null;
      requesterId = user ? user.user : null;
    }

    requesterName = requesterName ?? "User removed from team";

    const isOrder = order;
    const isAttester = signees?.map((id) => id).includes(me);
    const isRejected = rejections?.length > 0;
    const isExpired = Date.parse(deadline) < Date.now();

    const completed = approvals?.length === signees?.length;
    const hasAttested = approvals?.map(({ user }) => user).includes(me) ?? [];

    const readyToAttest = isReadyToAttest(
      isAttester,
      hasAttested,
      rejections,
      isOrder,
      info,
      current,
      me,
      completed
    );

    const state = {
      approvals,
      completed,
      timestamp,
      deadline,
      isOrder,
      rejections,
      info,
      requesterName,
      readyToAttest,
      stateText,
      icon,
      isExpired,
      signees,
    };

    switch (true) {
      case isExpired:
        state.stateText = "Has expired";
        state.icon = iconRejected;
        break;
      case completed:
        state.stateText = "Completed";
        state.icon = iconApproved;
        break;
      case isRejected:
        state.stateText = "Was rejected";
        state.icon = iconRejected;
        break;
      case !completed && !isRejected:
        state.stateText = "Waiting to be attested";
        state.icon = iconWaiting;
        break;
      default:
        state.stateText = "Not started yet.";
        state.icon = iconApproved;
    }

    return state;
  };

const isReadyToAttest = (
  isAttester,
  hasAttested,
  rejections,
  isOrder,
  info,
  current,
  me,
  completed
) => {
  const canAttest = isAttester && !hasAttested;
  const isMyTurn = isOrder
    ? info.some(({ order, id }) => order === current && id === me)
    : true;

  return canAttest && !completed && !rejections?.length && isMyTurn;
};

export const doReject =
  (attestationRequest, setLoading) => async (dispatch, getState) => {
    setLoading(true);

    const identity = selectIdentity(getState());
    const attestation = attestationRequest[0];
    const {
      cryptoClient,
      credentials: { privateECDSAKey: key },
    } = await dispatch(getContext());
    const dataToSign = Data.join([
      Data.fromUTF8("REJECT"),
      Data.fromBase64(attestation.tbs),
    ]);
    const signature = (await cryptoClient.sign(key, dataToSign)).signature;

    const rejection = {
      user: {
        id: identity.id,
      },
      signature: signature,
      timestamp: +new Date(),
    };

    const api = createApiClient(config.API_URL_PREFIX);
    const token = await dispatch(getToken());

    try {
      await api
        .token(token)
        .attestations(attestation.id)
        .patch({ rejections: [rejection] });
    } catch (e) {
      throw e; //TODO handle
    }
    setLoading(false);
  };

export const doApprove =
  (nodeId, attestationRequest, setLoading) => async (dispatch, getState) => {
    try {
      setLoading(true);

      const identity = selectIdentity(getState());
      const attestation = attestationRequest[0];

      const api = createApiClient(config.API_URL_PREFIX);
      const token = await dispatch(getToken());

      const {
        cryptoClient,
        credentials: { privateECDSAKey: key },
      } = await dispatch(getContext());

      const dataToSign = Data.join([
        Data.fromUTF8("APPROVE"),
        Data.fromBase64(attestation.tbs),
      ]);

      const signature = (await cryptoClient.sign(key, dataToSign)).signature;

      const approval = {
        user: {
          id: identity.id,
        },
        signature,
        timestamp: +new Date(),
      };

      const { attestations } = await api
        .token(token)
        .files(nodeId)
        .get({
          fields: {
            storageId: true,
            group: true,
            attestations: {
              approvals: true,
              current: true,
            },
          },
        });

      const { approvals = [], current } = attestations[0] ?? {};

      const updatedApprovals = [approval, ...approvals].map(
        ({ user, signature, timestamp }) => ({
          user: { id: user.id },
          signature,
          timestamp,
        })
      );

      await api
        .token(token)
        .attestations(attestation.id)
        .patch({ approvals: updatedApprovals, current: current + 1 });

      setLoading(false);
    } catch (e) {
      throw e;
    }
  };

//REMOVE WHEN NO MORE ATTESTATIONS FROM OLD SYSTEM
export const doApproveDeprecated =
  (attestationNode, setLoading) => async (dispatch, getState) => {
    setLoading(true);
    const identity = selectIdentity(getState());
    const attestation = attestationNode[0];

    const api = createApiClient(config.API_URL_PREFIX);

    try {
      const {
        cryptoClient,
        credentials: { privateECDSAKey: key },
      } = await dispatch(getContext());
      const dataToSign = Data.join([
        Data.fromUTF8("APPROVE"),
        Data.fromBase64(attestation.tbs),
      ]);

      const signature = (await cryptoClient.sign(key, dataToSign)).signature;

      const approval = {
        user: {
          id: identity.id,
        },
        signature: signature,
        timestamp: +new Date(),
      };

      const token = await dispatch(getToken());
      await api
        .token(token)
        .attestations(attestation.id)
        .patch({ approvals: [approval] });
    } catch (e) {
      throw e; // todo
    }
    setLoading(false);
  };

export const doRequest =
  (nodeId, signees, info, order, expire, setLoading) =>
  async (dispatch, getState) => {
    const graph = getState().appstate.graph;
    const groupId = getGroupFromNode(graph, nodeId);
    const identity = selectIdentity(getState());
    const api = createApiClient(config.API_URL_PREFIX);
    const jwt = await dispatch(getToken());

    //const expirationDate = handleFormatExpirationDate(expire);

    let expirationDate;

    if (expire) {
      expire.setHours(
        HOURS_AT_MIDNIGHT,
        MINUTES_AT_MIDNIGHT,
        SECONDS_AT_MIDNIGHT,
        MILLISECONDS_AT_MIDNIGHT
      );
      expirationDate = expire.toISOString();
    }

    const id = uuid();
    const attestation = {
      id,
      tbs: Data.fromUTF8(
        JSON.stringify({
          timestamp: +new Date(),
          requester: identity.id,
          group: groupId,
          file: nodeId,
        })
      ).toBase64(),
      file: {
        id: nodeId,
      },
      requester: { id: identity.id },
      rejections: [],
      approvals: [],
      signees: signees,
      order: order,
      info: info,
      current: 1,
      timestamp: +new Date(),
      ...(expirationDate && { deadline: expirationDate }),
    };
    try {
      setLoading(true);
      const res = await api
        .token(jwt)
        .files(nodeId)
        .attestations(id)
        .post(attestation);
    } catch (e) {
      throw e; //TODO handle
    } finally {
      setLoading(false);
    }
  };

//ONLY FOR TESTING
export const doRequestDeprecated =
  (nodeId, userId, setLoading) => async (dispatch, getState) => {
    const graph = getState().appstate.graph;
    const groupId = getGroupFromNode(graph, nodeId);
    const identity = selectIdentity(getState());
    const api = createApiClient(config.API_URL_PREFIX);
    const jwt = await dispatch(getToken());

    const id = uuid();
    const attestation = {
      id,
      tbs: Data.fromUTF8(
        JSON.stringify({
          timestamp: +new Date(),
          requester: identity.id,
          group: groupId,
          file: nodeId,
        })
      ).toBase64(),
      file: {
        id: nodeId,
      },
      requester: { id: identity.id },
      rejections: [],
      approvals: [],
      signees: [{ id: userId }],
      timestamp: +new Date(),
    };

    try {
      setLoading(true);
      await api.token(jwt).files(nodeId).attestations(id).post(attestation);
    } catch (e) {
      throw e; //TODO handle
    } finally {
      setLoading(false);
    }
  };

export const doFetch =
  (groupId, nodeId, setLoading) => async (dispatch, getState) => {
    setLoading();

    const api = createApiClient(config.API_URL_PREFIX);
    const token = await dispatch(getToken());

    const {
      attestations,
      storageId,
      group: { id: groupId },
    } = await api
      .token(token)
      .files(nodeId)
      .get({
        fields: {
          storageId: true,
          group: true,
          attestations: {
            requester: true,
            signees: true,
            timestamp: true,
            rejections: true,
            approvals: true,
            current: true,
            deadline: true,
            order: true,
            info: true,
            tbs: true,
          },
        },
      });

    const group = await api
      .token(token)
      .groups(groupId)
      .get({
        fields: {
          team: true,
          members: {
            user: {
              name: true,
              provider: true,
              role: true,
            },
          },
        },
      });

    const users = group.members
      .map(({ user }) => user)
      .reduce((a, u) => ({ ...a, [u.id]: u }), {});

    for (const attestation of attestations) {
      if (attestation.requester.id in users) {
        attestation.requester = users[attestation.requester.id];
      }
      for (let i = 0; i < attestation.signees.length; i++) {
        if (attestation.signees[i].id in users) {
          attestation.signees[i] = users[attestation.signees[i].id];
        }
      }
    }

    setLoading(false);
  };

export const doFetchDeprecated =
  (groupId, nodeId, setLoading) => async (dispatch, getState) => {
    setLoading(true);

    const api = createApiClient(config.API_URL_PREFIX);
    const token = await dispatch(getToken());

    const {
      attestations,
      storageId,
      group: { id: groupId },
    } = await api
      .token(token)
      .files(nodeId)
      .get({
        fields: {
          storageId: true,
          group: true,
          attestations: {
            requester: true,
            signees: true,
            timestamp: true,
            rejections: true,
            approvals: true,
            tbs: true,
          },
        },
      });

    const group = await api
      .token(token)
      .groups(groupId)
      .get({
        fields: {
          team: true,
          members: {
            user: {
              name: true,
              provider: true,
              role: true,
            },
          },
        },
      });

    const users = group.members
      .map(({ user }) => user)
      .reduce((a, u) => ({ ...a, [u.id]: u }), {});

    for (const attestation of attestations) {
      if (attestation.requester.id in users) {
        attestation.requester = users[attestation.requester.id];
      }
      for (let i = 0; i < attestation.signees.length; i++) {
        if (attestation.signees[i].id in users) {
          attestation.signees[i] = users[attestation.signees[i].id];
        }
      }
    }

    setLoading(false);
  };
