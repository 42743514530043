export default class Key {
  constructor(key) {
    this.key = key;
  }

  static async generate() {
    throw new Error("Not implemented.");
  }

  static async import(key, format) {
    throw new Error("Not implemented.");
  }

  async export(format) {
    throw new Error("Not implemented.");
  }
}
