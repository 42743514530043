import KeyPair from "../key_pair";
import SJCLPublicECDSAKey from "./sjcl_public_ecdsa_key";
import SJCLPublicECDHKey from "./sjcl_public_ecdh_key";
import SJCLPrivateECDSAKey from "./sjcl_private_ecdsa_key";
import SJCLPrivateECDHKey from "./sjcl_private_ecdh_key";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLKeyPair extends KeyPair {
  constructor(publicKey, privateKey) {
    super(publicKey, privateKey);
  }

  static async generateECDSAKeyPair() {
    let keys = sjcl.ecc.ecdsa.generateKeys(256);
    return new SJCLKeyPair(
      new SJCLPublicECDSAKey(keys.pub),
      new SJCLPrivateECDSAKey(keys.sec)
    );
  }

  static async generateECDHKeyPair() {
    let keys = sjcl.ecc.elGamal.generateKeys(256);
    return new SJCLKeyPair(
      new SJCLPublicECDHKey(keys.pub),
      new SJCLPrivateECDHKey(keys.sec)
    );
  }
}
