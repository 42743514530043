import React, { useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import { Dialog, Icon, Switch, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import { ZONE } from "../../app/dictionary.js";
import styles from "./Dialogs.module.css";
import iconStyles from "../login/icons.module.css";
import { dispatchToast } from "../toasts/Toasts.js";
import { Restrict } from "../login/icons.tsx";
import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";
import Button from "../../helpers/the-button";

const {
  actions: {
    restrictZone,
    unrestrictZone,
    restrictFolder,
    unrestrictFolder,
    setCurrentNode,
  },
} = appState;

export default ({ dispatch, active, close }) => {
  const {
    zoneId,
    zoneName,
    nodeId,
    rootNodeId,
    folder,
    group,
    rootGroupId,
    virtualRootGroupId,
  } = useContext(appContext);

  const inProgress = useRef(false);

  if (!folder) {
    return null;
  }

  let { id: folderId, isRestricted, name } = folder;
  const isZone = folderId == rootNodeId;

  if (folderId == rootNodeId) {
    name = zoneName;
  }

  const doIt = async () => {
    if (inProgress.current) {
      return;
    } else {
      inProgress.current = true;
    }
    const dir = isRestricted ? "Off" : "On";
    const toast = await dispatchToast(dispatch, {
      message: `Turning ${dir}.`,
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    try {
      if (folderId == rootNodeId) {
        if (isRestricted) {
          await dispatch(unrestrictZone({ zoneId }));
        } else {
          await dispatch(restrictZone({ zoneId }));
        }
      } else if (folderId == nodeId) {
        if (isRestricted) {
          await dispatch(unrestrictFolder({ zoneId, nodeId }));
        } else {
          await dispatch(restrictFolder({ zoneId, nodeId }));
        }
        await dispatch(setCurrentNode(nodeId)); //make current(node, group) line up
      }
      await toast.replace({
        message: `Turned ${dir}.`,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
    } catch (e) {
      console.log(e);
      //Sentry.captureException(e);
      await toast.replace({
        message: "Something went wrong, please try again.",
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
    } finally {
      inProgress.current = false;
    }
    close();
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <Restrict size={34} />
          <span className={styles.dialogTitle}>Secret Groups</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <div className={styles.verticalSpace} />
        <div className={styles.yeah}>
          <div>{isRestricted ? "Turn off secrecy for" : "Turn"}</div>
          <div className={styles.pill}>
            <div>
              <Icon
                iconSize={16}
                icon={isZone ? "application" : "folder-open"}
              />
            </div>
            <div>{name}</div>
          </div>
          <div>{isRestricted ? "" : "into a secret group"}</div>
        </div>
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        {isRestricted ? (
          <>
            <p className={styles.usher}>
              <Icon iconSize={20} icon="clean" />
              <b>Contents</b> will be as <b>left as it</b> is right now, but ...
            </p>
            <p className={styles.usher}>
              <Icon iconSize={20} icon="blocked-person" />
              ... <b>who</b> can see it is up to the{" "}
              <b>parent folder or {ZONE}</b>.
            </p>
          </>
        ) : (
          <>
            <p className={styles.usher}>
              <Icon iconSize={20} icon="follower" />
              <b>New</b> users can see <b>nothing</b> inside, unless{" "}
              <b>allowed</b>.
            </p>
            <p className={styles.usher}>
              <Icon iconSize={20} icon="following" />
              <b>Old</b> users will see <b>everything</b>, but can be{" "}
              <b>denied</b>.
            </p>
          </>
        )}
        {!isRestricted && (
          <>
            <div className={styles.verticalSpace} />
            <div className={styles.verticalSpace} />
            <div className={styles.verticalSpace} />
            <p className={styles.cool}>
              <span>Use this</span>
              <Switch defaultChecked={true} />
              <span>to allow or deny users.</span>
            </p>
          </>
        )}
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <button autoFocus onClick={doIt} className={styles.dialogButton}>
          {isRestricted ? "Disable" : "Enable"}
        </button>
        {/*
        <Button
          autoFocus
          onClick={doIt}
          //className={styles.dialogButton}
        >
          {isRestricted ? "Disable" : "Enable"}
        </Button>
        */}
      </div>
    </Dialog>
  );
};
