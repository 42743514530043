import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import { ZoneUsers, FolderUsers } from "../login/icons.tsx";
import appContext from "../../app/appContext.js";
import { formatUser } from "../format/format.js";
import { PANE_CHATS, hidePane } from "../layout/layoutSlice.js";
import { appState } from "../../app/appState.js";
import styles from "./main.module.css";

const {
  derive: { getGroupsFromCurrentZone, getUsersFromGroup },
  actions: { setCurrentNode },
  selectors: { selectGraph, selectNodes },
} = appState;

export default (props) => {
  const dispatch = useDispatch();
  const { zoneName, group, rootGroupId, isRestricted, virtualRootGroupId } =
    useContext(appContext);
  const graph = useSelector(selectGraph);
  const nodes = useSelector(selectNodes);

  if (!group) {
    return null;
  }

  const groupId = group.id;
  const groups = props.group ? [group] : getGroupsFromCurrentZone(graph);

  const options = groups
    .filter((group) => (!isRestricted || group.id != rootGroupId) && group.rootFile in nodes)
    .map((group) => ({
      ...group,
      active: !props.group && group.id == groupId,
      users: getUsersFromGroup(graph, group.id),
    }));

  options.sort(({ active }) => -active);

  return (
    <div className={styles.outer}>
      <div>
        {options.map(({ id, rootFile, active, users }) => {
          let { name } = nodes[rootFile];
          const isZone =
            id == virtualRootGroupId ||
            id == rootGroupId ||
            !(rootFile in nodes);
          //if (id == virtualRootGroupId || id == rootGroupId) {
          if (isZone) {
            name = zoneName;
          }
          return (
            <div
              key={id}
              className={classNames(
                styles.option,
                active && styles.active,
                props.group && styles.inactive
              )}
              onClick={() => {
                if (props.onClick) {
                  props.onClick();
                }
                if (!active) {
                  dispatch(setCurrentNode(rootFile));
                }
              }}
            >
              {!props.group && (
                <div className={styles.group}>
                  {isZone ? <ZoneUsers /> : <FolderUsers />}
                  <span>{name}</span>
                </div>
              )}
              <div className={styles.names}>
                {users
                  .map((user) => [
                    dispatch(formatUser(user)),
                    user.deprecatedId,
                  ])
                  .map(([[[primary, secondary], detailed], uri]) => {
                    return (
                      <div key={uri} className={styles.name} title={detailed}>
                        <span>{primary}</span>
                        {secondary && <span>{secondary}</span>}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
