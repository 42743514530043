import { Data, crypto } from "hyker-crypto";
import { fromBER } from "asn1js";
import { CertificateRevocationList, Certificate } from "pkijs";
const _fetch = typeof window === "undefined" ? require("node-fetch") : fetch;

const INTEL_ROOT_CA = `-----BEGIN CERTIFICATE-----
  MIICjzCCAjSgAwIBAgIUImUM1lqdNInzg7SVUr9QGzknBqwwCgYIKoZIzj0EAwIw
  aDEaMBgGA1UEAwwRSW50ZWwgU0dYIFJvb3QgQ0ExGjAYBgNVBAoMEUludGVsIENv
  cnBvcmF0aW9uMRQwEgYDVQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExCzAJ
  BgNVBAYTAlVTMB4XDTE4MDUyMTEwNDExMVoXDTMzMDUyMTEwNDExMFowaDEaMBgG
  A1UEAwwRSW50ZWwgU0dYIFJvb3QgQ0ExGjAYBgNVBAoMEUludGVsIENvcnBvcmF0
  aW9uMRQwEgYDVQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExCzAJBgNVBAYT
  AlVTMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEC6nEwMDIYZOj/iPWsCzaEKi7
  1OiOSLRFhWGjbnBVJfVnkY4u3IjkDYYL0MxO4mqsyYjlBalTVYxFP2sJBK5zlKOB
  uzCBuDAfBgNVHSMEGDAWgBQiZQzWWp00ifODtJVSv1AbOScGrDBSBgNVHR8ESzBJ
  MEegRaBDhkFodHRwczovL2NlcnRpZmljYXRlcy50cnVzdGVkc2VydmljZXMuaW50
  ZWwuY29tL0ludGVsU0dYUm9vdENBLmNybDAdBgNVHQ4EFgQUImUM1lqdNInzg7SV
  Ur9QGzknBqwwDgYDVR0PAQH/BAQDAgEGMBIGA1UdEwEB/wQIMAYBAf8CAQAwCgYI
  KoZIzj0EAwIDSQAwRgIhAIpQ/KlO1XE4hH8cw5Ol/E0yzs8PToJe9Pclt+bhfLUg
  AiEAss0qf7FlMmAMet+gbpLD97ldYy/wqjjmwN7yHRVr2AM=
  -----END CERTIFICATE-----`;

const INTEL_SGX_PCK_CERTIFICATE = `-----BEGIN CERTIFICATE-----
MIICljCCAj2gAwIBAgIVAJVvXc29G+HpQEnJ1PQzzgFXC95UMAoGCCqGSM49BAMC
MGgxGjAYBgNVBAMMEUludGVsIFNHWCBSb290IENBMRowGAYDVQQKDBFJbnRlbCBD
b3Jwb3JhdGlvbjEUMBIGA1UEBwwLU2FudGEgQ2xhcmExCzAJBgNVBAgMAkNBMQsw
CQYDVQQGEwJVUzAeFw0xODA1MjExMDUwMTBaFw0zMzA1MjExMDUwMTBaMHAxIjAg
BgNVBAMMGUludGVsIFNHWCBQQ0sgUGxhdGZvcm0gQ0ExGjAYBgNVBAoMEUludGVs
IENvcnBvcmF0aW9uMRQwEgYDVQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0Ex
CzAJBgNVBAYTAlVTMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAENSB/7t21lXSO
2Cuzpxw74eJB72EyDGgW5rXCtx2tVTLq6hKk6z+UiRZCnqR7psOvgqFeSxlmTlJl
eTmi2WYz3qOBuzCBuDAfBgNVHSMEGDAWgBQiZQzWWp00ifODtJVSv1AbOScGrDBS
BgNVHR8ESzBJMEegRaBDhkFodHRwczovL2NlcnRpZmljYXRlcy50cnVzdGVkc2Vy
dmljZXMuaW50ZWwuY29tL0ludGVsU0dYUm9vdENBLmRlcjAdBgNVHQ4EFgQUlW9d
zb0b4elAScnU9DPOAVcL3lQwDgYDVR0PAQH/BAQDAgEGMBIGA1UdEwEB/wQIMAYB
Af8CAQAwCgYIKoZIzj0EAwIDRwAwRAIgXsVki0w+i6VYGW3UF/22uaXe0YJDj1Ue
nA+TjD1ai5cCICYb1SAmD5xkfTVpvo4UoyiSYxrDWLmUR4CI9NKyfPN+
-----END CERTIFICATE-----`;

const INTEL_QUOTE_VERSION = 3;
const OE_SGX_PCK_ID_PCK_CERT_CHAIN = 5;

const importPEM = (pem) => {
  if (typeof pem !== "string") {
    throw new Error(`Certificate.importPEM expects a string. (got ${pem})`);
  }

  const berString = pem.match(
    /(?:-+BEGIN CERTIFICATE-+)([\s\S]+?)(?:-+END CERTIFICATE-+)/i
  );
  if (!berString) throw new Error("Bad PEM.");

  const berData = Data.fromBase64(berString[1].replace(/\s/g, ""));

  return new Certificate({
    schema: fromBER(berData.createArrayBuffer()).result,
  });
};

function parseReportBody(seeker) {
  //  u8[16] cpusvn
  //  u32    miscselect
  //  u8[28] reserved
  //  struct attributes
  //  u8[32] mrenclave
  //  u8[32] reserved
  //  u8[32] mrsigner
  //  u8[96] reserved
  //  u16    isvprodid
  //  u16    isvsvn
  //  u8[60] reserved
  //  u8[64] report_data

  const raw = seeker.extract(
    16 + 4 + 28 + 8 + 8 + 32 + 32 + 32 + 96 + 2 + 2 + 60 + 64
  );
  seeker = new Seeker(raw);

  const cpusvn = seeker.extract(16);
  const miscselect = seeker.extractLEU32();
  /* reserved */ seeker.skip(28);
  const attributes = (() => {
    //  u64 flags
    //  u64 xfrm

    const flags = seeker.extractLEU64();
    const xfrm = seeker.extractLEU64();

    return {
      flags,
      xfrm,
    };
  })();
  const mrenclave = seeker.extract(32);
  /* reserved */ seeker.skip(32);
  const mrsigner = seeker.extract(32);
  /* reserved */ seeker.skip(96);
  const isvprodid = seeker.extractLEU16();
  const isvsvn = seeker.extractLEU16();
  /* reserved */ seeker.skip(60);
  const reportData = seeker.extract(64);

  return {
    cpusvn,
    miscselect,
    attributes,
    mrenclave: mrenclave,
    mrsigner: mrsigner,
    productID: new Data(
      new Uint8Array([
        isvprodid & 0x00ff,
        (isvprodid & 0xff00) >> 8,
        // OpenEnclave product ID is 16 bytes, pad with zeros.
        ...new Array(14).fill(0x00),
      ])
    ),
    securityVersion: isvsvn,
    reportData,
    signedData: raw,
  };
}

async function parseSignature(seeker) {
  // u8[32] r
  // u8[32] s

  // See https://tools.ietf.org/html/rfc3278#section-8.2 for more information.

  const r = seeker.extract(32);
  const s = seeker.extract(32);

  return Data.join([r, s]);
}

async function parsePublicECDSAKey(seeker) {
  // u8[32] x
  // u8[32] y

  const x = seeker.extract(32);
  const y = seeker.extract(32);

  return {
    key: await crypto.importPublicECDSAKey(Data.join([[0x04], x, y]), "spki"),
    raw: Data.join([x, y]),
  };
}

async function parseReport(report) {
  const seeker = new Seeker(report);

  return await (async () => {
    //  u16                version
    //  u16                sign_type
    //  u8[4]              reserved
    //  u16                qe_svn
    //  u16                pce_svn
    //  u8[16]             uuid
    //  u8[20]             user_data
    //  struct             sgx_report_body
    //  u32                signature_size
    //  u8[signature_size] signature

    const pos1 = seeker.pos;
    const version = seeker.extractLEU16();
    const signType = seeker.extractLEU16();
    /* reserved */ seeker.skip(4);
    const qeSVN = seeker.extractLEU16();
    const pceSVN = seeker.extractLEU16();
    const uuid = seeker.extract(16);
    const userData = seeker.extract(20);
    const reportBody = parseReportBody(seeker);
    const signedData = report.slice(pos1, seeker.pos);
    const signatureSize = seeker.extractLEU32();
    const quoteAuthData = await (async () => {
      //  u8[64] signature
      //  u8[64] attestation_key
      //  struct qe_report_body
      //  u8[64] qe_report_body_signature

      const signature = await parseSignature(seeker);
      const attestationKey = await parsePublicECDSAKey(seeker);
      const qeReportBody = parseReportBody(seeker);
      const qeReportBodySignature = await parseSignature(seeker);

      return {
        signature,
        attestationKey,
        qeReportBody,
        qeReportBodySignature,
      };
    })();

    const qeAuthData = (() => {
      // u16      size
      // u8[size] data

      const size = seeker.extractLEU16();
      const data = seeker.extract(size);

      return data;
    })();

    const qeCertData = (() => {
      // u16      type
      // u32      size
      // u8[size] data

      const type = seeker.extractLEU16();
      const size = seeker.extractLEU32();
      const data = seeker.extract(size);

      return {
        type,
        data,
      };
    })();

    if (seeker.remaining > 0)
      throw Error(`${seeker.remaining} bytes still remaining in report.`);

    return {
      version,
      signType,
      qeSVN,
      pceSVN,
      uuid,
      userData,
      reportBody,
      quoteAuthData,
      qeAuthData,
      qeCertData,
      signedData,
    };
  })();
}

export default async (
  report,
  { securityVersion = 0, uniqueID, signerID, productID }
) => {
  // Parse report
  const quote = await parseReport(report);

  // Sanity check
  if (quote.version != INTEL_QUOTE_VERSION)
    throw Error(
      `Unexpected quote version ${quote.version}. Expected ${INTEL_QUOTE_VERSION}.`
    );
  if (quote.qeCertData.type != OE_SGX_PCK_ID_PCK_CERT_CHAIN)
    throw Error(`Missing certificate chain.`);
  if (!quote.qeCertData.data) throw Error(`Missing certificate.`);

  // Parse certificate chain
  const certificateChain = quote.qeCertData.data
    .toUTF8()
    .match(/(-+BEGIN CERTIFICATE-+[\s\S]+?-+END CERTIFICATE-+)/g);
  for (let i = 0; i < certificateChain.length; ++i) {
    certificateChain[i] = crypto.Certificate.importPEM(certificateChain[i]);
  }

  // const crls = [
  //   new CertificateRevocationList({
  //     schema: fromBER(
  //       Buffer.from(
  //         await (
  //           await _fetch(
  //             "https://certificates.trustedservices.intel.com/IntelSGXRootCA.der"
  //           )
  //         ).arrayBuffer()
  //       )
  //         .toString("base64")
  //         .getUint8Array()
  //     ).result,
  //   }),
  //   new CertificateRevocationList({
  //     schema: fromBER(
  //       Buffer.from(
  //         await (
  //           await _fetch(
  //             "https://api.trustedservices.intel.com/sgx/certification/v3/pckcrl?ca=platform&encoding=der"
  //           )
  //         ).arrayBuffer()
  //       )
  //         .toString("base64")
  //         .getUint8Array()
  //     ).result,
  //   }),
  // ];

  const rootY = importPEM(INTEL_ROOT_CA);
  const pckRoot = importPEM(INTEL_SGX_PCK_CERTIFICATE);

  // for (const crl of crls) {
  //   if (new Date(crl.thisUpdate.value).getTime() > new Date().getTime()) {
  //     throw new Error("CRL is not yet valid");
  //   }
  //   if (new Date(crl.nextUpdate.value).getTime() < new Date().getTime()) {
  //     throw new Error("CRL is old");
  //   }
  // }

  // const verified = await Promise.all([
  //   crls[0].verify({ issuerCertificate: rootY }),
  //   crls[1].verify({ issuerCertificate: pckRoot }),
  //   crls[0].verify({ issuerCertificate: pckRoot }),
  //   crls[1].verify({ issuerCertificate: rootY }),
  // ]);

  // if (!((verified[0] && verified[1]) || (verified[2] && verified[3]))) {
  //   throw Error("Could not verify CRLs");
  // }

  // Verify certificate chain
  if (
    !(await new crypto.Certificate(rootY).validateChain(
      certificateChain.slice().reverse()
      // crls
    ))
  ) {
    throw Error(`Failed to verify certificate chain.`);
  }

  // Verify signature
  if (
    !(await (
      await certificateChain[0].getPublicKey()
    ).verify(
      quote.quoteAuthData.qeReportBody.signedData,
      quote.quoteAuthData.qeReportBodySignature
    ))
  ) {
    throw Error(`Report body signature could not be verified.`);
  }

  // Verify hash
  if (
    !(
      await crypto.sha256(
        Data.join([quote.quoteAuthData.attestationKey.raw, quote.qeAuthData])
      )
    ).equals(quote.quoteAuthData.qeReportBody.reportData.slice(0, 32))
  ) {
    throw Error(`Hash mismatch.`);
  }

  // Verify quote signature
  if (
    !(await quote.quoteAuthData.attestationKey.key.verify(
      quote.signedData,
      quote.quoteAuthData.signature
    ))
  ) {
    throw Error(`Quote signature could not be verified.`);
  }

  // Verify unique ID
  if (
    uniqueID &&
    !Data.fromBase64(uniqueID).equals(quote.reportBody.mrenclave)
  ) {
    throw Error(`Unique ID mismatch.`);
  }

  // Verify signer ID
  if (
    signerID &&
    !Data.fromBase64(signerID).equals(quote.reportBody.mrsigner)
  ) {
    throw Error(`Signer ID mismatch.`);
  }

  // Verify product ID
  if (
    productID &&
    !Data.fromBase64(productID).equals(quote.reportBody.productID)
  ) {
    throw Error(`Product ID mismatch.`);
  }

  // Verify security version
  if (quote.quoteAuthData.qeReportBody.securityVersion < securityVersion) {
    throw Error(`Security version is out of date.`);
  }

  return quote.reportBody;
};

const unpackLENumber = (bytes) => {
  return bytes.getUint8Array().reduceRight((acc, byte) => (acc << 8) + byte, 0);
};

class Seeker {
  constructor(data) {
    this.pos = 0;
    this.remaining = data.length;
    this.data = data;
  }

  extract(length) {
    if (this.pos + length > this.data.length) {
      throw new Error(
        `Can"t extract: Out of bounds. (pos=${this.pos}, len=${length}, available=${this.remaining})`
      );
    }
    const data = this.data.slice(this.pos, this.pos + length);
    this.pos += length;
    this.remaining -= length;
    return data;
  }

  extractLEU16() {
    return unpackLENumber(this.extract(2));
  }

  extractLEU32() {
    return unpackLENumber(this.extract(4));
  }

  extractLEU64() {
    return unpackLENumber(this.extract(8));
  }

  skip(length) {
    this.pos += length;
    this.remaining -= length;
  }
}
