import * as pdfjsLib from "pdfjs-dist/build/pdf";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer";

import { fromFile } from "./utils";

const PDF_WORKER_URL = "/pdf.worker.min.js";
const PDF_WORKER_CHECKSUM = "dxJKuMNJ4hbGl5zzDht8rQcpM9LT31TXsaIuO7sefwk=";

const toChecksum = async (buf) => {
  return new Uint8Array(await crypto.subtle.digest("SHA-256", buf));
};

const toBase64 = (uint8Array) =>
  new Promise((resolve, reject) => {
    try {
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });
      const fileReader = new FileReader();
      fileReader.addEventListener("error", (ev) => reject(ev.target.error));
      fileReader.addEventListener("load", () =>
        resolve(fileReader.result.substr(fileReader.result.indexOf(",") + 1))
      );
      fileReader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });

// TODO probably load css using intert style tag in head to make it lazy
export const createBackend = (container, blob) => {
  let theEventBus;
  let thePdfViewer;

  const create = async () => {
    const bytes = await fromFile(blob);
    const res = await fetch(PDF_WORKER_URL);
    const workerContent = await res.arrayBuffer();
    const checksum = await toBase64(await toChecksum(workerContent));

    if (checksum !== PDF_WORKER_CHECKSUM) {
      console.log(`${checksum} !== ${PDF_WORKER_CHECKSUM}`);
      throw new Error("failed to verify integrity with fetching pdfjs worker");
    }

    let workerBlob = new Blob([workerContent], { type: "text/javascript" });
    let workerBlobURL = URL.createObjectURL(workerBlob);
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerBlobURL;

    //| LATSTA
    //|
    //| // To test the AcroForm and/or scripting functionality, try e.g. this file:
    //|   "../../test/pdfs/160F-2019.pdf"
    //| const DEFAULT_URL = "../../web/compressed.tracemonkey-pldi-09.pdf";

    //| const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
    //| const CMAP_PACKED = true;
    //| const ENABLE_XFA = true;
    //| const SEARCH_FOR = ""; // try "Mozilla";
    //| const SANDBOX_BUNDLE_SRC = "../../node_modules/pdfjs-dist/build/pdf.sandbox.js";

    const eventBus = new pdfjsViewer.EventBus();

    //| // (Optionally) enable hyperlinks within PDF files.
    //| const pdfLinkService = new pdfjsViewer.PDFLinkService({
    //|   eventBus,
    //| });
    //| // (Optionally) enable find controller.
    //| const pdfFindController = new pdfjsViewer.PDFFindController({
    //|   eventBus,
    //|   linkService: pdfLinkService,
    //| });
    //| // (Optionally) enable scripting support.
    //| const pdfScriptingManager = new pdfjsViewer.PDFScriptingManager({
    //|   eventBus,
    //|   sandboxBundleSrc: SANDBOX_BUNDLE_SRC,
    //| });

    const pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      //| linkService: pdfLinkService,
      //| findController: pdfFindController,
      //| scriptingManager: pdfScriptingManager,
    });

    //| pdfLinkService.setViewer(pdfViewer);
    //| pdfScriptingManager.setViewer(pdfViewer);

    eventBus.on("pagesinit", function () {
      theEventBus = eventBus;
      thePdfViewer = pdfViewer;
      //pdfViewer.currentScaleValue = "page-width";
      //pdfViewer.currentScaleValue = 1;

      //| // We can use pdfViewer now, e.g. let's change default scale.
      //| pdfViewer.currentScaleValue = "page-width";

      //| // We can try searching for things.
      //| if (SEARCH_FOR) {
      //|   eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
      //| }
    });

    const loadingTask = pdfjsLib.getDocument({
      data: bytes,
      //| cMapUrl: CMAP_URL,
      //| cMapPacked: CMAP_PACKED,
      //| enableXfa: ENABLE_XFA,
    });

    const pdfDocument = await loadingTask.promise;
    pdfViewer.setDocument(pdfDocument);
    //| pdfLinkService.setDocument(pdfDocument, null);
  };

  let destroyed = false;

  const destroy = () => {
    if (!destroyed) {
      destroyed = true;
      //try {
      //  container.innerHTML = "";
      //} catch (e) {
      //}
    }
  };

  const getPageNods = () =>
    document.querySelectorAll(".mozillaPdfJsRoot [data-page-number]");

  const gotoPage = (n) => {
    getPageNods()[n]?.scrollIntoView();
  };

  let currentValue = 1;

  const zoomOut = () => {
    let intervalValue = 0.25;
    if (currentValue > 3) {
      intervalValue = 1;
    } else if (currentValue > 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.max(0.25, currentValue - intervalValue);
    thePdfViewer.currentScaleValue = currentValue;
  };

  const zoomIn = () => {
    let intervalValue = 0.25;
    if (currentValue >= 3) {
      intervalValue = 1;
    } else if (currentValue >= 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.min(4, currentValue + intervalValue);
    thePdfViewer.currentScaleValue = currentValue;
  };

  // TODO current page is not updated on scoll
  let currentPage = 0;

  const navPrev = () => {
    //const pages = getPageNods();
    //if (currentPage === 0) {
    //  return;
    //}
    //gotoPage(--currentPage, pages);
  };

  const navNext = () => {
    //const pages = getPageNods();
    //if (currentPage === pages.length - 1) {
    //  return;
    //}
    //gotoPage(++currentPage, pages);
  };

  const navEnd = () => {
    const pages = getPageNods();
    //if (currentPage === pages.length - 1) {
    //  return;
    //}
    currentPage = pages.length - 1;
    gotoPage(currentPage, pages);
  };

  return {
    promise: create(),
    destroy,
    zoomOut,
    zoomIn,
    navPrev,
    navNext,
    navEnd,
  };
};
