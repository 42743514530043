import styles from "./Dialogs.module.css";
import { Dialog, Icon, Spinner } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { classNames } from "utils"
import { format } from "../invite/2fa";
import { TwoFA } from "features/login/icons";
import inviteStyles from "../invite/index.module.css";
import { upgradeToTwoFA } from "../../app/realworld";
import { 
  sendEmailToKeyper, 
  sendPhoneNumberToKeyper, 
  sendPinToKeyper, 
  closeKeyper,
} from "features/login/loginSlice";
import PinInput from "features/login/pinInput";
import { PIN_LENGTH, validatePinCode, preparePinCode } from "features/login/pin";
import { getToken } from "../../app/topSlice.js";
import { useSelector } from "react-redux";
import { selectIsMobile } from 'features/layout/layoutSlice';

interface UpgradeTo2faProps {
  dispatch: any;
  active: boolean;
  close: () => void;
  email: string;
}

enum View {
  PhoneNumberView,
  LoadingViewPhoneNumber,
  PinPhoneView,
  LoadingViewPhonePin,
  PinEmailView,
  LoadingViewEmailPin,
  ResultView,
}

const UpgradeTo2fa: React.FC<UpgradeTo2faProps> = ({
  dispatch,
  active,
  close,
  email
}) => {
  const [numberOkState, setNumberOk] = useState(false);
  const [countryState, setCountry] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [number, setNumber] = useState("");
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  const [timerTime, setTimerTime] = useState(0);
  const [currentView, setCurrentView] = useState<View>(View.PhoneNumberView);
  const [successUpgrading, setSuccessUpgrading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isMobile = useSelector(selectIsMobile);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const isLoadingView = 
      currentView === View.LoadingViewPhoneNumber || 
      currentView === View.LoadingViewPhonePin || 
      currentView === View.LoadingViewEmailPin;
    if (isLoadingView && loadingTimeout) {
      setLoadingTimeout(false);
      timer = setTimeout(() => setCurrentView(currentView + 1), timerTime);
    }
    if (timer) {
      return () =>  {
        clearTimeout(timer)
      }
    }
  }, [setCurrentView, currentView, timerTime]);

  const submitPhoneNumber = () => {
    if (!numberOkState) {
      setShowErrors(true);
      return;
    }
    const phoneNumber = number.replace(/\s/g, "");
    dispatch(sendPhoneNumberToKeyper({ phoneNumber }));
    setLoadingTimeout(true);
    setTimerTime(2000);
    setCurrentView(View.LoadingViewPhoneNumber);
  };

  const submitPhonePin = async (pin) => {
    const phoneNumber = number.replace(/\s/g, "");
    try {
      const { authenticated } = await dispatch(
        sendPinToKeyper({ phoneNumber, pin })
      );
      if (!!authenticated) {
        dispatch(sendEmailToKeyper({ email }));
        setLoadingTimeout(true);
        setTimerTime(4000);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const submitEmailPin = async (pin) => {
    const { authenticated, publicToken } = await dispatch(sendPinToKeyper({ email, pin }));
    
    if (!authenticated) { return false; }

    setLoadingTimeout(true);
    setTimerTime(2000);
    const phoneNumber = number.replace(/\s/g, "");
    const accessToken = await dispatch(getToken());
    try {
      const response = await upgradeToTwoFA(accessToken, { phoneNumber, email, publicToken });
      const json = await response.json();
      if (response.status !== 200) {
        throw new Error(json.error);
      }
      setSuccessUpgrading(true);
    } catch (e: Error) {
      setSuccessUpgrading(false);
      if (e.message === "DeprecatedId already exists in org") {
        setErrorMessage("This 2FA user already exists");
      }
      console.log(e);
    }
    return true;
  }

  const phoneNumberView = () => {
    return (
      <div style={{ 
        height: "100%", 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "flex-end", 
        gap: "20px" 
      }}>
        <div className={inviteStyles.inputPhone}>
          <input
            type="text"
            placeholder="Phone Number (+46 70 123 56 78)"
            className={classNames(
              inviteStyles.input,
              showErrors && !numberOkState && inviteStyles.error
            )}
            value={number}
            onChange={(e) => {
              setShowErrors(false);
              const element = e.target;
              let caret = element.selectionStart || 0;
              let original: string = element.value;
              let formated;
              let country: string | undefined;
              let numberOk;

              if (original == "+") {
                formated = original;
              } else {
                for (let i = 0; i < caret; i++) {
                  const letter = original[i];
                  if (!/[0-9]/.test(letter)) {
                    original = [...original];
                    original.splice(i--, 1);
                    original = original.join("");
                    caret--;
                  }
                }
                original = original.replaceAll(/[^0-9]/g, "");
                if (original.length) {
                  original = "+" + original;
                  caret++;
                }
                formated = format(original);
                country = formated.country;
                numberOk = formated.valid;
                formated = formated.number;
                for (let i = 0; i < caret; i++) {
                  const letter = formated[i];
                  if (letter == " ") {
                    caret++;
                  }
                }
              }

              window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });

              setCountry(country || "");
              setNumberOk(numberOk || false);
              setNumber(formated);
            }}
            onKeyDown={(e) => e.key == "Enter" && submitPhoneNumber()}
          />
          <div>{countryState}</div>
        </div>
        <div style={{display: "flex", justifyContent: "end"}}>
          <button
            className={inviteStyles.dialogButton}
            onClick={() => submitPhoneNumber()}
            disabled={!numberOkState}
           >   
            Submit Phone number
          </button>
        </div>
      </div>
    );
  }

  const PinView = ({ submit, buttonText, nextView }) => {
    const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0);
    const [pinValue, setPinValue] = useState<string | string[]>(
      isMobile ? "" : new Array(PIN_LENGTH).fill('')
    );
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    useEffect(() => {
      return () => {
        setPinValue(new Array(PIN_LENGTH).fill(''));
        setShowErrorMessage(false);
      }
    }, []);

    const isAttemptsExceeded: boolean = incorrectAttempts >= 3;
    const isPin: boolean = validatePinCode(pinValue);
    let errorMessage = ""; 
    
    if (isAttemptsExceeded && showErrorMessage) {
      errorMessage = "Maximum number of attempts reached. Please generate a new PIN.";
    } else if (showErrorMessage) {
      errorMessage = "Incorrect PIN. Please try again.";
    }

    const handleSubmit = async () => {
      const pin = preparePinCode(pinValue);
      if (!isPin) {
        return;
      }  
      const authenticated = await submit(pin);
      if (authenticated) {
        nextView();
      } else {
        setIncorrectAttempts(incorrectAttempts + 1);
        setShowErrorMessage(true);
      }
    }

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end", gap: "20px" }}>
        <div className={styles.pinContainer}>
          <PinInput
            pinValue={pinValue}
            setPinValue={setPinValue}
            pinLength={PIN_LENGTH}
            isLoading={false}
            isPin={isPin}
            next={handleSubmit}
            isAttemptsExceeded={isAttemptsExceeded}
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
          />
        </div>
        <div style={{ height: "18px", color: "red", textAlign: "center"}}>
          {errorMessage}
        </div>
        <div style={{display: "flex", justifyContent: "end"}}>
          <button
            className={inviteStyles.dialogButton}
            onClick={handleSubmit}
            disabled={!isPin || isAttemptsExceeded}
          >
            {buttonText}
          </button>
        </div>
      </div>

    );
  }

  const resultText = () => {
    if (successUpgrading) {
      return (
        <>
          You have successfully upgraded your account to two-factor authentication
        </>
      ); 
    } else {
      return (
        <>
          <Icon icon="delete" size={22} style={{ color: "#ff0000", marginRight: "5px" }} />
          {errorMessage ? errorMessage : "Something went wrong. Please try again."}
        </>
      );
    }
  }

  const resultView = () => {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end", gap: "40px" }}>
        <div style={{ fontSize: "18px", paddingLeft: "20px", paddingRight: "60px"}}>
          {resultText()}
        </div>
        <div style={{display: "flex", justifyContent: "end"}}>
          <button
            className={inviteStyles.dialogButton}
            onClick={() => close()}
          >
            Done
          </button>
        </div>
      </div>
    );
  }

 const displayCurrentView = () => {
    switch (currentView) {
      case View.PhoneNumberView:
        return phoneNumberView()
      case View.PinPhoneView:
        return (
          <PinView 
            submit={submitPhonePin} 
            buttonText={"Submit phone pin"}
            nextView={() => setCurrentView(View.LoadingViewPhonePin)}
          />
        )
      case View.LoadingViewPhoneNumber:
      case View.LoadingViewPhonePin:
      case View.LoadingViewEmailPin:
        return (
          <div style={{height: "100%", display: "flex", justifyContent: "center"}}>
            <Spinner />
          </div>
        )
      case View.PinEmailView:
        return (
          <PinView 
            submit={submitEmailPin} 
            buttonText={"Submit email pin"}
            nextView={() => setCurrentView(View.LoadingViewEmailPin)}
          />
        )
      case View.ResultView:
        return resultView()
    }
  }

  const stepListItem = (text: string, done: boolean, current: boolean) => {
    return (
      <li style={{ display: "flex" }}>
        <div style={{ minWidth: "40px", textAlign: "center" }}>
          
          {done && <Icon icon="tick-circle" style={{ color: "#00c853" }} />}
        </div>
        <span>{text}</span>
      </li>
    );
  }

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      onClosed={async () => {
        setNumber("");
        setErrorMessage("");
        if (currentView > View.PhoneNumberView) {
          await dispatch(closeKeyper());
          setCurrentView(View.PhoneNumberView)
        }
      }}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      className={styles.dialog}
      style={{ width: "560px", height: "422px" }}
      title={
        <div className={styles.title}>
          <TwoFA size={34} />
          <span className={styles.dialogTitle}>Upgrade your accont to 2FA</span>
        </div>
      }
    >
      <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
        <div>
          <div style={{ paddingTop: "20px"}}>To upgrade your accont from email authentication to 2 factor authentification please proceed with these four steps: </div>
          <ol style={{ listStyle: "none", margin: "20px 0" }}>
            {stepListItem("1. Enter your phone number and await the security code sent via SMS", currentView > View.PhoneNumberView, currentView === View.PhoneNumberView)}
            {stepListItem("2. Enter the security code received via SMS", currentView > View.PinPhoneView, currentView === View.PinPhoneView)}
            {stepListItem("3. Await the security code delivered to your email", currentView > View.LoadingViewPhonePin, currentView === View.LoadingViewPhonePin)}
            {stepListItem("4. Enter the security code from your email", currentView > View.PinEmailView, currentView === View.PinEmailView)}
          </ol>
        </div>
        {displayCurrentView()}
      </div>
    </Dialog>
  );

}

export default UpgradeTo2fa;
