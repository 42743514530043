import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Icon } from "@blueprintjs/core";
import { request, jwtPeek, authReq, classNames } from "utils";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import { appState } from "../../app/appState.js";
import { togglePane, PANE_DITTO } from "../layout/layoutSlice.js";
import {
  esignaturesFetch,
  getEsignaturesFromGraph,
} from "../esignature/esignatureSlice.js";
import { AddZone } from "../login/icons";
import EsignatureIcon from "../esignature/esignatureIcon.js";
import { API_URL_PREFIX } from "../../config.js";
import { ZONE } from "../../app/dictionary.js";
import {
  ESIGNATURE_REQUEST,
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,
} from "../../app/permissions.js";
import { COMPONENT_ESIGNATURE_TABLE } from "../esignature/EsignatureTable.js";
import styles from "./Banner.module.css";
import stylez from "../dialogs/Dialogs.module.css";

import zoneStyles from "../zones/zones.module.css";

const {
  selectors: { selectGraph, selectPermissions },
  actions: { loadZone, setCurrentZone, moveZoneFirst },
} = appState;

const observedEsignatures = new Set();
const observedExpirings = new Set();

export const Banner = () => {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const identity = useSelector(selectIdentity);
  const permissions = useSelector(selectPermissions);
  const [esignaturesFromQuery, setEsignatures] = useState(null);
  const [expiringsFromQuery, setExpirings] = useState(null);
  const [state, setState] = useState({});

  const goForEsignatures =
    !state.hideEsignatureToast &&
    (permissions?.[ESIGNATURE_REQUEST] ||
      permissions?.[ESIGNATURE_APPROVE] ||
      permissions?.[ESIGNATURE_REJECT]);

  const userId = identity?.id;

  useEffect(() => {
    (async () => {
      if (goForEsignatures) {
        const esignatures = await dispatch(esignaturesFetch());
        setEsignatures(esignatures);
      }
    })();
  }, [goForEsignatures]);

  useEffect(() => {
    (async () => {
      const accessToken = await dispatch(getToken());
      const url = `${API_URL_PREFIX}/expiringteams`;
      const { user: userId } = jwtPeek(accessToken);
      const result = await request(
        url,
        authReq({
          accessToken,
          userId,
        })
      );
      setExpirings(result);
    })();
  }, []);

  const esignatures = [];

  if (goForEsignatures && esignaturesFromQuery) {
    const esignaturesFromGraph = getEsignaturesFromGraph(graph);

    const esignatureMap = {};

    for (const esignature of esignaturesFromGraph) {
      esignatureMap[esignature.id] = esignature;
      observedEsignatures.add(esignature.id);
    }

    for (const esignature of esignaturesFromQuery) {
      if (!observedEsignatures.has(esignature.id)) {
        esignatureMap[esignature.id] = esignature;
      }
    }

    for (const esignature of Object.values(esignatureMap)) {
      const { status, approvals, rejections, signees, requester } = esignature;
      if (
        status != "canceled" &&
        status != "expired" &&
        approvals.length + rejections.length != signees.length &&
        (requester == userId || signees.includes(userId))
      ) {
        esignatures.push(esignature);
      }
    }
  }

  const expirings = [];

  if (expiringsFromQuery) {
    const expiringsFromGraph = Object.values(graph.zones).filter(
      (zone) => zone.expirationDate
    );

    const expiringMap = {};

    for (const expiring of expiringsFromGraph) {
      expiringMap[expiring.id] = expiring;
      observedExpirings.add(expiring.id);
    }

    for (const expiring of expiringsFromQuery) {
      if (!observedExpirings.has(expiring.id)) {
        expiringMap[expiring.id] = expiring;
      }
    }
    for (const expiring of Object.values(expiringMap)) {
      const { expirationDate } = expiring;
      if (new Date(expirationDate) - new Date() < 1000 * 60 * 60 * 24 * 7) {
        expirings.push(expiring);
      }
    }
  }

  const showEsignatureToast = goForEsignatures && !!esignatures.length;
  const showExpireToast = !state.hideExpireToast && !!expirings.length;

  return (
    <div>
      {showEsignatureToast && (
        <EsignatureToast
          onClick={() =>
            dispatch(
              togglePane({
                ...PANE_DITTO,
                component: COMPONENT_ESIGNATURE_TABLE,
              })
            )
          }
          onClose={() => setState((s) => ({ ...s, hideEsignatureToast: true }))}
        />
      )}
      {showExpireToast && (
        <ExpireToast
          onClick={() => setState((s) => ({ ...s, showExpireDialog: true }))}
          onClose={() => setState((s) => ({ ...s, hideExpireToast: true }))}
        />
      )}
      <ExpireDialog
        active={state.showExpireDialog}
        onClose={() => setState((s) => ({ ...s, showExpireDialog: false }))}
        zones={expirings}
      />
    </div>
  );
};

export const EsignatureToast = ({ onClick, onClose }) => {
  return (
    <div
      className={classNames(styles.banner, styles.esignature)}
      onClick={onClick}
    >
      <div className={styles.badge}>
        <EsignatureIcon />
      </div>
      <div className={styles.text}>
        <div>E-signatures pending</div>
        <div>Ongoing e-signature</div>
      </div>
      <div className={styles.flip}>
        <div
          className={styles.cancel}
          onClick={(e) => {
            e.stopPropagation();
            onClose(e);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <div className={styles.button}>Open</div>
      </div>
    </div>
  );
};

export const ExpireToast = ({ onClick, onClose }) => {
  return (
    <div
      className={classNames(styles.banner, styles.esignature)}
      onClick={onClick}
    >
      <div className={styles.badge}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#000000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <polyline points="12 7 12 12 15 15" />
        </svg>
      </div>
      <div className={styles.text}>
        <div>Expiring {ZONE}s</div>
        <div>{ZONE[0].toUpperCase() + ZONE.slice(1)}s are about to expire</div>
      </div>
      <div className={styles.flip}>
        <div
          className={styles.cancel}
          onClick={(e) => {
            e.stopPropagation();
            onClose(e);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <div className={styles.button}>Open</div>
      </div>
    </div>
  );
};

export const ExpireDialog = ({ zones, active, onClose }) => {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const [sortOn, setSortOn] = useState("date");
  const [reverseSort, setReverseSort] = useState(false);

  if (!active || !identity) {
    return null;
  }

  const onClick = async (zone) => {
    await dispatch(loadZone({ id: zone.id }));
    dispatch(setCurrentZone(zone.id));
    dispatch(moveZoneFirst(zone.id));
    onClose();
  };

  let list = [...zones];

  if (sortOn === "date") {
    list = list.sort(
      (a, b) => new Date(a.expirationDate) - new Date(b.expirationDate)
    );
  } else {
    list = list.sort((a, b) =>
      a.name != b.name ? (a.name < b.name ? -1 : 1) : 0
    );
  }
  if (reverseSort) {
    list = list.reverse();
  }

  list = list.map((zone) => {
    const now = new Date();
    const expirationDate = new Date(zone.expirationDate);
    const timeDiff = expirationDate - now;

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const dayText =
      days > 0 ? (days === 1 ? `${days} day` : `${days} days`) : "";
    const hoursText =
      hours > 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : "";
    const minutesText =
      minutes > 0
        ? minutes === 1
          ? `${minutes} minute`
          : `${minutes} minutes`
        : "";
    const secondsText =
      seconds > 0
        ? seconds === 1
          ? `${seconds} second`
          : `${seconds} seconds`
        : "";

    let timeRemaining = "";
    if (days) {
      timeRemaining = `${dayText} ${hoursText}`;
    } else if (hours) {
      timeRemaining = `${hoursText} ${minutesText}`;
    } else if (minutes) {
      timeRemaining = `${minutesText} ${secondsText}`;
    } else if (seconds) {
      timeRemaining = `${secondsText}`;
    } else {
      timeRemaining = "expired";
    }

    const initials = zone.name.replace(/[^\p{N}\p{L}\p{M}]/gu, "").slice(0, 2);

    return (
      <React.Fragment key={zone.id}>
        <div className={zoneStyles.zone} onClick={() => onClick(zone)}>
          <div className={zoneStyles.avatar}>{initials}</div>
          <div className={zoneStyles.label}>{zone.name}</div>
          <div>{timeRemaining}&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div style={{ height: "5px" }}></div>
      </React.Fragment>
    );
  });

  const handleSort = (columnToSort) => {
    if (columnToSort !== sortOn) {
      setSortOn(columnToSort);
      setReverseSort(false);
    } else {
      setReverseSort(!reverseSort);
    }
  };

  return (
    <Dialog
      isOpen={active}
      onClose={onClose}
      title={
        <div className={stylez.title}>
          <AddZone size={34} />
          <span className={stylez.dialogTitle}>Expiring {ZONE}s</span>
        </div>
      }
      className={stylez.dialog}
    >
      <div className={stylez.dialogBody}>
        <div className={styles.bar}>
          <div className={styles.header} onClick={() => handleSort("name")}>
            {ZONE[0].toUpperCase() + ZONE.slice(1)}s
          </div>
          <div className={styles.header} onClick={() => handleSort("date")}>
            Time to expire
          </div>
        </div>
        {list}
      </div>
    </Dialog>
  );
};
