import { Icon, Switch } from "@blueprintjs/core";
import { escapeRegExp } from "app-state/src/queries";
import { useEffect, useMemo, useState } from "react";
import { classNames } from "utils";
import styles from "./BhDialogs.module.css";
import {
  selectShowAllFileInfos,
  setShowAllFileInfos,
} from "./bullhornSlice.js";
import BhTableTop from "./BhTableTop.js";
import bullhornLogo from "./bullhorn.png";
import { ARG_TIME, ARG_DATE, ARG_DATE_NO_YEAR } from "../chat/derive.js";
import { useDispatch, useSelector } from "react-redux";
const DEFAULT_SORT = "candidate";
const HEADER_FIELDS = [
  ["candidate", "Candidate"],
  ["name", "Docs"],
  ["type", "Type"],
  //["size", "Size"],
  ["date", "Date Added"],
  ["check", "Selected"],
];

export default ({
  sync,
  disableCreateNewTeamButton,
  fileInfos,
  contentStatus,
  buttonText,
  defaultAllSelected,
}) => {
  const dispatch = useDispatch();
  const showAll = useSelector(selectShowAllFileInfos);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedFiles, setCheckedFiles] = useState(new Set());
  const [sortOn, setSortOn] = useState(DEFAULT_SORT);
  const [reverseSort, setReverseSort] = useState(false);

  const handleCheck = ({ id }) => {
    setCheckedFiles((checkedFiles) => {
      checkedFiles = new Set(checkedFiles);
      if (checkedFiles.has(id)) {
        checkedFiles.delete(id);
      } else {
        checkedFiles.add(id);
      }
      return checkedFiles;
    });
  };

  const handleSelectAll = (isSelectAll) => {
    if (isSelectAll) {
      setCheckedFiles(new Set(massagedFileInfos.map(({ id }) => id)));
    } else {
      setCheckedFiles(new Set());
    }
  };

  const handleShowAll = (isShowAll) => {
    dispatch(setShowAllFileInfos(!isShowAll));
  };

  const getCheckedFileInfos = () =>
    fileInfos.filter((fileInfo) => checkedFiles.has(fileInfo.id));

  const ORDER_OF_SORTS = {
    default: (a, b) => {
      return 0;
    },
    candidate: (a, b) => {
      const aName = `${a.candidate.firstName} ${a.candidate.lastName}`;
      const bName = `${b.candidate.firstName} ${b.candidate.lastName}`;
      let v = aName.localeCompare(bName);
      if (v === 0) {
        v = a.type.localeCompare(b.type);
      }
      if (v === 0) {
        v = a.index - b.index;
      }
      return v;
    },
    type: (a, b) => {
      const v = a.type.localeCompare(b.type);
      return v != 0 ? v : a.index - b.index;
    },
    name: (a, b) => {
      const v = a.name.localeCompare(b.name);
      return v != 0 ? v : a.index - b.index;
    },
    size: (a, b) => {
      const v = a.fileSize - b.fileSize;
      return v != 0 ? v : a.index - b.index;
    },
    date: (a, b) => {
      const v = a.dateAdded - b.dateAdded;
      return v != 0 ? v : a.index - b.index;
    },
    check: (a, b) => {
      if (checkedFiles.has(a.id) === checkedFiles.has(b.id)) {
        return a.index - b.index;
      }
      if (checkedFiles.has(a.id)) {
        return 1;
      }
      return -1;
    },
    index: (a, b) => {
      return a.index - b.index;
    },
  };

  const handleSortOn = (sortIndex) => {
    if (sortIndex != sortOn) {
      setReverseSort(false);
      setSortOn(sortIndex);
    } else if (!reverseSort) {
      setReverseSort(true);
    } else {
      setSortOn(DEFAULT_SORT);
      setReverseSort(false);
    }
  };

  const handleSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const { nrChecked, massagedFileInfos } = useMemo(() => {
    let massagedFileInfos = fileInfos;

    massagedFileInfos.sort(
      (a, b) => ORDER_OF_SORTS[sortOn](a, b) * (reverseSort ? -1 : 1)
    );

    if (searchTerm) {
      massagedFileInfos = massagedFileInfos.filter((fileInfo) => {
        const re = new RegExp(escapeRegExp(searchTerm), "i");
        const { firstName, lastName } = fileInfo.candidate;
        const candidateName = `${firstName} ${lastName}`;
        return re.test(candidateName) || re.test(fileInfo.name);
      });
    }

    const nrChecked = checkedFiles.size;

    return {
      nrChecked,
      massagedFileInfos,
    };
  }, [fileInfos, checkedFiles, sortOn, reverseSort, searchTerm]);

  useEffect(() => {
    const _checkedFiles = fileInfos.reduce((set, fileInfo) => {
      checkedFiles.has(fileInfo.id) && set.add(fileInfo.id);
      return set;
    }, new Set());
    setCheckedFiles(_checkedFiles);
  }, [showAll]);

  return (
    <div className={styles.dialogBody}>
      <BhTableTop
        showAll={showAll}
        handleSearchTerm={handleSearchTerm}
        handleSelectAll={handleSelectAll}
        handleShowAll={handleShowAll}
        defaultSelectAll={defaultAllSelected}
      />
      <div className={styles.bar}>
        {HEADER_FIELDS.map(([sort, colName]) => (
          <div
            key={sort}
            onClick={(e) => handleSortOn(sort)}
            className={styles.header}
          >
            <span>{colName}</span>{" "}
            {sortOn === sort && (
              <Icon
                className={classNames(reverseSort && styles.upsideDown)}
                icon="caret-down"
              />
            )}
          </div>
        ))}
      </div>
      {contentStatus === "idle" ? (
        <FileInfoList
          fileInfos={massagedFileInfos}
          handleChecked={handleCheck}
          checkedFiles={checkedFiles}
          //fileInfosToCheckedMap={fileInfosToCheckedMap}
        />
      ) : (
        <span> Loading... </span>
      )}
      <div className={styles.dialogBottomRow}>
        <img src={bullhornLogo} className={styles.img}></img>
        <button
          className={styles.dialogButton}
          onClick={(e) => sync(getCheckedFileInfos(), !nrChecked)}
          disabled={disableCreateNewTeamButton}
        >
          {nrChecked ? `${buttonText} (${nrChecked})` : "Skip"}
        </button>
      </div>
    </div>
  );
};

const FileInfoList = ({ fileInfos, handleChecked, checkedFiles }) => {
  return (
    <div className={styles.scrollable}>
      {fileInfos.map((fileInfo) => (
        <FileInfo
          key={fileInfo.id}
          fileInfo={fileInfo}
          handleChecked={handleChecked}
          checked={checkedFiles.has(fileInfo.id)}
        />
      ))}
    </div>
  );
};

const FileInfo = ({ fileInfo, handleChecked, checked }) => {
  const { fileSize, dateAdded, name, candidate, type } = fileInfo;
  const { firstName, lastName } = candidate;
  const candidateName = `${firstName} ${lastName}`;
  const humanFileSize = useMemo(() => {
    var i = fileSize == 0 ? 0 : Math.floor(Math.log(fileSize) / Math.log(1024));
    return (
      (fileSize / Math.pow(1024, i)).toFixed(2) * 1 +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }, [fileSize]);

  let dateString,
    dateObject = new Date(dateAdded);

  if (
    new Date().setHours(0, 0, 0, 0) == new Date(dateAdded).setHours(0, 0, 0, 0)
  ) {
    dateString = dateObject.toLocaleTimeString(...ARG_TIME);
  } else if (dateObject.getFullYear() == new Date().getFullYear()) {
    dateString = new Date(dateAdded).toLocaleDateString(...ARG_DATE_NO_YEAR);
  } else {
    dateString = new Date(dateAdded).toLocaleDateString(...ARG_DATE);
  }

  return (
    <div className={styles.row}>
      <div className={classNames(styles.column)}>
        <span title={candidateName}>{candidateName}</span>
      </div>
      <div
        className={classNames(
          styles.column,
          // styles.fileInfo,
          checked || styles.striked
        )}
      >
        <span title={name}>{name}</span>
        {/* {secondary && <span>{secondary}</span>} */}
      </div>
      <div className={classNames(styles.column)}>
        <span title={type}>{type}</span>
      </div>
      {/*
      <div
        className={classNames(
          styles.column //,
          // styles.provider,
          // styles[`provider-${fileInfo.provider}`]
        )}
      >
        <span>{humanFileSize}</span>
      </div>
      */}
      <div className={classNames(styles.column /*, styles.role*/)}>
        <span>{dateString}</span>
      </div>
      <div className={classNames(styles.column, styles.check)}>
        <Switch
          large={true}
          checked={checked}
          onChange={(e) => {
            handleChecked(fileInfo);
          }}
        />
      </div>
    </div>
  );
};
