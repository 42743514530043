import Data from "../../data";

import { crypto } from "../../crypto";
import {
  assertObject,
  assertString,
  assertInstanceOf,
  isEdge,
  isFirefox,
  isChrome
} from "../../util";

import * as asn1js from "asn1js";
import * as pkijs from "pkijs";

export async function importWebCryptoPublicKey(key, format, algorithm, usages) {
  assertString(format, "format");
  assertObject(algorithm, "algorithm");
  assertInstanceOf(Array, usages, "usages");

  switch (format) {
    case "spki":
      assertInstanceOf(Data, key, "key");

      // 0x04 + (32 bytes) X + (32 bytes) Y
      let bitstring;
      if (key.length == 65) {
        bitstring = key;
      } else {
        bitstring = new Data(
          asn1js.fromBER(
            key.createArrayBuffer()
          ).result.valueBlock.value[1].valueBlock.valueHex
        );
      }

      return await crypto.subtle.importKey(
        "jwk",
        {
          kty: "EC",
          crv: "P-256",
          x: bitstring
            .slice(1, 33)
            .toBase64URL()
            .slice(0, -1),
          y: bitstring
            .slice(33, 65)
            .toBase64URL()
            .slice(0, -1)
        },
        algorithm,
        true,
        usages
      );
    case "jwk":
      assertObject(key, "key");

      return await crypto.subtle.importKey("jwk", key, algorithm, true, usages);
    default:
      throw Error(
        `Unsupported import format for Web Crypto public key. (${format})`
      );
  }
}

export async function exportWebCryptoPublicKey(key, format) {
  assertString(format, "format");

  let xy = await crypto.subtle.exportKey("jwk", key);

  switch (format) {
    case "spki":
      return new Data(
        new asn1js.Sequence({
          value: [
            new asn1js.Sequence({
              value: [
                new asn1js.ObjectIdentifier({ value: "1.2.840.10045.2.1" }),
                new asn1js.ObjectIdentifier({ value: "1.2.840.10045.3.1.7" })
              ]
            }),
            new asn1js.BitString({
              valueHex: new pkijs.ECPublicKey({
                x: Data.fromBase64URL(xy.x).createArrayBuffer(),
                y: Data.fromBase64URL(xy.y).createArrayBuffer(),
                namedCurve: "1.2.840.10045.3.1.7"
              })
                .toSchema()
                .toBER(false)
            })
          ]
        }).toBER(false)
      );
    case "jwk":
      return {
        kty: "EC",
        crv: "P-256",
        x: Data.fromBase64(xy.x).toBase64URL(),
        y: Data.fromBase64(xy.y).toBase64URL()
      };
    default:
      throw Error(
        `Unsupported export format for Web Crypto public key. (${format})`
      );
  }
}

export async function importWebCryptoPrivateKey(
  key,
  format,
  algorithm,
  usages
) {
  assertString(format, "format");
  assertObject(algorithm, "algorithm");
  assertInstanceOf(Array, usages, "usages");

  if (format === "pkcs1" || format === "sec1") {
    assertInstanceOf(Data, key, "key");

    let jwk = new pkijs.ECPrivateKey({
      namedCurve: "1.2.840.10045.3.1.7",
      schema: asn1js.fromBER(key.createArrayBuffer() || key).result
    }).toJSON();

    if (isChrome() && jwk.d.length === 44) {
      // Chrome (webcrypto-liner) can't handle signed private exponents,
      // we need to make it unsigned ourselves

      // base64-url decode private exponent
      const d = Data.fromBase64URL(jwk.d);

      if (d.get(0) === 0 && d.get(1) > 127) {
        // convert to unsigned and base64-url encode it
        jwk.d = d
          .slice(1)
          .toBase64URL()
          .slice(0, -1);
      }
    }

    return crypto.subtle.importKey(
      "jwk",
      Object.assign({}, { kty: "EC" }, jwk),
      algorithm,
      true,
      usages
    );
  } else if (format === "pkcs8") {
    assertInstanceOf(Data, key, "key");

    if (isFirefox() || isEdge()) {
      const pkcs1 = new Data(
        asn1js.fromBER(
          key.createArrayBuffer()
        ).result.valueBlock.value[2].valueBlock.valueHex
      );
      return importWebCryptoPrivateKey(pkcs1, "pkcs1", algorithm, usages);
    } else {
      return crypto.subtle.importKey(
        "pkcs8",
        key.getUint8Array(),
        algorithm,
        true,
        usages
      );
    }
  } else if (format === "jwk") {
    assertObject(key, "key");

    return crypto.subtle.importKey("jwk", key, algorithm, true, usages);
  } else {
    throw Error(
      `Unsupported export format for Web Crypto private key. (${format})`
    );
  }
}

export async function exportWebCryptoPrivateKey(key, format) {
  assertString(format, "format");

  const exported = await crypto.subtle.exportKey(format, key);

  switch (format) {
    case "jwk":
      return exported;
    default:
      return new Data(exported);
  }
}
