import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { keys, values, classNames } from "utils";
import { selectSummary, selectProgress } from "./transferSlice.js";
import styles from "./Transfer.module.css";

import { hidePane, PANE_TRANSFER } from "../../features/layout/layoutSlice.js";

import { iconFactory, FolderIcon } from "../../helpers/tree-icons";
import { Trash } from "../login/icons.tsx";

export function Transfer() {
  const dispatch = useDispatch();
  const list = useSelector(selectProgress);
  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <div className={styles.header}>Progress</div>
        <div
          className={styles.header}
          onClick={() => dispatch(hidePane(PANE_TRANSFER))}
        >
          <Icon icon="cross" />
        </div>
      </div>
      <div className={styles.rest}>
        {values(list).map(({ id, ...props }) => (
          <Item key={id} {...props} />
        ))}
      </div>
    </div>
  );
}

export const Summary = () => {
  const props = useSelector(selectSummary);
  if (!props) {
    return null;
  }
  return (
    <div className={styles.summary}>
      <Item {...props} />
    </div>
  );
};

export const Item = ({
  name,
  completed,
  total,
  progress,
  size,
  hours,
  mins,
  secs,
  error,
}) => {
  if (hours > 99) {
    hours = "--";
    mins = "--";
  } else if (hours < 1) {
    hours = mins;
    mins = secs;
    if (hours == -1 || mins == -1) {
      hours = "--";
      mins = "--";
    }
  }

  const label = name || `${completed}/${total}`;
  const percent = `${Math.round(progress * 20) * 5}%`;
  const bottomLeft = `${Math.round(size / 1024 / 1024)}MB`;
  const bottomRight = `${`0${hours}`.slice(-2)}:${`0${mins}`.slice(-2)}`;
  return (
    <div className={styles.item}>
      <div className={classNames(styles.icon, name && styles.file)}>
        {name ? iconFactory(name) : <FolderIcon />}
      </div>
      {error ? (
        <Error {...{ label, bottomLeft, bottomRight, error }} />
      ) : (
        <Meter {...{ label, percent, bottomLeft, bottomRight }} />
      )}
      <div className={styles.trash}>
        <Trash size={25} />
      </div>
    </div>
  );
};

export const Error = ({ label, percent, bottomLeft, bottomRight, error }) => {
  return (
    <div className={`${styles.meter} `}>
      <div className={styles.upper}>
        <div className={styles.text}>{label}</div>
        <div className={styles.percent}>{percent}</div>
      </div>
      <div className={styles.middle}>
        <div className={styles.error}>{error}</div>
      </div>
      <div className={styles.lower}>
        <div className={styles.size}>{bottomLeft}</div>
        <div className={styles.speed}>{bottomRight}</div>
      </div>
    </div>
  );
};

export const Meter = ({ label, percent, bottomLeft, bottomRight }) => {
  return (
    <div className={styles.meter}>
      <div className={styles.upper}>
        <div className={styles.text}>{label}</div>
        <div className={styles.percent}>
          {percent !== "100%" ? percent : ""}
        </div>
      </div>
      <div className={styles.middle}>
        <div
          className={classNames(
            styles.progress,
            percent == "0%" && styles.zero,
            percent == "100%" && styles.done
          )}
          style={{ width: percent }}
        />
        <div style={{ width: `calc(100% - ${/*"33%"*/ percent})` }} />
      </div>
      <div className={styles.lower}>
        <div className={styles.size}>{bottomLeft}</div>
        <div className={styles.speed}>
          {bottomRight !== "00:00" ? bottomRight : "Done!"}
        </div>
      </div>
    </div>
  );
};
