import React, { useState, useContext, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { ZoneUsers, FolderUsers } from "../login/icons.tsx";

import appContext from "../../app/appContext.js";

import styles from "./bar.module.css";

import { appState } from "../../app/appState.js";

const {
  selectors: { selectNodes },
} = appState;

export default function Bar() {
  const dispatch = useDispatch();
  const { zoneName, group, rootGroupId, virtualRootGroupId } =
    useContext(appContext);
  const nodes = useSelector(selectNodes);

  const observer = useRef(null);

  const refResize = useCallback((ref) => {
    observer.current?.disconnect();
    if (ref) {
      observer.current = new ResizeObserver(() => {
        const { clientWidth: width, children: kids } = ref;
        kids[kids.length - 1].innerHTML = "";
        [...kids].map((el) => (el.style.display = ""));
        for (let i = 1; i < kids.length && width < ref.scrollWidth; i++) {
          kids[kids.length - i - 1].style.display = "none";
          kids[kids.length - 1].innerHTML = "+" + i;
        }
      });
      observer.current.observe(ref);
    }
  }, []);

  if (!group) {
    return null;
  }

  let name;

  const isZone =
    !group ||
    group.id == virtualRootGroupId ||
    group.id == rootGroupId ||
    !(group.rootFile in nodes);

  if (isZone) {
    name = zoneName;
  } else {
    name = nodes[group.rootFile].name;
  }

  return (
    <div className={styles.bar}>
      <div>Chat</div>
      {isZone ? <ZoneUsers /> : <FolderUsers />}
      <div>{name}</div>
    </div>
  );
}
