import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createSagasPolyfill, createMetaHooks } from "utils";
//import accountReducer, { accountSagas } from "./accountSlice.js";
import topReducer, { topSagas } from "./topSlice.js";
import appReducer, { appSagas } from "./appSlice.js";
import appStateReducers, { sagas as appStateSagas } from "./appState.js";
import trustReducers, { sagas as trustSagas } from "./trustKit.js";
import layoutReducer from "../features/layout/layoutSlice.js";
import inviteReducer, { inviteSagas } from "../features/invite/inviteSlice.tsx";
import activityReducer, {
  activitySagas,
} from "../features/activity/activitySlice.js";
import loginReducer from "../features/login/loginSlice.tsx";
import transferReducer from "../features/transfer/transferSlice.js";
import editorReducer from "../features/editor/editorSlice.js";
import chatReducer, { chatSagas } from "../features/chat/slice.js";
import { adminSagas } from "../features/admin/adminSlice.js";
import bullhornReducer, {
  bullhornSagas,
} from "../features/bullhorn/bullhornSlice.js";
//import { expireSagas } from "../features/expire/expireSlice.js";
import eSignatureReducer from "../features/esignature/esignatureSlice.js";
import muteTeamReducer from "../features/mute_team/muteSlice.js";

const { metaStore, metaSagas, metaActions, metaReducer } = createMetaHooks();

const sagasMiddleware = createSagasPolyfill({
  top: topSagas,
  app: appSagas,
  //account: accountSagas,
  activity: activitySagas,
  invite: inviteSagas,
  chat: chatSagas,
  admin: adminSagas,
  bullhorn: bullhornSagas,
  //expire: expireSagas,
  ...appStateSagas,
  ...trustSagas,
  ...metaSagas,
});

export const store = metaStore(
  configureStore({
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          ...Object.values(metaActions).map((action) => action().type),
        ],
      },
    }).concat(sagasMiddleware),
    reducer: {
      //account: accountReducer,
      //tree: treeReducer,
      top: topReducer,
      app: appReducer,
      activity: activityReducer,
      layout: layoutReducer,
      invite: inviteReducer,
      login: loginReducer,
      bullhorn: bullhornReducer,
      transfer: transferReducer,
      editor: editorReducer,
      chat: chatReducer,
      esignature: eSignatureReducer,
      mute: muteTeamReducer,
      ...appStateReducers,
      ...trustReducers,
      ...metaReducer,
    },
  })
);

export default store;
