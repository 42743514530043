import React from "react";
import { validateEmail } from "./email";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";

import styles from "./index.module.css";

export const validateSSN = (sPNum) => {
  var numbers = sPNum.match(
    /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/
  );
  var checkSum = 0;

  if (numbers == null) {
    return false;
  }

  var n;
  for (var i = 3; i <= 12; i++) {
    n = parseInt(numbers[i]);
    if (i % 2 == 0) {
      checkSum += n;
    } else {
      checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
    }
  }

  if (checkSum % 10 == 0) {
    return true;
  }
  return false;
};

export default function Bankid({ state, setState, submit }) {
  const { moreOptions, notifyAddress = "", wellknown: [ssn = ""] = [] } = state;

  const { showErrors, notifyOk, ssnOk } = state;

  return (
    <>
      <div className={styles.br} style={{ height: "30px" }} />
      <input
        type="text"
        placeholder="Email Address (alice@example.com)"
        className={classNames(
          styles.input,
          showErrors && !notifyOk && styles.error
        )}
        value={notifyAddress}
        onChange={(e) => {
          const email = e.target.value;
          const notifyOk = validateEmail(email);
          setState({ notifyAddress: email, notifyOk });
        }}
        onKeyDown={(e) => e.key == "Enter" && submit()}
      />
      <div className={styles.br} style={{ height: "20px" }} />
      <div
        onClick={() => setState({ moreOptions: !moreOptions })}
        className={styles.moreOptions}
      >
        <Icon iconSize={14} icon="form" />
        More Options
      </div>
      {moreOptions && (
        <>
          <div className={styles.br} style={{ height: "30px" }} />
          <div className={styles.label}>
            Set the personnummer to be used by the user:
          </div>
          <input
            type="text"
            placeholder="Personnummer (12 siffror)"
            className={classNames(
              styles.input,
              showErrors && !ssnOk && styles.error
            )}
            value={ssn}
            onChange={(e) => {
              const ssn = e.target.value;
              const ssnOk = validateSSN(ssn);
              setState({ wellknown: [ssn], ssnOk });
            }}
            onKeyDown={(e) => e.key == "Enter" && submit()}
          />
        </>
      )}
    </>
  );
}
