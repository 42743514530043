import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Banner } from "../banner/Banner.js";
import styles from "./Menu.module.css";
import { ZONES_BACKGROUND } from "../contexts/Contexts.js";
import { Zones } from "../zones/zones";
import Verifying from "../verifying/index.tsx";
import { selectIdentity } from "../../app/topSlice";
import { API_URL_PREFIX } from "../../config.js";

export const Menu = React.memo(() => {
  const dispatch = useDispatch();

  return (
    <div className={styles.outer} data-context={ZONES_BACKGROUND}>
      <div className={styles.top}>
        <Brand />
        <Banner />
      </div>
      <div className={styles.rest}>
        <Zones />
      </div>
      <div className={styles.bottom}>{/*<Banner />*/}</div>
    </div>
  );
});

export function Brand() {
  const { org } = useSelector(selectIdentity);
  const logoUrl = `${API_URL_PREFIX}/logo/${org}`;
  return (
    <div className={styles.wrapper}>
      <div className={styles.verified}>
        <Verifying size={24} auto={500} verified={false} />
      </div>
      <div className={styles.logo}>
        <img src={logoUrl} />
      </div>
    </div>
  );
}
