import PublicKey from "./public_key";
import { agree } from "./";

export default class PublicECDHKey extends PublicKey {
  constructor(key) {
    super(key);
  }

  async agree(privateKey) {
    return agree(this, privateKey);
  }
}
