import { assertInstanceOf } from "../util";
import Data from "../data";

import { importPublicECDSAKey, validateCertificateChain } from "./";

import * as asn1js from "asn1js";
import * as pkijs from "pkijs";

export default class Certificate {
  constructor(certificate) {
    this.certificate = certificate;
    this.subject = certificate.subject.typesAndValues[0].value.valueBlock.value;
  }

  async getPublicKey() {
    return importPublicECDSAKey(
      new Data(
        this.certificate.subjectPublicKeyInfo.subjectPublicKey.valueBlock.valueHex
      ),
      "spki"
    );
  }

  static importBER(ber) {
    assertInstanceOf(Data, ber, "Certificate.importBER");

    return new Certificate(
      new pkijs.Certificate({
        schema: asn1js.fromBER(ber.createArrayBuffer()).result
      })
    );
  }

  static importPEM(pem) {
    if (typeof pem !== "string") {
      throw new Error(`Certificate.importPEM expects a string. (got ${pem})`);
    }

    const ber = pem.match(
      /(?:-+BEGIN CERTIFICATE-+)([\s\S]+?)(?:-+END CERTIFICATE-+)/i
    );
    if (!ber) throw new Error("Bad PEM.");
    return this.importBER(Data.fromBase64(ber[1].replace(/\s/g, "")));
  }

  validateChain(certificateChain) {
    return validateCertificateChain(this, certificateChain);
  }
}
