import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { classNames } from "utils";
import { API_URL_SUB, API_URL_PREFIX, KONFIDENT_URL } from "../../config";
import { fetchIdProviders } from "../../app/realworld.js";
import Verifying from "../verifying";
import { selectIsMobile } from "../layout/layoutSlice.js";
import { IdProvider } from "../../types";
import {
  selectProcess,
  selectProgress,
  selectView,
  setProgress,
  triggerSAML,
} from "./loginSlice";
import { SSO } from "./icons";
import Email from "./email";
import Pin from "./pin";
import BankID from "./bankid";
import Hub from "./hub";
import Sso from "./sso";
import { Spinner, SpinnerSize, Icon } from "@blueprintjs/core";
import { inBullhorn } from "app/topSlice";
import styles from "./Login.module.css";

interface IdProvidersProps {
  idProviders: IdProvider[];
  isLoading: boolean;
}

export const Login: React.FC = () => {
  const isMobile = useSelector(selectIsMobile);
  const process = useSelector(selectProcess);
  const view = useSelector(selectView);

  useEffect(() => {
    const samlLocalStorage = localStorage.getItem("saml");
    if (samlLocalStorage) {
      setProgress("LOGIN");
    }
  }, []);

  if (process === "PREPARE") {
    return (
      <div className={styles.canvas}>
        <Verifying color="blue" />
      </div>
    );
  }

  const signUp = (
    <>
      <span>Sign up</span>
      <Icon icon="arrow-right" />
    </>
  );

  const logo = (
    <div className={styles.yourlogo}>
      {view !== "HUB" && <img src={`${API_URL_PREFIX}/logo/${API_URL_SUB}`} />}
    </div>
  );

  const companyLogo = (
    <div className={styles.companyLogo} >
      <a href={KONFIDENT_URL}>
        {!isMobile && signUp}
        <img src={`${API_URL_PREFIX}/company-logo.png`} />
      </a>
    </div>
  );

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        {logo}
        {view === "EMAIL" && !inBullhorn && companyLogo}
      </div>
      <Provider />
    </div>
  );
}

const Provider: React.FC = ({ }) => {
  const isMobile = useSelector(selectIsMobile);
  const process = useSelector(selectProcess);
  const progress = useSelector(selectProgress);
  const view = useSelector(selectView);
  const [idProviders, setIdProviders] = useState<IdProvider[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchIdProviders().then((idProviders: IdProvider[]) => {
      setIdProviders(idProviders);
      setIsLoading(false);
    })
  }, []);

  const isIdProvider = idProviders.length > 0 && view === "EMAIL";
  const welcomed: boolean = ["WAITING", "FINISHED"].includes(progress) && view !== "SSO" && view !== "EMAIL";

  let message: string = "";
  let loginView: JSX.Element | null = null;
  let innerClass: string = styles.inner;
  let centerClass: string = styles.center;
  let welcomeClass: string = "";
  let mobileClass: string = styles.mobile;

  if (welcomed) {
    welcomeClass = classNames(styles.welcome, styles.welcomed);
  } else {
    welcomeClass = styles.welcome;
  }

  if (process === "LOGIN") {
    if (welcomed) {
      message = "";
    } else if (view === "PIN") {
      message = "Enter pin code from email.";
    } else if (view === "PIN2FA") {
      message = "Enter pin code from phone.";
    } else if (view === "SSO") {
      message = "Login with SAML SSO";
    } else if (view === "HUB") {
      message = "";
    } else if (view === "BANKID_DIRECT" || view === "BANKID") {
      message = "Login with BankID";
    } else {
      message = "Login with your email address";
    }
  }

  switch (view) {
    case "HUB":
      innerClass = styles.hubContainer;
      centerClass = styles.hub;
      welcomeClass = styles.welcomed;
      mobileClass = styles.hubMobile;
      loginView = <Hub />;
      break;
    case "EMAIL":
      loginView = <Email />;
      break;
    case "BANKID":
    case "BANKID_DIRECT":
    case "PLACEHOLDER":
      loginView = <BankID />;
      break;
    case "PIN":
    case "PIN2FA":
      loginView = <Pin />;
      break;
    case "SSO":
      loginView = <Sso />;
      break;
    default:
      loginView = <Email />;
      break;
  }

  return (
    <div className={classNames(innerClass, isMobile && mobileClass)}>
      <div className={classNames(centerClass)}>
        <div className={welcomeClass}>{message}</div>
        {isIdProvider && <div className={styles.loginText}>
          <span>Or select an ID provider</span>
        </div>}
        {loginView}
        {isIdProvider &&
          <IdProviders
            idProviders={idProviders}
            isLoading={isLoading}
          />}
      </div>
    </div>
  );
};

const IdProviders: React.FC<IdProvidersProps> = ({ idProviders, isLoading }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectIsMobile);
  const [selectedId, setSelectedId] = useState("");

  const handleIdProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(event.target.value);
    dispatch(triggerSAML(event.target.value));
  };

  return (
    <div className={classNames(styles.idProviders, isMobile && styles.idProvidersMobile)}>
      <SSO size={28} background={false} />
      {!isLoading ? <select value={selectedId} onChange={handleIdProviderChange}>
        <option value="">Select an ID Provider</option>
        {idProviders.map((provider) => (
          <option key={provider.id} value={provider.id}>
            {provider.name}
          </option>
        ))}
      </select> : <Spinner size={SpinnerSize.SMALL} />}
    </div>
  );
}