import React, { useState, useContext } from "react";
import { Dialog, Icon, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";
import iconStyles from "../login/icons.module.css";
import { dispatchToast } from "../toasts/Toasts.js";
import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";
const {
  actions: { createNode },
} = appState;

export default ({ dispatch, active, close }) => {
  const { zoneId, groupId, folderId: parentId } = useContext(appContext);
  const [name, setName] = useState("");
  const [error, setError] = useState();

  const doIt = async () => {
    if (200 < name.length) {
      dispatchToast(dispatch, {
        message: `Name too long.`,
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      return;
    }
    const toast = dispatchToast(dispatch, {
      message: `Creating folder.`,
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    const { reason } = await dispatch(
      createNode({ zoneId, name, groupId, parentId })
    );
    if (reason) {
      console.log(reason);
      setError(true);
      if (reason == "BAD_NAME") {
        toast.replace({
          message: "That name is not allowed, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else if (reason != "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else {
        toast.dismiss();
      }
    } else {
      setName("");
      close();
      toast.replace({
        message: "Folder created.",
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
    }
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <div
            className={classNames(
              "squircle",
              iconStyles.squircle,
              styles.quickIcon
            )}
          >
            <Icon icon="folder-new" iconSize={20} />
          </div>
          <span className={styles.dialogTitle}>Create a new folder</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <input
          autoFocus
          value={name}
          className={classNames(styles.dialogInput, error && styles.errorInput)}
          placeholder="Folder Name"
          onKeyDown={(e) => e.key == "Enter" && doIt()}
          onChange={(e) => setName(e.target.value.normalize()) | setError()}
        />
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <button className={styles.dialogButton} onClick={doIt}>
          Create
        </button>
      </div>
    </Dialog>
  );
};
