import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Icon, Intent } from "@blueprintjs/core";
import createApiClient from "api-client";
import { classNames, createMutex } from "utils";
import { getToken, selectIdentity, inBullhorn } from "../../app/topSlice.js";
import { appState } from "../../app/appState.js";
import { ZONE } from "../../app/dictionary.js";
import * as config from "../../config";
import styles from "./UserPicker.module.css";
import { formatUser } from "../format/format.js";
import { AddUser } from "../login/icons.tsx";
import { dispatchToast } from "../toasts/Toasts.js";
import KeyperProvider from "../../helpers/wrappers/KeyVaultProvider";
import { fetchUserList } from "./usersSlice.js";
import { selectContactRole } from "../bullhorn/bullhornSlice.js";

const {
  derive: { getGroupsFromZone, getUsersFromGroup },
  selectors: { selectGraph, selectPermissions },
  actions: { addMember },
} = appState;

export default function Add({ active, close, zoneId, zoneName }) {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const identity = useSelector(selectIdentity);
  const graph = useSelector(selectGraph);
  const contactRole = useSelector(selectContactRole);
  const userIdsAlreadyInZone = getGroupsFromZone(graph, zoneId)
    .flatMap(({ id: groupId }) => getUsersFromGroup(graph, groupId))
    .map(({ user }) => user);

  const [user, setUser] = useState(null);

  const [roles, setRoles] = useState({});
  //const [users, setUsers] = useState(null);
  const [result, setResult] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  //const { current: myDebounce } = useRef(debounce(f => f(), 500));
  const {
    current: { sync: syncSearch },
  } = useRef(createMutex());
  const users = useRef(null);
  const [disableAddUserButton, setDisableAddUserButton] = useState(false);

  useEffect(
    () => () => {
      setUser(null);
      setResult(null);
      setSearchValue("");
      setDisableAddUserButton(false);
    },
    [active]
  );

  useEffect(() => {
    (async () => {
      if (!active || !identity) {
        return;
      }
      const { org } = identity;
      const token = await dispatch(getToken());
      const api = createApiClient(config.API_URL_PREFIX);
      const roles = await api.token(token).orgs(org).roles().get();

      setRoles(roles.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}));
    })();
  }, [active, identity]);

  useEffect(() => {
    (async () => {
      if (!active || !identity || (inBullhorn && !contactRole)) {
        return;
      }

      const us = await dispatch(fetchUserList({ identity, permissions }));
      users.current = us.filter((u) => !userIdsAlreadyInZone.includes(u.id));
      if (inBullhorn) {
        users.current = users.current.filter((u) => u.role.id !== contactRole);
      }
    })();
  }, [active, identity, contactRole]);

  useEffect(() => {
    syncSearch(async () => {
      while (searchValue && !users.current) {
        await new Promise((f) => setTimeout(f, 500));
      }
      if (searchValue) {
        const query = searchValue.replace(/[^a-z0-9-_@.]+/gi, ".*");
        const regex = new RegExp(".*" + query + ".*", "gi");
        const result = users.current.filter(
          (user) =>
            //regex.test(user.name) ||
            //regex.test(user.deprecatedId.replace(/^.*:/, ""))
            `${user.name}`.match(regex) ||
            `${user.deprecatedId}`.replace(/^.*:/, "").match(regex)
        );
        result.forEach((user) => (user.active = false));
        if (result.length) {
          result[0].active = true;
        }
        setResult(result);
      } else {
        setResult(null);
      }
    })();
  }, [searchValue]);

  if (!active || !identity) {
    return null;
  }

  const move = (e) => {
    if (!result || !result.length) {
      return;
    }

    if (e.key == "Enter") {
      e.preventDefault();
      const user = result.find((user) => user.active);
      setUser(user);
      return;
    }

    let index;

    if (e.key == "ArrowUp") {
      e.preventDefault();
      index = result.findIndex((user) => user.active) - 1;
    } else if (e.key == "ArrowDown") {
      e.preventDefault();
      index = result.findIndex((user) => user.active) + 1;
    } else {
      return;
    }

    index = Math.min(Math.min(Math.max(0, index), result.length - 1), 9);
    result.forEach((user) => (user.active = false));
    result[index].active = true;
    setResult([...result]);
  };

  const submit = async () => {
    setDisableAddUserButton(true);
    const toast = dispatchToast(dispatch, {
      message: "Adding User",
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    const { reason } = await dispatch(
      addMember({
        userId: user.id,
        zoneId,
      })
    );
    if (reason) {
      if (reason != "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else {
        toast.dismiss();
      }
      return;
    }
    toast.replace({
      message: "User Added",
      icon: "CHECK",
      intent: Intent.PRIMARY,
    });
    close();
  };

  const zoneText = ZONE[0].toUpperCase() + ZONE.slice(1);

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <AddUser size={34} />
          <span className={styles.dialogTitle}>
            Add a User to the {zoneText}
          </span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.outer}>
        {user ? (
          <>
            {(() => {
              const roleColor =
                user.role.id in roles ? roles[user.role.id].color : {};
              const [[primary, secondary = ""], detailed] = dispatch(
                formatUser(user)
              );
              return (
                <>
                  <div className={styles.confirm}>
                    Add this user to that {ZONE}?
                  </div>
                  <div className={styles.yeah}>
                    <div
                      title={detailed}
                      className={classNames(styles.recipient)}
                    >
                      <span>{primary}</span>
                      {secondary && <span>{secondary}</span>}
                    </div>
                    <div
                      className={classNames(
                        styles.provider,
                        styles[`provider-${user.provider}`]
                      )}
                    >
                      <span>
                        <KeyperProvider>{user.provider}</KeyperProvider>
                      </span>
                    </div>
                    <div className={classNames(styles.role)}>
                      <span
                        style={{
                          backgroundColor: roleColor.background,
                          borderColor: roleColor.border,
                          color: roleColor.foreground,
                        }}
                      >
                        {user.role.id in roles
                          ? roles[user.role.id].name
                          : "..."}
                      </span>
                    </div>
                  </div>
                  <div className={styles.icon}>
                    <Icon iconSize={40} icon="small-plus" />
                  </div>
                  <div className={styles.yeah}>
                    <div className={styles.pill}>
                      <div>
                        <Icon iconSize={16} icon={"application"} />
                      </div>
                      <div>{zoneName}</div>
                    </div>
                  </div>
                  <div className={styles.verticalSpace} />
                </>
              );
            })()}
            <div className={styles.verticalSpace} />
            <div className={styles.verticalSpace} />
            <div className={styles.dialogButtons}>
              <button className={styles.dialogButton} onClick={() => setUser()}>
                Cancel
              </button>
              <button
                autoFocus
                className={styles.dialogButton}
                onClick={submit}
                disabled={disableAddUserButton}
              >
                Okay
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.inputGroup}>
              <input
                autoFocus
                type="text"
                placeholder="Search Text e.g. Name or Email Address"
                className={styles.input}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => move(e)}
              />
              <button className={styles.dialogButton}>Find</button>
            </div>
            <div className={styles.verticalSpace} />
            <div className={styles.params}>
              <div className={styles.pill}>
                <div>
                  <Icon iconSize={14} icon="user" />
                </div>
                <div>
                  <Icon iconSize={14} icon="plus" />
                </div>
                <div>
                  <Icon iconSize={14} icon="application" />
                </div>
                <div>{zoneName}</div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  {result && !!result.length && (
                    <div className={styles.results}>
                      <div className={styles.verticalSpace} />
                      {result &&
                        result.slice(0, 10).map((user, i) => {
                          const roleColor =
                            user.role.id in roles
                              ? roles[user.role.id].color
                              : {};
                          const [[primary, secondary], detailed] = dispatch(
                            formatUser(user)
                          );
                          return (
                            <div
                              key={user.id}
                              className={classNames(
                                styles.result,
                                user.active && styles.active
                              )}
                              onClick={() => setUser(user)}
                            >
                              <div
                                title={detailed}
                                className={classNames(
                                  styles.column,
                                  styles.user
                                )}
                              >
                                <span>{primary}</span>
                                {secondary && <span>{secondary}</span>}
                              </div>
                              <div
                                className={classNames(
                                  styles.column,
                                  styles.provider,
                                  styles[`provider-${user.provider}`]
                                )}
                              >
                                <span>
                                  <KeyperProvider>
                                    {user.provider}
                                  </KeyperProvider>
                                </span>
                              </div>
                              <div
                                className={classNames(
                                  styles.column,
                                  styles.role
                                )}
                              >
                                <span
                                  style={{
                                    backgroundColor: roleColor.background,
                                    borderColor: roleColor.border,
                                    color: roleColor.foreground,
                                  }}
                                >
                                  {user.role.id in roles
                                    ? roles[user.role.id].name
                                    : "..."}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {result && 10 < result.length && (
                    <div className={styles.info}>
                      <div>
                        <Icon iconSize={24} icon="zoom-in" />
                      </div>
                      <div>
                        <div>That's Not All</div>
                        <div>
                          You can find more if you try something more specific
                        </div>
                      </div>
                    </div>
                  )}
                  {result && !result.length && (
                    <div className={styles.empty}>
                      <div>
                        <Icon iconSize={30} icon="zoom-out" />
                      </div>
                      <div>
                        <div>Nothing Found</div>
                        <div>Try again with something less specific</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}
