import { Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setBullhornActive, setDialog } from "../../../app/appSlice.js";
import { getToken } from "../../../app/topSlice.js";
import { AddUser } from "../../login/icons.tsx";
import { dispatchToast } from "../../toasts/Toasts.js";
import styles from "../BhDialogs.module.css";
import bullhornLogo from "../bullhorn.png";
import {
  fetchContacts,
  fetchFileInfos,
  postConfig,
  selectDefaultCountryCode,
  selectNonformattedFiletypes,
  selectStatus,
} from "../bullhornSlice.js";
import BhDefaultCountryCodeConfig from "./BhDefaultCountryCodeConfig.js";
import BhNonformattedFiletypesConfig from "./BhNonformattedFiletypesConfig.js";
import BhStatusConfig from "./BhStatusConfig.js";

export default function Config({ dispatch, active }) {
  const status = useSelector(selectStatus);
  const nonformattedFiletypes = useSelector(selectNonformattedFiletypes);
  const defaultCountryCode = useSelector(selectDefaultCountryCode);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const sync = async () => {
    setDisableSaveButton(true);
    const toast = dispatchToast(dispatch, {
      message: "Saving config.",
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });
    const accessToken = await dispatch(getToken());
    for (const [name, { dbName, filter: value }] of Object.entries(status)) {
      const response = await postConfig(dbName, value, accessToken);
      if (!response?.ok) {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
        setDisableSaveButton(false);
        return;
      }
    }

    let response = await postConfig(
      defaultCountryCode.dbName,
      defaultCountryCode.countryCode,
      accessToken
    );
    if (!response?.ok) {
      toast.replace({
        message: "Something went wrong, please try again.",
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      setDisableSaveButton(false);
      return;
    }

    response = await postConfig(
      nonformattedFiletypes.dbName,
      nonformattedFiletypes.filter,
      accessToken
    );
    if (!response?.ok) {
      toast.replace({
        message: "Something went wrong, please try again.",
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      setDisableSaveButton(false);
      return;
    }

    toast.replace({
      message: "Config saved.",
      icon: "CHECK",
      intent: Intent.PRIMARY,
    });

    dispatch(setBullhornActive(false));
    dispatch(fetchContacts());
    dispatch(fetchFileInfos());
    setDisableSaveButton(false);
    dispatch(setDialog());
  };

  return (
    <Dialog
      isOpen={active}
      onClose={() => {
        dispatch(setBullhornActive(false));
        dispatch(setDialog());
      }}
      title={
        <div className={styles.title}>
          <AddUser size={34} />
          <span className={styles.dialogTitle}>Configure Bullhorn</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <div className={styles.scrollable}>
          <BhStatusConfig dispatch={dispatch} />
          <br />
          <br />
          <BhNonformattedFiletypesConfig dispatch={dispatch} />
          <br />
          <br />
          <BhDefaultCountryCodeConfig dispatch={dispatch} />
        </div>
        <div className={styles.dialogBottomRow}>
          <img src={bullhornLogo} className={styles.img}></img>
          <button className={styles.dialogButton} onClick={(e) => sync()}>
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
}
