import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { PANE_ADMIN, hidePane } from "../layout/layoutSlice";
import UserList from "./UserList";
import LogoResize from "./LogoResize";
import DocTag from "../doctag";

import { appState } from "../../app/appState.js";

import {
  WORKSPACE_LIST_TEAMS,
  WORKSPACE_UPLOAD_LOGO,
} from "../../app/permissions.js";

import styles from "./Admin.module.css";

const {
  selectors: { selectPermissions },
} = appState;

export const Admin = ({ /*ani,*/ pane: { logo, tag } }) => {
  const dispatch = useDispatch();
  const [ani, setAni] = useState(true);
  const permissions = useSelector(selectPermissions);

  useEffect(() => {
    setTimeout(() => setAni(false), 500);
  }, []);

  if (ani) {
    return null;
  }

  let component;

  if (logo) {
    component = <LogoResize />;
  } else if (tag) {
    component = <DocTag />;
  } else {
    component = <UserList />;
  }

  return (
    <div className={styles.outer}>
      <div
        className={styles.close}
        onClick={() => dispatch(hidePane(PANE_ADMIN))}
      >
        <Icon icon="cross" />
      </div>
      {/*logo ? <LogoResize /> : <UserList />*/}
      {component}
    </div>
  );

  //const list = permissions[WORKSPACE_LIST_TEAMS] && (
  //  <Tab id="users" title="Users" panel={<UserList />} />
  //);
  //const logo = permissions[WORKSPACE_UPLOAD_LOGO] && (
  //  <Tab id="logo" title="Logo" panel={<LogoResize />} />
  //);

  //return (
  //  <Tabs animate={true} large={true}>
  //    {list}
  //    {logo}
  //    <Tabs.Expander />
  //  </Tabs>
  //);
};
