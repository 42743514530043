import Key from "./key";

export const TAG_LENGTH_IN_BITS = 128;

export default class SecretKey extends Key {
  constructor(key) {
    super(key);
  }

  static async agree(publicECDHKey, privateECDHKey) {
    throw new Error("Not implemented.");
  }

  async encrypt(data, iv, aad) {
    throw new Error("Not implemented.");
  }

  async decrypt(data, iv, aad) {
    throw new Error("Not implemented.");
  }

  static getEncryptedLength(dataLength) {
    // AES (no padding)
    return dataLength + TAG_LENGTH_IN_BITS / 8;
  }

  static getDecryptedLength(dataLength) {
    // AES (no padding)
    return dataLength - TAG_LENGTH_IN_BITS / 8;
  }
}
