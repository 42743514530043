import { classNames } from "utils";
import { DropDown } from "../overlays/WidgetAttestation.js";
import { isSwedishOrganizationNumber } from "./utils";
import styles from "./Wizard.module.css";

export const Edit = ({ setSelected, selectedUsers }) => {
  const handleEdit = (user) => {
    setSelected(
      selectedUsers.map((userEdit) => {
        if (userEdit.id === user.id) {
          return {
            ...userEdit,
            [user.type]: user[user.type],
          };
        } else {
          return userEdit;
        }
      })
    );
  };

  return (
    <div className={styles.edit}>
      {selectedUsers.map((user, index) => {
        return (
          <DropDown
            key={index}
            icon={"user"}
            title={user.text[0][0]}
            children={<EditItem user={user} handleEdit={handleEdit} />}
          />
        );
      })}
    </div>
  );
};

const EditItem = ({ user, handleEdit }) => {
  return (
    <div className={styles.form}>
      <label>Full name</label>
      <input
        defaultValue={user.name}
        onChange={(e) => {
          handleEdit({ ...user, name: e.target.value, type: "name" });
        }}
      />
      <label>Company name</label>
      <input
        type="text"
        defaultValue={user.companyName}
        onChange={(e) => {
          handleEdit({
            ...user,
            companyName: e.target.value,
            type: "companyName",
          });
        }}
      />
      <label>Organization number</label>
      <input
        className={classNames(
          !isSwedishOrganizationNumber(user.orgNo) && styles.invalid
        )}
        defaultValue={user.orgNo}
        onChange={(e) => {
          handleEdit({ ...user, orgNo: e.target.value, type: "orgNo" });
        }}
      />
    </div>
  );
};
