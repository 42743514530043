import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Icon } from "@blueprintjs/core";
import { classNames, request, jwtPeek, authReq } from "utils";
import appContext from "../../app/appContext";
import { hidePane, PANE_SEARCH } from "../layout/layoutSlice";
import styles from "./Search.module.css";
import { getToken } from "../../app/topSlice.js";
import { iconFactory, FolderIcon } from "../../helpers/tree-icons";
import { ARG_DATE } from "../chat/derive";
import { CUSTOM } from "../contexts/Contexts.js";
import { useContextMenu } from "../contexts/Popover.js";
import { FILE_PREVIEW, FILE_DOWNLOAD } from "../../app/permissions.js";
import { appState } from "../../app/appState.js";
import { API_URL_PREFIX } from "../../config.js";

const {
  selectors: { selectGraph },
  actions: { expandNode, setCurrentNode },
} = appState;

const getPage = async (accessToken, query, zoneId, pageNumber, pageSize) => {
  query = query.replace(/[^a-zA-Z0-9]+/, "-");
  const url =
    API_URL_PREFIX +
    `/searchteam?q=${query}&teamId=${zoneId}&page=${pageNumber}&pageSize=${pageSize}`;
  const { user: userId } = jwtPeek(accessToken);
  const result = await request(
    url,
    authReq({
      accessToken,
      userId,
    })
  );
  return result;
};

export const getParents = async (accessToken, zoneId, fileId) => {
  const url = API_URL_PREFIX + `/fileparent?teamId=${zoneId}&fileId=${fileId}`;
  const { user: userId } = jwtPeek(accessToken);
  const result = await request(
    url,
    authReq({
      accessToken,
      userId,
    })
  );
  return result;
};

export function Search({ ani }) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [prev, setPrev] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [page, setPage] = useState(1);
  const resultRef = useRef(null);
  const expandPath = useExpandNodesFromPath();
  const context = useContext(appContext);
  const { zoneId } = context;

  useEffect(() => {
    setQuery("");
    setPrev("");
    setPage(0);
    setNodes([]);
  }, [zoneId]);

  const doSearch = async (_page = 0) => {
    if (isSearching || (query === prev && _page === page)) {
      return;
    }

    if (!query) {
      setPrev(query);
      setPage(0);
      setNodes([]);
      return;
    }

    setIsSearching(true);

    try {
      const accessToken = await dispatch(getToken());
      const pageSize = Math.floor(resultRef.current.offsetHeight / 56);
      const result = await getPage(accessToken, query, zoneId, _page, pageSize);

      setPrev(query);
      setPage(_page);
      setNodes(result);
    } finally {
      setIsSearching(false);
    }
  };

  if (ani) {
    return null;
  }

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <div className={classNames(styles.header, styles.no_hover)}>
          <span>File Search</span>
        </div>
        <div
          className={styles.header}
          onClick={() => dispatch(hidePane(PANE_SEARCH))}
        >
          <Icon icon="cross" />
        </div>
      </div>
      <div className={styles.rest}>
        <div className={styles.search}>
          <Icon
            icon="search"
            className={styles.searchIcon}
            onClick={doSearch}
          />
          <input
            autoFocus
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && doSearch()}
          />
        </div>
      </div>
      <div ref={resultRef} className={classNames(styles.scroll)}>
        <Result
          {...{
            query,
            prev,
            isSearching,
            zoneId,
            nodes,
            page,
            expandPath,
          }}
        />
      </div>
      <div className={styles.pagination}>
        {prev && (
          <>
            <Button
              icon="chevron-left"
              minimal={true}
              className={styles.paginationButton}
              disabled={page === 0}
              onClick={() => {
                doSearch(page - 1);
              }}
            />
            <div className={styles.pageNumber}>Page {page + 1}</div>
            <Button
              icon="chevron-right"
              className={styles.paginationButton}
              minimal={true}
              disabled={nodes.length === 0}
              onClick={() => {
                doSearch(page + 1);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

const Result = ({ prev, isSearching, zoneId, nodes, page, expandPath }) => {
  if (isSearching) {
    return <div className={styles.searchStatusMessage}>Searching...</div>;
  } else if (!nodes.length && prev) {
    if (page === 0) {
      return <div className={styles.searchStatusMessage}>Nothing Found</div>;
    } else {
      return <div className={styles.searchStatusMessage}>End of Result</div>;
    }
  } else {
    return nodes.map((node) => (
      <Item
        item={node}
        zoneId={zoneId}
        expandPath={expandPath}
        key={"node-search-result-" + node.id}
      />
    ));
  }
};

const Item = ({ item, zoneId, expandPath }) => {
  const dispatch = useDispatch();
  const refFile = useContextMenu(CUSTOM, {
    actions: [FILE_PREVIEW, FILE_DOWNLOAD],
    item,
  });
  const isFile = !!item.storageId;

  const onDoubleClick = async (item) => {
    const accessToken = await dispatch(getToken());
    const parents = await getParents(accessToken, zoneId, item.id);
    expandPath(parents);
  };

  let icon = (
    <FolderIcon
      full={!item.empty}
      open={item.expanded}
      special={item.restricted}
    />
  );

  if (isFile) {
    icon = iconFactory(item.name);
  }

  return (
    <div
      className={classNames(styles.row)}
      ref={refFile}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onDoubleClick(item);
      }}
    >
      <div className={classNames(styles.item, styles.icon)}>{icon}</div>
      <div className={classNames(styles.item, styles.name)} title={item.name}>
        <span>{item.name}</span>
      </div>
      <div
        className={classNames(styles.item, styles.date)}
        title={new Date(item.timestamp).toLocaleString("en-GB")}
      >
        <span>{new Date(item.timestamp).toLocaleDateString(...ARG_DATE)}</span>
      </div>
    </div>
  );
};

export const useExpandNodesFromPath = () => {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  let [path, setPath] = useState(undefined);
  useEffect(() => {
    if (path && path.length !== 0 && path[0] in graph.nodes) {
      if (path.length > 1) {
        dispatch(expandNode({ id: path[0], expanded: true }));
        setPath(path.slice(1));
      } else {
        dispatch(setCurrentNode(path[0]));
        setPath(undefined);
        // useEffect runs after render (all renders?),
        // so it should be safe to assume this dom el can be found
        document
          .querySelector(`[data-rbd-drag-handle-draggable-id="${path[0]}"]`)
          ?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
      }
    }
  }, [graph, path]);
  return setPath;
};
