import { Data } from "../";

import {
  Certificate,
  berDecodeSignature,
  importPublicECDHKey,
  validateCertificateChain,
} from "../crypto";

const _fetch = typeof window === "undefined" ? require("node-fetch") : fetch;

/*******************/

export default class KDSClient {
  constructor(host, port, rootCertificate, issuer) {
    this.host = host;
    this.port = port;
    this.rootCertificate = rootCertificate;
    this.issuer = issuer;
  }

  async fetch(deviceId) {
    const protocol = this.port === 80 ? "http" : "https";
    const base = this.port ? `${this.host}:${this.port}` : `${this.host}`;
    const response = await (
      await _fetch(`${protocol}://${base}/public/${this.issuer}/${deviceId}`)
    ).json();

    const certificate = Certificate.importBER(
      Data.fromBase64(response.certificate)
    );

    if (
      !(await validateCertificateChain(this.rootCertificate, [certificate]))
    ) {
      throw new Error("Could not verify certificate chain in response.");
    }

    const publicECDSAKey = await certificate.getPublicKey();
    const publicECDHKey = await importPublicECDHKey(
      Data.fromBase64(response.ecdh_public_key),
      "spki"
    );
    let publicECDHKeySignature = berDecodeSignature(
      Data.fromBase64(response.ecdh_public_key_signature)
    );

    if (
      !(await publicECDSAKey.verify(
        Data.fromBase64(response.ecdh_public_key),
        publicECDHKeySignature
      ))
    ) {
      throw new Error("Could not verify public negotiation key in response.");
    }

    if (certificate.subject !== deviceId) {
      throw new Error("Response did not contain requested device ID.");
    }

    return {
      publicECDHKey,
      publicECDSAKey,
    };
  }
}
