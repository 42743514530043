import { createSlice } from "@reduxjs/toolkit";

export const editorSlice = createSlice({
  name: "editor",
  initialState: {
    showEditor: false
  },
  reducers: {
    setShowEditor: (state, { payload }) => {
      state.showEditor = payload;
    }
  }
});

export const { setShowEditor } = editorSlice.actions;

export const editFile = file => dispatch => {
  window.__edit_file = file;
  dispatch(setShowEditor(true));
};

export const closeEditor = () => dispatch => {
  delete window.__edit_file;
  dispatch(setShowEditor(false));
};

export const selectShowEditor = state => state.editor.showEditor;

export default editorSlice.reducer;
