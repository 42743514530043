export const MESSAGE_NONCE_LENGTH_IN_BYTES = 8;
export const RIKS_KEY_LENGTH_IN_BYTES = 32;
export const RIKS_KEY_ID_LENGTH_IN_BYTES = 8;
export const SIGNATURE_LENGTH_IN_BYTES = 64;

// Packet header keys
export const TYPE = "TYPE";
export const VERSION = "VERSION";
export const KEY = "KEY";
export const KEY_ID = "KEY_ID";
export const SENDER = "SENDER";
export const SECRET = "SECRET";
export const IMMUTABLE = "IMMUTABLE";
export const NONCE = "NONCE";
export const SIGNATURE = "SIGNATURE";

// Packet header values
export const TYPE_MESSAGE = "MESSAGE";
export const TYPE_KEY = "KEY";
export const TYPE_REQUEST = "REQUEST";
export const TYPE_DENY = "DENY";
export const VERSION_VALUE = "4.0.0";
