import React, { useEffect, useState } from "react";
import QRLib from 'qrcode';
import { QRCodeErrorCorrectionLevel } from 'qrcode';
import { Spinner } from "@blueprintjs/core";

interface QRCodeOptions {
    width: number;
    margin: number;
    errorCorrectionLevel: QRCodeErrorCorrectionLevel;

}

interface QRCodeProps {
    qrCode: string;

}

const qrCodeOptions: QRCodeOptions = {
    width: 200,
    margin: 3,
    errorCorrectionLevel: 'L',
};

export const QRCode: React.FC<QRCodeProps> = ({ qrCode }) => {
    const [qrImage, setQrImage] = useState<string | undefined>("");

    useEffect(() => {
        let isMounted = true;

        QRLib.toDataURL(qrCode, qrCodeOptions)
            .then((url) => {
                if (isMounted) {
                    setQrImage(url);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setQrImage(undefined);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [qrCode]);

    return (
        !qrImage ?
            <Spinner />
            :
            <img src={qrImage} alt={'qr-code'} />
    );
}
