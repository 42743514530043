export default ({ options: { name } }) => ({
  selectInitialized: (state) => state[name].initialized,
  selectGraph: (state) => state[name].graph,
  selectZones: (state) => state[name].graph.zones,
  selectNodes: (state) => state[name].graph.nodes,
  selectUsers: (state) => state[name].graph.users,
  selectGroups: (state) => state[name].graph.groups,
  selectZone: (state) => (id) => state[name].graph.zones[id],
  selectNode: (state) => (id) => state[name].graph.nodes[id],
  selectGroup: (state) => (id) => state[name].graph.groups[id],
  selectMember: (state) => (id) => state[name].graph.users[id],
  selectCategories: (state) => state[name].categories,
  selectPermissions: (state) => state[name].permissions,
  selectTemplatesCount: (state) => state[name].templatesCount,
  selectZonesSearchTerm: (state) => state[name].zonesSearchTerm,
  selectCanLoadMore: (state) => state[name].canLoadMore,
  selectTimeSkew: (state) => state[name].timeSkew,
});
