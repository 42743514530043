export const isBrowser = typeof window !== "undefined";
//const require = isBrowser ? () => { throw 'no require' } : global.require;
//export const fetch = isBrowser ? window.fetch : require("./node-fetch").default;
export const fetch = isBrowser ? window.fetch : require("node-fetch");

// hack to prevent CRA not to greedly require node modules
//const _require = isBrowser || require;
//const _require = () => require;

/// CLI ////////////////////////////////////////////////////////////////////////

export const cli = async (theModule, customCommands) => {
  if (!isBrowser && require.main === theModule) {
    (async () => {
      try {
        const commands = {
          ...customCommands,
          testStore,
          passwordProtectText,
          unPasswordProtectText,
          hash,
        };
        const [name, ...args] = process.argv.slice(2);

        if (!name || !(name in commands)) {
          entries(commands).forEach(([name, func]) => {
            console.log(name, ...getArgs(func));
          });
        } else {
          console.log(await commands[name](...args));
        }
        process.exit(0);
      } catch (e) {
        console.error(e);
        process.exit(1);
      }
    })();
  }
};

/// CORE ///////////////////////////////////////////////////////////////////////

export const rand = (_) =>
  Math.random().toString(36).slice(2, 12).padEnd(10, "0");

export const range = (n) => [...Array(n).keys()];

export const intersect = (a, b) => a.filter((x) => b.includes(x));

export const diff = (a, b) => a.filter((x) => !b.includes(x));

export const diffEq = (eq) => (as, bs) =>
  as.filter((a) => !bs.some((b) => eq(a, b)));

export const symDiff = (a, b) =>
  a.filter((x) => !b.includes(x)).concat(b.filter((x) => !a.includes(x)));

export const diffObj = (a, b) => filterObj(a, (k) => !(k in b));

export const startsWithExclamationMark = (s) =>
  isString(s) && s.startsWith("!");

export const format = (str) =>
  str.replace(/([A-Z])/g, (s) => " " + s.toLowerCase());

export const camelToKebab = (str) =>
  str.replace(/([A-Z])/g, (s) => "-" + s.toLowerCase());

//export const snakeToCamel = str =>
//  str.toLowerCase().replace(/(\_\[A-Z])/g, s => s[1].toUpperCase());

export const snakeToCamel = (str) =>
  str.toLowerCase().replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

const AsyncFunction = (async () => {}).constructor;
const GeneratorFunction = function* () {
  yield 0;
}.constructor;
const AsyncGeneratorFunction = async function* () {
  yield 0;
}.constructor;

export const isBoolean = (x) => x === true || x === false;
export const isString = (x) => typeof x === "string" || x instanceof String;
export const isArray = (x) => Array.isArray(x);
export const isObject = (x) => x !== null && typeof x === "object";
export const isDate = (x) => x !== null && typeof x.getFullYear === "function";
export const isMap = (x) => isObject(x) && !isArray(x) && !isDate(x);
export const isUndefined = (x) => x === undefined;
export const isFunction = (x) => typeof x === "function";
export const isNumber = (n) => !isNaN(n);
//export const isInteger = n =>
//  typeof n === "number" && Number.isFinite(n) && Number.isSafeInteger(n);
export const isInteger = (n) => Number.isSafeInteger(n);
export const isPromise = (x) => isObject(x) && isFunction(x.then);
export const isGenerator = (x) => isObject(x) && isFunction(x.next);
export const isIdentifier = (x) => isInteger(x) || isString(x);
export const isNullish = (x) => x === null || x === undefined;
export const isAsyncFunction = (x) => x instanceof AsyncFunction;
export const isGeneratorFunction = (x) => x instanceof GeneratorFunction;
export const isAsyncGeneratorFunction = (x) =>
  x instanceof AsyncGeneratorFunction;

export const { assign, keys, values, entries, fromEntries, freeze } = Object;

export const { stringify, parse: parseJson } = JSON;

export const frank = (str = "", left = "{", right = "}", offset = 0) => {
  let startIndex = str.indexOf(left, offset),
    pos = startIndex,
    openBrackets = 0,
    waitForChar = false;

  while (-1 < pos && pos < str.length) {
    let currChar = str.charAt(pos);

    if (!waitForChar) {
      switch (currChar) {
        case left:
          openBrackets++;
          break;
        case right:
          openBrackets--;
          break;
        case '"':
        case "'":
          waitForChar = currChar;
          break;
        case "/":
          let nextChar = str.charAt(pos + 1);
          if (nextChar === "/") {
            waitForChar = "\n";
          } else if (nextChar === "*") {
            waitForChar = "*/";
          }
      }
    } else {
      if (currChar === waitForChar) {
        if (waitForChar === '"' || waitForChar === "'") {
          if (str.charAt(pos - 1) !== "\\") {
            waitForChar = false;
          }
        } else {
          waitForChar = false;
        }
      } else if (currChar === "*") {
        if (str.charAt(pos + 1) === "/") {
          waitForChar = false;
        }
      }
    }

    if (openBrackets === 0) {
      break;
    }

    pos++;
  }

  if (pos <= -1 || str.length <= pos) {
    return -1;
  }

  return pos + 1;

  //return block.slice(startIndex, currPos + 1);
};

export const dict = (arr) =>
  arr.length ? assign(...arr.map(([k, v]) => ({ [k]: v }))) : {};

//export const path = (obj, ...path) => {
//  const def = isIdentifier(path.slice(-1)[0]) ? null : path.splice(-1)[0];
//  return (path.forEach(path => (obj = (obj || {})[path] || null)), obj) || def;
//};
//
//export const touch = (object, ...path) => {
//  const def = isIdentifier(path.slice(-1)[0]) ? null : path.pop(); //.splice(-1)[0];
//  return path.reduce((obj, id) => (obj[id] = obj[id] || def || {}), object);
//};

export const setDeep =
  (o) =>
  (...p) =>
  (d) =>
    p.reduce((o, s, i) => (o[s] = !(++i in p) ? d : s in o ? o[s] : {}), o);

export const getDeep =
  (o) =>
  (...p) =>
  (...d) =>
    //p.reduce((o, s, i) => (s in o ? o[s] : ++i in p || !(0 in d) ? {} : d[0]), o);
    p.reduce(
      (o, s, i) =>
        isObject(o) && s in o ? o[s] : ++i in p || !(0 in d) ? {} : d[0],
      o
    );

export const touch =
  (o) =>
  (...p) =>
  (...d) =>
    p.reduce(
      (o, s, i) => (s in o ? o[s] : (o[s] = ++i in p || !(0 in d) ? {} : d[0])),
      o
    );

export const index = (l) => l.map((e, i) => [e, i, l.length]);

//export const pick = (object, ...props) =>
//  assign({}, ...props.map(prop => ({ [prop]: object[prop] })));

export const pick = (o, ...ps) =>
  assign({}, ...ps.map((p) => (p in o ? { [p]: o[p] } : {})));

export const without = (object, ...props) =>
  filterObj(object, (k) => !props.includes(k));

export const maybe = (object) => filterObj(object, (_, value) => value);

export const sortKeys = (obj, _) => (
  (_ = entries(obj)), dict(_.sort((a, b) => a[0].localeCompare(b[0])))
);

export const sortList = (r) =>
  r.sort((a, b) =>
    toString(a).localeCompare(toString(b), "en", { sensitivity: "base" })
  );

export const sortObject = (x) => {
  if (isArray(x)) {
    return sortList(x.map(sortObject));
  } else if (isMap(x)) {
    return sortKeys(mapObj(x, sortObject));
  }
  return x;
};

//export const mapObj = (o, f) =>
//  entries(o).reduce((r, [k, v]) => ((r[k] = f(v, k)), r), {});

export const mapObj = (obj, func) => {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    result[key] = func(value, key);
  }
  return result;
};

export const mapMap = (o, f) =>
  Array.from(o.entries()).reduce((r, [k, v]) => ((r[k] = f(v, k)), r), {});

export const transformObj = (o, f) =>
  entries(o).reduce((r, [k, v]) => (([k, v] = f(k, v)), (r[k] = v), r), {});

export const filterObj = (o, p) =>
  entries(o).reduce((r, [k, v]) => (p(k, v) && (r[k] = v), r), {});

//export const pairsToObj = pairs => mapMap(new Map(pairs), _ => _);

export const nonEmpty = (array) => (array.length ? array : null);

export const nonEmptyObj = (obj) => (keys(obj).length ? obj : null);

export const nonEmptyItems = (array) => (array.length ? array : null);

export const nullishFilter = (list) => list.filter((item) => !isNullish(item));

export const nullishFilterObj = (x) => filterObj(x, (k, v) => !isNullish(v));

export const clearObj = (obj) => keys(obj).forEach((key) => delete obj[key]);

export const clearArray = (arr) => arr.splice(0, arr.length);

export const mergeArrays = (a, b) =>
  new Array(Math.max(a.length, b.length)).fill().map((e, i) => a[i] || b[i]);

export const concatMap = (arr, f) => [].concat(...arr.map(f));

export const flatten = (arr) => [].concat(...arr);

export const findIndices = (arr, pred) =>
  arr.reduce((acc, el, i) => (pred(el) ? [...acc, i] : acc), []);

export const indexOfAll = (arr, val) => findIndices(arr, (el) => el === val);

export const remove = (r, ...xs) =>
  xs.flatMap((x, i) => (~(i = r.indexOf(x)) && r.splice(i, 1)) || []);

export const removeAll = (r, ...xs) =>
  xs.flatMap((x) => indexOfAll(r, x).flatMap((i, j) => r.splice(i - j, 1)));

export const searchRemove = (list, find, i) =>
  (~(i = list.findIndex(find)) && list.splice(i, 1)) || [];

export const searchRemoveAll = (list, find /*, i*/) =>
  findIndices(list, find).flatMap((i, j) => list.splice(i - j, 1));

export const setArray = (array) => [...new Set(array)];

export const eqArray = (a, b, A = setArray(a), B = setArray(b)) =>
  A.length == B.length && A.every((x) => B.some((y) => x == y));

export const toString = (obj, pretty) =>
  isString(obj) ? obj : stringify(obj, ...(pretty ? [null, 2] : []));

export const toStringSorted = (obj, pretty) =>
  toString(sortObject(obj), pretty);

export const s = (strs, ...objs) =>
  strs[0] + objs.map((obj, i) => toStringSorted(obj) + strs[i + 1]).join("");

export const substitute = (x, db = {}) => {
  if (isArray(x)) {
    return x.map((x) => substitute(x, db));
  } else if (isMap(x)) {
    return transformObj(x, (k, v) => [substitute(k, db), substitute(v, db)]);
  } else if (isString(x)) {
    return x.replace(/<[^+]+>/, (m) => db[m] || (db[m] = rand()));
  }
  return x;
};

export const clone = (x) => {
  if (isArray(x)) {
    return x.map(clone);
  } else if (isMap(x)) {
    return mapObj(x, clone);
  }
  return x;
};

export const traverseMap = (x, map) => {
  const traverse = (x) => {
    if (isArray(x)) {
      return x.map(traverse);
    } else if (isMap(x)) {
      return mapObj(x, traverse);
    }
    return map(x);
  };
  return traverse(x);
};

export const distinct = (list, ...items) => (
  list.splice(0, list.length, ...new Set([...list, ...items])), list
);

//export const distinctEq = eq => (li, ...xs) =>
//  [...li, ...xs].reduce((r, a) => (r.some(b => eq(a, b)) ? r : [...r, a]), []);

export const distinctEq =
  (eq) =>
  (li, ...xs) => (
    li.push(...xs),
    li.reduceRight(
      (_, a, i) => li.splice(i, +li.some((b, j) => j < i && eq(a, b))),
      0
    ),
    li
  );

export const changesDiff = (diff) => (a, b) => [diff(a, b), diff(b, a)];

export const changes = changesDiff(diff);

export const fullFill = (n, f) => Promise.all([...Array(n)].map(f));

export const nil = () => new Proxy(() => {}, new Proxy({}, { get: () => nil }));

export const trial =
  (f, ...maybeDefault) =>
  (...args) => {
    try {
      return f(...args);
    } catch (e) {
      if (maybeDefault.length) {
        return maybeDefault[0];
      }
      return { __error: e };
    }
  };

export const funcName = (func) => /(\w+)\(/.exec(func)[1];

export const getArgs = (f) =>
  `${f}`.replace(/^[\w\s]*\(|\)[^]*|=[^]*|\s/g, "").split(",");

export const kwargs = (args, ...keys) => {
  for (let i = 0; i < keys.length; i++) {
    args[keys[i]] = args[i];
  }
  return new Proxy(args, {
    set(target, property, value, receiver) {
      if (keys.includes(property)) {
        Reflect.set(target, keys.indexOf(property), value, receiver);
      } else if (!isNaN(property) && property < keys.length) {
        Reflect.set(target, keys[property], value, receiver);
      }
      return Reflect.set(target, property, value, receiver);
    },
  });
};

export const proxy = (obj, { get, set }) => {
  const handler = {
    get(...args) {
      if (get) {
        get(kwargs(args, "target", "property", "receiver"));
      }
      const obj = Reflect.get(...args);
      if (isArray(obj) || isMap(obj)) {
        return new Proxy(obj, handler);
      }
      return obj;
    },
    set(...args) {
      if (set) {
        set(kwargs(args, "target", "property", "value", "receiver"));
      }
      return Reflect.set(...args);
    },
  };
  return new Proxy(obj, handler);
};

export const freezeProxy = (obj) => {
  const handler = {
    get(...args) {
      const obj = Reflect.get(...args);
      if (isArray(obj) || isMap(obj)) {
        return new Proxy(obj, handler);
      }
      return obj;
    },
    set(...args) {
      console.log("mutation attempt on frozen object:", ...args);
    },
    deleteProperty(...args) {
      console.log("deletion attempt on frozen object:", ...args);
    },
  };
  return new Proxy(obj, handler);
};

export const freezeDeep = (object) => {
  if (isObject(object)) {
    freeze(object);
    values(object).forEach(freezeDeep);
  }
};

export const createJsonChecksum = async (o) => await hash(toStringSorted(o));

export const jwtPeek = (jwt) => JSON.parse(b642Utf8(jwt.split(".")[1]));

export const fileExt = (name) => (isString(name) ? name : "").split(".").pop();

export const fileSizeHuman = (bytes = 0, decimals = 1, units = null) => {
  units = units || ["B", "KB", "MB", "GB", "TB"];
  let size = 0,
    unit = "B";
  if (bytes > 0) {
    const power = Math.min(
      Math.floor(Math.log(bytes) / Math.log(1000)),
      units.length - 1
    );
    unit = units[power];
    size =
      Math.round((Math.pow(10, decimals) * bytes) / Math.pow(1000, power)) /
      Math.pow(10, decimals);
  }
  return { size, unit, label: `${size} ${unit}` };
};

/// ENCODING ///////////////////////////////////////////////////////////////////

export const buffer2Hex = (data) =>
  isBrowser
    ? bytes.reduce(
        (data, byte) => data + byte.toString(16).padStart(2, "0"),
        ""
      )
    : data.toString("hex");

export const hex2Buffer = (data) =>
  isBrowser
    ? new Uint8Array(data.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)))
    : Buffer.from(data, "hex");

export const utf82B64 = (data) =>
  isBrowser ? btoa(data) : Buffer.from(data, "utf8").toString("base64");

export const b642Utf8 = (data) =>
  isBrowser ? atob(data) : Buffer.from(data, "base64").toString("utf8");

export const utf82B64Url = (data) =>
  utf82B64(data).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

export const b64Url2Utf8 = (data) =>
  b642Utf8(
    data
      .replace(/-/g, "+")
      .replace(/_/g, "/")
      .replace(/$/, "=".repeat(3 - ((data.length + 3) % 4)))
  );

export const b642Buffer = (data) =>
  isBrowser
    ? Uint8Array.from(atob(data), (c) => c.charCodeAt(0))
    : Buffer.from(data, "base64");

//export const buffer2B64 = data =>
//  isBrowser
//    ? //btoa(new TextDecoder('utf8').decode(data)) :
//      btoa(String.fromCharCode(...data))
//    : Buffer.from(data).toString("base64");

export const buffer2B64 = (uint8Array) => {
  if (!isBrowser) {
    return Buffer.from(uint8Array).toString("base64");
  }
  let bytes = "";
  for (let i = 0; i < uint8Array.length; i += 1024) {
    bytes += String.fromCharCode(...uint8Array.slice(i, i + 1024));
  }
  return btoa(bytes);
};

export const buffer2B64Url = (data) =>
  buffer2B64(data).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

export const pack = (num) => {
  let str = String.fromCharCode(((num & 0xf000) >> 12) + 97);
  str += String.fromCharCode(((num & 0x0f00) >> 8) + 97);
  str += String.fromCharCode(((num & 0x00f0) >> 4) + 97);
  return str + String.fromCharCode((num & 0x000f) + 97);
};

export const unpack = (str) => {
  let num = str.charCodeAt(0) - 97;
  num = (str.charCodeAt(1) - 97) | (num << 4);
  num = (str.charCodeAt(2) - 97) | (num << 4);
  return (str.charCodeAt(3) - 97) | (num << 4);
};

/// DOM ////////////////////////////////////////////////////////////////////////

export const classNames = (...x) => x.filter(isString).join(" ");

export const htmlToElement = (html) => {
  var template = document.createElement("template");
  template.innerHTML = html.trim();
  return template.content.firstChild;
};

export const htmlToElements = (html) => {
  var template = document.createElement("template");
  template.innerHTML = html;
  return template.content.childNodes;
};

export const normalizeCSS = (doc) => {
  const style = htmlToElement(`
    <style>
      *,
      *::before,
      *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
    </style>
  `);
  doc.head.append(style);
  return doc;
};

export const injectScript = (doc, src, onload) => {
  const script = doc.createElement("script");
  script.onload = (_) => onload && onload();
  script.src = src;
  doc.head.appendChild(script);
};

export const mobileFirst = (doc) => {
  let s = '<meta name="viewport" content="width=device-width,initial-scale=1">';
  return doc.head.append(htmlToElement(s)), doc;
};

export const attachEvents = (el, events) => (
  entries(events).forEach(([event, handle]) =>
    el.addEventListener(event.slice(2).toLowerCase(), handle)
  ),
  el
);

export const applyStyle = (el, styles) => (
  keys(styles).forEach((style) => (el.style[style] = styles[style])), el
);

export const createDocument = (title) =>
  document.implementation.createHTMLDocument(title);

export const replaceDocument = (doc) =>
  document.replaceChild(doc.documentElement, document.documentElement);

export const createElement = (tag = "div", text = "", el) => (
  (el = document.createElement(tag)), (el.innerHTML = text), el
);

export const installElement = (pear, kid) => {
  pear.innerHTML = "";
  pear.append(kid);
  return kid;
};

export const installElements = (pear, ...kids) => {
  pear.innerHTML = "";
  kids.forEach((kid) => pear.append(kid));
};

export const removeElement = (element) =>
  element.parentNode.removeChild(element);

export const stopEvent =
  (_) =>
  (f) =>
  (e, ...args) => {
    e.preventDefault();
    e.stopPropagation();
    return f(e, ...args);
  };

export const hide = (el, s = el.style, d = el.dataset) => (
  (s.visibility = "hidden"),
  (d._p = s.padding),
  (s.padding = s.maxHeight = s.maxWidth = 0)
);
export const show = (el, s = el.style, d = el.dataset) => (
  (s.visibility = s.maxHeight = s.maxWidth = null), (s.padding = d._p || null)
);

export const addRule = (selector, styles) => {
  const { sheet } =
    document.querySelector("head > style[data-wk6vZfvpAu]") ||
    document.head.appendChild(htmlToElement("<style data-wk6vZfvpAu></style>"));
  const css = isString(styles)
    ? styles
    : entries(styles)
        .map(([k, v]) => [k, k == "content" ? `'${v}'` : v])
        .map(([k, v]) => `${camelToKebab(k)}:${v}`)
        .join(";");
  console.log(`${selector}{${css}}`, sheet.cssRules.length);
  sheet.insertRule(`${selector}{${css}}`, sheet.cssRules.length);
};

export const applyPsuedo = (el, styles, pos, id) => (
  (id = rand()), (el.dataset[id] = ""), addRule(`[data-${id}]:${pos}`, styles)
);

export const applyBefore = (el, styles) => applyPsuedo(el, styles, "before");
export const applyAfter = (el, styles) => applyPsuedo(el, styles, "after");

export const applyX = (e, s, c, x) => (
  (x = rand()), (e.dataset[x] = ""), addRule(s.replace(/&/g, `[data-${x}]`), c)
);

/// UUID ///////////////////////////////////////////////////////////////////////

//export const uid = _ => uuid().replace(/-/g, "");

export const getRandomValues = isBrowser
  ? (r) => (window.crypto || window.msCrypto).getRandomValues(r)
  : (r) => (r.set(require("crypto").randomBytes(r.length)), r);

export const nanoid = (t = 21) => {
  let e = "",
    r = getRandomValues(new Uint8Array(t));
  for (; t--; ) {
    let n = 63 & r[t];
    e +=
      n < 36
        ? n.toString(36)
        : n < 62
        ? (n - 26).toString(36).toUpperCase()
        : n < 63
        ? "_"
        : "-";
  }
  return e;
};

export const uid = (enc = "base64Url") => {
  const rv = getRandomValues(new Uint8Array(16));
  //return enc == "hex" ? buffer2Hex(rv) : buffer2B64(rv).slice(0, 22);
  switch (enc) {
    case "base64":
      return buffer2B64(rv).replace(/=+$/, "");
    case "base64Url":
      return buffer2B64Url(rv);
    case "hex":
      return buffer2Hex(rv);
    default:
      return buffer2Hex(rv);
  }
};

// uuid returns an RFC 4122 compliant universally unique
// identifier using the crypto API
export const uuid = (_) => {
  const cryptoObj = (_) => window.crypto || window.msCrypto;
  const nodeGetRandomValues = (buf, _) =>
    ((_ = require("crypto").randomBytes(buf.length)) && buf.set(_)) || buf;
  const getRandomValues =
    typeof window !== "undefined"
      ? (_) => cryptoObj().getRandomValues(_)
      : nodeGetRandomValues;

  // get sixteen unsigned 8 bit random values
  var u = getRandomValues(new Uint8Array(16));

  // set the version bit to v4
  u[6] = (u[6] & 0x0f) | 0x40;

  // set the variant bit to "don't care" (yes, the RFC
  // calls it that)
  u[8] = (u[8] & 0xbf) | 0x80;

  // hex encode them and add the dashes
  var uid = "";
  uid += u[0].toString(16).padStart(2, "0");
  uid += u[1].toString(16).padStart(2, "0");
  uid += u[2].toString(16).padStart(2, "0");
  uid += u[3].toString(16).padStart(2, "0");
  uid += "-";

  uid += u[4].toString(16).padStart(2, "0");
  uid += u[5].toString(16).padStart(2, "0");
  uid += "-";

  uid += u[6].toString(16).padStart(2, "0");
  uid += u[7].toString(16).padStart(2, "0");
  uid += "-";

  uid += u[8].toString(16).padStart(2, "0");
  uid += u[9].toString(16).padStart(2, "0");
  uid += "-";

  uid += u[10].toString(16).padStart(2, "0");
  uid += u[11].toString(16).padStart(2, "0");
  uid += u[12].toString(16).padStart(2, "0");
  uid += u[13].toString(16).padStart(2, "0");
  uid += u[14].toString(16).padStart(2, "0");
  uid += u[15].toString(16).padStart(2, "0");

  return uid;
};

/// HTTP ///////////////////////////////////////////////////////////////////////

export const request = async (url, options = get(), reload = isBrowser) => {
  let response;
  try {
    response = await fetch(url, options);
  } catch (e) {
    throw "FAILED_TO_FETCH";
  }
  if (response.status === 418) {
    if (reload) {
      if (!window.__reloading) {
        window.__reloading = 1;
        location.reload(true);
      }
      return new Promise((_) => {});
    }
    throw "VERSION_MISMATCH";
  }
  if (response.status === 403) {
    throw "SERVER_DENIED_PERMISSION";
  }
  if (
    !options.expectedResponseType ||
    options.expectedResponseType === "json"
  ) {
    let json;
    try {
      json = await response.json();
    } catch (e) {
      throw new Error("bad response format");
    }
    if (response.status != 200) {
      throw new Error((json && json.error) || "bad response status code");
    }
    return json;
  } else {
    throw new Error("expected response type not supported");
  }
};

export const authReq = ({
  method,
  headers,
  expectedResponseType,
  accessToken,
  userId,
} = {}) => ({
  method: method || "get",
  headers: {
    ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
    ...(userId ? { "user-id": userId } : {}),
    ...(headers || {}),
  },
  expectedResponseType: expectedResponseType || "json",
});

export const get = (options) => authReq(options);

export const post = (data, { method, ...options } = {}) => ({
  ...authReq({ method: method || "post", ...options }),
  body: entries(data || {})
    .map(([k, v]) => encodeURIComponent(k) + "=" + encodeURIComponent(v))
    .join("&"),
});

export const json = (data, { method, headers, ...options } = {}) => ({
  ...authReq({
    method: method || "post",
    headers: {
      "Content-Type": "application/json",
      ...(headers || {}),
    },
    ...options,
  }),
  ...(data !== undefined && { body: stringify(data) }),
});

/// CONCURRENCY ///////////////////////////////////////////////////////////////////////

export const detach = (f) => setTimeout(f, 0);

export const pause = (ms) => new Promise((f) => setTimeout(f, ms));

export const future = (x, y) => [
  new Promise((a, b) => ((x = a), (y = b))),
  x,
  y,
];

export const // µ js concurrency //
  go = (cb) => new Promise(cb),
  all = (...args) => Promise.all(args),
  any = (...args) => Promise.race(args),
  to = (f, ms = 0) => setTimeout(f, ms),
  age = (v, ms) => go((r) => to(() => r(v), ms)),
  off = (ref) => clearTimeout(ref),
  delay = (t, f, r) => (r = to(f, t)) && ((_) => off(r)),
  ev = (set) => [go((res) => (set = res)), (ok) => set(ok)],
  sleep = (ms, r, x) => [go((ok) => (x = delay(ms, (_) => ok(r)))), x];

//export const debounce = (func, delay = 100) => {
//  let timeout, promise, resolve;
//  return function() {
//    promise = promise || new Promise(r => resolve = r);
//    clearTimeout(timeout);
//    timeout = setTimeout(() => {
//      promise = null;
//      resolve(func.apply(this, arguments));
//    }, delay);
//    return promise;
//  };
//};

export const debounce = (func, delay = 100, { ff = null, rej = null } = {}) => {
  let timeout,
    promise,
    rs = [];
  return function () {
    ff && rs.splice(0, rs.length).forEach((a) => a[rej % 2](null));
    const promise = new Promise((...a) => rs.push(a));
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const res = func.apply(this, arguments);
      rs.splice(0, rs.length - 1).forEach((a) => a[rej % 2](res));
      setTimeout(() => rs.splice(0, 1)[0][0](res), 0);
    }, delay);
    return promise;
  };
};

//export const throttle = (func, limit) => {
//  let timeout,
//    promise,
//    resolve,
//    latest = Date.now() - limit;
//  return function() {
//    promise = promise || new Promise(r => (resolve = r));
//    clearTimeout(timeout);
//    timeout = setTimeout(() => {
//      promise = null;
//      latest = Date.now();
//      resolve(func.apply(this, arguments));
//    }, limit - (Date.now() - latest));
//    return promise;
//  };
//};

export const throttle = (func, limit) => {
  let timeout,
    promise,
    resolve,
    latest = Date.now() - limit;
  return function () {
    clearTimeout(timeout);
    promise = promise || new Promise((r) => (resolve = r));
    const delay = latest + limit - Date.now();
    const call = () => {
      promise = null;
      latest = Date.now();
      const result = func.apply(this, arguments);
      resolve(result);
      return result;
    };
    if (delay <= 0) {
      return Promise.resolve(call());
    }
    timeout = setTimeout(call, delay);
    return promise;
  };
};

//export const createTimeout = ms => {
//  let expired, resolve,
//    promise = new Promise(f => resolve = f),
//    timeout = setTimeout(() => (expired = 1, resolve()), ms);
//  return {
//    timeout: promise,
//    expired: () => !!expired,
//    cancel: () => clearTimeout(timeout)
//  };
//}

export const createSignal = (...def) => {
  let resolve, promise, resolved;
  const notify = (...arg) => {
    const [result] = [...arg, ...def];
    if (resolve) {
      const recent = resolve;
      resolve = null;
      recent(result);
    } else {
      resolved = Promise.resolve(result);
    }
  };
  const wakeup = () => {
    if (resolved) {
      const temp = resolved;
      resolved = null;
      return temp;
    }
    if (!resolve) {
      promise = new Promise((f) => (resolve = f));
    }
    return promise;
  };
  return { notify, wakeup };
};

export const createMutex = (concurrent = 1) => {
  const queue = [];
  const isLocked = () => concurrent <= 0;
  const acquire = () => {
    const locked = isLocked();
    const ticket = new Promise((resolve) => queue.push(resolve));
    if (!locked) {
      dispatch();
    }
    return ticket;
  };
  const sync =
    (callback) =>
    async (...args) => {
      const [release, number] = await acquire();
      try {
        return await callback(...args /*, number*/);
      } finally {
        release();
      }
    };
  //const signal = async () => {
  //  const [release] = await acquire();
  //  release();
  //};
  const dispatch = () => {
    const consumer = queue.shift();
    if (consumer) {
      let released = false;
      consumer([
        () => {
          if (!released) {
            released = true;
            concurrent++;
            dispatch();
          }
        },
        concurrent--,
      ]);
    }
  };
  return { isLocked, acquire, sync /*, signal*/ };
};

export const createMemo =
  (m = {}, a = 0) =>
  (k, f) => (f || ([f, k] = [k, a++]), k in m ? m[k] : (m[k] = f()));

/// PROMPT /////////////////////////////////////////////////////////////////////

export const getPassword = async (ask = "password: ") => {
  if (isBrowser) {
    return prompt(ask);
  } else {
    let resolve,
      promise = new Promise((r) => (resolve = r));
    //const readline = _require()("readline");
    const readline = global.require("readline");
    const { stdin: input, stdout: output } = process;
    const rlif = readline.createInterface({ input, output });
    rlif.input.on("keypress", () => {
      readline.moveCursor(rlif.output, -rlif.line.length, 0);
      readline.clearLine(rlif.output, 1);
      rlif.output.write("*".repeat(rlif.line.length));
    });
    rlif.question(ask, resolve);
    const password = await promise;
    rlif.close();
    rlif.removeAllListeners();
    return password;
  }
};

/// CRYPTO /////////////////////////////////////////////////////////////////////

export const hash = async (text) => {
  if (isBrowser) {
    const encoded = new TextEncoder().encode(text);
    const buffer = await crypto.subtle.digest("SHA-256", encoded);
    return buffer2B64Url(new Uint8Array(buffer));
    //return btoa(array.reduce((d, b) => d + String.fromCharCode(b), ""))
    //  .replace(/\+/g, "-")
    //  .replace(/\//g, "_")
    //  .replace(/=+$/, "");
  } else {
    const data = require("crypto")
      .createHash("sha256")
      .update(Buffer.from(text, "utf8"))
      .digest(/*"base64"*/);
    return buffer2B64Url(data);
    //.replace(/\+/g, "-")
    //.replace(/\//g, "_")
    //.replace(/=+$/, "");
  }
};

export const hmac = async (text, key) => {
  if (isBrowser) {
    const textEncoder = new TextEncoder();
    const keyEncoded = textEncoder.encode(key);
    const textEncoded = textEncoder.encode(text);
    const type = { name: "HMAC", hash: { name: "SHA-256" } };
    const importArgs = ["raw", keyEncoded, type, false, ["sign", "verify"]];
    const keyImported = await crypto.subtle.importKey(...importArgs);
    const buffer = await crypto.subtle.sign("HMAC", keyImported, textEncoded);
    const array = new Uint8Array(buffer);
    return btoa(array.reduce((d, b) => d + String.fromCharCode(b), ""));
  } else {
    return require("crypto")
      .createHmac("sha256", Buffer.from(key, "utf8"))
      .update(Buffer.from(text, "utf8"))
      .digest("base64");
  }
};

export const passwordProtectText = async (password, text) => {
  if (isBrowser) {
    throw "no brower support yet";
  } else {
    const crypto = require("crypto");
    text = Buffer.from(text, "utf8");
    const iv = crypto.randomBytes(16);
    const salt = crypto.randomBytes(16);
    const pw = Buffer.from(password, "utf8");
    const key = crypto.pbkdf2Sync(pw, salt, 65536, 32, "sha512");
    const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
    const blob = Buffer.concat([cipher.update(text), cipher.final()]);
    return {
      iv: iv.toString("hex"),
      salt: salt.toString("hex"),
      blob: blob.toString("base64"),
    };
  }
};

export const unPasswordProtectText = async (password, text, iv, salt) => {
  if (isBrowser) {
    text = b642Buffer(text);
    iv = hex2Buffer(iv);
    salt = hex2Buffer(salt);
    const pw = new TextEncoder("utf-8").encode(password);
    const key = await crypto.subtle.importKey(
      "raw",
      pw,
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );
    const wk = await crypto.subtle.deriveKey(
      { name: "PBKDF2", salt, iterations: 65536, hash: "SHA-512" },
      key,
      { name: "AES-CBC", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );
    const blob = await crypto.subtle.decrypt({ name: "AES-CBC", iv }, wk, text);
    return new TextDecoder("utf-8").decode(blob);
  } else {
    const crypto = require("crypto");
    text = Buffer.from(text, "base64");
    iv = Buffer.from(iv, "hex");
    salt = Buffer.from(salt, "hex");
    const pw = Buffer.from(password, "utf8");
    const key = crypto.pbkdf2Sync(pw, salt, 65536, 32, "sha512");
    const cipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
    const blob = Buffer.concat([cipher.update(text), cipher.final()]);
    return blob.toString("utf8");
  }
};

/// REDUXISH ///////////////////////////////////////////////////////////////////

//export const createSlice = slice => {
//  slice.initialState && !slice.state && (slice.state = slice.initialState);
//  slice = { name: "global", state: {}, reducers: {}, sagas: {}, ...slice };
export const createSlice = ({ initialState: s = {}, ...slice }) => {
  slice = { name: "global", reducers: {}, sagas: {}, state: s, ...slice };
  const actions = {},
    reducers = {},
    sagas = {},
    { name, state } = slice;
  for (const [type, reducer] of entries(slice.reducers)) {
    actions[type] = (payload) => ({ type: `${name}/${type}`, payload });
    reducers[`${name}/${type}`] = { name, reducer };
  }
  for (const [type, saga] of entries(slice.sagas)) {
    actions[type] = (payload) => ({ type: `${name}/${type}`, payload });
    sagas[`${name}/${type}`] = { name, saga };
  }
  return { name, actions, reducer: { sagas, reducers, state } };
};

export const createStore = (slices) => {
  let state = {};
  let reducers = {};
  let reducerFs = {};
  let sagas = {};
  let predicates = [];
  let subscribers = [];
  //let forReading = freezeDeep(state);
  //let forWriting = proxy(state, {
  //  get(args) {
  //    args.value = clone(args.value);
  //  }
  //});
  for (let [name, reducer] of entries(slices)) {
    if (name == "_m374") {
      const f = (k, saga) => [`${name}/${k}`, { name, saga }];
      reducer = { state: {}, reducers: {}, sagas: transformObj(reducer, f) };
    }
    if (isFunction(reducer)) {
      reducerFs[name] = reducer;
    } else {
      state[name] = clone(reducer.state);
      reducers = { ...reducers, ...reducer.reducers };
      sagas = { ...sagas, ...reducer.sagas };
    }
  }
  const getState = (name) => {
    if (name) {
      return clone(state[name]);
      //return clone(state[name]);
      //return forReading[name];
    }
    return clone(state);
    //return clone(state);
    //return forReading;
  };
  const dispatch = (action) => {
    let result = null;
    if (action.type) {
      entries(reducerFs).forEach(
        //([n, f]) => (state[n] = clone(f(clone(state[n]), action)))
        ([n, f]) => (state[n] = f(state[n], action))
      );
      if (action.type in reducers) {
        const { name, reducer } = reducers[action.type];
        reducer(/*forWriting*/ state[name], action);
        state[name] = clone(state[name]);
      } else if (action.type in sagas) {
        const { name, saga } = sagas[action.type];
        result = saga(action, dispatch, (global) => getState(!global && name));
      }
    } else {
      result = action(dispatch, getState /*, waitFor*/);
    }
    subscribers.forEach((subscriber) => subscriber());
    return result;
  };
  const unSubscribe = (callback) => {
    subscribers = subscribers.filter((subscriber) => subscriber !== callback);
  };
  const subscribe = (callback) => {
    unSubscribe(callback);
    subscribers.push(callback);
    return unSubscribe;
  };
  return { dispatch, getState, /*waitFor,*/ subscribe, sagas };
};

export const createSagasPolyfill = (sagas) => (store) => (next) => (action) => {
  if (action.type) {
    const [name, type] = action.type.split("/");
    if (name in sagas && type in sagas[name]) {
      const getState = (g) => (g ? store.getState() : store.getState()[name]);
      const result = sagas[name][type](action, store.dispatch, getState);
      next(action);
      return result;
    }
  }
  return next(action);
};

export const createSagaActions = (blueprint) =>
  createSlice({ ...blueprint /*, reducers: {}*/ }).actions;

export const createControlledSagas = (sagas, def) =>
  mapObj(sagas, (saga) =>
    middlewareConcurrency.ctrld(createMutex(), def)(saga)
  );

export const createMetaHooks = () => {
  let theStore = null;
  const getStore = () => theStore;
  let history = [];
  let unsubscribe = null;
  let subscribers = [];
  let pushHistory = (action) => subscribers.length && history.push(action);
  const subscribe = (f) => {
    if (!subscribers.length) {
      unsubscribe = getStore().subscribe(() => {
        subscribers.forEach((f) => f());
        history = [];
      });
    }
    subscribers = [...subscribers, f];
    return () => {
      subscribers = subscribers.filter((x) => x !== f);
      if (!subscribers.length) {
        unsubscribe();
      }
    };
  };
  const blueprint = {
    name: "_m374",
    sagas: {
      terms: async ({ payload: terms }, dispatch) => {
        const actions = [];
        const handle = termsRead(terms);
        const predicate = (action) => {
          const left = termsLeft(handle);
          termsEval(handle, action);
          if (left != termsLeft(handle)) {
            actions.push(action);
          }
          return termsOkey(handle);
        };
        await dispatch({ type: "_m374/take", payload: predicate });
        return actions;
      },
      takes: async ({ payload: takes }, dispatch) => {
        const actions = [];
        const predicate = (action) => {
          const takesLength = takes.length;
          takes = takes.filter((predicate) => !predicate(action));
          if (takesLength != takes.length) {
            actions.push(action);
          }
          return !takes.length;
        };
        await dispatch({ type: "_m374/take", payload: predicate });
        return actions;
      },
      take: async ({ payload: predicate }, dispatch) => {
        return await dispatch({
          type: "_m374/takeRace",
          payload: { predicate },
        });
      },
      takeRace: async ({ payload: { predicate, challenge } }) => {
        let _resolve;
        const promise = new Promise((r) => (_resolve = r));
        const resolve = (result) => (remove(), _resolve(result));
        const remove = subscribe(() => {
          const action = history.find(trial(predicate, false));
          action && resolve(action);
        });
        challenge && challenge.then(resolve);
        return await promise;
      },
      waitFor: async ({ payload: select }) => {
        let result = select(getStore().getState());
        if (result) {
          return result;
        }
        let resolve,
          promise = new Promise((r) => (resolve = r));
        const remove = getStore().subscribe(() => {
          result = select(getStore().getState());
          if (result) {
            remove();
            resolve(result);
          }
        });
        return await promise;
      },
      observe: ({ payload: { select, change } }) => {
        let value = select(getStore().getState());
        const remove = getStore().subscribe(() => {
          const val = select(getStore().getState());
          if (value != val) {
            value = val;
            change(val);
          }
        });
        return { remove, value };
      },
      subscribe: ({ payload: callback }) => {
        const remove = subscribe(() => {
          callback(history);
        });
        return remove;
      },
    },
  };

  return {
    metaStore: (store) => (theStore = store),
    metaActions: createSagaActions(blueprint),
    metaSagas: { _m374: blueprint.sagas },
    metaReducer: { _v01d: (s = null, action) => (pushHistory(action), s) },
  };
};

export const middlewareConcurrency = {
  debounce:
    (delay = 100) =>
    (f) =>
      debounce(f, delay),
  sync: () => middlewareConcurrency.pool(1),
  pool: (concurrent = 2) => createMutex(concurrent).sync,
  throttle:
    (limit = 100) =>
    (f) =>
      throttle(f, limit),
  queue: (queue, select, concurrent = 1) => {
    const maybeSync = concurrent ? createMutex(concurrent).sync : (f) => f;
    return (f) =>
      (action, ...args) => {
        try {
          const ref = select(action);
          let resolve,
            promise = new Promise((r) => (resolve = r));
          queue[ref] = maybeSync(async () => {
            if (ref in queue) {
              delete queue[ref];
              const result = await f(action, ...args);
              resolve(result);
              return result;
            }
          });
          return promise;
        } catch (e) {
          return e;
        }
      };
  },
  ctrld: (mutex, def) => (f) => {
    mutex = mutex || createMutex();
    return (...args) => {
      if (mutex.isLocked()) {
        const promise = Promise.resolve(def);
        promise.busy = true;
        promise.mutex = mutex;
        return promise;
      }
      return mutex.sync(f)(...args);
      //let release;
      //try {
      //  [release] = await mutex.acquire();
      //  return await f(...args);
      //} finally {
      //  release();
      //}
    };
  },
};

/// VALIDATION /////////////////////////////////////////////////////////////////

export const validation = {
  TYPE: "@TYPE",
  REQUIRED: "@REQUIRED",
  OPTIONAL: "@OPTIONAL",
  inherit: (...supers) => {
    const { REQUIRED, OPTIONAL } = validation;
    let [sub] = supers.splice(-1);
    if (!isArray(sub)) {
      sub = [sub];
    }
    const sup = supers.reduce(
      (sup, sub) => ({
        [REQUIRED]: {
          ...sup[REQUIRED],
          ...sub[REQUIRED],
        },
        [OPTIONAL]: {
          ...sup[OPTIONAL],
          ...sub[OPTIONAL],
        },
      }),
      {}
    );
    return sub.map((sub) => {
      return {
        [REQUIRED]: {
          ...sup[REQUIRED],
          ...sub[REQUIRED],
        },
        [OPTIONAL]: {
          ...sup[OPTIONAL],
          ...sub[OPTIONAL],
        },
      };
    });
  },
};

export const validate = (types = {}, val = null) => {
  const { TYPE, REQUIRED, OPTIONAL } = validation;

  if (!isArray(types)) {
    types = [types];
  }

  for (const type of types) {
    switch (type[TYPE]) {
      case "SENARIO":
        const error = validator(val, type.senario /*, true*/);
        if (!error) {
          return true;
        }
        break;
      case "MAP":
        if (isMap(val)) {
          const vals = values(val),
            len = vals.length;
          if ((type.minLen || 0) <= len && len <= (type.maxLen || 16384)) {
            if (!vals.some((v) => !validate(type.items, v))) {
              return true;
            }
          }
        }
        break;
      case "ARRAY":
        if (isArray(val)) {
          const vals = val,
            len = vals.length;
          if ((type.minLen || 0) <= len && len <= (type.maxLen || 65536)) {
            if (!vals.some((v) => !validate(type.items, v))) {
              return true;
            }
          }
        }
        break;
      case "INTEGER":
        if (isInteger(val)) {
          return true;
        }
        break;
      case "BOOLEAN":
        if (val === true || val === false || val === 1 || val === 0) {
          return true;
        }
        break;
      case "STRING":
        if (isString(val) && val.length <= (type.maxLen || 65536)) {
          return true;
        }
        break;
      case "ONEOF":
        if (type.value.some((v) => v === val)) {
          return true;
        }
        break;
    }
  }
};

export const validator = (data, senarios /*, inception = false*/) => {
  const { TYPE, REQUIRED, OPTIONAL } = validation;

  if (!isArray(senarios)) {
    senarios = [senarios];
  }

  const flatten = (input, senario = {}, output = {}, path = null) => {
    for (const [key, value] of entries(input)) {
      if (key.includes(".")) {
        throw "some keys did include '.'";
      }
      const keyPath = path ? path + "." + key : key;
      //console.log('@', keyPath, value, isObject(value), !(keyPath in senario), isObject(value) && !(keyPath in senario));
      if (isMap(value) && !(keyPath in senario)) {
        flatten(value, senario, output, keyPath);
      } else {
        output[keyPath] = value;
      }
    }
    return output;
  };

  //if (!inception) {
  //  if (isObject(data)) {
  //    const nbrOfKeys = Object.keys(flatten(data)).length;
  //    if (!(1 <= nbrOfKeys && nbrOfKeys < 999)) {
  //      return `data object has an unreasonable amount of elements ${nbrOfKeys}`;
  //    }
  //  } else if (isArray(data)) {
  //    if (16384 < data.length) {
  //      return `data array has an unreasonable amount of items ${data.length}`;
  //    }
  //  } else if (isString(data)) {
  //    if (65536 < data.length) {
  //      return `data string has an unreasonable amount of chars ${data.length}`;
  //    }
  //  //} else if (isInteger(data) || isNumber(data)) {
  //  //} else {
  //  //  return 'input was of unknown type';
  //  }
  //}

  for (let senario of senarios) {
    let { [TYPE]: type, [REQUIRED]: required, [OPTIONAL]: optional } = senario;
    if (!type && !required && !optional) {
      [required, senario] = [senario, { required: senario }];
    }

    if (type) {
      if (validate(senario, data)) {
        return null;
      }
    }

    if (!isMap(data)) {
      //*console.log('did expect input to be an object');
      continue;
    }

    required = required || {};
    optional = optional || {};
    const combined = { ...required, ...optional };

    let flat;
    try {
      flat = flatten(data, combined);
    } catch (e) {
      return e;
    }

    if (diff(keys(flat), keys(combined)).length) {
      //* console.log(`found unspecified key paths`, JSON.stringify(diff(Object.keys(flat), Object.keys(combined)), null, 2));
      continue;
    }

    if (entries(required).some(([attr, type]) => !validate(type, flat[attr]))) {
      //* console.log(`bad required`, JSON.stringify(flat, null, 2), `did not match`, JSON.stringify(required, null, 2));
      continue;
    }

    if (
      entries(flat).some(
        ([key, value]) => !(key in required) && !validate(optional[key], value)
      )
    ) {
      //* console.log(`bad optional`, JSON.stringify(flat, null, 2), `did not match`, JSON.stringify(optional, null, 2));
      continue;
    }

    //*console.log(`input ok`, JSON.stringify(flat, null, 2), `did match`, JSON.stringify(required, null, 2), `and`, JSON.stringify(optional, null, 2));

    // this senario was ok, return null error
    return null;
  }

  return `the input did not satisfy any senarios`;
};

/// CONDITION //////////////////////////////////////////////////////////////////

export const termsRead = (str) =>
  str
    .split(",")
    .map((str) =>
      str
        .split("|")
        .map((str) =>
          str.split("&").map((s) => [...s.split(/!|=/), s.includes("!")])
        )
    );

export const termsEval = (terms, attributes) => {
  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];
    if (
      term.some(
        (term) =>
          !term.some(
            ([k, v, f]) => !(k in attributes) || (attributes[k] == v) == f
          )
      )
    ) {
      terms.splice(i--, 1);
    }
  }
  return terms;
};

export const termsLeft = (terms) => terms.length;
export const termsOkey = (terms) => !terms.length;

/// TESTS //////////////////////////////////////////////////////////////////////

export const testReduxishMiddlewareConcurrency = async () => {
  const { sync, pool, debounce, throttle } = middlewareConcurrency;
  const testSlice = createSlice({
    name: "test",
    sagas: {
      synced: sync()(async ({ payload }) => {
        await new Promise((k) => setTimeout(k, 500));
        console.log(payload);
      }),
      pooled: pool(2)(async ({ payload }) => {
        await new Promise((k) => setTimeout(k, 1000));
        console.log(payload);
      }),
      debounced: debounce()(({ payload }) => {
        console.log(payload);
      }),
      asyncDebounced: debounce()(async ({ payload }) => {
        console.log(payload);
      }),
      throttled: throttle()(({ payload }) => {
        console.log(payload);
      }),
    },
  });

  const { synced, pooled, debounced, asyncDebounced, throttled, mix1, mix2 } =
    testSlice.actions;

  const { dispatch } = createStore({ test: testSlice });

  switch (test) {
    case "sync":
      dispatch(synced("s1"));
      dispatch(synced("s2"));
      dispatch(synced("s3"));
      dispatch(synced("s4"));
      dispatch(pooled("p1"));
      dispatch(pooled("p2"));
      dispatch(pooled("p3"));
      dispatch(pooled("p4"));
      break;
    case "debounce":
      dispatch(debounced("d1"));
      dispatch(debounced("d2"));
      dispatch(debounced("d3"));
      await new Promise((k) => setTimeout(k, 200));
      dispatch(debounced("d4"));
      dispatch(debounced("d5"));
      dispatch(debounced("d6"));
      break;
    case "debounceAsync":
      dispatch(asyncDebounced("d1"));
      dispatch(asyncDebounced("d2"));
      dispatch(asyncDebounced("d3"));
      await new Promise((k) => setTimeout(k, 200));
      dispatch(asyncDebounced("d4"));
      dispatch(asyncDebounced("d5"));
      dispatch(asyncDebounced("d6"));
      break;
  }
  await new Promise((k) => setTimeout(k, 3000));
};

export const testReduxishMiddlewareConcurrencyQueue = async () => {
  const map = {};
  const selectRef = (action) => action.payload.ref;

  const testSlice = createSlice({
    name: "test",
    sagas: {
      doSomething: queue(
        map,
        selectRef
      )(async ({ payload: { ref } }, dispatch) => {
        console.log("DOING ", ref, dispatch);
        await new Promise((r) => setTimeout(r, 3000));
        console.log("FINISH ", ref);
        throw ref;
        return ref;
      }),
    },
  });

  const { doSomething } = testSlice.actions;

  const { dispatch } = createStore({ test: testSlice });

  setTimeout(async () => {
    console.log("TRIGGER 2");
    map["2"]()
      .then((ref) => console.log("ok", ref))
      .catch((ref) => console.log("err", ref));
    await new Promise((k) => setTimeout(k, 1000));
    console.log("TRIGGER 1");
    map["1"]()
      .then((ref) => console.log("ok", ref))
      .catch((ref) => console.log("err", ref));
  }, 1000);

  setTimeout(async () => {
    console.log("DID", await dispatch(doSomething({ ref: "1" })));
  });

  setTimeout(async () => {
    console.log("DID", await dispatch(doSomething({ ref: "2" })));
  });

  await new Promise((k) => setTimeout(k, 10000));
};

export const testConditions = () => {
  const tests = [
    {
      name: "null",
      attributes: [],
      terms: "",
      result: false,
    },
    {
      name: "null 2",
      attributes: [{ a: "b" }],
      terms: "",
      result: false,
    },
    {
      name: "equal",
      attributes: [{ a: "b" }],
      terms: "a=b",
      result: true,
    },
    {
      name: "equal inverted",
      attributes: [{ a: "x" }],
      terms: "a=b",
      result: false,
    },
    {
      name: "and",
      attributes: [{ a: "b", c: "d" }],
      terms: "a=b&c=d",
      result: true,
    },
    {
      name: "and inverted",
      attributes: [{ a: "b", c: "x" }],
      terms: "a=b&c=d",
      result: false,
    },
    {
      name: "not",
      attributes: [{ a: "x" }],
      terms: "a!b",
      result: true,
    },
    {
      name: "not inverted",
      attributes: [{ a: "b" }],
      terms: "a!b",
      result: false,
    },
    {
      name: "and not",
      attributes: [{ a: "b", c: "x" }],
      terms: "a=b&c!d",
      result: true,
    },
    {
      name: "and not inverted",
      attributes: [{ a: "b", c: "d" }],
      terms: "a=b&c!d",
      result: false,
    },
    {
      name: "and double not",
      attributes: [{ a: "b", c: "y" }],
      terms: "a=b&c!d&c!x",
      result: true,
    },
    {
      name: "and double not inverted",
      attributes: [{ a: "b", c: "x" }],
      terms: "a=b&c!d&c!x",
      result: false,
    },
    {
      name: "or",
      attributes: [{ a: "x", c: "d" }],
      terms: "a=b|c=d",
      result: true,
    },
    {
      name: "or inverted",
      attributes: [{ a: "x", c: "x" }],
      terms: "a=b|c=d",
      result: false,
    },
    {
      name: "multi",
      attributes: [{ a: "b" }, { c: "d" }],
      terms: "a=b,c=d",
      result: true,
    },
    {
      name: "multi inverted",
      attributes: [{ a: "b" }],
      terms: "a=b,c=d",
      result: false,
    },
    {
      name: "mix 1",
      attributes: [{ a: "b", c: "d", e: "f" }],
      terms: "a=b,a=b&a=c|c=d&e!x",
      result: true,
    },
    {
      name: "mix 2",
      attributes: [{ a: "b", c: "d", e: "f" }],
      terms: "a=a,a=b&a=c|c=d&e!x",
      result: false,
    },
  ];

  for (let { name, attributes, terms, result } of tests) {
    const handle = termsRead(terms);
    for (const attrs of attributes) {
      termsEval(handle, attrs);
    }
    console.log(`test ${name} ${result == termsOkey(handle) ? "ok" : "fail"}`);
  }
};

export const testStore = async () => {
  const slice = createSlice({
    name: "test",
    initialState: {
      value: 1,
    },
    reducers: {
      increment: (state, action) => {
        state.value += action.payload;
      },
    },
  });
  const { increment } = slice.actions;
  const saga = (amount) => (dispatch) => {
    setTimeout(() => dispatch(increment(amount)), 100);
  };
  const selectValue = (state) => state.test.value;
  const store = createStore({
    test: slice.reducer,
  });
  const { dispatch } = store;
  dispatch(saga(2));
  console.log(store.getState());
  await store.waitFor((state) => selectValue(state) == 3);
  console.log(store.getState());
  const value = store.getState().test.value;
  if (value != 3) {
    throw "failure, value was " + value;
  }
};

// copy this and above into browser or node repl
const testHash = async () => {
  const string =
    "Ḽơᶉëᶆ ȋṕšᶙṁ ḍỡḽǭᵳ ʂǐť ӓṁệẗ, ĉṓɲṩḙċťᶒțûɾ ấɖḯƥĭṩčįɳġ ḝłįʈ, șếᶑ ᶁⱺ ẽḭŭŝḿꝋď ṫĕᶆᶈṓɍ ỉñḉīḑȋᵭṵńť ṷŧ ḹẩḇőꝛế éȶ đꝍꞎôꝛȇ ᵯáꞡᶇā ąⱡîɋṹẵ";
  const result = await hash(string);
  if (result != "Cc9gBmDU1YyQt5LVeZXCeWvnOEA31IKMQ1C128n3e5o") {
    throw "not ok";
  }
  console.log("ok");
};

// copy this and above into browser or node repl
const testHmac = async () => {
  const string =
    "Ḽơᶉëᶆ ȋṕšᶙṁ ḍỡḽǭᵳ ʂǐť ӓṁệẗ, ĉṓɲṩḙċťᶒțûɾ ấɖḯƥĭṩčįɳġ ḝłįʈ, șếᶑ ᶁⱺ ẽḭŭŝḿꝋď ṫĕᶆᶈṓɍ ỉñḉīḑȋᵭṵńť ṷŧ ḹẩḇőꝛế éȶ đꝍꞎôꝛȇ ᵯáꞡᶇā ąⱡîɋṹẵ";
  const result = await hmac(string, string);
  if (result != "4YVobzh7sWuMCv2IfpMPSrn+GZyXyMvHWr0fJ4Soyl4=") {
    throw "not ok";
  }
  console.log("ok");
};

export const testValidation_validate_TYPE_STRING = () => {
  if (!validate(TYPE_STRING, "this is a string")) {
    throw "was a string but failed";
  }
  if (validate(TYPE_STRING, 1337)) {
    throw "was not a string but succeded";
  }
};

export const testValidation_direct = () => {
  const error = validator("this is a string", TYPE_STRING);
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_direct_neg = () => {
  const error = validator(1337, TYPE_STRING);
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_direct_multi_types = () => {
  const error =
    validator("this is a string", [TYPE_STRING, TYPE_INTEGER]) ||
    validator(1337, [TYPE_STRING, TYPE_INTEGER]);
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_direct_multi_types_neg = () => {
  const error = validator({}, [TYPE_STRING, TYPE_INTEGER]);
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_direct_array = () => {
  const error = validator(["this is a string"], TYPE_STRING_ARRAY);
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_direct_array_neg = () => {
  const error = validator([1337], TYPE_STRING_ARRAY);
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_required = () => {
  const error = validator(
    {
      entry: { type: "" },
    },
    {
      [REQUIRED]: {
        "entry.type": { [TYPE]: "STRING" },
      },
    }
  );
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_required_neg = () => {
  const error = validator(
    {},
    {
      [REQUIRED]: {
        "entry.type": { [TYPE]: "STRING" },
      },
    }
  );
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_optional = () => {
  const error = validator(
    {
      ns: "namespace",
    },
    SENARIO_ENTRY
  );
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_optional_neg = () => {
  const error = validator(
    {
      namespace: "namespace",
    },
    SENARIO_ENTRY
  );
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_senarios = () => {
  const error =
    validator({ entry: { type: "" }, topics: [""] }, SENARIO_DEFAULT) ||
    validator({ unique: "", topics: [""] }, SENARIO_DEFAULT);
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_senarios_neg = () => {
  const error = validator({}, SENARIO_DEFAULT);
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_senario_inception = () => {
  const error = validator(
    { a: [{ entry: { type: "" } }] },
    {
      [REQUIRED]: {
        a: {
          [TYPE]: "ARRAY",
          items: [{ [TYPE]: "SENARIO", senario: [SENARIO_ENTRY] }],
        },
      },
    }
  );
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_senarios_inception_neg = () => {
  const error = validator(
    { key: [{ entry: { type: 1337 } }] },
    {
      [REQUIRED]: {
        key: {
          [TYPE]: "ARRAY",
          items: [{ [TYPE]: "SENARIO", senario: [SENARIO_ENTRY] }],
        },
      },
    }
  );
  if (!error) {
    throw "was incorrect but succeded";
  }
};

export const testValidation_array = () => {
  const error = validator(
    {
      topics: [""],
    },
    SENARIO_ENTRY_WITH_TOPICS
  );
  if (error) {
    throw `was correct but failed (${error})`;
  }
};

export const testValidation_object_attributes = () => {
  const error = validator(
    { a: { b: "c" } },
    {
      "a.b": {
        [TYPE]: "ONEOF",
        value: ["c"],
      },
    }
  );
  if (error) {
    throw "was correct but failed";
  }
};

export const testValidation_object_dictionary = () => {
  const error = validator(
    { a: { b: "c" } },
    {
      a: {
        [TYPE]: "MAP",
        items: {
          [TYPE]: "ONEOF",
          value: ["c"],
        },
      },
    }
  );
  if (error) {
    throw `was correct but failed (${error})`;
  }
};

export const testValidation_bulk = () => {
  const data = [{ topics: [""] }];
  const error = validator(data, SENARIO_BULK);
  if (error) {
    throw `was correct but failed (${error})`;
  }
};

export const testValidation_both_1 = () => {
  const error = validator(
    {
      a: "a",
    },
    {
      [REQUIRED]: {
        a: { [TYPE]: "ONEOF", value: ["a"] },
      },
      [OPTIONAL]: {
        a: { [TYPE]: "ONEOF", value: ["a"] },
      },
    }
  );
  if (error) {
    throw `was correct but failed (${error})`;
  }
};

export const testValidation_both_2 = () => {
  const error = validator(
    {},
    {
      [REQUIRED]: {
        a: { [TYPE]: "ONEOF", value: ["a"] },
      },
      [OPTIONAL]: {
        a: { [TYPE]: "ONEOF", value: ["a"] },
      },
    }
  );
  if (!error) {
    throw "was incorrect but succeded";
  }
};

cli(module);

export const deprecatedIdToProviders = (deprecatedId) => {
  const [provider, id] = deprecatedId.split(":");
  if (provider === "email") {
    return [
      {
        type: "email",
        address: id,
      },
    ];
  } else if (provider === "sms") {
    return [
      {
        type: "sms",
        phone_number: id,
      },
    ];
  } else if (provider === "bankid") {
    return [
      {
        type: "swedish_bank_id",
        swedish_personal_number: id,
      },
    ];
  } else if (provider === "2fa") {
    const [address, phone_number] = id.split("|");
    return [
      {
        type: "email",
        address,
      },
      {
        type: "sms",
        phone_number,
      },
    ];
  } else if (provider === "placeholder") {
    return [
      {
        type: "email",
        address: id,
      },
    ];
  }
  throw new Error(`Unknown provider: ${provider}`);
};
