import { PDFDocument, StandardFonts, rgb, degrees } from "pdf-lib";
import { createElectronicSignaturePDFExpress } from "./createElectronicSignaturePDFExpress.js";
import { createSignatureDetailsPDFExpress } from "./createSignatureDetailsPDFExpress.js";

const docEvaluateArray = (expr, doc, context, resolver) => {
  let i;
  const a = [];
  doc = doc || (context ? context.ownerDocument : document);
  resolver = resolver || null;
  context = context || doc;

  const result = doc.evaluate(
    expr,
    context,
    resolver,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
    null
  );
  for (let i = 0; i < result.snapshotLength; i++) {
    a.push(result.snapshotItem(i));
  }
  return a;
};

export const appendPageToPdfExpress = async (
  esignature,
  fileName,
  documentBlob,
  attachmentBlob
) => {
  const xmlTxt = new TextDecoder().decode(attachmentBlob);
  const xmlDoc = new DOMParser().parseFromString(xmlTxt, "text/xml");

  //XML DATA TO ARRAY
  const els = docEvaluateArray(
    "//SignEvents/SignEvent/Signature/SignatureItem/Certificate",
    xmlDoc,
    null
  );

  const certificates = els.map((i) => {
    const certificateData = {
      issuer: i.querySelector("Issuer").textContent,
      subjectName: i.querySelector("SubjectName").textContent,
      validFrom: i.querySelector("ValidFrom").textContent,
      validTo: i.querySelector("ValidTo").textContent,
      policyOid: i.querySelector("PolicyOid").textContent,
    };
    return certificateData;
  });

  // STEP CONVERT TO BUFFER
  const fileBuffer = await new Response(documentBlob).arrayBuffer();
  //.then((ab) => Buffer.from(ab));

  //EXTRACT CHECKSUM
  const { id, checksum } = esignature;

  // CREATE NEW PDF
  const pdfDoc = await PDFDocument.load(fileBuffer);

  // ADD STAMP TO PAGES
  const pages = pdfDoc.getPages();

  const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const ARG_DATE = [
    "en-SE",
    {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    },
  ];

  pages.map((pageDoc) => {
    const { height } = pageDoc.getSize();

    const stamp = `ID: ${
      esignature.id
    } This document is signed. Verified by HYKER ${new Date().toLocaleDateString(
      ...ARG_DATE
    )}`;

    const textSize = helvetica.widthOfTextAtSize(stamp, 8);

    pageDoc.drawText(stamp, {
      x: 15,
      y: height / 2 - textSize / 2,
      size: 8,
      font: helvetica,
      color: rgb(0.6, 0.6, 0.6),
      rotate: degrees(90),
    });
  });

  pdfDoc.attach(fileBuffer, "Original.pdf", {
    mimeType: "application/pdf",
  });

  const page = pdfDoc.addPage();

  // CREATE NEW ATTACHEMENT PDF
  const pdfAttachment = await PDFDocument.create();
  //pdfAttachment.setAuthor("konfident");
  pdfAttachment.setKeywords(["konfident " + id]);

  const attachment = pdfAttachment.addPage();

  // ATTACH XML FILE
  //const xmlAttachment = Buffer.from(signicatXMLString);
  await pdfDoc.attach(attachmentBlob, "ElectronicSignature.xml", {
    mimeType: "text/html",
  });

  // EMBED FONTS ELECTRONIC SIGNATURE
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold, {
    subset: true,
  });

  const helveticaBoldOblique = await pdfDoc.embedFont(
    StandardFonts.HelveticaBoldOblique,
    {
      subset: true,
    }
  );

  // EMBED FONTS SIGNATURE DETAILS
  const helveticaFontDetails = await pdfAttachment.embedFont(
    StandardFonts.Helvetica
  );

  const helveticaBoldDetails = await pdfAttachment.embedFont(
    StandardFonts.HelveticaBold,
    {
      subset: true,
    }
  );
  const helveticaObliqueDetails = await pdfAttachment.embedFont(
    StandardFonts.HelveticaOblique,
    {
      subset: true,
    }
  );

  // SET FONT
  page.setFont(helveticaFont);
  attachment.setFont(helveticaFontDetails);

  // NUMBER OF PAGES
  //const totalPages = pdfDoc.getPageCount();

  // CALCULATE POSITION ON PAGE
  const calculate = (index, position, margin) => {
    return index === 0 ? position : position - margin * index;
  };

  // CALCULATE NUMBER OF PAGES
  const calculateNumberOfPages = (signatures, signaturePerPage) => {
    let pages = Math.floor(signatures.length / signaturePerPage);
    let remainder = signatures.length % signaturePerPage;
    if (remainder > 0) {
      pages += 1;
    }
    return pages;
  };

  // INCREMENT COUNTER FOR PAGES
  const incrementCounter = (counter, signaturesPerPage, array) => {
    return array.length >= counter + signaturesPerPage
      ? counter + signaturesPerPage
      : array.length;
  };

  //GET SIZE FROM BLOB
  const { size } = documentBlob;

  // ELECTRONIC SIGNATURE PDF
  createElectronicSignaturePDFExpress(
    page,
    helveticaBold,
    helveticaBoldOblique,
    calculate,
    checksum,
    calculateNumberOfPages,
    pdfDoc,
    incrementCounter,
    esignature,
    fileName
  );

  // SIGNATURE DETAILS PDF
  createSignatureDetailsPDFExpress(
    attachment,
    helveticaBoldDetails,
    helveticaObliqueDetails,
    calculate,
    certificates,
    calculateNumberOfPages,
    pdfAttachment,
    incrementCounter,
    esignature,
    fileName,
    size
  );

  // SAVE SIGNATURE DETAILS AND ATTACH TO DOCUMENT
  const attachMe = await pdfAttachment.save();
  pdfDoc.attach(attachMe, "Signature-Details.pdf", {
    mimeType: "application/pdf",
  });

  // CONVERT PDF DOCUMENT BACK TO BLOB AND SAVE
  const pdfBytes = await pdfDoc.save();
  let byteArray = new Uint8Array(pdfBytes);

  // PDF TO BLOB
  return new Blob([byteArray], { type: "application/pdf;base64" });
  //let file = new Blob([byteArray], { type: "application/pdf;base64" });

  //// UPDATE FILE WITH SIGNATURE
  //file.id = node.id;
  //file.name = node.name;
  //file.storageId = node.storageId;
  //file.lastModifiedDate = new Date();

  //return file;
};

export const getUserInfoByID = (id, signatures) => {
  for (const user of signatures.signicatUrl) {
    if (id === user.externalSignerId) {
      const email = user.signerInfo.email || "Not specified";
      const companyName =
        user.signerInfo.organizationInfo.companyName || "Not specified";
      const orgNo = user.signerInfo.organizationInfo.orgNo || "Not specified";
      return { email, companyName, orgNo };
    }
  }
};
