import React from "react";
import { Dialog } from "@blueprintjs/core";
import EsignatureWizard from "../esignature/esignatureWizard.js";

export default ({ blob, node, active, close }) => {
  return (
    <Dialog
      isOpen={active}
      style={{ padding: 0 }}
    >
      <EsignatureWizard {...{ blob, node, active, close }} />
    </Dialog>
  );
};
