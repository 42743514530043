//import api from "./api.js";

import {
  uid,
  //uuid,
  snakeToCamel,
  transformObj,
  pick,
  filterObj,
  jwtPeek,
  request,
  json,
  post,
  trial,
} from "utils";

import { getNodeChildren } from "./queries.js";

//import { error } from "./shared.js";

//import config from "./config.js";

//const { agree, importPublicECDHKey, importPrivateECDHKey } = cryptoApi;

//export const requestAccessToken = async (deviceId, credentials) => {
//  const { API_URL_PREFIX_AUTH } = await config;
//  const b642data = (...args) => Data.fromBase64(...args);
//  const { privateECDHKey } = JSON.parse(credentials);
//  try {
//    const url = `${API_URL_PREFIX_AUTH}/auth/${deviceId}` + "/-";
//    const { cipher, iv, publicKey } = await request(url);
//    const serverPublic = await importPublicECDHKey(b642data(publicKey), "spki");
//    const clientPrivate = await importPrivateECDHKey(privateECDHKey, "jwk");
//    const secretKey = await agree(serverPublic, clientPrivate);
//    const plainBuffer = await secretKey.decrypt(b642data(cipher), b642data(iv));
//    return plainBuffer.toUTF8();
//  } catch (e) {
//    console.error("Could not fetch jwt token", e);
//    throw e;
//  }
//};

export const formatWellknown = (user) => {
  switch (user.provider) {
    case "bankid":
      const days = user.wellknown[0].slice(4, 8);
      const last = user.wellknown[0].slice(-1);
      return `XX XX ${days} - XXX ${last}`;
    case "email":
      return user.wellknown[0];
    case "2fa":
      return `${user.wellknown[0]} ${user.wellknown[1]}`;
    case "placeholder":
      return user.email;
    default:
      return user.wellknown.join("|");
  }
};

export const makeshiftName = (user) => {
  switch (user.provider) {
    case "bankid":
      return formatWellknown(user);
    case "email":
    case "2fa":
      return user.wellknown[0].split("@")[0].replace(/\W+/, " ");
    case "placeholder":
      return user.email.split("@")[0].replace(/\W+/, " ");
    case "saml":
      return user.wellknown[1].split("@")[0].replace(/\W+/, " ");
    default:
      return user.wellknown.join("|");
  }
};

export const cloneZone = async (
  config,
  accessToken,
  teamName,
  rootGroupId,
  virtualRootGroupId,
  teamIdToCopy = null,
  options = {},
  oldToNewGroupMap = {}
) => /*async dispatch =>*/ {
  const { API_URL_PREFIX, SERVER_DENIED_PERMISSION } = /*await*/ config;
  const { org: orgId, user: userId, __error } = trial(jwtPeek)(accessToken);

  if (!teamName) throw "Provide a team name";
  const teamIdNew = uid();

  const url = `${API_URL_PREFIX}/project/clone`;
  const opt = json(
    {
      orgId,
      userId,
      teamIdNew,
      rootGroupId,
      virtualRootGroupId,
      teamName,
      ...(teamIdToCopy ? { teamId: teamIdToCopy } : {}),
      tmpl: !!options.isTemplate,
      chat: !!options.copyMessages,
      dirs: !!options.copyFolders,
      files: !!options.copyFiles,
      mates: !!options.copyMembers,
      oldToNewGroupMap,
    },
    {
      userId,
      accessToken,
    }
  );

  try {
    await request(url, opt);
  } catch (e) {
    if (e === SERVER_DENIED_PERMISSION) {
      throw e;
      //await dispatch(error(e));
    } else {
      throw e.message || "Team name is unavailable.";
      //await dispatch(error(e.message || "Team name is unavailable."));
    }
  }
  //return teamIdNew;
  return {
    id: teamIdNew,
    rootGroup: rootGroupId,
    virtualRootGroup: virtualRootGroupId,
  };
};

export const findUserIdsFromDeprecatedId = async (
  api,
  accessToken,
  deprecatedId
) => {
  const { org } = trial(jwtPeek)(accessToken);

  if (!org) {
    throw new Error("bad access token");
  }

  return (
    await api //()
      .token(accessToken)
      .orgs(org)
      .users()
      .get({
        fields: {
          id: true,
          deprecatedId: true,
        },
        filter: [`deprecatedId[eq]${deprecatedId}`],
      })
  ).map(({ id }) => id);
};

export const invite = async (
  api,
  config,
  accessToken,
  deprecatedId,
  emailAddress,
  roleId,
  zoneId = null
) => {
  const { API_URL_PREFIX } = /*await*/ config;
  const { org, user: originId, __error } = trial(jwtPeek)(accessToken);

  if (__error) {
    throw new Error("bad access token: " + __error);
  }

  //const source = yield select(makeSelectSource());
  //const identity = yield select(makeSelectId());
  //const konfCred = yield select(makeSelectKonfCred());
  //const orgId = identity.workspace;
  //const team = yield select(makeSelectTeam());
  //const group = team.rootGroup;
  //const deprecatedId = account;
  //const myDeprecatedId = identity.account;
  const provider = deprecatedId.split(":")[0];
  const wellknown = deprecatedId.split(":")[1].split("|");
  //const namespace = yield hashFunc(identity.workspace);

  //console.log('before console log 1', api)
  //console.log('before console log 3', await api)
  //console.log('before console log 8', (await api).get())
  //console.log('before console log 8', api.then(x => console.log(x)))
  //console.log('before console log 9', await api.get())
  //console.log('this should say localhost:1337', await api().get());
  //console.log('this should also say localhost:1337', await api().get());

  //return;

  //const x = await api()
  //  .token(accessToken)
  //  .orgs(org)
  //  .users()
  //  .get({
  //    fields: {
  //      id: true,
  //      deprecatedId: true
  //    },
  //    filter: [`deprecatedId[eq]${deprecatedId}`]
  //  })
  //  //.map(({ id }) => id);

  //console.log('???', x);

  //try {
  let [userId] = await findUserIdsFromDeprecatedId(
    api,
    accessToken,
    deprecatedId
  );

  const createUser = !userId;

  if (!userId) {
    userId = uid();
  }

  if (createUser) {
    // Add new user to organization
    const user = {
      id: userId,
      org: { id: org },
      role: { id: roleId },
      subscribed: true,
      categories: [],
      email: emailAddress,
      provider,
      wellknown,
    };

    user.name = makeshiftName(user);
    console.log("will create user", user);
    const x = await api //()
      .token(accessToken)
      .users(userId)
      .post(user);
    console.log("did create user", x);
  }

  //// Don't add if user is already a member of the team.
  //const isAlreadyMemberOfTeam = team.rootGroup.members.some(
  //  ({ user }) => user.id === userId
  //);

  let rootGroupId;
  let createMember;

  if (zoneId) {
    const team = await api //()
      .teams(zoneId)
      .get({
        fields: {
          rootGroup: {
            members: {
              user: true,
            },
          },
        },
      });
    rootGroupId = team.rootGroup.id;
    createMember = !team.rootGroup.members.some(
      ({ user: { id } }) => id == userId
    );
  }

  console.log({ createMember });

  //const createMember = zoneId && !(
  //  .rootGroup
  //  .members
  //  .some(({ user: { id } }) => id == userId );

  if (createMember) {
    //// Add member to trust group
    //const umbrellaTask = yield fork(
    //  [trustGroup, trustGroup.addUmbrella],
    //  deprecatedId
    //);
    //let logLevel = 0;
    //while (umbrellaTask.isRunning()) {
    //  const runnTime = Math.floor((+new Date() - startTime) / 1000);
    //  yield call(delay, 1000, true);
    //  logLevel++;
    //  if (logLevel % 5 === 0) {
    //    if (runnTime < 24) {
    //    } else if (runnTime < 35) {
    //      yield put(note("Still working..."));
    //    } else if (runnTime < 40) {
    //      yield put(note("Fetching resources..."));
    //    } else if (runnTime < 45) {
    //      yield put(note("Still working..."));
    //    } else if (runnTime < 50) {
    //      yield put(note("Sharing resources to new user..."));
    //    } else if (runnTime < 55) {
    //      yield put(note("Still working..."));
    //    } else if (runnTime < 60) {
    //      yield put(note("Big task, this might take a while..."));
    //    } else if (runnTime < 65) {
    //      yield put(note("Still working..."));
    //    } else {
    //      yield put(
    //        note(
    //          "The task is taking an very long. If the task " +
    //            " is not completed soon contact support..."
    //        )
    //      );
    //    }
    //  }
    //}
    //yield join(umbrellaTask);
    // Add user to team
    const memberId = uid();
    const result = await api //()
      .token(accessToken)
      .members(memberId)
      .post({
        id: memberId,
        group: { id: rootGroupId },
        user: { id: userId },
      });
    return {
      ...pick(result.user, "deprecatedId", "name", "provider", "wellknown"),
      ...pick(result, "role"),
      member: result.id,
      user: result.user.id,
      group: result.group.id,
    };
  } else {
    const url = `${API_URL_PREFIX}/project/reinvite`;
    const opt = json(
      {
        userId,
      },
      {
        userId: originId,
        accessToken,
      }
    );
    try {
      await request(url, opt);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  //  if (!createUser) {
  //    // If user already existed, send invite only if member was added to team.
  //    const url = `${API_URL_PREFIX}/project/reinvite`;
  //    const opt = post({
  //      userId
  //    });
  //    yield call(authorizedRequest, url, opt);
  //  }

  //  yield delay(5000);
  //  yield put(spinAck());
  //  yield put(note("Invite sent!"));
  //} catch (e) {
  //  yield put(error(e));
  //}

  //yield put(teamLoad());
};

export const createFile = async (api, accessToken, name, ...args) => {
  let [groupId, parentId, storageId, version, id, index, meta] = args;

  const { org, user: userId, __error } = trial(jwtPeek)(accessToken);

  if (__error) {
    throw new Error("bad access token: " + __error);
  }

  id = id || uid();
  index = index || 0;

  try {
    const result = await api //()
      .token(accessToken)
      .files(id)
      .post({
        id,
        name,
        parent: { id: parentId },
        group: { id: groupId },
        creator: { id: userId },
        ...(storageId ? { storageId, version } : {}),
        timestamp: +new Date(),
        index,
        meta,
      });
    return {
      ...pick(
        result,
        "id",
        "name",
        "storageId",
        "version",
        "timestamp",
        "index",
        "meta"
      ),
      creator: result.creator.id,
      group: result.group.id,
      parent: result.parent.id,
    };
  } catch (e) {
    // Check if name conflict
    if (
      e.code === 409 &&
      e.message.startsWith("There is already a file with the name")
    ) {
      //name = await resolveNameConflict(api, accessToken, name, parentId);
      name = await resolveNameConflict2(name, parentId);
      return await createFile(api, accessToken, name, ...args);
    } else {
      throw e;
    }
  }
};

export const getNonConflictingName = (name, siblingNames) => {
  //const regex = /^(.+?)(?: \((\d+)\))?(?:\.(?!.*\.)(.*))?$/;
  const regex = /^(.+?)(?: *\((\d+)\))?(?:\.([^.]+))?$/;

  const [, baseName, , extension] = name.match(regex);

  const numbers = siblingNames
    .map((name) => name.match(regex))
    .filter(([, name, , ext]) => name == baseName && ext == extension)
    .map(([, , number]) => (number ? parseInt(number) : 0));

  const nextNumber = Math.max(-2, ...numbers) + 1;

  let nextName = baseName;

  if (nextNumber >= 0) {
    nextName += ` (${nextNumber})`;
  }

  if (extension) {
    nextName += `.${extension}`;
  }

  return nextName;
};

export const resolveNameConflict = async (api, accessToken, name, parentId) => {
  // Get siblings of file
  const siblings = await api //()
    .token(accessToken)
    .files(parentId)
    .files()
    .get({
      fields: {
        name: true,
      },
    });

  // Calculate next available number of file
  const regex = /^(.+?)(?: \((\d+)\))?(?:\.(?!.*\.)(.*))?$/;
  const [, extensionlessName /*currentNumber*/, , extension] =
    name.match(regex);
  const namesakes = siblings
    .map((sibling) => sibling.name.match(regex))
    .filter(
      ([, siblingExtensionlessName /*siblingNumber*/, , siblingExtension]) =>
        extensionlessName === siblingExtensionlessName &&
        extension === siblingExtension
    );
  const number =
    Math.max(
      ...namesakes.map(
        ([
          ,
          ,
          /*siblingExtensionlessName*/ siblingNumber /*siblingExtension*/,
        ]) => siblingNumber || 0
      )
    ) + 1;

  return `${extensionlessName} (${number})${extension ? `.${extension}` : ""}`;
};

export const resolveNameConflict2 = async (name, parentId) => {
  const siblings = await getNodeChildren(parentId);
  const siblingNames = siblings.map((node) => node.name);
  return getNonConflictingName(name, siblingNames);
};

//export const moveNode = async (accessToken, name, parentId) => {
//
//}
//export const move => async (
//  file,
//  newParentId,
//  index,
//  name,
//  nameConflict = false
//) => {
//  try {
//    //// If the file is being moved to the root, resolve it to the virtual root.
//    //let newParent;
//    //if (!newParentId || newParentId === ID_ROOT) {
//    //  newParent = yield select(makeSelectVirtualRootFile());
//    //} else {
//    //  newParent = yield select(makeSelectFile(newParentId));
//    //}
//
//    let changeGroup = false;
//    if (file.group.id !== newParent.group.id) {
//      // If the file is not the root file of its group, change its group to the group of the new parent.
//      const group = yield select(makeSelectGroup(file.group.id));
//      changeGroup = group.rootFile.id !== file.id;
//    }
//
//    let descendants = [];
//
//    if (changeGroup) {
//      // Compile list of key IDs that need to be moved
//      const keyIds = [];
//      if (file.isDir) {
//        const groupFiles = yield api
//          .groups(file.group.id)
//          .files()
//          .get({
//            fields: {
//              parent: true,
//              meta: true
//            }
//          });
//
//        const getAllDescendants = rootFileId =>
//          _(groupFiles)
//            .filter(file => file.parent && file.parent.id === rootFileId)
//            .flatMap(descendant => [
//              descendant,
//              ...getAllDescendants(descendant.id)
//            ])
//            .value();
//
//        descendants = getAllDescendants(file.id);
//
//        const descendantKeyIds = descendants
//          .filter(descendant => "key" in descendant.meta)
//          .map(descendant => descendant.meta.key);
//
//        keyIds.push(...descendantKeyIds);
//      } else {
//        keyIds.push(file.data.key);
//      }
//
//      // Move resources trust log
//      const manager = yield ensureClient();
//      yield manager.moveResources(
//        /*sourceGroupId*/ file.group.id,
//        /*targetGroupId*/ newParent.group.id,
//        keyIds
//      );
//    }
//
//    // PATCH database
//    const entries = yield api.files().patch([
//      {
//        id: file.id,
//        parent: { id: newParent.id },
//        ...(changeGroup ? { group: { id: newParent.group.id } } : {}),
//        ...(Number.isNaN(index) ? {} : { index }),
//        ...(nameConflict ? { name } : {})
//      },
//      ...descendants.map(descendant => ({
//        id: descendant.id,
//        group: {
//          id: newParent.group.id
//        }
//      }))
//    ]);
//    const entry = entries.find(entry => entry.id === file.id);
//
//    const team = yield select(makeSelectTeamFromGroup(entry.group.id));
//    //yield put(drop(team, entry.id, entry.parent.id, entry.index));
//
//    yield refresh(entry.parent && entry.parent.id);
//  } catch (e) {
//    // Check if name conflict
//    if (e.code === 409) {
//      yield move({
//        file,
//        newParentId,
//        index,
//        name: yield resolveNameConflict({
//          name,
//          parentId: newParentId || file.parentId
//        }),
//        nameConflict: true
//      });
//    } else {
//      yield put(loadOk());
//      yield put(errorAction(`Failed to move file: ${e.message || e}`));
//    }
//  }
//}
