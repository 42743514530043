import { Dialog, Intent } from "@blueprintjs/core";
import { escapeRegExp } from "app-state/src/queries.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { classNames } from "utils";
import { setBullhornActive } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { ZONE } from "../../app/dictionary.js";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import { AddZone } from "../login/icons.tsx";
import { dispatchToast } from "../toasts/Toasts.js";
import styles from "./BhDialogs.module.css";
import bullhornLogo from "./bullhorn.png";
import {
  postZoneToClientCorporation,
  selectCorporationId,
  selectJobOrderId,
  setZoneId,
} from "./bullhornSlice.js";

const {
  actions: { createZone, restrictZone, setCurrentZone, remote },
  server: { bumpZone },
} = appState;

export default function CreateZone({ dispatch, active, close, zoneName }) {
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(false);
  const identity = useSelector(selectIdentity);
  const jobOrderId = useSelector(selectJobOrderId);
  const fixedText = !!jobOrderId
    ? `[ ${zoneName} - ${jobOrderId} ] `
    : `[ ${zoneName} ] `;
  const [inputField, setInputField] = useState(fixedText);
  const [error, setError] = useState(null);
  const clientCorporationId = useSelector(selectCorporationId);

  if (!identity || !zoneName) {
    return null;
  }

  const isEmptyName = () => inputField === fixedText;

  const createTeam = async () => {
    if (isEmptyName()) {
      setError(true);
      dispatchToast(dispatch, {
        message: "The name can't be empty.",
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      return;
    }
    setDisableCreateNewTeamButton(true);

    const toast = dispatchToast(dispatch, {
      message: `Creating ${ZONE}.`,
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0,
    });

    const { reason, id } = await dispatch(
      createZone({
        name: inputField,
      })
    );

    if (reason) {
      setError(true);
      if (reason === "BAD_NAME") {
        toast.replace({
          message: `That name is already in use.`,
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else if (reason !== "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else {
        toast.dismiss();
      }
      return;
    }
    const accessToken = await dispatch(getToken());
    await postZoneToClientCorporation(
      clientCorporationId,
      id,
      accessToken,
      jobOrderId
    );

    await dispatch(restrictZone({ zoneId: id }));
    await dispatch(setZoneId(id));
    await dispatch(setCurrentZone(id));
    await dispatch(remote(bumpZone({ id })));

    if (reason) {
      if (reason !== "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else {
        toast.dismiss();
      }
      return;
    }

    toast.replace({
      message: `${ZONE[0].toUpperCase() + ZONE.slice(1)} created.`,
      icon: "CHECK",
      intent: Intent.PRIMARY,
    });
    close();
  };

  const reFixedText = new RegExp("^" + escapeRegExp(fixedText));
  const zoneText = ZONE[0].toUpperCase() + ZONE.slice(1);

  return (
    <Dialog
      isOpen={active}
      onClose={(e) => dispatch(setBullhornActive(false))}
      isCloseButtonShown={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      title={
        <div className={styles.title}>
          <AddZone size={34} />
          <span className={styles.dialogTitle}>Create a new {zoneText}</span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <input
          value={inputField}
          className={classNames(styles.dialogInput, error && styles.errorInput)}
          onChange={(e) => {
            if (reFixedText.test(e.target.value)) {
              setError();
              setInputField(e.target.value);
            }
          }}
        />
        <br />
        <div className={styles.dialogBottomRow}>
          <img src={bullhornLogo} className={styles.img}></img>
          <button
            className={styles.dialogButton}
            onClick={(e) =>
              createTeam().then(() => setDisableCreateNewTeamButton(false))
            }
            disabled={disableCreateNewTeamButton}
          >
            Create
          </button>
        </div>
      </div>
    </Dialog>
  );
}
