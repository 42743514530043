import jwt from "jsonwebtoken";
import { getWrappedUser } from "./index";

function E(object = {}) {
  const error = new Error(object.message || "Unknown Error");
  for (let [key, value] of Object.entries(object)) {
    error[key] = value;
  }
  return error;
}

const ensureerCreator = (basicToken, publicKey, roleExpander) => async (
  req,
  res,
  next
) => {
  let error = null;
  let json = null;
  try {
    if (!req.headers || !req.headers.authorization) {
      throw E({
        _error: "AUTH_ERROR",
        code: "NO_AUTH_HEADER",
        message: "You need a token in the authorization header"
      });
    }
    let token;
    const parts = req.headers.authorization.split(" ");
    if (parts.length == 2) {
      const scheme = parts[0];
      const credentials = parts[1];
      if (/^Bearer$/i.test(scheme)) {
        token = credentials;
      } else {
        throw E({
          _error: "AUTH_ERROR",
          code: "NO_BEARER",
          message: "Only supports the Bearer protocol"
        });
      }
    } else {
      throw E({
        _error: "AUTH_ERROR",
        code: "NO_AUTH_PROVIDED",
        message: "You need a token in the authorization header"
      });
    }
    try {
      json = jwt.verify(token, publicKey, basicToken);
    } catch (e) {
      throw E({
        _error: "AUTH_ERROR",
        code: "MALFORMED_BEARER_TOKEN",
        message: "Token is malformed, or expired"
      });
    }
  } catch (e) {
    error = e;
    //json = { user: req.header("User-Id"), permissions: {} }
  }

  let user = null;
  req.isGodAdmin = () => json && json.godadmin === true;

  if (json) {
    try {
      user = await getWrappedUser(json.permissions, json.user, roleExpander);
    } catch (e) {
      error = e;
    }
  }

  req.isAllowed = (...tests) => {
    if (error) throw error;

    const userId = req.header("User-Id");
    if (!json.user || userId !== json.user) {
      return false;
    }
    if (tests.length && !user.isAllowedInTeam(null, ...tests)) {
      return false;
    }

    return json;
  };

  req.assertIsAllowed = (...tests) => {
    if (error) throw error;

    const userId = req.header("User-Id");
    if (!json.user || userId !== json.user) {
      throw E({
        _error: "AUTH_ERROR",
        code: "BAD_USER",
        message: "You dont have permission to perform this action"
      });
    }

    if (!user.isAllowedInTeam(null, ...tests)) {
      throw E({
        _error: "AUTH_ERROR",
        code: "NOT_ALLOWED",
        message: "You dont have permission to perform this action"
      });
    }
  };

  next();
};

export default ({ publicKey, audience, issuer }, roleExpander) => {
  const basicToken = {
    audience,
    issuer
  };
  return ensureerCreator(basicToken, publicKey, roleExpander);
};
