export function isIE11() {
  return (
    typeof window !== "undefined" &&
    !!window.MSInputMethodContext &&
    !!document.documentMode
  );
}

export function isEdge() {
  return typeof window !== "undefined" && /Edge/i.test(navigator.userAgent);
}

export function isMicrosoft() {
  return isIE11() || isEdge();
}

export function isFirefox() {
  return (
    typeof window !== "undefined" &&
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  );
}

export function isChrome() {
  return (
    typeof window !== "undefined" &&
    window.chrome !== null &&
    typeof window.chrome !== "undefined" &&
    window.navigator.vendor === "Google Inc." &&
    !isOpera() &&
    !isEdge()
  );
}

export function isOpera() {
  return typeof window !== "undefined" && typeof window.opr !== "undefined";
}
