import { throttle } from "utils";

const RAT_LIM = 100; //50; //150;
const ANI_DUR = 200; //250;

export const getIdFromEl = el => el.dataset.rbdDragHandleDraggableId;
export const getDraggedDom = id =>
  document.querySelector(`[data-rbd-drag-handle-draggable-id='${id}']`);
export const getDragging = () =>
  document.querySelector(`[data-dnd-portal=tree] [data-dragging=yes]`);
export const getHoverables = () =>
  document.querySelectorAll(
    `[data-droppable=tree] [data-rbd-drag-handle-draggable-id]:not([data-dragging=yes])`
  );

export const getOverlap = (row, hov, threshold = 0.5) => {
  row = row.getBoundingClientRect();
  hov = hov.getBoundingClientRect();
  const getDistance = () => {
    if (hov.top <= row.top && row.bottom <= hov.bottom) {
      return row.bottom - row.top;
    } else if (row.top <= hov.top && hov.bottom <= row.bottom) {
      return hov.bottom - hov.top;
    } else if (row.top <= hov.top && hov.top <= row.bottom) {
      return row.bottom - hov.top;
    } else if (row.top <= hov.bottom && hov.bottom <= row.bottom) {
      return hov.bottom - row.top;
    } else {
      return 0;
    }
  };
  const distance = getDistance();
  if (
    (row.bottom - row.top) * threshold < distance ||
    (hov.bottom - hov.top) * threshold < distance
  ) {
    return distance;
  }
  return 0;
};

export const createHoverZone = (droppable, onHover) => {
  let destroyed = false;
  let hoverable = null;
  let timeout = null;
  const onMouseMove = throttle(artificial => {
    if (destroyed) {
      return;
    }
    if (!artificial) {
      clearTimeout(timeout);
      timeout = setTimeout(() => onMouseMove(true), ANI_DUR);
    }
    const dragging = getDragging(droppable);
    if (!dragging) {
      return;
    }
    let prevDist = 0;
    let candidate = null;
    for (const el of getHoverables(droppable)) {
      let distance = getOverlap(el, dragging, 0.3);
      if (distance > prevDist) {
        candidate = getIdFromEl(el);
      }
    }
    if (candidate != hoverable) {
      hoverable = candidate;
      onHover(hoverable, hoverable && getDraggedDom(hoverable));
    }
  }, RAT_LIM);

  const onMouseMoveReal = () => onMouseMove(false);

  const destroy = () => {
    destroyed = true;
    droppable.removeEventListener("mousemove", onMouseMoveReal, true);
  };

  droppable.addEventListener("mousemove", onMouseMoveReal, true);

  return destroy;
};

export const createDropzone = (
  dropzoneEl,
  freezoneEl,
  onStart,
  onHover,
  onDrop
) => {
  let destroyed = false;
  let hoverable = null;

  const enter = e => {
    if (destroyed) {
      return;
    }
    e.preventDefault();
    if (e.type == "dragenter") {
      onStart(true);
    }
  };
  const leave = e => {
    if (destroyed) {
      return;
    }
    e.preventDefault();
    if (e.type == "dragleave" && !dropzoneEl.contains(e.relatedTarget)) {
      onStart(false);
      onHover(null, null, getHoverables(dropzoneEl));
    }
  };

  const over = e => {
    if (destroyed) {
      return;
    }
    e.preventDefault();
    const els = getHoverables(dropzoneEl);
    const { top: edge } = freezoneEl.getBoundingClientRect();
    for (const el of els) {
      const { x, y } = e; //event;
      const { top, bottom /*left, right*/ } = el.getBoundingClientRect();
      const bool = y < edge && top <= y && y <= bottom; //&& left <= x && x <= right;
      if (bool) {
        if (el != hoverable) {
          hoverable = el;
          onHover(getIdFromEl(el), el, els);
        }
        return;
      }
    }
    hoverable = null;
    onHover(null, null, els);
  };

  const drop = e => {
    e.preventDefault();
    onStart(false);
    onHover(null, null, getHoverables(dropzoneEl));
    onDrop(hoverable && getIdFromEl(hoverable), e);
  };

  dropzoneEl.addEventListener("dragenter", enter);
  dropzoneEl.addEventListener("dragleave", leave);
  dropzoneEl.addEventListener("dragover", over);
  dropzoneEl.addEventListener("drop", drop);

  return () => {
    dropzoneEl.removeEventListener("dragenter", enter);
    dropzoneEl.removeEventListener("dragleave", leave);
    dropzoneEl.removeEventListener("dragover", over);
    dropzoneEl.removeEventListener("drop", drop);
  };
};
