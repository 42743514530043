import { rgb } from "pdf-lib";
import { fileSizeHuman } from "utils";
import { formatISOTimeStamp } from "../esignatureSlice";
import { getUserInfoByID } from "./appendPageToPdf";

export const createSignatureDetailsPDF = (
  attachment,
  helveticaBoldDetails,
  helveticaObliqueDetails,
  calculate,
  certificates,
  calculateNumberOfPages,
  pdfAttachment,
  incrementCounter,
  esignature,
  fileName,
  size
) => {
  const documentSignatures = esignature.approvals;
  const { label: sizeLabel } = fileSizeHuman(size);
  const { width } = attachment.getSize();

  attachment.drawText(`Signature details`, {
    x: 35,
    y: 785,
    size: 20,
    font: helveticaBoldDetails,
  });

  attachment.drawText(
    "Following is an overview of the electronic signature. The attachment(s) E-Signature-[number].xml contains the complete electronic signature.",
    {
      x: 35,
      y: 765,
      size: 8,
    }
  );

  attachment.drawText(`Document`, {
    x: 35,
    y: 730,
    size: 12,
    font: helveticaObliqueDetails,
  });

  attachment.drawText("Title:", {
    x: 35,
    y: 715,
    size: 8,
  });

  attachment.drawText(`${fileName}`, {
    x: 55,
    y: 715,
    size: 8,
  });

  attachment.drawText("ID:", {
    x: 35,
    y: 700,
    size: 8,
  });

  attachment.drawText(`${esignature.id}`, {
    x: 50,
    y: 700,
    size: 8,
  });

  attachment.drawText("Checksum:", {
    x: 35,
    y: 685,
    size: 8,
  });

  attachment.drawText(`${esignature.checksum}`, {
    x: 80,
    y: 685,
    size: 8,
  });

  attachment.drawText(`Size:`, {
    x: 35,
    y: 670,
    size: 8,
  });

  attachment.drawText(`${sizeLabel}`, {
    x: 55,
    y: 670,
    size: 8,
  });

  attachment.drawText(`Version: 1.0`, {
    x: 35,
    y: 655,
    size: 8,
  });

  attachment.drawText(`Electronic Signatures:`, {
    x: 35,
    y: 625,
    size: 12,
    font: helveticaObliqueDetails,
  });

  // FIRST PAGE SIGNATURE DETAILS
  let counterFirstPageSignatureDetails = 0;

  documentSignatures.forEach(({ signature }, index) => {
    if (counterFirstPageSignatureDetails < 2) {
      const { companyName, orgNo } = getUserInfoByID(
        signature.externalSignerId,
        esignature
      );

      attachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
        x: 35,
        y: calculate(index, 614, 280),
        scale: 1,
        thickness: 0.5,
        color: rgb(0.83, 0.83, 0.83),
        opacity: 0.9,
      });

      attachment.drawText(`Name`, {
        x: 40,
        y: calculate(index, 600, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(`${signature.fullName}`, {
        x: 280,
        y: calculate(index, 600, 280),
        size: 10,
      });

      attachment.drawText(`Platform`, {
        x: 40,
        y: calculate(index, 580, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(
        `${signature.signatureMethod === "BANKID" ? "Swedish BankID" : null}`,
        {
          x: 280,
          y: calculate(index, 580, 280),
          size: 10,
        }
      );

      attachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
        x: 35,
        y: calculate(index, 574, 280),
        scale: 1,
        thickness: 0.5,
        color: rgb(0.83, 0.83, 0.83),
        opacity: 0.9,
      });

      attachment.drawText(`Date of birth`, {
        x: 40,
        y: calculate(index, 560, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(`${signature.dateOfBirth}`, {
        x: 280,
        y: calculate(index, 560, 280),
        size: 10,
      });

      attachment.drawText(`Unique ID`, {
        x: 40,
        y: calculate(index, 540, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(`${signature.signatureMethodUniqueId}`, {
        x: 280,
        y: calculate(index, 540, 280),
        size: 10,
      });

      attachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
        x: 35,
        y: calculate(index, 534, 280),
        scale: 1,
        thickness: 0.5,
        color: rgb(0.83, 0.83, 0.83),
        opacity: 0.9,
      });

      attachment.drawText(`Company name`, {
        x: 40,
        y: calculate(index, 520, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(companyName, {
        x: 280,
        y: calculate(index, 520, 280),
        size: 10,
      });

      attachment.drawText(`Organization number`, {
        x: 40,
        y: calculate(index, 500, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(orgNo, {
        x: 280,
        y: calculate(index, 500, 280),
        size: 10,
      });

      attachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
        x: 35,
        y: calculate(index, 494, 280),
        scale: 1,
        thickness: 0.5,
        color: rgb(0.83, 0.83, 0.83),
        opacity: 0.9,
      });

      attachment.drawText(`Time stamp`, {
        x: 40,
        y: calculate(index, 480, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(formatISOTimeStamp(signature.signedTime), {
        x: 280,
        y: calculate(index, 480, 280),
        size: 10,
      });

      let certificateList = [];
      certificateList = certificates[index].issuer.toString().split(",");

      attachment.drawText(`Certificate issuer`, {
        x: 40,
        y: calculate(index, 460, 280),
        size: 10,
        font: helveticaBoldDetails,
      });

      attachment.drawText(
        `${(certificateList[0] || "") + ", " + (certificateList[1] || "")}`,
        {
          x: 130,
          y: calculate(index, 460, 280),
          size: 10,
        }
      );

      attachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
        x: 35,
        y: calculate(index, 454, 280),
        scale: 1,
        thickness: 0.5,
        color: rgb(0.83, 0.83, 0.83),
        opacity: 0.9,
      });

      attachment.drawText(
        `${(certificateList[2] || "") + ", " + (certificateList[3] || "")}`,
        {
          x: 130,
          y: calculate(index, 440, 280),
          size: 10,
        }
      );

      attachment.drawLine({
        start: { x: 35, y: calculate(index, 434, 280) },
        end: { x: 560, y: calculate(index, 434, 280) },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      counterFirstPageSignatureDetails++;
    }
  });

  attachment.drawText(
    `Page 1 / ${calculateNumberOfPages(documentSignatures, 2)}`,
    {
      x: width - 80,
      y: 20,
      size: 8,
    }
  );

  // --- FIRST PAGE SIGNATURE DETAILS ENDS HERE ---

  // --- ADDITIONAL PAGES SIGNATURE DETAILS ---
  if (documentSignatures.length > 2) {
    let indexNewPageSignatureDetails = 0;
    let counterSignatureDetails = 2;

    let numberOfPagesSignatureDetails = calculateNumberOfPages(
      documentSignatures,
      2
    );

    for (let i = 1; i < numberOfPagesSignatureDetails; i++) {
      const newPageAttachment = pdfAttachment.addPage();

      counterSignatureDetails = incrementCounter(
        counterSignatureDetails,
        2,
        documentSignatures
      );

      for (let j = 2 * i; j < counterSignatureDetails; j++) {
        const { companyName, orgNo } = getUserInfoByID(
          documentSignatures[j].signature.externalSignerId,
          esignature
        );

        newPageAttachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
          x: 35,
          y: calculate(indexNewPageSignatureDetails, 714, 280),
          scale: 1,
          thickness: 0.5,
          color: rgb(0.83, 0.83, 0.83),
          opacity: 0.9,
        });

        newPageAttachment.drawText(`Name`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 700, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${documentSignatures[j].signature.fullName}`,
          {
            x: 280,
            y: calculate(indexNewPageSignatureDetails, 700, 280),
            size: 10,
          }
        );

        newPageAttachment.drawText(`Platform`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 680, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${
            documentSignatures[j].signature.signatureMethod === "BANKID"
              ? "Swedish BankID"
              : null
          }`,
          {
            x: 280,
            y: calculate(indexNewPageSignatureDetails, 680, 280),
            size: 10,
          }
        );

        newPageAttachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
          x: 35,
          y: calculate(indexNewPageSignatureDetails, 674, 280),
          scale: 1,
          thickness: 0.5,
          color: rgb(0.83, 0.83, 0.83),
          opacity: 0.9,
        });

        newPageAttachment.drawText(`Date of birth`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 660, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${documentSignatures[j].signature.dateOfBirth}`,
          {
            x: 280,
            y: calculate(indexNewPageSignatureDetails, 660, 280),
            size: 10,
          }
        );

        newPageAttachment.drawText(`Unique ID`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 640, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${documentSignatures[j].signature.signatureMethodUniqueId}`,
          {
            x: 280,
            y: calculate(indexNewPageSignatureDetails, 640, 280),
            size: 10,
          }
        );

        newPageAttachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
          x: 35,
          y: calculate(indexNewPageSignatureDetails, 634, 280),
          scale: 1,
          thickness: 0.5,
          color: rgb(0.83, 0.83, 0.83),
          opacity: 0.9,
        });

        newPageAttachment.drawText(`Company name`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 620, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(companyName, {
          x: 280,
          y: calculate(indexNewPageSignatureDetails, 620, 280),
          size: 10,
        });

        newPageAttachment.drawText(`Organization number`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 600, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(orgNo, {
          x: 280,
          y: calculate(indexNewPageSignatureDetails, 600, 280),
          size: 10,
        });

        newPageAttachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
          x: 35,
          y: calculate(indexNewPageSignatureDetails, 594, 280),
          scale: 1,
          thickness: 0.5,
          color: rgb(0.83, 0.83, 0.83),
          opacity: 0.9,
        });

        newPageAttachment.drawText(`Time stamp`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 580, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${formatISOTimeStamp(documentSignatures[j].signature.signedTime)}`,
          {
            x: 280,
            y: calculate(indexNewPageSignatureDetails, 580, 280),
            size: 10,
          }
        );

        let certificateListNewPage = [];
        certificateListNewPage = certificates[j].issuer.toString().split(",");

        newPageAttachment.drawText(`Certificate issuer`, {
          x: 40,
          y: calculate(indexNewPageSignatureDetails, 560, 280),
          size: 10,
          font: helveticaBoldDetails,
        });

        newPageAttachment.drawText(
          `${
            (certificateListNewPage[0] || "") +
            ", " +
            (certificateListNewPage[1] || "")
          }`,
          {
            x: 130,
            y: calculate(indexNewPageSignatureDetails, 560, 280),
            size: 10,
          }
        );

        newPageAttachment.drawSvgPath("M 0 0 h 525 v 20 h -525 Z", {
          x: 35,
          y: calculate(indexNewPageSignatureDetails, 554, 280),
          scale: 1,
          thickness: 0.5,
          color: rgb(0.83, 0.83, 0.83),
          opacity: 0.9,
        });

        newPageAttachment.drawText(
          `${
            (certificateListNewPage[2] || "") +
            ", " +
            (certificateListNewPage[3] || "")
          }`,
          {
            x: 130,
            y: calculate(indexNewPageSignatureDetails, 540, 280),
            size: 10,
          }
        );

        newPageAttachment.drawLine({
          start: {
            x: 35,
            y: calculate(indexNewPageSignatureDetails, 534, 280),
          },
          end: { x: 560, y: calculate(indexNewPageSignatureDetails, 534, 280) },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });

        indexNewPageSignatureDetails++;
        if (indexNewPageSignatureDetails === 2) {
          indexNewPageSignatureDetails = 0;
        }
      }
      newPageAttachment.drawText(
        `Page ${i + 1} / ${calculateNumberOfPages(documentSignatures, 2)}`,
        {
          x: width - 80,
          y: 20,
          size: 8,
        }
      );
    }
  }
};
