import { createSlice } from "@reduxjs/toolkit";
import { Intent } from "@blueprintjs/core";
import createApiClient from "api-client";
import { Data } from "hyker-crypto";
import { getToken, getContext, selectIdentity } from "../../app/topSlice.js";
import * as config from "../../config";
import { trial, jwtPeek } from "utils";
import { setOverlay } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import {
  startDownloadBlobBackground,
  startUpdate,
} from "../transfer/transferSlice.js";
import { createToast } from "../toasts/Toasts.js";
import {
  handleDateToInteger,
  handleFormatExpirationDate,
  HOURS_AT_MIDNIGHT,
  MINUTES_AT_MIDNIGHT,
  SECONDS_AT_MIDNIGHT,
  MILLISECONDS_AT_MIDNIGHT,
} from "../wizard/utils.js";

import {
  isSignedButNotComplete,
  isPackage,
  toPackage,
} from "./packaging/provider.js";

import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays.js";

const {
  selectors: { selectGraph },
  actions: { loadZone, loadNode, setCurrentZone, moveZoneFirst },
} = appState;

const ARG_DATE_SIGNATURE = [
  "sv-SE",
  {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "numeric",
  },
];

export const logEvent =
  (...args) =>
  async (dispatch) => {
    const headers = await prepareHeaders(dispatch);
    const urlStatus = `${config.API_URL_PREFIX}/logEvent/${args.join("/")}`;
    const response = await fetch(urlStatus, {
      method: "GET",
      headers: headers,
    });
    if (response.status !== 200) {
      throw new Error("could not log event");
    }
  };

export const esignatureSlice = createSlice({
  name: "esignature",
  initialState: {
    //loading: true,
    //signedDocumentInfo: { zoneId: "", nodeId: "" },
  },
  reducers: {
    //clear: (state) => {
    //  state.requests = [];
    //  state.rejects = [];
    //  state.approves = [];
    //},
    //setSignedDocumentInfo: (state, action) => {
    //  state.signedDocumentInfo = action.payload;
    //},
  },
});

//export const { clear, setSignedDocumentInfo } = esignatureSlice.actions;

export default esignatureSlice.reducer;

export const esignatureTouch = (nodeId, mechanism) => async (dispatch) => {
  try {
    //TOKEN
    const headers = await prepareHeaders(dispatch);
    const urlStatus =
      `${config.API_URL_PREFIX}/esignature/touch/` + nodeId + "/" + mechanism;
    const response = await fetch(urlStatus, {
      method: "GET",
      headers: headers,
    });
  } catch (e) {
    console.log(e);
  }
};

export const esignaturesFetch =
  ({ all, signedButNotCompletedInZone } = {}) =>
  async (dispatch) => {
    try {
      const headers = await prepareHeaders(dispatch);
      let url = `${config.API_URL_PREFIX}/esignature/fetch`;
      if (all) {
        url += "/all";
      } else if (signedButNotCompletedInZone) {
        url += "/almost/" + signedButNotCompletedInZone;
      }
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      return await response.json();
    } catch (e) {
      console.log(e);
    }
    return [];
  };

export const esignatureFinalized =
  (esignatureRequestId) => async (dispatch) => {
    const api = createApiClient(config.API_URL_PREFIX);
    const token = await dispatch(getToken());
    await api
      .token(token)
      .esignatures(esignatureRequestId)
      .patch({ completed: true });
  };

export const esignatureReject = (esigReq) => async (dispatch, getState) => {
  const identity = selectIdentity(getState());
  const {
    cryptoClient,
    credentials: { privateECDSAKey: key },
  } = await dispatch(getContext());

  const dataToSign = Data.join([
    Data.fromUTF8("REJECT"),
    Data.fromBase64(esigReq.tbs),
  ]);

  const signature = (await cryptoClient.sign(key, dataToSign)).signature;

  const rejection = {
    user: {
      id: identity.id,
    },
    signature,
    timestamp: +new Date(),
  };

  const api = createApiClient(config.API_URL_PREFIX);
  const token = await dispatch(getToken());

  try {
    await api
      .token(token)
      .esignatures(esigReq.id)
      .patch({ rejections: [rejection], status: "canceled" });
  } catch (e) {
    throw e;
  }
};

export const wrapStartDownload =
  (
    startDownload,
    node,
    intent = undefined,
    folderStructure = undefined,
    showPaneTransfer = undefined
  ) =>
  async (dispatch) => {
    const transform = async (node, blob) => {
      const { esignature, name } = normalizeNode(node);
      if (
        isSignedButNotComplete(esignature) &&
        !(await isPackage(esignature, blob))
      ) {
        const token = await dispatch(getToken());
        blob = await toPackage(esignature, blob, name, token);
      }
      return blob;
    };

    return await dispatch(
      startDownload(node, intent, transform, folderStructure, showPaneTransfer)
    );
  };

export const justPackage =
  (node, blob = null) =>
  async (dispatch) => {
    node = normalizeNode(node);
    if (isSignedButNotComplete(node?.esignature)) {
      if (!blob) {
        blob = await dispatch(startDownloadBlobBackground(node));
      }
      if (!(await isPackage(node.esignature, blob))) {
        const token = await dispatch(getToken());
        blob = await toPackage(node.esignature, blob, node.name, token);
      }
      return blob;
    }
  };

export const packageAndUpdate =
  (zoneId, node, blob = null) =>
  async (dispatch) => {
    node = normalizeNode(node);
    if (isSignedButNotComplete(node?.esignature)) {
      if (!blob) {
        blob = await dispatch(startDownloadBlobBackground(node));
      }
      if (!(await isPackage(node.esignature, blob))) {
        await dispatch(ensureConditions(zoneId, node.id));
        const token = await dispatch(getToken());
        blob = await toPackage(node.esignature, blob, node.name, token);
        await dispatch(startUpdate(zoneId, node, blob));
      }
      await dispatch(esignatureFinalized(node.esignature.id));
      return blob;
    }
  };

export const packageAndUpdateZone = (zoneId) => async (dispatch) => {
  // TODO not tested. what if not part of group?
  const esignatures = await dispatch(
    esignaturesFetch({ signedButNotCompletedInZone: zoneId })
  );
  const nodes = [];

  for (const _esignature of esignatures) {
    const { zone, node, ...esignature } = _esignature;
    nodes.push({ ...node, esignature });
  }

  for (const node of nodes) {
    await dispatch(packageAndUpdate(zoneId, node));
  }
};

export const packageAndUpdateDescendants =
  (zoneId, parentId) => async (dispatch, getState) => {
    // TODO not implemented
  };

export const esignatureLaunchUrl = () => async (dispatch) => {
  const args = window.location.pathname.replace(/^\/+/, "").split("/");

  let [what] = args;

  if (what?.toLowerCase() == "esignature") {
    window.history.replaceState(null, "", "/");

    let [, zoneId, nodeId, retur, final] = args;

    //dispatch(setSignedDocumentInfo({ zoneId, nodeId }));
    //iDidJustSign = !!iDidJustSign;

    let node = normalizeNode(await dispatch(fetchNode(nodeId)));
    if (node) {
      //dispatch(setSignedDocumentInfo({ zoneId: "", nodeId: "" }));
      await dispatch(ensureConditions(zoneId, node.id));
      dispatch(
        setOverlay({
          overlay: OVERLAY_PREVIEW,
          zoneId,
          node,
          retur: !!retur,
          final: !!final,
        })
      );
    }
  }
};

export const displayZone = (zoneId) => async (dispatch) => {
  await dispatch(ensureZone(zoneId));
  dispatch(setCurrentZone(zoneId));
  dispatch(moveZoneFirst(zoneId));
};

export const ensureZone = (zoneId) => async (dispatch, getState) => {
  if (!(zoneId in getState().appstate.graph.zones)) {
    await dispatch(loadZone({ id: zoneId }));
  }
};

export const ensureNode = (zoneId, nodeId) => async (dispatch, getState) => {
  let node = getState().appstate.graph.nodes[nodeId];
  if (!node) {
    await dispatch(loadNode({ zoneId, nodeId }));
    node = getState().appstate.graph.nodes[nodeId];
  }
  if (node && !(node.parent in getState().appstate.graph.nodes)) {
    await dispatch(loadNode({ zoneId, nodeId: node.parent }));
  }
};

export const ensureConditions = (zoneId, nodeId) => async (dispatch) => {
  await dispatch(ensureZone(zoneId));
  await dispatch(ensureNode(zoneId, nodeId));
};

export const fetchNode = (nodeId) => async (dispatch) => {
  const token = await dispatch(getToken());
  const api = createApiClient(config.API_URL_PREFIX);
  try {
    return await api
      .token(token)
      .files(nodeId)
      .get({
        fields: {
          id: true,
          meta: true,
          name: true,
          index: true,
          group: true,
          storageId: true,
          version: true,
          timestamp: true,
          parent: true,
          esignatures: {
            completed: true,
            status: true,
            requester: true,
            signees: true,
            approvals: true,
            rejections: true,
            order: true,
            current: true,
            signicatId: true,
            signicatUrl: true,
            timestamp: true,
            deadline: true,
            checksum: true,
            tbs: true,
          },
        },
      });
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const normalizeEsignature = (esignature) => {
  esignature = esignature && { ...esignature };
  if (esignature?.requester?.id) {
    esignature.requester = esignature.requester.id;
  }
  if (esignature?.signees?.[0].id) {
    esignature.signees = esignature.signees.map((signee) => signee.id);
  }
  return esignature;
};

export const normalizeNode = (node) => {
  node = node && { ...node };
  if (node?.group?.id) {
    node.group = node.group.id;
  }
  if (node?.parent?.id) {
    node.parent = node.parent.id;
  }
  if (node?.esignatures?.[0] && !node.esignature) {
    node.esignature = normalizeEsignature(node.esignatures[0]);
  }
  return node;
};

export const esignatureOpenViewer = (zoneId, nodeId) => async (dispatch) => {
  dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, zoneId, nodeId }));
};

export const getBlobChecksum = async (blob) => {
  const bytes = new Uint8Array(await blob.arrayBuffer());
  const hash = await window.crypto.subtle.digest("SHA-256", bytes);
  const array = [...new Uint8Array(hash)];
  return array.map((n) => n.toString(16).padStart(2, "0")).join("");
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.addEventListener("error", (e) => reject(e));
      fileReader.addEventListener("load", () => {
        resolve(fileReader.result.substr(fileReader.result.indexOf(",") + 1));
      });
      fileReader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
};

export const formatISOTimeStamp = (signedTime) => {
  const date = new Date(signedTime);
  const formattedTimestamp = date.toLocaleString(...ARG_DATE_SIGNATURE);
  return formattedTimestamp;
};

export const getEsignaturesFromGraph = (graph) => {
  const { zones, groups, nodes } = graph;

  const esignatures = [];

  for (const node of Object.values(nodes)) {
    if ("esignatures" in node) {
      const zone = zones[groups[node.group]?.zone];
      if (zone) {
        for (let esignature of node.esignatures) {
          esignature = {
            ...esignature,
            zone: { ...zone },
            node: { ...node },
          };
          esignatures.push(esignature);
        }
      }
    }
  }

  return esignatures;
};

export const getEsignatureState =
  (esignatureRequest) => (dispatch, getState) => {
    const { id: userId } = selectIdentity(getState());
    const { users } = selectGraph(getState());

    esignatureRequest = normalizeEsignature(esignatureRequest);

    let stateText = "Not started yet.";

    if (!esignatureRequest) {
      return {
        notYetStarted: true,
        stateText,
      };
    }

    const signatures = esignatureRequest.approvals.map((doc) => doc.signature);

    const approvals = esignatureRequest.approvals.map((doc) => doc.user);
    const rejections = esignatureRequest.rejections.map((doc) => doc.user);
    const submitted = esignatureRequest.signicatUrl;
    const deadline = esignatureRequest.deadline;

    const signedSealedDelivered = esignatureRequest.completed;

    const nbrOfApprovals = approvals.length;
    const nbrOfRejections = rejections.length;
    const nbrOfRequests = esignatureRequest.signees.length;
    const nbrOfResponses = nbrOfApprovals + nbrOfRejections;
    const allHaveResponded = nbrOfRequests == nbrOfResponses;

    const wasCanceled = esignatureRequest.status == "canceled";
    const sign =
      esignatureRequest && !!Object.keys(esignatureRequest).length > 0;

    const expiredKonfident =
      sign &&
      new Date(deadline).getTime() < Date.now() &&
      !esignatureRequest[0]?.completed &&
      nbrOfRejections === 0;

    const didExpire = expiredKonfident || esignatureRequest.status == "expired";

    const isFinished = wasCanceled || didExpire || allHaveResponded;

    const wasApproved = /*isFinished &&*/ nbrOfApprovals == nbrOfRequests;
    const wasRejected = isFinished && !wasApproved;

    const requiresAttention = wasApproved && !signedSealedDelivered;

    const isOrdered = esignatureRequest.order;
    const imASignee = esignatureRequest.signees.includes(userId);
    const { name: iAmRequester } =
      Object.values(users).find(
        (user) => user.user === esignatureRequest.requester
      ) || {};

    const iHaveApproved = approvals.includes(userId);
    const iHaveRejected = rejections.includes(userId);

    const iHaveResponded = iHaveApproved || iHaveRejected;

    const myPlace =
      esignatureRequest.signicatUrl.find((x) => x.externalSignerId == userId)
        ?.order ?? -1;

    const readyToSign =
      (imASignee && !iHaveResponded && myPlace == 0) ||
      myPlace == nbrOfResponses + 1;

    const queue = esignatureRequest.signicatUrl
      .map((signicatUrl) => {
        const { order, externalSignerId: userId } = signicatUrl;
        const {
          email,
          lastName: name,
          socialSecurityNumber,
          organizationInfo,
        } = signicatUrl.signerInfo;
        const approved = approvals.includes(userId);
        const rejected = rejections.includes(userId);
        const responded = approved || rejected;

        return {
          userId,
          email,
          name,
          order,
          approved,
          rejected,
          responded,
          socialSecurityNumber,
          organizationInfo,
          deadline,
        };
      })
      .sort((a, b) => a - b);

    const colorGreen = "#8BC34A";
    const colorOrange = "#FF9800";
    const colorRed = "#F44336";

    const iconSuccess = "tick-circle";
    const iconFailure = "ban-circle";
    const iconPending = "time";

    let color = colorOrange;
    let icon = iconPending;

    if (signedSealedDelivered) {
      stateText = "Signature complete";
      color = colorGreen;
      icon = iconSuccess;
    } else if (requiresAttention) {
      stateText = "Requires attention";
      stateText = "Signature complete";
      color = colorGreen;
      icon = iconSuccess;
    } else if (wasApproved) {
      stateText = "Was approved";
      color = colorGreen;
    } else if (didExpire) {
      stateText = "Has expired";
    } else if (wasRejected) {
      stateText = "Was rejected";
      color = colorRed;
      icon = iconFailure;
    } else if (wasCanceled) {
      stateText = "Was canceled";
      color = colorRed;
      icon = iconFailure;
    } else if (readyToSign) {
      stateText = "Waiting for you";
    } else if (isOrdered) {
      stateText = "Waiting for next signature";
    } else {
      stateText = "Waiting for more signatures";
    }

    const state = {
      notYetStarted: false,
      signedSealedDelivered,
      requiresAttention,
      isFinished,
      wasApproved,
      wasRejected,
      wasCanceled,
      didExpire,
      readyToSign,
      isOrdered,
      queue,
      stateText,
      signatures,
      color,
      icon,
      approvals,
      rejections,
      submitted,
      deadline,
      iAmRequester,
    };

    return state;
  };

export const isEsignatureFinalizable = (esignatureRequest) => () => {
  if (!esignatureRequest) {
    return false;
  }

  const approvals = esignatureRequest.approvals.map((doc) => doc.user);
  const rejections = esignatureRequest.rejections.map((doc) => doc.user);

  const signedSealedDelivered = esignatureRequest.completed;

  const nbrOfApprovals = approvals.length;
  const nbrOfRejections = rejections.length;
  const nbrOfRequests = esignatureRequest.signees.length;
  const nbrOfResponses = nbrOfApprovals + nbrOfRejections;
  const allHaveResponded = nbrOfRequests == nbrOfResponses;

  const wasCanceled = esignatureRequest.status == "canceled";
  const didExpire = esignatureRequest.status == "expired";

  const isFinished = wasCanceled || didExpire || allHaveResponded;

  const wasApproved = isFinished && nbrOfApprovals == nbrOfRequests;
  const wasRejected = isFinished && !wasApproved;

  return !signedSealedDelivered && !wasCanceled && !didExpire && wasApproved;
};

export const createSignatureRequest =
  (blob, node, user, signers, order, org, expire) => async (dispatch) => {
    try {
      const headers = await prepareHeaders(dispatch);
      const hex = await getBlobChecksum(blob);

      //const daysToExpire = handleDateToInteger(expire);
      //const expirationDate = handleFormatExpirationDate(expire);

      if (!expire) {
        expire = new Date();
        expire.setDate(expire.getDate() + 30);
      }

      expire.setHours(
        HOURS_AT_MIDNIGHT,
        MINUTES_AT_MIDNIGHT,
        SECONDS_AT_MIDNIGHT,
        MILLISECONDS_AT_MIDNIGHT
      );

      let base64Content;
      let url = `${config.API_URL_PREFIX}/esignature/create`;

      const body = {
        title: node.name,
        //description: "Document to sign",
        description: node.name,
        checksum: hex,
        name: user.name,
        nodeId: node.id,
        signers,
        order,
        org,
        groupId: node.group,
        ...(base64Content && { base64Content }),
        expire: +expire,
        //daysToExpire,
        //expire: expirationDate,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.status === 404) {
        dispatch(
          createToast({
            message: "File does not exist",
            icon: "document",
            intent: Intent.DANGER,
          })
        );
        return;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        createToast({
          message: "Error when requesting signature",
          icon: "document",
          intent: Intent.DANGER,
        })
      );
    }
  };

export const createSignatureRequestExpress =
  (blob, node, user, signers, order, org) => async (dispatch) => {
    try {
      const headers = await prepareHeaders(dispatch);
      const hex = await getBlobChecksum(blob);

      let base64Content;
      let url = `${config.API_URL_PREFIX}/esignature/express/create`;

      const body = {
        title: node.name,
        description: "Document to sign",
        checksum: hex,
        name: user.name,
        nodeId: node.id,
        signers,
        order,
        org,
        groupId: node.group,
        ...(base64Content && { base64Content }),
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.status === 404) {
        dispatch(
          createToast({
            message: "File does not exist",
            icon: "document",
            intent: Intent.DANGER,
          })
        );
        return;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        createToast({
          message: "Error when requesting signature",
          icon: "document",
          intent: Intent.DANGER,
        })
      );
    }
  };

export const prepareHeaders = async (dispatch) => {
  const token = await dispatch(getToken());

  const headers = {
    "content-type": "application/json",
    accept: "application/json",
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
    const { user } = trial(jwtPeek)(token);
    if (user) {
      headers["user-id"] = user;
    }
  }
  return headers;
};

//export const selectSignedDocumentInfo = (state) =>
//  state.esignature.signedDocumentInfo;
