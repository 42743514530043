import React, { useState, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  SelectionState,
  Modifier,
} from "draft-js";

import { Button, Icon, Intent } from "@blueprintjs/core";

import { classNames } from "utils";

import appContext from "../../app/appContext";

import { selectDraft, draftMessage, submitDraft } from "./slice";
import { appState } from "../../app/appState.js";

import styles from "./compose.module.css";

import { TEAM_MESSAGE_CREATE } from "../../app/permissions.js";

import { dispatchToast } from "../toasts/Toasts.js";

const {
  selectors: { selectPermissions },
} = appState;

export default function Compose({ isMobile, isLandscape, realEstateBudget }) {
  const dispatch = useDispatch();

  const { groupId } = useContext(appContext);

  const draft = useSelector((state) => selectDraft(state)(groupId));

  const editorRef = useRef(null);

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const permissions = useSelector(selectPermissions);
  if (!permissions[TEAM_MESSAGE_CREATE]) {
    return (
      <div className={styles.nonepremitted}>
        You dont have permission to send chat messages
      </div>
    );
  }

  const handleCompose = () => {
    if (editorRef.current) {
      editorRef.current.blur();
    }
    let content = editorState.getCurrentContent();
    let text = content.getPlainText().trim();
    if (text) {
      let draft = convertToRaw(content);
      if (1000 < text.length) {
        dispatchToast(dispatch, {
          message: `Message too long.`,
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
        return;
      }
      dispatch(draftMessage({ group: groupId, message: { draft, text } }));
      dispatch(submitDraft(groupId));
      setEditorState(() => EditorState.createEmpty());
      setTimeout(() => {
        focusEditor();
      }, 1);
    }
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleReturn = (e) => {
    if (e.nativeEvent.shiftKey) {
      handleCompose();
      return "handled";
    }
  };

  const toggleBlockType = (type) => {
    setEditorState(RichUtils.toggleBlockType(editorState, type));
  };
  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const contentState = editorState.getCurrentContent();
  const noPH =
    contentState.hasText() ||
    contentState.getBlockMap().first().getType() !== "unstyled";

  return (
    <div
      className={classNames(
        styles.outer,
        styles.RichEditor_root,
        noPH && styles.RichEditor_hidePlaceholder,
        isLandscape && styles.landscape
      )}
    >
      <div className={styles.bar}>
        {!isMobile && realEstateBudget !== 0 && (
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
        )}
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <Button
          text="Send"
          rightIcon={
            <span>
              <Icon icon="key-shift" />
              <span>Shift</span>
              &nbsp;
              <Icon icon="key-enter" />
              <span>Enter</span>
            </span>
          }
          minimal={true}
          onClick={handleCompose}
          className={styles.button}
        />
      </div>
      <div
        className={classNames(styles.box, styles.RichEditor_editor)}
        onClick={focusEditor}
      >
        <Editor
          ref={editorRef}
          placeholder="Type a message..."
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          handleReturn={handleReturn}
          onChange={setEditorState}
        />
      </div>
    </div>
  );
}

/*
<input
  className={styles.compose}
  value={draft}
  placeholder="type to start a message"
  onChange={e =>
    dispatch(draftMessage({ group: groupId, message: e.target.value }))
  }
  onKeyDown={e => e.key == "Enter" && dispatch(submitDraft(groupId))}
/>
*/

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return styles.RichEditor_blockquote;
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = classNames(
      styles.RichEditor_styleButton,
      this.props.active && styles.RichEditor_activeButton
    );
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  //{label: 'H1', style: 'header-one'},
  //{label: 'H2', style: 'header-two'},
  //{label: 'H3', style: 'header-three'},
  { key: "h1", label: <Icon icon="header" />, style: "header-four" },
  { key: "h2", label: <Icon icon="header-one" />, style: "header-five" },
  { key: "h3", label: <Icon icon="header-two" />, style: "header-six" },
  //{label: 'Blockquote', style: 'blockquote'},
  {
    key: "l1",
    label: <Icon icon="properties" />,
    style: "unordered-list-item",
  },
  {
    key: "l2",
    label: <Icon icon="numbered-list" />,
    style: "ordered-list-item",
  },
  //{label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={styles.RichEditor_controls}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.key}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { key: "bold", label: <Icon icon="bold" />, style: "BOLD" },
  { key: "italic", label: <Icon icon="italic" />, style: "ITALIC" },
  { key: "underline", label: <Icon icon="underline" />, style: "UNDERLINE" },
  //{label: <Icon icon="" />, style: 'CODE'},
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className={styles.RichEditor_controls}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.key}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
