import { Cache, Data } from "../";

import { importPublicECDHKey, importPublicECDSAKey } from "../crypto";

/*******************/

export default class PublicKeyCache {
  constructor(kdsClient, ttl, file, password) {
    this.kdsClient = kdsClient;
    this.publicECDHKeys = new Cache();
    this.publicECDSAKeys = new Cache();
    this.load().catch(e => console.log(e));
  }

  has(owner) {
    return this.publicECDSAKeys.has(owner) && this.publicECDHKeys.has(owner);
  }

  async fetch(owner) {
    this.publicECDSAKeys.get(owner).catch(e => null);
    this.publicECDHKeys.get(owner).catch(e => null);

    try {
      let response = await this.kdsClient.fetch(owner);

      this.publicECDSAKeys.get(owner).resolve(response.publicECDSAKey);
      this.publicECDHKeys.get(owner).resolve(response.publicECDHKey);
    } catch (e) {
      this.publicECDHKeys.get(owner).reject(e);
      this.publicECDSAKeys.get(owner).reject(e);
    }

    await this.save(owner);
  }

  async save(owner) {
    let negotiation, verification;
    if (this.publicECDHKeys.has(owner)) {
      const future = this.publicECDHKeys.get(owner);
      if (future.isDone()) {
        negotiation = (await (await future.get()).export("spki")).toHex();
      }
    }
    if (this.publicECDSAKeys.has(owner)) {
      const future = this.publicECDSAKeys.get(owner);
      if (future.isDone()) {
        verification = (await (await future.get()).export("spki")).toHex();
      }
    }
    if (negotiation && verification) {
      if (typeof localStorage !== "undefined") {
        const json = JSON.parse(localStorage["public-key-cache"] || "{}");
        json[owner] = { negotiation, verification };
        localStorage["public-key-cache"] = JSON.stringify(json);
      }
    }
  }

  async load() {
    if (typeof localStorage !== "undefined") {
      const json = JSON.parse(localStorage["public-key-cache"] || "{}");

      for (const [owner, { negotiation: n, verification: v }] of Object.entries(
        json
      )) {
        const negotiation = await importPublicECDHKey(Data.fromHex(n), "spki");
        const verification = await importPublicECDSAKey(
          Data.fromHex(v),
          "spki"
        );
        this.publicECDSAKeys.get(owner).resolve(verification);
        this.publicECDHKeys.get(owner).resolve(negotiation);
      }
    }
  }

  updateTicket(ticket) {}
}
