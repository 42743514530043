import React, { useState, useEffect, useRef } from "react";
import { mobile } from "../../helpers/detect-browser";

import components from "./Contexts.js";
import styles from "./Contexts.module.css";

const ANI_MS = 100;

let length = 0;
const contexts = {};

export const registerContext = context => {
  const l = length++;
  contexts[l] = context;
  return () => {
    delete contexts[l];
  };
};

export const useContextMenu = (context, props) => {
  const ref = useRef();
  useEffect(() => registerContext({ ref, context, props }), [context, props]);
  return ref;
};

export default () => {
  const wrapperRef = useRef(null);
  const [context, setContext] = useState({});

  useEffect(() => {
    let previous = "";
    let running = false;
    let params = null;
    const toggle = async (...args) => {
      params = args;
      if (!running) {
        running = true;
        while (params) {
          let [context, x, y, props] = params;
          params = null;
          if (!previous || !context || previous != context + x + y) {
            if (previous) {
              wrapperRef.current?.classList.remove(styles.active);
              await new Promise(f => setTimeout(f, ANI_MS));
              if (!context) {
                setContext({});
              }
            }
            previous = null;
            if (context) {
              setContext({ context, x, y, props });
              wrapperRef.current.classList.add(styles.active);
              await new Promise(f => setTimeout(f, ANI_MS));
              previous = context + x + y;
              window.requestAnimationFrame(() => {
                const [el] = document.getElementsByClassName(styles.wrapper);
                if (el) {
                  const rect = el.getBoundingClientRect();
                  const { x, y, width, height, right, bottom } = rect;
                  if (window.innerWidth < right) {
                    el.style.left = x - width + "px";
                  }
                  if (window.innerHeight < bottom) {
                    el.style.top = y - height + "px";
                  }
                }
              });
            }
          }
        }
        running = false;
      }
    };
    const onContextMenu = event => {
      if (
        /input/i.test(event.target.tagName) &&
        /text|password/i.test(event.target.type)
      ) {
        return;
      }
      event.preventDefault();
      if (mobile) {
        return;
      }
      const dataElement = event.target.closest("[data-context]");
      const dataContext = dataElement?.dataset.context;
      let refContext = null;
      for (let item of Object.values(contexts)) {
        if (item.ref.current?.contains(event.target)) {
          refContext = item;
          break;
        }
      }
      let context = dataContext,
        props = {};
      if (dataElement?.contains(refContext?.ref.current) || refContext) {
        context = refContext.context;
        props = refContext.props;
      }
      context ? toggle(context, event.clientX, event.clientY, props) : toggle();
    };
    document.addEventListener("click", () => toggle());
    document.addEventListener("contextmenu", onContextMenu);
    return () => {
      document.removeEventListener("click", () => toggle());
      document.removeEventListener("contextmenu", onContextMenu);
    };
  }, []);
  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      style={
        context.context && {
          top: `${context.y}px`,
          left: `${context.x}px`
        }
      }
    >
      {components[context.context] &&
        React.createElement(components[context.context], context.props)}
    </div>
  );
};
