import { Icon } from "@blueprintjs/core";
import { useState } from "react";
import styles from "./BhDialogs.module.css";

export default ({
  showAll,
  handleSearchTerm,
  handleShowAll,
  handleSelectAll,
  defaultSelectAll,
}) => {
  return (
    <div className={styles.tableTop}>
      <SearchBar handleSearchTerm={handleSearchTerm} />
      {handleShowAll && (
        <ShowAllButton showAll={showAll} handleShowAll={handleShowAll} />
      )}
      <SelectAllButton
        handleSelectAll={handleSelectAll}
        defaultSelectAll={defaultSelectAll}
      />
    </div>
  );
};

const ShowAllButton = ({ showAll, handleShowAll }) => {
  const buttonText = showAll ? "Show Grouped" : "Show All";
  return (
    <button
      className={styles.tableTopButton}
      onClick={(e) => {
        handleShowAll(showAll);
      }}
    >
      {buttonText}
    </button>
  );
};

const SelectAllButton = ({ handleSelectAll, defaultSelectAll }) => {
  const [isSelectAll, setIsSelectAll] = useState(!defaultSelectAll);
  const buttonText = isSelectAll ? "Select All" : "Deselect All";
  return (
    <button
      className={styles.tableTopButton}
      onClick={(e) => {
        handleSelectAll(isSelectAll);
        setIsSelectAll(!isSelectAll);
      }}
    >
      {buttonText}
    </button>
  );
};

const SearchBar = ({ handleSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className={styles.search}>
      <Icon icon="search" />
      <input
        type="text"
        value={searchTerm}
        {...(searchTerm && { autoFocus: true })}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          handleSearchTerm(e.target.value);
        }}
      />
      <Icon
        icon="cross"
        onClick={(e) => {
          setSearchTerm("");
          handleSearchTerm("");
        }}
      />
    </div>
  );
};
