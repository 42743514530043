export default class Notifier {
  constructor() {
    this.inboxes = [];
  }

  addInbox(inbox, filter) {
    this.inboxes.push({
      inbox: inbox,
      filter: filter
    });
  }

  removeInbox(inbox, filter) {
    this.inboxes.filter(
      holder =>
        holder.inbox != inbox ||
        (typeof filter === "undefined" && holder.filter != filter)
    );
  }

  notify(payload, options) {
    this.inboxes
      .filter(
        holder =>
          typeof filter === "undefined" || holder.filter(payload, options)
      )
      .forEach(holder => holder.inbox(payload, options));
  }
}
