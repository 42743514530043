const regex = new RegExp(/function\s([^(]{1,})\(/);

export function getClassName(object) {
  if (typeof object === "undefined") {
    return undefined;
  } else if (object.constructor.name) {
    return object.constructor.name;
  } else {
    // Internet Explorer does not support object.constructor.name
    const results = object.constructor.toString().match(regex);
    return results && results.length > 1 ? results[1].trim() : "";
  }
}
