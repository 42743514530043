import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "draft-js/dist/Draft.css";
import "normalize.css/normalize.css";
import "react-phone-number-input/style.css";
import "simplebar/dist/simplebar.min.css";
import "./index.css";
import "./preflight.css";

import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createApiClient from "api-client";
import { index, values, uid, nanoid } from "utils";
import App from "./App.js";
import store from "./app/store.js";
import { appState } from "./app/appState.js";
import {
  selectIdentity,
  getToken,
  getChannel,
  initialWindowLocationHref,
} from "./app/topSlice.js";
import { waitFor, setDialog, setOverlay } from "./app/appSlice.js";
import { trustKit } from "./app/trustKit.js";
import {
  startUpload,
  startDownloadBlobBackground,
} from "./features/transfer/transferSlice.js";
import { checkBullhorn } from "./features/bullhorn/bullhornSlice.js";
import { PANE_DEBUG, togglePane } from "./features/layout/layoutSlice.js";
import { restoreSession } from "./features/login/loginSlice.tsx";
import { esignatureLaunchUrl } from "./features/esignature/esignatureSlice";
import { registerServiceWorker } from "./service-worker-client";
import {
  ADD_MEMBER,
  CONFIRM_TEAM_CREATE,
  INVITE,
} from "./features/dialogs/Dialogs.js";
import {
  BH_CREATE_ZONE,
  BH_SYNC_MEMBERS,
  BH_SYNC_FILES,
} from "./features/bullhorn/BhDialogs.js";
import { API_URL_PREFIX } from "./config";
import * as config from "./config.js";
import { doRequestDeprecated } from "./features/attestation/slice.js";
import { createSignatureRequestExpress } from "./features/esignature/esignatureSlice.js";
import { formatUser } from "./features/format/format.js";

//import tkLoadGroup from "./sideloads/tkLoadGroup.js";
//import addMember from "./sideloads/addMember.js";
//import { portal, loadEditor } from "./features/overlays/Editor.js";
//import createEditor from "./alone.js";

const {
  derive,
  misc,
  selectors: { selectGraph },
  actions: {
    removeNode,
    createNode,
    remote,
    createUser,
    addMember,
    removeMember,
    setCurrentZone,
  },
  server: { fetchNode },
} = appState;

const {
  actions: { registerListener },
} = trustKit;

export const getStore = () => store;
export const getState = () => store.getState();
export const getDispatch = () => store.dispatch;

const useSentry =
  window.location.host.endsWith("test.konfident.io") ||
  window.location.host.endsWith("konfident.io");

if (useSentry) {
  Sentry.init({
    dsn: "https://bc510418ccc448848649514a90212cb6@sentry.io/1201915",
    release: `app-konfident@16.0.0`,
    environment: window.location.host.endsWith("test.konfident.io")
      ? "test"
      : "production",
    beforeSend: (event, hint) => {
      if (
        hint.originalException instanceof SyntaxError &&
        hint.originalException.message === "Unexpected token '<'"
      ) {
        return null;
      } else {
        return event;
      }
    },
  });
}

if (window.parent !== window) {
  document.documentElement.classList.add("inside-iframe");
}

//const initialWindowLocationHref = window.location.href;

store.dispatch(waitFor(selectIdentity)).then(() => registerServiceWorker());

store.dispatch(registerListener());

store.dispatch(restoreSession());

store.dispatch(esignatureLaunchUrl());

store.dispatch(checkBullhorn(initialWindowLocationHref));

function logErrorToMyService(error, errorInfo) {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (useSentry) {
      logErrorToMyService(error, errorInfo);
    } else {
      console.error("will not log to sentry", error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="sentry-error">
          <h1>We Are Sorry</h1>
          <div>Something went wrong, an error report has been sent.</div>
          <a
            rel="noopener noreferrer"
            onClick={() => Sentry.showReportDialog()}
          >
            Report additional feedback
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}

ReactDOM.render(
  //<React.StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  /*</React.StrictMode>*/ document.getElementById("root")
);

//window.addEventListener("beforeunload", function (e) {
//  const { getState } = store;
//  const state = getState().login;
//  const pending =
//    (state.process !== "LOGIN" || state.provider) && !state.redirecting;
//  if (pending) {
//    // Cancel the event
//    e.preventDefault();
//    // Chrome requires returnValue to be set
//    e.returnValue = "";
//  }
//});

/// DEBUG TOOLS ///
document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "B" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      //const referrer = new URL(window.location.href);
      const referrer = new URL(
        "http://localhost:3000?EntityType=Client&UserID=3&CorporationID=24103&PrivateLabelID=42535&EntityID=1"
      ); //until we can make it work in iframe we fake it
      referrer.pathname = "/bhtoken";
      const url = new URL(API_URL_PREFIX);
      url.pathname = "/bullhorn/start";
      url.searchParams.set("referrer", referrer.toString());

      window.location.href = url.toString();
      e.preventDefault();
      e.stopPropagation();
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "F" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;

      const state = getState();
      const identity = selectIdentity(state);
      const graph = selectGraph(state);
      const context = derive.getContext(graph, identity);
      const { nodeId } = context;

      const token = await dispatch(getToken());
      const api = createApiClient(config.API_URL_PREFIX);
      const result = await api
        .token(token)
        .files(nodeId)
        .get({
          fields: {
            id: true,
            meta: true,
            name: true,
            index: true,
            group: true,
            storageId: true,
            version: true,
            timestamp: true,
            parent: true,
            esignatures: {
              completed: true,
              status: true,
              requester: true,
              signees: true,
              approvals: true,
              rejections: true,
              order: true,
              current: true,
              signicatId: true,
              signicatUrl: true,
              timestamp: true,
              deadline: true,
              checksum: true,
              tbs: true,
            },
          },
        });
      console.log(result);
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "G" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const identity = selectIdentity(state);
      const graph = selectGraph(state);
      const context = derive.getContext(graph, identity);
      //const { zoneId } = context;
      const zoneId = "lMASq5iGsZql-CVHYPZiZQ";
      const channel = await dispatch(getChannel());
      {
        let topics = [identity.id, zoneId];
        [topics] = channel.diff(topics);
        console.log("T", topics);
        if (topics?.length) {
          console.log("subscript!", [-1, ...topics]);
          channel.subscribe([-1, ...topics]);
        }
      }
      {
        let topics = [identity.id, zoneId];
        [topics] = channel.diff(topics);
        console.log("T", topics);
        if (topics?.length) {
          console.log("subscript!", [-1, ...topics]);
          channel.subscribe([-1, ...topics]);
        }
      }
      {
        let topics = [identity.id, zoneId];
        [topics] = channel.diff(topics);
        console.log("T", topics);
        if (topics?.length) {
          console.log("subscript!", [-1, ...topics]);
          channel.subscribe([-1, ...topics]);
        }
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "O" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch } = store;
      dispatch(setOverlay({ overlay: "PREVIEW", nodeId: "" }));
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "E" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const identity = selectIdentity(state);
      const graph = selectGraph(state);
      const context = derive.getContext(graph, identity);
      const { notLoaded, zoneId, groupId, rootNodeId } = context;

      if (!identity || notLoaded) {
        return;
      }

      (async () => {
        for (let i = 0; i < 1; i++) {
          const folder = {};
          folder.id = uid();
          folder.name = "RandomFolder" + folder.id;
          folder.zoneId = zoneId;
          folder.groupId = groupId;
          folder.parentId = rootNodeId;
          await dispatch(createNode(folder));
          console.log(folder);
        }
      })();
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "R" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const identity = selectIdentity(state);
      const graph = selectGraph(state);
      const context = derive.getContext(graph, identity);
      const { zoneId, node } = context;

      if (node && node.meta) {
        let resolve,
          promise = new Promise((r) => (resolve = r));
        dispatch(
          startDownloadBlobBackground(node, undefined, () => {}, resolve)
        );

        const file = await promise;

        file.id = node.id;
        file.storageId = node.storageId;
        file.name = node.name;
        file.lastModifiedDate = new Date();

        dispatch(startUpload([file], node.group, node.parent, zoneId));
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "J" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const identity = selectIdentity(state);
      const graph = selectGraph(state);
      const context = derive.getContext(graph, identity);
      const { zoneId, groupId, rootNodeId, node } = context;

      let parent = rootNodeId;

      if (node) {
        parent = node.id;
        if (node.meta) {
          parent = node.parent;
        }
      }

      const buffer = new TextEncoder().encode("hello world");
      const file = new Blob([buffer]);

      file.name = "A file " + nanoid() + ".txt";
      file.lastModifiedDate = new Date();

      dispatch(startUpload([file], groupId, parent, zoneId));
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "H" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const graph = getState().appstate.graph;
      const context = derive.getContext(graph);
      const { zoneId, groupId, rootNodeId, node } = context;
      const token = await dispatch(getToken());
      const api = createApiClient(API_URL_PREFIX);
      const result = await api
        .token(token)
        .teams(zoneId)
        //.delete();
        .get({
          fields: { id: true, name: true },
        });
      console.log(result);
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "L" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const graph = getState().appstate.graph;
      const context = derive.getContext(graph);
      const { groupId, rootNodeId, node } = context;
      if (node) {
        const token = await dispatch(getToken());
        const api = createApiClient(API_URL_PREFIX);
        const result = await api
          .token(token)
          .files(node.id)
          //.delete();
          .get({
            fields: { id: true, name: true },
          });
        console.log(result);
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "K" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch } = store;
      const token = await dispatch(getToken());
      const api = createApiClient(API_URL_PREFIX);
      const result = await api
        .token(token)
        .users("37c55cfa-6337-4c0d-83bc-749d638bf711")
        .get({
          fields: { id: true, name: true },
        });
      console.log(result);
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function debugPanel(e) {
    if (e.key == "Y" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const { _debug, _original } = localStorage;
      if (_original) {
        localStorage.id = _original;
        delete localStorage._original;
        window.location.reload();
      } else if (_debug) {
        const _debugger = state.top.identity?.device;
        const { space, provider, identity, device } = misc.parseUri(_debug);
        console.log(space, provider, identity, device);
        if (space && provider && identity && device) {
          const uri = misc.createUri({ provider, identity });
          const token = await dispatch(getToken());
          const api = createApiClient(API_URL_PREFIX);
          const [result] = await api
            .token(token)
            .orgs(space)
            .users()
            .get({
              fields: {
                id: true,
                deprecatedId: true,
              },
              filter: [`deprecatedId[eq]${uri}`],
            });
          //const result = await api.devices(deviceId).get({
          //  fields: {
          //    id: true,
          //    user: {
          //      org: true
          //    },
          //    extra: true
          //  }
          //});
          //if (result && result.org.id == space) {
          //  const userId = result.user.id;
          if (result) {
            const userId = result.id;
            //console.log({
            //  credentials: state.top.identity.credentials,
            //  org: space,
            //  id: userId,
            //  account: uri,
            //  device,
            //  _debugger
            //})
            localStorage._original = localStorage.id;
            localStorage.id = JSON.stringify({
              credentials: state.top.identity.credentials,
              org: space,
              id: userId,
              account: uri,
              device,
              _debugger,
            });
            window.location.reload();
          }
          //const token = await dispatch(getToken());
          //const api = createApiClient(API_URL_PREFIX);
          //const [result] = await api
          //  .token(token)
          //  .orgs(space)
          //  .users()
          //  .get({
          //    fields: {
          //      id: true,
          //      deprecatedId: true,
          //      devices: true
          //    },
          //    filter: [`deprecatedId[eq]${uri}`]
          //  });
          //if (result) {
          //  const { id, devices: [ { id: device } ] } = result;
          //  localStorage._original = localStorage.id;
          //  localStorage.id = JSON.stringify({
          //    credentials: state.top.identity.credentials,
          //    org: space,
          //    id,
          //    account: uri,
          //    device,
          //    _debugger
          //  });
          //  window.location.reload();
          //}
        }
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function debugPanel(e) {
    if (e.key == "Z" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();

      if (state.top.identity) {
        const { id, device, account, org, credentials } = state.top.identity;
        console.log(
          JSON.stringify({ id, device, account, org, credentials }, null, 2)
        );
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  function debugPanel(e) {
    if (e.key == "X" && e.ctrlKey) {
      if (config.API_URL_AUTHORITY.endsWith(".lo")) {
        store.dispatch(togglePane(PANE_DEBUG));
      } else {
        this.count = (this.count || 0) + 1;
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => (this.count = 0), 5000);
        if (this.count == 9) {
          //getPassword().then(async password => {
          //  const SALT = "Ph0YUGTI07kDeiaG5LZWgQ";
          //  const HASH = "2k7-UABPyjwA3yjiisvKfZW1OoHKqDmHXPYatTBEpTs";
          //  if ((await hash(SALT + password)) == HASH) {
          //store.dispatch(togglePane("debug"));
          store.dispatch(togglePane(PANE_DEBUG));
          //  }
          //});
        }
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "A" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch } = store;
      dispatch(setDialog({ dialog: ADD_MEMBER }));
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "I" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch } = store;
      dispatch(setDialog({ dialog: INVITE }));
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "Q" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const graph = state.appstate.graph;

      const MAX = 10;
      const MIN = 5;

      const t0 = +new Date();
      const t1 = Math.ceil(t0 / (MAX * 1000)) * MAX * 1000;
      const x = t1 - t0;
      const y = x / 1000;

      if (y < MIN) {
        console.log("not enough time", y);
        return;
      }

      console.log("the future is in ", y, "seconds");

      //const tests = entries(parTests);
      const tests = [["", parTests["removeNode (folder)"]]];

      for (const [[name, f], i] of index(tests)) {
        console.log("doing", name);
        const it = f(dispatch, graph);
        try {
          await it.next();
        } catch (e) {
          console.log("skipping", name, e);
          continue;
        }
        await new Promise((k) => setTimeout(k, t1 + i * 1000 - new Date()));
        console.log((await it.next()).value);
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (e.key == "W" && e.ctrlKey && config.API_URL_AUTHORITY.endsWith(".lo")) {
      const { dispatch, getState } = store;
      const state = getState();
      const graph = state.appstate.graph;

      const MAX = 10;
      const MIN = 5;

      const t0 = +new Date();
      const t1 = Math.ceil(t0 / (MAX * 1000)) * MAX * 1000;
      const x = t1 - t0;
      const y = x / 1000;

      if (y < MIN) {
        console.log("not enough time", y);
        return;
      }

      console.log("the future is in ", y, "seconds");

      //const tests = entries(parTests);
      const tests = [["", parTests["fetchRootNode"]]];

      for (const [[name, f], i] of index(tests)) {
        console.log("doing", name);
        const it = f(dispatch, graph);
        try {
          await it.next();
        } catch (e) {
          console.log("skipping", name, e);
          continue;
        }
        await new Promise((k) => setTimeout(k, t1 + i * 1000 - new Date()));
        console.log((await it.next()).value);
      }
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (
      e.key == "C" &&
      e.ctrlKey &&
      e.shiftKey &&
      (config.API_URL_AUTHORITY.endsWith(".lo") ||
        config.API_URL_AUTHORITY.endsWith("test.konfident.io"))
    ) {
      const { dispatch, getState } = store;
      const state = getState();
      const graph = state.appstate.graph;

      const context = derive.getContext(graph);

      const user = state.top.identity;
      const { node } = context;

      if (!user || !node || !node.meta) {
        console.log(
          "No id in state, or no node node was selected, or selected node is not a file."
        );
        return;
      }

      dispatch(doRequestDeprecated(node.id, user.id, () => {}));
    }
  },
  false
);

document.addEventListener(
  "keydown",
  async function (e) {
    if (
      e.key == "Å" &&
      e.ctrlKey &&
      e.shiftKey &&
      (config.API_URL_AUTHORITY.endsWith(".lo") ||
        config.API_URL_AUTHORITY.endsWith("test.konfident.io"))
    ) {
      const { dispatch, getState } = store;
      const state = getState();
      const graph = state.appstate.graph;
      const context = derive.getContext(graph);

      const { users } = graph;
      const { node } = context;
      const { id: userId, org } = state.top.identity;

      const requesterAndSigner = values(users);

      const user = requesterAndSigner.find(
        (user) => user.user === userId || user.id === userId
      );
      const selectableUsers = user
        ? [
            {
              id: user.user || user.id,
              text: dispatch(formatUser(user)),
              email: user.email,
              wellknown: user.wellknown[0],
              companyName: `Express test 1`,
              orgNo: "",
              name: user.name,
            },
          ]
        : [];

      const mergedUser = {
        ...values(users).find((user) => user.user == userId),
        ...users[userId],
      };

      const [[primary, secondary], detailed] = dispatch(formatUser(mergedUser));

      let myUser = {
        id: mergedUser.user || mergedUser.id,
        text: [[primary, secondary], detailed],
        email: mergedUser.email,
        wellknown: mergedUser.wellknown,
      };

      myUser = {
        ...myUser,
        name: myUser.text[0][0],
      };

      if (!userId || !node || !node.meta) {
        console.log(
          "No id in state, or no node node was selected, or selected node is not a file."
        );
        return;
      }

      if (node && node.meta) {
        let resolve,
          promise = new Promise((r) => (resolve = r));
        dispatch(
          startDownloadBlobBackground(node, undefined, () => {}, resolve)
        );
        const blob = await promise;
        dispatch(
          createSignatureRequestExpress(
            blob,
            node,
            myUser,
            selectableUsers,
            false,
            org,
            null
          )
        );
      }
    }
  },
  false
);

//document.addEventListener(
//  "keydown",
//  async function(e) {
//    if (e.key == "p" && e.ctrlKey && e.metaKey) {
//      const { dispatch } = store;
//      //const userId = "a30aec35-794c-4dca-a936-72f5dcccaf47";
//      //const groupId = "ohlJYDY_nlc-e12qHoWz6A";
//      const groupId = "4a04c5cfd3a54c019a12e3295863cac7";
//      //const zoneId = "CCJqImT7GzQMrYG1LC7XNw";
//      dispatch(tkLoadGroup(groupId));
//      //dispatch(addMember({ userId, groupId, zoneId }));
//    }
//  },
//  false
//);

const parTests = {
  "removeNode (with subgroups)": async function* (dispatch, graph) {
    const context = derive.getContext(graph);
    const { rootNodeId } = context;
    const nodeWithSubGroups = values(graph.nodes).find(
      (node) => !node.storageId && node.parent == rootNodeId
    );
    if (!nodeWithSubGroups) {
      throw new Error("Could not find any node candidate");
    }

    const hasSubGroup = values(graph.nodes).some(
      (node) =>
        node.group != nodeWithSubGroups.group &&
        node.parent == nodeWithSubGroups.id
    );

    if (!hasSubGroup) {
      throw new Error("Could not find any node candidate");
    }

    yield;
    console.log("will remove node", nodeWithSubGroups.name);
    dispatch(removeNode({ nodeId: nodeWithSubGroups.id }));
    return "ok";
  },
  "removeNode (folder)": async function* (dispatch, graph) {
    const context = derive.getContext(graph);
    const { rootNodeId } = context;
    const node = values(graph.nodes).find((node) => node.parent == rootNodeId);
    if (!node) {
      throw new Error("Could not find any node candidate");
    }
    yield;
    console.log("will remove node", node.name);
    dispatch(removeNode({ nodeId: node.id }));
    return "ok";
  },
  fetchRootNode: async function* (dispatch, graph) {
    const context = derive.getContext(graph);
    const { zoneId, rootNodeId } = context;
    yield;
    console.log("will fetch node", rootNodeId);
    dispatch(remote(fetchNode({ zoneId, id: rootNodeId })));
    return "ok";
  },
  "createNode (file)": async function* (dispatch, graph) {
    const context = derive.getContext(graph);
    const { zoneId, groupId, rootNodeId } = context;
    yield;
    console.log("will create node");
    const buffer = new TextEncoder().encode("hello world");
    const file = new Blob([buffer]);
    file.lastModifiedDate = new Date();
    file.name = "hello-world.txt";
    dispatch(startUpload([file], groupId, rootNodeId, zoneId));
    //dispatch(removeNode({ nodeId: node.id }));
    return "ok";
  },
  "createNode (folder)": async function* (dispatch, graph) {
    const context = derive.getContext(graph);
    const { zoneId, groupId, rootNodeId } = context;
    yield;
    console.log("will create folder node");

    const folder = {};
    folder.id = nanoid();
    folder.name = "A folder " + folder.id;
    folder.zoneId = zoneId;
    folder.groupId = groupId;
    folder.parentId = rootNodeId;

    console.log(folder);
    return;

    dispatch(createNode(folder));
    return "ok";
  },
};
