import { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { classNames } from "utils";
import { selectIsMobile } from "../layout/layoutSlice.js";
import { validateSelectedUsers } from "./utils";
import { combineClassNames } from "./utils";
import { Calender } from "./Calender.js";
import { Order } from "./Order.js";
import { Select } from "./Select.js";
import { Edit } from "./Edit.js";
import { MobileSelect } from "./MobileSelect.js";
import styles from "./Wizard.module.css";

const NUMBER_OF_STEPS = 4;

export const Wizard = (props) => {
  const isMobile = useSelector(selectIsMobile);
  const [step, setstep] = useState(1);

  const {
    selectedUsers,
    setSelectedUsers,
    signingOrder,
    setSigningOrder,
    expire,
    setExpire,
    selectableUsers,
    onSubmit,
    close,
    service,
    SharedIcon,
  } = props;

  const isSelected = selectedUsers?.length > 0;
  const isValidated = validateSelectedUsers(selectedUsers);

  const nextStep = () => {
    setstep(step + 1);
  };

  const prevStep = () => {
    setstep(step - 1);
  };

  let current;

  switch (step) {
    case 1:
      current = (
        <StepOne
          step={step}
          nextStep={nextStep}
          prevStep={close}
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          proceed={isSelected}
          members={selectableUsers}
          isMobile={isMobile}
        />
      );
      break;
    case 2:
      current = (
        <StepTwo
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          proceed={isValidated}
          isMobile={isMobile}
        />
      );
      break;
    case 3:
      current = (
        <StepThree
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          signingOrder={signingOrder}
          setSigningOrder={setSigningOrder}
          service={service}
          isMobile={isMobile}
        />
      );
      break;
    case 4:
      current = (
        <Final
          step={step}
          prevStep={prevStep}
          onSubmit={onSubmit}
          service={service}
          expire={expire}
          setExpire={setExpire}
          SharedIcon={SharedIcon}
          isMobile={isMobile}
        />
      );
      break;
    default:
      console.log("Invalid step number");
      break;
  }

  return (
    <div className={styles.wizard}>
      <Header SharedIcon={SharedIcon} service={service} close={close} />
      {current}
    </div>
  );
};

const Header = ({ SharedIcon, service, close }) => {
  return (
    <div className={styles.title}>
      <div>
        {SharedIcon}
        <h5>{service}</h5>
      </div>
      <Icon icon="cross" onClick={close} />
    </div>
  );
};

const StepOne = ({
  step,
  nextStep,
  prevStep,
  setSelectedUsers,
  selectedUsers,
  proceed,
  members,
  isMobile,
}) => {
  return (
    <div>
      <div className={classNames(styles.first, isMobile && styles.expand)}>
        {isMobile ? (
          <MobileSelect
            selectableUsers={members}
            selectedUsers={selectedUsers}
            setSelected={setSelectedUsers}
          />
        ) : (
          <Select
            selectableUsers={members}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        )}
      </div>
      <Step
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        proceed={proceed}
        btnText={"Cancel"}
        isMobile={isMobile}
      />
    </div>
  );
};

const StepTwo = ({
  step,
  nextStep,
  prevStep,
  setSelectedUsers,
  selectedUsers,
  proceed,
  isMobile,
}) => {
  return (
    <div>
      <div className={styles.second}>
        <Edit setSelected={setSelectedUsers} selectedUsers={selectedUsers} />
      </div>
      <Step
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        proceed={proceed}
        isMobile={isMobile}
      />
    </div>
  );
};

const StepThree = ({
  step,
  nextStep,
  prevStep,
  selectedUsers,
  setSelectedUsers,
  signingOrder,
  setSigningOrder,
  service,
  isMobile,
}) => {
  return (
    <div>
      <div className={styles.third}>
        <Order
          selectedUsers={selectedUsers}
          setSelected={setSelectedUsers}
          signingOrder={signingOrder}
          setSigningOrder={setSigningOrder}
          service={service}
        />
      </div>
      <Step
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        isMobile={isMobile}
      />
    </div>
  );
};

const Final = ({
  step,
  prevStep,
  onSubmit,
  service,
  expire,
  setExpire,
  SharedIcon,
  isMobile,
}) => {
  return (
    <div>
      <Calender expire={expire} setExpire={setExpire} service={service} />
      <Step
        step={step}
        prevStep={prevStep}
        service={service}
        onSubmit={onSubmit}
        SharedIcon={SharedIcon}
        isMobile={isMobile}
      />
    </div>
  );
};

const Step = ({
  step,
  nextStep,
  prevStep,
  proceed,
  service,
  onSubmit,
  btnText,
  SharedIcon,
  isMobile,
}) => {
  const handleNextClick = () => {
    if (proceed !== undefined && !proceed) {
      return;
    }
    nextStep();
  };

  return (
    <div className={styles.step}>
      {!isMobile && <StepIndicator stepIndex={step} service={service} />}
      <div className={isMobile ? styles.mobile : ""}>
        <div
          className={combineClassNames(
            styles.back,
            isMobile && styles.mobileBtn
          )}
          onClick={prevStep}
        >
          <span>{btnText ? btnText : "Back"}</span>
        </div>
        {service ? (
          <div
            onClick={onSubmit}
            className={combineClassNames(
              styles.submit,
              styles[service],
              isMobile && styles.mobileBtn
            )}
          >
            {SharedIcon}
            <span>Request</span>
          </div>
        ) : (
          <div
            className={combineClassNames(styles.next, {
              [styles.disabled]: proceed !== undefined && !proceed,
              [styles.mobileBtn]: isMobile,
            })}
            onClick={handleNextClick}
          >
            <span>Next</span>
          </div>
        )}
      </div>
    </div>
  );
};

const StepIndicator = ({ stepIndex }) => {
  const steps = Array.from({ length: NUMBER_OF_STEPS }, (_, i) => i + 1);
  return (
    <div className={styles.indicatorWrapper}>
      {steps.map((step) => (
        <span
          key={step}
          className={combineClassNames(styles.indicator, {
            [styles.active]: step === stepIndex,
            [styles.finish]: step < stepIndex,
          })}
        />
      ))}
    </div>
  );
};

export const Search = ({ searchValue, onChange, clearSearch }) => {
  const handleClearClick = () => {
    clearSearch();
  };

  return (
    <div>
      <div className={styles.search}>
        <Icon icon="search" />
        <input
          type="text"
          placeholder="Search user"
          onChange={onChange}
          value={searchValue}
        />
        {searchValue && <Icon icon="cross" onClick={handleClearClick} />}
      </div>
    </div>
  );
};
