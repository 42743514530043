import Future from "./future.js";

export default class Cache {
  constructor(notifier) {
    this.map = new Map();
    this.notifier = notifier;
  }

  has(key) {
    return this.map.has(key);
  }

  get(key) {
    if (!this.map.has(key)) {
      let future = new Future();
      if (typeof this.notifier !== "undefined") future.then(this.notifier);
      this.map.set(key, future);
      return future;
    } else {
      return this.map.get(key);
    }
  }

  emplace(key, value) {
    this.map.set(key, value);
  }

  keys() {
    return this.map.keys();
  }

  size() {
    return this.map.size;
  }
}
