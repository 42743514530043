const nonSerializableValues = new Map();

//setInterval(() => {
//  console.log(nonSerializableValues);
//}, 1000);

let i = 0;

export const wrapNonSerializableValue = (value) => {
  const key = "_nonSerializableValue" + i++;
  nonSerializableValues.set(key, value);
  return key;
};

export const unWrapNonSerializableValue = (key) => {
  return nonSerializableValues.get(key);
};

export const releaseNonSerializableValues = (prev, next) => {
  for (const [key, value] of Object.entries(prev)) {
    if (nonSerializableValues.has(value) && !(key in next)) {
      nonSerializableValues.delete(value);
    }
  }
};

//export const weakRefs = new WeakMap();
//
//setInterval(() => {
//  console.log(weakRefs);
//}, 1000);
//
//export const serializableReference = (value) => {
//  const key = {};
//  weakRefs.set(key, value);
//  return key;
//}
//
//export const unwrapReference = (key) => {
//  return weakRefs.get(key);
//}
