import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Menu, MenuItem, Dialog, Intent } from "@blueprintjs/core";
import { FixedSizeList } from "react-window";
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useFlexLayout
} from "react-table";
import { jwtPeek, classNames } from "utils";
import createApiClient from "api-client";
//import { createUri } from "app-state";
import { appState } from "../../app/appState.js";
import { trustKit } from "../../app/trustKit.js";
import styles from "./UserList.module.css";
import dialogs from "./Dialogs.module.css";
import icons from "../login/icons.module.css";
import {
  getToken,
  getChannel,
  getContext,
  selectIdentity
} from "../../app/topSlice.js";
import * as config from "../../config";
import { showPane, PANE_USER } from "../layout/layoutSlice";
//import { formatUri } from "../format/format.js";
import { formatUri } from "./format.js";
import { dispatchToast } from "../toasts/Toasts.js";
import { ZONE } from "../../app/dictionary.js";
import { getState } from "../../index.js";
import { TEAM_TEAMMATE_ADD_OTHER } from "../../app/permissions.js";

const {
  actions: { consume, addMember, remoteSync },
  derive: { getGroupsFromZone, getUsersFromGroup, getUsersFromZoneRootGroup },
  selectors: { selectGraph, selectZone, selectPermissions },
  server: { fetchZoneForeign }
} = appState;

const {
  actions: { loadGroup },
  selectors: { selectScope, selectMembers }
} = trustKit;

const createUri = (user = {}, _space = null) => {
  let { space, provider, identity, wellknown } = user;
  space = _space || space || null;
  provider = provider || null;
  identity = identity || wellknown || [];
  return `${space ? space + ";" : ""}${provider}:${identity.join("|")}`;
};

export default ({}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [reload, setReload] = useState(1);
  const [search, setSearch] = useState();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [paneHeight, setPaneHeight] = useState(500);
  const [dialogContext, setDialogContext] = useState(null);

  const giveRefForMeasure = useCallback(el => {
    if (el) {
      setPaneHeight(el.offsetHeight);
    }
  }, []);

  const doReload = () => setReload(reload + 1);

  useEffect(
    () => {
      (async _ => {
        setLoading(true);
        const token = await dispatch(getToken());
        const { org } = jwtPeek(token);

        const api = createApiClient(config.API_URL_PREFIX);
        const users = await api
          .token(token)
          .orgs(org)
          .users()
          .get({
            fields: {
              role: {
                name: true,
                color: true
              },
              provider: true,
              wellknown: true,
              email: true,
              name: true,
              teams: {
                name: true,
                rootGroup: true
              }
            }
          });

        const tabelized = [];
        for (const user of users) {
          user.teams = user.teams.length === 0 ? [{ name: "" }] : user.teams;
          for (const team of user.teams) {
            tabelized.push({
              name: user.name,
              role: user.role.name,
              roleBg: user.role.color.background,
              roleFg: user.role.color.foreground,
              provider:
                user.provider == "placeholder" ? "bankid" : user.provider,
              zone: team.name,
              zoneId: team.id,
              rootGroup: team.rootGroup?.id,
              wellknown: user.wellknown.join("|"),
              user: user
            });
          }
        }
        setData(tabelized);
        setLoading(false);
      })();
    },
    [reload]
  );

  useEffect(
    () => {
      setColumns([
        {
          Header: "Name",
          accessor: "name",
          canSearch: true,
          Filter: header => (
            <div
              className={classNames(
                styles.search,
                header.column.id === search && styles.visible
              )}
            >
              <Icon
                icon="reset"
                onClick={_ => header.setFilter(header.column.id) | setSearch()}
              />
              <input
                autoFocus
                className={styles.input}
                placeholder="Name..."
                value={header.column.filterValue || ""}
                onChange={e =>
                  header.setFilter(header.column.id, e.target.value)
                }
              />
            </div>
          ),
          Cell: row => (
            <span>
              <span
                style={{
                  textTransform: "capitalize"
                }}
              >
                {row.value}
              </span>
            </span>
          )
        },
        {
          Header: "Identity",
          accessor: "wellknown",
          canSearch: true,
          Filter: header => (
            <div
              className={classNames(
                styles.search,
                header.column.id === search && styles.visible
              )}
            >
              <Icon
                icon="reset"
                onClick={_ => header.setFilter(header.column.id) | setSearch()}
              />
              <input
                autoFocus
                className={styles.input}
                placeholder="Identity..."
                value={header.column.filterValue || ""}
                onChange={e =>
                  header.setFilter(header.column.id, e.target.value)
                }
              />
            </div>
          )
        },
        {
          Header: "Provider",
          accessor: "provider",
          disableFilters: true,
          Cell: row => (
            <span
              {...row.cell.row.original && {
                className: classNames(styles.pill, styles.wide),
                style: {
                  color: row.value == "email" ? "#334155" : "#1b5e20",
                  background: row.value == "email" ? "#E2E8F0" : "#69f0ae"
                }
              }}
            >
              {row.value}
            </span>
          )
        },
        {
          Header: ZONE[0].toUpperCase() + ZONE.slice(1),
          accessor: "zone",
          canSearch: true,
          Filter: header => (
            <div
              className={classNames(
                styles.search,
                header.column.id === search && styles.visible
              )}
            >
              <Icon
                icon="reset"
                onClick={_ => header.setFilter(header.column.id) | setSearch()}
              />
              <input
                autoFocus
                className={styles.input}
                placeholder={ZONE + "..."}
                value={header.column.filterValue || ""}
                onChange={e =>
                  header.setFilter(header.column.id, e.target.value)
                }
              />
            </div>
          )
        },
        {
          Header: "Role",
          accessor: "role",
          disableFilters: true,
          Cell: row => (
            <span
              {...row.cell.row.original && {
                className: styles.pill,
                style: {
                  color: row.cell.row.original.roleFg,
                  background: row.cell.row.original.roleBg
                }
              }}
            >
              {row.value}
            </span>
          )
        }
      ]);
    },
    [search]
  );

  const selectUser = async user => {
    await dispatch(showPane({ ...PANE_USER, user }));
  };

  const table = useTable(
    {
      columns,
      data
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout
  );

  const { pageIndex, pageSize, sortBy, groupBy, filters } = table.state;

  const infiniteScroll = useInfiniteScroll({
    enabled: true,
    sortBy,
    groupBy,
    filters,
    pageIndex,
    pageSize
  });

  if (loading) {
    return (
      <div className={styles.outer}>
        <div className={styles.skeleton}>
          <div>
            <div />
            <div />
            <div />
          </div>
          <div>
            <div />
            <div />
            <div />
          </div>
          <div>
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }

  let tableBody;
  const renderRow = (row, index, style = {}) => {
    table.prepareRow(row);
    return (
      <div
        onClick={() => {
          if (row.original) {
            setDialogContext(row.original);
          } else {
            table.toggleRowExpanded(row.id);
          }
        }}
        className={classNames(styles.row, index % 2 && styles.odd)}
        {...row.getRowProps({ style })}
      >
        {row.cells.map(cell => {
          const isPivot = row.groupByID === cell.column.id;
          const showAggregate = row.subRows.length > 0 && !isPivot;
          return (
            <div {...cell.getCellProps()} className={styles.cell}>
              {showAggregate ? (
                cell.column.aggregate ? (
                  cell.render("Aggregated")
                ) : null
              ) : (
                <>
                  {isPivot ? (
                    <Icon
                      icon={row.isExpanded ? "caret-down" : "caret-right"}
                    />
                  ) : null}
                  <span>{cell.render("Cell")}</span>
                  {isPivot ? <span> ({row.subRows.length})</span> : null}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const panelHeight = paneHeight - 40 - (search ? 50 : 0);

  tableBody = (
    <FixedSizeList
      ref={infiniteScroll.listRef}
      height={panelHeight}
      itemCount={table.rows.length}
      itemSize={infiniteScroll.rowHeight}
      overscanCount={infiniteScroll.overscan}
      scrollToAlignment="start"
    >
      {({ index, style }) => {
        const row = table.rows[index];
        return renderRow(row, index, style);
      }}
    </FixedSizeList>
  );

  const grouped = table.headerGroups.some(headerGroup =>
    headerGroup.headers.some(column => groupBy.includes(column.id))
  );

  return (
    <div
      ref={giveRefForMeasure}
      className={classNames(
        styles.outer,
        search && styles.searching,
        grouped && styles.grouped
      )}
    >
      <div className={styles.table}>
        {table.headerGroups.map(headerGroup => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className={styles.headerRow}
          >
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className={styles.header}>
                <div>
                  <span>{column.render("Header")}</span>
                  {(column.isSorted &&
                    (column.isSortedDesc ? (
                      <div {...column.getSortByToggleProps()}>
                        <Icon icon="sort-alphabetical-desc" />
                      </div>
                    ) : (
                      <div {...column.getSortByToggleProps()}>
                        <Icon icon="sort-alphabetical" />
                      </div>
                    ))) || (
                    <div {...column.getSortByToggleProps()}>
                      <Icon icon="sort" />
                    </div>
                  )}
                  {column.canGroupBy ? (
                    <div {...column.getGroupByToggleProps()}>
                      <Icon
                        icon={
                          groupBy.includes(column.id) ? "reset" : "diagram-tree"
                        }
                      />
                    </div>
                  ) : null}
                  {column.canSearch &&
                    !search && (
                      <div onClick={_ => setSearch(column.id)}>
                        <Icon icon={"search"} />
                      </div>
                    )}
                </div>
                {column.canFilter ? <div>{column.render("Filter")}</div> : null}
              </div>
            ))}
          </div>
        ))}
        {tableBody}
      </div>
      <MyDialog {...{ data, dialogContext, setDialogContext, doReload }} />
    </div>
  );
};

const MyDialog = ({ data, dialogContext, setDialogContext, doReload }) => {
  const dispatch = useDispatch();

  const { id: myUserId } = useSelector(selectIdentity);
  const permissions = useSelector(selectPermissions);

  const [step, setStep] = useState(null);

  const [result, setResult] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [addUserItem, setAddUserItem] = useState(null);
  const [disableAddUserButton, setDisableAddUserButton] = useState(false);

  const userId = addUserItem?.user.id;
  const zoneId = dialogContext?.zoneId;
  const groupId = dialogContext?.rootGroup;

  const users = Object.values(
    Object.fromEntries(data.map(data => [data.user.id, data]))
  );
  const zoneMembers =
    zoneId &&
    data.filter(data => data.zoneId == zoneId).map(data => data.user.id);

  useEffect(
    () => {
      if (!dialogContext) {
        setStep(null);
        setSearchValue("");
        setAddUserItem(null);
        setDisableAddUserButton(false);
      }
    },
    [dialogContext]
  );

  useEffect(
    () => {
      if (searchValue) {
        const query = searchValue.replace(/[^a-z0-9-_@.]+/gi, ".*");
        const regex = new RegExp(".*" + query + ".*", "gi");
        const result = users
          .filter(
            row =>
              regex.test(row.user.name) ||
              regex.test(row.user.wellknown.join("|"))
          )
          .filter(row => !zoneMembers.includes(row.user.id));
        result.forEach(row => (row.active = false));
        if (result.length) {
          result[0].active = true;
        }
        setResult(result);
      } else {
        setResult(null);
      }
    },
    [searchValue]
  );

  if (!dialogContext) {
    return null;
  }

  const move = e => {
    if (!result || !result.length) {
      return;
    }

    if (e.key == "Enter") {
      e.preventDefault();
      const row = result.find(row => row.active);
      setAddUserItem(row);
      return;
    }

    let index;

    if (e.key == "ArrowUp") {
      e.preventDefault();
      index = result.findIndex(row => row.active) - 1;
    } else if (e.key == "ArrowDown") {
      e.preventDefault();
      index = result.findIndex(row => row.active) + 1;
    } else {
      return;
    }

    index = Math.min(Math.min(Math.max(0, index), result.length - 1), 9);
    result.forEach(row => (row.active = false));
    result[index].active = true;
    setResult([...result]);
  };

  const submitAddUser = async () => {
    setDisableAddUserButton(true);

    if (!permissions[TEAM_TEAMMATE_ADD_OTHER]) {
      dispatchToast(dispatch, {
        message: "You lack required permission",
        icon: "warning-sign",
        intent: Intent.DANGER
      });
      setDisableAddUserButton(false);
      return;
    }

    const { credentials: { deviceId } } = await dispatch(getContext());

    await dispatch(loadGroup(groupId));

    let members = selectMembers(selectScope(getState()))(groupId);

    if (!members.includes(deviceId)) {
      dispatchToast(dispatch, {
        message: "Admin not part of zone group",
        icon: "warning-sign",
        intent: Intent.DANGER
      });
      setDisableAddUserButton(false);
      return;
    }

    await (await dispatch(getChannel())).subscribe([-1, myUserId, zoneId]);

    await dispatch(remoteSync(fetchZoneForeign({ id: zoneId })));

    const graph = selectGraph(getState());
    const isMember = getUsersFromZoneRootGroup(graph, zoneId).some(
      user => user.user == myUserId
    );

    if (isMember) {
      const { foreign, ...zone } = graph.zones[zoneId];
      dispatch(consume({ graph: { zones: { [zoneId]: zone } } }));
    }

    const toast = dispatchToast(dispatch, {
      message: "Adding User",
      icon: "SPINNER",
      intent: Intent.PRIMARY,
      timeout: 0
    });
    const { reason } = await dispatch(addMember({ userId, zoneId }));
    if (reason) {
      if (reason != "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER
        });
      } else {
        toast.dismiss();
      }
    } else {
      toast.replace({
        message: "User Added",
        icon: "CHECK",
        intent: Intent.PRIMARY
      });
    }
    setDialogContext(null);
    doReload();
  };

  const [[primary, secondary], detailed, identity, provider] = formatUri(
    createUri({
      provider: dialogContext.user.provider,
      identity: dialogContext.user.wellknown
    }),
    dialogContext.user.name
  );

  let icon, title, body;

  if (!step) {
    const { zone, zoneId, role, roleFg, roleBg } = dialogContext;
    icon = "th-derived";
    title = "Edit User Catalog";
    body = (
      <div className={styles.dialogSteps}>
        {zoneId && (
          <>
            <div className={dialogs.row}>
              <div className={dialogs.head}>
                {ZONE[0].toUpperCase() + ZONE.slice(1)}:
              </div>
              <div className={dialogs.pill}>
                <div>
                  <Icon iconSize={16} icon={"application"} />
                </div>
                <div>{zone}</div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <button
                disabled={!zoneId}
                className={classNames(dialogs.dialogButton, dialogs.small)}
                onClick={() => setStep("ADD_USER")}
              >
                Add User
              </button>
              <button
                disabled
                className={classNames(dialogs.dialogButton, dialogs.small)}
              >
                Remove Member
              </button>
              <button
                disabled
                className={classNames(dialogs.dialogButton, dialogs.small)}
              >
                Join {ZONE[0].toUpperCase() + ZONE.slice(1)}
              </button>
            </div>
            <br />
            <br />
            <br />
          </>
        )}
        <div className={dialogs.row}>
          <div className={dialogs.head}>User:</div>
          <div title={detailed} className={classNames(dialogs.recipient)}>
            <span>{primary}</span>
            {secondary && <span>{secondary}</span>}
          </div>
          <div
            className={classNames(
              dialogs.provider,
              dialogs[`provider-${provider}`]
            )}
          >
            <span>{provider}</span>
          </div>
        </div>
        <br />
        <br />
        <div>
          <button
            disabled
            className={classNames(dialogs.dialogButton, dialogs.small)}
          >
            Delete User
          </button>
          <button
            disabled
            className={classNames(dialogs.dialogButton, dialogs.small)}
          >
            Change Role
          </button>
        </div>
        <br />
        <br />
        <br />
        <div className={dialogs.row}>
          <div className={dialogs.head}>Role:</div>
          <div className={classNames(dialogs.role)}>
            <span
              style={{
                backgroundColor: roleBg,
                color: roleFg
              }}
            >
              {role}
            </span>
          </div>
        </div>
        <br />
        <br />
        <div>
          <button
            disabled
            className={classNames(dialogs.dialogButton, dialogs.small)}
          >
            Edit Role
          </button>
          <button
            disabled
            className={classNames(dialogs.dialogButton, dialogs.small)}
          >
            Delete Role
          </button>
          <button
            disabled
            className={classNames(dialogs.dialogButton, dialogs.small)}
          >
            Add Role
          </button>
        </div>
        <br />
      </div>
    );
  }
  if (step == "ADD_USER") {
    title = "Add User";
    icon = "new-person";
    if (addUserItem) {
      const { zone } = dialogContext;
      const { role, roleFg, roleBg } = addUserItem;
      const [[primary, secondary], detailed, identity, provider] = formatUri(
        createUri({
          provider: addUserItem.user.provider,
          identity: addUserItem.user.wellknown
        }),
        addUserItem.user.name
      );

      body = (
        <div>
          <div className={dialogs.hero}>Add this user to that {ZONE}?</div>
          <div className={classNames(dialogs.row, dialogs.wow)}>
            <div title={detailed} className={classNames(dialogs.recipient)}>
              <span>{primary}</span>
              {secondary && <span>{secondary}</span>}
            </div>
            <div
              className={classNames(
                dialogs.provider,
                dialogs[`provider-${provider}`]
              )}
            >
              <span>{provider}</span>
            </div>
            <div className={classNames(dialogs.role)}>
              <span
                style={{
                  backgroundColor: roleBg,
                  color: roleFg
                }}
              >
                {role}
              </span>
            </div>
          </div>
          <div className={dialogs.icon}>
            <Icon iconSize={40} icon="small-plus" />
          </div>
          <div className={classNames(dialogs.row, dialogs.wow)}>
            <div className={dialogs.pill}>
              <div>
                <Icon iconSize={16} icon={"application"} />
              </div>
              <div>{zone}</div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className={dialogs.dialogButtons}>
            <button
              className={classNames(dialogs.dialogButton, dialogs.right)}
              onClick={() => setDialogContext(null) | setStep(null)}
            >
              Cancel
            </button>
            <button
              autoFocus
              disabled={disableAddUserButton}
              className={dialogs.dialogButton}
              onClick={() => submitAddUser()}
            >
              Okay
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div>
          <div className={dialogs.inputGroup}>
            <input
              autoFocus
              type="text"
              placeholder="Search Text e.g. Name or Email Address"
              className={dialogs.input}
              onChange={e => setSearchValue(e.target.value)}
              onKeyDown={e => move(e)}
            />
            <button className={dialogs.dialogButton}>Find</button>
          </div>
          <div>
            {result &&
              !!result.length && (
                <div className={dialogs.results}>
                  <br />
                  {result &&
                    result.slice(0, 10).map((row, i) => {
                      const [
                        [primary, secondary],
                        detailed,
                        identity,
                        provider
                      ] = formatUri(
                        createUri({
                          provider: row.user.provider,
                          identity: row.user.wellknown
                        }),
                        row.user.name
                      );
                      return (
                        <div
                          key={row.user.id}
                          className={classNames(
                            dialogs.result,
                            row.active && dialogs.active
                          )}
                          onClick={() => setAddUserItem(row)}
                        >
                          <div
                            title={detailed}
                            className={classNames(dialogs.column, dialogs.user)}
                          >
                            <span>{primary}</span>
                            {secondary && <span>{secondary}</span>}
                          </div>
                          <div
                            className={classNames(
                              dialogs.column,
                              dialogs.provider,
                              dialogs[`provider-${row.user.provider}`]
                            )}
                          >
                            <span>{provider}</span>
                          </div>
                          <div
                            className={classNames(dialogs.column, dialogs.role)}
                          >
                            <span
                              style={{
                                backgroundColor: row.roleBg,
                                color: row.roleFg
                              }}
                            >
                              {row.role}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            {result &&
              10 < result.length && (
                <div className={dialogs.info}>
                  <div>
                    <Icon iconSize={24} icon="zoom-in" />
                  </div>
                  <div>
                    <div>That's Not All</div>
                    <div>
                      You can find more if you try something more specific
                    </div>
                  </div>
                </div>
              )}
            {result &&
              !result.length && (
                <div className={dialogs.empty}>
                  <div>
                    <Icon iconSize={30} icon="zoom-out" />
                  </div>
                  <div>
                    <div>Nothing Found</div>
                    <div>Try again with something less specific</div>
                  </div>
                </div>
              )}
          </div>
        </div>
      );
    }
  }
  return (
    <Dialog
      isOpen={true}
      onClose={() => setDialogContext(null) | setStep(null)}
      title={
        <div className={dialogs.title}>
          <div
            className={classNames(
              "squircle",
              icons.squircle,
              dialogs.quickIcon
            )}
          >
            <Icon icon={icon} iconSize={20} />
          </div>
          <span className={dialogs.dialogTitle}>{title}</span>
        </div>
      }
      className={dialogs.dialog}
    >
      <div className={dialogs.dialogBody}>{body}</div>
    </Dialog>
  );
};

const useInfiniteScroll = ({
  enabled,
  sortBy,
  groupBy,
  filters,
  pageIndex,
  pageSize
}) => {
  const listRef = useRef();
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [rowHeight, setRowHeight] = useState(40);
  const [height, setHeight] = useState(450);
  const [overscan, setOverscan] = useState(25);

  useEffect(
    () => {
      if (!enabled) {
        return;
      }
      if (listRef.current) {
        listRef.current.scrollToItem(scrollToIndex, "start");
      }
    },
    [scrollToIndex]
  );

  useLayoutEffect(
    () => {
      if (!enabled) {
        return;
      }
      if (listRef.current) {
        listRef.current.scrollToItem(0, "start");
      }
    },
    [sortBy, groupBy, filters]
  );

  return {
    listRef,
    scrollToIndex,
    setScrollToIndex,
    rowHeight,
    setRowHeight,
    height,
    setHeight,
    overscan,
    setOverscan
  };
};
