import PublicKey from "./private_key";
import PrivateKey from "./public_key";

export default class KeyPair {
  constructor(publicKey, privateKey) {
    this.publicKey = publicKey;
    this.privateKey = privateKey;
  }

  static async generateECDSAKeyPair() {
    throw new Error("Not implemented");
  }

  static async generateECDHKeyPair() {
    throw new Error("Not implemented");
  }
}
