import { Dialog, Intent } from "@blueprintjs/core";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import appContext from "../../app/appContext.js";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import { AddUser } from "../login/icons.tsx";
import { dispatchToast } from "../toasts/Toasts.js";
import styles from "./BhDialogs.module.css";
import BhSyncMembers from "./BhSyncMembers.js";
import {
  addUsers,
  postDisregarded,
  selectContactsStatus,
  setDisregarded,
  useUsersDiff,
} from "./bullhornSlice.js";

const ADDING_MEMBERS = "Adding Member(s).";
const MEMBERS_ADDED = "Member(s) Added.";
const ADD = "Add";

export default function SyncAddedMembers({ dispatch, active, close }) {
  const { zoneId } = useContext(appContext);
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(false);
  const identity = useSelector(selectIdentity);

  const contentStatus = useSelector(selectContactsStatus);
  const { added: users } = useUsersDiff();

  if (!identity) {
    return null;
  }

  const alertUserError = (user) => {
    let msg;
    if (user.provider === "2fa" && user.validEmail && user.validPhoneNr) return;
    if (!user.validEmail && !user.validPhoneNr) {
      msg = `Email address "${user.email}" and phone number "${user.phoneNr}" are not valid!`;
    } else if (!user.validEmail) {
      msg = `Email address "${user.email}" is not valid!`;
    } else if (!user.validPhoneNr) {
      msg = `Phone number "${user.phoneNr}" is not valid!`;
    }
    alert(msg);
  };

  const syncUsers = async (usersToSync, shouldSkip) => {
    const accessToken = await dispatch(getToken());
    const added = users
      .filter(
        (user) => !usersToSync.some((userToSync) => user.id === userToSync.id)
      )
      .map((user) => user.email);
    const removed = usersToSync.map((user) => user.email);
    dispatch(setDisregarded({ contacts: added }));
    await postDisregarded(
      zoneId,
      { contacts: { added, removed } },
      accessToken
    );

    if (!shouldSkip) {
      setDisableCreateNewTeamButton(true);
      const toast = dispatchToast(dispatch, {
        message: ADDING_MEMBERS,
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0,
      });

      const reason = await dispatch(addUsers({ users: usersToSync, zoneId }));
      console.log("reason", reason);
      if (reason && reason !== "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else {
        toast.replace({
          message: MEMBERS_ADDED,
          icon: "CHECK",
          intent: Intent.PRIMARY,
        });
      }
    }
    close();
    setDisableCreateNewTeamButton(false);
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      canOutsideClickClose={false}
      title={
        <div className={styles.title}>
          <AddUser size={34} />
          <span className={styles.dialogTitle}>Sync Added Contacts</span>
        </div>
      }
      className={styles.dialog}
    >
      <BhSyncMembers
        dispatch={dispatch}
        sync={syncUsers}
        disableCreateNewTeamButton={disableCreateNewTeamButton}
        users={users}
        contentStatus={contentStatus}
        buttonText={ADD}
        defaultAllSelected={false}
        alertUserError={alertUserError}
      />
    </Dialog>
  );
}
