const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, crop, targetSize = {}) {
  const image = await createImage(imageSrc);
  const width = crop.width / 100 * image.width;
  const height = crop.height / 100 * image.height;
  let canvas = document.createElement("canvas");
  canvas.width = targetSize.width || width;
  canvas.height = targetSize.height || height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x / 100 * image.width,
    crop.y / 100 * image.height,
    width,
    height,
    0,
    0,
    targetSize.width || width,
    targetSize.height || height
  );

  canvas.imageSmoothingQuality = "high";
  ctx.imageSmoothingQuality = "high";

  // As Base64 string
  return canvas.toDataURL("image/png");
}
