import React, { useState } from "react";
import { Button, Icon } from "@blueprintjs/core";
import { classNames } from "utils";

import styles from "./index.module.css";

export const validateEmail = value =>
  /^[a-z0-9_.+-]+@([a-z0-9-]+\.)+[a-z0-9-]+$/i.test(value) &&
  value.length <= 200;

export default function Email({ state, setState, submit }) {
  const {
    moreOptions,
    notifyAddress = "",
    wellknown: [email = ""] = []
  } = state;

  const { showErrors, emailOk, notifyOk } = state;

  return (
    <>
      <div className={styles.br} style={{ height: "30px" }} />
      <input
        type="text"
        placeholder="Email Address (alice@example.com)"
        className={classNames(
          styles.input,
          showErrors && !emailOk && styles.error
        )}
        value={email}
        onChange={e => {
          const email = e.target.value;
          const emailOk = validateEmail(email);
          setState({ wellknown: [email], emailOk });
        }}
        onKeyDown={e => e.key == "Enter" && submit()}
      />
      <div className={styles.br} style={{ height: "20px" }} />
      <div
        onClick={() => setState({ moreOptions: !moreOptions })}
        className={styles.moreOptions}
      >
        <Icon iconSize={14} icon="form" />
        More Options
      </div>
      {moreOptions && (
        <>
          <div className={styles.br} style={{ height: "30px" }} />
          <div className={styles.label}>
            Use a different email address for notifications:
          </div>
          <input
            type="text"
            placeholder="Notification Email Address (alice@example.com)"
            className={classNames(
              styles.input,
              showErrors && !notifyOk && styles.error
            )}
            value={notifyAddress}
            onChange={e => {
              const email = e.target.value;
              const notifyOk = validateEmail(email);
              setState({ notifyAddress: email, notifyOk });
            }}
            onKeyDown={e => e.key == "Enter" && submit()}
          />
        </>
      )}
    </>
  );
}
