import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { PANE_DITTO, hidePane } from "../layout/layoutSlice";
import { appState } from "../../app/appState.js";
import {} from "../../app/permissions.js";
import styles from "./Ditto.module.css";

const {} = appState;

const components = {};

export const registerComponent = (id, component) => {
  components[id] = component;
};

export const Ditto = ({ pane: { component, ...args } }) => {
  const dispatch = useDispatch();

  let instance = null;

  if (component && component in components) {
    const Component = components[component];
    instance = <Component {...args} />;
  }

  return (
    <div className={styles.outer}>
      <div
        className={styles.close}
        onClick={() => dispatch(hidePane(PANE_DITTO))}
      >
        <Icon icon="cross" />
      </div>
      {instance}
    </div>
  );
};
