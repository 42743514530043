import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Switch, Intent } from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import * as Sentry from "@sentry/browser";
import { classNames, keys, values } from "utils";
import Enable from "./Enable";
import { selectWebPushStatus } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { dispatchToast } from "../toasts/Toasts.js";
import browser from "../../helpers/detect-browser";
import {
  LOG_MESSAGE_DELETE,
  LOG_MESSAGE_EDIT,
  LOG_MESSAGE_READ,
  LOG_WORKSPACE_DELETE_LOGS,
  LOG_WORKSPACE_EDIT_LOGS,
  LOG_WORKSPACE_EXPORT_LOGS,
  LOG_WORKSPACE_EXPORT_REPORTS,
  LOG_WORKSPACE_LIST_TEAMS,
  LOG_WORKSPACE_ROLE_ADD
} from "../../app/permissions.js";
import {
  fetchSettings,
  selectLoading,
  selectFilter,
  selectNotificationAddress,
  changeFilter,
  changeNotificationAddress
} from "./activitySlice.js";
import styles from "./Settings.module.css";
import * as unblock from "./unblock";

const { selectors: { selectPermissions } } = appState;

export const Header = () => (
  <div className={styles.header}>
    <p>Settings</p>
  </div>
);

export default ({ ...props }) => {
  const loading = useSelector(selectLoading);
  const filter = useSelector(selectFilter);
  const webPushStatus = useSelector(selectWebPushStatus);
  const [showUnblockDialog, setShowUnblockDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box>
      <Spacer>
        <SectionHeader>Notifications</SectionHeader>
        {!/(granted|nosupport)/.test(webPushStatus) && (
          <Enable
            webPushStatus={webPushStatus}
            showUnblockDialog={showUnblockDialog}
            setShowUnblockDialog={setShowUnblockDialog}
          />
        )}
        <NotificationSettings
          filter={filter["GLOBAL"]}
          onChange={async filter => {
            const toast = await dispatchToast(dispatch, {
              message: `Changing Filter.`,
              icon: "SPINNER",
              intent: Intent.PRIMARY,
              timeout: 0
            });
            try {
              await dispatch(changeFilter({ team: "GLOBAL", filter }));
              await toast.replace({
                message: `Changed Filter.`,
                icon: "CHECK",
                intent: Intent.PRIMARY
              });
            } catch (e) {
              Sentry.captureException(e);
              await toast.replace({
                message: `Failed to Change Filter.`,
                icon: "warning-sign",
                intent: Intent.DANGER
              });
            }
          }}
        />
      </Spacer>
      <UnblockDialog
        showUnblockDialog={showUnblockDialog}
        setShowUnblockDialog={setShowUnblockDialog}
      />
    </Box>
  );
};

const NotificationSettings = ({ filter, onChange }) => {
  const webPushStatus = useSelector(selectWebPushStatus);

  if (filter.length == 0) {
    return null;
  }

  const webPushFilter = filter.find(f => f.channelType === "WEB_PUSH");
  const emailFilter = filter.find(f => f.channelType === "EMAIL");
  const webPushEnabled = values(webPushFilter.eventType).some(et => et);

  return (
    <>
      {webPushStatus == "granted" &&
        webPushFilter && (
          <ChannelSection>
            <ChannelFilter
              label={"Push Notifications"}
              channelType={webPushFilter.channelType}
              eventType={webPushFilter.eventType}
              time={webPushFilter.time}
              onChange={onChange}
              binary={true}
            />
          </ChannelSection>
        )}
      {emailFilter && (
        <ChannelSection>
          <ChannelFilter
            label="Email Notifications"
            border={true}
            channelType={emailFilter.channelType}
            eventType={emailFilter.eventType}
            time={emailFilter.time}
            onChange={onChange}
            expand={true}
          />
          <br />
        </ChannelSection>
      )}
    </>
  );
};

const ChannelFilter = props => {
  const dispatch = useDispatch();
  const [notificationAddress, setNotificationAddress] = useState(
    useSelector(selectNotificationAddress) || ""
  );
  const [notificationAddressError, setNotificationAddressError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const permissions = useSelector(selectPermissions);

  useEffect(
    () => {
      setNotificationAddressError("");
    },
    [notificationAddress]
  );

  const filter = {
    channelType: props.channelType,
    eventType: props.eventType,
    time: props.time
  };
  const enabled = values(filter.eventType).some(et => et);

  const handleNotificationAddressChanged = async () => {
    if (!isEmailAddress(notificationAddress)) {
      setNotificationAddressError(true);
    } else {
      const toast = await dispatchToast(dispatch, {
        message: `Changing Address.`,
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0
      });
      try {
        await dispatch(changeNotificationAddress({ notificationAddress }));
        await toast.replace({
          message: `Changed Address.`,
          icon: "CHECK",
          intent: Intent.PRIMARY
        });
      } catch (e) {
        Sentry.captureException(e);
        await toast.replace({
          message: `Failed to Change Address.`,
          icon: "warning-sign",
          intent: Intent.DANGER
        });
      }
    }
  };

  const handleChannelChange = event => {
    const enabled = event.target.checked;
    props.onChange(setAllEventTypes(enabled, filter));
  };

  const handleEventChange = (value, name) => {
    props.onChange({
      ...filter,
      eventType: {
        ...filter.eventType,
        [name]: value
      }
    });
  };

  const handleTimeChange = time => {
    props.onChange({
      ...filter,
      time
    });
  };

  const { channelType, eventType, time, binary } = props;

  return (
    <FilterPanel border={props.border && enabled}>
      <CheckBox
        large={true}
        label={props.label}
        valueType={channelType}
        value={enabled}
        onChange={event => handleChannelChange(event)}
        expand={props.expand && (() => setExpanded(!expanded))}
      />
      {enabled &&
        expanded &&
        !binary && (
          <div>
            <br />
            <PanelHeader>Notification address</PanelHeader>
            <MethodPanel inputError={!!notificationAddressError}>
              <div className="bp3-form-group">
                <div className="bp3-form-content">
                  <div className="bp3-input-group">
                    <input
                      type="text"
                      className="bp3-input"
                      placeholder="john@example.com"
                      value={notificationAddress}
                      onChange={e => setNotificationAddress(e.target.value)}
                      onKeyPress={e => {
                        if (e.key == "Enter") {
                          handleNotificationAddressChanged();
                        }
                      }}
                    />
                    <button
                      className="bp3-button bp3-minimal bp3-intent-success"
                      onClick={handleNotificationAddressChanged}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </MethodPanel>
            <br />
            <br />
            <PanelHeader>When to be notified</PanelHeader>
            <TimePanel>
              <Time time={time} onChange={handleTimeChange} />
            </TimePanel>
            <br />
            <br />
            <PanelHeader>Your events</PanelHeader>
            <SwitchPanel>
              {sortedKeys(eventType)
                .filter(IS_IMPLEMENTED)
                .filter(action => permissions[action])
                .map(et => (
                  <CheckBox
                    key={et}
                    label={et
                      .replace(/^LOG_/g, "")
                      .replace(/^WORKSPACE_/g, "")
                      .replace(/^TEAM_TEAMMATE/g, "TEAMMATE")
                      .replace(/^TEAM_EXPIRE/g, "TEAM_EXPIRE_DATE_CHANGE")
                      .split("_")
                      .map(s => s.charAt(0) + s.substr(1).toLowerCase())
                      .join(" ")}
                    valueType={et}
                    value={eventType[et]}
                    onChange={event =>
                      handleEventChange(event.target.checked, et)
                    }
                  />
                ))}
            </SwitchPanel>
            <br />
          </div>
        )}
    </FilterPanel>
  );
};

const Time = ({ time, onChange }) => {
  const handleChange = change => onChange({ ...time, ...change });
  return (
    <div>
      <CheckBox
        label="At any time of day or night."
        value={time.always}
        onChange={({ target: { checked } }) =>
          handleChange({
            always: checked
          })
        }
      />
      <CheckBox
        label="Between"
        value={!time.always}
        onChange={({ target: { checked } }) =>
          handleChange({
            always: !checked
          })
        }
      />
      <TimeSelector
        time={time.from}
        disabled={time.always}
        onChange={date =>
          handleChange({
            from: date.getUTCHours() * 60 + date.getUTCMinutes()
          })
        }
      />
      <span>and</span>
      <TimeSelector
        time={time.to}
        disabled={time.always}
        onChange={date =>
          handleChange({
            to: date.getUTCHours() * 60 + date.getUTCMinutes()
          })
        }
      />
    </div>
  );
};

const CheckBox = ({ large, label, value, onChange, expand }) => (
  <SwitchWrapper large={large} enabled={value} expand={expand}>
    <Switch large={large} checked={value} label={label} onChange={onChange} />
  </SwitchWrapper>
);

const TimeSelector = ({ type, time, disabled, onChange }) => (
  <TimePicker
    disabled={disabled}
    value={new Date(time * 60 * 1000)}
    onChange={onChange}
  />
);

const Box = ({ children }) => <div className={styles.box}>{children}</div>;
const Spacer = ({ children }) => (
  <div className={styles["spacer"]}>{children}</div>
);
const ChannelSection = ({ children }) => (
  <div className={styles["channel-section"]}>{children}</div>
);
const SectionHeader = ({ children }) => (
  <div className={styles["section-header"]}>{children}</div>
);
const PanelHeader = ({ children }) => (
  <div className={styles["panel-header"]}>{children}</div>
);
const SwitchWrapper = ({ large, enabled, expand, children }) => (
  <div
    onClick={() => expand && expand()}
    className={classNames(
      styles["switch-wrapper"],
      enabled && styles.enabled,
      expand && styles.expand,
      large && styles.large
    )}
  >
    {children}
  </div>
);
const TimePanel = ({ children }) => (
  <div className={styles["time-panel"]}>{children}</div>
);
const SwitchPanel = ({ children }) => (
  <div className={styles["switch-panel"]}>{children}</div>
);
const MethodPanel = ({ children, inputError }) => (
  <div
    className={classNames(
      styles["method-panel"],
      inputError && styles["input-error"]
    )}
  >
    {children}
  </div>
);
const FilterPanel = ({ children, border }) => (
  <div className={styles["filter-panel"]}>{children}</div>
);

const sortedKeys = obj => (obj ? keys(obj).sort() : []);

const setAllEventTypes = (enabled, channelFilter) => {
  const { eventType, channelType, time } = channelFilter;
  const et = keys(eventType).reduce(
    (acc, et) => ((acc[et] = enabled), acc),
    {}
  );
  return {
    channelType,
    time,
    eventType: et
  };
};

const IS_IMPLEMENTED = x =>
  ![
    LOG_MESSAGE_DELETE,
    LOG_MESSAGE_EDIT,
    LOG_MESSAGE_READ,
    LOG_WORKSPACE_DELETE_LOGS,
    LOG_WORKSPACE_EDIT_LOGS,
    LOG_WORKSPACE_EXPORT_LOGS,
    LOG_WORKSPACE_EXPORT_REPORTS,
    LOG_WORKSPACE_LIST_TEAMS,
    LOG_WORKSPACE_ROLE_ADD
  ].includes(x);

const isEmailAddress = x => /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/i.test(x);

const UnblockDialog = ({ showUnblockDialog, setShowUnblockDialog }) => {
  return (
    <Dialog
      icon="notification"
      isOpen={showUnblockDialog}
      title=""
      onClose={() => setShowUnblockDialog(false)}
      className={styles.unblock}
    >
      <div className="bp3-dialog-body">
        {(browser.name == "edge" && <img src={unblock.EDGE} />) ||
          (browser.name == "firefox" && <img src={unblock.FIREFOX} />) ||
          (browser.name == "safari" && <img src={unblock.SAFARI} />) || (
            <img src={unblock.CHROME} />
          )}
      </div>
    </Dialog>
  );
};
