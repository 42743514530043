import React, { Dispatch, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useSelector, useDispatch } from "react-redux";
import { Callout, H5, Intent, Spinner } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Login.module.css";
import {
  cancel,
  selectProgress,
  selectProvider,
  cancelKeyper,
  selectStatus,
  setView,
  setStatus,
  setProgress,
  selectHub,
  selectChallenge,
  selectAbort,
  setChallenge,
} from "./loginSlice";
import { BankID, Cancel } from "./icons";
import { selectIsMobile } from "../layout/layoutSlice.js";
import { QRCode } from "./qrCode";

interface CancelProps {
  dispatch: Dispatch<unknown>;
  progress?: string;
  hub?: boolean;
}

export default ({ }) => {
  const dispatch = useDispatch();
  const provider = useSelector(selectProvider);
  const progress = useSelector(selectProgress);
  const hub = useSelector(selectHub);

  useEffect(() => {
    const handleUnload = async () => {
      await dispatch(cancelKeyper());
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const isActive = provider === "bankid" || provider === "placeholder";
  const isProgress = ["WAITING", "FINISHED"].includes(progress);

  const isWaiting =
    ["bankid", "placeholder"].includes(provider) &&
    ["WAITING", "FINISHED", "ERROR"].includes(progress);

  return (
    <div
      className={classNames(
        styles.method,
        styles.bankid,
        isActive && styles.active,
        isProgress && styles.tall
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.dialog}>
        <div className={styles.header}>
          <BankID size={34} />
          <div className={styles.name}>BankID</div>
          <Cancel
            size={34}
            onClick={() => handleCancel({ dispatch, progress, hub })}
          />
        </div>
        <div className={styles.middle}>
          <div className={styles.columns}>
            <div className={styles.column}>
              {isWaiting ? (
                <Waiting />
              ) : (
                <Callout intent={Intent.WARNING}>
                  <H5>Could not launch BankID</H5>
                </Callout>
              )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <CancelBankID dispatch={dispatch} progress={progress} hub={hub} />
        </div>
      </div>
    </div>
  );
};

const Waiting: React.FC = () => {
  const status = useSelector(selectStatus);
  //const isMobile = useSelector(selectIsMobile);
  const abort = useSelector(selectAbort);

  const isMobile =
    /Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  let content;
  if (abort) {
    content = (
      <Callout intent={Intent.DANGER}>
        <H5>This BankID is already in use</H5>
      </Callout>
    );
  } else if (status !== "failed") {
    content = isMobile ? <Mobile /> : <Desktop />;
  } else {
    content = (
      <Callout intent={Intent.DANGER}>
        <H5>Authentication failed, please try again</H5>
      </Callout>
    );
  }
  return content;
};

const Desktop: React.FC = () => {
  const challenge = useSelector(selectChallenge);
  const [qrCode] = challenge.split("|");

  return qrCode ? <QRCode qrCode={qrCode} /> : <Spinner />;
};

const Mobile: React.FC = () => {
  const dispatch = useDispatch();
  const challenge = useSelector(selectChallenge);
  const progress = useSelector(selectProgress);
  const [, autoStartToken] = challenge.split("|");
  const [url, setUrl] = useState("");

  const isError = progress === "ERROR";

  useEffect(() => {
    if (autoStartToken) {
      try {
        const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
        setUrl(url);
      } catch (error) {
        Sentry.captureException(error);
        dispatch(setProgress("ERROR"));
        console.error("Error redirecting to BankID:", error);
      }
    }
  }, [autoStartToken]);

  return (
    <div className={styles.mobileAuth}>
      {url && url !== "" && autoStartToken ? (
        <a
          className={classNames(styles.bankidBtn, styles.mobile)}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          <BankID size={22} />
          <span className={isError ? styles.retry : ""}>
            {isError ? "Error opening BankID" : "Open BankID"}
          </span>
        </a>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const CancelBankID: React.FC<CancelProps> = ({ dispatch, progress, hub }) => {
  return progress !== "FINISHED" ? (
    <div
      onClick={() => handleCancel({ dispatch, hub })}
      className={styles.button}
    >
      <span>Cancel login</span>
    </div>
  ) : null;
};

const handleCancel = async ({ dispatch, hub }: CancelProps) => {
  const resetViewToEmail = async () => {
    await dispatch(cancel());
  };

  const resetViewToHub = () => {
    dispatch(setStatus(""));
    dispatch(setProgress(""));
    dispatch(setView("HUB"));
  };

  const resetKeyper = async () => {
    await dispatch(cancelKeyper());
    dispatch(setChallenge(""));
    dispatch(setStatus(""));
    dispatch(setProgress(""));
    if (hub) {
      resetViewToHub();
    } else {
      await resetViewToEmail();
    }
  };

  await resetKeyper();
};
