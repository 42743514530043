import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { dispatchToast } from "../toasts/Toasts.js";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import createApiClient from "api-client";
import { API_URL_PREFIX } from "../../config";

import styles from "./index.module.css";

//const initData = {
//  zones: [
//    { name: "Some Team Name" },
//    { name: "Some Other Name" },
//    { name: "Some Other Team" },
//    { name: "The Fourth Team" },
//  ]
//}

export default ({}) => {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const [data, setData] = useState([]);
  const [item, setItem] = useState(null);

  const doIt = async () => {
    const token = await dispatch(getToken());
    const api = createApiClient(API_URL_PREFIX);

    try {
      const response = await fetch(API_URL_PREFIX + "/dtix/get", {
        method: "get",
        mode: "cors",
        headers: {
          "user-id": identity.id,
          authorization: `Bearer ${token}`,
        },
      });
      return await response.json();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await doIt();
      if (response?.result) {
        setData(response?.result);
      }
    })();
  }, []);

  return (
    <div className={styles.outer}>
      <div className={styles.list}>
        {data.map((item) => (
          <div className={styles.zone}>
            <div className={styles.left} onClick={() => setItem(item)}>
              <Icon className={styles.type} icon="tag" />
              <div className={styles.name}>{item.team.name}</div>
              <Icon className={styles.arrow} icon="chevron-down" />
            </div>
            <div className={styles.right}>
              <div className={styles.done}>Up to date</div>
              <Icon className={styles.check} icon="small-tick" />
            </div>
          </div>
        ))}
      </div>
      {item && <div className={styles.item}></div>}
    </div>
  );
};
