import { Dialog, Icon } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { classNames } from "utils";
import { setBullhornActive, setDialog } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { ZONE } from "../../app/dictionary.js";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import { AddZone, Users } from "../login/icons.tsx";
import { BH_CREATE_ZONE } from "./BhDialogs.js";
import styles from "./BhDialogs.module.css";
import bullhornLogo from "./bullhorn.png";
import {
  postZoneToClientCorporation,
  selectCorporationId,
  selectJobOrderId,
  selectRelatedZones,
  selectZoneName,
  selectContactRole,
  setZoneId,
  displayZone,
  setSyncFilesOnly,
} from "./bullhornSlice.js";
import { TEAM_CREATE } from "../../app/permissions.js";

const {
  selectors: { selectPermissions },
  actions: { remote },
  server: { bumpZone },
} = appState;

const DEFAULT_SORT = "default";
const HEADER_FIELDS = [
  ["name", "Name"],
  ["owner", "Owner"],
];

export default function SelectZone({ dispatch, active }) {
  const permissions = useSelector(selectPermissions);
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(true);
  const [sortOn, _setSortOn] = useState(DEFAULT_SORT);
  const [reverseSort, _setReverseSort] = useState(false);
  const identity = useSelector(selectIdentity);
  const jobOrderId = useSelector(selectJobOrderId);
  const relatedZones = useSelector(selectRelatedZones);
  const zoneName = useSelector(selectZoneName);
  const clientCorporationId = useSelector(selectCorporationId);
  const contactRole = useSelector(selectContactRole);
  const ORDER_OF_SORTS = {
    name: (a, b) => a.name.localeCompare(b.name),
    owner: (a, b) => a.owner.name.localeCompare(b.owner.name),
    default: (a, b) => b.isMember - a.isMember || a.name.localeCompare(b.name),
  };

  const [sortedRelatedZones, setSortedRelatedZones] = useState(
    [...relatedZones].sort(
      (a, b) => ORDER_OF_SORTS[sortOn](a, b) * (reverseSort ? -1 : 1)
    )
  );

  useEffect(() => {
    setDisableCreateNewTeamButton(!permissions[TEAM_CREATE]);
  }, [permissions]);

  const setSortOn = (sortIndex) => {
    let _sortOn = sortOn;
    let _reverseSort = reverseSort;
    if (sortIndex !== sortOn) {
      _reverseSort = false;
      _sortOn = sortIndex;
      _setReverseSort(false);
      _setSortOn(sortIndex);
    } else if (!reverseSort) {
      _setReverseSort(true);
      _reverseSort = true;
    } else {
      _sortOn = DEFAULT_SORT;
      _reverseSort = false;
      _setSortOn(DEFAULT_SORT);
      _setReverseSort(false);
    }
    setSortedRelatedZones(
      sortedRelatedZones.sort(
        (a, b) => ORDER_OF_SORTS[_sortOn](a, b) * (_reverseSort ? -1 : 1)
      )
    );
  };

  if (!identity || !contactRole) {
    return null;
  }

  const zoneText = ZONE[0].toUpperCase() + ZONE.slice(1);

  const renderZones = () => (
    <div className={styles.scrollable}>
      {sortedRelatedZones.map((zone) => (
        <Zone key={zone.id} {...zone} />
      ))}
    </div>
  );

  const Zone = ({ id, name, isRestricted, willExpire, isMember, owner }) => {
    const initials = name.replace(/[^\p{N}\p{L}\p{M}]/gu, "").slice(0, 2);

    const selectZone = async () => {
      if (!isMember) {
        alert("You are not a member of this team!");
        return;
      }
      if (jobOrderId) {
        const accessToken = await dispatch(getToken());
        postZoneToClientCorporation(
          clientCorporationId,
          id,
          accessToken,
          jobOrderId
        );
      }
      dispatch(setSyncFilesOnly(true));
      setDisableCreateNewTeamButton(true);
      dispatch(displayZone(id));
      dispatch(remote(bumpZone({ id })));
      dispatch(setZoneId(id));
      dispatch(setDialog());
    };

    return (
      <div
        // ref={ref}
        className={classNames(
          styles.margin,
          isMember ? styles.zone : styles.disabledZone,
          styles.selectZoneColumn
        )}
        // onContextMenu={selectZone}
        onClick={selectZone}
        disabled={true}
      >
        <div className={styles.avatar}>{initials}</div>
        <div className={styles.selectZoneNameColumn}>{name}</div>
        {owner.role === contactRole ? (
          <div title={"NO OWNER"} className={styles.selectZoneOwnerColumn}>
            NO OWNER
          </div>
        ) : (
          <div title={owner.email} className={styles.selectZoneOwnerColumn}>
            {owner.name}
          </div>
        )}
        <div className={styles.icons}>
          {isRestricted && (
            <span>
              <Users className={styles.users} />
            </span>
          )}
          {willExpire && <Icon icon="time" />}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      isOpen={active}
      onClose={(e) => dispatch(setBullhornActive(false))}
      isCloseButtonShown={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      title={
        <div className={styles.title}>
          <AddZone size={34} />
          <span className={styles.dialogTitle}>
            Select a related {zoneText}
          </span>
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <div className={styles.bar}>
          {HEADER_FIELDS.map(([sort, colName]) => (
            <div
              key={sort}
              onClick={(e) => setSortOn(sort)}
              className={styles.selectZoneHeader}
            >
              <span>{colName}</span>{" "}
              {sortOn === sort && (
                <Icon
                  className={classNames(reverseSort && styles.upsideDown)}
                  icon="caret-down"
                />
              )}
            </div>
          ))}
        </div>
        {renderZones()}
        <div className={styles.dialogBottomRow}>
          <img src={bullhornLogo} className={styles.img}></img>
          <button
            className={styles.dialogButton}
            onClick={(e) =>
              dispatch(setDialog({ dialog: BH_CREATE_ZONE, zoneName }))
            }
            disabled={disableCreateNewTeamButton}
          >
            Create new Team
          </button>
        </div>
      </div>
    </Dialog>
  );
}
