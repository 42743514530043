import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Intent } from "@blueprintjs/core";
import { values } from "utils";
import { getUsersFromGroup } from "app-state/src/derive.js";
import { useRoles } from "../../app/rolesContext.js";
import { appState } from "../../app/appState.js";
import { unWrapNonSerializableValue } from "../../helpers/nonSerializableValues/index.js";
import { selectIdentity } from "../../app/topSlice.js";
import { dispatchToast } from "../toasts/Toasts";
import { formatUser } from "../format/format.js";
import {
  createSignatureRequest,
  createSignatureRequestExpress,
} from "./esignatureSlice.js";
import {
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,
} from "../../app/permissions.js";
import EsignatureIcon from "../esignature/esignatureIcon.js";
import { Wizard } from "../wizard/Wizard.js";

const {
  selectors: { selectGraph },
} = appState;

const service = "signature";

export default ({ blob, node, active, close }) => {
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [signingOrder, setSigningOrder] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [expire, setExpire] = useState(null);

  const graph = useSelector(selectGraph);
  const identity = useSelector(selectIdentity);
  const roles = useRoles();

  const { nodes, users } = graph;

  if (!identity) {
    return null;
  }

  if (active && (!node || !(node.id in nodes))) {
    console.log("node was not in nodes!");
    return null;
  }

  const group = node?.group;

  const { id: userId, org } = identity;

  const groupUsers = group && getUsersFromGroup(graph, group);

  if (
    active &&
    (!groupUsers || !groupUsers.some((user) => user.user === userId))
  ) {
    console.log("user is not member of node group!");
    return null;
  }

  const onClose = () => {
    setSelectedUsers([]);
    setSigningOrder(false);
    setIsDisabled(false);
    close();
  };

  const filteredUsers = groupUsers?.filter((user) => {
    const actions = roles?.find((role) => user.role == role.id)?.actions;
    return (
      (user.email && actions?.[ESIGNATURE_APPROVE]) ||
      actions?.[ESIGNATURE_REJECT]
    );
  });

  const selectableUsers = filteredUsers?.map((user) => {
    const [[primary, secondary], detailed] = dispatch(formatUser(user));
    return {
      id: user.user || user.id,
      text: [[primary, secondary], detailed],
      email: user.email,
      wellknown: user.wellknown[0],
      companyName: "",
      orgNo: "",
    };
  });

  const user = {
    ...values(users).find((user) => user.user == userId),
    ...users[userId],
  };

  const onSubmit = async () => {
    try {
      setIsDisabled(true);

      const myBlob = unWrapNonSerializableValue(blob);

      const [[primary, secondary], detailed] = dispatch(formatUser(user));

      let myUser = {
        id: user.user || user.id,
        text: [[primary, secondary], detailed],
        email: user.email,
        wellknown: user.wellknown,
      };

      // TODO prompt name in wizard
      myUser = {
        ...myUser,
        name: myUser.text[0][0],
      };

      dispatch(
        createSignatureRequest(
          myBlob,
          node,
          myUser,
          selectedUsers,
          signingOrder,
          org,
          expire
        )
      );

      dispatchToast(dispatch, {
        message: `Signature request sent!`,
        icon: "CHECK",
        intent: Intent.SUCCESS,
        timeout: 2000,
      });
    } catch (error) {
      dispatchToast(dispatch, {
        message: `Please, try again.`,
        icon: "error",
        intent: Intent.DANGER,
        timeout: 5000,
      });
    } finally {
      onClose();
    }
  };

  const props = {
    selectedUsers,
    setSelectedUsers,
    signingOrder,
    setSigningOrder,
    expire,
    setExpire,
    selectableUsers,
    onSubmit,
    close,
    service,
    SharedIcon: <EsignatureIcon width={20} height={20} />,
  };

  return <Wizard {...props} />;
};
