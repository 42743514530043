import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";

import { classNames } from "utils";

import { setOverlay } from "../../app/appSlice.js";
import { ZONE } from "../../app/dictionary.js";
import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays.js";

import { registerComponent } from "../ditto/Ditto.js";

import {
  esignaturesFetch,
  getEsignatureState,
} from "../esignature/esignatureSlice.js";

import styles from "./EsignatureTable.module.css";

export const COMPONENT_ESIGNATURE_TABLE = "COMPONENT_ESIGNATURE_TABLE";

export const ARG_DATE = [
  "sv-SE",
  {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
];

const MAX_PARTIES = 10;

export const EsignatureTable = ({ all }) => {
  const dispatch = useDispatch();
  const [esignatures, setEsignatures] = useState(null);

  useEffect(() => {
    (async () => {
      setEsignatures(await dispatch(esignaturesFetch({ all })));
    })();
  }, []);

  if (!esignatures) {
    return null;
  }

  const onClick = async (esignature) => {
    dispatch(
      setOverlay({
        overlay: OVERLAY_PREVIEW,
        nodeId: esignature.node.id,
        zoneId: esignature.zone.id,
      })
    );
  };

  let maxNbrOfParties = Math.min(
    MAX_PARTIES,
    Math.max(...esignatures.map((x) => x.signicatUrl.length))
  );

  const widthParties = maxNbrOfParties * 29 + 24 + "px";

  const rows = esignatures
    .slice()
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((esignature) => {
      let { id, timestamp, deadline } = esignature;
      const created = new Date(timestamp).toLocaleDateString(...ARG_DATE);
      deadline = new Date(deadline).toLocaleDateString(...ARG_DATE);
      const esignatureState = dispatch(getEsignatureState(esignature));
      const { stateText, color, icon, approvals, rejections, iAmRequester } =
        esignatureState;
      let parties = esignature.signicatUrl.map((data) => {
        const approved = approvals.includes(data.externalSignerId);
        const rejected = rejections.includes(data.externalSignerId);
        return (
          <div
            key={data.id}
            className={classNames(
              styles.signer,
              approved && styles.approved,
              rejected && styles.rejected
            )}
          >
            {data.signerInfo.lastName.slice(0, 2)}
          </div>
        );
      });

      let offset = 0;
      if (parties.length > maxNbrOfParties) {
        let more = (
          <div className={classNames(styles.signer, styles.more)}>...</div>
        );
        parties = [...parties.slice(offset, maxNbrOfParties - 1), more];
      }
      return (
        <div
          key={id}
          className={styles.row}
          onClick={() => onClick(esignature)}
        >
          <div
            className={classNames(styles.cell, styles.icon)}
            style={{ color }}
          >
            <div className={styles.item}>
              <Icon icon={icon} />
            </div>
          </div>
          <div className={classNames(styles.cell, styles.node)}>
            <div className={styles.item}>{esignature.node.name}</div>
          </div>
          <div className={classNames(styles.cell, styles.zone)}>
            <div className={styles.item}>{esignature.zone.name}</div>
          </div>
          <div className={classNames(styles.cell, styles.zone)}>
            <div className={styles.item}>{iAmRequester}</div>
          </div>
          <div
            className={classNames(styles.cell, styles.parties)}
            style={{ flexBasis: widthParties }}
          >
            <div className={classNames(styles.item, styles.signers)}>
              {parties}
            </div>
          </div>
          <div
            className={classNames(styles.cell, styles.status)}
            style={{ color }}
          >
            <div className={styles.item}>{stateText}</div>
          </div>
          <div className={classNames(styles.cell, styles.date)}>
            <div className={styles.item}>{created}</div>
          </div>
          <div className={classNames(styles.cell, styles.date)}>
            <div className={styles.item}>{deadline}</div>
          </div>
        </div>
      );
    });

  return (
    <div className={styles.outer}>
      <div className={styles.table}>
        <div className={styles.headers}>
          <div className={classNames(styles.header, styles.icon)}></div>
          <div className={classNames(styles.header, styles.node)}>Document</div>
          <div className={classNames(styles.header, styles.zone)}>{ZONE}</div>
          <div className={classNames(styles.header, styles.zone)}>
            Requester
          </div>
          <div
            className={classNames(styles.header, styles.parties)}
            style={{ flexBasis: widthParties }}
          >
            Parties
          </div>
          <div className={classNames(styles.header, styles.status)}>Status</div>
          <div className={classNames(styles.header, styles.created)}>
            Created
          </div>
          <div className={classNames(styles.header, styles.deadline)}>
            Deadline
          </div>
        </div>
        {rows}
      </div>
    </div>
  );
};

registerComponent(COMPONENT_ESIGNATURE_TABLE, EsignatureTable);
