import "./Doc.css";

const loadScript = (src, callback) => {
  let s = document.getElementById(src);

  if (s) {
    if (s.dataset.loaded) {
      callback();
    } else {
      s.onload = () => {
        s.dataset.loaded = true;
        callback();
      };
    }
  } else {
    s = document.createElement("script");
    s.type = "text/javascript";
    s.src = `/${src}`;
    s.id = src;
    s.onload = () => {
      s.dataset.loaded = true;
      callback();
    };
    document.body.appendChild(s);
  }
};
export const createBackend = (el, bytes, mime) => {
  let theInstance;

  const create = async () => {
    //const jq = "jquery.1.12.3.min.js";
    const jq = "jquery-3.6.0.min.js";
    //const src = "DocxJS.bundle.js";
    const src = "DocxJS.bundle.min.js";

    let resolve,
      promise = new Promise((r) => (resolve = r));

    loadScript(jq, () => {
      loadScript(src, () => {
        resolve(window.DocxJS);
      });
    });

    const DocxJS = await promise;

    if (typeof DocxJS === "undefined") {
      throw new Error("failed to load doc viewer");
    }
    const instance = new DocxJS();

    let div = document.createElement("div");

    el.innerHTML = "";
    el.appendChild(div);

    let blob = new Blob([bytes], { type: mime });

    instance.parse(
      blob,
      () =>
        instance.render(
          div,
          () => {
            theInstance = instance;
          },
          3
        ),
      (e) => console.error(e)
    );
  };

  let destroyed = false;

  const destroy = () => {
    if (!destroyed) {
      destroyed = true;
      el.innerHTML = "";
    }
  };

  let currentValue = 1;

  const zoomOut = () => {
    let intervalValue = 0.25;
    if (currentValue > 3) {
      intervalValue = 1;
    } else if (currentValue > 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.max(0.5, currentValue - intervalValue);
    theInstance.setZoom(currentValue);
  };

  const zoomIn = () => {
    let intervalValue = 0.25;
    if (currentValue >= 3) {
      intervalValue = 1;
    } else if (currentValue >= 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.min(3, currentValue + intervalValue);
    theInstance.setZoom(currentValue);
  };

  const navPrev = () => {
    console.log("prev!");
  };

  const navNext = () => {
    console.log("next!");
  };

  const navLast = () => {
    console.log("next!");
  };

  return {
    promise: create(),
    destroy,
    zoomOut,
    zoomIn,
    navPrev,
    navNext,
    navLast,
  };
};
