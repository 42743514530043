export const ARG_DATE = [
  "en-SE",
  {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  },
];

export const ARG_DATE_CALENDER = [
  "sv-SE",
  {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
];

export const HOURS_AT_MIDNIGHT = 23;
export const MINUTES_AT_MIDNIGHT = 59;
export const SECONDS_AT_MIDNIGHT = 59;
export const MILLISECONDS_AT_MIDNIGHT = 0;

export const initializeDefaultTimestamp = (isCalenderDisplay = false) => {
  let defaultTimestamp = new Date();
  defaultTimestamp.setHours(
    HOURS_AT_MIDNIGHT,
    MINUTES_AT_MIDNIGHT,
    SECONDS_AT_MIDNIGHT,
    MILLISECONDS_AT_MIDNIGHT
  );
  defaultTimestamp.setDate(defaultTimestamp.getDate() + 30);
  if (isCalenderDisplay) {
    return defaultTimestamp.toLocaleDateString(...ARG_DATE);
  } else {
    return new Date(defaultTimestamp);
  }
};

export const handleTimestamp = (timestamp) => {
  const fullDate = new Date(timestamp);
  const dateTime = fullDate.toLocaleDateString(...ARG_DATE_CALENDER);
  return dateTime;
};

export const handleMinDate = () => {
  const today = new Date();
  const firstDay = today.setDate(today.getDate() + 1);
  const minDate = new Date(firstDay);
  return minDate;
};

export const handleMaxDate = () => {
  const today = new Date();
  const expiryDate = today.setDate(today.getDate() + 30);
  const maxDate = new Date(expiryDate);
  return maxDate;
};

//export const handleDateToInteger = (date) => {
//  if (date === null) {
//    return 30;
//  } else {
//    let currentDate = new Date();
//    currentDate.setHours(
//      HOURS_AT_MIDNIGHT,
//      MINUTES_AT_MIDNIGHT,
//      SECONDS_AT_MIDNIGHT,
//      MILLISECONDS_AT_MIDNIGHT
//    );
//    const selectedDate = new Date(date);
//    const timeDiff = selectedDate - currentDate;
//    const daysDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24));
//    return daysDiff;
//  }
//};
//
//export const handleFormatExpirationDate = (date) => {
//  const expireDate = date === null ? initializeDefaultTimestamp() : date;
//  return expireDate.toISOString();
//};

export const isSwedishOrganizationNumber = (orgNo) => {
  if (orgNo === "") {
    return true;
  }

  let matches = orgNo.match(/^(\d{1})(\d{5})\-(\d{4})$/);

  if (!matches) {
    return false;
  }

  let group = matches[1];
  let controldigits = matches[3];
  let alldigits = group + matches[2] + controldigits;

  if (parseInt(alldigits.substring(2, 3)) < 2) {
    return false;
  }

  let nn = "";

  for (var n = 0; n < alldigits.length; n++) {
    nn += (((n + 1) % 2) + 1) * alldigits.substring(n, n + 1);
  }

  let checksum = 0;

  for (let n = 0; n < nn.length; n++) {
    checksum += nn.substring(n, n + 1) * 1;
  }

  return checksum % 10 === 0;

  //HANDELSBOLAG - OK
  //AKTIEBOLAG - OK
  //BRF - OK
  //TROSSAMFUND - OK
  //MYNDIGHETER - OK
  //ENSKILD FIRMA - NO
};

export const validateSelectedUsers = (selectedUsers) => {
  return selectedUsers.every(
    (user) =>
      user.name !== "" &&
      (user.orgNo === "" || isSwedishOrganizationNumber(user.orgNo))
  );
};

export const combineClassNames = (...args) => {
  let classes = [];

  args.forEach((arg) => {
    if (typeof arg === "string") {
      classes.push(arg);
    } else if (typeof arg === "object") {
      Object.entries(arg).forEach(([className, condition]) => {
        if (condition) {
          classes.push(className);
        }
      });
    }
  });

  return classes.join(" ");
};
