import React, { useState } from "react";

import { Icon } from "@blueprintjs/core";

import styles from "./Splash.module.css";

export default function({ noAccess }) {
  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <span className={styles.header}>Chat</span>
      </div>
      <div className={styles.rest}>
        {noAccess && (
          <div className={styles.empty}>
            <div>
              <Icon iconSize={44} icon="eye-off" />
            </div>
            <div>
              <div>Nothing To See Here</div>
              <div>You've got no access to selected resources</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
