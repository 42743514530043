import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import createApiClient from "api-client";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import { getToken, selectSpace } from "../../app/topSlice.js";
import * as config from "../../config";
import styles from "./index.module.css";

const validateEmail = (value) =>
  /^[a-z0-9_.+-]+@([a-z0-9-]+\.)+[a-z0-9-]+$/i.test(value) &&
  value.length <= 200;

export const useIdProviders = () => {
  const [idProviders, setIdProviders] = useState<{ name: string, id: string }[]>([]);
  const orgId = useSelector(selectSpace);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (!orgId) return;
      const token = await dispatch(getToken());
      const api = createApiClient(config.API_URL_PREFIX);
      const { idProviders } = await api.token(token).orgs(orgId).get();
      setIdProviders(idProviders);
    })();
  }, [orgId, dispatch]);
  return idProviders;
};

export default function SSO({ state, setState, submit, idProviders }) {
  const {
    moreOptions,
    notifyAddress = "",
    wellknown: [idProvider = "", email = ""] = [],
  } = state;

  const { showErrors, emailOk, idProviderOk, notifyOk } = state;

  return (
    <>
      <div className={styles.br} style={{ height: "30px" }} />
      <div
        className={classNames(
          "select",
          styles.select,
          showErrors && !idProviderOk && styles.error
        )}
      >
        <select
          onChange={(e) => {
            const idProvider = e.target.value;
            setState({
              idProvider,
              idProviderOk: true,
              wellknown: [idProvider, email],
            });
          }}
        >
          <option hidden>Select ID provider</option>
          {idProviders.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.br} style={{ height: "20px" }} />
      <input
        type="text"
        placeholder="Email Address (alice@example.com)"
        className={classNames(
          styles.input,
          showErrors && !emailOk && styles.error
        )}
        value={email}
        onChange={(e) => {
          const email = e.target.value;
          const emailOk = validateEmail(email);
          setState({ wellknown: [idProvider, email], emailOk });
        }}
        onKeyDown={(e) => e.key === "Enter" && submit()}
      />
      <div className={styles.sso}>
        <span>The email is used for SSO verification with Konfident.</span>
      </div>
      <div className={styles.br} style={{ height: "20px" }} />
      <div
        onClick={() => setState({ moreOptions: !moreOptions })}
        className={styles.moreOptions}
      >
        <Icon iconSize={14} icon="form" />
        More Options
      </div>
      {moreOptions && (
        <>
          <div className={styles.br} style={{ height: "30px" }} />
          <div className={styles.label}>
            Use a different email address for notifications:
          </div>
          <input
            type="text"
            placeholder="Notification Email Address (alice@example.com)"
            className={classNames(
              styles.input,
              showErrors && !notifyOk && styles.error
            )}
            value={notifyAddress}
            onChange={(e) => {
              const email = e.target.value;
              const notifyOk = validateEmail(email);
              setState({ notifyAddress: email, notifyOk });
            }}
            onKeyDown={(e) => e.key === "Enter" && submit()}
          />
        </>
      )}
    </>
  );
}
