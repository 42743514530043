import parsePhoneNumber from "libphonenumber-js";

import { selectLegalNames } from "../../app/appSlice.js";

const parseUri = uri => {
  let [, , space, provider, identity] =
    /^(([^;]*);)?(.+):(.+)$/.exec(uri.toLowerCase()) || [];
  identity = (identity || "").split("|").filter(str => str.length > 0);
  return {
    space: space || "",
    provider: provider || "",
    identity
  };
};

export const formatPhoneNumberNational = phoneNumber => {
  try {
    return parsePhoneNumber(phoneNumber).formatNational();
  } catch (e) {}
  return phoneNumber;
};

export const formatPhoneNumberInternational = phoneNumber => {
  try {
    return parsePhoneNumber(phoneNumber).formatInternational();
  } catch (e) {}
  return phoneNumber;
};

export const formatEmailAddress = emailAddress => {
  try {
    const [name, domain] = emailAddress.split("@");
    const [company] = domain.split(".").slice(-2, -1);
    const names = name.match(/[\p{N}\p{L}\p{M}]+/gu);
    return `${names.join(" ")} (${company})`;
  } catch (e) {
    return emailAddress;
  }
};

export const formatSocialSecurityNumber = ssn => {
  return `XX XX ${ssn.slice(4, 8)} - XXX ${ssn.slice(-1)}`;
};

//export const selectLegalName = ssn => (dispatch, getState) => {
//  return selectLegalNames(getState())[ssn] || formatSocialSecurityNumber(ssn);
//};

export const formatUser = user => dispatch => {
  return formatUri(user.deprecatedId, user.name, user.email);
};

export const formatUri = (uri, name = "", email = "") => {
  let { space, provider, identity } = parseUri(uri);
  let [primary, secondary] = identity;
  switch (provider) {
    case "bankid":
      let ssn = formatSocialSecurityNumber(primary);
      let legalName = name || ssn;
      return [[legalName], `${ssn} (${provider})`, ssn, provider];
    case "email":
      let detailed = `${primary} (${provider})`;
      return [[formatEmailAddress(primary)], detailed, primary, provider];
    case "2fa":
      secondary = formatPhoneNumberInternational(secondary);
      let twoFA = `${primary} ${secondary}`;
      primary = formatEmailAddress(primary);
      secondary = formatPhoneNumberNational(secondary);
      return [[primary, secondary], `${twoFA} (${provider})`, twoFA, provider];
    case "placeholder":
      let placeholder = formatEmailAddress(/*email ||*/ primary);
      //return [[placeholder], `${email} (bankid)`, email, "bankid"];
      return [[placeholder], `${primary} (bankid)`, primary, "bankid"];
    default:
      let id = identity.join(" ");
      return [[id], `${id} (${provider})`, id, provider];
  }
};
