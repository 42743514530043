import { Icon, Switch } from "@blueprintjs/core";
import { escapeRegExp } from "app-state/src/queries.js";
import { useState } from "react";
import { classNames } from "utils";
import { useRoles } from "../../app/rolesContext";
import KeyperProvider from "../../helpers/wrappers/KeyVaultProvider";
import { formatUser } from "../format/format.js";
import styles from "./BhDialogs.module.css";
import BhTableTop from "./BhTableTop.js";
import bullhornLogo from "./bullhorn.png";

const DEFAULT_SORT = "default";
const HEADER_FIELDS = [
  ["name", "Users"],
  ["id", "Id"],
  ["role", "Role"],
  ["check", "Selected"],
];

export default function SyncMembers({
  dispatch,
  sync,
  disableCreateNewTeamButton,
  users,
  contentStatus,
  buttonText,
  defaultAllSelected,
  alertUserError = () => {},
}) {
  let roles = useRoles();
  roles = roles.reduce((roles, role) => ({ ...roles, [role.id]: role }));
  const [usersToCheckedMap, setUsersToCheckedMap] = useState(
    users.reduce(
      (obj, user) => (
        (obj[user.user] =
          (user.valid2fa || !user.isContact) &&
          (defaultAllSelected || user.isJobOrderContact)),
        obj
      ),
      {}
    )
  );
  const [nrChecked, setNrChecked] = useState(
    users.filter(
      (user) =>
        (user.valid2fa || !user.isContact) &&
        (defaultAllSelected || user.isJobOrderContact)
    ).length
  );
  const [sortOn, _setSortOn] = useState(DEFAULT_SORT);
  const [reverseSort, _setReverseSort] = useState(false);

  const handleCheck = (user) => {
    const prev = usersToCheckedMap[user.user];
    setUsersToCheckedMap({
      ...usersToCheckedMap,
      [user.user]: !prev,
    });
    setNrChecked(nrChecked + (!prev ? 1 : -1));
  };

  const handleSelectAll = (isSelectAll) => {
    setUsersToCheckedMap(
      users.reduce(
        (obj, user) => (
          (obj[user.user] = (user.valid2fa || !user.isContact) && isSelectAll),
          obj
        ),
        {}
      )
    );
    setNrChecked(
      isSelectAll *
        users.filter((user) => user.valid2fa || !user.isContact).length
    );
  };

  const getCheckedUsers = () =>
    users.filter((user) => usersToCheckedMap[user.user]);

  const ORDER_OF_SORTS = {
    name: (a, b) => {
      const v = a.name.localeCompare(b.name);
      return v !== 0 ? v : a.index - b.index;
    },
    id: (a, b) => {
      const v = a.provider.localeCompare(b.provider);
      return v !== 0 ? v : a.index - b.index;
    },
    role: (a, b) => {
      const v = roles[a.role].privilegeOrder - roles[b.role].privilegeOrder;
      return v !== 0 ? v : a.index - b.index;
    },
    check: (a, b) => {
      const v = usersToCheckedMap[a.id] - usersToCheckedMap[b.id];
      return v !== 0 ? v : a.index - b.index;
    },
    index: (a, b) => {
      return a.index - b.index;
    },
    default: (a, b) => {
      const jobOrder = b.isJobOrderContact - a.isJobOrderContact;
      const name = jobOrder !== 0 ? jobOrder : a.name.localeCompare(b.name);
      return name !== 0 ? name : a.index - b.index;
    },
  };

  const [sortedUsers, setSortedUsers] = useState(
    users.sort((a, b) => ORDER_OF_SORTS[sortOn](a, b) * (reverseSort ? -1 : 1))
  );
  const [filteredUsers, setFilteredUsers] = useState(sortedUsers);

  const setSortOn = (sortIndex) => {
    if (sortIndex !== sortOn) {
      _setReverseSort(false);
      _setSortOn(sortIndex);
    } else if (!reverseSort) {
      _setReverseSort(true);
    } else {
      _setSortOn(DEFAULT_SORT);
      _setReverseSort(false);
    }
    setSortedUsers(
      sortedUsers.sort(
        (a, b) => ORDER_OF_SORTS[sortOn](a, b) * (reverseSort ? -1 : 1)
      )
    );
  };

  const handleSearchTerm = (searchTerm) => {
    setFilteredUsers(
      sortedUsers.filter((user) => {
        const re = new RegExp(escapeRegExp(searchTerm), "i");
        return re.test(user.name + user.email + user.mobile);
      })
    );
  };

  return (
    <div className={styles.dialogBody}>
      <BhTableTop
        handleSearchTerm={handleSearchTerm}
        handleSelectAll={handleSelectAll}
        defaultSelectAll={defaultAllSelected}
      />
      <div className={styles.bar}>
        {HEADER_FIELDS.map(([sort, colName]) => (
          <div
            key={sort}
            onClick={(e) => setSortOn(sort)}
            className={styles.header}
          >
            <span>{colName}</span>{" "}
            {sortOn === sort && (
              <Icon
                className={classNames(reverseSort && styles.upsideDown)}
                icon="caret-down"
              />
            )}
          </div>
        ))}
      </div>
      {contentStatus === "idle" ? (
        <UserList
          roles={roles}
          users={filteredUsers}
          dispatch={dispatch}
          handleChecked={handleCheck}
          usersToCheckedMap={usersToCheckedMap}
          alertUserError={alertUserError}
        />
      ) : (
        <span> Loading... </span>
      )}
      <div className={styles.dialogBottomRow}>
        <img src={bullhornLogo} className={styles.img}></img>
        <button
          className={styles.dialogButton}
          onClick={(e) => sync(getCheckedUsers(), !nrChecked)}
          disabled={disableCreateNewTeamButton}
        >
          {nrChecked ? `${buttonText} (${nrChecked})` : "Skip"}
        </button>
      </div>
    </div>
  );
}

const UserList = ({
  roles,
  users,
  dispatch,
  handleChecked,
  usersToCheckedMap,
  alertUserError,
}) => {
  return (
    <div className={styles.scrollable}>
      {users.map((user) => (
        <User
          key={user.wellknown.join(" ") + user.user}
          user={user}
          handleChecked={handleChecked}
          dispatch={dispatch}
          checked={usersToCheckedMap[user.user]}
          alertUserError={alertUserError}
          role={roles[user.role]}
        />
      ))}
    </div>
  );
};

const User = ({
  dispatch,
  user,
  handleChecked,
  checked,
  alertUserError,
  role,
}) => {
  const [[primary, secondary], detailed] = dispatch(formatUser(user));
  return (
    <div onClick={(e) => alertUserError(user)}>
      <div
        className={classNames(
          styles.row,
          user.isContact && !user.valid2fa && styles.error2fa
        )}
      >
        <div
          title={detailed}
          className={classNames(
            styles.column,
            styles.user,
            checked || styles.striked
          )}
        >
          <span>{user.name}</span>
        </div>
        <div
          className={classNames(
            styles.column,
            styles.provider,
            styles[`provider-${user.provider}`]
          )}
        >
          <span>
            <KeyperProvider>{user.provider}</KeyperProvider>
          </span>
        </div>
        <div className={classNames(styles.column, styles.role)}>
          <span
            style={{
              backgroundColor: role?.color.background,
              borderColor: role?.color.border,
              color: role?.color.foreground,
            }}
          >
            {role?.name}
          </span>
        </div>
        <div className={classNames(styles.column, styles.check)}>
          <Switch
            large={true}
            checked={checked}
            onChange={(e) => {
              if (!user.isContact || user.valid2fa) {
                handleChecked(user);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
