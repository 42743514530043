import rethink from "rethinkdbdash";

export const createDatabase = async () => {
  return rethink({
    db: "hyker",
    servers: [{ host: "rethinkdb", port: 28015 }],
    silent: true
  });
};

export const getDatabase = (r => async () =>
  (r = r || (await createDatabase())))();

export const query = async (query, errorNr = "unknown") => {
  try {
    const r = await getDatabase();
    const t0 = +new Date();
    const result = await query(r);
    let ms = +new Date() - t0;
    console.log(`queriesProfiler[${errorNr}]ms(${ms})`);
    return result;
  } catch (e) {
    //TODO report e to sentry
    console.error(e);
    throw new Error("Internal server error:" + errorNr);
  }
};
