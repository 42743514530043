import Data from "../../data";
import PublicECDHKey from "../public_ecdh_key";

import { importWebCryptoPublicKey, exportWebCryptoPublicKey } from "./wc";
import { getClassName, assertObject, isEdge } from "../../util";

export default class WebCryptoPublicECDHKey extends PublicECDHKey {
  constructor(key) {
    if (isEdge()) {
      assertObject(key, "key");
    } else if (getClassName(key) !== "CryptoKey") {
      throw new Error(
        `key must be a CryptoKey object. (got ${key.constructor.name})`
      );
    }

    super(key);
  }

  static async import(key, format) {
    return new WebCryptoPublicECDHKey(
      await importWebCryptoPublicKey(
        key,
        format,
        {
          name: "ECDH",
          namedCurve: "P-256"
        },
        []
      )
    );
  }

  async export(format) {
    return exportWebCryptoPublicKey(this.key, format);
  }
}
