import { useState } from "react";
import { Checkbox, Icon } from "@blueprintjs/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { classNames } from "utils";
import styles from "./Wizard.module.css";

export const Order = ({
  selectedUsers,
  setSelected,
  signingOrder,
  setSigningOrder,
  service,
}) => {
  const [placeholderProps, setPlaceholderProps] = useState({});

  const queryAttr = "data-rbd-drag-handle-draggable-id";

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;

  /* IF WE WANT BOX SHADOW BORDER INSTEAD, UN-COMMENT CSS-RULE BELOW */
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "3px",
    background: isDragging ? "#EEF6FC" : "#fff",
    boxShadow: "0 3px 10px rgba(0, 0, 0, .2)",
    /* boxShadow:
      "0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2)",*/
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#f5f8fa" : "#f5f8fa",
    padding: `${grid}px 0 ${grid}px 0`,
    maxWidth: "100%",
    position: "relative",
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const signOrder = reorder(
      selectedUsers,
      result.source.index,
      result.destination.index
    );
    setSelected(signOrder);
    setPlaceholderProps({});
  };

  const onDragUpdate = (update) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    //Core code to calculate Y position of the placeholder
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const onOrder = () => {
    setSigningOrder(!signingOrder);
  };

  const displaySignOrderCheckbox = (
    <div
      className={classNames(
        styles.signOrder,
        !signingOrder && styles.signOrderDisabled
      )}
    >
      <Checkbox onChange={onOrder} checked={signingOrder}>
        <span>{`Set ${service} order`}</span>
        <Icon icon="numbered-list" />
      </Checkbox>
    </div>
  );

  return (
    <div>
      {displaySignOrderCheckbox}
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Droppable droppableId="droppable" isDropDisabled={!signingOrder}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {selectedUsers.map((user, index) => (
                <Draggable
                  key={user.id}
                  draggableId={user.id}
                  index={index}
                  isDragDisabled={!signingOrder}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div className={styles.orderAndName}>
                        {signingOrder ? (
                          <div>
                            <span>{index + 1}</span>
                          </div>
                        ) : (
                          <Icon icon="user" />
                        )}
                        <span>{user.name}</span>
                        <Icon
                          icon={signingOrder ? "drag-handle-vertical" : ""}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}

              <div
                style={{
                  position: "absolute",
                  top: placeholderProps.clientY,
                  left: placeholderProps.clientX,
                  height: placeholderProps.clientHeight,
                  width: placeholderProps.clientWidth,
                }}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
