import PrivateKey from "./private_key";

export default class PrivateECDSAKey extends PrivateKey {
  constructor(key) {
    super(key);
  }

  async sign(data) {
    throw new Error("Not implemented.");
  }
}
