import { Data } from "../";

import { importPrivateECDHKey, importPrivateECDSAKey } from "../crypto";

export default class Credentials {
  constructor(privateECDHKey, privateECDSAKey) {
    this.privateECDHKey = privateECDHKey;
    this.privateECDSAKey = privateECDSAKey;
  }

  static load(file, password) {}

  save(file, password) {}

  static async parse(str) {
    let privateECDHKey;
    let privateECDSAKey;
    let format;

    if (str.length === 552) {
      // Version 1
      privateECDHKey = Data.fromHex(str.slice(0, 276));
      privateECDSAKey = Data.fromHex(str.slice(276));
      format = "pkcs8";
    } else if (str[0] === "0") {
      // Version 2
      let privateECDHKeyLength = Number(str.slice(0, 8));
      privateECDHKey = Data.fromHex(str.slice(8, 8 + privateECDHKeyLength));
      privateECDSAKey = Data.fromHex(str.slice(8 + privateECDHKeyLength));
      format = "pkcs8";
    } else {
      // Version 3
      let credentials = JSON.parse(str);
      format = credentials.format;
      switch (format) {
        case "jwk":
          privateECDHKey =
            credentials.privateECDHKey || credentials.privateNegotiationKey;
          privateECDSAKey =
            credentials.privateECDSAKey || credentials.privateSigningKey;
          break;
        default:
          privateECDHKey = Data.fromHex(
            credentials.privateECDHKey || credentials.privateNegotiationKey
          );
          privateECDSAKey = Data.fromHex(
            credentials.privateECDSAKey || credentials.privateSigningKey
          );
          break;
      }
    }

    return new Credentials(
      await importPrivateECDHKey(privateECDHKey, format),
      await importPrivateECDSAKey(privateECDSAKey, format)
    );
  }

  async stringify() {
    return JSON.stringify({
      privateECDHKey: await this.privateECDHKey.export("jwk"),
      privateECDSAKey: await this.privateECDSAKey.export("jwk"),
      format: "jwk"
    });
  }
}
