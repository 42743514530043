export function toString(provider) {
  return provider.replace(/placeholder/i, "bankid");
}

export default function Provider({ children }) {
  if (typeof children === "string") {
    children = children.replace(/placeholder/, "bankid");
    children = children.replace(/PLACEHOLDER/, "BANKID");
  }
  return children;
}
