module.exports = {
  //isGray, denide
  FILE_CREATE: "FILE_CREATE", //x,x
  FILE_DELETE: "FILE_DELETE", //x,x
  FILE_DELETE_OWN: "FILE_DELETE_OWN", //x,x
  FILE_DOWNLOAD: "FILE_DOWNLOAD", //x,x
  FILE_PREVIEW: "FILE_PREVIEW", //x,x
  FILE_MOVE: "FILE_MOVE", //x,x
  FILE_MOVE_OWN: "FILE_MOVE_OWN", //x,x
  FILE_UPDATE: "FILE_UPDATE", //x,x
  FILE_UPDATE_OWN: "FILE_UPDATE_OWN", //x,x
  FILE_READ: "FILE_READ", //x,x
  FILE_READ_OWN: "FILE_READ_OWN", //x,x

  ATTESTATION_REQUEST: "ATTESTATION_REQUEST",
  ATTESTATION_APPROVE: "ATTESTATION_APPROVE",
  ATTESTATION_REJECT: "ATTESTATION_REJECT",

  ESIGNATURE_REQUEST: "ESIGNATURE_REQUEST",
  ESIGNATURE_APPROVE: "ESIGNATURE_APPROVE",
  ESIGNATURE_REJECT: "ESIGNATURE_REJECT",

  FOLDER_CREATE: "FOLDER_CREATE", //x,x
  FOLDER_DELETE: "FOLDER_DELETE", //x,x
  FOLDER_DELETE_OWN: "FOLDER_DELETE_OWN", //x,x
  FOLDER_MOVE: "FOLDER_MOVE", //x,x
  FOLDER_MOVE_OWN: "FOLDER_MOVE_OWN", //x,x
  FOLDER_RESTRICT: "FOLDER_RESTRICT", //x,0
  FOLDER_UNRESTRICT: "FOLDER_UNRESTRICT", //x,0

  GROUP_TEAMMATE_ADD: "GROUP_TEAMMATE_ADD", //x,0
  GROUP_TEAMMATE_REMOVE: "GROUP_TEAMMATE_REMOVE", //x,0

  TEAM_MESSAGE_READ: "TEAM_MESSAGE_READ", //x,0
  TEAM_MESSAGE_CREATE: "TEAM_MESSAGE_CREATE", //x,0
  TEAM_MESSAGE_DELETE: "TEAM_MESSAGE_DELETE",
  TEAM_MESSAGE_EDIT: "TEAM_MESSAGE_EDIT",

  TEAM_CREATE: "TEAM_CREATE", //x,x
  TEAM_EXPIRE: "TEAM_EXPIRE", //x,x
  TEAM_RENAME: "TEAM_RENAME", //x,x
  TEAM_TEAMMATE_ADD: "TEAM_TEAMMATE_ADD", //x,x
  TEAM_TEAMMATE_ADD_OTHER: "TEAM_TEAMMATE_ADD_OTHER", //x,x
  TEAM_TEAMMATE_LEAVE: "TEAM_TEAMMATE_LEAVE", //x,x
  TEAM_TEAMMATE_REMOVE: "TEAM_TEAMMATE_REMOVE", //x,x
  TEAM_RESTRICT: "TEAM_RESTRICT", //x,0
  TEAM_UNRESTRICT: "TEAM_UNRESTRICT", //x,0

  WORKSPACE_TEAMMATE_REMOVE: "WORKSPACE_TEAMMATE_REMOVE",
  WORKSPACE_TEAMMATE_INVITE: "WORKSPACE_TEAMMATE_INVITE", //x,TEAM_TEAMMATE_ADD
  WORKSPACE_ROLE_ASSIGN: "WORKSPACE_ROLE_ASSIGN", //0,x

  WORKSPACE_ROLE_ADD: "WORKSPACE_ROLE_ADD", //0,x
  WORKSPACE_DELETE_LOGS: "WORKSPACE_DELETE_LOGS",
  WORKSPACE_EDIT_LOGS: "WORKSPACE_EDIT_LOGS",
  WORKSPACE_EXPORT_LOGS: "WORKSPACE_EXPORT_LOGS",
  WORKSPACE_EXPORT_REPORTS: "WORKSPACE_EXPORT_REPORTS",
  WORKSPACE_LIST_TEAMS: "WORKSPACE_LIST_TEAMS", //x,x
  WORKSPACE_UPLOAD_LOGO: "WORKSPACE_UPLOAD_LOGO",
  WORKSPACE_GET_CONFIG: "WORKSPACE_GET_CONFIG",
  WORKSPACE_SET_CONFIG: "WORKSPACE_SET_CONFIG",
  WORKSPACE_GET_BULLHORN_ZONE: "WORKSPACE_GET_BULLHORN_ZONE",
  WORKSPACE_SET_BULLHORN_ZONE: "WORKSPACE_SET_BULLHORN_ZONE",

  LOG_ATTESTATION_REQUEST: "LOG_ATTESTATION_REQUEST",
  LOG_ATTESTATION_APPROVE: "LOG_ATTESTATION_APPROVE",
  LOG_ATTESTATION_REJECT: "LOG_ATTESTATION_REJECT",
  LOG_ESIGNATURE_REQUEST: "LOG_ESIGNATURE_REQUEST",
  LOG_ESIGNATURE_APPROVE: "LOG_ESIGNATURE_APPROVE",
  LOG_ESIGNATURE_REJECT: "LOG_ESIGNATURE_REJECT",
  LOG_FILE_CREATE: "LOG_FILE_CREATE",
  LOG_FILE_DELETE: "LOG_FILE_DELETE",
  LOG_FILE_DOWNLOAD: "LOG_FILE_DOWNLOAD",
  LOG_FILE_MOVE: "LOG_FILE_MOVE",
  LOG_FILE_UPDATE: "LOG_FILE_UPDATE",
  LOG_FILE_PREVIEW: "LOG_FILE_PREVIEW",
  LOG_FILE_READ: "LOG_FILE_READ",
  LOG_FOLDER_CREATE: "LOG_FOLDER_CREATE",
  LOG_FOLDER_DELETE: "LOG_FOLDER_DELETE",
  LOG_FOLDER_MOVE: "LOG_FOLDER_MOVE",
  LOG_FOLDER_RESTRICT: "LOG_FOLDER_RESTRICT",
  LOG_FOLDER_UNRESTRICT: "LOG_FOLDER_UNRESTRICT",
  LOG_MESSAGE_CREATE: "LOG_MESSAGE_CREATE",
  LOG_MESSAGE_DELETE: "LOG_MESSAGE_DELETE",
  LOG_MESSAGE_EDIT: "LOG_MESSAGE_EDIT",
  LOG_MESSAGE_READ: "LOG_MESSAGE_READ",
  LOG_TEAM_CREATE: "LOG_TEAM_CREATE",
  LOG_TEAM_EXPIRE: "LOG_TEAM_EXPIRE",
  LOG_TEAM_DID_EXPIRE: "LOG_TEAM_DID_EXPIRE",
  LOG_TEAM_RENAME: "LOG_TEAM_RENAME",
  LOG_TEAM_TEAMMATE_ADD: "LOG_TEAM_TEAMMATE_ADD",
  LOG_TEAM_TEAMMATE_REMOVE: "LOG_TEAM_TEAMMATE_REMOVE",
  LOG_TEAM_RESTRICT: "LOG_TEAM_RESTRICT", //x,0
  LOG_TEAM_UNRESTRICT: "LOG_TEAM_UNRESTRICT", //x,0

  LOG_GROUP_TEAMMATE_ADD: "LOG_GROUP_TEAMMATE_ADD", //x,0
  LOG_GROUP_TEAMMATE_REMOVE: "LOG_GROUP_TEAMMATE_REMOVE", //x,0

  LOG_WORKSPACE_DELETE_LOGS: "LOG_WORKSPACE_DELETE_LOGS",
  LOG_WORKSPACE_EDIT_LOGS: "LOG_WORKSPACE_EDIT_LOGS",
  LOG_WORKSPACE_EXPORT_LOGS: "LOG_WORKSPACE_EXPORT_LOGS",
  LOG_WORKSPACE_EXPORT_REPORTS: "LOG_WORKSPACE_EXPORT_REPORTS",
  LOG_WORKSPACE_LIST_TEAMS: "LOG_WORKSPACE_LIST_TEAMS",
  LOG_WORKSPACE_ROLE_ADD: "LOG_WORKSPACE_ROLE_ADD",
  LOG_WORKSPACE_ROLE_ASSIGN: "LOG_WORKSPACE_ROLE_ASSIGN",
  LOG_WORKSPACE_TEAMMATE_INVITE: "LOG_WORKSPACE_TEAMMATE_INVITE",
  LOG_WORKSPACE_UPLOAD_LOGO: "LOG_WORKSPACE_UPLOAD_LOGO",
  LOG_WORKSPACE_TEAMMATE_REMOVE: "LOG_WORKSPACE_TEAMMATE_REMOVE",
  LOG_WORKSPACE_GET_CONFIG: "LOG_WORKSPACE_GET_CONFIG",
  LOG_WORKSPACE_SET_CONFIG: "LOG_WORKSPACE_SET_CONFIG",
  LOG_WORKSPACE_GET_BULLHORN_ZONE: "LOG_WORKSPACE_GET_BULLHORN_ZONE",
  LOG_WORKSPACE_SET_BULLHORN_ZONE: "LOG_WORKSPACE_SET_BULLHORN_ZONE",
};
