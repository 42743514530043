import { Data } from "../../";

import { assertInstanceOf, assertObject, assertString } from "../../util";

import * as sjcl from "./sjcl-1.0.7";
import * as asn1js from "asn1js";
import * as pkijs from "pkijs";

function fromBits(bits) {
  return new Data(sjcl.codec.bytes.fromBits(bits));
}

function toBits(data) {
  return sjcl.codec.bytes.toBits(data.getUint8Array());
}

export async function importSJCLPrivateKey(key, format, eccNamespace) {
  assertString(format, "format");

  switch (format) {
    case "pkcs1":
    case "sec1":
      assertInstanceOf(Data, key, "key");

      return importSJCLPrivateKey(
        new pkijs.ECPrivateKey({
          namedCurve: "1.2.840.10045.3.1.7",
          schema: asn1js.fromBER(key.createArrayBuffer() || key).result
        }).toJSON(),
        "jwk",
        eccNamespace
      );
    case "pkcs8":
      assertInstanceOf(Data, key, "key");

      return importSJCLPrivateKey(
        new pkijs.ECPrivateKey({
          namedCurve: "1.2.840.10045.3.1.7",
          schema: asn1js.fromBER(
            asn1js.fromBER(key.createArrayBuffer()).result.valueBlock.value[2]
              .valueBlock.valueHex
          ).result
        }).toJSON(),
        "jwk",
        eccNamespace
      );
    case "jwk":
      assertObject(key, "key");

      return new eccNamespace.secretKey(
        sjcl.ecc.curves.c256,
        sjcl.ecc.curves.c256.field.fromBits(toBits(Data.fromBase64URL(key.d)))
      );
    default:
      throw Error(
        `Unsupported import format for SJCL private key. (${format})`
      );
  }
}

export async function exportSJCLPrivateKey(key, format) {
  assertString(format, "format");

  switch (format) {
    case "pkcs8":
      const ecKey = new pkijs.ECPrivateKey();
      ecKey.fromJSON(await exportSJCLPrivateKey(key, "jwk"));
      return new Data(ecKey.toSchema().toBER(false));

    case "jwk":
      const d = fromBits(key.get())
        .toBase64URL()
        .slice(0, 43);

      //changeFormat
      const sjclD = sjcl.ecc.curves.c256.field.fromBits(
        sjcl.codec.bytes.toBits(Data.fromBase64URL(d).getUint8Array())
      );
      const KEY_TYPE = "ecdsa"; //This could be elGammal but it seam to make no difference
      const keys = sjcl.ecc.basicKey.generateKeys(KEY_TYPE)(
        256,
        undefined,
        sjclD
      );
      const x = fromBits(keys.pub.get().x)
        .toBase64URL()
        .slice(0, 43);
      const y = fromBits(keys.pub.get().y)
        .toBase64URL()
        .slice(0, 43);
      return {
        kty: "EC",
        crv: "P-256",
        x,
        y,
        d
      };
    default:
      throw Error(
        `Unsupported export format for SJCL private key. (${format})`
      );
  }
}

export async function importSJCLPublicKey(key, format, eccNamespace) {
  assertString(format, "format");

  switch (format) {
    case "spki":
      assertInstanceOf(Data, key, "key");

      // 0x04 + (32 bytes) X + (32 bytes) Y
      let bitstring;
      if (key.length == 65) {
        bitstring = key;
      } else {
        bitstring = new Data(
          asn1js.fromBER(
            key.createArrayBuffer()
          ).result.valueBlock.value[1].valueBlock.valueHex
        );
      }

      return new eccNamespace.publicKey(
        sjcl.ecc.curves.c256,
        toBits(bitstring.slice(1))
      );
    case "jwk":
      assertObject(key, "key");

      const x = toBits(Data.fromBase64URL(key.x));
      const y = toBits(Data.fromBase64URL(key.y));

      return new eccNamespace.publicKey(sjcl.ecc.curves.c256, x.concat(y));
    default:
      throw Error(`Unsupported import format for SJCL public key. (${format})`);
  }
}

export async function exportSJCLPublicKey(key, format) {
  assertString(format, "format");

  const xy = key.get();

  switch (format) {
    case "spki":
      return new Data(
        new asn1js.Sequence({
          value: [
            new asn1js.Sequence({
              value: [
                new asn1js.ObjectIdentifier({ value: "1.2.840.10045.2.1" }),
                new asn1js.ObjectIdentifier({ value: "1.2.840.10045.3.1.7" })
              ]
            }),
            new asn1js.BitString({
              valueHex: new pkijs.ECPublicKey({
                x: fromBits(xy.x).getUint8Array(),
                y: fromBits(xy.y).getUint8Array(),
                namedCurve: "1.2.840.10045.3.1.7"
              })
                .toSchema()
                .toBER(false)
            })
          ]
        }).toBER(false)
      );
    case "jwk":
      return {
        kty: "EC",
        crv: "P-256",
        x: fromBits(xy.x).toBase64URL(),
        y: fromBits(xy.y).toBase64URL()
      };
    default:
      throw Error(`Unsupported export format for SJCL public key. (${format})`);
  }
}
