import CBORjs from "./util/cbor-js-0.1.0";

import { Data } from "./";
import { isInstanceOf, assertInstanceOf } from "./util";

function isIterable(object) {
  return isInstanceOf(Object, object) && !Array.isArray(object);
}

function wrapDecode(object) {
  const wrappedObject = {};
  for (const key of Object.keys(object)) {
    if (isInstanceOf(Uint8Array, object[key])) {
      wrappedObject[key] = new Data(object[key]);
    } else if (isIterable(object[key])) {
      wrappedObject[key] = wrapDecode(object[key]);
    } else {
      wrappedObject[key] = object[key];
    }
  }
  return wrappedObject;
}

function wrapEncode(object) {
  const wrappedObject = {};
  for (const key of Object.keys(object)) {
    if (isInstanceOf(Data, object[key])) {
      wrappedObject[key] = object[key].getUint8Array();
    } else if (isIterable(object[key])) {
      wrappedObject[key] = wrapEncode(object[key]);
    } else {
      wrappedObject[key] = object[key];
    }
  }
  return wrappedObject;
}

export default class CBOR {
  static decode(data) {
    assertInstanceOf(Data, data, "CBOR.decode-data");

    return wrapDecode(
      CBORjs.decode(
        data.getUint8Array().buffer,
        data.getUint8Array().byteOffset,
        data.getUint8Array().byteLength
      )
    );
  }

  static encode(object) {
    if (!isIterable(object))
      throw new Error(
        `CBOR.encode expects an iterable object. (got ${object})`
      );

    return new Data(CBORjs.encode(wrapEncode(object)));
  }
}
