import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import {
  doApprove,
  doReject,
  doApproveDeprecated,
  getAttestationState,
} from "../attestation/slice";
import { setDialog, approve as approveDialog } from "../../app/appSlice";
import { Expired, Rejected, Waiting, CannotRequest } from "./WidgetEsignature";
import { ATTESTATION } from "../dialogs/Dialogs";
import { handleTimestamp } from "../wizard/utils";
import { NOT_AVAILABLE } from "./Overlays.js";
import styles from "./Widget.module.css";

import { appState } from "../../app/appState.js";
const {
  selectors: { selectGraph },
} = appState;

export default ({ canAttestRequest, node, onClose }) => {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const [loading, setLoading] = useState(false);
  const { users } = graph;
  const attestationRequest = node?.attestations || [];
  const attestationState = dispatch(getAttestationState(attestationRequest));

  const {
    approvals,
    completed,
    timestamp,
    deadline,
    isOrder,
    rejections,
    info,
    requesterName,
    notYetStarted,
    readyToAttest,
    stateText,
    icon,
    isExpired,
    signees,
  } = attestationState;

  const onStart = () => {
    dispatch(
      setDialog({
        dialog: ATTESTATION,
        node: node,
      })
    );
  };

  if (loading) {
    return null;
  }

  const approve = async () => {
    const { approved } = await dispatch(approveDialog());
    if (approved) {
      if (info) {
        await dispatch(doApprove(node.id, attestationRequest, setLoading));
      } else {
        await dispatch(doApproveDeprecated(attestationRequest, setLoading));
      }
    }
  };

  const reject = async () => {
    const { approved } = await dispatch(approveDialog());
    if (approved) {
      await dispatch(doReject(attestationRequest, setLoading));
    }
  };

  if (notYetStarted) {
    let what;
    if (canAttestRequest) {
      what = <Request onClose={onClose} onStart={onStart} icon={icon} />;
    } else {
      what = (
        <CannotRequest
          text={"No attestation requests allowed."}
          elementStyle={classNames(styles.status, styles.cyan)}
        />
      );
    }
    return (
      <div className={styles.sidekick}>
        <Header onClose={onClose} />
        {what}
      </div>
    );
  }

  let extra;

  if (isExpired) {
    extra = <Expired text={stateText} icon={icon} />;
  } else if (readyToAttest) {
    extra = <Attest reject={reject} approve={approve} />;
  } else if (rejections?.length) {
    extra = <Rejected text={stateText} icon={icon} />;
  } else if (completed) {
    extra = <Completed text={stateText} icon={icon} />;
  } else {
    extra = (
      <Waiting
        text={stateText}
        elementStyle={classNames(styles.status, styles.cyan)}
        icon={icon}
      />
    );
  }

  return (
    <div className={styles.sidekick}>
      <Header onClose={onClose} />
      {extra}
      <div className={styles.verticalSpace} />
      <div className={styles.verticalSpace} />
      <div className={styles.verticalSpace} />
      <div className={classNames(styles.block, styles.queue)}>
        <div className={classNames(styles.label, styles.names)}>
          {completed ? "Attested by parties:" : "Parties to attest: "}
        </div>
        <div className={styles.verticalSpace} />
        <Status
          info={info}
          approvals={approvals}
          rejections={rejections}
          requesterName={requesterName}
          timestamp={timestamp}
          deadline={deadline}
          isOrder={isOrder}
          signees={signees}
          users={users}
        />
      </div>
    </div>
  );
};

const Request = ({ onClose, onStart, icon }) => {
  return (
    <div className={styles.sidekick}>
      <Header onClose={onClose} />
      <div className={classNames(styles.block, styles.center)}>
        <button
          className={classNames(styles.button, styles.pill, styles.cyan)}
          onClick={onStart}
        >
          <Icon icon={icon} />
          <span>Request attestation</span>
        </button>
      </div>
    </div>
  );
};

const Attest = ({ reject, approve }) => {
  return (
    <div className={styles.block}>
      <div className={classNames(styles.label, styles.ready)}>
        Ready to attest:
      </div>
      <div className={classNames(styles.columns, styles.sign)}>
        <button
          className={classNames(styles.button, styles.pill, styles.red)}
          onClick={reject}
        >
          <Icon icon="delete" />
          <span>Reject</span>
        </button>
        <button
          className={classNames(styles.button, styles.pill, styles.green)}
          onClick={approve}
        >
          <Icon icon={"endorsed"} />
          <span>Approve</span>
        </button>
      </div>
    </div>
  );
};

const Completed = ({ text, icon }) => {
  return (
    <div className={classNames(styles.status, styles.green)}>
      <Icon icon={icon} />
      {text}
    </div>
  );
};

const Header = () => {
  return <div className={styles.header}></div>;
};

const Info = ({
  requester,
  reviewer,
  timestamp,
  created,
  deadline,
  message,
  order,
  isOrder,
  orgNo,
  styleStatus,
}) => {
  const { name } = reviewer;
  const date = timestamp === "" ? message : handleTimestamp(timestamp);
  const organizationNumber = orgNo ? orgNo : NOT_AVAILABLE;

  return (
    <>
      <div>{requester}</div>
      <div>Requester</div>
      <div>{name}</div>
      <div>Reviewer</div>
      <div className={styleStatus}>{date}</div>
      <div>Date and time</div>
      <div>{handleTimestamp(created)}</div>
      <div>Requested at</div>
      <div>{isOrder ? order : "No order"}</div>
      <div>Attestation order</div>
      <div>{organizationNumber}</div>
      <div>Organization number</div>
      <div>{deadline ? handleTimestamp(deadline) : "No expiration date"}</div>
      <div>Expiry date</div>
    </>
  );
};

export const Status = ({
  info,
  approvals,
  rejections,
  requesterName,
  timestamp,
  deadline,
  isOrder,
  signees,
  users,
}) => {
  //THIS SWITCH BETWEEN SIGNEES AND INFO IS A TEMPORARY FIX
  //TO BE ABLE TO SHOW THE INFO IN THE DROPDOWN FOR OLD AND NEW ATTESTATIONS
  //AS THERE ARE NO INFO IN THE OLD ATTESTATIONS OBJECT ONLY SIGNEES
  return (
    <>
      {(info || signees)?.map(
        ({ id, fullName, order, organizationInfo }, index) => {
          const userId = id || signees[0];
          const isApproved = approvals.find(({ user }) => user === userId);
          const isRejected = rejections.find(({ user }) => user === userId);
          const { companyName, orgNo } = organizationInfo || {};

          const userName = getUserName(users, userId);

          let icon = "user";
          let timestampStatus = "";
          let styleStatus = "";

          if (isApproved) {
            icon = "endorsed";
            timestampStatus = isApproved.timestamp;
            styleStatus = styles.approve;
          } else if (isRejected) {
            icon = "ban-circle";
            timestampStatus = isRejected.timestamp;
            styleStatus = styles.reject;
          }

          return (
            <DropDown
              key={index}
              icon={icon}
              title={
                companyName
                  ? companyName.replace(/\s/g, "")
                  : fullName || userName
              }
              isWizard={false}
              children={
                <Info
                  requester={requesterName}
                  reviewer={{ name: fullName || userName }}
                  timestamp={timestampStatus}
                  created={timestamp}
                  deadline={deadline}
                  message={"Not attested"}
                  order={order}
                  isOrder={isOrder}
                  orgNo={orgNo}
                  styleStatus={styleStatus}
                />
              }
            />
          );
        }
      )}
    </>
  );
};

export const DropDown = ({ icon, title, isWizard = true, children }) => {
  const [openAttestation, setOpenAttestation] = useState(false);
  return (
    <div
      className={classNames(
        styles.dropdown,
        openAttestation && styles.dropdownOpen
      )}
    >
      <div onClick={() => setOpenAttestation(!openAttestation)}>
        <Icon
          icon={icon}
          className={classNames(styles.icon, isWizard && styles.selected)}
        />
        <span>{title}</span>
        <Icon icon={"chevron-down"} />
      </div>
      {openAttestation && <div className={styles.info}>{children}</div>}
    </div>
  );
};

const getUserName = (users, id) => {
  const usersIfNoInfo = Object.values(users);
  const userName = usersIfNoInfo.find((user) => user.user === id);
  const { name } = userName || {};
  return name || "Not found";
};
