import { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Wizard.module.css";
import { Search } from "./Wizard.js";

export const MobileSelect = ({
  selectableUsers,
  selectedUsers,
  setSelected,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const filteredUsers = selectableUsers.filter((user) =>
    user.text[0][0].toLowerCase().includes(searchValue.toLowerCase())
  );

  const isMemberSelected = (aUser) =>
    selectedUsers.some((bUser) => aUser.id == bUser.id);

  const handleUserDeselect = (aUser) =>
    setSelected(selectedUsers.filter((bUser) => aUser.id != bUser.id));

  const handleUserSelect = (user) => {
    if (!isMemberSelected(user)) {
      user = {
        ...user,
        name: user.text[0][0],
      };
      setSelected([user, ...selectedUsers]);
    } else {
      handleUserDeselect(user);
    }
  };

  return (
    <div className={styles.mobileSelect} onClick={(e) => e.preventDefault()}>
      <Search
        searchValue={searchValue}
        onChange={handleSearchChange}
        clearSearch={clearSearch}
      />
      {filteredUsers.map((user, index) => {
        let iconClassName;
        if (isMemberSelected(user)) {
          iconClassName = classNames(styles.icon, styles.selected);
        } else {
          iconClassName = styles.icon;
        }
        return (
          <div
            key={index}
            className={styles.card}
            onClick={() => handleUserSelect(user)}
          >
            <Icon icon={"user"} className={iconClassName} />
            <span>{user.text[0]}</span>
          </div>
        );
      })}
    </div>
  );
};
