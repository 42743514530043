import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { keys, values, diff, classNames, isFunction } from "utils";
import { ZONE } from "../../app/dictionary.js";
import {
  ///loadZones,
  ///searchZones,
  ///resetZonesPageParams,
  setDialog,
  ///selectZonesSearchTerm
  searchZonesDebouced,
  selectBullhorn,
  selectNotifications,
} from "../../app/appSlice.js";
import { selectIdentity, getToken } from "../../app/topSlice.js";
import styles from "./zones.module.css";
import { useContextMenu } from "../contexts/Popover.js";
import {
  ZONES_TEMPLATES,
  ZONES_TEMPLATE,
  ZONES_CATEGORY,
  ZONES_BACKGROUND,
  ZONES_ZONE,
} from "../contexts/Contexts.js";
import DND from "./dnd.js";
import { appState } from "../../app/appState.js";
import { TEAM_CREATE as DIALOG_TEAM_CREATE } from "../dialogs/Dialogs.js";
import { Users } from "../login/icons.tsx";

import { TEAM_CREATE } from "../../app/permissions.js";

import { fetchMutedTeams, selectMutedTeams } from "../mute_team/muteSlice.js";

import { API_URL_PREFIX } from "../../config.js";
import { request, jwtPeek, authReq } from "utils";

const {
  derive: { getZones },
  selectors: {
    selectGraph,
    //selectUsers,
    selectCategories,
    selectTemplatesCount,
    selectPermissions,
    selectZonesSearchTerm,
  },
  actions: { setCurrentZone, remote, resetZones, assignCategory },
  actions: { loadZones, /*searchZones,*/ resetZonesPageParams },
  server: { bumpZone },
} = appState;

const orders = {
  //date: "sort-numerical-desc",
  date: "sort",
  //'date-asc': 'sort-numerical',
  "name-asc": "sort-alphabetical",
  name: "sort-alphabetical-desc",
};

export const Zones = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [opens, setOpen] = useState({ "": true });
  const [order, setOrder] = useState("date");
  const [timeOfLastSeenActivity, setTimeOfLastSeenActivity] = useState(0);
  const [teamsWithUnreadEvents, setTeamsWithUnreadEvents] = useState([]);
  const { id: userId } = useSelector(selectIdentity);
  const categories = useSelector(selectCategories);
  const templatesCount = useSelector(selectTemplatesCount);
  const searchTerm = useSelector(selectZonesSearchTerm);
  const permissions = useSelector(selectPermissions);
  const bullhorn = useSelector(selectBullhorn);
  const notifications = useSelector(selectNotifications);
  const zones = getZones(useSelector(selectGraph)).filter(
    (zone) => !zone.isForeign
  );

  const volatileCategory = useRef(null);
  volatileCategory.current = categories;

  const mutedTeams = useSelector(selectMutedTeams);

  useEffect(() => {
    dispatch(fetchMutedTeams());
    setTimeOfLastSeenActivity(Date.now());
  }, []);

  useEffect(() => {
    setTimeOfLastSeenActivity(Date.now());
  }, [notifications]);

  useEffect(() => {
    const newNotifications = Object.values(notifications).filter(
      (notification) => {
        return (
          notification.origin.id !== userId &&
          notification.time > timeOfLastSeenActivity
        );
      }
    );
    if (newNotifications.length > 0) {
      setTeamsWithUnreadEvents((oldState) => {
        const newState = [
          ...oldState,
          ...newNotifications
            .filter(
              (notification) =>
                notification.target?.team &&
                !oldState.includes(notification.target?.team?.id)
            )
            .map((notification) => {
              return notification.target.team.id;
            }),
        ];
        return newState;
      });
    }
  }, [notifications, timeOfLastSeenActivity, userId]);

  const nextOrder = () => {
    const keys = Object.keys(orders);
    const next = keys[(keys.indexOf(order) + 1) % keys.length];
    setOrder(next);
    dispatch(resetZones());
    dispatch(resetZonesPageParams({ order: next }));
  };

  const uncategorized = diff(
    zones.map((z) => z.id),
    categories.flatMap((c) => c.zones || [])
  );

  const sections = categories.map(({ id, ...category }) => ({
    id,
    name: category.name,
    type: ZONES_CATEGORY,
    icon: "star",
    zones: category.zones || [],
    open: !!searchTerm || opens[id],
    setOpen: (b) => !searchTerm && setOpen({ ...opens, [id]: b }),
    initDone: !!searchTerm,
    isMobile,
    count: searchTerm ? null : (category.zones || []).length,
    setTeamsWithUnreadEvents: setTeamsWithUnreadEvents,
    hasUnreadEvent: teamsWithUnreadEvents?.includes(id),
  }));

  const uncategorizedTeams = uncategorized.filter(
    (id) => !zones.find((zone) => zone.id === id).isTemplate
  );

  sections.push({
    id: "",
    name: ZONE + "s",
    icon: "application",
    open: !!searchTerm || opens[""],
    setOpen: (b) => !searchTerm && setOpen({ ...opens, [""]: b }),
    zones: uncategorizedTeams,
    triggerOpenOther: () => {
      //triggers when a section is done loading but still empty
      const ref = setInterval(() => {
        const categories = volatileCategory.current;
        if (!categories) {
          clearInterval(ref);
        } else if (categories.length) {
          const cat = categories.find((f) => f.zones && f.zones.length);
          if (cat) {
            setOpen({ ...opens, [cat.id]: true });
          }
          clearInterval(ref);
        }
      }, 100);
    },
    setTeamsWithUnreadEvents: setTeamsWithUnreadEvents,
  });

  const templates = uncategorized
    .map((id) => zones.find((zone) => zone.id === id))
    .filter((zone) => zone.isTemplate);

  const templateProp = {
    name: "templates",
    icon: "applications",
    type: ZONES_TEMPLATES,
    isTemplates: true,
    id: "templates-x",
    open: !!searchTerm || opens["templates-x"],
    setOpen: (b) => !searchTerm && setOpen({ ...opens, ["templates-x"]: b }),
    zones: templates,
    count: searchTerm ? null : templatesCount,
    triggerOpenOther: () => {},
    setTeamsWithUnreadEvents: setTeamsWithUnreadEvents,
  };

  const droppables = sections.map((section) => ({
    id: section.id,
    props: section,
    comp: Section,
    draggables: values(zones)
      .filter((zone) => section.zones.includes(zone.id))
      .map((zone) => ({
        id: zone.id,
        props: {
          ...zone,
          isMobile,
          muted: mutedTeams.includes(zone.id),
          hasUnreadEvent: teamsWithUnreadEvents?.includes(zone.id),
          setTeamsWithUnreadEvents: setTeamsWithUnreadEvents,
        },
        comp: Zone,
        rowAniClass: styles.ani,
      })),
  }));

  return (
    <div
      key={order + searchTerm}
      className={styles.outer}
      data-context={ZONES_BACKGROUND}
    >
      <div className={styles.head}>
        <div className={styles.search}>
          <Icon icon="search" />
          <input
            type="text"
            defaultValue={searchTerm}
            {...(searchTerm && { autoFocus: true })}
            onChange={(e) => dispatch(searchZonesDebouced(e.target.value))}
          />
          <Icon
            icon="cross"
            onClick={(e) =>
              searchTerm !== "" && dispatch(searchZonesDebouced(""))
            }
          />
        </div>
        {permissions[TEAM_CREATE] && !bullhorn.hideAddZoneButton && (
          <div
            className={styles.bullet}
            onClick={() => dispatch(setDialog({ dialog: DIALOG_TEAM_CREATE }))}
          >
            <Icon icon="plus" />
          </div>
        )}
        <div className={styles.bullet} onClick={nextOrder}>
          <Icon icon={orders[order]} />
        </div>
      </div>
      <div className={styles.list} data-k-list="1">
        <div className={styles.scroll}>
          <div>
            <Section {...templateProp}>
              {templateProp.zones.map((zone) => (
                <Template
                  {...zone}
                  key={"template-x-" + zone.id}
                  muted={mutedTeams.includes(zone.id)}
                  hasUnreadEvent={teamsWithUnreadEvents?.includes(zone.id)}
                  setTeamsWithUnreadEvents={setTeamsWithUnreadEvents}
                />
              ))}
            </Section>
            <DND
              droppables={droppables}
              onDrop={(zoneId, categoryId) => {
                dispatch(assignCategory({ /*userId,*/ zoneId, categoryId }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({
  id,
  icon,
  name,
  zones,
  type,
  children,
  open,
  setOpen,
  initDone,
  isTemplates = false,
  count = null,
  triggerOpenOther = (_) => _,
  setTeamsWithUnreadEvents,
}) => {
  const refContext = useContextMenu(type, { id, name });

  const dispatch = useDispatch();

  const [done, setDone] = useState(initDone);
  const [load, setLoad] = useState(false);

  const observer = useRef(null);

  const refAppear = useCallback(
    (node) => {
      if (node) {
        observer.current = new IntersectionObserver(
          async (entries) => {
            if (0 < entries[0].intersectionRatio) {
              setLoad(true);

              let payload;
              if (isTemplates) {
                payload = { templates: true };
              } else if (id) {
                payload = { category: id };
              } else {
                payload = {};
              }

              const { graph, error } = await dispatch(loadZones(payload));

              if (error) {
                setDone(true);
                setLoad(false);
                return;
              }
              if (!keys(graph.zones).length) {
                setDone(true);
                if (!zones.length) {
                  triggerOpenOther();
                }
              }
              // Load teams that have unread events
              const accessToken = await dispatch(getToken());
              const url = `${API_URL_PREFIX}/unreadevent?teamIds=${keys(
                graph.zones
              ).join(",")}`;
              const { user: userId } = jwtPeek(accessToken);
              const teams = await request(
                url,
                authReq({
                  accessToken,
                  userId,
                })
              );

              setTeamsWithUnreadEvents((oldState) => [...oldState, ...teams]);

              setLoad(false);
            }
          },
          { root: node.closest("[data-k-list]") }
        );
        observer.current.observe(node);
      } else {
        observer.current?.disconnect();
      }
    },
    [triggerOpenOther, setTeamsWithUnreadEvents]
  );

  return (
    <>
      <div
        ref={refContext}
        className={classNames(styles.section, open && styles.open)}
      >
        <div
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Icon icon={icon || "star"} />
          <span>{name}</span>
          <span>{count}</span>
          <Icon icon={"caret-left"} />
        </div>
      </div>
      {(isFunction(children) && children(open)) || (open && children)}
      {open && !load && !done ? (
        <div ref={refAppear} style={{ height: "1px" }} />
      ) : (
        <div style={{ height: "1px" }} />
      )}
    </>
  );
};

const Zone = ({
  id,
  name,
  isCurrent,
  isRestricted,
  willExpire,
  isMobile,
  muted,
  hasUnreadEvent,
  setTeamsWithUnreadEvents,
}) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(selectIdentity);
  const ref = useContextMenu(ZONES_ZONE, {
    zone: { id, name },
    userId,
  });
  const initials = name.replace(/[^\p{N}\p{L}\p{M}]/gu, "").slice(0, 2);

  const selectZone = () => {
    dispatch(setCurrentZone(id));
    dispatch(remote(bumpZone({ id })));
    setTeamsWithUnreadEvents((oldState) =>
      oldState.filter((teamId) => teamId !== id)
    );
  };
  return (
    <div
      ref={ref}
      className={classNames(styles.margin)}
      onContextMenu={selectZone}
      onClick={selectZone}
    >
      <div className={classNames(styles.zone, isCurrent && styles.current)}>
        <div className={styles.avatar}>
          {initials}
          {hasUnreadEvent && <span className={styles.badge}></span>}
        </div>
        <div
          className={classNames(styles.label, hasUnreadEvent && styles.bold)}
        >
          {name}
        </div>
        <div className={styles.icons}>
          {muted && <Icon icon="volume-off" />}
          {isRestricted /* || hasGroupChoice*/ && (
            <span>
              <Users className={styles.users} />
            </span>
          )}
          {willExpire && <Icon icon="time" />}
        </div>
      </div>
    </div>
  );
};

const Template = ({
  id,
  name,
  isCurrent,
  isRestricted,
  willExpire,
  muted,
  hasUnreadEvent,
  setTeamsWithUnreadEvents,
}) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(selectIdentity); // || {};
  const ref = useContextMenu(ZONES_TEMPLATE, {
    zone: { id, name },
    userId,
  });
  const initials = name.replace(/[^\p{N}\p{L}\p{M}]/gu, "").slice(0, 2);

  const selectZone = () => {
    dispatch(setCurrentZone(id));
    dispatch(remote(bumpZone({ id })));
    setTeamsWithUnreadEvents((oldState) =>
      oldState.filter((teamId) => teamId !== id)
    );
  };
  return (
    <div
      ref={ref}
      className={classNames(styles.margin)}
      onContextMenu={selectZone}
      onClick={selectZone}
    >
      <div
        className={classNames(
          styles.zone,
          styles.template,
          isCurrent && styles.current
        )}
      >
        <div className={styles.avatar}>{initials}</div>
        <div className={styles.label}>{name}</div>
        <div className={styles.icons}>
          {muted && <Icon icon="volume-off" />}
          {isRestricted && (
            <span>
              <Users className={styles.users} />
            </span>
          )}
          {willExpire && <Icon icon="time" />}
        </div>
      </div>
    </div>
  );
};
