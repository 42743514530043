import RethinkdbBackend from "./rethinkdb-backend";
import Actions from "./actions";
import UUID from "./crypto-uuid";
import expressMiddleWare from "./express-middle-ware";
import ActionDescriptions from "./action-descriptions";

export { RethinkdbBackend, expressMiddleWare, ActionDescriptions };

export const uuid = _ => UUID().replace(/-/g, "");
export const nonEmpty = array => (array.length ? array : null);
export const nonEmptyObj = obj => (Object.keys(obj).length ? obj : null);
export const path = (object, ...path) => (
  path.forEach(path => (object = (object || {})[path] || null)), object
);
export const mapObj = (o, f) =>
  Object.entries(o).reduce((r, [k, v]) => ((r[k] = f(v, k)), r), {});
export const transformObj = (o, f) =>
  Object.entries(o).reduce(
    (r, [k, v]) => (([k, v] = f(k, v)), (r[k] = v), r),
    {}
  );
export const filterObj = (o, p) =>
  Object.entries(o).reduce((r, [k, v]) => (p(k, v) && (r[k] = v), r), {});

const isArray = value => Array.isArray(value);
const isObject = value =>
  !isArray(value) && typeof value === "object" && value !== null;
const isUndefined = value => value === undefined;
const isFunction = value => typeof value === "function";

export const dict = arr => Object.assign(...arr.map(([k, v]) => ({ [k]: v })));
export const sortKeys = (obj, _) => (
  (_ = Object.entries(obj)), dict(_.sort((a, b) => a[0].localeCompare(b[0])))
);
export const sortKeysRec = obj =>
  sortKeys(mapObj(obj, val => (isObject(val) ? sortKeysRec(val) : val)));

const ALLOW = true;
const DENY = false;

export const A = Actions;

const w8 = async (strings, ...promisees) => {
  const vals = await Promise.all(promisees);
  return strings
    .reduce((acc, el, i) => acc.concat([el, vals[i] || ""]), [])
    .join("");
};

export const HumanReadableEvent = fetcher => event =>
  TDID(fetcher)[event.type](event);

export const TDID = fetcher => {
  //The Deed Is Done

  const teamBody = (title, tab) => async event => ({
    body: await w8`Team: ${fetcher.project(event)}`,
    projectName: await fetcher.project(event),
    title,
    tab
  });

  const groupBody = (title, tab) => async event => ({
    body: await w8`Team: ${fetcher.group(event)}`,
    projectName: await fetcher.group(event),
    title,
    tab
  });
  const orgBody = title => async event => ({
    body: await w8`Workspace: ${event.target}`,
    title
  });
  const notImplemented = e => {
    throw new Error(`Not Implemented[${e.type}]`);
  };

  return {
    [A.FILE_CREATE]: notImplemented,
    [A.FILE_DELETE]: notImplemented,
    [A.FILE_DELETE_OWN]: notImplemented,
    [A.FILE_DOWNLOAD]: notImplemented,
    [A.FILE_PREVIEW]: notImplemented,
    [A.FILE_MOVE]: notImplemented,
    [A.FILE_MOVE_OWN]: notImplemented,
    [A.FILE_UPDATE]: notImplemented,
    [A.FILE_UPDATE_OWN]: notImplemented,
    [A.FILE_READ]: notImplemented,
    [A.FILE_READ_OWN]: notImplemented,

    [A.FOLDER_CREATE]: notImplemented,
    [A.FOLDER_DELETE]: notImplemented,
    [A.FOLDER_DELETE_OWN]: notImplemented,
    [A.FOLDER_MOVE]: notImplemented,
    [A.FOLDER_MOVE_OWN]: notImplemented,
    [A.FOLDER_RESTRICT]: notImplemented,
    [A.FOLDER_UNRESTRICT]: notImplemented,

    [A.GROUP_TEAMMATE_ADD]: notImplemented,
    [A.GROUP_TEAMMATE_REMOVE]: notImplemented,

    [A.ATTESTATION_REQUEST]: notImplemented,
    [A.ATTESTATION_APPROVE]: notImplemented,
    [A.ATTESTATION_REJECT]: notImplemented,

    [A.ESIGNATURE_REQUEST]: notImplemented,
    [A.ESIGNATURE_APPROVE]: notImplemented,
    [A.ESIGNATURE_REJECT]: notImplemented,

    [A.TEAM_MESSAGE_READ]: notImplemented,
    [A.TEAM_MESSAGE_CREATE]: notImplemented,
    [A.TEAM_MESSAGE_DELETE]: notImplemented,
    [A.TEAM_MESSAGE_EDIT]: notImplemented,

    [A.TEAM_CREATE]: notImplemented,
    [A.TEAM_EXPIRE]: notImplemented,
    [A.TEAM_RENAME]: notImplemented,
    [A.TEAM_RESTRICT]: notImplemented,
    [A.TEAM_UNRESTRICT]: notImplemented,
    [A.TEAM_TEAMMATE_ADD]: notImplemented,
    [A.TEAM_TEAMMATE_ADD_OTHER]: notImplemented,
    [A.TEAM_TEAMMATE_LEAVE]: notImplemented,
    [A.TEAM_TEAMMATE_REMOVE]: notImplemented,

    [A.WORKSPACE_TEAMMATE_INVITE]: notImplemented,
    [A.WORKSPACE_ROLE_ASSIGN]: notImplemented,

    [A.WORKSPACE_ROLE_ADD]: notImplemented,
    [A.WORKSPACE_DELETE_LOGS]: notImplemented,
    [A.WORKSPACE_EDIT_LOGS]: notImplemented,
    [A.WORKSPACE_EXPORT_LOGS]: notImplemented,
    [A.WORKSPACE_EXPORT_REPORTS]: notImplemented,
    [A.WORKSPACE_LIST_TEAMS]: notImplemented,
    [A.WORKSPACE_UPLOAD_LOGO]: notImplemented,
    [A.WORKSPACE_GET_CONFIG]: notImplemented,
    [A.WORKSPACE_SET_CONFIG]: notImplemented,
    [A.WORKSPACE_GET_BULLHORN_ZONE]: notImplemented,
    [A.WORKSPACE_SET_BULLHORN_ZONE]: notImplemented,

    [A.LOG_ATTESTATION_REQUEST]: groupBody("Attestation Requested", "files"),
    [A.LOG_ATTESTATION_APPROVE]: groupBody("Attestation Approved", "files"),
    [A.LOG_ATTESTATION_REJECT]: groupBody("Attestation Rejected", "files"),

    [A.LOG_ESIGNATURE_REQUEST]: groupBody("Esignature Requested", "files"),
    [A.LOG_ESIGNATURE_APPROVE]: groupBody("Esignature Approved", "files"),
    [A.LOG_ESIGNATURE_REJECT]: groupBody("Esignature Rejected", "files"),

    [A.LOG_TEAM_RENAME]: async event => ({
      title: "Team Name Changed",
      body: `from:${event.meta.from}, to:${event.meta.to}`,
      projectName: event.meta.to
    }),
    [A.LOG_TEAM_CREATE]: async event => ({
      title: event.meta.isTemplate
        ? "New Template Created"
        : "New Team Created",
      body: await w8`Workspace: ${fetcher.org(event)}`
    }),
    [A.LOG_FILE_CREATE]: groupBody("New File Uploaded", "files"),
    [A.LOG_FILE_DELETE]: groupBody("File Deleted", "files"),
    [A.LOG_FILE_MOVE]: groupBody("File Moved", "files"),
    [A.LOG_FILE_READ]: groupBody("File Read", "files"),
    [A.LOG_FILE_DOWNLOAD]: groupBody("File Downloaded", "files"),
    [A.LOG_FILE_PREVIEW]: groupBody("File Previewed", "files"),
    [A.LOG_FOLDER_CREATE]: groupBody("Folder Created", "files"),
    [A.LOG_FOLDER_DELETE]: groupBody("Folder Removed", "files"),
    [A.LOG_FOLDER_MOVE]: groupBody("Folder Moved", "files"),
    [A.LOG_FOLDER_UPDATE]: groupBody("Folder Updated", "files"),
    [A.LOG_FOLDER_RESTRICT]: groupBody("Folder Restricted", "trust"),
    [A.LOG_FOLDER_UNRESTRICT]: groupBody("Folder Unrestricted", "trust"),
    [A.LOG_MESSAGE_CREATE]: groupBody("New Message", "chat"),
    [A.LOG_MESSAGE_DELETE]: groupBody("Message Deleted", "chat"),
    [A.LOG_MESSAGE_EDIT]: groupBody("Changed a Message", "chat"),
    [A.LOG_MESSAGE_READ]: groupBody("A Message was Read", "chat"),
    [A.LOG_TEAM_EXPIRE]: teamBody("Team Expire Date Changed", "files"),
    [A.LOG_TEAM_DID_EXPIRE]: teamBody("Team Did Expire", "files"),
    [A.LOG_TEAM_TEAMMATE_ADD]: groupBody("Teammate Added", "trust"),
    [A.LOG_TEAM_TEAMMATE_REMOVE]: groupBody("Teammate Removed", "trust"),
    [A.LOG_TEAM_RESTRICT]: groupBody("Team Restricted", "trust"),
    [A.LOG_TEAM_UNRESTRICT]: groupBody("Team Unrestricted", "trust"),
    [A.LOG_GROUP_TEAMMATE_ADD]: groupBody(
      "Security Group Member Added",
      "trust"
    ),
    [A.LOG_GROUP_TEAMMATE_REMOVE]: groupBody(
      "Security Group Member Removed",
      "trust"
    ),
    [A.LOG_WORKSPACE_TEAMMATE_INVITE]: orgBody("Member added to Workspace"),
    [A.LOG_WORKSPACE_DELETE_LOGS]: notImplemented,
    [A.LOG_WORKSPACE_EDIT_LOGS]: notImplemented,
    [A.LOG_WORKSPACE_EXPORT_LOGS]: notImplemented,
    [A.LOG_WORKSPACE_EXPORT_REPORTS]: notImplemented,
    [A.LOG_WORKSPACE_LIST_TEAMS]: notImplemented,
    [A.LOG_WORKSPACE_ROLE_ADD]: notImplemented,
    [A.LOG_WORKSPACE_UPLOAD_LOGO]: notImplemented,
    [A.LOG_WORKSPACE_ROLE_ASSIGN]: orgBody("Member role assigned"),
    [A.LOG_WORKSPACE_GET_CONFIG]: notImplemented,
    [A.LOG_WORKSPACE_SET_CONFIG]: notImplemented,
    [A.LOG_WORKSPACE_GET_BULLHORN_ZONE]: notImplemented,
    [A.LOG_WORKSPACE_SET_BULLHORN_ZONE]: notImplemented,
  };
};

export const ALL_ACTIONS = Object.keys(A);

// TODO override is not a great name
export const PERMISSIONS_EXAMPLE = {
  account: "some account",
  workspace: "some workspace",
  roles: {
    global: "LIMITED",
    override: {
      "some team": "TEAM_MANAGEER"
    }
  },
  global: {
    //NOTE!! if roles are present both global and override are unused
    UPLOAD_FILE: true,
    DOWNLOAD_FILE: true
  },
  override: {
    "some team": {
      INVITE: true,
      DOWNLOAD_FILE: false
    }
  }
};

export function userRef(user) {
  return { user };
}

export function isAllowedWithPermissions(perms, ...tests) {
  if (!tests.length) return true;
  for (let test of tests) {
    if (!isArray(test)) {
      test = [test];
    }
    let allowed = !!test.length; // TODO evaluate
    for (const perm of test) {
      if (!perms[perm]) {
        allowed = false;
        break;
      }
    }
    if (allowed) {
      return true;
    }
  }
  return false;
}

export function normalizePermissions(perms) {
  // TODO sanitize
  return mapObj(perms, value => (value ? ALLOW : DENY));
}

export function packPermissions(perms) {
  perms = normalizePermissions(perms);
  perms = transformObj(perms, (key, v) => [key, v]);
  return perms;
}

export function combinePermissions(...combine) {
  let combined = {};
  for (const perms of combine) {
    combined = { ...combined, ...(perms || {}) };
  }
  return normalizePermissions(combined);
}

export function getPermissionsInTeam(perms, team) {
  const one = path(perms, "global") || {};
  const two = path(perms, "override", team) || {};

  return combinePermissions(one, two);
}

export function isAllowedInTeam(perms, team, ...tests) {
  perms = getPermissionsInTeam(perms, team);
  return isAllowedWithPermissions(perms, ...tests);
}

export async function getWrappedUser(permissions, user, roleExpander) {
  user = userRef(user);
  if (permissions.roles) {
    const expandedPermissions = { override: {} };
    expandedPermissions.global = await roleExpander.toPermissions(
      permissions.roles.global
    );
    for (let [team, role] of Object.entries(permissions.roles.override || {})) {
      expandedPermissions.override[team] = await roleExpander.toPermissions(
        role
      );
    }
    permissions = expandedPermissions;
  }

  // TODO check that its the right user using isWorkspace and isAccount
  return {
    isAllowedInTeam(team, ...tests) {
      return isAllowedInTeam(permissions, team, ...tests);
    }
  };
}

/**
 * # Permission Storage Module for konfident.
 *
 * ## Run test
 * from dev tools:
 * require('psm').testRunner()
 */
export default class PermissionStorageModule {
  constructor(backend = null) {
    this.backend = backend || new MemoryStorageBackend();
  }

  async getEntry(user) {
    const entry = await this.backend.load(user);
    if (entry) {
      return { /*...user,*/ ...entry };
    }

    return null;
  }

  async setPermission(user, permission, allow = true) {
    // TODO sanitize
    // TODO make atomic
    const addition = { [permission]: allow };
    const entry = await this.getEntry(user);
    const previous = path(entry, "global");
    const permissions = combinePermissions(previous, addition);
    await this.setPermissions(user, permissions);
  }

  async setPermissionsInTeam(user, subspace, permissions) {
    // TODO sanitize
    // TODO make atomic
    const entry = await this.getEntry(user);
    const extra = path(entry, "override");
    const perms = normalizePermissions(permissions);
    const next = { ...entry, override: { ...extra, [subspace]: perms } };
    await this.backend.save(user, next);
  }

  async setPermissionInTeam(user, subspace, permission, allow = true) {
    // TODO sanitize
    // TODO make atomic
    const addition = { [permission]: allow };
    const entry = await this.getEntry(user);
    const extra = path(entry, "override");
    const previous = path(extra, subspace);
    const perms = combinePermissions(previous, addition);
    const next = { ...entry, override: { ...extra, [subspace]: perms } };
    await this.backend.save(user, next);
  }

  async getPermissionsInTeam(user, subspace) {
    const entry = await this.getEntry(user);
    return getPermissionsInTeam(entry, subspace);
  }

  async isAllowedInTeam(user, team, ...tests) {
    const perms = await this.getPermissionsInTeam(user, team);
    return isAllowedWithPermissions(perms, ...tests);
  }

  async getToken(user) {
    return (await this.getEntry(userRef(user))) || {};
  }
}

export class MemoryStorageBackend {
  constructor() {
    this.storage = {};
  }
  async save(user, permissions) {
    this.storage[JSON.stringify(user)] = permissions;
  }
  async load(user) {
    return this.storage[JSON.stringify(user)] || null;
  }
}
