import React, { useContext } from "react";
import { Dialog } from "@blueprintjs/core";
import appContext from "../../app/appContext.js";
import AttestationWizard from "../attestation/attestationWizard";
import styles from "./Dialogs.module.css";

export default ({ node, active, close }) => {
  const { groupId, nodeId } = useContext(appContext);

  return (
    <Dialog
      isOpen={active}
      style={{ padding: 0 }}
    >
      <AttestationWizard {...{ node, active, groupId, nodeId, close }} />
    </Dialog>
  );
};
