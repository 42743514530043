import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDialog, setBullhornActive, setDialog } from "../../app/appSlice";
import { appState } from "../../app/appState";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import BhDialogs, {
  BH_CREATE_ZONE,
  BH_SELECT_ZONE,
  BH_SYNC_ADDED_FILES,
  BH_SYNC_ADDED_MEMBERS,
  BH_SYNC_REMOVED_MEMBERS,
} from "./BhDialogs";
import {
  displayZone,
  fetchDisregarded,
  groupFileInfos,
  selectDisregardedStatus,
  selectEntityType,
  selectJobOrderId,
  selectRelatedZones,
  selectRelatedZonesStatus,
  selectRunFlow,
  selectShowAllFileInfos,
  selectSyncFilesOnly,
  selectZoneId,
  selectZoneName,
  setRunFlow,
  setSyncFilesOnly,
  setZoneId,
  useFilesDiff,
  useUsersDiff,
} from "./bullhornSlice";
const {
  selectors: { selectGraph },
  derive: { getUsersFromGroup },
  actions: { remote },
  server: { bumpZone },
} = appState;

//[{dialogName, arguments, diff}]
const dialogActionGenerator = function* (dialogs) {
  for (const dialog of dialogs) {
    if (dialog.shouldShow) {
      yield setDialog({ dialog: dialog.name, ...dialog.args });
    }
  }
};

export default function Controller() {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const zoneName = useSelector(selectZoneName);
  const zoneId = useSelector(selectZoneId);
  const { dialog: dialogIsShowing } = useSelector(selectDialog);
  const relatedZones = useSelector(selectRelatedZones);
  const entityType = useSelector(selectEntityType);
  const jobOrderId = useSelector(selectJobOrderId);
  const relatedZonesStatus = useSelector(selectRelatedZonesStatus);
  const currentUser = useSelector(selectIdentity);
  const showAll = useSelector(selectShowAllFileInfos);
  const syncFilesOnly = useSelector(selectSyncFilesOnly);
  const runFlow = useSelector(selectRunFlow);
  const disregardedStatus = useSelector(selectDisregardedStatus);
  const [hasFetchedDisregarded, setHasFetchedDisregarded] = useState(false);
  const {
    ready: usersReady,
    added: addableUsers,
    removed: removableUsers,
  } = useUsersDiff();
  const {
    ready: filesReady,
    added: addableFiles,
    removed: removableFiles,
  } = useFilesDiff(
    entityType === "Job Posting" && !showAll ? groupFileInfos : null
  );
  const [generator, setGenerator] = useState(null);

  //HANDLES SELECTZONE AND CREATEZONE DIALOGS
  //via either dialog the zoneId will be set, allowing for the second useEffect to run
  useEffect(() => {
    if (zoneId || relatedZonesStatus !== "idle") return;
    const jobOrderZone = relatedZones.find(
      (zone) =>
        zone.jobOrderIds.some((_jobOrderId) => _jobOrderId === jobOrderId) &&
        zone.isMember
    );
    if (entityType === "Job Posting" && jobOrderZone) {
      dispatch(setSyncFilesOnly(true));
      dispatch(displayZone(jobOrderZone.id, true));
      dispatch(remote(bumpZone({ id: jobOrderZone.id })));
      dispatch(setZoneId(jobOrderZone.id));
    } else if (relatedZones.length) {
      dispatch(setDialog({ dialog: BH_SELECT_ZONE }));
    } else {
      dispatch(setDialog({ dialog: BH_CREATE_ZONE, zoneName }));
    }
  }, [relatedZonesStatus]);

  const fetchDisregardedAsync = async (zoneId) => {
    const accessToken = await dispatch(getToken());
    dispatch(fetchDisregarded({ zoneId, accessToken }));
    setHasFetchedDisregarded(true);
  };

  const newDataAvailable = (isAllowedUser) =>
    isAllowedUser &&
    (!!addableUsers.length ||
      !!removableUsers.length ||
      (!!jobOrderId && !!addableFiles.length));

  //SYNC DIALOGS
  useEffect(() => {
    if (zoneId && !hasFetchedDisregarded) {
      fetchDisregardedAsync(zoneId);
      return;
    }
    if (dialogIsShowing || !zoneId || !usersReady || !filesReady) {
      return;
    }
    if (!generator) {
      const virtualRootGroup = graph.zones[zoneId]?.virtualRootGroup;
      const users = getUsersFromGroup(graph, virtualRootGroup);
      const isAllowedUser = users.some(
        (user) => user.deprecatedId === currentUser.account
      );
      if (newDataAvailable(isAllowedUser)) {
        setGenerator(
          dialogActionGenerator([
            {
              name: BH_SYNC_ADDED_MEMBERS,
              args: { zoneName, zoneId },
              shouldShow:
                !!addableUsers.length && isAllowedUser && !syncFilesOnly,
            },
            {
              name: BH_SYNC_REMOVED_MEMBERS,
              args: { zoneName, zoneId },
              shouldShow:
                !!removableUsers.length && isAllowedUser && !syncFilesOnly,
            },
            {
              name: BH_SYNC_ADDED_FILES,
              args: { zoneName, zoneId },
              shouldShow:
                !!jobOrderId && !!addableFiles.length && isAllowedUser, //TODO, remove first part of boolean expr to allow clientCorp to sync added files
            },
            //TODO, uncomment to allow syncing of removed files
            // {
            //   name: BH_SYNC_REMOVED_FILES,
            //   args: { zoneName, zoneId },
            //   shouldShow: !!removableFiles.length,
            // },
          ])
        );
      } else if (runFlow) {
        alert("No new files or contacts to sync.");
      }
      dispatch(setRunFlow(false));
    } else {
      const { done, value: dialogAction } = generator.next();
      if (!done) {
        dispatch(dialogAction);
      } else {
        dispatch(setBullhornActive(false));
      }
    }
  }, [
    zoneId,
    dialogIsShowing,
    usersReady,
    filesReady,
    generator,
    runFlow,
    disregardedStatus,
  ]);

  return <BhDialogs />;
}
