import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from "react";

import { useSelector, useDispatch } from "react-redux";

import { Editor, EditorState, convertFromRaw } from "draft-js";

import { values, classNames } from "utils";

import appContext from "../../app/appContext.js";

import { selectUserURI } from "../../app/topSlice.js";

import { formatUser } from "../format/format.js";

import { openGroup, loadNext, selectMessages } from "./slice.js";

import { arrange } from "./derive.js";

import styles from "./feed.module.css";

import { appState } from "../../app/appState.js";

import { TEAM_MESSAGE_READ } from "../../app/permissions.js";

const RED = 0; //+new Date() - 3600_000 * 1;

const {
  selectors: { selectPermissions, selectUsers },
} = appState;

export default function (props) {
  const dispatch = useDispatch();
  const { groupId } = useContext(appContext);
  const uri = useSelector(selectUserURI).replace(/^[^;]*;/, "");
  const permissions = useSelector(selectPermissions);
  const messages = useSelector((state) => selectMessages(state)(groupId));
  const [load, setLoad] = useState(false);
  const [done, setDone] = useState(false);

  const observer = useRef(null);

  useEffect(() => {
    dispatch(openGroup(groupId));
  }, []);

  //const refAppear = useCallback(node => {
  //  observer.current?.disconnect();
  //  if (node) {
  //    const root = node.closest("[data-k-list]");
  //    observer.current = new IntersectionObserver(
  //      async entries => {
  //        if (0 < entries[0].intersectionRatio /* && !done*/) {
  //          setLoad(true);
  //          console.log("load next", groupId);
  //          const more = await dispatch(loadNext(groupId));
  //          if (!more) {
  //            setDone(true);
  //          }
  //          setLoad(false);
  //        }
  //      },
  //      { root }
  //    );
  //    observer.current.observe(node);
  //  }
  //}, []);

  const refAppear = (node) => {
    observer.current?.disconnect();
    if (node) {
      const root = node.closest("[data-k-list]");
      observer.current = new IntersectionObserver(
        async (entries) => {
          if (0 < entries[0].intersectionRatio /* && !done*/) {
            setLoad(true);
            const more = await dispatch(loadNext(groupId));
            if (!more) {
              setDone(true);
            }
            setLoad(false);
          }
        },
        { root }
      );
      observer.current.observe(node);
    }
  };

  //if (!uri) {
  //  return null;
  //}

  if (!permissions[TEAM_MESSAGE_READ]) {
    return (
      <div className={styles.feed}>
        <div className={styles.nonepermission}>
          You dont have the rights to read this chat
        </div>
      </div>
    );
  }

  const feed = arrange(values(messages), uri);

  return (
    <div className={styles.feed} data-k-list="1">
      <Feed messages={feed} />
      {!load && !done ? (
        <div ref={refAppear} className={styles.more} />
      ) : (
        <div className={styles.more}>
          {done && "This is the start of this conversation."}
        </div>
      )}
    </div>
  );
}

function Feed({ messages }) {
  return [...messages].reverse().map(({ type, id, ...message }, index) => {
    const { label, name, time, at } = message;
    const key = id
      ? id
      : type == "PERIOD"
      ? label
      : name + time + at + index + messages.length;
    switch (type) {
      //case "from":
      //  return <From key={key} {...message} />;
      case "IN":
        return <In key={key} {...message} />;
      case "OUT":
        return <Out key={key} {...message} />;
      case "DRAFT":
        return <Draft key={key} {...message} />;
      case "PERIOD":
        return <Period key={key} {...message} />;
      case "MONOLOG":
        return <Monolog key={key} {...message} />;
      case "TIMESTAMP":
        return <Timestamp key={key} {...message} />;
      default:
        throw new Error("this should not happend");
    }
  });
}

//function From({ from }) {
//  return <div className={classNames(styles.item, styles.from)}>{from}</div>;
//}

function In({ message: { draft, text } = {}, at }) {
  let red = RED && at < RED && styles.red;
  return (
    <div className={classNames(styles.item, styles.bubble, styles.in, red)}>
      {draft ? (
        <Editor
          readOnly={true}
          editorState={EditorState.createWithContent(convertFromRaw(draft))}
        />
      ) : (
        text
      )}
    </div>
  );
}

function Out({ message: { draft, text } = {}, at }) {
  let red = RED && at < RED && styles.red;
  return (
    <div className={classNames(styles.item, styles.bubble, styles.out, red)}>
      {draft ? (
        <Editor
          readOnly={true}
          editorState={EditorState.createWithContent(convertFromRaw(draft))}
        />
      ) : (
        text
      )}
    </div>
  );
}

function Draft({ message: { draft, text } = {} }) {
  const editorState = EditorState.createWithContent(convertFromRaw(draft));
  return (
    <div className={classNames(styles.item, styles.bubble, styles.draft)}>
      {draft ? (
        <Editor
          readOnly={true}
          editorState={EditorState.createWithContent(convertFromRaw(draft))}
        />
      ) : (
        text
      )}
    </div>
  );
}

function Period({ label }) {
  return (
    <div className={classNames(styles.item, styles.meta, styles.period)}>
      {label === "1970" ? "" : label}
    </div>
  );
}

function Monolog({ name, time }) {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const user = Object.values(users).find(
    ({ deprecatedId }) => deprecatedId === name
  ) || { deprecatedId: name };

  const [[primary, secondary], detailed] = dispatch(formatUser(user));
  return (
    <div
      className={classNames(styles.item, styles.meta, styles.monolog)}
      title={detailed}
    >
      <div>{time}</div>
      <div>{primary}</div>
      <div>{secondary}</div>
    </div>
  );
}

function Timestamp({ time }) {
  let year = 1970;
  try {
    year = new Date(time).getFullYear();
  } catch (ignore) {}
  return (
    <div className={classNames(styles.item, styles.meta, styles.timestamp)}>
      {year == 1970 ? "" : time}
    </div>
  );
}
