export default callback => () => {
  if (!callback) {
    return;
  }
  const listener = e => {
    if (e.key == "Enter") {
      callback(remove);
    }
  };
  const remove = () => document.removeEventListener("keydown", listener, false);
  document.addEventListener("keydown", listener, false);
  return remove;
};
