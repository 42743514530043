import PrivateKey from "./private_key";
import { agree } from "./";

export default class PrivateECDHKey extends PrivateKey {
  constructor(key) {
    super(key);
  }

  async agree(publicKey) {
    return agree(publicKey, this);
  }
}
