export default {
  getToken: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  getChannel: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  getContext: () => {
    throw "this sagas should never be called but rather overloaded";
  },
  remote: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  error: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  response: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  message: async () => {
    throw "this sagas should never be called but rather overloaded";
  },
  distribute: async () => {
    throw "this sagas should never be called but rather overloaded";
  }
  //intermediate: async () => {
  //  throw "this sagas should never be called but rather overloaded";
  //},
  //respond: async () => {
  //  throw "this sagas should never be called but rather overloaded";
  //},
  //hasPermission: async action => {
  //  return true;
  //},
  //authorize: async action => {
  //  throw "this sagas should never be called but rather overloaded";
  //},
};
