import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { classNames, request, jwtPeek, authReq } from "utils";
import { API_URL_PREFIX } from "../../config.js";
import appContext from "../../app/appContext";
import { getToken } from "../../app/topSlice.js";
import { useContextMenu } from "../contexts/Popover.js";
import { hidePane, PANE_ARCHIVED } from "../layout/layoutSlice";
import { startDownload } from "../transfer/transferSlice.js";

import { CUSTOM } from "../contexts/Contexts.js";
import { FILE_DOWNLOAD } from "../../app/permissions.js";

import { iconFactory, FolderIcon, ZipIcon } from "../../helpers/tree-icons";
import Size from "../tree/Size";
import { Icon, Menu, MenuItem } from "@blueprintjs/core";

import styles from "./Archived.module.css";
import treeStyles from "../tree/Tree.module.css";

export default () => {
  const dispatch = useDispatch();
  const context = useContext(appContext);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [disableDownload, setDisableDownload] = useState(false);

  const { zoneId } = context;

  useEffect(() => {
    if (zoneId) {
      const getDeletedFiles = async () => {
        const url = API_URL_PREFIX + `/archived?zoneId=${zoneId}`;
        const accessToken = await dispatch(getToken());
        const { user: userId } = jwtPeek(accessToken);
        const result = await request(
          url,
          authReq({
            accessToken,
            userId,
          })
        );
        result.sort((a, b) => {
          return !a.storageId === !b.storageId ? 0 : !a.storageId ? -1 : 1;
        });
        setDeletedFiles(result);
      };
      getDeletedFiles();
    }
  }, [zoneId]);

  const downloadFolder = async (item) => {
    setDisableDownload(true);
    try {
      if (item._bundled) {
        const bundleId = item._bundled;
        const params = `zoneId=${zoneId}&bundleId=${bundleId}`;
        const url = API_URL_PREFIX + `/archived/bundle/deep?` + params;
        const accessToken = await dispatch(getToken());
        const { user: userId } = jwtPeek(accessToken);
        const folderStructure = await request(
          url,
          authReq({
            accessToken,
            userId,
          })
        );
        if (folderStructure.id !== item.id) {
          console.log({ error: "Incorrect folder structure" });
          return;
        }
        const showPaneTransfer = false;
        const promise = dispatch(
          startDownload(
            item,
            undefined,
            undefined,
            folderStructure,
            showPaneTransfer
          )
        );
        await promise;
      }
    } finally {
      setDisableDownload(false);
    }
  };

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <div className={classNames(styles.header, styles.no_hover)}>
          <span>Restored Files</span>
        </div>
        <div
          className={styles.header}
          onClick={() => dispatch(hidePane(PANE_ARCHIVED))}
        >
          <Icon icon="cross" />
        </div>
      </div>
      <div className={classNames(treeStyles.scroll)}>
        {deletedFiles
          //.filter((node) => !!node.storageId)
          .map((node) => {
            return (
              <Item
                key={node.id}
                item={node}
                disableDownload={disableDownload}
                downloadFolder={downloadFolder}
              />
            );
          })}
      </div>
    </div>
  );
};

const Item = ({ item, disableDownload, downloadFolder }) => {
  item = {
    ...item,
    fileId: item.id,
    id: item.archiveId,
    name: item._bundleName || item.name,
  };

  const dispatch = useDispatch();

  const isFile = !!item.storageId;

  const doDownload = () => {
    if (!disableDownload) {
      if (isFile) {
        dispatch(startDownload(item, undefined, undefined, undefined, false));
      } else {
        downloadFolder(item);
      }
    }
  };

  const refFile = useContextMenu("RAW", {
    createComponent: () => (
      <Menu>
        <MenuItem
          key={`download-${item.id}`}
          icon="download"
          text="download"
          onClick={() => {
            doDownload();
          }}
        ></MenuItem>
      </Menu>
    ),
  });

  let icon = (
    <FolderIcon
      full={!item.empty}
      open={item.expanded}
      special={item.restricted}
    />
  );
  if (isFile) {
    icon = iconFactory(item.name);
  } else {
    icon = <ZipIcon />;
  }

  const date = new Date(item._deleted).toLocaleString("sv-SE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <div ref={refFile} className={classNames(treeStyles.wrapper)}>
      <div
        className={classNames(styles.row)}
        onDoubleClick={(e) => {
          e.stopPropagation();
          doDownload();
        }}
      >
        <div className={styles.item}>
          {icon}
          <span>{item.name}</span>
        </div>
        <div className={styles.item}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  );
};
