import {
  assign,
  clone,
  touch,
  remove,
  distinct,
  distinctEq,
  searchRemoveAll
} from "utils";

const DEFAULT_GROUP = {
  seq: 0,
  hsh: "",
  mem: [],
  res: []
};

export const initialState = {
  aut: ["174e04a1f46e41de965bcc1be7619d28", "cf3ac19368994a8c8941c9e2d94001d6"],
  pub: {},
  rel: {},
  grp: {},
  key: {}
};

export default {
  error: (state, { payload: action }) => {
    const { group, att, fatal, reason } = action;
    if (group && att && !fatal) {
      if (att == "fatal") {
        console.log("TODO is this ok?");
      }
      updateHashNSeq(state, action);
    } else if (group) {
      updateSeq(state, action);
    }
    console.log(`error: bad entry ${fatal ? "(fatal)" : ""} ${reason}`);
    console.log(action);
  },
  acceptState: (state, { payload: { grp, pub, rel } }) => {
    assign(state.grp, grp);
    assign(state.pub, pub);
    assign(state.rel, rel);
  },
  //acceptGlobal: (state, { payload: action }) => {
  //  assign(state, action.state);
  //},
  //acceptGroup: (state, { payload: action }) => {
  //  state.grp[action.group] = action.state;
  //  updateHashNSeq(state, { ...action, hash: action.hsh });
  //},
  publicKeys: (state, { payload: action }) => {
    state.pub[action.origin] = action.publicKeys;
  },
  keyShare: (state, { payload: action }) => {
    state.key[action.keyId] = action.symKey;
    updateSeq(state, action);
  },
  publishRelation: (state, { payload: action }) => {
    //touch(state.rel)(action.member)([]).push(action.device);
    distinct(touch(state.rel)(action.member)([]), action.device);
  },
  createGroup: (state, { payload: action }) => {
    state.grp[action.group] = clone(DEFAULT_GROUP);
    state.grp[action.group].mem = [action.origin];
    updateHashNSeq(state, action);
  },
  addMember: (state, { payload: action }) => {
    updateHashNSeq(state, action);
    //state.grp[action.group]?.mem.push(action.member);
    state.grp[action.group] && state.grp[action.group].mem.push(action.member);
  },
  removeMember: (state, { payload: action }) => {
    // backwards compability
    if (action.prev) {
      updateHashNSeq(state, action);
    } else {
      updateSeq(state, action);
    }
    //remove(state.grp[action.group]?.mem || [], action.member);
    remove(
      (state.grp[action.group] && state.grp[action.group].mem) || [],
      action.member
    );
  },
  shareRes: (state, { payload: action }) => {
    updateHashNSeq(state, action);
    const { group, keyId, checksum, salt } = action;
    const { res = [] } = state.grp[group] || {};
    const item = [keyId, checksum, salt];
    const found = res.find(([a, b]) => a == keyId && b == checksum);
    if (!found) {
      res.push(item);
    }
  },
  removeRes: (state, { payload: action }) => {
    // updateHashNSeq(state, action);
    // backwards compability
    if (action.prev) {
      updateHashNSeq(state, action);
    } else {
      updateSeq(state, action);
    }
    const { group, keyId, checksum, salt } = action;
    const { res = [] } = state.grp[group] || {};
    const index = res.findIndex(([id, sum]) => id == keyId && sum == checksum);
    if (index != -1) {
      res.splice(index, 1);
    }
  }
};

const updateHashNSeq = (state, action) => {
  updateSeq(state, action);
  updateHash(state, action);
};
const updateSeq = (state, action) => {
  try {
    // legacy
    if (action && action.group && ("id" in action || "seq" in action)) {
      if (!(action.group in state.grp)) {
        state.grp[action.group] = clone(DEFAULT_GROUP);
      }
      state.grp[action.group].seq = action.id;
      if ("seq" in action) {
        // legacy
        state.grp[action.group].seq = action.seq;
      }
    }
  } catch (e) {
    console.log(e);
  }
};
const updateHash = (state, action) => {
  try {
    state.grp[action.group].hsh = action.hash;
  } catch (e) {
    console.log(e);
  }
};
