import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {} from "./footerSlice";
import styles from "./Footer.module.css";

export function Footer() {
  const dispatch = useDispatch();

  return <div>Footer</div>;
}
