import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {} from "@blueprintjs/core";
import {
  classNames,
  snakeToCamel,
  detach,
  getPassword,
  debounce,
  toStringSorted
} from "utils";
import appContext from "../../app/appContext.js";
import { selectIdentity } from "../../app/topSlice.js";

import { waitFor, observe } from "../../app/appSlice.js";

import {} from "./debugSlice";
import styles from "./Debug.module.css";

//import { loadGroup, getGroupLog, getGoodybag } from "trust-kit";

import { CryptoClient, Data } from "hyker-crypto"; //"riks-js";

import { trustKit } from "../../app/trustKit.js";

import { appState } from "../../app/appState.js";

const { actions: { loadGroup, getGroupLog, getGoodybag } } = trustKit;

const {
  derive: { getGroupsFromZone, getGroupsFromCurrentZone },
  selectors: { selectGraph, selectGroups }
} = appState;

export const Debug = () => {
  const dispatch = useDispatch();
  const [tabState, setTab] = useState({ tab: "HOME" });
  const state = useSelector(state => state);
  const context = useContext(appContext);
  const identity = useSelector(selectIdentity);

  const { zoneId } = context;
  const { tab, group } = tabState;
  //const groups = useSelector(selectGroups)(zoneId);
  const groups = getGroupsFromZone(useSelector(selectGraph), zoneId);

  if (group && !groups.some(({ id }) => id == group)) {
    detach(() => setTab({ tab }));
  }

  const doSomething1 = async () => {
    //const groupId = "xxx";
    //const theManager = await dispatch(manager());
    //const group = await theManager.getGroup(groupId);
    //const members = group.getMembers();
    //await group.addUmbrella("email:jonas@hyker.io");
  };

  const doSomething2 = async () => {
    const pub = "";
    const data = "";
    const sign = "";
    console.log(
      "ok?",
      await new CryptoClient().verify(pub, Data.fromBase64URL(data), sign)
    );
  };

  if (!identity) {
    return <div>nog logged in</div>;
  }

  return (
    <div className={styles.outer}>
      <div className={styles.tabs}>
        {[
          "HOME",
          "CONTEXT_INSPECT",
          "GRAPH_STATE",
          "TRUST_INSPECT",
          "TRUST_STATE"
          //"TRUST_EXPLOR",
          //"GOODYBAG"
        ].map(tab => (
          <div key={tab} className={styles.tab} onClick={() => setTab({ tab })}>
            {tab.replace(/_/g, " ")}
          </div>
        ))}
      </div>
      <div className={classNames(styles.content, styles[snakeToCamel(tab)])}>
        {(tab == "HOME" && <pre>Diagnostics Tool</pre>) ||
          (tab == "CONTEXT_INSPECT" && (
            <pre>{JSON.stringify(context, null, 2)}</pre>
          )) ||
          (tab == "GRAPH_STATE" && (
            <pre>{toStringSorted(selectGraph(state), true)}</pre>
          )) ||
          (tab == "TRUST_INSPECT" && (
            <TrustInspect {...{ tabState, setTab }} />
          )) ||
          (tab == "TRUST_STATE" && (
            <pre>{JSON.stringify(state.trust, null, 2)}</pre>
          ))
        //||
        //(tab == "TRUST_EXPLOR" && (
        //  <TrustExplor {...{ tabState, setTab }} />
        //)) ||
        //(tab == "GOODYBAG" && <Goodybag {...{ tabState, setTab }} />)
        }
      </div>
    </div>
  );
};

const debounced = debounce(f => f(), 1000);

//const dispatchDebounce = debounce(f => (), 333);

export const TrustInspect = ({ tabState, setTab }) => {
  const dispatch = useDispatch();
  const { isRestricted, rootGroupId, virtualRootGroupId } = useContext(
    appContext
  );
  const { device } = useSelector(selectIdentity);
  const state = useSelector(state => state);
  const groups = getGroupsFromCurrentZone(useSelector(selectGraph));

  const { group, log } = tabState;

  const seq = useSelector(state => state.trust.grp[group]?.seq);

  const loadLog = async () => {
    setTab({ ...tabState, log: [] });
    const log = (await dispatch(getGroupLog(group))).filter(row => row.entry);
    setTab({ ...tabState, log });
  };

  //debounced(() => dispatch(waitFor(state => seq != state.sdk.grp[group]?.seq)).then(doIt))

  useEffect(() => {
    const select = state => state.trust.grp[group]?.seq;
    const change = () => debounced(loadLog);
    return dispatch(observe({ select, change })).remove;
  });

  if (group) {
    if (!log) {
      dispatch(loadGroup(group));
      detach(loadLog);
    }
    return (
      <div>
        {state.trust.grp[group] ? (
          <pre>{JSON.stringify(state.trust.grp[group], null, 2)}</pre>
        ) : (
          <div>group not present in trust log</div>
        )}
        <LogView log={log} />
        <LogRaw log={log} />
      </div>
    );
  }

  return (
    <div>
      {
        <div className={styles.spec}>
          restricted zone: {isRestricted ? "yes" : "no"}
        </div>
      }
      {groups.map(group => (
        <div
          key={group.id}
          className={styles.group}
          onClick={() => setTab({ ...tabState, group: group.id })}
        >
          <div>
            <div>{group.id}</div>
            <div>root node: {group.rootFile}</div>
          </div>
          <div>
            {group.id == rootGroupId && <div>root group</div>}
            {group.id == virtualRootGroupId && <div>virtual root</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export const TrustExplor = ({ tabState, setTab }) => {
  const dispatch = useDispatch();
  const { group, log } = tabState;
  const doIt = async () => {
    const log = await dispatch(getGroupLog(group));
    setTab({ ...tabState, log: log || [] });
  };
  return (
    <div>
      <input type="text" placeholder="org" />
      <input type="text" placeholder="topics" />
      <LogView log={log} />
    </div>
  );
};

export const LogView = ({ log }) =>
  log ? (
    <div className={styles.table}>
      <div>type</div>
      <div>subject</div>
      <div>object</div>
      <div />
      {log.map(
        ({ entry: { type, origin, member, device, keyId, hash } }, index) => (
          <Fragment key={index}>
            <div>{type}</div>
            <div>{origin}</div>
            <div>{device || member || keyId || origin}</div>
            <div>{}</div>
          </Fragment>
        )
      )}
    </div>
  ) : (
    ""
  );

export const LogRaw = ({ log = null }) =>
  log && (
    <div className={styles.detailed}>
      {log.map(({ entry }, index) => (
        <pre key={index}>{JSON.stringify(entry, null, 2)}</pre>
      ))}
    </div>
  );

export const Goodybag = ({ tabState, setTab }) => {
  const dispatch = useDispatch();
  const { org, lines } = tabState;
  const [value, setValue] = useState("");
  const doIt = async () => {
    const password = await getPassword();
    const { lines } = await dispatch(getGoodybag(value, password));

    setTab({ ...tabState, lines });
  };
  return (
    <div className={styles.topics}>
      <input
        type="text"
        placeholder="org"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyPress={e => e.key == "Enter" && doIt()}
      />
      {lines && <textarea value={lines} onChange={() => {}} />}
    </div>
  );
};
