import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setShowEditor } from "./editorSlice";

import styles from "./Editor.module.css";

import { SplashDoc, SplashSlides, SplashSheets } from "./editors-splash-screen";

import createEditor from "../../alone";

export function Editor() {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("editor was added!");
    return () => {
      console.log("editor was remvoed");
    };
  }, []);
  let blob = window.__edit_file;
  let getContent;
  //return <SplashDoc/>
  return (
    <div
      className={styles.outer}
      ref={el => {
        if (
          el &&
          (!el.editorItem || el.editorItem.id !== this.props.editorItem.id)
        ) {
          //let blob = undefined//this.props.editorItem.blob;
          let fileName = "jonas.docx"; //this.props.editorItem.data.title;

          let type = "";
          if (fileName.endsWith(".docx")) {
            type = "oodoc";
          } else if (fileName.endsWith(".xlsx")) {
            type = "sheet";
          } else if (fileName.endsWith(".pptx")) {
            type = "ooslide";
          } else {
            alert("please try again");
            return;
          }

          el.editorItem = undefined; //this.props.editorItem;
          const bar = document.querySelector("#k-splash-progress-bar") || {
            style: {}
          };
          bar.style.width = "10%";
          let interval = setInterval(() => {
            const percent = Math.min(100, +bar.style.width.slice(0, -1) + 1);
            bar.style.width = percent + "%";
          }, 1000);
          getContent = createEditor(
            el,
            type,
            fileName,
            blob,
            progress => {
              const percent = Math.round(100 * progress);
              bar.style.width = percent + "%";
            },
            () => {
              clearInterval(interval);
              bar.style.transition = "width 500ms";
              bar.style.width = 100 + "%";
              setTimeout(() => {
                console.log("TODO this");
                //this.props.dispatch({
                //  type: "SET_EDITOR_ITEM",
                //  editorItem: {
                //    ...this.props.editorItem,
                //    loading: false
                //  }
                //});
              }, 500);
            }
          );
        }
      }}
    />
  );
}
