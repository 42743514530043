import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import store from "../../app/store.js";
import styles from "./Activity.module.css";
import Settings from "./Settings";
import FilteredActivity from "./FilteredActivity";
import { A, ActionDescriptions } from "psm";
import { values, classNames } from "utils";
import { Spinner, Icon } from "@blueprintjs/core";
import { ZONE } from "../../app/dictionary.js";
import { setOverlay } from "../../app/appSlice.js";
import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays.js";
import { useExpandNodesFromPath, getParents } from "../search/Search.js";
import { getToken } from "../../app/topSlice.js";

import {
  //selectCanLoadMore,
  selectNotifications,
  selectStickyNotifications,
  loadNotifications,
  loadStickyNotifications,
} from "../../app/appSlice";

import {
  PANE_DOCS,
  PANE_CHAT,
  PANE_USERS,
  PANE_USER,
  PANE_FILE,
  showPane,
} from "../layout/layoutSlice.js";

import { appState } from "../../app/appState.js";
import appContext from "../../app/appContext.js";

const {
  actions: { error, remoteSync, loadZone, setCurrentZone, setCurrentNode },
  server: { /*fetchZoneFlat,*/ fetchNode },
  selectors: { selectGraph, selectNodes },
  selectors: { selectCanLoadMore },
} = appState;

const { getState } = store;

const COLOR_CHANGE = "#009688";
const COLOR_CREATE = "#4CAF50";
const COLOR_DESTROY = "#f44336";
const COLOR_CONSUME = "#673AB7";
const COLOR_PRODUCE = "#9C27B0";
const COLOR_UPLOAD = "#4CAF50";
const COLOR_DOWNLOAD = "#00ACC1";

export const Activity = () => {
  const context = useContext(appContext);
  const dispatch = useDispatch();
  const canLoadMore = useSelector(selectCanLoadMore);
  const [query, setQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [filterOnTeam, setFilterOnTeam] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const expandPath = useExpandNodesFromPath();

  useEffect(() => {
    dispatch(loadStickyNotifications());
  }, []);

  const onSelect = async (tab, event) => {
    if (!tab) {
      return; //dont do anything (Workspace Scoped Events)
    } else if (typeof event.target === "string") {
      //events begining with LOG_WORKSPACE_ or LOG_TEAM_CREATE
    } else if ("team" in event.target) {
      //await dispatch(remoteSync(fetchZoneFlat({ id: event.target.team.id })));
      await dispatch(loadZone({ id: event.target.team.id }));
      await dispatch(setCurrentZone(event.target.team.id));
    }
    if (event.meta?.file) {
      const nodeId = event.meta.file;
      await dispatch(
        remoteSync(fetchNode({ id: nodeId, zoneId: event.target.team.id }))
      );

      if (!(nodeId in selectNodes(getState()))) {
        dispatch(error("The file is no longer around"));
        return;
      }

      if (
        event.type === A.LOG_ESIGNATURE_REQUEST ||
        event.type === A.LOG_ESIGNATURE_APPROVE ||
        event.type === A.LOG_ESIGNATURE_REJECT ||
        /* event.type === A.LOG_ESIGNATURE_COMPLETED || */
        event.type === A.LOG_ATTESTATION_REQUEST ||
        event.type === A.LOG_ATTESTATION_APPROVE ||
        event.type === A.LOG_ATTESTATION_REJECT
      ) {
        dispatch(
          setOverlay({
            overlay: OVERLAY_PREVIEW,
            nodeId,
            zoneId: event.target.team.id,
          })
        );
      }

      const accessToken = await dispatch(getToken());
      const parents = await getParents(
        accessToken,
        event.target.team.id,
        nodeId
      );
      expandPath(parents);
    }

    await dispatch(showPane(tab));
  };

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        {showSettings ? (
          <div className={styles.header}>
            <span>Settings</span>
          </div>
        ) : (
          <div
            className={styles.header}
            onClick={() => setFilterOnTeam(!filterOnTeam)}
          >
            <span>Activity</span>
            {filterOnTeam ? <span>{ZONE} Filter</span> : <span>No Filter</span>}
            {filterOnTeam ? (
              <Icon icon="filter-remove" />
            ) : (
              <Icon icon="filter-keep" />
            )}
          </div>
        )}
        <div
          className={styles.header}
          onClick={() => setShowSearch(!showSearch)}
        >
          {/*showSettings ? null : <Icon icon="search" />*/}
        </div>
        <div
          className={styles.header}
          onClick={() => setShowSettings(!showSettings)}
        >
          {showSettings ? <Icon icon="cross" /> : <Icon icon="cog" />}
        </div>
      </div>
      {showSettings ? (
        <Settings />
      ) : (
        <>
          {showSearch && (
            <div className={styles.rest}>
              <div className={styles.search}>
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                {isSearching ? <Spinner size={18} /> : <Icon icon="search" />}
                {query && query.length && (
                  <div onClick={() => setQuery("")}>
                    <Icon icon="cross" />
                  </div>
                )}
              </div>
            </div>
          )}
          {query ? (
            <>
              <FilteredActivity
                filterOnTeam={filterOnTeam}
                searchTerm={query}
                onSelect={onSelect}
                onSearch={() => setIsSearching(true)}
                onSearched={() => setIsSearching(false)}
              />
            </>
          ) : (
            <EventList
              filterOnTeam={filterOnTeam}
              onLoadMore={() => {
                const { zoneId } = context;
                dispatch(
                  loadNotifications({
                    ...(filterOnTeam && { zoneId }),
                    more: true,
                  })
                );
              }}
              showLoadMore={canLoadMore}
              onSelect={onSelect}
            />
          )}
        </>
      )}
    </div>
  );
};

const EventList = ({ filterOnTeam, onLoadMore, showLoadMore, onSelect }) => {
  const graph = useSelector(selectGraph);
  const context = useContext(appContext);
  const { zoneId } = context;
  const dispatch = useDispatch();
  const stickyNotifications = useSelector(selectStickyNotifications);
  const notifications = useSelector(selectNotifications);
  const compareTime = (a, b) => b.time - a.time;
  const sortedNotifications = values(notifications).sort(compareTime);
  const sortedStickyNotifications =
    values(stickyNotifications).sort(compareTime);

  const parseList = (list) => {
    return list.map((event) => ({
      originalEvent: event,
      id: event.id,
      ...parseEvent(event, graph),
    }));
  };

  const styleStickyEvent = (event) => ({
    ...event,
    style: styles.activitySticky,
  });
  const isEventNotStickyFilter = (event) =>
    !sortedStickyNotifications.some(({ id }) => id === event.id);
  const nonstickyEvents = parseList(sortedNotifications).filter(
    isEventNotStickyFilter
  );
  const stickyEvents = parseList(sortedStickyNotifications).map(
    styleStickyEvent
  );
  const events = stickyEvents
    .concat(nonstickyEvents)
    .filter(({ originalEvent }) => {
      if (filterOnTeam) {
        if (originalEvent.target.team?.id === zoneId) {
          return true;
        }
        if (
          originalEvent.type === A.LOG_TEAM_CREATE &&
          originalEvent.meta.team?.id === zoneId
        ) {
          return true;
        }
        return false;
      }
      return true;
    });

  useEffect(() => {
    if (zoneId) {
      dispatch(loadNotifications({ ...(filterOnTeam && { zoneId }) }));
    }
  }, [filterOnTeam, zoneId]);

  return (
    <div className={styles.activityList}>
      {events.map(
        ({
          id,
          icon,
          color,
          tab,
          h1,
          h2,
          h3,
          who,
          when,
          where,
          style,
          originalEvent,
        }) => (
          <div
            key={id}
            className={styles.activityEntryContent}
            onClick={() => onSelect(tab, originalEvent)}
          >
            <div className={styles.activityEntryIcon}>
              <div
                className={classNames("squircle", styles.squircle)}
                style={{ background: color }}
              >
                <Icon icon={icon} iconSize={20} />
              </div>
            </div>
            <div className={styles.activityEntryText}>
              {h1 && <h1>{h1}</h1>}
              {h2 && <h2>{h2}</h2>}
              {h3 && <h3>{h3}</h3>}
            </div>
            <div className={styles.activityEntryMeta}>
              <p className={styles.activityEntryWhen}>
                {Moment(when, "x").fromNow()}
              </p>
              <p className={styles.activityEntryWho}>{who}</p>
              <p className={styles.activityEntryWhere}>{where}</p>
            </div>
          </div>
        )
      )}
      {showLoadMore ? (
        <div className={styles.activityMore} onClick={onLoadMore}>
          Load More
        </div>
      ) : (
        <div className={styles.activityDone}>END</div>
      )}
    </div>
  );
};

export const parseEvent = ({ type, time, meta, origin, target }, graph) => {
  switch (type) {
    case A.LOG_ATTESTATION_REQUEST:
      const numberOfReviewers =
        meta?.file &&
        meta.attestation &&
        graph.nodes[meta.file]?.attestations?.find(
          (att) => att.id == meta.attestation
        )?.signees?.length;
      return {
        icon: "endorsed",
        color: "#FF9800",
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: `Reviewer${numberOfReviewers > 1 ? "s" : ""}: ${formatUser(
          meta.to
        )}${numberOfReviewers > 1 ? ` and ${numberOfReviewers - 1} more` : ""}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_ATTESTATION_REJECT:
      return {
        icon: "thumbs-down",
        color: COLOR_DESTROY,
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: `From: ${meta.from}`,
        h3: `To: ${formatUser(meta.to)}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_ATTESTATION_APPROVE:
      return {
        icon: "thumbs-up",
        color: COLOR_CREATE,
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: `Reviewer: ${formatUser(meta.to)}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_ESIGNATURE_REQUEST:
      return {
        icon: "edit",
        color: "#FF9800",
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: `Reviewers: ${formatUser(meta.to)}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_ESIGNATURE_REJECT:
      return {
        icon: "ban-circle",
        color: COLOR_DESTROY,
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: `From: ${meta.from}`,
        h3: `Canceled by: ${formatUser(meta.to)}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_ESIGNATURE_APPROVE:
      return {
        icon: "tick-circle",
        color: COLOR_CREATE,
        tab: PANE_FILE,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: `Reviewer: ${formatUser(meta.to)}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_READ:
      return {
        icon: "cloud-download",
        color: COLOR_CONSUME,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_DOWNLOAD:
      return {
        icon: "download",
        color: COLOR_DOWNLOAD,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_PREVIEW:
      return {
        icon: "eye-open",
        color: COLOR_CONSUME,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_CREATE:
      return {
        icon: "upload",
        color: COLOR_UPLOAD,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.to,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_DELETE:
      return {
        icon: "cross",
        color: COLOR_DESTROY,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_MOVE:
      return {
        icon: "rotate-document",
        color: COLOR_CHANGE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: `From: ${meta.from}`,
        h3: `To: ${meta.to}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FILE_UPDATE:
      return {
        icon: "rotate-document",
        color: COLOR_CHANGE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.fileName,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FOLDER_CREATE:
      return {
        icon: "folder-shared-open",
        color: COLOR_PRODUCE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.to,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FOLDER_DELETE:
      return {
        icon: "cross",
        color: COLOR_DESTROY,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.from,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FOLDER_MOVE:
      return {
        icon: "rotate-page",
        color: COLOR_CHANGE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: `From: ${meta.from}`,
        h3: `To: ${meta.to}`,
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FOLDER_RESTRICT:
      return {
        icon: "lock",
        color: COLOR_CHANGE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.to,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_FOLDER_UNRESTRICT:
      return {
        icon: "unlock",
        color: COLOR_CREATE,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: meta.to,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_MESSAGE_CREATE:
      return {
        icon: "chat",
        color: COLOR_CREATE,
        tab: PANE_CHAT,
        h1: ActionDescriptions[type],
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_TEAM_RENAME:
      return {
        icon: "edit",
        color: COLOR_CHANGE,
        tab: null,
        h1: ActionDescriptions[type],
        h2: `From: ${meta.from}`,
        h3: `To: ${meta.to}`,
        who: formatUser(origin),
        when: time,
        where: "", //formatGroup(target)
      };
    case A.LOG_TEAM_EXPIRE:
      return {
        icon: "outdated",
        color: COLOR_DESTROY,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: "Files will be deleted at:",
        h3: Moment(new Date(meta.expirationDate), "x").format(
          "MMMM Do YYYY, h:mm:ss a"
        ),
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_TEAM_DID_EXPIRE:
      return {
        icon: "trash",
        color: COLOR_DESTROY,
        tab: PANE_DOCS,
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_TEAM_TEAMMATE_REMOVE:
      return {
        icon: "blocked-person",
        color: COLOR_DESTROY,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: formatUser(meta.from),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_TEAM_TEAMMATE_ADD:
      return {
        icon: "following",
        color: COLOR_PRODUCE,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: formatUser(meta.to),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_GROUP_TEAMMATE_REMOVE:
      return {
        icon: "blocked-person",
        color: COLOR_DESTROY,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: formatUser(meta.from),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_GROUP_TEAMMATE_ADD:
      return {
        icon: "following",
        color: COLOR_CREATE,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: formatUser(meta.to),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatGroup(target),
      };
    case A.LOG_WORKSPACE_UPLOAD_LOGO:
      return {
        icon: "style",
        color: COLOR_CHANGE,
        tab: null,
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatOrg(target),
      };
    case A.LOG_WORKSPACE_ROLE_ASSIGN:
      return {
        icon: "walk",
        color: COLOR_CHANGE,
        tab: {
          user: { ...meta.user, role: { id: meta.user.role } },
          ...PANE_USER,
        },
        h1: ActionDescriptions[type],
        h2: formatRole(meta.to),
        h3: formatUser(meta.user),
        who: formatUser(origin),
        when: time,
        where: formatOrg(target),
      };
    case A.LOG_TEAM_CREATE:
      return {
        icon: "people",
        color: COLOR_PRODUCE,
        tab: null,
        h1:
          meta && meta.isTemplate
            ? "Template Created"
            : ActionDescriptions[type],
        h2: meta.to,
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatOrg(target),
      };
    case A.LOG_WORKSPACE_TEAMMATE_INVITE:
      return {
        icon: "envelope",
        color: COLOR_PRODUCE,
        tab: { user: { ...meta.to, role: { id: meta.to.role } }, ...PANE_USER },
        h1: ActionDescriptions[type],
        h2: formatUser(meta.to),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatOrg(target),
      };
    case A.LOG_TEAM_RESTRICT:
      return {
        icon: "lock",
        color: COLOR_CHANGE,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatTeam(target),
      };
    case A.LOG_TEAM_UNRESTRICT:
      return {
        icon: "unlock",
        color: COLOR_CREATE,
        tab: PANE_USERS,
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: formatUser(origin),
        when: time,
        where: formatTeam(target),
      };
    case A.LOG_WORKSPACE_TEAMMATE_REMOVE:
      return {
        icon: "trash",
        color: COLOR_DESTROY,
        tab: null,
        h1: ActionDescriptions[type],
        h2: formatUser(meta.from),
        h3: "",
        who: formatUser(origin),
        when: time,
        where: target,
      };
    case A.LOG_WORKSPACE_DELETE_LOGS:
    case A.LOG_WORKSPACE_EDIT_LOGS:
    case A.LOG_WORKSPACE_EXPORT_LOGS:
    case A.LOG_WORKSPACE_EXPORT_REPORTS:
    case A.LOG_WORKSPACE_LIST_TEAMS:
    case A.LOG_WORKSPACE_ROLE_ADD:
    case "X":
      return {
        icon: "blank",
        color: "#FF7043",
        tab: "",
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: "",
        when: 0,
        where: "",
      };
    default:
      return {
        icon: "add",
        color: COLOR_CREATE,
        tab: "",
        h1: ActionDescriptions[type],
        h2: "",
        h3: "",
        who: "",
        when: 0,
        where: "",
      };
  }
};

const formatUser = (user) => {
  return user.name || "BAD USER NAME";
};

const formatGroup = (group) => {
  return (group.team || {}).name || "BAD GROUP NAME";
};

const formatTeam = (team) => {
  return team.name || "BAD TEAM NAME";
};

const formatOrg = (orgId) => " ";

const formatRole = (roleIdOrRole) => {
  return roleIdOrRole.name || "BAD ROLE";
};
