import PublicKey from "./public_key";

export default class PublicECDSAKey extends PublicKey {
  constructor(key) {
    super(key);
  }

  async verify(data, signature) {
    throw new Error("Not implemented.");
  }
}
