import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import styles from "./Activity.module.css";
import { classNames } from "utils";
import { getToken } from "../../app/topSlice.js";
import { jwtPeek, request, authReq } from "utils";

import { Icon } from "@blueprintjs/core";

import { appState } from "../../app/appState.js";
import { parseEvent } from "./Activity";
import { API_URL_PREFIX_NOTIFY } from "../../config";
import appContext from "../../app/appContext.js";

const { selectors: { selectGraph } } = appState;

export default ({
  onSelect,
  onSearch,
  onSearched,
  filterOnTeam,
  searchTerm = ""
}) => {
  const [events, setEvents] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [debounce, setDebounce] = useState(null);
  const graph = useSelector(selectGraph);
  const dispatch = useDispatch();
  const context = useContext(appContext);
  const { zoneId } = context;

  const loadMoreEvents = async (events = []) => {
    onSearch();
    const accessToken = await dispatch(getToken());
    const { user: userId } = jwtPeek(accessToken);
    const numberOfEventsToLoad = 20;
    const start = events.length;
    const end = events.length + numberOfEventsToLoad;
    const url = new URL(API_URL_PREFIX_NOTIFY);
    url.pathname = "/events/teams/fuzzysearch";
    url.searchParams.append("userId", userId);
    url.searchParams.append("start", start);
    url.searchParams.append("end", end);
    url.searchParams.append("q", searchTerm);
    if (filterOnTeam) {
      url.searchParams.append("zone", zoneId);
    }
    const newEvents = await request(url.href, authReq({ accessToken, userId }));
    setEvents(events.concat(newEvents));
    setShowLoadMore(newEvents.length === numberOfEventsToLoad);
    onSearched();
  };

  const debounceEventLoad = async () => {
    if (debounce !== null) clearTimeout(debounce);
    setDebounce(setTimeout(loadMoreEvents, 300));
  };

  useEffect(
    () => {
      if (filterOnTeam && !zoneId) return;
      setShowLoadMore(true);
      debounceEventLoad();
    },
    [searchTerm, zoneId, filterOnTeam]
  );

  const onLoadMore = () => loadMoreEvents(events);

  const parsedEvents = events.map(event => ({
    originalEvent: event,
    id: event.id,
    ...parseEvent(event, graph)
  }));

  return (
    <div className={styles.activityList}>
      {parsedEvents.map(
        ({
          id,
          icon,
          color,
          tab,
          h1,
          h2,
          h3,
          who,
          when,
          where,
          style,
          originalEvent
        }) => (
          <div
            key={id}
            className={styles.activityEntryContent}
            onClick={() => onSelect(tab, originalEvent)}
          >
            <div className={styles.activityEntryIcon}>
              <div
                className={classNames("squircle", styles.squircle)}
                style={{ background: color }}
              >
                <Icon icon={icon} iconSize={20} />
              </div>
            </div>
            <div className={styles.activityEntryText}>
              {h1 && <h1>{h1}</h1>}
              {h2 && <h2>{h2}</h2>}
              {h3 && <h3>{h3}</h3>}
            </div>
            <div className={styles.activityEntryMeta}>
              <p className={styles.activityEntryWhen}>
                {Moment(when, "x").fromNow()}
              </p>
              <p className={styles.activityEntryWho}>{who}</p>
              <p className={styles.activityEntryWhere}>{where}</p>
            </div>
          </div>
        )
      )}
      {showLoadMore ? (
        <div className={styles.activityMore} onClick={onLoadMore}>
          Load More
        </div>
      ) : (
        <div className={styles.activityDone}>END</div>
      )}
    </div>
  );
};
