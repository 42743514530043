import { Data } from "hyker-crypto";

import {
  entries,
  pick,
  pause,
  snakeToCamel,
  hash as hshf,
  jwtPeek,
  json,
  request,
  createJsonChecksum
} from "utils";

export const VERSION = "v13";

export const align = entry =>
  entries(
    pick(
      entry,
      "type",
      "prev",
      "pos",
      "origin",
      "device",
      "group",
      "member",
      "keyId",
      "checksum",
      "salt"
    )
  )
    .map(([k, v]) => k + v)
    .sort()
    .join("");

export default module => ({
  align,
  request: (path, data = null) => async (dispatch, getState) => {
    const { config: { API_URL_PREFIX }, actions: { getToken } } = module;
    const accessToken = await dispatch(getToken());
    const { org, userId } = jwtPeek(accessToken);
    const namespace = await hshf(org);
    const url = `${API_URL_PREFIX}/stream/${VERSION}/sdk/${path}`;
    const options = { accessToken, userId };
    if (data) {
      data.ns = namespace;
    } else {
      data = undefined;
      options.method = "get";
    }
    const result = await request(url, json(data, options));
    if (result && (result.error || ("success" in result && !result.success))) {
      throw new Error(result.error || "Unknown error.");
    }
    return result;
  },
  lockSymKey: (publicKey, keyId, symKey) => async dispatch => {
    const { actions: { getContext } } = module;
    const { cryptoClient, credentials } = await dispatch(getContext());
    const { deviceId, privateECDHKey, privateECDSAKey } = credentials;
    return (await cryptoClient.wrapKey(
      keyId,
      symKey,
      deviceId,
      publicKey,
      privateECDHKey,
      privateECDSAKey
    )).secKey;
  },
  unlockSecKey: (publicKeys, keyId, secKey) => async dispatch => {
    const { actions: { getContext } } = module;
    const { cryptoClient, credentials } = await dispatch(getContext());
    const [publicECDHKey, publicECDSAKey] = publicKeys;
    const { privateECDHKey } = credentials;
    return (await cryptoClient.unwrapKey(
      keyId,
      secKey,
      publicECDHKey,
      publicECDSAKey,
      privateECDHKey
    )).symKey;
  },
  fetchReverse: deviceId => async dispatch => {
    const { actions: { getContext } } = module;
    const { channel } = await dispatch(getContext());
    const result = await channel.fetch(`rel/${deviceId}`);
    if (!result) {
      throw new Error("TODO ERROR no such reverse");
    }
    return result.entry || {};
  },
  fetchPublicKeys: originId => async dispatch => {
    const { actions: { getContext } } = module;
    const { cryptoClient: cc } = await dispatch(getContext());
    try {
      const { publicECDHKey, publicECDSAKey } = await cc.publicLookup(originId);
      return [publicECDHKey, publicECDSAKey];
    } catch (e) {
      console.log(e);
    }
    throw new Error("TODO public key not found for " + originId);
  },
  fetchSymKey: (originId, keyId) => async dispatch => {
    const { auxiliary: { request, unlockSecKey, cachePublicKeys } } = module;
    const uniKey = await hshf(originId + keyId);
    //const { entry } = (await channel.fetch(`sk/${uniKey}`)) || {};
    const doc = await dispatch(request(`fetch/sk/${uniKey}`));
    if (doc?.entry?.keyId != keyId) {
      throw new Error("TODO ERROR no access to the key");
    }
    const publicKeys = await dispatch(cachePublicKeys(doc.entry.origin));
    return await dispatch(unlockSecKey(publicKeys, keyId, doc.entry.secKey));
  },
  cachePublicKeys: originId => async (dispatch, getState) => {
    const {
      selectors: { selectScope, selectPublicKeys },
      actions: { getContext, publicKeys },
      auxiliary: { fetchPublicKeys }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.cachePublicKeys.acquire();
    try {
      let keys = selectPublicKeys(selectScope(getState()))(originId);
      if (!keys) {
        keys = await dispatch(fetchPublicKeys(originId));
        dispatch(publicKeys({ origin: originId, publicKeys: keys }));
      }
      return keys;
    } finally {
      release();
    }
  },
  cacheSymKey: (originId, keyId) => async (dispatch, getState) => {
    const {
      selectors: { selectScope, selectKey },
      actions: { getContext, keyShare },
      auxiliary: { fetchSymKey }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.cacheSymKey.acquire();
    try {
      let symKey = selectKey(selectScope(getState()))(keyId);
      if (!symKey) {
        symKey = await dispatch(fetchSymKey(originId, keyId));
        dispatch(keyShare({ keyId, symKey }));
      }
      return symKey;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      release();
    }
  },
  cacheReverse: deviceId => async (dispatch, getState) => {
    const {
      options: { name },
      selectors: { selectScope, selectReverse },
      auxiliary: { fetchReverse },
      consumers
    } = module;
    for (let exhausted; ; exhausted = true) {
      const uri = selectReverse(selectScope(getState()))(deviceId);
      if (uri || exhausted) {
        return uri;
      }
      const entry = await dispatch(fetchReverse(deviceId));

      if (entry) {
        await dispatch({
          type: "!" + name + "/" + snakeToCamel(entry.type),
          payload: entry
        });
      }
    }
  },
  registerListener: () => async dispatch => {
    const { options: { name }, actions: { getContext } } = module;
    const { addListener } = await dispatch(getContext());
    const removeListener = addListener((entry, topics, { id, at }) => {
      dispatch({
        type: "!" + name + "/" + snakeToCamel(entry.type),
        payload: {
          ...entry,
          id,
          at
        }
      });
    });
    return removeListener;
  },
  getSymKey: (originId, keyId) => async dispatch => {
    const { auxiliary: { cacheSymKey } } = module;
    //while (true) {
    for (let i = 0; ; i++) {
      try {
        return await dispatch(cacheSymKey(originId, keyId));
      } catch (e) {
        //console.log(e);
      }
      console.log(`key ${keyId} not yet available for ${originId}`);
      //await pause(1000);
      await pause(1000 * Math.min(3600, 2 ** i));
    }
  },
  verifySignature: (data, origin, sign) => async dispatch => {
    const { actions: { getContext }, auxiliary: { cachePublicKeys } } = module;
    const { cryptoClient: cc } = await dispatch(getContext());
    const [, publicECDSAKey] = await dispatch(cachePublicKeys(origin));
    return await cc.verify(publicECDSAKey, Data.fromBase64URL(data), sign);
  },
  lockOneKeyForManyIds: (keyId, symKey, publicKeys) => async dispatch => {
    const { auxiliary: { lockSymKey } } = module;
    const secKeys = {};
    for (const [id, publicKey] of Object.entries(publicKeys)) {
      secKeys[id] = await dispatch(lockSymKey(publicKey, keyId, symKey));
    }
    return secKeys;
  },
  lockManyKeysForOneId: (keys, publicKey) => async dispatch => {
    const { auxiliary: { lockSymKey } } = module;
    const secKeys = {};
    for (const [keyId, symKey] of Object.entries(keys)) {
      secKeys[keyId] = await dispatch(lockSymKey(publicKey, keyId, symKey));
    }
    return secKeys;
  },
  signHash: hash => async dispatch => {
    const { actions: { getContext } } = module;
    const { cryptoClient, credentials } = await dispatch(getContext());
    const { privateECDSAKey } = credentials;
    return (await cryptoClient.sign(privateECDSAKey, Data.fromBase64URL(hash)))
      .signature;
  },
  getGroupLog: id => async (dispatch, getState) => {
    const { auxiliary: { request } } = module;
    return await dispatch(request("slice", { topics: [[1, `grp/${id}`]] }));
    //const { config: { API_URL_PREFIX }, actions: { getToken } } = module;
    //const accessToken = await dispatch(getToken());
    //const { org } = jwtPeek(accessToken);
    //const ns = await hshf(org);
    //const { user: userId } = jwtPeek(accessToken);
    //const url = `${API_URL_PREFIX}/stream/${VERSION}/sdk/slice`;
    //const opt = json(
    //  {
    //    ns,
    //    topics: [[1, `grp/${id}`]]
    //  },
    //  {
    //    accessToken,
    //    userId
    //  }
    //);
    //const result = await request(url, opt);
    //if (result.error || ("success" in result && !result.success)) {
    //  throw new Error(result.error || "Unknown error.");
    //}
    //return result;
  },
  getGoodybag: (arg, pwd) => async (dispatch, getState) => {
    const { config: { API_URL_PREFIX }, actions: { getToken } } = module;
    const accessToken = await dispatch(getToken());
    const { user: userId } = jwtPeek(accessToken);
    const url = `${API_URL_PREFIX}/stream/${VERSION}/sdk/goodybag`;
    const opt = json(
      {
        arg,
        pwd
      },
      {
        accessToken,
        userId
      }
    );
    const result = await request(url, opt);
    if (result.error || ("success" in result && !result.success)) {
      throw new Error(result.error || "Unknown error.");
    }
    return result;
  },
  getPreState: group => async (dispatch, getState) => {
    const {
      config: { API_URL_PREFIX, ENCLAVE },
      actions: { getToken },
      auxiliary: { verifySignature }
    } = module;
    const accessToken = await dispatch(getToken());
    const { user: userId } = jwtPeek(accessToken);
    const url = `${API_URL_PREFIX}/stream/${VERSION}/sdk/prestate/${group}`;
    const opt = json(
      {},
      {
        accessToken,
        userId
      }
    );
    const result = await request(url, opt);
    if (result.error || ("success" in result && !result.success)) {
      throw new Error(result.error || "Unknown error.");
    }
    let { state, signature, checksum: cs } = result;
    if (state) {
      state = JSON.parse(state);
      const checksum = await createJsonChecksum(state);
      const ok = await dispatch(verifySignature(checksum, ENCLAVE, signature));
      if (!ok) {
        throw new Error(`bad prestate signature ${cs} ${checksum}`);
      }
      return state;
    }
  },
  getAnchor: () => async (dispatch, getState) => {
    const {
      config: { API_URL_PREFIX, ENCLAVE },
      actions: { getToken },
      auxiliary: { verifySignature }
    } = module;
    const accessToken = await dispatch(getToken());
    const { org, user: userId } = jwtPeek(accessToken);
    const ns = await hshf(org);
    const url = `${API_URL_PREFIX}/stream/${VERSION}/sdk/anchor/${ns}`;
    const opt = json(
      {},
      {
        accessToken,
        userId
      }
    );
    let result;
    while (true) {
      try {
        result = await request(url, opt);
      } catch (e) {
        if (e == "FAILED_TO_FETCH") {
          await new Promise(k => setTimeout(k, 1750));
          continue;
        }
        throw e;
      }
      break;
    }
    if (result.error || ("success" in result && !result.success)) {
      throw new Error(result.error || "Unknown error.");
    }
    const { anchor } = result;
    if (anchor) {
      const { sign, ...rest } = anchor;
      const checksum = await createJsonChecksum(rest);
      const ok = await dispatch(verifySignature(checksum, ENCLAVE, sign));
      if (!ok) {
        throw new Error("bad anchor signature");
      }
      return rest;
    }
  }
});
