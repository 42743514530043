import { API_URL_PREFIX } from "../../config.js";
import { request, jwtPeek, json } from "utils";
import { getToken } from "../../app/topSlice.js";
import { MenuItem, Intent } from "@blueprintjs/core";

import { dispatchToast } from "../toasts/Toasts.js";
import { useDispatch, useSelector } from "react-redux";
import { selectMutedTeams, setMutedTeams } from "./muteSlice.js";

export default ({ item }) => {
  const dispatch = useDispatch();
  const mutedTeams = useSelector(selectMutedTeams);
  const muted = mutedTeams.includes(item.zone.id);

  return (
    <MenuItem
      text={muted ? "Unmute team" : "Mute team"}
      icon={muted ? "volume-up" : "volume-off"}
      onClick={async () => {
        const toast = await dispatchToast(dispatch, {
          message: muted ? `Unmuting` : `Muting`,
          icon: "SPINNER",
          intent: Intent.PRIMARY,
          timeout: 0,
        });

        const url = `${API_URL_PREFIX}/muteteam`;
        const accessToken = await dispatch(getToken());
        const { user: userId } = jwtPeek(accessToken);

        const result = await request(
          url,
          json(
            {
              zoneId: item.zone.id,
              userId,
              action: !muted,
            },
            {
              accessToken,
              userId,
            }
          )
        );

        if (result.ok) {
          toast.replace({
            message: muted ? `Unmuted` : `Muted`,
            icon: "CHECK",
            intent: Intent.PRIMARY,
          });
        }

        dispatch(
          setMutedTeams(
            muted
              ? mutedTeams.filter((zoneId) => zoneId !== item.zone.id)
              : [...mutedTeams, item.zone.id]
          )
        );
      }}
    />
  );
};
