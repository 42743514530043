import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import { getToken, selectIdentity } from "../../app/topSlice.js";
import createApiClient from "api-client";
import { API_URL_PREFIX } from "../../config";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0
  },
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    }
  }
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = state => state.counter.value;

export default counterSlice.reducer;

export const fetchUserList = ({ identity, permissions }) => async dispatch => {
  const users = {};

  const token = await dispatch(getToken());
  const api = createApiClient(API_URL_PREFIX);

  if (permissions.WORKSPACE_LIST_TEAMS) {
    // Get all users in org
    return await api
      .token(token)
      .orgs(identity.org)
      .users()
      .get();
  } else {
    try {
      const response = await fetch(API_URL_PREFIX + "/friends", {
        method: "get",
        mode: "cors",
        headers: {
          "user-id": identity.id,
          authorization: `Bearer ${token}`
        }
      });

      const result = await response.json();

      return result.map(user => ({ ...user, role: { id: user.role } }));
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return [];
    }
  }
};
