export const hasSupport = (okOss, okBrowsers, ua) => {
  const { os, browser } = ua ? detect(ua) : detection;
  return (
    os &&
    okOss.includes(os.name) &&
    browser &&
    browser.name in okBrowsers &&
    compareVersion(okBrowsers[browser.name], browser.version)
  );
};

const compareVersion = (version, currentBrowserVersion) => {
  let expectedResults = [0];
  let comparableVersion = version;
  let isLoose = false;

  if (typeof currentBrowserVersion !== "string") {
    return void 0;
  }

  if (version[0] === ">" || version[0] === "<") {
    comparableVersion = version.substr(1);
    if (version[1] === "=") {
      isLoose = true;
      comparableVersion = version.substr(2);
    } else {
      expectedResults = [];
    }
    if (version[0] === ">") {
      expectedResults.push(1);
    } else {
      expectedResults.push(-1);
    }
  } else if (version[0] === "=") {
    comparableVersion = version.substr(1);
  } else if (version[0] === "~") {
    isLoose = true;
    comparableVersion = version.substr(1);
  }

  return (
    expectedResults.indexOf(
      compareVersions(currentBrowserVersion, comparableVersion, isLoose)
    ) > -1
  );
};

const getVersionPrecision = version => {
  return version.split(".").length;
};

const compareVersions = (versionA, versionB, isLoose = false) => {
  // 1) get common precision for both versions, for example for "10.0" and "9" it should be 2
  const versionAPrecision = getVersionPrecision(versionA);
  const versionBPrecision = getVersionPrecision(versionB);

  let precision = Math.max(versionAPrecision, versionBPrecision);
  let lastPrecision = 0;

  const chunks = [versionA, versionB].map(version => {
    const delta = precision - getVersionPrecision(version);

    // 2) "9" -> "9.0" (for precision = 2)
    const _version = version + new Array(delta + 1).join(".0");

    // 3) "9.0" -> ["000000000"", "000000009"]
    return _version
      .split(".")
      .map(chunk => new Array(20 - chunk.length).join("0") + chunk)
      .reverse();
  });

  // adjust precision for loose comparison
  if (isLoose) {
    lastPrecision = precision - Math.min(versionAPrecision, versionBPrecision);
  }

  // iterate in reverse order by reversed chunks array
  precision -= 1;
  while (precision >= lastPrecision) {
    // 4) compare: "000000009" > "000000010" = false (but "9" > "10" = true)
    if (chunks[0][precision] > chunks[1][precision]) {
      return 1;
    }

    if (chunks[0][precision] === chunks[1][precision]) {
      if (precision === lastPrecision) {
        // all version chunks are same
        return 0;
      }

      precision -= 1;
    } else if (chunks[0][precision] < chunks[1][precision]) {
      return -1;
    }
  }

  return undefined;
};

const getFirstMatch = (regexp, ua) => {
  const match = ua.match(regexp);
  return (match && match.length > 0 && match[1]) || "";
};

const getSecondMatch = (regexp, ua) => {
  const match = ua.match(regexp);
  return (match && match.length > 1 && match[2]) || "";
};

const getAndroidVersionName = version => {
  const v = version
    .split(".")
    .splice(0, 2)
    .map(s => parseInt(s, 10) || 0);
  v.push(0);
  if (v[0] === 1 && v[1] < 5) return undefined;
  if (v[0] === 1 && v[1] < 6) return "Cupcake";
  if (v[0] === 1 && v[1] >= 6) return "Donut";
  if (v[0] === 2 && v[1] < 2) return "Eclair";
  if (v[0] === 2 && v[1] === 2) return "Froyo";
  if (v[0] === 2 && v[1] > 2) return "Gingerbread";
  if (v[0] === 3) return "Honeycomb";
  if (v[0] === 4 && v[1] < 1) return "Ice Cream Sandwich";
  if (v[0] === 4 && v[1] < 4) return "Jelly Bean";
  if (v[0] === 4 && v[1] >= 4) return "KitKat";
  if (v[0] === 5) return "Lollipop";
  if (v[0] === 6) return "Marshmallow";
  if (v[0] === 7) return "Nougat";
  if (v[0] === 8) return "Oreo";
  if (v[0] === 9) return "Pie";
  return undefined;
};

const getWindowsVersionName = version => {
  switch (version) {
    case "NT":
      return "NT";
    case "XP":
      return "XP";
    case "NT 5.0":
      return "2000";
    case "NT 5.1":
      return "XP";
    case "NT 5.2":
      return "2003";
    case "NT 6.0":
      return "Vista";
    case "NT 6.1":
      return "7";
    case "NT 6.2":
      return "8";
    case "NT 6.3":
      return "8.1";
    case "NT 10.0":
      return "10";
    default:
      return undefined;
  }
};

const getMacOSVersionName = version => {
  const v = version
    .split(".")
    .splice(0, 2)
    .map(s => parseInt(s, 10) || 0);
  v.push(0);
  if (v[0] !== 10) return undefined;
  switch (v[1]) {
    case 5:
      return "Leopard";
    case 6:
      return "Snow Leopard";
    case 7:
      return "Lion";
    case 8:
      return "Mountain Lion";
    case 9:
      return "Mavericks";
    case 10:
      return "Yosemite";
    case 11:
      return "El Capitan";
    case 12:
      return "Sierra";
    case 13:
      return "High Sierra";
    case 14:
      return "Mojave";
    case 15:
      return "Catalina";
    default:
      return undefined;
  }
};

const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;

export const browsers = [
  {
    test: [/chrome|crios|crmo/i],
    describe(ua) {
      const browser = {
        name: "chrome"
      };
      const version = getFirstMatch(
        /(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i,
        ua
      );

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test: [/chromium/i],
    describe(ua) {
      const browser = {
        name: "chromium"
      };
      const version =
        getFirstMatch(/(?:chromium)[\s/](\d+(\.?_?\d+)+)/i, ua) ||
        getFirstMatch(commonVersionIdentifier, ua);

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test: [/\sedg\//i],
    describe(ua) {
      const browser = {
        name: "edge"
      };

      const version = getFirstMatch(/\sedg\/(\d+(\.?_?\d+)+)/i, ua);

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test: [/edg([ea]|ios)/i],
    describe(ua) {
      const browser = {
        name: "edge"
      };

      const version = getSecondMatch(/edg([ea]|ios)\/(\d+(\.?_?\d+)+)/i, ua);

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test: [/firefox|iceweasel|fxios/i],
    describe(ua) {
      const browser = {
        name: "firefox"
      };
      const version = getFirstMatch(
        /(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i,
        ua
      );

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test: [/safari|applewebkit/i],
    describe(ua) {
      const browser = {
        name: "safari"
      };
      const version = getFirstMatch(commonVersionIdentifier, ua);

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  },
  {
    test(parser) {
      const notLikeAndroid = !parser.test(/like android/i);
      const butAndroid = parser.test(/android/i);
      return notLikeAndroid && butAndroid;
    },
    describe(ua) {
      const browser = {
        name: "android"
      };
      const version = getFirstMatch(commonVersionIdentifier, ua);

      if (version) {
        browser.version = version;
      }

      return browser;
    }
  }
];

export const mobiles = [
  {
    test: [/(ipod|iphone|ipad)/i],
    describe(ua) {
      const version = getFirstMatch(
        /os (\d+([_\s]\d+)*) like mac os x/i,
        ua
      ).replace(/[_\s]/g, ".");

      return {
        name: "ios",
        version
      };
    }
  },
  {
    test(parser) {
      const notLikeAndroid = !parser.test(/like android/i);
      const butAndroid = parser.test(/android/i);
      return notLikeAndroid && butAndroid;
    },
    describe(ua) {
      const version = getFirstMatch(/android[\s/-](\d+(\.\d+)*)/i, ua);
      const versionName = getAndroidVersionName(version);
      const os = {
        name: "android",
        version
      };
      if (versionName) {
        os.versionName = versionName;
      }
      return os;
    }
  }
];

export const oss = [
  {
    test: [/windows /i],
    describe(ua) {
      const version = getFirstMatch(/Windows ((NT|XP)( \d\d?.\d)?)/i, ua);
      const versionName = getWindowsVersionName(version);

      return {
        name: "windows",
        version,
        versionName
      };
    }
  },
  {
    test: [/macintosh/i],
    describe(ua) {
      const version = getFirstMatch(/mac os x (\d+(\.?_?\d+)+)/i, ua).replace(
        /[_\s]/g,
        "."
      );
      const versionName = getMacOSVersionName(version);

      const os = {
        name: "macos",
        version
      };
      if (versionName) {
        os.versionName = versionName;
      }
      return os;
    }
  },
  ...mobiles,
  {
    test: [/linux/i],
    describe() {
      return {
        name: "linux"
      };
    }
  },
  {
    test: [/CrOS/],
    describe() {
      return {
        name: "chromeos"
      };
    }
  }
];

export const isMobile = ua => {
  ua = ua || window.navigator.userAgent;
  return !!mobiles.find(
    mobile =>
      mobile.test instanceof Array
        ? mobile.test.some(rex => rex.test(ua))
        : mobile.test({ test: rex => rex.test(ua) })
  );
};

export const detect = (ua = window.navigator.userAgent) => ({
  mobile: isMobile(ua),
  browser: browsers
    .find(
      browser =>
        browser.test instanceof Array
          ? browser.test.some(rex => rex.test(ua))
          : browser.test({ test: rex => rex.test(ua) })
    )
    ?.describe(ua),
  os: oss
    .find(
      os =>
        os.test instanceof Array
          ? os.test.some(rex => rex.test(ua))
          : os.test({ test: rex => rex.test(ua) })
    )
    ?.describe(ua)
});

export const detection = detect();

export default detection;

export const { mobile } = detection;
