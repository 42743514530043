import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import createApiClient from "api-client";
import { values } from "utils";
import { selectIdentity, getToken } from "./topSlice.js";
import { appState } from "./appState.js";
import { API_URL_PREFIX } from "../config.js";

const {
  selectors: { selectUsers },
} = appState;

const UNPROVIDED = {};

const RolesContext = createContext(UNPROVIDED);

function RolesProvider({ children }) {
  const dispatch = useDispatch();
  const semaphore = useRef(false);
  const [roles, setRoles] = useState(null);
  const identity = useSelector(selectIdentity);
  const users = useSelector(selectUsers);
  const actualUser = users?.[identity?.id];
  const memberUser =
    identity && users && values(users).find((user) => user.user == identity.id);
  const user = useMemo(
    () => (memberUser || actualUser) && { ...memberUser, ...actualUser },
    [memberUser, actualUser]
  );
  const roleId = user?.role;
  const theRole = useMemo(
    () => roleId && roles?.find((role) => role.id == roleId),
    [roleId, roles]
  );

  useEffect(() => {
    const syncedSummonRoles = async () => {
      if (semaphore.current) {
        return;
      }
      semaphore.current = true;
      try {
        setRoles(await summonRoles(dispatch, identity, user));
      } finally {
        semaphore.current = false;
      }
    };
    if (!theRole && user) {
      syncedSummonRoles();
    }
  }, [theRole, user]);

  return (
    <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
  );
}

function useRoles() {
  const context = useContext(RolesContext);
  if (context === UNPROVIDED) {
    throw new Error("useRoles must be used within a RolesProvider");
  }
  return context;
}

export { RolesProvider, useRoles };

const roleCompare = (a, b) => a.privilegeOrder - b.privilegeOrder;

const summonRoles = async (dispatch, identity, user) => {
  const { org } = identity;
  const token = await dispatch(getToken());

  const api = createApiClient(API_URL_PREFIX);
  const roles = await api.token(token).retry().orgs(org).roles().get();

  const myRole = roles.find((role) => role.id == user.role);
  myRole.currentRole = true;

  let allowedToSet = (role) => role.privilegeOrder >= myRole.privilegeOrder;
  if (
    org === "manpowergroup" ||
    org === "experis" ||
    org === "aspia" ||
    org === "hykersec" ||
    org === "manpowergroupno" ||
    org === "mpgnobhtest1"
  ) {
    allowedToSet = (role) => role.privilegeOrder > myRole.privilegeOrder;
  }

  const modifiedRoles = roles.map((role) => ({
    allowedToSet: allowedToSet(role),
    ...role,
  }));

  modifiedRoles.sort(roleCompare);

  return modifiedRoles;
};
