import {
  keys,
  values,
  entries,
  assign,
  fromEntries,
  pick,
  clone,
  filterObj,
  setDeep,
  transformObj,
  diffEq,
  changesDiff,
  changes,
  distinctEq,
} from "utils";

export const prepare = (graph, extra) => clean(clone({ ...graph, ...extra }));

//export const idListToMap = list =>
//  //list.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
//  // added || cur.member 220228
//  list.reduce((acc, cur) => ({ ...acc, [cur.id || cur.member]: cur }), {});

//export const idListToMap = list => {
//  //list.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
//  // added || cur.member 220228
//  let t0 = new Date;
//  const x = list.reduce((acc, cur) => ({ ...acc, [cur.id || cur.member]: cur }), {});
//  console.log("idListToMap took", new Date - t0);
//  return x;
//}

export const idListToMap = (list) => {
  //let t0 = new Date;
  const acc = {};
  for (const cur of list) {
    acc[cur.id || cur.member] = cur;
  }
  //console.log("idListToMap took", new Date - t0);
  return acc;
};

export const clean = (graph) => (
  graph.current.zoneId in graph.zones || delete graph.current.zoneId,
  graph.current.groupId in graph.groups || delete graph.current.groupId,
  graph.current.nodeId in graph.nodes || delete graph.current.nodeId,
  graph
);

//export const accumulate = (graph, ids /*, me = null*/) => {
//  const { zones = [], nodes = [], users = [], groups = [] } = ids || {};
//  //// added 220311
//  //if (me) {
//  //  groups.push(
//  //    ...users
//  //      .map(userId => graph.users[userId])
//  //      .filter(user => user?.member && user.user == me)
//  //      .map(user => user.group)
//  //  );
//  //}
//  groups.push(
//    ...zones
//      .flatMap(zoneId => getGroupsFromZone(graph, zoneId))
//      .map(group => group.id)
//  );
//  nodes.push(
//    ...groups
//      .flatMap(groupId => getNodesFromGroup(graph, groupId))
//      .map(node => node.id)
//  );
//  // added 220222
//  users.push(
//    ...values(graph.users)
//      .filter(user => users.includes(user.user))
//      .map(user => user.member)
//  );
//  users.push(
//    ...groups
//      .flatMap(groupId => getUsersFromGroup(graph, groupId))
//      .map(user => user.member)
//  );
//  return { zones, nodes, users, groups };
//};

// 220615 new accumulate without side effects
// MUST BE CAREFULLY TESTED BEFORE PROD!!!
// Might be things that unintentionally depends on this "bug"
export const accumulate = (graph, ids /*, me = null*/) => {
  let { zones = [], nodes = [], users = [], groups = [] } = ids || {};
  //// added 220311
  //if (me) {
  //  groups.push(
  //    ...users
  //      .map(userId => graph.users[userId])
  //      .filter(user => user?.member && user.user == me)
  //      .map(user => user.group)
  //  );
  //}
  groups = [
    ...groups,
    ...zones
      .flatMap((zoneId) => getGroupsFromZone(graph, zoneId))
      .map((group) => group.id),
  ];
  nodes = [
    ...nodes,
    ...groups
      .flatMap((groupId) => getNodesFromGroup(graph, groupId))
      .map((node) => node.id),
  ];
  // added 220222
  users = [
    ...users,
    ...values(graph.users)
      .filter((user) => users.includes(user.user))
      .map((user) => user.member),
  ];
  users = [
    ...users,
    ...groups
      .flatMap((groupId) => getUsersFromGroup(graph, groupId))
      .map((user) => user.member),
  ];
  return { zones, nodes, users, groups };
};

export const pluck = (graph, ids) => {
  ids = accumulate(graph, ids);
  return {
    zones: pick(graph.zones || {}, ...ids.zones),
    nodes: pick(graph.nodes || {}, ...ids.nodes),
    users: pick(graph.users || {}, ...ids.users),
    groups: pick(graph.groups || {}, ...ids.groups),
  };
};

export const getSurfaceNodes = ({ zones, groups, nodes }) =>
  values(nodes).filter(({ id, parent: p, group }) => {
    const root = groups[zones[groups[group]?.zone]?.rootGroup]?.rootFile;
    return !p || p == root || !nodes[p]?.parent || nodes[p]?.parent == root;
  });

export const removeDeepNodes = ({ zones, groups, nodes }) => {
  for (const { id, parent, group } of values(nodes)) {
    const root = groups[zones[groups[group]?.zone]?.rootGroup]?.rootFile;
    if (
      parent &&
      parent != root &&
      //nodes[parent]?.id != root &&
      nodes[parent]?.parent != root &&
      nodes[parent]?.parent
    ) {
      delete nodes[id];
    }
  }
};

//export const rebalanceIndices = (siblings, files = []) => {
//  siblings.sort((a, b) => a.index - b.index);
//
//  let index = 0;
//
//  for (const sibling of siblings) {
//    index = files.filter(file => file.index <= index).length;
//    sibling.index = index;
//    files.splice(index, 0, sibling);
//    index++;
//  }
//};

export const rebalanceIndices = (siblings, vip = null) => {
  const nodes = [...siblings].sort((a, b) => a.index - b.index);

  if (vip) {
    const index = nodes.findIndex((n) => n.index >= vip.index);
    nodes.splice(~index ? index : nodes.length, 0, vip);
  }

  for (let i = 0; i < nodes.length; i++) {
    nodes[i].index = nodes[0].index + i;
  }
};

export const moveNodeInGraph = (graph, nodeId, parentId, index) => {
  const node = graph.nodes[nodeId];

  if (node) {
    if (node.parent != parentId) {
      const siblings = values(graph.nodes).filter(
        (n) => n.id != node.id && n.parent == node.parent
      );
      rebalanceIndices(siblings);
      node.parent = parentId;
    }

    const siblings = values(graph.nodes).filter(
      (n) => n.id != node.id && n.parent == node.parent
    );

    //node.index = Math.min(Math.max(0, index), siblings.length);

    node.index = index;

    rebalanceIndices(siblings, node);
  }
};

//export const moveNodeInGraph = (graph, nodeId, parentId, index) => {
//  index = index || 0;
//  if (nodeId in graph.nodes) {
//    const node = graph.nodes[nodeId];
//    const previousParent = node.parent;
//    parentId = parentId || node.parent;
//
//    if (index < 0) {
//      index = values(graph.nodes).filter(node => node.parent == parentId)
//        .length;
//    }
//
//    values(graph.nodes)
//      .filter(node => node.parent == parentId)
//      .filter(node => node.index >= index)
//      .forEach(node => node.index++);
//
//    node.index = index;
//    node.parent = parentId;
//
//    values(graph.nodes)
//      .filter(node => node.parent == previousParent)
//      .sort((a, b) => a.index - b.index)
//      .forEach((node, index) => assign(node, { index }));
//  }
//};

export const filterGraphByZone = (graph, zone) => {
  if (zone in (graph.zones || {})) {
    const { zones, ...rest } = graph;
    return entries(rest).reduce(
      (acc, [key, entries]) => ({
        ...acc,
        [key]: filterObj(entries, (id, entry) => entry.zone == zone),
      }),
      { zones: pick(zones, zone) }
    );
  }
};

export const getRootNodeFromZone = (graph, zone) => {
  try {
    return graph.groups[graph.zones[zone].rootGroup].rootFile;
  } catch (_) {}
};

//export const removeNoneDirectNodes = (nodes, nodeIds) =>
//  filterObj(
//    nodes,
//    (id, node) =>
//      nodeIds.includes(id) ||
//      nodeIds.includes(node.parent) ||
//      (node.parent in nodes && nodeIds.includes(nodes[node.parent].parent))
//  );

export const getNodeWithChildren = ({ nodes }, nodeId) =>
  values(nodes).filter(
    (node) =>
      node.id == nodeId ||
      node.parent == nodeId ||
      nodes[node.parent]?.parent == nodeId
  );

export const getGroupNodes = (graph, groupId) =>
  values(graph.nodes).filter((node) => node.group == groupId);

//export const getNodeDescendants = (graph, parentId) =>
//  values(graph.nodes)
//    .filter(node => node.parent == parentId)
//    .flatMap(node => [node, ...getNodeDescendants(graph, node.id)]);
//

// should be a circular dependecy proof version of above
export const getNodeDescendants = (graph, parentId) => {
  const nodes = { ...graph.nodes },
    level = [];
  for (const [id, node] of entries(nodes)) {
    if (node.parent == parentId) {
      level.push(node);
      delete nodes[id];
    }
  }
  return level.flatMap((n) => [n, ...getNodeDescendants({ nodes }, n.id)]);
};

export const getNodeWithDescendants = (graph, nodeId) => [
  graph.nodes[nodeId],
  ...getNodeDescendants(graph, nodeId),
];

//export const getNodeWithAscendants = (graph, nodeId) =>
//  nodeId && nodeId in graph.nodes
//    ? [
//        graph.nodes[nodeId],
//        ...getNodeWithAscendants(graph, graph.nodes[nodeId].parent),
//      ]
//    : [];

// should be a circular dependecy proof version of above
export const getNodeWithAscendants = (graph, nodeId) => {
  if (nodeId && nodeId in graph.nodes) {
    const { [nodeId]: node, ...nodes } = graph.nodes;
    return [node, ...getNodeWithAscendants({ nodes }, node.parent)];
  }
  return [];
};

export const getNodeAscendants = (graph, nodeId) =>
  getNodeWithAscendants(graph, nodeId).slice(1);

//export const getNodeWithDescendants = (graph, nodeId) =>
//  nodeId in graph.nodes
//    ? [
//        graph.nodes[nodeId],
//        ...values(graph.nodes)
//          .filter(node => node.parent == nodeId)
//          .map(node => getNodeWithDescendants(graph, node.id))
//      ]
//    : [];

export const getNodeGroupDescendants = (graph, nodeId) => {
  const groupId = graph.nodes[nodeId]?.group;
  const nodes = getGroupNodes(graph, groupId);
  graph = { nodes: idListToMap(nodes) };
  return getNodeDescendants(graph, nodeId);
};

export const getNodeWithGroupDescendants = (graph, nodeId) => [
  graph.nodes[nodeId],
  ...getNodeGroupDescendants(graph, nodeId),
];

//export const constructTree = (node, nodes) => {
//  const root = { node, kids: [] };
//  for (const kid of nodes) {
//    if (kid.parent == node.id) {
//      root.kids.push(constructTree(kid, nodes));
//    }
//  }
//  return root;
//};

// should be a circular dependecy proof version of above
//export const constructTree = (node, nodes, _) => {
//  let t0 = new Date;
//  nodes = [...nodes];
//  const kids = [],
//    root = { node, kids: [] };
//  for (let i = nodes.length - 1; i >= 0; i--) {
//    if (nodes[i].parent == node.id) {
//      kids.push(nodes.splice(i, 1)[0]);
//    }
//  }
//  root.kids.push(...kids.map(node => constructTree(node, nodes, 1)));
//  if (!_) {
//    console.log("~~~~ constructTree took", new Date - t0);
//  }
//  return root;
//};

export const constructTree = (root, nodes) => {
  //let t0 = new Date;
  let tree,
    arr = [],
    map = {};
  for (const node of nodes) {
    const glue = { node };
    arr.push(glue);
    if (node.id == root.id) {
      tree = glue;
    }
    if (node.parent in map) {
      map[node.parent].push(glue);
    } else {
      map[node.parent] = [glue];
    }
  }
  for (const glue of arr) {
    if (glue.node.id in map) {
      glue.kids = map[glue.node.id];
    } else {
      glue.kids = [];
    }
  }
  //console.log("~~~~ constructTree took", new Date - t0);
  return tree;
};

//export const deconstructTree = (root, nodes = []) => {
//  nodes.push(root.node);
//  for (const kid of root.kids) {
//    deconstructTree(kid, nodes);
//  }
//  return nodes;
//};

export const deconstructTree = (tree) => {
  const map = {};
  const f = (glue) => {
    if (!(glue.node.id in map)) {
      map[glue.node.id] = glue.node;
      for (const kid of glue.kids) {
        f(kid);
      }
    }
  };
  f(tree);
  return Object.values(map);
};

const getFolderId = ({ nodes }, nodeId) =>
  //nodes[nodeId]?.storageId ? nodes[nodeId].parent : nodeId;
  (nodes[nodeId] || {}).storageId ? nodes[nodeId].parent : nodeId;

const getDefaultZoneId = ({ zones }) => keys(zones)[0] || "";

const getDefaultGroupId = ({ zones, users, groups, id }, zoneId) => {
  //let groupId = (zoneId in zones && zones[zoneId].virtualRootGroup) || "";

  const { rootGroup = "", virtualRootGroup = "" } = zones[zoneId] || {};

  let groupId = virtualRootGroup;

  const idGroups = values(users).flatMap((user) => {
    if (user.user == id) {
      const group = groups[user.group];
      if (
        group &&
        group.zone == zoneId &&
        (group.id == virtualRootGroup || group.id != rootGroup)
      ) {
        return [group.id];
      }
    }
    return [];
  });

  if (!idGroups.includes(groupId)) {
    groupId = rootGroup;
    if (!idGroups.includes(groupId)) {
      groupId = idGroups[0] || "";
    }
  }

  return groupId;
};

const getDefaultNodeId = (graph, zoneId) => {
  //groups[zones[zoneId]?.rootGroup]?.rootFile || "";
  //groups[zones[zoneId]?.virtualRootGroup]?.rootFile || "";
  //(groups[(zones[zoneId] || {}).virtualRootGroup] || {}).rootFile || "";

  const { groups, zones } = graph;
  return (groups[getDefaultGroupId(graph, zoneId)] || {}).rootFile || "";
};

const getDefaultFolderId = (graph, zoneId) =>
  getFolderId(graph, getDefaultNodeId(graph, zoneId));

const getCurrentOrDefaultZoneId = (graph) =>
  graph.current.zoneId || getDefaultZoneId(graph);

const getCurrentOrDefaultGroupId = (graph) =>
  graph.current.groupId ||
  getDefaultGroupId(graph, getCurrentOrDefaultZoneId(graph));

const getGroupIdOfCurrentOrDefaultNode = (graph) =>
  //graph.nodes[getCurrentOrDefaultNodeId(graph)]?.group || "";
  (graph.nodes[getCurrentOrDefaultNodeId(graph)] || {}).group || "";

const getRootGroupIdOfCurrentOrDefaultZone = (graph) =>
  //graph.zones[getCurrentOrDefaultZoneId(graph)]?.rootGroup || "";
  (graph.zones[getCurrentOrDefaultZoneId(graph)] || {}).rootGroup || "";

const getRootNodeIdOfCurrentOrDefaultZone = (graph) =>
  //graph.groups[getRootGroupIdOfCurrentOrDefaultZone(graph)]?.rootFile || "";
  (graph.groups[getRootGroupIdOfCurrentOrDefaultZone(graph)] || {}).rootFile ||
  "";

const getCurrentOrDefaultNodeId = (graph) =>
  graph.current.nodeId ||
  getDefaultNodeId(graph, getCurrentOrDefaultZoneId(graph));

const getCurrentOrDefaultFolderId = (graph) =>
  getFolderId(graph, getCurrentOrDefaultNodeId(graph));

export const getContext = (graph, id) => {
  //let t0 = new Date;
  graph = prepare(graph, id);

  const {
    zones,
    nodes,
    users,
    groups,
    current: { expandeds },
  } = graph;

  const defaultContext = {
    notLoaded: true,
    zoneId: "",
    groupId: "",
    nodeId: "",
    zoneName: "",
    zones: [],
  };

  if (!keys(zones).length) {
    return defaultContext;
  }

  const zoneId = getCurrentOrDefaultZoneId(graph);
  const groupId = getCurrentOrDefaultGroupId(graph);
  //const groupId = getGroupIdOfCurrentOrDefaultNode(graph);
  const rootGroupId = getRootGroupIdOfCurrentOrDefaultZone(graph);
  const nodeId = getCurrentOrDefaultNodeId(graph);
  const rootNodeId = getRootNodeIdOfCurrentOrDefaultZone(graph);
  const folderId = getCurrentOrDefaultFolderId(graph);
  const zoneGroups = getGroupsFromZone(graph, zoneId);

  const zone = zones[zoneId];
  const group = groups[groupId];
  const rootGroup = groups[rootGroupId];
  const node = nodes[nodeId];
  const rootNode = nodes[rootNodeId];
  const folder = clone(nodes[folderId]);

  if (!zoneGroups.length) {
    return {
      ...defaultContext,
      zone,
    };
  }

  //console.log(JSON.stringify(rootGroupId), JSON.stringify(groupId), JSON.stringify(graph, null, 2));

  //if (zone && !group) {
  //  return {
  //    ...defaultContext,
  //    notLoaded: !zone.loaded,
  //    zone
  //  };
  //}

  const { name: zoneName, virtualRootGroup: virtualRootGroupId } = zone;

  const isRestricted = rootGroupId != virtualRootGroupId;

  //const hasGroupChoice = (isRestricted ? 2 : 1) < zoneGroups.length;

  const hasGroupChoice = zoneGroups.some(
    (group) =>
      group.id !== rootGroupId &&
      group.id !== virtualRootGroupId &&
      group.rootFile in nodes
  );

  if (folder) {
    folder.isRestricted =
      !(rootGroupId in groups) || folderId == groups[rootGroupId].rootFile
        ? isRestricted
        : folderId == groups[folder.group].rootFile;

    //: !folder.parent ||
    //  (nodes[folder.id] &&
    //    nodes[folder.parent] &&
    //    nodes[folder.id].group != nodes[folder.parent].group);
  }

  //console.log("getContext took", new Date - t0);

  return {
    zoneId,
    groupId,
    nodeId,
    rootNodeId,
    folderId,
    rootGroupId,
    virtualRootGroupId,
    isRestricted,
    hasGroupChoice,
    zoneName,
    zone,
    group,
    rootGroup,
    node,
    rootNode,
    folder,
  };
};

export const getZones = (graph) => {
  //graph = cloneAndClean(graph);
  const zoneId = getCurrentOrDefaultZoneId(prepare(graph));
  return values(graph.zones).map((zone) => {
    const {
      id,
      name,
      rootGroup,
      virtualRootGroup,
      expirationDate,
      template,
      foreign,
    } = zone;
    const isRestricted = rootGroup != virtualRootGroup;
    const groupCount =
      zone._count ||
      values(graph.groups).filter((group) => group.zone == id).length;
    const hasGroupChoice = (isRestricted ? 2 : 1) < groupCount;
    //const hasGroupChoice = (isRestricted ? 2 : 1) < values(graph.groups).filter(group => group.zone == id).length;
    return {
      id,
      name,
      rootGroupId: rootGroup,
      virtualRootGroupId: virtualRootGroup,
      isRestricted,
      hasGroupChoice,
      isTemplate: !!template,
      willExpire: !!expirationDate,
      isForeign: !!foreign,
      isCurrent: id == zoneId,
    };
  });
};

export const getNameFromZone = (graph, zone) =>
  graph.zones[zone] && graph.zones[zone].name;

export const getExpireDateFromZone = (graph, zone) =>
  graph.zones[zone].expirationDate || null;

export const getGroupsFromZone = (graph, zone) =>
  values(graph.groups).filter((group) => group.zone == zone);

export const getGroupsFromCurrentZone = (graph) =>
  getGroupsFromZone(graph, getCurrentOrDefaultZoneId(prepare(graph)));

export const getUsersFromZoneRootGroup = (graph, zoneId) =>
  zoneId in graph.zones
    ? values(graph.users)
        .filter((user) => user.group == graph.zones[zoneId].rootGroup)
        // map added 2111
        .map((user) => ({
          ...user,
          ...graph.users[user.user],
        }))
    : [];

export const getUsersFromCurrentZoneRootGroup = (g) =>
  getUsersFromZoneRootGroup(g, getCurrentOrDefaultZoneId(prepare(g)));

// should be de-duped, as only members have a group and member id is key in map
export const getUsersFromGroup = (graph, group) =>
  values(graph.users)
    .filter((user) => user.group && user.group == group)
    // map added 2111
    .map((user) => ({
      ...user,
      ...graph.users[user.user],
    }));

export const getUsersFromCurrentGroup = (graph, id) =>
  getUsersFromGroup(graph, getCurrentOrDefaultGroupId(prepare(graph, { id })));

export const getNodesFromGroup = (graph, group) =>
  values(graph.nodes).filter((node) => node.group == group);

export const getGroupFromNode = (graph, node) => graph.nodes[node].group;

export const getNodesFromCurrentGroup = (graph, id) =>
  getNodesFromGroup(graph, getCurrentOrDefaultGroupId(prepare(graph, { id })));

export const getZoneFromNode = (graph, nodeId) =>
  graph.groups[graph.nodes[nodeId].group].zone;

export const getUsersFromZone = (graph, zone) =>
  distinctEq((a, b) => a.member == b.member)(
    getGroupsFromZone(graph, zone).flatMap((group) =>
      values(graph.users).filter(
        (user) => user.member && user.group == group.id
      )
    )
  );

export const getUsersFromCurrentZone = (graph) =>
  getUsersFromZone(graph, getCurrentOrDefaultZoneId(prepare(graph)));

export const getGroupsFromUser = (graph, user) =>
  distinctEq((a, b) => a.id == b.id)(
    values(graph.users)
      .filter((user) => user.group && user.user == user)
      .map((user) => graph.groups[user.group])
  );

export const getNodesFromZone = (graph, zone) => {
  const groups = values(graph.groups)
    .filter((group) => group.zone == zone)
    .map((group) => group.id);
  return values(graph.nodes).filter((node) => groups.includes(node.group));
};

export const getUserByUri = (graph, uri) =>
  values(graph.users).find((user) => user.deprecatedId == uri.toLowerCase());

export const getUserById = (graph, id) => graph.users[id];

export const eqAttestations = (a, b) => {
  if (!a.attestations?.length && !b.attestations?.length) {
    return true;
  }
  if (a.attestations?.length != b.attestations?.length) {
    return false;
  }
  let f = (a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0);
  a = [...a.attestations].sort(f);
  b = [...b.attestations].sort(f);
  for (let i = 0; i < a.length; i++) {
    if (
      a[i].id != b[i].id ||
      a[i].signees.length != b[i].signees.length ||
      a[i].rejections.length != b[i].rejections.length
    ) {
      return false;
    }
  }
  for (let i = 0; i < a.length; i++) {
    if (
      [...a[i].signees].sort().join() != [...b[i].signees].sort().join() ||
      [...a[i].rejections].sort().join() != [...b[i].rejections].sort().join()
    ) {
      return false;
    }
  }
  return true;
};

export const stateChanges = (a, b) => {
  const sub = {},
    add = {};

  const eq = ([x, a], [y, b]) =>
    x == y &&
    a.group == b.group &&
    a.parent == b.parent &&
    a.index == b.index &&
    a.version == b.version &&
    a.rootGroup == b.rootGroup &&
    a.expirationDate == b.expirationDate &&
    a.timestamp == b.timestamp &&
    a.name == b.name &&
    a.role == b.role &&
    a._touch == b._touch;
  //!a.attestations?.length &&
  //!b.attestations?.length;

  const changesF = changesDiff(diffEq(eq));

  const changes = (a, b) => {
    let [sub, add] = changesF(a, b);
    sub = sub.filter(([a]) => !add.some(([b]) => a == b));
    return [sub, add];
  };

  //let t0 = new Date;
  for (const key of new Set([...keys(a), ...keys(b)])) {
    [sub[key], add[key]] = changes(
      entries(a[key] || {}),
      entries(b[key] || {})
    ).map(fromEntries);
  }
  //console.log("stateChanges0 took", new Date - t0);

  return [sub, add];
};

// sub needs no search, only check if id is present in prev but not next
//  if id is present in both but items unequal, it will be set in add result,
//  and should result in an update of the item in the state
// in many cases, find could be turned off altogether.
//  these are cases where we know nothing has changed but only new creations
//  then the id will be present in next but not prev and no find is required
//  e.g. creating a member.
// in some cases changes of items are known berforehand
//  then the hint argument can be used to specified the items id
//  then they will instantly be added to the add result without searching for
//  other equal items of that kind
//  e.g. creating a node: the node timestamp will propogate upwards in the
//  node tree affecting other nodes than itself
// it is probable that hinting the change of some kind of item can indirecly
//  hint of a change in an other kind of item related to the first
//  to be effective, that other kind of items must be changed not newly
//  created, since if just new items then turning off find is probably faster
//  than deriving hints
//  OBS not implemented
export const stateChanges5 = (prev, next, find = true, hint = {}) => {
  const sub = {};
  const add = {};
  for (const kind of ["zones", "nodes", "users", "groups"]) {
    sub[kind] = [];
    add[kind] = {};
    if (prev && kind in prev) {
      for (const id of Object.keys(prev[kind])) {
        if (!next || !(kind in next) || !(id in next[kind])) {
          sub[kind].push(id);
        }
      }
    }
  }
  if ("users" in hint) {
    for (const id of Object.values(hint.users)) {
      add.users[id] = next.users[id];
    }
  } else if (next?.users) {
    for (const [id, a] of Object.entries(next.users)) {
      if (!prev?.users || !(id in prev.users)) {
        add.users[id] = a;
        continue;
      }
      if (find && prev?.users) {
        const b = prev.users[id];
        if (
          a.group != b.group ||
          a.name != b.name ||
          a.role != b.role ||
          a._touch != b._touch
        ) {
          add.users[id] = a;
        }
      }
    }
  }
  if ("groups" in hint) {
    for (const id of Object.values(hint.groups)) {
      add.groups[id] = next.groups[id];
    }
  } else if (next?.groups) {
    for (const [id, a] of Object.entries(next.groups)) {
      if (!prev?.groups || !(id in prev.groups)) {
        add.groups[id] = a;
        continue;
      }
      if (find && prev?.groups) {
        const b = prev.groups[id];
        if (false) {
          add.groups[id] = a;
        }
      }
    }
  }
  if ("nodes" in hint) {
    for (const id of Object.values(hint.nodes)) {
      add.nodes[id] = next.nodes[id];
    }
  } else if (next?.nodes) {
    for (const [id, a] of Object.entries(next.nodes)) {
      if (!prev?.nodes || !(id in prev.nodes)) {
        add.nodes[id] = a;
        continue;
      }
      if (find && prev?.nodes) {
        const b = prev.nodes[id];
        if (
          a.group != b.group ||
          a.parent != b.parent ||
          a.index != b.index ||
          a.version != b.version ||
          a.timestamp != b.timestamp ||
          a.name != b.name ||
          a._touch != b._touch
        ) {
          add.nodes[id] = a;
        }
      }
    }
  }
  if ("zones" in hint) {
    for (const id of Object.values(hint.zones)) {
      add.zones[id] = next.zones[id];
    }
  } else if (next?.zones) {
    for (const [id, a] of Object.entries(next.zones)) {
      if (!prev?.zones || !(id in prev.zones) /* || id in hint.zones*/) {
        add.zones[id] = a;
        continue;
      }
      if (find && prev?.zones) {
        const b = prev.zones[id];
        if (
          a.rootGroup != b.rootGroup ||
          a.expirationDate != b.expirationDate ||
          a.name != b.name ||
          a._touch != b._touch
        ) {
          add.zones[id] = a;
        }
      }
    }
  }
  return [sub, add];
};

//export const stateChanges4 = (a, b) => {
//  const sub = {},
//    add = {};
//
//  const eq = ([x, a], [y, b]) =>
//    x == y &&
//    a.group == b.group &&
//    a.parent == b.parent &&
//    a.index == b.index &&
//    a.version == b.version &&
//    a.rootGroup == b.rootGroup &&
//    a.expirationDate == b.expirationDate &&
//    a.timestamp == b.timestamp &&
//    a.name == b.name &&
//    a.role == b.role &&
//    a._touch == b._touch;
//
//  //let t0 = new Date;
//  for (const key of ["zones", "nodes", "users", "groups"]) {
//    let as = entries(a[key] || {});
//    let bs = entries(b[key] || {});
//
//    //let su = diffEq(eq)(as, bs);
//
//    let t1 = new Date;
//    let su = [];
//    for (const a of as) {
//      let some = false;
//      for (const b of bs) {
//        if (eq(a, b)) {
//          some = true;
//          break;
//        }
//      }
//      if (!some) {
//        su.push(a);
//      }
//    }
//
//    //let ad = diffEq(eq)(bs, as);
//
//    let ad = [];
//    for (const b of bs) {
//      let some = false;
//      for (const a of as) {
//        if (eq(a, b)) {
//          some = true;
//          break;
//        }
//      }
//      if (!some) {
//        ad.push(b);
//      }
//    }
//    console.log("type 1 took", new Date - t1);
//
//    let t2 = new Date;
//    let some = true;
//    for (const b of bs) {
//      for (const a of as) {
//        if (eq(a, b)) {
//          some = true;
//        }
//      }
//    }
//    console.log("type 2 took", new Date - t2);
//
//    su = su.filter(([a]) => !ad.some(([b]) => a == b));
//    sub[key] = fromEntries(su);
//    add[key] = fromEntries(ad);
//  }
//  //console.log("stateChanges4 took", new Date - t0);
//
//  return [sub, add];
//};
//
//export const stateChanges2 = (a, b) => {
//  const sub = {
//    zones: { ...a?.zones },
//    nodes: { ...a?.nodes },
//    users: { ...a?.users },
//    groups: { ...a?.groups }
//  };
//  const add = {
//    zones: { ...b?.zones },
//    nodes: { ...b?.nodes },
//    users: { ...b?.users },
//    groups: { ...b?.groups }
//  };
//  let t0 = new Date;
//  for (const kind of ["zones", "nodes", "users", "groups"]) {
//    for (const [id, a] of Object.entries(sub[kind])) {
//      for (const [bId, b] of Object.entries(add[kind])) {
//        if (id == bId) {
//          if (
//            a.group == b.group &&
//            a.parent == b.parent &&
//            a.index == b.index &&
//            a.version == b.version &&
//            a.rootGroup == b.rootGroup &&
//            a.expirationDate == b.expirationDate &&
//            a.timestamp == b.timestamp &&
//            a.name == b.name &&
//            a.role == b.role &&
//            a._touch == b._touch
//          ) {
//            console.log("IS EUQLS!");
//            delete sub[kind][id];
//            delete add[kind][id];
//          }
//        }
//      }
//    }
//  }
//  console.log("stateChanges2 took", new Date - t0);
//  return [sub, add];
//};

//export const stateChanges3 = (a, b) => {
//  for (const kind of ["zones", "nodes", "users", "groups"]) {
//    for (const [aId, a] of Object.entries(sub[kind])) {
//      for (const [bId, b] of Object.entries(add[kind])) {
//          if (!(
//            aId == bId &&
//            a.group == b.group &&
//            a.parent == b.parent &&
//            a.index == b.index &&
//            a.version == b.version &&
//            a.rootGroup == b.rootGroup &&
//            a.expirationDate == b.expirationDate &&
//            a.timestamp == b.timestamp &&
//            a.name == b.name &&
//            a.role == b.role &&
//            a._touch == b._touch
//          )) {
//            console.log("IS EUQLS NOT!");
//            sub[kind][aId] = ;
//            add[kind][id];
//          }
//        }
//      }
//    }
//  }
//  return [sub, add];
//};

//export const stateChanges = (a, b) => {
//    for (const [aId, aItem] of as) {
//      for (const [bId, bItem] of bs) {
//        if (aId == bId && aItem == bItem) {
//          delete as[aId];
//          delete bs[bId];
//        }
//      }
//    }
//  }
//
//  return [sub, add];
//};
//
//export const stateChanges = (a, b) => {
//  const sub = {},
//    add = {};
//
//  const eq = ([x, a], [y, b]) =>
//    x == y &&
//    a.group == b.group &&
//    a.parent == b.parent &&
//    a.index == b.index &&
//    a.version == b.version &&
//    a.rootGroup == b.rootGroup &&
//    a.expirationDate == b.expirationDate &&
//    a.timestamp == b.timestamp &&
//    a.name == b.name &&
//    a.role == b.role &&
//    a._touch == b._touch;
//
//  for (const things of ["zones", "nodes", "users", "groups"]) {
//    sub[things] = {};
//    add[things] = {};
//
//    const aMap = a[things];
//    const bMap = b[things];
//
//    for (const aList of entries(aMap)) {
//      for (const [aId, aItem] of aList) {
//        let includes = false;
//        for (const [bId, bItem] of bList) {
//          if (eq([aId, aItem], [bId, bItem])) {
//            includes = true;
//            break;
//          }
//        }
//        if (!includes) {
//          sub[things][aId] = aItem;
//        }
//      }
//    }
//
//    const matrix = {};
//
//    for (let i = 0; i < allIds.length; i++) {
//      matrix[i][]
//
//    }
//
//    //let eqs = [];
//
//    for (const [aId, aItem] of as) {
//      let aInB = false;
//      for (const [bId, bItem] of bs) {
//        //let bInA = false;
//        if (aId == bId) {
//          if (eq(aItem, bItem)) {
//            aInB = true;
//          }
//        }
//      }
//
//      if (!aInB) {
//
//      }
//    }
//
//    //////////////////////
//
//    for (const [aId, aItem] of as) {
//      let aInB = true;
//      for (const [bId, bItem] of bs) {
//        if (aId == bId && aItem == bItem) {
//          aInB = true;
//        }
//      }
//      if (!aInB) {
//        sub[things][aId] = aItem;
//      }
//    }
//
//    for (const [bId, bItem] of as) {
//      let bInA = false;
//      for (const [aId, aItem] of bs) {
//        if (aId == bId && aItem == bItem) {
//          bInA = true;
//        }
//      }
//      if (!bInA) {
//        add[things][bId] = bItem;
//      }
//    }
//
//    for (const [aId, aItem] of as) {
//      for (const [bId, bItem] of bs) {
//        if (aId == bId && aItem == bItem) {
//          delete as[aId];
//          delete bs[bId];
//        }
//      }
//    }
//  }
//
//  return [sub, add];
//};

export const getPersonalState = (
  graph,
  zones = [],
  users = [],
  orphanGraftNodes = false
) => {
  //let t0 = new Date;
  const userToGroups = {};

  for (const user of values(graph.users)) {
    for (const group of values(graph.groups)) {
      if (user.group == group.id) {
        if (!users.length || users.includes(user.user)) {
          if (!zones.length || zones.includes(group.zone)) {
            setDeep(userToGroups)(user.user, group.id)(group);
          }
        }
      }
    }
  }

  const result = {};

  for (const [user, groups] of entries(userToGroups)) {
    result[user] = {
      zones: transformObj(groups, (_, g) => [g.zone, graph.zones[g.zone]]),
      nodes: filterObj(graph.nodes, (_, node) => node.group in groups),
      users: filterObj(graph.users, (_, user) => user.group in groups),
      groups,
    };
  }

  if (orphanGraftNodes) {
    for (const [user, { nodes }] of entries(result)) {
      for (const node of values(nodes)) {
        if (node.parent && !(node.parent in nodes)) {
          const { parent, ...orphan } = node;
          nodes[node.id] = orphan;
        }
      }
    }
  }

  //console.log("getPersonalState took", new Date - t0);

  return result;
};

export const getGraftNodes = (graph, userId) =>
  values(graph.nodes)
    .filter(
      (node) =>
        node.parent in graph.nodes &&
        !values(graph.users).some(
          (user) =>
            user.user == userId && user.group == graph.nodes[node.parent].group
        )
    )
    .map((node) => node.id);

export const getPersonalStateChange = (
  A,
  B,
  zones = [],
  users = [],
  orphanGraftNodes = false
) => {
  const a = getPersonalState(A, zones, users);
  //const b = clone(getPersonalState(B, zones, users));
  const b = getPersonalState(B, zones, users);
  //let t0 = new Date;

  const orphans = {};

  if (orphanGraftNodes) {
    for (const [user, { nodes }] of entries(b)) {
      //graftNodes[user] = getGraftNodes(B, user);
      orphans[user] = values(nodes)
        .filter((node) => node.parent && !(node.parent in nodes))
        .map((node) => node.id);
    }
  }

  //console.log("???", orphans)

  //let t1 = new Date;
  const result = {};

  for (const user of new Set([...keys(a), ...keys(b)])) {
    result[user] = stateChanges5(a[user], b[user]);

    //result[user] = stateChanges(a[user] || {}, b[user] || {});

    //result[user][0] = Object.entries(result[user][0]).reduce((r, [k, v]) => ((r[k] = Object.keys(v, k)), r), {});

    //try {
    //  let x = stateChanges5(a[user], b[user]);
    //  if (JSON.stringify(result[user]) != JSON.stringify(x)) {
    //    console.log("WARNING DID NOT MATCH!")
    //    console.log(JSON.stringify(result[user], null, 2))
    //    console.log(JSON.stringify(x, null, 2))
    //  }
    //} catch(e) {
    //  console.log(e);
    //}
  }

  //console.log("all stateChanges took", new Date - t1);

  for (const [user, nodeIds] of entries(orphans)) {
    const [, add] = result[user];
    for (const nodeId of nodeIds) {
      //console.log("@@@", nodeId, add.nodes)
      if (nodeId in add.nodes) {
        const { parent, ...orphan } = add.nodes[nodeId];
        add.nodes[nodeId] = orphan;
      }
    }
  }

  //for (const [user, [_, add]] of entries(result)) {
  //  for (const node of values(add.nodes)) {
  //    if (graftNodes[user].includes(node.id)) {
  //      delete node.parent;
  //    }
  //  }
  //}

  //console.log("getPersonalStateChange (not including getPersonalState) took", new Date - t0);

  return result;
};

//export const getTree = graph => {
//  let t0 = new Date;
//  graph = prepare(graph);
//
//  const { current: { expandeds, nodeId } } = graph;
//  const { zones, nodes, users, groups } = graph;
//
//  const zoneId = getCurrentOrDefaultZoneId(graph);
//
//  if (!zoneId) {
//    return [];
//  }
//
//  const { rootGroup } = zones[zoneId];
//
//  if (!(rootGroup in groups)) {
//    return [];
//  }
//
//  //const zoneGroups = getGroupsFromZone(graph, zoneId);
//  //const zoneNodes = values(nodes).filter(node =>
//  //  zoneGroups.some(group => group.id === node.group)
//  //);
//  const zoneNodes = getNodesFromZone(graph, zoneId);
//
//  const { rootFile } = groups[rootGroup];
//
//  const graftBranches = zoneNodes
//    .filter(node => node.id != rootFile)
//    .filter(node => groups[node.group].rootFile == node.id)
//    .filter(node => !node.parent);
//
//  //const f = (parentId, depth = 0) =>
//  //  values(nodes)
//  //    .filter(node => {
//  //      if (node.parent == parentId) {
//  //        return true;
//  //      }
//  //      if (parentId == rootFile) {
//  //        return graftBranches.some(n => n.id == node.id);
//  //      }
//  //    })
//  //    .flatMap(node => {
//  //      const children = f(node.id, depth + 1);
//  //      const current = node.id == nodeId;
//  //      const expanded = node.id in expandeds;
//  //      const empty = !children.length;
//  //      const restricted =
//  //        !node.parent ||
//  //        !(node.parent in nodes) ||
//  //        nodes[node.parent].group != node.group;
//  //      const ns = [{ ...node, current, expanded, depth, empty, restricted }];
//  //      if (expanded) {
//  //        ns.push(...children);
//  //      }
//  //      return ns;
//  //    });
//
//  // TODO optimize
//  // should be a circular dependecy proof version of above
//  const f = (list, parentId, depth = 0) => {
//    const level = [];
//    for (let i = 0; i < list.length; i++) {
//      if (
//        list[i].parent == parentId // ||
//        //(parentId == rootFile &&
//        //  graftBranches.some((node) => node.id == list[i].id))
//      ) {
//        level.push(list.splice(i--, 1)[0]);
//      }
//    }
//    return level.flatMap(node => deco(list, node, depth));
//  };
//
//  const deco = (list, node, depth = 0) => {
//    const children = f(list, node.id, depth + 1);
//    const current = node.id == nodeId;
//    const expanded = node.id in expandeds;
//    const empty = !children.length;
//    const restricted =
//      !node.parent ||
//      !(node.parent in nodes) ||
//      nodes[node.parent].group != node.group;
//    const ns = [{ ...node, current, expanded, depth, empty, restricted }];
//    if (expanded) {
//      ns.push(...children);
//    }
//    return ns;
//  };
//
//  const list = values(nodes).filter(
//    a => !graftBranches.some(b => a.id == b.id)
//  );
//
//  const x = [
//    ...f(list, rootFile),
//    ...graftBranches.flatMap(node => deco(list, node))
//  ];
//
//  console.log("getTree took", new Date - t0);
//
//  return x;
//};

export const getTree = (graph) => {
  //let t0 = new Date;
  graph = prepare(graph);

  const {
    current: { expandeds, nodeId },
  } = graph;
  const { zones, nodes, users, groups } = graph;

  const zoneId = getCurrentOrDefaultZoneId(graph);

  if (!zoneId) {
    return [];
  }

  const { rootGroup } = zones[zoneId];

  if (!(rootGroup in groups)) {
    return [];
  }

  const { rootFile } = groups[rootGroup];

  const zoneNodes = getNodesFromZone(graph, zoneId);

  const list = {};

  const graftRoots = [];

  for (const node of zoneNodes) {
    if (
      !node.parent &&
      node.id != rootFile &&
      groups[node.group].rootFile == node.id
    ) {
      graftRoots.push(node);
    }
  }

  for (const node of graftRoots) {
    node.parent = rootFile;
    node.graftRoot = true;
  }

  const generations = {};

  for (const node of zoneNodes) {
    if (node.parent) {
      if (node.parent in generations) {
        generations[node.parent].push(node);
      } else {
        generations[node.parent] = [node];
      }
    }
  }

  const f = (node, depth = -1) => {
    if (!(node.id in list)) {
      const item = {
        ...node,
        depth,
        empty: true,
        current: node.id == nodeId,
        expanded: node.id in expandeds,
        restricted:
          node.graftRoot ||
          !node.parent ||
          !(node.parent in nodes) ||
          nodes[node.parent].group != node.group,
      };
      if (depth != -1) {
        //list.push(item);
        list[node.id] = item;
      }
      const kids = generations[node.id];
      if (kids?.length) {
        item.empty = false;
        if (item.expanded || depth == -1) {
          for (const node of kids) {
            f(node, depth + 1);
          }
        }
      }
    }
  };

  f({ id: rootFile });

  for (const node of graftRoots) {
    delete node.parent;
    delete node.graftRoot;
  }

  const result = Object.values(list);

  //console.log("getTree took", new Date - t0);

  return result;
};

export const decorateTheTree = (graph, zone) => {
  const rootId = getRootNodeFromZone(graph, zone);

  if (!rootId) {
    return;
  }

  const root = graph.nodes[rootId];
  const nodes = getNodesFromZone(graph, zone);
  const tree = constructTree(root, nodes);

  const map = {};

  const bubbleUpTimestamp = (glue) => {
    if (!(glue.node.id in map)) {
      for (const kid of glue.kids) {
        bubbleUpTimestamp(kid);
      }
      glue.node.timestamp = Math.max(
        glue.node.timestamp,
        ...glue.kids.map((kid) => kid.node.timestamp)
      );
    }
  };

  bubbleUpTimestamp(tree);
};

export const decorateBranch = (branch, root) => {
  const tree = constructTree(root, branch);

  const map = {};

  const bubbleUpTimestamp = (glue) => {
    if (!(glue.node.id in map)) {
      for (const kid of glue.kids) {
        bubbleUpTimestamp(kid);
      }
      glue.node.timestamp = Math.max(
        glue.node.timestamp,
        ...glue.kids.map((kid) => kid.node.timestamp)
      );
    }
  };

  bubbleUpTimestamp(tree);
};

//export const getBranchFromLeaf = (graph, leaf) => {
//  const branch = [leaf];
//
//  while (leaf.parent && leaf.parent in graph.nodes) {
//    leaf = graph.nodes[leaf.parent];
//    branch.push(leaf);
//  }
//
//  return branch;
//};

//export const getBranchFromLeaf = (graph, leaf) => {
//  const branch = values(graph.nodes).filter(node => node.parent == leaf.parent);
//
//  if (!branch.some(node => node.id == lead.id)) {
//    branch.push(leaf);
//  }
//
//  while (true) {
//    const level = leafs.map(leaf => graph.nodes[parent]).filter(n => n);
//  }
//
//  const bottomUp = (parent) => {
//    const level = leafs.map(leaf => graph.nodes[parent]).filter(n => n);
//    if (level.length) {
//      branch.push(...level);
//      bottomUp(level[0].parent);
//    }
//  }
//
//  bottomUp(leaf.parent);
//
//  return branch;
//}
