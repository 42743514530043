import PublicECDSAKey from "../public_ecdsa_key";

import { importSJCLPublicKey, exportSJCLPublicKey } from "./sjcl";

import { assertInstanceOf } from "../../util";

import { Data } from "../../";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLPublicECDSAKey extends PublicECDSAKey {
  constructor(key) {
    super(key);
  }

  static async import(key, format) {
    return new SJCLPublicECDSAKey(
      await importSJCLPublicKey(key, format, sjcl.ecc.ecdsa)
    );
  }

  async export(format) {
    return exportSJCLPublicKey(this.key, format);
  }

  async verify(data, signature) {
    assertInstanceOf(Data, data, "data");
    assertInstanceOf(Data, signature, "signature");

    return this.key.verify(
      sjcl.hash.sha256.hash(sjcl.codec.bytes.toBits(data.getUint8Array())),
      sjcl.codec.bytes.toBits(signature.getUint8Array())
    );
  }
}
