import { createSlice } from "@reduxjs/toolkit";
import createApiClient from "api-client";
import { jwtPeek, createSagaActions } from "utils";
import { getToken } from "../../app/topSlice.js";
import { API_URL_PREFIX, API_URL_PREFIX_NOTIFY } from "../../config";

const blueprint = {
  name: "activity",
  initialState: {
    loading: true,
    filter: {},
    notificationAddress: undefined
  },
  reducers: {
    clear: state => {
      state.filter = {};
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setFilter: (state, { payload: { team, filters } }) => {
      state.filter[team] = filters;
    },
    setNotificationAddress: (state, { payload: notificationAddress }) => {
      state.notificationAddress = notificationAddress;
    }
  },
  sagas: {
    fetchSettings: async ({}, dispatch, getState) => {
      await dispatch(setLoading(true));
      await dispatch(clear());

      const api = createApiClient(API_URL_PREFIX);
      const token = await dispatch(getToken());
      const { user } = jwtPeek(token);

      // Fetch filters
      const timezoneOffset = new Date().getTimezoneOffset() || 0;
      const url = `${API_URL_PREFIX_NOTIFY}/filters?userId=${user}&timezoneOffset=${timezoneOffset}`;
      const filter = await api.token(token).rawGet(url);
      for (const { project, filters } of filter) {
        await dispatch(setFilter({ team: project, filters }));
      }

      // Fetch notification address
      const { email, subscribed } = await api.users(user).get({
        fields: {
          email: true,
          subscribed: true
        }
      });
      await dispatch(setNotificationAddress(subscribed ? email : undefined));

      await dispatch(setLoading(false));
    },
    changeFilter: async (
      { payload: { team, filter: changedFilter } },
      dispatch,
      getState
    ) => {
      const token = await dispatch(getToken());
      const { user, org } = jwtPeek(token);

      // Update old filter
      const { filter: oldFilter } = getState();
      const updatedFilter = oldFilter[team].map(
        filter =>
          filter.channelType === changedFilter.channelType
            ? changedFilter
            : filter
      );

      // Update remotely
      const api = createApiClient(API_URL_PREFIX);
      await api.token(token).raw({
        url: `${API_URL_PREFIX_NOTIFY}/filters`,
        method: "PATCH",
        body: JSON.stringify({
          userId: user,
          org,
          filters: [
            {
              filters: updatedFilter,
              project: team
            }
          ],
          timezoneOffset: new Date().getTimezoneOffset() || 0
        })
      });

      // Update locally
      await dispatch(setFilter({ team, filters: updatedFilter }));
    },
    changeNotificationAddress: async (
      { payload: { notificationAddress } },
      dispatch,
      getState
    ) => {
      const token = await dispatch(getToken());
      const { user } = jwtPeek(token);

      // Update remotely
      const api = createApiClient(API_URL_PREFIX);
      if (notificationAddress) {
        // Change notification address
        await api
          .token(token)
          .users(user)
          .patch({
            subscribed: true,
            email: notificationAddress
          });
      } else {
        // Unsubscribe
        await api
          .token(token)
          .users(user)
          .patch({
            subscribed: false
          });
      }

      // Update locally
      await dispatch(setNotificationAddress(notificationAddress));
    }
  }
};

export const slice = createSlice(blueprint);

export const {
  clear,
  setLoading,
  setFilter,
  setNotificationAddress
} = slice.actions;

export const {
  fetchSettings,
  changeFilter,
  changeNotificationAddress
} = createSagaActions(blueprint);

export const selectLoading = state => state.activity.loading;
export const selectFilter = state => state.activity.filter;
export const selectNotificationAddress = state =>
  state.activity.notificationAddress;

export const activitySagas = blueprint.sagas;

export default slice.reducer;
