import {
  // Classes
  RiksKey,
  RiksMessage,

  // Constants
  KEY,
  KEY_ID,
  IMMUTABLE,
  MESSAGE_NONCE_LENGTH_IN_BYTES,
  NONCE,
  RIKS_KEY_ID_LENGTH_IN_BYTES,
  SECRET,
  SENDER,
  SIGNATURE,
  SIGNATURE_LENGTH_IN_BYTES,
  TYPE,
  TYPE_DENY,
  TYPE_KEY,
  TYPE_MESSAGE,
  VERSION,
  VERSION_VALUE
} from "./";

import {
  assertInstanceOf,
  assertInteger,
  assertNonEmptyString,
  assertObject,
  assertPEM,
  assertPort,
  assertString,
  isInstanceOf
} from "../util";

import * as crypto from "../crypto";

import {
  Cryptobox,
  Credentials,
  KDS_HOST,
  KDS_PORT,
  KDS_API_KEY,
  KDS_ROOT_CERTIFICATE
} from "../cryptobox";

import { CBOR, Data } from "../";

/*******************/

function assertGoodConfig(config) {
  assertObject(config, "config");
  assertString(config[KDS_HOST], `config.${KDS_HOST}`);
  assertPort(config[KDS_PORT], `config.${KDS_PORT}`);
  assertString(config[KDS_API_KEY], `config.${KDS_API_KEY}`);
  assertPEM(config[KDS_ROOT_CERTIFICATE], `config.${KDS_ROOT_CERTIFICATE}`);
}

export default class RiksKit {
  static async register(uid, password, config) {
    // Sanitize arguments
    assertNonEmptyString(uid, "uid");
    assertNonEmptyString(password, "password");
    assertGoodConfig(config);

    return Cryptobox.register(uid, password, config);
  }

  static async start(uid, credentials, whitelist, config) {
    // Sanitize arguments
    assertNonEmptyString(uid, "uid");
    assertInstanceOf(Credentials, credentials, "credentials");
    assertGoodConfig(config);

    return new RiksKit(
      uid,
      await Cryptobox.start(uid, credentials, config),
      whitelist,
      config
    );
  }

  static async parseCredentials(credentials) {
    return Credentials.parse(credentials);
  }

  constructor(uid, cryptobox, whitelist) {
    // Sanitize arguments
    assertNonEmptyString(uid, "uid");

    this.uid = uid;
    this.cryptobox = cryptobox;
    //this.cache = (storage.open(CACHE_FILE), password);
    //this.keySharer = (uid, cryptobox, cache, whitelist, config[KEY_RELAY_ENABLED]);
  }

  generateKey() {
    return new RiksKey();
  }

  async preshareKey(recipientUID, key) {
    // Sanitize arguments
    assertNonEmptyString(recipientUID, "recipientUID");
    assertInstanceOf(RiksKey, key, "key");

    let response = await this.cryptobox.encrypt(
      recipientUID,
      CBOR.encode({
        [TYPE]: TYPE_KEY,
        [VERSION]: VERSION_VALUE,
        [SENDER]: this.uid,
        [KEY_ID]: key.keyID,
        [KEY]: key.keyData,
        [SIGNATURE]: await this.cryptobox.sign(
          // Signed data
          Data.join([
            Data.fromUTF8(TYPE_KEY),
            Data.fromUTF8(VERSION_VALUE),
            Data.fromUTF8(this.uid),
            key.keyID,
            key.keyData
          ])
        )
      })
    );

    // Add 'from' field to returned CBOR
    response = CBOR.decode(response);
    response.from = this.uid;
    response = CBOR.encode(response);

    return response;
  }

  async onResponse(response) {
    // Sanitize arguments
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      response,
      "response"
    );

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array, Buffer], response)) {
      response = new Data(response);
    }

    const from = CBOR.decode(response).from;
    const message = CBOR.decode(await this.cryptobox.decrypt(from, response));

    switch (message[TYPE]) {
      // Access granted
      case TYPE_KEY:
        {
          if (
            !await this.cryptobox.verify(
              // Signed data
              Data.join([
                Data.fromUTF8(TYPE_KEY),
                Data.fromUTF8(message[VERSION]),
                Data.fromUTF8(message[SENDER]),
                message[KEY_ID],
                message[KEY]
              ]),
              // Signature
              message[SIGNATURE],
              // Sender UID
              message[SENDER]
            )
          ) {
            throw new Error("Invalid sign on response.");
          }

          return new RiksKey(message[KEY_ID], message[KEY]);
        }
        break;

      // Access denied
      case TYPE_DENY:
        {
          if (
            !await this.cryptobox.verify(
              // Signed data
              Data.join([
                Data.fromUTF8(TYPE_DENY),
                Data.fromUTF8(message[VERSION]),
                Data.fromUTF8(message[SENDER]),
                message[KEY_ID]
              ]),
              // Signature
              message[SIGNATURE],
              // Sender UID
              message[SENDER]
            )
          ) {
            throw new Error("Invalid sign on response.");
          }

          throw new Error("Access denied.");
        }
        break;

      // Unknown response
      default:
        throw new Error("Unknown response.");
    }
  }

  async encrypt(message, key) {
    // Sanitize arguments
    assertInstanceOf(RiksMessage, message, "message");
    assertInstanceOf(RiksKey, key, "key");

    const nonce = crypto.random(MESSAGE_NONCE_LENGTH_IN_BYTES);

    return CBOR.encode({
      [TYPE]: TYPE_MESSAGE,
      [VERSION]: VERSION_VALUE,
      [SENDER]: this.uid,
      [KEY_ID]: key.keyID,
      [NONCE]: nonce,
      [SECRET]: await crypto.encrypt(
        // Data
        message.secret,
        // Secret key
        await key.getSecretKey(),
        // AAD
        Data.join([Data.fromUTF8(this.uid), nonce])
      ),
      [IMMUTABLE]: message.immutable,
      [SIGNATURE]: await this.cryptobox.sign(
        // Signed data
        Data.join([
          Data.fromUTF8(TYPE_MESSAGE),
          Data.fromUTF8(VERSION_VALUE),
          Data.fromUTF8(this.uid),
          key.keyID,
          nonce,
          message.secret,
          message.immutable
        ])
      )
    });
  }

  async decrypt(data, key) {
    // Sanitize arguments
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      data,
      "data"
    );
    assertInstanceOf(RiksKey, key, "key");

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array, Buffer], data))
      data = new Data(data);

    // Decompose
    const message = CBOR.decode(data);

    // Decrypt
    const secret = await crypto.decrypt(
      message[SECRET],
      await key.getSecretKey(),
      Data.join([Data.fromUTF8(message[SENDER]), message[NONCE]])
    );

    // Verify
    if (
      !await this.cryptobox.verify(
        // Signed data
        Data.join([
          Data.fromUTF8(TYPE_MESSAGE),
          Data.fromUTF8(message[VERSION]),
          Data.fromUTF8(message[SENDER]),
          key.keyID,
          message[NONCE],
          secret,
          message[IMMUTABLE] || new Data(0)
        ]),
        // Signature
        message[SIGNATURE],
        // Sender UID
        message[SENDER]
      )
    ) {
      throw new Error("Invalid sign on message.");
    }

    return new RiksMessage(secret, message[IMMUTABLE]);
  }

  getEncryptedLength(secretLengthOrMessage = 0, immutableLength = 0) {
    // Sanitize arguments
    if (isInstanceOf(RiksMessage, secretLengthOrMessage)) {
      if (immutableLength !== 0)
        throw new Error(
          `immutableLength must be 0 or undefined if first argument is a RiksMessage object.`
        );

      return this.getEncryptedLength(
        secretLengthOrMessage.secret.length,
        secretLengthOrMessage.immutable.length
      );
    }
    assertInteger(secretLengthOrMessage, "secretLengthOrMessage");
    assertInteger(immutableLength, "immutableLength");

    return new LengthCalculator(this.uid).getEncryptedLength(
      secretLengthOrMessage,
      immutableLength
    );
  }

  static getDecryptedLength(uid, encryptedLength = 0, immutableLength = 0) {
    assertString(uid, "uid");
    assertInteger(
      encryptedLengthOrEncryptedData,
      "encryptedLengthOrEncryptedData"
    );
    assertInteger(immutableLength, "immutableLength");

    return new LengthCalculator(uid).getDecryptedLength(
      encryptedLength,
      immutableLength
    );
  }
}

class LengthCalculator {
  constructor(uid) {
    this.overhead =
      1 +
      this.getEncodedPairLength(TYPE.length, TYPE_MESSAGE.length) +
      this.getEncodedPairLength(VERSION.length, VERSION_VALUE.length) +
      this.getEncodedPairLength(SENDER.length, uid.length) +
      this.getEncodedPairLength(KEY_ID.length, RIKS_KEY_ID_LENGTH_IN_BYTES) +
      this.getEncodedPairLength(NONCE.length, MESSAGE_NONCE_LENGTH_IN_BYTES) +
      this.getEncodedPairLength(SIGNATURE.length, SIGNATURE_LENGTH_IN_BYTES);
  }

  getEncryptedLength(secretLength, immutableLength) {
    return (
      this.overhead +
      this.getEncodedPairLength(
        SECRET.length,
        crypto.getEncryptedLength(secretLength)
      ) +
      this.getEncodedPairLength(IMMUTABLE.length, immutableLength)
    );
  }

  getDecryptedLength(messageLength, immutableLength) {
    return crypto.getDecryptedLength(
      this.getDecodedItemLength(
        messageLength -
          this.overhead -
          this.getEncodedItemLength(SECRET.length) -
          this.getEncodedPairLength(IMMUTABLE.length, immutableLength)
      )
    );
  }

  getItemLength(length, encoded) {
    if (length <= 23) {
      return length + (1 + 0) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FF", 16)) {
      return length + (1 + 1) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFF", 16)) {
      return length + (1 + 2) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFFFFFF", 16)) {
      return length + (1 + 4) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFFFFFFFFFFFFFF", 16)) {
      return length + (1 + 8) * (encoded ? 1 : -1);
    } else {
      throw new Error("Item too big.");
    }
  }

  getEncodedItemLength(length) {
    return this.getItemLength(length, true);
  }

  getDecodedItemLength(length) {
    return this.getItemLength(length, false);
  }

  getEncodedPairLength(keyLength, valueLength) {
    return (
      this.getEncodedItemLength(keyLength) +
      this.getEncodedItemLength(valueLength)
    );
  }
}
