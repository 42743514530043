import { createSlice } from "@reduxjs/toolkit";

import createTrustKitSlice from "trust-kit";

import { getToken, getContext } from "./topSlice.js";

import * as config from "../config.js";

const { reducers, sagas, actions, auxiliary, selectors } = createTrustKitSlice({
  name: "trust",
  config,
  createSlice,
  actions: {
    getToken,
    getContext
  }
});

const trustKit = {
  actions,
  auxiliary,
  selectors
};

export default reducers;

export { sagas, trustKit };
