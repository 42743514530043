import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { pick, mapObj, assign, classNames } from "utils";

import { PANE_ORDER, PANE_VIPS, PANE_ADMIN, PANE_USER } from "./layoutSlice.js";

import {
  syncPanes,
  toggleMenu,
  toggleFooter,
  selectPaneCeil,
  selectIsMobile,
  selectIsMiddle,
  selectIsTablet,
  selectIsLandscape,
  selectShowHeader,
  selectShowFooter,
  selectPaneList,
  selectPaneAdded,
  selectPaneRemoved,
} from "./layoutSlice.js";

import styles from "./Layout.module.css";
import { selectBullhorn } from "../../app/appSlice.js";

const paneComparator = (a, b) =>
  PANE_ORDER.indexOf(a.pane) - PANE_ORDER.indexOf(b.pane);

export const Layout = React.memo((props) => {
  const { tabs, header: Header, footer: Footer, menu: Menu } = props;

  const dispatch = useDispatch();

  const ceil = useSelector(selectPaneCeil);
  const list = useSelector(selectPaneList);
  const isMobile = useSelector(selectIsMobile);
  const isMiddle = useSelector(selectIsMiddle);
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  const showHeader = useSelector(selectShowHeader);
  const showFooter = useSelector(selectShowFooter);
  const paneAdded = useSelector(selectPaneAdded);
  const paneRemoved = useSelector(selectPaneRemoved);
  const bullhorn = useSelector(selectBullhorn);

  const panes = list.slice(-ceil);
  panes.sort(paneComparator);

  const menuWidth = isMobile ? 0 : isMiddle ? 275 : 350;
  const headerHeight =
    isMobile && isLandscape ? 54 : isMobile ? 160 : isTablet ? 130 : 70;

  const realEstateBudget = ceil - panes.length;

  return (
    <div
      className={classNames(
        styles.outer,
        realEstateBudget < 2 && `real_estate_warning`,
        realEstateBudget < 1 && `real_estate_danger`
      )}
    >
      {!bullhorn.hideMenu && (
        <div style={{ width: menuWidth }} className={styles.left}>
          <Menu />
        </div>
      )}
      <div className={styles.right}>
        <div className={styles.top} style={{ height: headerHeight }}>
          {showHeader && <Header />}
        </div>
        <TransitionGroup
          className={classNames(styles.middle, paneRemoved && styles.rm)}
        >
          {panes.map((pane) => {
            const Comp = tabs[pane.pane];
            return (
              <CSSTransition
                key={pane.pane}
                classNames={{ ...styles }}
                timeout={500}
                onEntered={() => {
                  dispatch(syncPanes());
                }}
                onExited={() => {
                  dispatch(syncPanes());
                }}
              >
                <Pane key={pane.pane} pane={pane.pane}>
                  <Comp
                    ani={pane.pane == paneAdded}
                    realEstateBudget={realEstateBudget}
                    isMobile={isMobile}
                    isLandscape={isLandscape}
                    pane={pane}
                  />
                </Pane>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
        {showFooter && (
          <div className={styles.bottom}>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
});

const Pane = React.memo(({ pane, children }) => {
  const vip = PANE_VIPS.includes(pane);
  const scope = useRef({});
  const ref = useCallback((el) => {
    const { current } = scope;
    current.observer?.disconnect();
    if (el) {
      current.observer = new ResizeObserver(() => {
        if (!el.classList.contains(styles.exit)) {
          const { innerWidth, innerHeight } = window;
          let { top, right, bottom, left } = el.getBoundingClientRect();
          right = innerWidth - right;
          bottom = innerHeight - bottom;
          const kid = el.firstElementChild;
          if (top != current.top) {
            current.top = top;
            kid.style.top = top + "px";
          }
          if (right != current.right) {
            current.right = right;
            kid.style.right = right + "px";
          }
          if (bottom != current.bottom) {
            current.bottom = bottom;
            kid.style.bottom = bottom + "px";
          }
          if (left != current.left) {
            current.left = left;
            kid.style.left = left + "px";
          }
        }
      });
      current.observer.observe(el);
    }
  });
  return (
    <div ref={ref} className={classNames(styles.aaa, vip && styles.vip)}>
      <div className={styles.bbb}>
        <div className={styles.tile}>{children}</div>
      </div>
    </div>
  );
});
