import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import { BankID, TwoFA, Email, SSO } from "./icons";
import EsignatureIcon from "../esignature/esignatureIcon.js";
import {
  setIdentity,
  setProvider,
  setDeviceId,
  setUserId,
  setOrgId,
  performLogin,
  selectProgress,
  cancel,
  selectAuthInfo,
  selectUserLogins,
  startBankIdAuthentication,
  sendPhoneNumberToKeyper,
  setView,
  selectStatus,
  triggerSAML,
  resetKeyper,
  setAbort,
  setHub,
} from "./loginSlice";
//import {selectSignedDocumentInfo } from "features/esignature/esignatureSlice";
import { selectIsMobile } from "../layout/layoutSlice.js";
import { classNames } from "utils";
import { AuthInfo, User, UserHistory } from "../../types/index";
import Option from "./option";
import { API_URL_SUB, KONFIDENT_URL } from "../../config";
import { formatSocialSecurityNumber } from "features/format/format";
import { inBullhorn } from "app/topSlice";
import styles from "./Login.module.css";

interface CardProps extends UserHistory {
  index: number;
  loadingIndex: number | null;
  bankIdCounter: number;
  handleProgress: (props: ProgressProps) => void;
}

interface ProgressProps
  extends Pick<
    UserHistory,
    | "device"
    | "user"
    | "org"
    | "provider"
    | "swedish_personal_number"
    | "address"
    | "samlIdProvider"
  > {
  index: number;
  phoneNumber: string;
}

const Hub: React.FC = ({}) => {
  const dispatch = useDispatch();
  const logins = useSelector(selectUserLogins);
  const status = useSelector(selectStatus);
  const isMobile = useSelector(selectIsMobile);
  const progress = useSelector(selectProgress);
  const authInfo = useSelector(selectAuthInfo);
  const swedishBankIds: string[] = authInfo?.swedishBankIds;
  const [loadingIndex, setLoadingIndex] = useState<null | number>(null);

  useEffect(() => {
    return () => {
      setLoadingIndex(null);
    };
  }, []);

  const handleProgress = async (props: ProgressProps): Promise<void> => {
    const {
      index,
      device,
      user,
      org,
      provider,
      phoneNumber,
      swedish_personal_number,
      address,
      samlIdProvider,
    } = props;

    setLoadingIndex(index);
    dispatch(setDeviceId(device));
    dispatch(setUserId(user));
    dispatch(setOrgId(org));
    dispatch(setProvider(provider));

    switch (provider) {
      case "2fa":
        dispatch(setIdentity([address, phoneNumber]));
        await dispatch(sendPhoneNumberToKeyper({ phoneNumber }));
        dispatch(setView("PIN2FA"));
        break;
      case "bankid":
        if (status === "authenticated") {
          await dispatch(performLogin());
        } else {
          dispatch(setIdentity([swedish_personal_number]));
          await dispatch(startBankIdAuthentication(false));
          dispatch(setHub(true));
          dispatch(setView("BANKID"));
        }
        break;
      case "placeholder":
        dispatch(setIdentity([]));
        await dispatch(startBankIdAuthentication(false));
        dispatch(setHub(true));
        dispatch(setView("BANKID"));
        break;
      case "email":
        dispatch(setIdentity([address]));
        await dispatch(performLogin());
        break;
      case "saml":
        dispatch(triggerSAML(samlIdProvider));
        break;
    }
  };

  const extractedData: UserHistory[] = logins
    .map(
      ({
        device,
        latestActivity,
        numberOfTeams,
        org,
        provider,
        wellknown,
        user,
        teams,
      }: UserHistory & { wellknown: User["wellknown"] }) => {
        let type: UserHistory["type"] = "email";
        let address: UserHistory["address"] = "";
        let swedish_personal_number: UserHistory["swedish_personal_number"] =
          "";
        let phone_number: UserHistory["phone_number"] = "";
        let samlIdProvider: UserHistory["samlIdProvider"] = "";

        if (provider === "email") {
          type = "email";
          address = wellknown[0] || "";
        } else if (provider === "2fa") {
          type = "2fa";
          address = wellknown[0] || "";
          phone_number = wellknown[1] || "";
        } else if (provider === "bankid") {
          type = "bankid";
          swedish_personal_number = wellknown[0] || "";
        } else if (provider === "placeholder") {
          type = "bankid";
        } else if (provider === "saml") {
          type = "saml";
          samlIdProvider = wellknown[0] || "";
        }

        return {
          device,
          latestActivity,
          numberOfTeams,
          org,
          provider,
          type,
          address,
          swedish_personal_number,
          phone_number,
          samlIdProvider,
          user,
          teams,
        };
      }
    )
    .filter((obj: UserHistory | null): obj is UserHistory => {
      if (inBullhorn && obj && obj.provider !== "2fa") {
        return false;
      }
      return true;
    });

  const providerInfo = "No login providers were found.";

  const inviteInfo = (
    <div
      className={classNames(styles.hubInfo, isMobile && styles.hubInfoMobile)}
    >
      <span>
        If you've received an invitation, please log in with your email for the
        first time. If you haven't received an invitation but would like to use
        Konfident, click <a href={KONFIDENT_URL}>Sign up</a> for more
        information.
      </span>
    </div>
  );

  const bankIdCounter: CardProps["bankIdCounter"] = extractedData.filter(
    (obj: UserHistory) => ["bankid", "placeholder"].includes(obj.provider)
  ).length;

  const isProvider: boolean = extractedData.length > 0;

  const handleCancel = (): void => {
    if (progress === "WAITING" || loadingIndex !== null) {
      dispatch(setAbort(true));
    }
    dispatch(resetKeyper());
    dispatch(cancel());
  };

  return (
    <>
      {extractedData.map((data, index) => (
        <Card
          key={index}
          index={index}
          loadingIndex={loadingIndex}
          handleProgress={handleProgress}
          bankIdCounter={bankIdCounter}
          {...data}
        />
      ))}
      {bankIdCounter === 0 && !inBullhorn && (
        <div
          className={
            isMobile ? styles.bankidDirectMobile : styles.bankidDirectContainer
          }
        >
          <div className={styles.bankidDirect}>
            <Option />
          </div>
        </div>
      )}
      <Identity />
      <div className={styles.undoContainer}>
        <div className={classNames(styles.undo)} onClick={() => handleCancel()}>
          <Icon icon="undo" />
        </div>
      </div>
      {!isProvider && (
        <div className={isMobile ? styles.noProviderMobile : ""}>
          <div className={styles.welcome}>{providerInfo}</div>
          {swedishBankIds && swedishBankIds.length !== 0 && inviteInfo}
        </div>
      )}
    </>
  );
};

let esignatureLaunchUrlZoneId = "";
{
  const args = window.location.pathname.replace(/^\/+/, "").split("/");
  let [what] = args;
  if (what?.toLowerCase() == "esignature") {
    [, esignatureLaunchUrlZoneId] = args;
  }
}

const Card: React.FC<CardProps> = ({
  index,
  loadingIndex,
  handleProgress,
  bankIdCounter,
  device,
  latestActivity,
  numberOfTeams,
  org,
  provider,
  address,
  swedish_personal_number,
  phone_number,
  samlIdProvider,
  user,
  teams,
}) => {
  const progress = useSelector(selectProgress);
  //const { zoneId } = useSelector(selectSignedDocumentInfo);
  const isLoadingCard: boolean = loadingIndex === index;

  const maskSSN = (swedish_personal_number: string): string => {
    const firstPart: string = "****" + swedish_personal_number.substring(4, 8);
    const lastPart: string =
      "****" +
      swedish_personal_number.charAt(swedish_personal_number.length - 1);
    return `${firstPart}${lastPart}`;
  };

  const maskPhoneNumber = (phone_number: string): string => {
    const firstPart: string = phone_number.substring(0, 3);
    const lastPart: string = phone_number.substring(phone_number.length - 2);
    return `${firstPart}*****${lastPart}`;
  };

  const maskedSSN: string = maskSSN(swedish_personal_number as string);
  const maskedPhoneNumber: string = maskPhoneNumber(phone_number as string);

  const isSSNVisible: boolean =
    (provider === "bankid" || provider === "placeholder") && bankIdCounter > 0;

  let loginIcon: JSX.Element = <Icon icon="help" />;
  let providerClassName: string = "";
  let buttonText: string = "login";

  if (provider === "email") {
    loginIcon = <Email size={24} />;
    providerClassName = styles.providerEmail;
  } else if (provider === "2fa") {
    loginIcon = <TwoFA size={24} />;
    providerClassName = styles.providerTwoFa;
    buttonText = maskedPhoneNumber;
  } else if (isSSNVisible) {
    loginIcon = <BankID size={24} />;
    providerClassName = styles.providerBankId;
    buttonText = provider === "placeholder" ? "Login" : maskedSSN;
  } else if (provider === "bankid" || provider === "placeholder") {
    loginIcon = <BankID size={24} />;
    providerClassName = styles.providerBankId;
  } else if (provider === "saml") {
    loginIcon = <SSO size={24} background={false} />;
    providerClassName = styles.providerSso;
    buttonText = samlIdProvider;
  }

  const initials: string = org.slice(0, 2).toUpperCase();
  const isWaiting: boolean = progress === "WAITING" || loadingIndex !== null;
  const isEsignature: boolean =
    teams.length > 0 && teams.includes(esignatureLaunchUrlZoneId);

  const combinedClassNames: string = classNames(
    styles.card,
    providerClassName,
    isWaiting && styles.waiting,
    isLoadingCard && styles.loading,
    isEsignature && styles.relative
  );

  const activityDate: Date | string = latestActivity
    ? new Date(latestActivity).toLocaleDateString("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    : "No activity yet";

  const esignatureNotice = (
    <div className={classNames(styles.esignatureNotice, styles.absolute)}>
      <EsignatureIcon
        width="20"
        height="20"
        className={styles.esignIcon}
        onClick={undefined}
      />
    </div>
  );

  return (
    <div
      className={classNames(combinedClassNames)}
      onClick={() =>
        handleProgress({
          index,
          device,
          org,
          provider,
          address,
          swedish_personal_number,
          phoneNumber: phone_number,
          user,
          samlIdProvider,
        })
      }
    >
      {isEsignature && esignatureNotice}
      <div className={styles.avatar}>{initials}</div>
      <div className={styles.org}>{org}</div>
      <div>Teams</div>
      <span>{numberOfTeams}</span>
      <div>Latest activity</div>
      <span>{activityDate}</span>
      {isLoadingCard ? (
        <div className={styles.spinner}>
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      ) : (
        <div className={styles.login}>
          {loginIcon}
          <span>{buttonText}</span>
          <Icon icon="log-in" />
        </div>
      )}
    </div>
  );
};

const Identity: React.FC = () => {
  const authInfo: AuthInfo = useSelector(selectAuthInfo);
  const isMobile = useSelector(selectIsMobile);

  const validateSSNString = (ssn: string): boolean => {
    if (typeof ssn !== "string" || ssn.length !== 12) {
      return false;
    }
    return /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(-|\s*)?\d{4}$/.test(
      ssn
    );
  };

  const formatName = (name: string) => {
    if (!name) {
      return "User not found";
    }
    const names = name.split("@")[0].split(".");
    const capitalizedNames: string[] = names.map(
      (name: string) => name.charAt(0).toUpperCase() + name.slice(1)
    );
    return capitalizedNames.join(" ");
  };

  const displayIdentifier = () => {
    const authFlat = Object.values(authInfo).flat();
    const authValue = authFlat[0];
    const displaySSN = validateSSNString(authValue as string);
    const displayValue = displaySSN
      ? formatSocialSecurityNumber(authValue)
      : isMobile
      ? formatName(authValue as string)
      : authValue
      ? authValue
      : "not found";
    return <>{displayValue}</>;
  };

  return (
    <div
      className={classNames(styles.identityContainer, styles.identityMobile)}
    >
      <div className={styles.identity}>
        <Icon icon="shield" />
        <div> {displayIdentifier()}</div>
      </div>
    </div>
  );
};

export default Hub;
