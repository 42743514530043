import React from "react";

const SIZES = [
  { name: "TB", size: 10 ** 12 },
  { name: "GB", size: 10 ** 9 },
  { name: "MB", size: 10 ** 6 },
  { name: "KB", size: 10 ** 3 },
  { name: "B", size: 1 }
];

const FLOOR = Math.floor.bind(Math);
const ROUND = (a, nr = 2) => Math.round(a * 10 ** nr) / 10 ** nr;

export default ({ bytes }) => {
  for (let { name, size } of SIZES) {
    const rounded = ROUND(bytes / size);
    if (rounded >= 1) {
      return `${rounded}${name}`;
    }
  }
  return "0B";
};
