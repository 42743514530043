import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancel, resetKeyper, selectAuthInfo } from "./loginSlice";
import { SSO, Cancel } from "./icons";
import { classNames } from "utils";
import { AuthInfo } from "types";
import styles from "./Login.module.css";

const Sso: React.FC = ({ }) => {
  const dispatch = useDispatch();

  const handleCancel = (): void => {
    dispatch(resetKeyper());
    dispatch(cancel());
  }

  return (
    <div className={classNames(styles.method, styles.active)}>
      <div className={styles.dialog}>
        <div className={styles.header}>
          <SSO size={34} background={false} />
          <Cancel size={34} onClick={() => handleCancel()} />
        </div>
        <div className={styles.middle}>
          <SsoError />
        </div>
      </div>
    </div>
  );
};

const SsoError: React.FC = ({ }) => {
  const authInfo: AuthInfo = useSelector(selectAuthInfo);

  const getAddress = (authInfo: AuthInfo) => {
    const authFlat = Object.values(authInfo).flat();
    const authValue = authFlat[0] as { address: string };
    const { address } = authValue;
    return address;
  }

  const address = getAddress(authInfo);

  return (
    <div className={styles.error}>
      <span>SAML SSO Error:</span>
      <span>User <span>{address}</span> not found.</span>
      <span>Please verify credentials and retry.</span>
      <span>Contact SSO provider if issues persist.</span>
    </div>
  );
}

export default Sso;