import { getClassName } from "./class_name";

class AssertionError extends Error {
  constructor(message, name) {
    super(message);
    this.name = name;
  }
}

export function assertString(value, name) {
  if (typeof value !== "string")
    throw new AssertionError(
      `${name} must be a string. (got ${getClassName(value)})`,
      name
    );
}

export function assertNonEmptyString(value, name) {
  assertString(value, name);
  if (value.length == 0)
    throw new AssertionError(`${name} must not be empty.`, name);
}

export function assertObject(value, name) {
  if (!(value instanceof Object))
    throw new AssertionError(
      `${name} must be an object. (got ${getClassName(value)})`,
      name
    );
}

export function assertInteger(value, name) {
  if (!Number.isInteger(value))
    throw new AssertionError(
      `${name} must be a number. (got ${getClassName(value)})`,
      name
    );
}

export function assertPort(value, name) {
  assertInteger(value, name);

  if (value < 1 || value > 65536)
    throw new AssertionError(
      `${name} must be between 1 and 65536. (got ${value})`,
      name
    );
}

export function assertPEM(value, name) {
  assertString(value, name);

  if (
    !/(-+BEGIN CERTIFICATE-+)([a-zA-Z0-9/=+\r\n]*?)(-+END CERTIFICATE-+)/.test(
      value
    )
  ) {
    throw new AssertionError(`${name} must be valid PEM. (got ${value})`, name);
  }
}

export function assertInstanceOf(type, value, name) {
  if (!isInstanceOf(type, value)) {
    if (type instanceof Array) {
      throw new AssertionError(
        `${name} must be one of [${type
          .map(type => type.name)
          .join(", ")}]. (got ${getClassName(value)})`,
        name
      );
    } else {
      throw new AssertionError(
        `${name} must be a ${type.name}. (got ${getClassName(value)})`,
        name
      );
    }
  }
}

export function isInstanceOf(types, value) {
  if (!(types instanceof Array)) {
    types = [types];
  }
  //TODO only use instance of
  //(But cant since app-konfident preforms ({Data} = require('risk-js')) and in trust-kit loads in the same class
  //effectively leading to two identical classes called Data

  return types.some(
    type => value instanceof type || getClassName(value) === type.name
  );
}
