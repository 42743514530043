import React, { memo, useState, useContext } from "react";
import UserPicker from "../users/user-picker.js";
import appContext from "../../app/appContext.js";
import styles from "./Dialogs.module.css";

export default ({ dispatch, active, close }) => {
  const { groupId, nodeId, zoneId, zoneName } = useContext(appContext);

  return <UserPicker {...{ active, close, zoneId, zoneName }} />;
};
