import "./Sheet.css";

import { loadScript } from "./utils";

export const createBackend = (el, bytes, mime) => {
  let theInstance;

  const create = async () => {
    //const jq = "jquery.1.12.3.min.js";
    const jq = "jquery-3.6.0.min.js";
    //const src = "CellJS.bundle.js";
    const src = "CellJS.bundle.min.js";

    let resolve,
      promise = new Promise((r) => (resolve = r));

    loadScript(jq, () => {
      loadScript(src, () => {
        resolve(window.CellJS);
      });
    });

    const CellJS = await promise;

    if (typeof CellJS === "undefined") {
      throw new Error("failed to load doc viewer");
    }
    const instance = new CellJS();

    let div = document.createElement("div");

    el.innerHTML = "";
    el.appendChild(div);

    let blob = new Blob([bytes], { type: mime });

    instance.parse(
      blob,
      () =>
        instance.render(
          div,
          () => {
            theInstance = instance;
          },
          3
        ),
      (e) => console.error(e)
    );
  };

  let destroyed = false;

  const destroy = () => {
    if (!destroyed) {
      destroyed = true;
      el.innerHTML = "";
    }
  };

  let currentValue = 1;

  const zoomOut = () => {
    let intervalValue = 0.25;
    if (currentValue > 3) {
      intervalValue = 1;
    } else if (currentValue > 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.max(0.5, currentValue - intervalValue);
    theInstance.setZoom(currentValue);
  };

  const zoomIn = () => {
    let intervalValue = 0.25;
    if (currentValue >= 3) {
      intervalValue = 1;
    } else if (currentValue >= 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.min(3, currentValue + intervalValue);
    theInstance.setZoom(currentValue);
  };

  const navPrev = () => {
    const fileInfo = theInstance.getFileInfo();
    const sheetNames = fileInfo.sheetNames.filter((_) => _); //for some reason some sheet names are empty

    if (sheetNames) {
      const currentId = theInstance.getCurrentId();
      let nextSheetIndex = 0;

      for (let s of sheetNames) {
        if (s.sheetId === currentId) {
          nextSheetIndex =
            (sheetNames.indexOf(s) - 1 + sheetNames.length) % sheetNames.length;
        }
      }
      theInstance.gotoPage(sheetNames[nextSheetIndex].sheetId);
    }
  };

  const navNext = () => {
    const fileInfo = theInstance.getFileInfo();

    //for some reason some sheet names are empty
    const sheetNames = fileInfo.sheetNames.filter((_) => _);

    if (sheetNames) {
      const currentId = theInstance.getCurrentId();
      let nextSheetIndex = 0;

      for (let s of sheetNames) {
        if (s.sheetId === currentId) {
          nextSheetIndex = (sheetNames.indexOf(s) + 1) % sheetNames.length;
        }
      }
      theInstance.gotoPage(sheetNames[nextSheetIndex].sheetId);
    }
  };

  const navLast = () => {
    console.log("last!");
  };

  return {
    promise: create(),
    destroy,
    zoomOut,
    zoomIn,
    navPrev,
    navNext,
    navLast,
  };
};
