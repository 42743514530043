import React, { useState } from "react";
import { Button, Icon } from "@blueprintjs/core";
import parsePhoneNumber from "libphonenumber-js";
import { classNames } from "utils";

import { validateEmail } from "./email";

import styles from "./index.module.css";

//export const validNrRe = "\\+[0-9]{5,14}";
//const validNrRegExp = new RegExp(validNrRe);
//export const validatePhoneNr = value => validNrRegExp.test(value);
export const format = (number) => {
  const parsed = parsePhoneNumber(number);
  if (!parsed) {
    return { number };
  }
  return {
    parsed,
    valid: parsed.isValid(),
    country: parsed.country,
    number: parsed.formatInternational(),
  };
};

export default function Twofa({ state, setState, submit }) {
  const {
    moreOptions,
    notifyAddress = "",
    wellknown: [email = "", number = ""] = [],
    country = "",
  } = state;

  const { showErrors, emailOk, numberOk, notifyOk } = state;

  return (
    <>
      <div className={styles.br} style={{ height: "30px" }} />
      <input
        type="text"
        placeholder="Email Address (alice@example.com)"
        className={classNames(
          styles.input,
          showErrors && !emailOk && styles.error
        )}
        value={email}
        onChange={(e) => {
          const email = e.target.value;
          const emailOk = validateEmail(email);
          setState({ wellknown: [email, number], emailOk });
        }}
        onKeyDown={(e) => e.key == "Enter" && submit()}
      />
      <div className={styles.br} style={{ height: "20px" }} />
      <div className={styles.inputPhone}>
        <input
          type="text"
          placeholder="Phone Number (+46 70 123 56 78)"
          className={classNames(
            styles.input,
            showErrors && !numberOk && styles.error
          )}
          value={number}
          onChange={(e) => {
            const element = e.target;
            let caret = element.selectionStart;
            let original = element.value;
            let formated;
            let country;
            let numberOk;

            if (original == "+") {
              formated = original;
            } else {
              for (let i = 0; i < caret; i++) {
                const letter = original[i];
                if (!/[0-9]/.test(letter)) {
                  original = [...original];
                  original.splice(i--, 1);
                  original = original.join("");
                  caret--;
                }
              }
              original = original.replaceAll(/[^0-9]/g, "");
              if (original.length) {
                original = "+" + original;
                caret++;
              }
              formated = format(original);
              country = formated.country;
              numberOk = formated.valid;
              formated = formated.number;
              for (let i = 0; i < caret; i++) {
                const letter = formated[i];
                if (letter == " ") {
                  caret++;
                }
              }
            }

            window.requestAnimationFrame(() => {
              element.selectionStart = caret;
              element.selectionEnd = caret;
            });

            setState({
              country,
              wellknown: [email, formated /*.replace(/\s/g, "")*/],
              numberOk,
            });
          }}
          onKeyDown={(e) => e.key == "Enter" && submit()}
        />
        <div>{country}</div>
      </div>
      <div className={styles.br} style={{ height: "20px" }} />
      <div
        onClick={() => setState({ moreOptions: !moreOptions })}
        className={styles.moreOptions}
      >
        <Icon iconSize={14} icon="form" />
        More Options
      </div>
      {moreOptions && (
        <>
          <div className={styles.br} style={{ height: "30px" }} />
          <div className={styles.label}>
            Use a different email address for notifications:
          </div>
          <input
            type="text"
            placeholder="Notification Email Address (alice@example.com)"
            className={classNames(
              styles.input,
              showErrors && !notifyOk && styles.error
            )}
            value={notifyAddress}
            onChange={(e) => {
              const email = e.target.value;
              const notifyOk = validateEmail(email);
              setState({ notifyAddress: email, notifyOk });
            }}
            onKeyDown={(e) => e.key == "Enter" && submit()}
          />
        </>
      )}
    </>
  );
}
