import { createSlice, createSagaActions } from "utils";
import { getToken } from "../../app/topSlice.js";
import { sendInviteToUser } from "../../app/realworld";

export const blueprint = {
  name: "invite",
  initialState: {
    placeholderInvites: {},
  },
  sagas: {
    sendInvite: async ({ payload: { userId } }, dispatch, getState) => {
      const jwt = await dispatch(getToken());
      await sendInviteToUser(jwt, userId);
    },
  },
};

export const inviteSlice = createSlice(blueprint);

export const inviteSagas = blueprint.sagas;

export const { sendInvite } = createSagaActions(blueprint);

export const selectPlaceholderInvites = (state) =>
  state.invite.placeholderInvites;

export default inviteSlice.reducer;
