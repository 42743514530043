import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { uid } from "utils";
import { Dialog, Icon, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";
import { ZONE } from "../../app/dictionary.js";
import { appState } from "../../app/appState.js";
import { selectIdentity /*, getChannel*/ } from "../../app/topSlice.js";
import TeamSelect from "./team-select.js";
import { dispatchToast } from "../toasts/Toasts.js";
import { AddZone } from "../login/icons.tsx";
import appContext from "../../app/appContext.js";
import { selectMutedTeams, setMutedTeams } from "../mute_team/muteSlice";

const {
  derive: { getZones },
  selectors: { selectGraph },
  actions: { createZone, moveLastZoneFirst, assignCategory },
} = appState;

export default ({ dispatch, active, close, zone, category, template }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [zoneId, setZoneId] = useState();
  const [copyFolders, setCopyFolders] = useState(false);
  const [copyFiles, setCopyFiles] = useState(false);
  const [copyMessages, setCopyMessages] = useState(false);
  const [copyMembers, setCopyMembers] = useState(true);
  const identity = useSelector(selectIdentity);
  const mutedTeams = useSelector(selectMutedTeams);
  const zones = getZones(useSelector(selectGraph));
  const [moreOptions, setMoreOptions] = useState();
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(false);
  const context = useContext(appContext);
  const { zoneId: currentZoneId } = context;

  //useEffect(() => setZoneId(zone), [zone]);

  useEffect(() => {
    setName("");
    setError(null);
    setZoneId(null);
    setCopyFolders(false);
    setCopyFiles(false);
    setCopyMessages(false);
    setCopyMembers(false);
    setMoreOptions(false);
    setDisableCreateNewTeamButton(false);
  }, [active]);

  if (!identity) {
    return null;
  }

  const { id: userId } = identity;

  const createTeam = async () => {
    setDisableCreateNewTeamButton(true);
    try {
      if (200 < name.length) {
        dispatchToast(dispatch, {
          message: `Name too long.`,
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
        return;
      }

      //const channel = await dispatch(getChannel());
      //await channel.sub([[-1, userId, zoneId]]);

      const action = createZone({
        name,
        zoneId,
        copyFolders,
        copyFiles: copyFolders && copyFiles,
        copyMessages,
        copyMembers,
        isTemplate: !!template,
      });

      const toast = dispatchToast(dispatch, {
        message: `Creating ${ZONE}.`,
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0,
      });

      const { id: createdZoneId, reason } = await dispatch(action);

      if (reason) {
        setError(true);
        if (reason == "BAD_NAME") {
          toast.replace({
            message: `That name is already in use.`,
            icon: "warning-sign",
            intent: Intent.DANGER,
          });
        } else if (reason == "NO_NAME") {
          toast.replace({
            message: "The name can't be empty.",
            icon: "warning-sign",
            intent: Intent.DANGER,
          });
        } else if (reason != "HANDLED") {
          toast.replace({
            message: "Something went wrong, please try again.",
            icon: "warning-sign",
            intent: Intent.DANGER,
          });
        } else {
          toast.dismiss();
        }
        return;
      }

      if (category) {
        await dispatch(
          assignCategory({
            userId,
            zoneId: createdZoneId,
            categoryId: category,
          })
        );
      } else {
        dispatch(moveLastZoneFirst());
      }

      const isCloning = copyFolders || copyFiles || copyMessages || copyMembers;

      if (isCloning) {
        const oldTeamMuted = mutedTeams.includes(currentZoneId);
        if (oldTeamMuted) {
          dispatch(setMutedTeams([...mutedTeams, createdZoneId]));
        }
      }

      toast.replace({
        message: `${ZONE[0].toUpperCase() + ZONE.slice(1)} created.`,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });

      setName("");
      close();
    } finally {
      setDisableCreateNewTeamButton(false);
    }
  };

  const zoneText = ZONE[0].toUpperCase() + ZONE.slice(1);

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <div className={styles.title}>
          <AddZone size={34} />
          {template ? (
            <span className={styles.dialogTitle}>Create a new Template</span>
          ) : (
            <span className={styles.dialogTitle}>Create a new {zoneText}</span>
          )}
        </div>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <input
          autoFocus
          value={name}
          className={classNames(styles.dialogInput, error && styles.errorInput)}
          placeholder="A new team name"
          onKeyDown={(e) => e.key == "Enter" && createTeam()}
          onChange={(e) => setName(e.target.value) | setError()}
        />
        {!!zones.length && (
          <>
            <div className={styles.verticalSpace}></div>
            <label className={classNames("checkbox", styles.checkbox)}>
              <div>
                <Icon iconSize={14} icon="applications" />
              </div>
              <span>import from template</span>
              <input
                type="checkbox"
                checked={!!moreOptions}
                onChange={() => {
                  if (!moreOptions) {
                    setZoneId(currentZoneId);
                  } else {
                    setZoneId(null);
                    setCopyFolders(false);
                    setCopyFiles(false);
                    setCopyMessages(false);
                    setCopyMembers(false);
                  }
                  setMoreOptions(!moreOptions);
                }}
              />
              <span className={"checkmark"} />
            </label>
            {/*
              <br/>
              <div
                onClick={() => setMoreOptions(!moreOptions)}
                className={styles.moreOptions}
              >
                <Icon iconSize={14} icon="form" />
                More Options
              </div>
              */}
            {/*<TeamSelect setZoneId={v => setZoneId(v)} zoneId={zoneId} />*/}
            {moreOptions && (
              <>
                <div className={styles.verticalSpace} />
                <div className={styles.verticalSpace} />
                <div className={styles.label}>Import from:</div>
                <div className={classNames("select", styles.select)}>
                  <select
                    value={zoneId || ""}
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    {zones
                      .filter((z) => z.id === zoneId)
                      .map((z) => (
                        <option key={z.id} value={z.id}>
                          {z.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className={styles.verticalSpace} />
                {zoneId && (
                  <>
                    <div className={styles.verticalSpace} />
                    <div className={styles.label}>What to Import:</div>
                    <div className={styles.checkboxes}>
                      <label className={classNames("checkbox")}>
                        <span>Folders</span>
                        <input
                          type="checkbox"
                          checked={copyFolders}
                          onChange={(e) => setCopyFolders(e.target.checked)}
                        />
                        <span className={"checkmark"} />
                      </label>
                      <label className={classNames("checkbox")}>
                        <input
                          type="checkbox"
                          checked={copyFolders && copyFiles}
                          onChange={(e) => setCopyFiles(e.target.checked)}
                          disabled={!copyFolders}
                        />
                        <span>Files</span>
                        <span className={"checkmark"} />
                      </label>
                      <label className={classNames("checkbox")}>
                        <span>Chat Messages</span>
                        <input
                          type="checkbox"
                          checked={copyMessages}
                          onChange={(e) => setCopyMessages(e.target.checked)}
                        />
                        <span className={"checkmark"} />
                      </label>
                      <label className={classNames("checkbox")}>
                        <span>Members</span>
                        <input
                          type="checkbox"
                          checked={copyMembers}
                          onChange={(e) => setCopyMembers(e.target.checked)}
                        />
                        <span className={"checkmark"} />
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {/*
        <div className={classNames(styles.errorMessage, error && styles.show)}>
          <span>{error}</span>
        </div>
        */}
        <div className={styles.verticalSpace} />
        <button
          className={styles.dialogButton}
          onClick={(e) => createTeam()}
          disabled={disableCreateNewTeamButton}
        >
          Create
        </button>
      </div>
    </Dialog>
  );
};
