import PrivateECDHKey from "../private_ecdh_key";

import { importSJCLPrivateKey, exportSJCLPrivateKey } from "./sjcl";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLPrivateECDHKey extends PrivateECDHKey {
  constructor(key) {
    super(key);
  }

  static async import(key, format) {
    return new SJCLPrivateECDHKey(
      await importSJCLPrivateKey(key, format, sjcl.ecc.elGamal)
    );
  }

  async export(format) {
    return exportSJCLPrivateKey(this.key, format);
  }
}
