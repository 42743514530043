import { createSlice } from "@reduxjs/toolkit";

import createAppStateSlice, { derive, createUri, parseUri } from "app-state";

import { warning } from "./appSlice.js";

import {
  selectClientId,
  getToken,
  getChannel,
  getContext
} from "./topSlice.js";

import { trustKit } from "./trustKit.js";

import * as config from "../config.js";

const { selectors, reducers, sagas, actions, server } = createAppStateSlice({
  name: "appstate",
  config,
  createSlice,
  modeClient: true,
  trustKit,
  actions: {
    getToken,
    getChannel,
    getContext
  },
  sagas: {
    remote: async ({ payload: action }, dispatch, getState) => {
      //console.log("@@@", action);
      //const clientId = selectClientId(getState(true));
      const channel = await dispatch(getChannel());
      const accessToken = await dispatch(getToken());
      //action.clientId = clientId;
      action.payload = action.payload || {};
      action.payload.accessToken = accessToken;
      //await channel.publish([["$central"]], action);
      await channel.publish(["$central"], action);
    },
    error: async ({ payload }, dispatch) => {
      if (!payload) {
        dispatch(warning("Something went wrong, please try again."));
        //} else if (payload.rejected) {
        //  console.log("did supress rejection warning");
        //} else if (payload.timeout) {
        //  console.log("did supress timeout warning");
        //  //dispatch(warning("A pending action is taking too long to finish."));
      } else if (payload == "SERVER_DENIED_PERMISSION") {
        dispatch(warning("A request was denied due to lack of permission."));
      } else if (payload.reason === "SERVER_DENIED_PERMISSION") {
        dispatch(warning("A request was denied due to lack of permission."));
      } else if (payload.reason == "OUT_OF_SYNC") {
        //dispatch(warning("A connection was lost, please try again."));
        dispatch(warning("Something went wrong, please try again."));
      } else if (payload.reason == "UNAUTHORIZED") {
        dispatch(warning("A request was denied, please try again."));
      } else if (payload.reason == "ADVERSE_EFFECT") {
        dispatch(warning("An action was aborted due to its side effects."));
      } else if (payload.reason == "UNKNOWN") {
        dispatch(warning("Something went wrong, please try again."));
      } else if (typeof payload === "string") {
        dispatch(warning(payload));
      } else if (typeof payload.reason === "string") {
        dispatch(warning(payload.reason));
      } else {
        dispatch(warning("Something went wrong, please try again."));
        console.log(payload);
      }
    }
  }
});

const appState = {
  derive,
  selectors,
  actions,
  server,
  misc: { createUri, parseUri }
};

export default reducers;

export { sagas, appState };
