import PublicECDHKey from "../public_ecdh_key";

import { importSJCLPublicKey, exportSJCLPublicKey } from "./sjcl";

import * as sjcl from "./sjcl-1.0.7";

export default class SJCLPublicECDHKey extends PublicECDHKey {
  constructor(key) {
    super(key);
  }

  static async import(key, format) {
    return new SJCLPublicECDHKey(
      await importSJCLPublicKey(key, format, sjcl.ecc.elGamal)
    );
  }

  async export(format) {
    return exportSJCLPublicKey(this.key, format);
  }
}
