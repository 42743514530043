import {
  isUndefined,
  isInteger,
  isNumber,
  isString,
  isArray,
  isMap,
  keys,
  filterObj,
  nanoid,
} from "utils";
import { A } from "psm";
import { getDatabase, query } from "./database.js";

export const createUri = (user = {}, _space = null, _device = null) => {
  let { space, provider, identity, wellknown, device } = user;
  space = _space || space || null;
  device = _device /*|| device*/ || null;
  provider = provider || null;
  identity = identity || wellknown || [];
  let uri = `${provider}:${identity.join("|")}`;
  if (space) {
    uri = `${space};${uri}`;
  }
  if (device) {
    uri = `${uri}#${device}`;
  }
  return uri;
  //return `${space ? space + ";" : ""}${provider}:${identity.join("|")}`;
};

export const parseUri = (uri) => {
  uri = `${uri?.toLowerCase?.() || ""}`;
  let [, , space, provider, identity, , device] =
    ///^(([^;]*);)?(.+):(.+)$/.exec(uri.toLowerCase()) || [];
    /^(([^;]*);)?(.+):(.+?)(#([^#]*))?$/.exec(uri) || [];
  identity = (identity || "").split("|").filter((str) => str.length > 0);
  return {
    space: space || "",
    provider: provider || "",
    identity,
    device: device || "",
  };
};

export const escapeRegExp = (string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const hints = async (callback) => {
  const r = await getDatabase();

  let t0 = 0;
  while (true) {
    await new Promise((k) =>
      setTimeout(k, Math.max(0, t0 + 1750 - new Date()))
    );
    t0 = +new Date();
    let cursor;
    try {
      cursor = await r.db("hyker").table("hints").changes({ squash: false })(
        "new_val"
      );
      while (true) {
        callback(await cursor.next());
      }
    } catch (e) {
      if (
        e.name == "ReqlServerError" &&
        e.message?.startsWith(
          "The connection was closed before the query could be completed"
        )
      ) {
        try {
          cursor.close();
        } catch (e) { }
        try {
          console.error(e);
          //console.error(Sentry.captureException(e));
        } catch (f) { }
        continue;
      }
      throw e;
    }
    break;
  }
};

export const watch = async (callback) => {
  const r = await getDatabase();

  let t0 = 0;
  while (true) {
    await new Promise((k) =>
      setTimeout(k, Math.max(0, t0 + 1750 - new Date()))
    );
    t0 = +new Date();
    let cursor;
    try {
      // A quirk of this query forces some default value to be an empty object
      // even tho e.g. null would have been more natural. This includes the
      // default value at the last argument of the branch call, as well as both
      // the default values at the end of attesation and esignature banches.
      cursor = await r
        .union(
          r.db("hyker").table("user").merge({ _entity: "user" }),
          r.db("hyker").table("member").merge({ _entity: "member" }),
          r.db("hyker").table("team").merge({ _entity: "team" }),
          r.db("hyker").table("file").merge({ _entity: "file" }),
          r.db("hyker").table("attestation").merge({ _entity: "attestation" }),
          r.db("hyker").table("esignature").merge({ _entity: "esignature" })
        )
        .changes({ squash: false, includeTypes: true })
        //.filter({ type: "remove" })
        .filter((doc) =>
          r.or(
            r.and(
              doc("type").eq("add"),
              r.or(
                doc("new_val")("_entity").eq("attestation"),
                doc("new_val")("_entity").eq("esignature")
              )
            ),
            r.and(
              doc("type").eq("change"),
              //doc("new_val")("_entity").eq("file"),
              r.or(
                doc("new_val")("_entity").eq("attestation"),
                doc("new_val")("_entity").eq("esignature"),
                doc("new_val")("role")
                  .ne(doc("old_val")("role"))
                  .default(false),
                doc("new_val")("provider")
                  .ne(doc("old_val")("provider"))
                  .default(false),
                r.and(
                  doc("new_val")("_entity").eq("team"),
                  doc("new_val")("name")
                    .ne(doc("old_val")("name"))
                    .default(false)
                ),
                r.and(
                  doc("new_val")("_entity").eq("file"),
                  doc("new_val")("index")
                    .eq(doc("old_val")("index"))
                    .default(false),
                  doc("new_val")("parent")
                    .eq(doc("old_val")("parent"))
                    .default(false),
                  doc("new_val")("name")
                    .ne(doc("old_val")("name"))
                    .default(false)
                ),
                r.and(
                  doc("new_val")("_entity").eq("user"),
                  doc("new_val")("name")
                    .ne(doc("old_val")("name"))
                    .default(false)
                ),
                // used for id migration, will also update  provider and wellknown
                r.and(
                  doc("new_val")("_entity").eq("user"),
                  doc("new_val")("deprecatedId")
                    .ne(doc("old_val")("deprecatedId"))
                    .default(false)
                )

                //r.and(
                //  r.or(
                //    doc("new_val")("_entity").eq("team"),
                //    r.and(
                //      doc("new_val")("_entity").eq("file"),
                //      r.or(

                //      )
                //    )
                //  ),
                //  doc("new_val")("name")
                //    .ne(doc("old_val")("name"))
                //    .default(false)
                //)
              )
            ),
            r.and(
              doc("type").eq("remove"),
              r.or(
                doc("old_val")("_entity").eq("team"),
                doc("old_val")("_entity").eq("attestation"),
                doc("old_val")("_entity").eq("esignature")
                //doc("old_val")("_entity").eq("file"),
                //doc("old_val")("_entity").eq("member")
              )
            ),
            //r.and(
            //  doc("new_val")("deleted"),
            //  doc("old_val")("deleted")
            //    .default(false)
            //    .not()
            //),
            r.and(
              doc("new_val")("_touch"),
              doc("new_val")("_touch").ne(doc("old_val")("_touch").default(0))
            )
          )
        )
        .map((doc) =>
          r.branch(
            doc("type").eq("remove"),
            doc("old_val").merge({
              _touch: false,
              _removed: true,
            }),
            doc("new_val").merge({
              _removed: r
                .and(
                  doc("new_val")("deleted"),
                  doc("old_val")("deleted").default(false).not()
                )
                .default(false),
              _touch: r
                .and(
                  doc("new_val")("_touch"),
                  doc("new_val")("_touch").ne(
                    doc("old_val")("_touch").default(0)
                  )
                )
                .default(false),
            })
          )
        )
        //("new_val")
        .map((doc) =>
          r.branch(
            doc("_entity").eq("team"),
            //doc.merge({ /*_entity: "zone",*/ _zone: doc("id") }),
            doc.merge({ _ws: doc("org"), _zone: doc("id") }),
            doc("_entity").eq("user"),
            //doc, //.merge({ _user: doc("id") }),
            doc.merge({ _ws: doc("org") }),
            doc("_entity").eq("member"),
            doc
              .merge({
                //_entity: "node",
                _zone: r
                  .db("hyker")
                  .table("group")
                  .get(doc("group"))("team")
                  .default(null),
              })
              .merge((doc) => ({
                _ws: r
                  .db("hyker")
                  .table("team")
                  .get(doc("_zone"))("org")
                  .default(null),
              })),
            doc("_entity").eq("file"),
            doc
              .merge({
                //_entity: "node",
                _zone: r
                  .db("hyker")
                  .table("group")
                  .get(doc("group"))("team")
                  .default(null),
              })
              .merge((doc) => ({
                _ws: r
                  .db("hyker")
                  .table("team")
                  .get(doc("_zone"))("org")
                  .default(null),
              })),
            doc("_entity").eq("attestation"),
            r
              .db("hyker")
              .table("file")
              .get(doc("file"))
              .merge((d) => ({
                _entity: "file",
                _zone: r
                  .db("hyker")
                  .table("group")
                  .get(d("group"))("team")
                  .default(null),
                attestation: doc.without("_entity", "_touch" /*, "_removed"*/),
                //_touch: +new Date()
                _touch: false,
              }))
              .merge((doc) => ({
                _ws: r
                  .db("hyker")
                  .table("team")
                  .get(doc("_zone"))("org")
                  .default(null),
              }))
              .default({}),
            doc("_entity").eq("esignature"),
            r
              .db("hyker")
              .table("file")
              .get(doc("file"))
              .merge((d) => ({
                _entity: "file",
                _zone: r
                  .db("hyker")
                  .table("group")
                  .get(d("group"))("team")
                  .default(null),
                esignature: doc.without("_entity", "_touch" /*, "_removed"*/),
                //_touch: +new Date()
                _touch: false,
              }))
              .merge((doc) => ({
                _ws: r
                  .db("hyker")
                  .table("team")
                  .get(doc("_zone"))("org")
                  .default(null),
              }))
              .default({}),
            {}
          )
        );

      while (true) {
        const doc = await cursor.next();
        if (Object.keys(doc).length !== 0) {
          callback(doc);
        }
      }
    } catch (e) {
      if (
        e.name == "ReqlServerError" &&
        e.message?.startsWith(
          "The connection was closed before the query could be completed"
        )
      ) {
        try {
          cursor.close();
        } catch (e) { }
        try {
          console.error(e);
          //console.error(Sentry.captureException(e));
        } catch (f) { }
        continue;
      }
      throw e;
    }
    break;
  }
};

//export const onSetExpire = async (callback) => {
//  const r = await getDatabase();
//
//  const cursor = await r
//    .db("hyker")
//    .table("team")
//    .changes({ squash: false })
//    .filter((d) =>
//      d("new_val")("expirationDate").and(
//        d("new_val")("expirationDate").ne(
//          d("old_val")("expirationDate").default(null)
//        )
//      )
//    );
//
//  while (true) {
//    callback(await cursor.next());
//  }
//};

export const onSetExpire = async (callback) => {
  const r = await getDatabase();

  let t0 = 0;
  while (true) {
    await new Promise((k) =>
      setTimeout(k, Math.max(0, t0 + 1750 - new Date()))
    );
    t0 = +new Date();
    let cursor;
    try {
      cursor = await r
        .db("hyker")
        .table("team")
        .changes({ squash: false })
        .filter((d) =>
          d("new_val")("expirationDate").and(
            d("new_val")("expirationDate").ne(
              d("old_val")("expirationDate").default(null)
            )
          )
        );

      while (true) {
        callback(await cursor.next());
      }
    } catch (e) {
      if (
        e.name == "ReqlServerError" &&
        e.message?.startsWith(
          "The connection was closed before the query could be completed"
        )
      ) {
        try {
          cursor.close();
        } catch (e) { }
        try {
          console.error(e);
          //console.error(Sentry.captureException(e));
        } catch (f) { }
        continue;
      }
      throw e;
    }
    break;
  }
};

export const getExpiredZones = async () => {
  const r = await getDatabase();
  return await r
    .db("hyker")
    .table("team")
    .filter(r.row("expirationDate").lt(r.now()));
};

export const getNextExpire = async () => {
  const r = await getDatabase();

  const nextExpire = await r
    .db("hyker")
    .table("team")("expirationDate")
    .min()
    .default(null);

  if (nextExpire) {
    return nextExpire - new Date();
  }

  return 2 ** 31 - 1;
};

export const expireZone = async (zone) => {
  const r = await getDatabase();

  //const team = await r.db("hyker").table("team").get(zoneId);

  try {
    const { id, org, rootGroup, virtualRootGroup } = zone || {};
    if (
      !id ||
      !isString(id) ||
      !org ||
      !isString(org) ||
      !rootGroup ||
      !isString(rootGroup) ||
      !virtualRootGroup ||
      !isString(virtualRootGroup)
    ) {
      console.log(zone);
      throw new Error("expireZone: bad zone args");
    }

    console.log(`Expiring team ${id}.`);

    // Get groups in team
    const groups = await r.table("group").getAll(id, { index: "team" })("id");
    //.coerceTo("array");

    //const regularGroups = groups.difference([rootGroup, virtualRootGroup]);
    const regularGroups = groups.filter(
      (id) => id != rootGroup && id != virtualRootGroup
    );

    const deleteTime = new Date();
    const expireId = nanoid();

    // Delete all files in team (except for the root file of the root group)
    await r
      .db("hyker")
      .table("file")
      .getAll(r.args(groups), { index: "group" })
      .hasFields("parent")
      //.delete();
      .delete({ returnChanges: "always" })
      .do((changes) =>
        r.table("file_archive").insert(
          changes("changes")("old_val")
            .default([])
            .filter((doc) => doc)
            .merge({
              _deleted: deleteTime,
              _expired: expireId,
            })
        )
      );

    ////.filter(doc => doc.hasFields("parent"));
    ////.filter(doc =>
    ////  doc.filter(doc => doc.hasFields(FIELD_PARENT)).coerceTo("array")
    ////)

    // Delete all files in team (except for the root file of the root group)
    await r
      .db("hyker")
      .table("member")
      .getAll(r.args(regularGroups), { index: "group" })
      //.delete();
      .delete({ returnChanges: "always" })
      .do((changes) =>
        r.table("member_archive").insert(
          changes("changes")("old_val")
            .default([])
            .filter((doc) => doc)
            .merge({
              _deleted: deleteTime,
              _expired: expireId,
            })
        )
      );

    // Delete all groups that are not root group or virtual root group
    await r
      .db("hyker")
      .table("group")
      .getAll(r.args(regularGroups))
      //.delete();
      .delete({ returnChanges: "always" })
      .do((changes) =>
        r.table("group_archive").insert(
          changes("changes")("old_val")
            .default([])
            .filter((doc) => doc)
            .merge({
              _deleted: deleteTime,
              _expired: expireId,
            })
        )
      );

    // Remove expiration date
    const result = await r
      .db("hyker")
      .table("team")
      .get(id)
      .replace(r.row.without("expirationDate"), { returnChanges: true });

    await insertEvent({
      type: A.LOG_TEAM_DID_EXPIRE,
      org,
      origin: "KONF_SERVER",
      target: rootGroup,
      meta: {
        deletedAt: deleteTime,
        expireId: expireId,
        expirationDate: result?.changes?.[0]?.old_val?.expirationDate,
      },
    });
  } catch (e) {
    console.log("expireZone: failed to expire zone", e);
  }
};

const insertEvent = async (event) => {
  const r = await getDatabase();
  try {
    const { type, org, origin, target, meta, notify } = event || {};
    if (
      !type ||
      !isString(type) ||
      !org ||
      !isString(org) ||
      !origin ||
      !isString(origin) ||
      !target ||
      !isString(target) ||
      (!isUndefined(meta) && !isMap(meta)) ||
      (!isUndefined(notify) && (!isArray(notify) || !notify.every(isString)))
    ) {
      console.log(event);
      throw new Error("insertEvent: bad event args");
    }
    event = { type, org, origin, target, meta, notify, time: +new Date() };
    await r.table("event").insert(event);
  } catch (e) {
    console.log("insertEvent: failed to insert event", e);
  }
};

//export const dump = async () => {
//  return await query(
//    r =>
//      r.expr({}).merge({
//        team: r
//          .db("hyker")
//          .table("team")
//          .coerceTo("array"),
//        file: r
//          .db("hyker")
//          .table("file")
//          .coerceTo("array"),
//        user: r
//          .db("hyker")
//          .table("user")
//          .coerceTo("array"),
//        group: r
//          .db("hyker")
//          .table("group")
//          .coerceTo("array"),
//        member: r
//          .db("hyker")
//          .table("member")
//          .coerceTo("array")
//      }),
//    801
//  );
//};

export const getPermissions = async (roleId) => {
  const r = await getDatabase();
  return await r.db("hyker").table("role").get(roleId);
};

export const getRoles = async (orgId) => {
  const r = await getDatabase();
  return await r
    .db("hyker")
    .table("role")
    .getAll(r.args(r.db("hyker").table("org").get(orgId)("roles")))
    .pluck("id", "privilegeOrder");
};

export const getNodeChildren = async (nodeId) => {
  const r = await getDatabase();
  return await r.db("hyker").table("file").getAll(nodeId, { index: "parent" });
};

export const getNodeSiblings = async (nodeId) => {
  const r = await getDatabase();
  //return await r.db("hyker").table("file").getAll(r.db("hyker").table("file").get(nodeId)("parent"), { index: "parent" }).hasFields("parent").filter(doc => doc("parent"));
  return r
    .db("hyker")
    .table("file")
    .get(nodeId)
    .do((node) =>
      r.branch(
        node("parent"),
        r.db("hyker").table("file").getAll(node("id"), { index: "parent" }),
        []
      )
    )
    .default([]);
};

//export const getPermissions = async userId => {
//  const r = await getDatabase();
//  const permissions = await r
//    .db("hyker")
//    .table("user")
//    .get(userId)
//    .do(user =>
//      r
//        .db("hyker")
//        .table("role")
//        .get(user("role"))
//    )("actions")
//    .default(null);
//  return filterObj(permissions || {}, (_, value) => value);
//};

export const getInitSpace = async (userId) => {
  const result = await query(
    (r) =>
      r
        .db("hyker")
        .table("user")
        .get(userId)
        .do((user) =>
          r.expr({}).merge({
            permissions: r
              .db("hyker")
              .table("role")
              .get(user("role"))("actions")
              .default(null),
            cat2Zon: r
              .db("hyker")
              .table("member")
              .getAll(user("id"), { index: "user" })
              .hasFields("category")
              .pluck("category", "group")
              .coerceTo("array")
              .do((members) =>
                r
                  .db("hyker")
                  .table("group")
                  .getAll(r.args(members("group")))
                  .pluck("id", "team")
                  .coerceTo("array")
                  .do((groups) => ({ members, groups }))
              ),
            categories: r
              .expr([])
              .add(
                r
                  .db("hyker")
                  .table("category")
                  .getAll(user("id"), { index: "user" })
                  .coerceTo("array")
              ),
            templatesCount: r
              .table("member")
              .getAll(user("id"), { index: "user" })
              .eqJoin("group", r.table("group"))
              .eqJoin((d) => d("right")("team"), r.table("team"))
              .filter((d) => d("right")("template"))("right")("id")
              .distinct()
              .count(),
            clock: +new Date(),
            //esignatures: r
            //  .db("hyker")
            //  .table("esignature")
            //  .getAll(userId, { index: "signees" })
            //  .filter((doc) =>
            //    //r.or(
            //    //  doc("timestamp").gt(new Date() - 60 * 60 * 1000),
            //      r.and(
            //        doc("completed").eq(false),
            //        doc("status").ne("canceled"),
            //        doc("status").ne("expired")
            //      )
            //    //)
            //  )
            //  .merge((doc) => ({
            //    node: r.db("hyker").table("file").get(doc("file")),
            //  }))
            //  .filter((doc) => doc("node").ne(null))
            //  .merge((doc) => ({
            //    zone: r
            //      .db("hyker")
            //      .table("team")
            //      .get(
            //        r.db("hyker").table("group").get(doc("node")("group"))(
            //          "team"
            //        )
            //      ),
            //  }))
            //  .coerceTo("array"),
          })
        ),
    801
  );
  const cat2Zon = {},
    {
      cat2Zon: { members, groups },
      categories,
    } = result;
  for (const { id: group, team: zone } of groups) {
    cat2Zon[group] = zone;
  }
  for (const { category, group } of members) {
    if (category in cat2Zon) {
      cat2Zon[category].push(group);
    } else {
      cat2Zon[category] = [group];
    }
  }
  for (const category of categories) {
    if (category.id in cat2Zon) {
      category.zones = cat2Zon[category.id].map((group) => cat2Zon[group]);
    }
  }
  //console.log(result.esignatures);
  return result;
};

// Search matching compound indexes similary to regex /^name@example.com|/
// based on a hack from knowing how rethinkdb handles compound indexes.
// Second part of compund index (org) is never accounted for, hence the filter.
export const getPlaceholderUsers = async (org, email) => {
  const value = "placeholder:" + email;
  return await query(
    (r) =>
      r
        .db("hyker")
        .table("user")
        .between([value + "|"], [value + "|~"], { index: "deprecatedIdAndOrg" })
        .filter({ org })
        .pluck(
          "id",
          "deprecatedId",
          "email",
          "name",
          "provider",
          "wellknown",
          "role"
        ),
    802
  );
};

export const getUserFromUri = async (uri) => {
  const { space, provider, identity } = parseUri(uri);
  const deprecatedId = `${provider}:${identity.join("|")}`;
  return await query(
    (r) =>
      r
        .db("hyker")
        .table("user")
        .getAll([deprecatedId, space], { index: "deprecatedIdAndOrg" })
        .pluck(
          "id",
          "deprecatedId",
          "email",
          "name",
          "provider",
          "wellknown",
          "role"
        )(0)
        .default(null),
    803
  );
};

export const getUserFromId = async (id) => {
  return await query(
    (r) =>
      r
        .db("hyker")
        .table("user")
        .getAll(id, { index: "id" })
        .pluck(
          "id",
          "deprecatedId",
          "email",
          "name",
          "provider",
          "wellknown",
          "role"
        )(0)
        .default(null),
    804
  );
};

export const getGroupFromId = async (id) => {
  return await query(
    (r) =>
      r
        .db("hyker")
        .table("group")
        .getAll(id, { index: "id" })
        .pluck("id", "rootFile", "team")
        .map((d) => ({
          id: d("id"),
          rootFile: d("rootFile"),
          zone: d("team"),
        }))(0)
        .default(null),
    805
  );
};

export const bumpMember = async (zone, user) => {
  return await query(
    (r) =>
      r
        .db("hyker")
        .table("team")
        //.get(zone || "")("virtualRootGroup")
        .get(zone || "")("rootGroup")
        .do((group) =>
          r
            .db("hyker")
            .table("member")
            .getAll(user || "", { index: "user" })
            .filter({ group })
            .update({ _active: new Date() })
        ),
    806
  );
};

export const getConnectedUsers = async (userId) => {
  const result = await query(
    (r) =>
      r
        .db("hyker")
        .table("member")
        .getAll(userId, { index: "user" })("group")
        .distinct()
        .do((groups) =>
          r
            .db("hyker")
            .table("member")
            .getAll(r.args(groups), { index: "group" })("user")
            .distinct()
        ),
    701
  );
  return result;
};

export const getGrafts = async (zone, user) => {
  const result = await query(
    (r) =>
      r
        .expr({})
        .merge({
          members: r
            .db("hyker")
            .table("member")
            .getAll(user, { index: "user" })
            .coerceTo("array"),
        })
        .merge((docs) => ({
          groups: r
            .db("hyker")
            .table("group")
            .getAll(zone, { index: "team" })
            .filter((d) => docs("members")("group").contains(d("id")))
            .coerceTo("array"),
        }))
        .merge((docs) => ({
          rootFiles: r
            .db("hyker")
            .table("file")
            .getAll(r.args(docs("groups")("rootFile")))
            .hasFields("parent")
            .coerceTo("array"),
        }))
        .merge((docs) => ({
          adoptiveParents: r
            .db("hyker")
            .table("file")
            .getAll(r.args(docs("rootFiles")("parent")))
            .filter((d) => docs("members")("group").contains(d("group")).not())
            .coerceTo("array"),
        }))
        .do((docs) =>
          docs("adoptiveParents").add(
            docs("rootFiles").filter((d) =>
              docs("adoptiveParents")("id").contains(d("parent"))
            )
          )
        )
        .distinct(),
    812
  );
  return result;
};

export const getZoneDeep = async (zone) => {
  const defaultGraph = {
    zones: {},
    nodes: {},
    users: {},
    groups: {},
  };

  if (!isString(zone)) {
    return defaultGraph;
  }

  const result = await query(async (r) => {
    const actual = await r
      .table("team")
      .getAll(zone)
      .map((team) => ({
        zones: [team],
        groups: r
          .table("group")
          .getAll(team("id"), { index: "team" })
          .map((group) => ({
            id: group("id"),
            zone: group("team"),
            rootFile: group("rootFile"),
          }))
          .coerceTo("array"),
      }))
      .merge((doc) => ({
        nodes: r
          .table("file")
          .getAll(r.args(doc("groups")("id").distinct()), { index: "group" })
          .distinct()
          .orderBy((file) => file("id"))
          .pluck(
            "id",
            "group",
            "parent",
            "storageId",
            "version",
            "index",
            "name",
            "creator",
            "timestamp",
            "meta"
          )
          .coerceTo("array"),
        users: r
          .table("member")
          .getAll(r.args(doc("groups")("id")), { index: "group" })
          .merge((doc) => ({
            member: doc("id"),
            user: doc("user"),
            group: doc("group"),
          }))
          .merge((doc) => r.table("user").get(doc("user")))
          // added 220222
          .filter((doc) => doc("deleted").default(false).not())
          .pluck(
            "user",
            "member",
            "group",
            "deprecatedId",
            "email",
            "name",
            "provider",
            "wellknown",
            "role"
          )
          .coerceTo("array"),
      }))(0)
      .default(null);

    if (!actual) {
      return null;
    }

    const attestations = await r
      .table("attestation")
      .getAll(r.args(actual.nodes.map(({ id }) => id)), { index: "file" })
      .pluck(
        "id",
        "file",
        "approvals",
        "rejections",
        "requester",
        "signees",
        "order",
        "info",
        "deadline",
        "current",
        "tbs",
        "timestamp"
      )
      .coerceTo("array");

    for (const node of actual.nodes) {
      node.attestations = attestations
        .filter((attestation) => attestation.file === node.id)
        .map(({ file, ...properties }) => properties);
    }

    const esignatures = await r
      .table("esignature")
      .getAll(r.args(actual.nodes.map(({ id }) => id)), { index: "file" })
      .pluck(
        "id",
        "file",
        "approvals",
        "rejections",
        "requester",
        "signees",
        "signicatId",
        "signicatUrl",
        "checksum",
        "deadline",
        "status",
        "order",
        "current",
        "completed",
        "tbs",
        "timestamp"
      )
      .coerceTo("array");

    for (const node of actual.nodes) {
      node.esignatures = esignatures
        .filter((esignature) => esignature.file === node.id)
        .map(({ file, ...properties }) => properties);
    }

    return actual;
  }, 807);

  if (!result) {
    return defaultGraph;
  }

  for (const [col, rows] of Object.entries(result)) {
    result[col] = {};
    for (const row of rows) {
      const { member, id } = row;
      result[col][member || id] = { ...row /*, zone*/ };
    }
  }

  return result;
};

export const getZoneFlat = async (zone) => {
  const defaultGraph = {
    zones: {},
    nodes: {},
    users: {},
    groups: {},
  };

  if (!isString(zone)) {
    return defaultGraph;
  }

  const result = await query(async (r) => {
    const actual = await r
      .table("team")
      .getAll(zone)
      .map((team) => ({
        zones: [team],
        groups: r
          .table("group")
          .getAll(team("id"), { index: "team" })
          .map((group) => ({
            id: group("id"),
            zone: group("team"),
            rootFile: group("rootFile"),
          }))
          .coerceTo("array"),
      }))
      .merge((doc) => ({
        nodes: r
          .table("file")
          .getAll(r.args(doc("groups")("rootFile").distinct()))
          .concatMap((rootFile) =>
            r
              .table("file")
              .getAll(rootFile("id"), { index: "parent" })
              .concatMap((rootChild) =>
                r
                  .table("file")
                  .getAll(rootChild("id"), { index: "parent" })
                  .union([rootChild])
              )
              .union([rootFile])
          )
          .distinct()
          .orderBy((file) => file("id"))
          .pluck(
            "id",
            "group",
            "parent",
            "storageId",
            "version",
            "index",
            "name",
            "creator",
            "timestamp",
            "meta"
          )
          .coerceTo("array"),
        users: r
          .table("member")
          .getAll(r.args(doc("groups")("id")), { index: "group" })
          .merge((doc) => ({
            member: doc("id"),
            user: doc("user"),
            group: doc("group"),
          }))
          .merge((doc) => r.table("user").get(doc("user")))
          // added 220222
          .filter((doc) => doc("deleted").default(false).not())
          .pluck(
            "user",
            "member",
            "group",
            "deprecatedId",
            "email",
            "name",
            "provider",
            "wellknown",
            "role"
          )
          .coerceTo("array"),
      }))(0)
      .default(null);

    if (!actual) {
      return null;
    }

    const attestations = await r
      .table("attestation")
      .getAll(r.args(actual.nodes.map(({ id }) => id)), { index: "file" })
      .pluck(
        "id",
        "file",
        "approvals",
        "rejections",
        "requester",
        "signees",
        "order",
        "info",
        "deadline",
        "current",
        "tbs",
        "timestamp"
      )
      .coerceTo("array");

    for (const node of actual.nodes) {
      node.attestations = attestations
        .filter((attestation) => attestation.file === node.id)
        .map(({ file, ...properties }) => properties);
    }

    const esignatures = await r
      .table("esignature")
      .getAll(r.args(actual.nodes.map(({ id }) => id)), { index: "file" })
      .pluck(
        "id",
        "file",
        "approvals",
        "rejections",
        "requester",
        "signees",
        "signicatId",
        "signicatUrl",
        "checksum",
        "deadline",
        "order",
        "status",
        "current",
        "completed",
        "tbs",
        "timestamp"
      )
      .coerceTo("array");

    for (const node of actual.nodes) {
      node.esignatures = esignatures
        .filter((esignature) => esignature.file === node.id)
        .map(({ file, ...properties }) => properties);
    }
    return actual;
  }, 808);

  if (!result) {
    return defaultGraph;
  }

  for (const [col, rows] of Object.entries(result)) {
    result[col] = {};
    for (const row of rows) {
      const { member, id } = row;
      result[col][member || id] = { ...row /*, zone*/ };
    }
  }

  return result;
};

//export const getZoneQuick = async zone => {
//  const defaultGraph = {
//    zones: {},
//    nodes: {},
//    users: {},
//    groups: {}
//  };
//
//  if (!isString(zone)) {
//    return defaultGraph;
//  }
//
//  const result = await query(r => r
//    .table("team")
//    .getAll(zone)
//    .map(team => ({
//      zones: [team],
//      groups: r
//        .table("group")
//        .getAll(team("id"), { index: "team" })
//        .map(group => ({
//          id: group("id"),
//          zone: group("team"),
//          rootFile: group("rootFile")
//        }))
//        .coerceTo("array")
//    }))(0)
//    .default(null)
//  , 907);
//
//  if (!result) {
//    return defaultGraph;
//  }
//
//  for (const [col, rows] of Object.entries(result)) {
//    result[col] = {};
//    for (const row of rows) {
//      result[col][row.id] = row;
//    }
//  }
//
//  return result;
//};

export const getZones = async (
  userId,
  comparator,
  filter,
  quantity,
  previousId,
  categoryId,
  templates
) => {
  categoryId = categoryId || "";

  if (!isString(userId) || 255 < userId.length) {
    return [];
  }
  if (!isString(categoryId) || 255 < categoryId.length) {
    return [];
  }
  if (!["date", "date-asc", "name", "name-asc"].includes(comparator)) {
    comparator = "date";
  }
  if (!(isInteger(quantity) && 0 < quantity && quantity < 100)) {
    quantity = 1;
  }

  if (!(isString(filter) && filter.length <= 300)) {
    filter = "";
  }
  return await query(async (r) => {
    let q = r
      .db("hyker")
      .table("member")
      .getAll(userId, { index: "user" })
      .eqJoin("group", r.table("group"))
      .eqJoin(r.row("right")("team"), r.table("team"))
      .map((d) =>
        d("left")("left")
          .merge(d("left")("right"))
          .merge({ template: d("right")("template") })
      )
      .pluck("team", "category", "_active", "template")
      .group("team")
      .ungroup()
      .map((doc) => ({
        team: doc("group"),
        _count: doc("reduction").count(),
        category: doc("reduction")
          .hasFields("category")("category")(0)
          .default(null),
        _active: doc("reduction").max("_active")("_active").default(null),
        template: doc("reduction")(0)("template"),
      }));

    if (!filter) {
      q = q.filter({ template: !!templates });
      if (categoryId) {
        q = q.filter({ category: categoryId });
      } else {
        q = q.filter(r.row.hasFields("category").not());
      }
    }

    q = q
      .pluck("team", "_active", "_count")
      .merge(r.db("hyker").table("team").get(r.row("team")))
      .merge((doc) => ({
        _at: doc("_active").default(doc("_created")).default(r.epochTime(0)),
      }))
      .hasFields("_at")
      .pluck(
        "_at",
        "_count",
        "id",
        "name",
        "rootGroup",
        "virtualRootGroup",
        "expirationDate",
        "template"
      );

    if (filter) {
      q = q.filter((doc) => doc("name").match(`(?i)${escapeRegExp(filter)}`));
    }

    if (comparator.startsWith("date")) {
      if (comparator.endsWith("asc")) {
        q = q.orderBy(r.asc("_at"));
      } else {
        q = q.orderBy(r.desc("_at"));
      }
    } else if (comparator.startsWith("name")) {
      if (comparator.endsWith("asc")) {
        q = q.orderBy(r.asc("name"));
      } else {
        q = q.orderBy(r.desc("name"));
      }
    }

    let offset = 0;
    if (previousId) {
      // Basically q.indexOf(previousId)
      offset = await q.do((doc) =>
        doc
          .fold({ index: 0, found: false }, (acc, doc) =>
            acc.merge({
              found: acc("found").or(doc("id").eq(previousId)),
              index: acc("index").add(acc("found").branch(0, 1)),
            })
          )
          .do((acc) => acc("found").branch(acc("index"), 0))
      );
    }

    q = q.slice(offset, quantity + offset);

    const graph = { zones: {} },
      inter = await q;

    for (const doc of inter) {
      doc.at = +doc._at;
      delete doc._at;
      graph.zones[doc.id] = doc;
    }

    const next = {
      previousId: inter.length > 0 && inter[inter.length - 1].id,
      comparator,
      quantity,
    };

    return { graph, next };
  }, 809);
};

export const getNodeFromId = async (id) => {
  const result = await query(
    (r) =>
      r
        .db("hyker")
        .table("file")
        .getAll(id, { index: "parent" })
        .coerceTo("array")
        .do((docs) =>
          docs.add(
            r
              .db("hyker")
              .table("file")
              .getAll(r.args(docs("id")), { index: "parent" })
              .coerceTo("array")
          )
        )
        .add(r.db("hyker").table("file").getAll(id).coerceTo("array"))
        .do(function (files) {
          const attestations = r
            .db("hyker")
            .table("attestation")
            .getAll(r.args(files("id")), { index: "file" })
            .pluck(
              "file",
              "id",
              "approvals",
              "rejections",
              "requester",
              "signees",
              "order",
              "info",
              "deadline",
              "current",
              "tbs",
              "timestamp"
            )
            .coerceTo("array");

          const esignatures = r
            .db("hyker")
            .table("esignature")
            .getAll(r.args(files("id")), { index: "file" })
            .pluck(
              "id",
              "file",
              "approvals",
              "rejections",
              "requester",
              "signees",
              "signicatId",
              "signicatUrl",
              "checksum",
              "deadline",
              "order",
              "status",
              "current",
              "completed",
              "tbs",
              "timestamp"
            )
            .coerceTo("array");

          const pluckedFiles = files.pluck(
            "id",
            "group",
            "parent",
            "storageId",
            "version",
            "index",
            "name",
            "creator",
            "timestamp",
            "meta"
          );
          return { attestations, esignatures, files: pluckedFiles };
        }),
    810
  );

  for (const file of result.files) {
    file.attestations = result.attestations.filter(
      (attestation) => attestation.file === file.id
    );
  }

  for (const file of result.files) {
    file.esignatures = result.esignatures.filter(
      (esignature) => esignature.file === file.id
    );
  }

  return result.files.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
};

export const getNodesFromGroup = async (id) => {
  const result = await query(
    (r) =>
      r
        .db("hyker")
        .table("file")
        .getAll(id, { index: "group" })
        .merge((d) => ({
          attestations: r
            .db("hyker")
            .table("attestation")
            .getAll(d("id"), { index: "file" })
            .pluck(
              "id",
              "approvals",
              "rejections",
              "requester",
              "signees",
              "order",
              "info",
              "deadline",
              "current",
              "tbs",
              "timestamp"
            )
            .coerceTo("array"),
          esignatures: r
            .db("hyker")
            .table("esignature")
            .getAll(d("id"), { index: "file" })
            .pluck(
              "id",
              "approvals",
              "rejections",
              "requester",
              "signees",
              "signicatId",
              "signicatUrl",
              "checksum",
              "deadline",
              "status",
              "order",
              "current",
              "completed",
              "tbs",
              "timestamp"
            )
            .coerceTo("array"),
        }))
        .pluck(
          "id",
          "group",
          "parent",
          "storageId",
          "version",
          "index",
          "name",
          "creator",
          "timestamp",
          "meta",
          "attestations",
          "esignatures"
        ),
    811
  );

  return result.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
};

export const getZoneFromNode = async (nodeId) => {
  const r = await getDatabase();
  const qGroupId = r.db("hyker").table("file").get(nodeId)("group");
  const zoneId = await r
    .db("hyker")
    .table("group")
    .get(qGroupId)("team")
    .default(null);
  return zoneId;
};
