import { Data, CBOR } from "./";

import {
  SJCLKeyPair,
  SJCLPublicECDHKey,
  WebCryptoKeyPair,
  random,
  agree,
  encrypt,
  decrypt,
  importSecretKey,
  importPublicECDHKey,
  importPublicECDSAKey,
  importPrivateECDHKey,
  importPrivateECDSAKey,
  getEncryptedLength,
  getDecryptedLength,
} from "./crypto";

import {
  TYPE_MESSAGE,
  NONCE,
  SECRET,
  IMMUTABLE,
  RIKS_KEY_LENGTH_IN_BYTES,
  RIKS_KEY_ID_LENGTH_IN_BYTES,
  MESSAGE_NONCE_LENGTH_IN_BYTES,
  SIGNATURE_LENGTH_IN_BYTES,
} from "./riks";

import { isInstanceOf } from "./util";

import {
  TYPE,
  TYPE_KEY,
  VERSION,
  VERSION_VALUE,
  SENDER,
  KEY_ID,
  KEY,
  SIGNATURE,
} from "./riks/constants";

import { CIPHER_TEXT, KDSClient } from "./cryptobox";

const _fetch = typeof window === "undefined" ? require("node-fetch") : fetch;

export default class CryptoClient {
  constructor({ host, port, pkiHost, pkiPort, rootCertificate, issuer } = {}) {
    this.host = host;
    this.port = port;
    this.pkiHost = pkiHost;
    this.pkiPort = pkiPort;
    this.rootCertificate = rootCertificate;
    this.issuer = issuer;
  }

  async publicLookup(deviceId) {
    const { host, port, pkiHost, pkiPort, rootCertificate, issuer } = this;
    const kdsClient = new KDSClient(
      pkiHost || host,
      pkiPort || port,
      rootCertificate,
      issuer
    );
    const { publicECDHKey, publicECDSAKey } = await kdsClient.fetch(deviceId);
    return {
      publicECDHKey: (await publicECDHKey.export("spki")).toBase64(),
      publicECDSAKey: (await publicECDSAKey.export("spki")).toBase64(),
    };
  }

  async generateKey() {
    const keyId = random(RIKS_KEY_ID_LENGTH_IN_BYTES);
    const keyData = random(RIKS_KEY_LENGTH_IN_BYTES);
    const encodedKeyId = keyId.toBase64();
    const encodedKeyData = keyData.toBase64();
    return { keyId: encodedKeyId, symKey: encodedKeyData };
  }

  async wrapKey(
    keyId,
    symKey,
    sourceId,
    targetPubKey,
    privateKey,
    privateSignKey
  ) {
    const importedPublicECDHKey = await importPublicECDHKey(
      Data.fromBase64(targetPubKey),
      "spki"
    );
    const importedPrivateECDHKey = await importPrivateECDHKey(
      JSON.parse(privateKey),
      "jwk"
    );
    const importedPrivateECDSAKey = await importPrivateECDSAKey(
      JSON.parse(privateSignKey),
      "jwk"
    );

    const decodedKeyId = Data.fromBase64(keyId);
    const decodedSymKey = Data.fromBase64(symKey);

    const agreedKey = await agree(
      importedPublicECDHKey,
      importedPrivateECDHKey
    );

    const signature = await importedPrivateECDSAKey.sign(
      Data.join([
        Data.fromUTF8(TYPE_KEY),
        Data.fromUTF8(VERSION_VALUE),
        Data.fromUTF8(sourceId),
        decodedKeyId,
        decodedSymKey,
      ])
    );

    const cipherText = await encrypt(
      CBOR.encode({
        [TYPE]: TYPE_KEY,
        [VERSION]: VERSION_VALUE,
        [SENDER]: sourceId,
        [KEY_ID]: decodedKeyId,
        [KEY]: decodedSymKey,
        [SIGNATURE]: signature,
      }),
      agreedKey,
      Data.fromUTF8(VERSION_VALUE)
    );

    const secKey = CBOR.encode({
      [CIPHER_TEXT]: cipherText,
      [VERSION]: VERSION_VALUE,
      from: sourceId,
    });

    const encodedSecKey = secKey.toBase64();

    return { secKey: encodedSecKey };
  }

  async unwrapKey(keyId, secKey, sourcePubKey, sourcePubSignKey, privateKey) {
    const importedPublicECDHKey = await importPublicECDHKey(
      Data.fromBase64(sourcePubKey),
      "spki"
    );
    const importedPublicECDSAKey = await importPublicECDSAKey(
      Data.fromBase64(sourcePubSignKey),
      "spki"
    );
    const importedPrivateECDHKey = await importPrivateECDHKey(
      JSON.parse(privateKey),
      "jwk"
    );

    const decodedSecKey = CBOR.decode(Data.fromBase64(secKey));
    const {
      from,
      [CIPHER_TEXT]: cipherText,
      [VERSION]: version,
    } = decodedSecKey;

    const agreedKey = await agree(
      importedPublicECDHKey,
      importedPrivateECDHKey
    );

    const message = CBOR.decode(
      await decrypt(cipherText, agreedKey, Data.fromUTF8(version))
    );

    const ok = await importedPublicECDSAKey.verify(
      Data.join([
        Data.fromUTF8(TYPE_KEY),
        Data.fromUTF8(message[VERSION]),
        Data.fromUTF8(message[SENDER]),
        message[KEY_ID],
        message[KEY],
      ]),
      message[SIGNATURE]
    );

    if (!ok) {
      throw new Error("Invalid sign on response.");
    }

    const symKey = message[KEY];
    const encodedSymKey = symKey.toBase64();

    return { symKey: encodedSymKey };
  }

  async encrypt(
    sourceId,
    privateSignKey,
    keyId,
    symKey,
    secret = null,
    immutable = null
  ) {
    const importedPrivateECDSAKey = await importPrivateECDSAKey(
      JSON.parse(privateSignKey),
      "jwk"
    );
    const importedSymKey = await importSecretKey(
      Data.fromBase64(symKey),
      "raw"
    );

    const decodedSourceId = Data.fromUTF8(sourceId);
    const decodedKeyId = Data.fromBase64(keyId);
    const decodedSecret = secret || new Data(0); //Data.fromUTF8(secret);
    const decodedImmutable = immutable || new Data(0); //Data.fromUTF8(immutable);

    const nonce = random(MESSAGE_NONCE_LENGTH_IN_BYTES);

    const encryptedSecret = await encrypt(
      // Data
      decodedSecret,
      // Secret key
      importedSymKey,
      // AAD
      Data.join([decodedSourceId, nonce])
    );

    const signature = await importedPrivateECDSAKey.sign(
      // Signed data
      Data.join([
        Data.fromUTF8(TYPE_MESSAGE),
        Data.fromUTF8(VERSION_VALUE),
        decodedSourceId,
        decodedKeyId,
        nonce,
        decodedSecret,
        decodedImmutable,
      ])
    );

    const encodedSecData = CBOR.encode({
      [TYPE]: TYPE_MESSAGE,
      [VERSION]: VERSION_VALUE,
      [SENDER]: sourceId,
      [KEY_ID]: decodedKeyId,
      [NONCE]: nonce,
      [SECRET]: encryptedSecret,
      [IMMUTABLE]: decodedImmutable,
      [SIGNATURE]: signature,
    });

    return { secData: encodedSecData };
  }

  async decrypt(sourcePubSignKey, keyId, symKey, secData) {
    const importedPublicECDSAKey = await importPublicECDSAKey(
      Data.fromBase64(sourcePubSignKey),
      "spki"
    );
    const importedSymKey = await importSecretKey(
      Data.fromBase64(symKey),
      "raw"
    );

    const decodedKeyId = Data.fromBase64(keyId);
    const decodedSecData = CBOR.decode(secData);

    const {
      [SENDER]: sourceId,
      [NONCE]: nonce,
      [VERSION]: version,
      [SECRET]: encryptedSecret,
      [IMMUTABLE]: decodedImmutable = new Data(0),
      [SIGNATURE]: signature,
    } = decodedSecData;

    const decodedSourceId = Data.fromUTF8(sourceId);

    // Decrypt
    const decodedSecret = await decrypt(
      encryptedSecret,
      importedSymKey,
      Data.join([decodedSourceId, nonce])
    );

    // Verify
    const ok = await importedPublicECDSAKey.verify(
      Data.join([
        Data.fromUTF8(TYPE_MESSAGE),
        Data.fromUTF8(version),
        decodedSourceId,
        decodedKeyId,
        nonce,
        decodedSecret,
        decodedImmutable,
      ]),
      signature
    );

    if (!ok) {
      throw new Error("Invalid sign on message.");
    }

    return { secret: decodedSecret, immutable: decodedImmutable };
  }

  getSenderDeviceId(secData) {
    return CBOR.decode(secData)[SENDER];
  }

  async sign(privateSignKey, /*data,*/ decodedData) {
    const importedPrivateECDSAKey = await importPrivateECDSAKey(
      JSON.parse(privateSignKey),
      "jwk"
    );
    const signature = await importedPrivateECDSAKey.sign(decodedData);
    const encodedSignature = signature.toBase64();
    return { signature: encodedSignature };
  }

  async verify(publicSignKey, /*data,*/ decodedData, signature) {
    const importedPublicECDSAKey = await importPublicECDSAKey(
      Data.fromBase64(publicSignKey),
      "spki"
    );
    const decodedSignature = Data.fromBase64(signature);
    const ok = await importedPublicECDSAKey.verify(
      decodedData,
      decodedSignature
    );
    return ok;
  }

  getEncryptedLength(deviceId, secretLength = 0, immutableLength = 0) {
    return new LengthCalculator(deviceId).getEncryptedLength(
      secretLength,
      immutableLength
    );
  }

  getDecryptedLength(deviceId, encryptedLength = 0, immutableLength = 0) {
    return new LengthCalculator(deviceId).getDecryptedLength(
      encryptedLength,
      immutableLength
    );
  }
}

export class LengthCalculator {
  constructor(uid) {
    this.overhead =
      1 +
      this.getEncodedPairLength(TYPE.length, TYPE_MESSAGE.length) +
      this.getEncodedPairLength(VERSION.length, VERSION_VALUE.length) +
      this.getEncodedPairLength(SENDER.length, uid.length) +
      this.getEncodedPairLength(KEY_ID.length, RIKS_KEY_ID_LENGTH_IN_BYTES) +
      this.getEncodedPairLength(NONCE.length, MESSAGE_NONCE_LENGTH_IN_BYTES) +
      this.getEncodedPairLength(SIGNATURE.length, SIGNATURE_LENGTH_IN_BYTES);
  }

  getEncryptedLength(secretLength, immutableLength) {
    return (
      this.overhead +
      this.getEncodedPairLength(
        SECRET.length,
        getEncryptedLength(secretLength)
      ) +
      this.getEncodedPairLength(IMMUTABLE.length, immutableLength)
    );
  }

  getDecryptedLength(messageLength, immutableLength) {
    return getDecryptedLength(
      this.getDecodedItemLength(
        messageLength -
          this.overhead -
          this.getEncodedItemLength(SECRET.length) -
          this.getEncodedPairLength(IMMUTABLE.length, immutableLength)
      )
    );
  }

  getItemLength(length, encoded) {
    if (length <= 23) {
      return length + (1 + 0) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FF", 16)) {
      return length + (1 + 1) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFF", 16)) {
      return length + (1 + 2) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFFFFFF", 16)) {
      return length + (1 + 4) * (encoded ? 1 : -1);
    } else if (length <= parseInt("FFFFFFFFFFFFFFFF", 16)) {
      return length + (1 + 8) * (encoded ? 1 : -1);
    } else {
      throw new Error("Item too big.");
    }
  }

  getEncodedItemLength(length) {
    return this.getItemLength(length, true);
  }

  getDecodedItemLength(length) {
    return this.getItemLength(length, false);
  }

  getEncodedPairLength(keyLength, valueLength) {
    return (
      this.getEncodedItemLength(keyLength) +
      this.getEncodedItemLength(valueLength)
    );
  }
}

export async function deriveKeyPairsFromPrivateKeys(
  privateECDHKey,
  privateECDSAKey
) {
  const importedPrivateECDHKey = await importPrivateECDHKey(
    JSON.parse(privateECDHKey),
    "jwk"
  );
  const importedPrivateECDSAKey = await importPrivateECDSAKey(
    JSON.parse(privateECDSAKey),
    "jwk"
  );
  const importedECDHKeyPair = await deriveKeyPairFromPrivate(
    importedPrivateECDHKey
  );
  const importedECDSAKeyPair = await deriveKeyPairFromPrivateSign(
    importedPrivateECDSAKey
  );
  const { publicKey: importedPublicECDHKey } = importedECDHKeyPair;
  const { publicKey: importedPublicECDSAKey } = importedECDSAKeyPair;
  const exportedPublicECDHKey = await importedPublicECDHKey.export("spki");
  const exportedPublicECDSAKey = await importedPublicECDSAKey.export("spki");
  const encodedPublicECDHKey = exportedPublicECDHKey.toBase64();
  const encodedPublicECDSAKey = exportedPublicECDSAKey.toBase64();

  return {
    publicECDHKey: encodedPublicECDHKey,
    privateECDHKey,
    publicECDSAKey: encodedPublicECDSAKey,
    privateECDSAKey,
  };
}

export async function deriveKeyPairFromPrivateSign(privateKey) {
  return await deriveKeyPairFromPrivate(privateKey, true);
}

export async function deriveKeyPairFromPrivate(privateKey, signing = false) {
  const { x, y } = await privateKey.export("jwk");
  const jwk = { kty: "EC", crv: "P-256", x, y };
  const importKey = signing ? importPublicECDSAKey : importPublicECDHKey;
  const publicKey = await importKey(jwk, "jwk");
  const useSJCL = isInstanceOf(SJCLPublicECDHKey, publicKey);
  const KeyPair = useSJCL ? SJCLKeyPair : WebCryptoKeyPair;
  return new KeyPair(publicKey, privateKey);
}
