import * as Sentry from "@sentry/browser";

//const defer = () => {
//  let res, rej;
//  const promise = new Promise((resolve, reject) => {
//    res = resolve;
//    rej = reject;
//  });
//  promise.resolve = res;
//  promise.reject = rej;
//  return promise;
//};

//const postMessage = async message => {
//  const id = (postMessage.messageCount = ++postMessage.messageCount || 0);
//  postMessage.deferredMessages = Object.assign(
//    postMessage.deferredMessages || {},
//    { [id]: defer() }
//  );
//  navigator.serviceWorker.controller.postMessage({
//    id,
//    origin: "service-worker-client",
//    message
//  });
//  return postMessage.deferredMessages[id];
//};

//const serviceWorker = defer();

let setServiceWorker,
  serviceWorker = new Promise((r) => (setServiceWorker = r));

export const registerServiceWorker = async () => {
  try {
    if (!navigator.serviceWorker) {
      console.warn(
        "Failed to start service worker: Not supported (Please note that it may not be available in private mode.)"
      );
      return;
    }

    await navigator.serviceWorker.register("/sw.js");
    ////serviceWorker.resolve(await navigator.serviceWorker.ready);
    //await navigator.serviceWorker.ready;
    setServiceWorker(await navigator.serviceWorker.ready);

    //navigator.serviceWorker.addEventListener("message", async event => {
    //  if (!event.data) return;
    //  if (event.data.origin === "service-worker-client") {
    //    postMessage.deferredMessages[event.data.id].resolve(event.data.message);
    //    delete postMessage.deferredMessages[event.data.id];
    //  } else {
    //    navigator.serviceWorker.controller.postMessage({
    //      id: event.data.id,
    //      origin: event.data.origin,
    //      message: await receiveMessage(event.data.message)
    //    });
    //  }
    //});

    //const receiveMessage = async message => {
    //  if (!("name" in message)) {
    //    console.warn(`Unknown call from service worker: ${message}`);
    //    return;
    //  }
    //  const { name, args } = message;
    //  if (name in commands) {
    //    return commands[name](...args);
    //  } else {
    //    console.warn(`Unhandled call from service worker: ${name}`);
    //  }
    //};

    //navigator.serviceWorker.addEventListener("controllerchange", (event) => {
    //  navigator.serviceWorker.controller.postMessage({ init: true });
    //});
    //if (navigator.serviceWorker.controller) {
    //  navigator.serviceWorker.controller.postMessage({ init: true });
    //}
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    //serviceWorker.reject(e);
  }
};

//export default new Proxy(
//  {},
//  {
//    get: (target, prop) => async (...args) =>
//      postMessage({
//        name: prop,
//        args
//      })
//  }
//);

export const pushSubscribe = async (options) => {
  return (await serviceWorker).pushManager.subscribe(options);
};

//export const download = (file, intent, onProgress, onComplete) => {
//  navigator.serviceWorker.controller.postMessage({ init: true });
//
//  const downloadId = (download.nextId = ++download.nextId || 0);
//  download[downloadId] = {
//    onProgress: onProgress || (() => {}),
//    onComplete: onComplete || (() => {})
//  };
//
//  const searchParams = new URLSearchParams();
//  searchParams.set("downloadId", downloadId);
//  searchParams.set("fileId", file.id);
//  searchParams.set("fileName", file.name);
//  searchParams.set("fileSize", file.meta.size);
//  searchParams.set("storageId", file.storageId);
//  searchParams.set("versionId", file.version);
//  searchParams.set("keyId", file.meta.key);
//  searchParams.set("intent", intent);
//  window.location.href = `/download?${searchParams.toString()}`;
//};

//const commands = {
//  getIdentity: async () => {
//    const dispatch = getDispatch();
//    const { credentials } = await dispatch(getContext());
//    return credentials;
//  },
//  getSymKey: async keyId => {
//    const { deviceId } = await commands.getIdentity();
//    const dispatch = getDispatch();
//    return dispatch(getSymKey(deviceId, keyId));
//  },
//  getAccessToken: async () => {
//    const dispatch = getDispatch();
//    const accessToken = await dispatch(getToken());
//    const { user: userId } = jwtPeek(accessToken);
//    return {
//      accessToken,
//      userId,
//      baseAuthURL: API_URL_PREFIX_AUTH
//    };
//  },
//  getPublicKeys: async deviceId => {
//    const dispatch = getDispatch();
//    const [publicECDHKey, publicECDSAKey] = await dispatch(
//      cachePublicKeys(deviceId)
//    );
//    return { publicECDHKey, publicECDSAKey };
//  },
//  onProgress: async (downloadId, progress) => {
//    if (downloadId in download) {
//      download[downloadId].onProgress(progress);
//    }
//  },
//  onComplete: async downloadId => {
//    if (downloadId in download) {
//      download[downloadId].onComplete();
//    }
//  }
//};
