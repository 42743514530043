import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Dialog } from "@blueprintjs/core";

import {
  PANE_ZONES,
  PANE_DOCS,
  PANE_CHAT,
  PANE_USERS,
  PANE_CHATS,
  PANE_ACTIVITY,
  PANE_ACCOUNT,
  PANE_ADMIN,
} from "../layout/layoutSlice.js";

import {
  togglePane,
  showPane,
  selectPaneCeil,
  selectPaneList,
  selectIsMobile,
  selectIsMiddle,
  selectIsTablet,
  selectIsLandscape,
} from "../layout/layoutSlice.js";

import { setDialog } from "../../app/appSlice.js";
import { TREE_ACTIONS } from "../dialogs/Dialogs.js";

import appContext from "../../app/appContext.js";
import { selectIdentity } from "../../app/topSlice.js";
import { formatUser } from "../format/format.js";
//import { Users as UsersIcon } from "../login/icons.tsx";
import { ZoneUsers, FolderUsers } from "../login/icons.tsx";
import styles from "./Header.module.css";
import { Brand } from "../menu/Menu.js";
import { selectNotifications } from "../../app/appSlice";
import { appState } from "../../app/appState.js";
import Groups from "../groups";

import {
  TEAM_MESSAGE_READ,
  TEAM_MESSAGE_CREATE,
  WORKSPACE_LIST_TEAMS,
  WORKSPACE_UPLOAD_LOGO,
} from "../../app/permissions.js";

const {
  derive: {
    getUsersFromCurrentZoneRootGroup,
    getUsersFromGroup,
    getGroupsFromCurrentZone,
  },
  selectors: { selectGraph, selectNodes, selectPermissions },
} = appState;

const isString = (x) => typeof x === "string" || x instanceof String;
const classNames = (...x) => x.filter(isString).join(" ");

export function Header() {
  const dispatch = useDispatch();
  const [showGroups, setShowGroups] = useState(false);
  const [showGroup, setShowGroup] = useState(false);

  const {
    zoneName,
    group,
    isRestricted,
    rootGroupId,
    virtualRootGroupId,
    hasGroupChoice,
  } = useContext(appContext);

  const graph = useSelector(selectGraph);
  const nodes = useSelector(selectNodes);
  const ceil = useSelector(selectPaneCeil);
  const list = useSelector(selectPaneList);
  const isMobile = useSelector(selectIsMobile);
  const isMiddle = useSelector(selectIsMiddle);
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  const notifications = useSelector(selectNotifications);
  const permissions = useSelector(selectPermissions);
  const { id: userId } = useSelector(selectIdentity);

  const [timeOfLastSeenActivity, setTimeOfLastSeenActivity] = useState(0);
  const panes = list.slice(-ceil).map(({ pane }) => pane);
  const activityOpen = panes.includes(PANE_ACTIVITY.pane);

  useEffect(() => {
    setTimeOfLastSeenActivity(Date.now());
  }, [activityOpen]);

  //if (isMobile && isLandscape) {
  //  return null;
  //}

  let choice;

  const isZone =
    !group ||
    group.id == virtualRootGroupId ||
    group.id == rootGroupId ||
    !(group.rootFile in nodes);

  if (hasGroupChoice) {
    if (isZone) {
      choice = zoneName;
    } else {
      choice = nodes[group.rootFile].name;
    }
  }

  const timeOfLastNotification = Math.max(
    ...Object.values(notifications)
      .filter(({ origin }) => origin.id !== userId)
      .map((_) => _.time)
  );
  const ringBell = timeOfLastNotification > timeOfLastSeenActivity;

  const initials = zoneName.replace(/[^\p{N}\p{L}\p{M}]/gu, "").slice(0, 2);

  const users = group
    ? getUsersFromGroup(graph, group.id).filter((user) => user.user != userId)
    : [];

  return (
    <div className={styles.outer}>
      <div
        className={classNames(
          styles.header,
          isTablet && styles.tablet,
          isMiddle && styles.middle,
          isMobile && styles.mobile,
          isMobile && isLandscape && styles.landscape
        )}
      >
        <div
          className={classNames(
            styles.name,
            panes.includes(PANE_ZONES.pane) && styles.active
          )}
          onClick={() => isMobile && dispatch(togglePane(PANE_ZONES))}
        >
          <div className={styles.icon}>{initials}</div>
          <div className={styles.zone}>{zoneName}</div>
        </div>
        <div
          className={classNames(
            styles.wrapper,
            styles.select,
            choice && styles.choice
          )}
        >
          <div
            className={classNames(styles.change, choice && styles.choice)}
            onClick={() => choice && setShowGroups(!showGroups)}
          >
            {(choice || !!users.length) && (
              <div className={styles.users}>
                {isZone ? <ZoneUsers /> : <FolderUsers />}
              </div>
            )}
            {choice && (
              <>
                <div className={styles.group}>{choice}</div>
                <div className={styles.caret}>
                  <Icon icon="caret-down" />
                </div>
              </>
            )}
          </div>
          {showGroups && (
            <Popup close={() => setShowGroups(false)}>
              <div className={styles.container}>
                <Groups onClick={() => setShowGroups(false)} />
              </div>
            </Popup>
          )}
        </div>
        {/*isMobile && <br />*/}
        <div
          key={group?.id}
          className={styles.recipients}
          ref={(el) => {
            if (el) {
              setTimeout(() => {
                if (document.body.contains(el)) {
                  const grid = [...el.children];
                  if (grid.length) {
                    const baseOffset = grid[0].offsetTop;
                    const breakIndex = grid.findIndex(
                      (item) => item.offsetTop > baseOffset
                    );
                    if (breakIndex != -1) {
                      const nonVisibles = grid.length - breakIndex; // + 1;
                      el.nextElementSibling.firstElementChild.innerHTML =
                        "+" + nonVisibles;
                      return;
                    }
                  }
                  el.nextElementSibling.firstElementChild.innerHTML = "";
                }
              }, 500);
            }
          }}
        >
          {users
            .map((user) => [dispatch(formatUser(user)), user.deprecatedId])
            .map(([[[primary, secondary], detailed], uri]) => (
              <div key={uri} className={styles.recipient} title={detailed}>
                <span>{primary}</span>
                {secondary && <span>{secondary}</span>}
              </div>
            ))}
        </div>
        <div className={classNames(styles.more, styles.wrapper)}>
          <div
            className={styles.count}
            onClick={() => setShowGroup(!showGroup)}
          />
          {showGroup && (
            <Popup close={() => setShowGroup(false)}>
              <div className={classNames(styles.container, styles.right)}>
                <Groups group={true} />
              </div>
            </Popup>
          )}
        </div>
        <div>
          {group && (
            <div
              className={classNames(
                styles.pill,
                panes.includes(PANE_DOCS.pane) && styles.active
              )}
              onClick={() => {
                dispatch(togglePane(PANE_DOCS));
                //if (isMobile && panes.includes(PANE_DOCS.pane)) {
                //  dispatch(setDialog({ dialog: TREE_ACTIONS }))
                //} else {
                //  dispatch(togglePane(PANE_DOCS));
                //}
                ////if (!panes.includes(PANE_DOCS.pane)) {
                ////} else {
                ////  dispatch(setDialog({ dialog: TREE_ACTIONS }))
                ////}
              }}
            >
              <Icon icon="folder-open" iconSize={18} />
              <span>Docs</span>
            </div>
          )}
        </div>
        <div>
          {group && (
            <div
              className={classNames(
                styles.pill,
                panes.includes(PANE_CHAT.pane) && styles.active
              )}
              onClick={() => dispatch(togglePane(PANE_CHAT))}
            >
              <Icon icon="comment" iconSize={18} />
              <span>Chat</span>
            </div>
          )}
        </div>
        <div>
          {group && (
            <div
              className={classNames(
                styles.pill,
                panes.includes(PANE_USERS.pane) && styles.active
              )}
              onClick={() => dispatch(togglePane(PANE_USERS))}
            >
              <Icon icon="people" iconSize={18} />
              <span>Users</span>
            </div>
          )}
        </div>
        <div>
          <div
            className={classNames(
              styles.dot,
              panes.includes(PANE_ACTIVITY.pane) && styles.active,
              !panes.includes(PANE_ACTIVITY.pane) && ringBell && styles.ringBell
            )}
            onClick={() => dispatch(togglePane(PANE_ACTIVITY))}
          >
            <Icon icon="notifications" />
          </div>
        </div>
        <div>
          <div
            className={classNames(
              styles.dot,
              panes.includes(PANE_ACCOUNT.pane) && styles.active
            )}
            onClick={() => dispatch(togglePane(PANE_ACCOUNT))}
          >
            <Icon icon="more" />
          </div>
        </div>
      </div>
    </div>
  );
}

export const Popup = ({ close, children }) => {
  const ref = useRef();
  useEffect(() => {
    const click = (e) => {
      if (!ref.current || !ref.current.contains(e.target)) {
        close();
      }
    };
    document.addEventListener("click", click);
    return () => {
      document.removeEventListener("click", click);
    };
  }, []);
  return <div ref={ref}>{children}</div>;
};
