export const {
  TEMPLATE_CREATE,
  CATEGORY_GLOBAL_CREATE,
  CATEGORY_LOCAL_CREATE,
  CATEGORY_GLOBAL_REMOVE,
  CATEGORY_LOCAL_REMOVE,
  FILE_INFO,

  FILE_CREATE,
  FILE_DELETE,
  FILE_DELETE_OWN,
  FILE_DOWNLOAD,
  FILE_PREVIEW,
  FILE_MOVE,
  FILE_MOVE_OWN,
  FILE_UPDATE,
  FILE_UPDATE_OWN,
  FILE_READ,
  FILE_READ_OWN,

  ATTESTATION_REQUEST,
  ATTESTATION_APPROVE,
  ATTESTATION_REJECT,

  ESIGNATURE_REQUEST,
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,

  FOLDER_CREATE,
  FOLDER_DELETE,
  FOLDER_DELETE_OWN,
  FOLDER_MOVE,
  FOLDER_MOVE_OWN,
  FOLDER_RESTRICT,
  FOLDER_UNRESTRICT,

  GROUP_TEAMMATE_ADD,
  GROUP_TEAMMATE_REMOVE,

  TEAM_MESSAGE_READ,
  TEAM_MESSAGE_CREATE,
  TEAM_MESSAGE_DELETE,
  TEAM_MESSAGE_EDIT,

  TEAM_CREATE,
  TEAM_EXPIRE,
  TEAM_RENAME,
  TEAM_TEAMMATE_ADD,
  TEAM_TEAMMATE_ADD_OTHER,
  TEAM_TEAMMATE_LEAVE,
  TEAM_TEAMMATE_REMOVE,
  TEAM_RESTRICT,
  TEAM_UNRESTRICT,
  TEAM_MUTE,

  WORKSPACE_TEAMMATE_INVITE,
  WORKSPACE_ROLE_ASSIGN,

  WORKSPACE_ROLE_ADD,
  WORKSPACE_DELETE_LOGS,
  WORKSPACE_EDIT_LOGS,
  WORKSPACE_EXPORT_LOGS,
  WORKSPACE_EXPORT_REPORTS,
  WORKSPACE_LIST_TEAMS,
  WORKSPACE_UPLOAD_LOGO,
  WORKSPACE_GET_CONFIG,
  WORKSPACE_SET_CONFIG,
  WORKSPACE_GET_BULLHORN_ZONE,
  WORKSPACE_SET_BULLHORN_ZONE,

  LOG_ATTESTATION_REQUEST,
  LOG_ATTESTATION_APPROVE,
  LOG_ATTESTATION_REJECT,
  LOG_ESIGNATURE_REQUEST,
  LOG_ESIGNATURE_APPROVE,
  LOG_ESIGNATURE_REJECT,
  LOG_FILE_CREATE,
  LOG_FILE_DELETE,
  LOG_FILE_DOWNLOAD,
  LOG_FILE_MOVE,
  LOG_FILE_UPDATE,
  LOG_FILE_PREVIEW,
  LOG_FILE_READ,
  LOG_FOLDER_CREATE,
  LOG_FOLDER_DELETE,
  LOG_FOLDER_MOVE,
  LOG_FOLDER_RESTRICT,
  LOG_FOLDER_UNRESTRICT,
  LOG_MESSAGE_CREATE,
  LOG_MESSAGE_DELETE,
  LOG_MESSAGE_EDIT,
  LOG_MESSAGE_READ,
  LOG_TEAM_CREATE,
  LOG_TEAM_EXPIRE,
  LOG_TEAM_DID_EXPIRE,
  LOG_TEAM_RENAME,
  LOG_TEAM_TEAMMATE_ADD,
  LOG_TEAM_TEAMMATE_REMOVE,
  LOG_TEAM_RESTRICT,
  LOG_TEAM_UNRESTRICT,

  LOG_GROUP_TEAMMATE_ADD,
  LOG_GROUP_TEAMMATE_REMOVE,

  LOG_WORKSPACE_DELETE_LOGS,
  LOG_WORKSPACE_EDIT_LOGS,
  LOG_WORKSPACE_EXPORT_LOGS,
  LOG_WORKSPACE_EXPORT_REPORTS,
  LOG_WORKSPACE_LIST_TEAMS,
  LOG_WORKSPACE_ROLE_ADD,
  LOG_WORKSPACE_ROLE_ASSIGN,
  LOG_WORKSPACE_TEAMMATE_INVITE,
  WORKSPACE_TEAMMATE_REMOVE,
  LOG_WORKSPACE_UPLOAD_LOGO
} = new Proxy(
  {},
  {
    get: (_, k) => {
      return k === "TEMPLATE_CREATE" ? "TEAM_CREATE" : k;
    }
  }
);
