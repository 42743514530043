/* A Future is an externally resolvable Promise */
export default class Future {
  constructor() {
    let that = this;
    this.promise = new Promise((resolve, reject) => {
      that.resolve = value => {
        that.done = true;
        resolve(value);
      };
      that.reject = value => {
        that.done = true;
        reject(value);
      };
    });
  }

  then(callback) {
    return this.promise.then(callback);
  }

  catch(callback) {
    return this.promise.catch(callback);
  }

  isDone() {
    return this.done;
  }

  async get() {
    return this.promise;
  }
}
