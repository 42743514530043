import Data from "../../data";
import PrivateECDSAKey from "../private_ecdsa_key";

import { importWebCryptoPrivateKey, exportWebCryptoPrivateKey } from "./wc";
import { crypto } from "../../crypto";
import {
  assertInstanceOf,
  getClassName,
  assertObject,
  isEdge
} from "../../util";

export default class WebCryptoPrivateECDSAKey extends PrivateECDSAKey {
  constructor(key) {
    if (isEdge()) {
      assertObject(key, "key");
    } else if (getClassName(key) !== "CryptoKey") {
      throw new Error(
        `key must be a CryptoKey object. (got ${key.constructor.name})`
      );
    }

    super(key);
  }

  static async import(key, format) {
    return new WebCryptoPrivateECDSAKey(
      await importWebCryptoPrivateKey(
        key,
        format,
        {
          name: "ECDSA",
          namedCurve: "P-256"
        },
        ["sign"]
      )
    );
  }

  async export(format) {
    return exportWebCryptoPrivateKey(this.key, format);
  }

  async sign(data) {
    assertInstanceOf(Data, data, "signing data");

    return new Data(
      await crypto.subtle.sign(
        {
          name: "ECDSA",
          hash: { name: "SHA-256" }
        },
        this.key,
        data.createArrayBuffer()
      )
    );
  }
}
