import React, { memo, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import appContext from "../../app/appContext";

import {
  TEAM_MESSAGE_READ,
  TEAM_MESSAGE_CREATE,
} from "../../app/permissions.js";

import Splash from "./splash";
import Bar from "./bar";
import Feed from "./feed";
import Compose from "./compose";

import { outer } from "./Chat.module.css";

import { appState } from "../../app/appState.js";

const {
  derive: { getGroupsFromCurrentZone, getUsersFromGroup },
  actions: { setCurrentNode },
  selectors: { selectGraph, selectPermissions },
} = appState;

export const Chat = memo(({ ani, isMobile, isLandscape, realEstateBudget }) => {
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const permissions = useSelector(selectPermissions);
  const { groupId, rootGroupId, isRestricted } = useContext(appContext);

  const groups = getGroupsFromCurrentZone(graph);

  const noAccess =
    (isRestricted && groups.length == 1 && groups[0].id == rootGroupId) ||
    !permissions[TEAM_MESSAGE_READ] ||
    !permissions[TEAM_MESSAGE_CREATE];

  if (ani || !groupId || noAccess) {
    return <Splash noAccess={noAccess} />;
  }

  return (
    <div className={outer}>
      <Bar />
      <Feed key={groupId} />
      <Compose
        isMobile={isMobile}
        isLandscape={isLandscape}
        realEstateBudget={realEstateBudget}
      />
    </div>
  );
});
