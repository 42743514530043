import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { classNames, fileSizeHuman, fileExt } from "utils";
import { iconFactory, FolderIcon } from "../../helpers/tree-icons";
import appContext from "../../app/appContext";
import { hidePane, PANE_FILE } from "../layout/layoutSlice";
import { appState } from "../../app/appState.js";
import { startDownload } from "../transfer/transferSlice.js";
import { formatUser } from "../format/format.js";
import styles from "./File.module.css";

export const ARG_DATE = [
  "en-GB",
  {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  },
];

const {
  derive: { getUsersFromCurrentZoneRootGroup },
  selectors: { selectGraph },
} = appState;

export function File({ ani }) {
  const dispatch = useDispatch();
  const { node, rootGroup, virtualRootGroup } = useContext(appContext);

  const graph = useSelector(selectGraph);

  if (/*ani ||*/ !node) {
    return null;
  }

  let parent = graph.nodes[node.parent];

  if (!parent) {
    return (
      <div className={styles.outer}>
        <div className={styles.bar}>
          <div className={styles.header}>
            <span>Info</span>
          </div>
          <div
            className={styles.header}
            onClick={() => dispatch(hidePane(PANE_FILE))}
          >
            <Icon icon="cross" />
          </div>
        </div>
        <div className={styles.rest}>
          <div className={styles.empty}>
            <div>
              <Icon iconSize={44} icon="eye-off" />
            </div>
            <div>
              <div>Nothing To See Here</div>
              <div>Please select a file to display its information</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    parent.id == rootGroup.rootFile ||
    parent.id == virtualRootGroup?.rootFile
  ) {
    parent = null;
  }

  const zoneUsers = getUsersFromCurrentZoneRootGroup(graph);

  const fullDate = new Date(node.timestamp);

  const isFolder = !node.storageId;

  const { label: sizeLabel } = isFolder
    ? { label: "" }
    : fileSizeHuman(node.meta.size);

  const { name } = node;
  const _ext = fileExt(name);

  const ext = isFolder || _ext === name ? null : _ext;
  const from = zoneUsers.find((user) => user.user == node.creator);
  const [[primary], detailed] = (from && dispatch(formatUser(from))) || [[]];
  const date = fullDate.toLocaleDateString(...ARG_DATE);
  const hours = `0${fullDate.getHours()}`.slice(-2);
  const mins = `0${fullDate.getMinutes()}`.slice(-2);
  const time = `${date} ${hours}:${mins}`;

  return (
    <div className={styles.outer}>
      <div className={styles.bar}>
        <div className={styles.header}>
          <span>Info</span>
        </div>
        <div
          className={styles.header}
          onClick={() => dispatch(hidePane(PANE_FILE))}
        >
          <Icon icon="cross" />
        </div>
      </div>
      <div className={styles.rest}>
        <div className={styles.card}>
          <div className={styles.file}>
            <div className={styles.icon}>
              {isFolder ? <FolderIcon /> : <div>{iconFactory(name)}</div>}
            </div>
            <div className={styles.name}>
              <div className={styles.textOverflow}>{name}</div>
            </div>
          </div>
          <br />
          <div className={styles.params}>
            {from && (
              <div className={styles.pill} title={detailed}>
                <span>from</span>
                <span>{primary}</span>
              </div>
            )}
            {time && (
              <div className={styles.pill}>
                <span>date</span>
                <span>{time}</span>
              </div>
            )}
            {ext && (
              <div className={styles.pill}>
                <span>ext</span>
                <span style={{ textTransform: "lowercase" }}>{ext}</span>
              </div>
            )}
            {sizeLabel && (
              <div className={styles.pill}>
                <span>size</span>
                <span>{sizeLabel}</span>
              </div>
            )}
            {parent && (
              <div className={styles.pill}>
                <span>parent</span>
                <span>{parent.name}</span>
              </div>
            )}
            <p className={styles.br} />
          </div>
        </div>
        <br />
        <br />
        {!isFolder && (
          <>
            <div
              className={classNames(styles.button, styles.download)}
              onClick={async (_) => {
                await dispatch(startDownload(node));
              }}
            >
              <Icon icon="download" />
              <span>Download</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
