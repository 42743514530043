import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { Icon, Button, ButtonGroup } from "@blueprintjs/core";
import { classNames, entries } from "utils";

import { useRoles } from "../../app/rolesContext.js";

import styles from "./roles.module.css";

const roleCompare = (a, b) => a.privilegeOrder - b.privilegeOrder;

//export function useRoles() {
//  const [roles, setRoles] = useState([]);
//  const [doneFetching, setDoneFetching] = useState(false);
//  const dispatch = useDispatch();
//
//  useEffect(() => {
//    (async () => {
//      const token = await dispatch(getToken());
//      const { permissions: { roles: { global: myRoleId } }, org } = jwtPeek(
//        token
//      );
//
//      const api = createApiClient(config.API_URL_PREFIX);
//      const roles = await api
//        .token(token)
//        .retry()
//        .orgs(org)
//        .roles()
//        .get();
//
//      const myRole = roles.find(({ id }) => myRoleId === id);
//      myRole.currentRole = true;
//
//      let allowedToSet = role => role.privilegeOrder >= myRole.privilegeOrder;
//      if (
//        org === "manpowergroup" ||
//        org === "experis" ||
//        org === "aspia" ||
//        org == "hykersec" ||
//        org === "manpowergroupno"
//      ) {
//        allowedToSet = role => role.privilegeOrder > myRole.privilegeOrder;
//      }
//
//      const modifiedRoles = roles.map(role => ({
//        allowedToSet: allowedToSet(role),
//        ...role
//      }));
//      setRoles(modifiedRoles.sort(roleCompare));
//      setDoneFetching(true);
//    })();
//  }, []);
//
//  return [doneFetching, roles];
//}

export default ({ onSelect, currentRole }) => {
  const [popOverOpened, setPopOverOpened] = useState(false);
  const [current, setCurrent] = useState(currentRole);
  const [showPermissions, setShowPermissions] = useState(null);
  //const [doneFetching, roles] = useRoles();
  const roles = useRoles();
  const dispatch = useDispatch();

  useEffect(() => setCurrent(currentRole), [currentRole]);

  const setCurrentRole = async role => {
    await onSelect(role.id);
    setPopOverOpened(false);
  };

  const permissionSort = ([permA, allowA], [permB, allowB]) => {
    if (allowA && !allowB) {
      return -1;
    } else if (allowB && !allowA) {
      return 1;
    }

    const order = o =>
      o
        .replace(/^FILE/, 1)
        .replace(/^FOLDER/, 2)
        .replace(/^TEAM/, 3)
        .replace(/^WORK/, 4)
        .replace(/^LOG/, 5);

    permA = order(permA);
    permB = order(permB);

    return permA.localeCompare(permB);
  };

  if (
    //!doneFetching ||
    !roles ||
    !roles.length ||
    !roles.some(role => role.allowedToSet)
  ) {
    return null;
  }

  let fakeCurrent = roles.find(role => role.id === current);

  if (!fakeCurrent) {
    fakeCurrent = roles
      .filter(role => role.allowedToSet)
      .sort(roleCompare)
      .reverse()[0];
  }

  const hasSupply = roles.some(
    role => role.allowedToSet && role.id != fakeCurrent.id
  );

  const color = fakeCurrent.color;

  const name = showPermissions
    ? "Permissions:"
    : popOverOpened ? "Select Role:" : fakeCurrent.name;

  return (
    <>
      <div
        className={classNames(
          styles.largeButton,
          popOverOpened && styles.opened
        )}
        onClick={() => {
          if (!hasSupply) {
            return;
          }
          if (showPermissions) {
            setShowPermissions(null);
          } else {
            setPopOverOpened(!popOverOpened);
          }
        }}
        style={{
          color: color.foreground,
          background: color.background
        }}
      >
        {!showPermissions && !popOverOpened && <Icon icon="walk" />}
        <span>{name}</span>
        <Icon
          icon={
            !hasSupply
              ? "blank"
              : !showPermissions && !popOverOpened
                ? "caret-down"
                : "small-cross"
          }
        />
      </div>
      {showPermissions ? (
        <div className={styles.list} key="list">
          {entries(showPermissions)
            .sort(permissionSort)
            .map(([perm, allow]) => {
              return (
                <div key={perm}>
                  <span>{perm.replace(/_/g, " ")}</span>
                  <Icon icon={allow ? "small-tick" : "small-cross"} />
                </div>
              );
            })}
        </div>
      ) : popOverOpened ? (
        <div className={styles.info}>
          {roles
            .filter(role => role.allowedToSet && role.id != fakeCurrent.id)
            .map(role => (
              <div
                key={role.id}
                className={styles.smallButton}
                style={{
                  color: role.color.foreground,
                  background: role.color.background
                  //border: `solid 1px ${props.color.border}`
                }}
                onClick={_ => setCurrentRole(role)}
              >
                <span className={styles.main}>{role.name}</span>
                <span
                  onClick={e => {
                    e.stopPropagation();
                    setShowPermissions(role.actions);
                  }}
                >
                  i
                </span>
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
};
