import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useSelector } from "react-redux";
import {
  b64Url2Utf8,
  createSagaActions,
  get,
  json,
  jwtPeek,
  request,
} from "utils";
import {
  setBullhornHideAddZoneButton,
  setBullhornHideMenu,
  setBullhornReady,
} from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { sendInviteToUser } from "../../app/realworld.js";
import { getToken, redirect, selectIdentity } from "../../app/topSlice.js";
import { API_URL_PREFIX } from "../../config";
import { validateEmail } from "../invite/email.tsx";
import { startUpload } from "../transfer/transferSlice.js";

const {
  derive: { getNodesFromZone, getUsersFromCurrentZoneRootGroup },
  selectors: { selectGraph },
  actions: {
    removeNode,
    createUser,
    removeMember,
    addMember,
    loadZone,
    setCurrentZone,
    moveZoneFirst,
  },
} = appState;

export const blueprint = {
  name: "bullhorn",
  initialState: {
    token: { restUrl: null, bhRestToken: null },
    zoneName: null,
    ZoneId: null,
    corporationId: null,
    entityType: null,
    jobOrderId: null,
    contactRole: null,
    relatedZones: [],
    relatedZonesStatus: "not-loaded",
    contacts: [],
    contactStatus: "not-loaded",
    fileInfos: [],
    fileInfosStatus: "not-loaded",
    showAllFileInfos: false, // true if we want to show all fileInfos, false if we want to show grouped fileInfos
    hasPermissions: false,
    runFlow: false,
    syncFilesOnly: false, // true if we want to sync files only, false if we want to sync contacts and files
    hasDialogsToShow: false, // true if there is something to sync, false if there is nothing to sync
    disregarded: {
      contacts: [],
      fileInfos: [],
      status: "not-loaded",
      active: true,
    },
    nonformattedFiletypes: {
      all: [],
      dbName: "nonformattedFiletypesFilter",
      filter: [],
      status: { all: "not-loaded", filter: "not-loaded" },
    },
    defaultCountryCode: {
      dbName: "defaultCountryCode",
      countryCode: "NO",
      status: "not-loaded",
    },
    status: {
      contacts: {
        all: [],
        dbName: "contactStatusFilter",
        filter: [],
        status: { all: "not-loaded", filter: "not-loaded" },
      },
      filetypes: {
        all: [],
        dbName: "filetypeFilter",
        filter: [],
        status: { all: "not-loaded", filter: "not-loaded" },
      },
      submissions: {
        all: [],
        dbName: "submissionStatusFilter",
        filter: [],
        status: { all: "not-loaded", filter: "not-loaded" },
      },
    },
  },
  reducers: {
    setBhToken: (state, { payload }) => {
      state.token = payload;
    },
    setCorporationId: (state, { payload }) => {
      state.corporationId = payload;
    },
    setZoneId: (state, { payload }) => {
      state.zoneId = payload;
    },
    setZoneName: (state, { payload }) => {
      state.zoneName = payload;
    },
    setRelatedZones: (state, { payload }) => {
      state.relatedZones = payload;
    },
    setEntityType: (state, { payload }) => {
      state.entityType = payload;
    },
    setEntityId: (state, { payload }) => {
      state.entityId = payload;
    },
    setJobOrderId: (state, { payload }) => {
      state.jobOrderId = payload;
    },
    setStatus: (state, { payload }) => {
      const { name, filter } = payload;
      state.status[name].filter = filter;
    },
    setShowAllFileInfos: (state, { payload }) => {
      state.showAllFileInfos = payload;
    },
    setDefaultCountryCode: (state, { payload }) => {
      state.defaultCountryCode.countryCode = payload;
    },
    setSyncFilesOnly: (state, { payload }) => {
      state.syncFilesOnly = payload;
    },
    setRunFlow: (state, { payload }) => {
      state.runFlow = payload;
    },
    setHasPermissions: (state, { payload }) => {
      state.hasPermissions = payload;
    },
    setHasDialogsToShow: (state, { payload }) => {
      state.hasDialogsToShow = payload;
    },
    setNonformattedFiletypes: (state, { payload }) => {
      state.nonformattedFiletypes.filter = payload;
    },
    setDisregarded: (
      state,
      { payload: { contacts, fileInfos, active, status } }
    ) => {
      !!contacts && state.disregarded.contacts.push(contacts);
      !!fileInfos && state.disregarded.fileInfos.push(fileInfos);
      state.disregarded.active = active ?? state.disregarded.active;
      state.disregarded.status = status ?? state.disregarded.status;
    },
  },
  extraReducers: (builder) => {
    builder
      ///////// FETCH_CONTACTS /////////////////////////////////////////
      .addCase(fetchContacts.pending, (state) => {
        state.contactStatus = "loading...";
      })
      .addCase(fetchContacts.fulfilled, (state, { payload }) => {
        state.contacts = payload;
        state.contactStatus = "idle";
      })
      .addCase(fetchContacts.rejected, (state, { payload }) => {
        state.contacts = [];
        state.contactStatus = "idle";
      })
      ///////// FETCH_FILE_INFOS /////////////////////////////////////////
      .addCase(fetchFileInfos.pending, (state) => {
        state.fileInfosStatus = "loading...";
      })
      .addCase(fetchFileInfos.fulfilled, (state, { payload }) => {
        state.fileInfos = payload?.filter(
          (fileInfo) => !state.status.filetypes.filter.includes(fileInfo.type)
        );
        state.fileInfosStatus = "idle";
      })
      .addCase(fetchFileInfos.rejected, (state, { payload }) => {
        state.fileInfos = [];
        state.fileInfosStatus = "idle";
      })
      ///////// FETCH_RELATED_ZONES /////////////////////////////////////////
      .addCase(fetchRelatedZones.pending, (state) => {
        state.relatedZonesStatus = "loading...";
      })
      .addCase(fetchRelatedZones.fulfilled, (state, { payload }) => {
        state.contactRole = payload?.contactRole;
        state.relatedZones = payload?.zones;
        state.relatedZonesStatus = "idle";
      })
      ///////// FETCH_CONTACT_STATUS /////////////////////////////////////////
      .addCase(fetchContactStatusFilter.pending, (state) => {
        state.status.contacts.status.filter = "loading...";
      })
      .addCase(fetchContactStatusFilter.fulfilled, (state, { payload }) => {
        state.status.contacts.filter = payload?.value ?? [];
        state.status.contacts.status.filter = "idle";
      })
      .addCase(fetchContactStatusOptions.pending, (state) => {
        state.status.contacts.status.all = "loading...";
      })
      .addCase(fetchContactStatusOptions.fulfilled, (state, { payload }) => {
        state.status.contacts.all = payload;
        state.status.contacts.status.all = "idle";
      })
      ///////// FETCH_FILETYPE ////////////////////////////////////////////////
      .addCase(fetchFiletypeFilter.pending, (state) => {
        state.status.filetypes.status.filter = "loading...";
      })
      .addCase(fetchFiletypeFilter.fulfilled, (state, { payload }) => {
        state.status.filetypes.filter = payload?.value ?? [];
        state.status.filetypes.status.filter = "idle";
      })
      .addCase(fetchFiletypeOptions.pending, (state) => {
        state.status.filetypes.status.all = "loading...";
        state.nonformattedFiletypes.status.all = "loading...";
      })
      .addCase(fetchFiletypeOptions.fulfilled, (state, { payload }) => {
        state.status.filetypes.all = [...payload, ""];
        state.status.filetypes.status.all = "idle";
        state.nonformattedFiletypes.all = payload;
        state.nonformattedFiletypes.status.all = "idle";
      })
      ///////// FETCH_PREFORMATTEDFILETYPES /////////////////////////////////////
      .addCase(fetchPreformattedFiletypeFilter.pending, (state) => {
        state.nonformattedFiletypes.status.filter = "loading...";
      })
      .addCase(
        fetchPreformattedFiletypeFilter.fulfilled,
        (state, { payload }) => {
          state.nonformattedFiletypes.filter = payload?.value ?? [];
          state.nonformattedFiletypes.status.filter = "idle";
        }
      )
      ///////// FETCH_DEFAULT_COUNTRY_CODE //////////////////////////////////////
      .addCase(fetchDefaultCountryCode.pending, (state) => {
        state.defaultCountryCode.status = "loading...";
      })
      .addCase(fetchDefaultCountryCode.fulfilled, (state, { payload }) => {
        state.defaultCountryCode.countryCode = payload?.value ?? "NO"; //TODO, Cleaner solution for handling non existing default in config table
        state.defaultCountryCode.status = "idle";
      })
      ///////// FETCH_SUBMISSION_STATUS /////////////////////////////////////////
      .addCase(fetchSubmissionStatusFilter.pending, (state) => {
        state.status.submissions.status.filter = "loading...";
      })
      .addCase(fetchSubmissionStatusFilter.fulfilled, (state, { payload }) => {
        state.status.submissions.filter = payload?.value ?? [];
        state.status.submissions.status.filter = "idle";
      })
      .addCase(fetchSubmissionStatusOptions.pending, (state) => {
        state.status.submissions.status.all = "loading...";
      })
      .addCase(fetchSubmissionStatusOptions.fulfilled, (state, { payload }) => {
        state.status.submissions.all = payload;
        state.status.submissions.status.all = "idle";
      })
      ///////// FETCH_DISREGARDED ///////////////////////////////////////////
      .addCase(fetchDisregarded.pending, (state) => {
        state.disregarded.status = "loading...";
      })
      .addCase(fetchDisregarded.fulfilled, (state, { payload }) => {
        const { contacts, fileInfos } = payload ?? {
          contacts: null,
          fileInfos: null,
        };
        state.disregarded.contacts = !!contacts ? contacts : [];
        state.disregarded.fileInfos = !!fileInfos ? fileInfos : [];
        state.disregarded.status = "idle";
      });
  },
  sagas: {
    //TODO, fix code duplication
    addFiles: async (
      { payload: { fileInfos, corporationId, zoneId, groupId, rootNodeId } },
      dispatch,
      getState
    ) => {
      const { token, entityType, nonformattedFiletypes } = getState();
      if (entityType === "Job Posting") {
        for (const { id, name, candidate, type } of fileInfos) {
          const url = new URL(
            `file/Candidate/${candidate.id}/${id}/raw`,
            token.restUrl
          );
          url.searchParams.set("BhRestToken", token.bhRestToken);
          const response = await fetch(url, { method: "GET" });
          if (!response.ok) {
            throw new Error("Could not upload file");
          }
          const blob = await response.blob();

          blob.name = nonformattedFiletypes.filter.includes(type)
            ? suffixName(name, candidate.firstName, candidate.lastName)
            : name;
          //blob.id = `bullhorn-fileInfo-${id}`;
          blob.lastModifiedData = new Date();
          blob.meta = { bhFileId: id, bhCandidateId: candidate.id };
          await dispatch(startUpload([blob], groupId, rootNodeId, zoneId));
        }
      } else if (entityType === "Client") {
        for (const { id, name } of fileInfos) {
          const url = new URL(
            `file/ClientCorporation/${corporationId}/${id}/raw`,
            token.restUrl
          );
          url.searchParams.set("BhRestToken", token.bhRestToken);
          const response = await fetch(url, { method: "GET" });
          if (!response.ok) {
            throw new Error("Could not upload file");
          }
          const blob = await response.blob();
          blob.name = name;
          //blob.id = `bullhorn-fileInfo-${id}`;
          blob.lastModifiedData = new Date();
          blob.meta = { bhFileId: id, bhCorporationId: corporationId };
          await dispatch(startUpload([blob], groupId, rootNodeId, zoneId));
        }
      }
    },
    removeFiles: async ({ payload: { files } }, dispatch) => {
      for (const file of files) {
        const { reason } = await dispatch(removeNode({ nodeId: file.id }));
        if (reason && reason !== "HANDLED") {
          throw new Error(reason);
        }
      }
    },
    addUsers: async ({ payload: { users, zoneId } }, dispatch) => {
      const accessToken = await dispatch(getToken());
      for (const user of users) {
        const { provider, wellknown, email, role, name } = user;
        const { userId, created } = await dispatch(
          createUser({ provider, wellknown, email, roleId: role, name })
        );
        if (created) {
          await sendInviteToUser(accessToken, userId);
        }
        const { reason } = await dispatch(addMember({ zoneId, userId }));
        if (reason && reason !== "HANDLED") {
          return reason;
          //   throw new Error(reason);
        }
      }
    },
    removeUsers: async ({ payload: { users, zoneId } }, dispatch) => {
      for (const user of users) {
        const { reason } = await dispatch(
          removeMember({ zoneId, userId: user.user })
        );
        if (reason && reason !== "HANDLED") {
          throw new Error(reason);
        }
      }
    },
  },
};

export const bullhornSlice = createSlice(blueprint);

export const bullhornSagas = blueprint.sagas;

export const checkBullhorn =
  (initialWindowLocationHref) => async (dispatch, getState) => {
    try {
      //const url = new URL(window.location.href);
      const url = new URL(initialWindowLocationHref);
      const searchParams = new URLSearchParams(url.search);
      const entityType = searchParams.get("EntityType");
      const entityID = searchParams.get("EntityID");
      if (url.pathname === "/") {
        if (entityType !== "Client" && entityType !== "Job Posting") {
          return;
        }
        const accessToken = await dispatch(getToken());
        //const referrer = new URL(window.location.href);
        const referrer = new URL(initialWindowLocationHref);
        referrer.pathname = "/bhtoken";
        const redirectURL = new URL(API_URL_PREFIX);
        redirectURL.pathname = "/bullhorn/start";
        redirectURL.searchParams.set("referrer", referrer.toString());
        redirectURL.searchParams.set("authorization", accessToken);
        while (!localStorage.id) {
          await new Promise((f) => setTimeout(f, 1000));
        }
        await dispatch(redirect(redirectURL.toString()));
        return;
      }

      if (url.pathname === "/bhtoken") {
        if (entityType === "Client" || entityType === "Job Posting") {
          dispatch(setBullhornHideAddZoneButton(entityType === "Client"));
          dispatch(setBullhornHideMenu(entityType === "Job Posting"));
          await dispatch(setEntityId(entityID));
          await dispatch(setEntityType(entityType));
          if (entityType === "Job Posting") {
            await dispatch(setJobOrderId(entityID));
          }
          const corporationId = searchParams.get("corporationId");
          const token = JSON.parse(b64Url2Utf8(url.hash.substring(1)));
          const accessToken = await dispatch(getToken());
          await Promise.all([
            dispatch(
              fetchRelatedZones({
                clientCorporationId: corporationId,
                accessToken,
              })
            ),
            dispatch(setZoneName(searchParams.get("corporationName"))),
            dispatch(setBhToken(token)),
            dispatch(setCorporationId(corporationId)),
          ]);
          await Promise.all([
            dispatch(fetchFiletypeOptions(token)),
            dispatch(fetchSubmissionStatusOptions(token)),
            dispatch(fetchContactStatusOptions(token)),
            dispatch(fetchContactStatusFilter({ accessToken })),
            dispatch(fetchFiletypeFilter({ accessToken })),
            dispatch(fetchSubmissionStatusFilter({ accessToken })),
            dispatch(fetchPreformattedFiletypeFilter({ accessToken })),
            dispatch(fetchDefaultCountryCode({ accessToken })),
          ]).catch((error) => console.error(error));
          dispatch(fetchContacts());
          dispatch(fetchFileInfos());
          dispatch(setBullhornReady(true));
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

export const displayZone =
  (zoneId, deep = false) =>
  async (dispatch) => {
    await dispatch(ensureZone(zoneId, deep));
    dispatch(setCurrentZone(zoneId));
    dispatch(moveZoneFirst(zoneId));
  };

export const ensureZone =
  (zoneId, deep = false) =>
  async (dispatch, getState) => {
    if (!(zoneId in getState().appstate.graph.zones)) {
      await dispatch(loadZone({ id: zoneId, deep }));
    }
  };

const fetchConfig = (dbName, accessToken) => {
  const url = `${API_URL_PREFIX}/config/${dbName}`;
  const { user: userId } = jwtPeek(accessToken);
  return request(
    url,
    get({
      accessToken,
      userId,
    })
  ).catch((err) => null);
};

export const postConfig = async (dbName, value, accessToken) => {
  const url = `${API_URL_PREFIX}/config`;
  const { user: userId } = jwtPeek(accessToken);
  return await request(
    url,
    json(
      {
        name: dbName,
        value,
      },
      {
        accessToken,
        userId,
      }
    )
  );
};

export const postZoneToClientCorporation = async (
  clientCorporationId,
  zoneId,
  accessToken,
  jobOrderId
) => {
  const url = `${API_URL_PREFIX}/zonesOfBullhornClientCorporation`;
  const { user: userId } = jwtPeek(accessToken);
  return await request(
    url,
    json(
      {
        clientCorporationId,
        zoneId,
        jobOrderId,
      },
      {
        accessToken,
        userId,
      }
    )
  );
};

// --------------------------------------------------------------------
// -------------------- START OF CONFIG FETCHERS ----------------------
// --------------------------------------------------------------------

export const fetchContactStatusFilter = createAsyncThunk(
  "bullhorn/fetchContactStatusFilter",
  async ({ accessToken }, { getState }) => {
    return fetchConfig(getState().bullhorn.status.contacts.dbName, accessToken);
  }
);
export const fetchFiletypeFilter = createAsyncThunk(
  "bullhorn/fetchFiletypeFilter",
  async ({ accessToken }, { getState }) => {
    return fetchConfig(
      getState().bullhorn.status.filetypes.dbName,
      accessToken
    );
  }
);

export const fetchPreformattedFiletypeFilter = createAsyncThunk(
  "bullhorn/fetchPreformattedFiletypeFilter",
  async ({ accessToken }, { getState }) => {
    return fetchConfig(
      getState().bullhorn.nonformattedFiletypes.dbName,
      accessToken
    );
  }
);

export const fetchSubmissionStatusFilter = createAsyncThunk(
  "bullhorn/fetchSubmissionStatusFilter",
  async ({ accessToken }, { getState }) => {
    return fetchConfig(
      getState().bullhorn.status.submissions.dbName,
      accessToken
    );
  }
);

export const fetchDefaultCountryCode = createAsyncThunk(
  "bullhorn/fetchDefaultCountryCode",
  async ({ accessToken }, { getState }) => {
    return fetchConfig(
      getState().bullhorn.defaultCountryCode.dbName,
      accessToken
    );
  }
);

// --------------------------------------------------------------------
// ---------------------- END OF CONFIG FETCHERS ----------------------
// --------------------------------------------------------------------

export const postDisregarded = async (zoneId, disregarded, accessToken) => {
  const url = `${API_URL_PREFIX}/disregarded/${zoneId}`;
  const { user: userId } = jwtPeek(accessToken);
  return await request(
    url,
    json(
      {
        ...disregarded,
      },
      {
        accessToken,
        userId,
      }
    )
  );
};

export const fetchDisregarded = createAsyncThunk(
  "bullhorn/fetchDisregarded",
  async ({ zoneId, accessToken }) => {
    const { user: userId } = jwtPeek(accessToken);
    const url = `${API_URL_PREFIX}/disregarded/${zoneId}`;
    return await request(
      url,
      get({
        accessToken,
        userId,
      })
    ).catch((err) => null);
  }
);

export const fetchRelatedZones = createAsyncThunk(
  "bullhorn/fetchRelatedZones",
  async (props) => {
    const { clientCorporationId, accessToken } = props;
    const url = `${API_URL_PREFIX}/zonesOfBullhornClientCorporation/${clientCorporationId}`;
    const { user: userId } = jwtPeek(accessToken);
    return await request(
      url,
      get({
        accessToken,
        userId,
      })
    );
  }
);

//TODO, fill method with logic and calls to correct settings once we know which to call
export const fetchContactStatusOptions = createAsyncThunk(
  "bullhorn/fetchContactStatusAll",
  async ({ restUrl, bhRestToken }, { getState }) =>
    getBullhornFieldOptions(
      "ClientContact",
      ["status"],
      bhRestToken,
      restUrl
    ).then((fields) => {
      const statuses = fields.find((field) => field.name === "status");
      return statuses.options.map((option) => option.value);
    })
);

export const fetchFiletypeOptions = createAsyncThunk(
  "bullhorn/fetchFiletypeAll",
  async ({ restUrl, bhRestToken }) =>
    getBullhornFieldOptions(
      "CandidateFileAttachment",
      ["type"],
      bhRestToken,
      restUrl
    ).then((fields) =>
      fields
        .find((field) => field.name === "type")
        .options.map((option) => option.value)
    )
);

export const fetchSubmissionStatusOptions = createAsyncThunk(
  "bullhorn/fetchSumbissionStatusAll",
  async ({ restUrl, bhRestToken }) => {
    return getBullhornSettings(
      ["jobResponseStatusList", "submissionPlacedStatus"],
      bhRestToken,
      restUrl
    ).then((resp) =>
      Object.values(resp).flat().concat(["New Lead", "Sendout"])
    );
  }
);

export const fetchContacts = createAsyncThunk(
  "bullhorn/fetchContacts",
  async (props, { getState }) => {
    const { restUrl, bhRestToken } = selectBullhornToken(getState());
    const entityType = selectEntityType(getState());
    const id = selectCorporationId(getState());
    const statusFilter = getState().bullhorn.status.contacts.filter;
    const statusSubQuery = !!statusFilter.length
      ? statusFilter.map((status) => `-status:"${status}"`).join(" ") + " AND "
      : "";
    const query = `${statusSubQuery}isDeleted:false AND clientCorporation.id:${id}`;
    const clientCorpContacts = searchBullhornEntity(
      "ClientContact",
      ["name", "email", "id", "status", "mobile", "phone"],
      query,
      bhRestToken,
      restUrl
    );
    if (entityType === "Client") {
      return clientCorpContacts;
    } else if (entityType === "Job Posting") {
      const jobOrderId = selectEntityId(getState());
      return clientCorpContacts.then((clientCorpContacts) =>
        getBullhornEntity(
          "JobOrder",
          jobOrderId,
          ["clientContact"],
          bhRestToken,
          restUrl
        ).then((jobOrder) =>
          clientCorpContacts.map((clientCorpContact) => {
            return {
              isJobOrderContact:
                clientCorpContact.id === jobOrder.clientContact.id,
              ...clientCorpContact,
            };
          })
        )
      );
    }
  }
);

export const fetchFileInfos = createAsyncThunk(
  "bullhorn/fetchFileInfos",
  async (props, { getState }) => {
    const id = selectEntityId(getState());
    const { restUrl, bhRestToken } = selectBullhornToken(getState());
    const entityType = selectEntityType(getState());
    if (entityType === "Client") {
      return getBullhornEntityFiles(
        "ClientCorporation",
        [id],
        ["id", "name"],
        bhRestToken,
        restUrl
      );
    } else if (entityType === "Job Posting") {
      return getBullhornEntity(
        "JobOrder",
        id,
        ["submissions[500]"],
        bhRestToken,
        restUrl
      )
        .then((response) =>
          response.submissions.data.map((submission) => submission.id)
        )
        .then((submissionIds) => {
          const statusFilter = getState().bullhorn.status.submissions.filter;
          const statusSubQuery = statusFilter.length
            ? ` AND (status NOT IN (${statusFilter
                .map((status) => `'${status}'`)
                .join(",")}))`
            : "";
          return submissionIds.length
            ? queryBullhornEntity(
                "JobSubmission",
                ["candidate"],
                bhRestToken,
                restUrl,
                `id IN (${submissionIds.join(",")})${statusSubQuery}`
              )
            : Promise.reject("no submissions");
        })
        .then((candidates) => candidates.map(({ candidate }) => candidate.id))
        .then((candidateIds) => {
          if (candidateIds.length === 0) {
            throw new Error("candidate list is empty when fetching fileInfos");
          }
          return getBullhornEntityFiles(
            "Candidate",
            candidateIds,
            ["id", "fileSize", "dateAdded", "name", "candidate", "type"],
            bhRestToken,
            restUrl
          );
        })
        .then((fileInfos) =>
          fileInfos.map((fileInfo) => ({
            ...fileInfo,
            type: fileInfo.type ?? "",
          }))
        );
    }
  }
);

// --------------------------------------------------------------------
// -------------- START OF BULLHORN API HELP METHODS ------------------
// --------------------------------------------------------------------

//type: string, fields: string[], query: string
export const searchBullhornEntity = (
  type,
  fields,
  query,
  bhRestToken,
  restUrl
) => {
  const url = new URL(`search/${type}`, restUrl);
  url.searchParams.set("query", query);
  return addGenericParamsAndFetch(url, fields, bhRestToken);
};

export const getBullhornSettings = (settings, bhRestToken, restUrl) => {
  const url = new URL(`settings/${settings.join(",")}`, restUrl);
  url.searchParams.set("BhRestToken", bhRestToken);
  return fetch(url, { method: "GET", headers: { Accept: "application/json" } })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`bad response status code ${response.status}: ${url}`);
      }
      return response;
    })
    .then((response) => response.json());
};

export const getBullhornEntityFiles = (
  type,
  ids,
  fields,
  bhRestToken,
  restUrl
) => {
  const url = new URL(
    `entity/${type}/${ids.join(",")}/fileAttachments`,
    restUrl
  );
  return addGenericParamsAndFetch(url, fields, bhRestToken);
};

export const getBullhornEntity = (type, id, fields, bhRestToken, restUrl) => {
  const url = new URL(`entity/${type}/${id}`, restUrl);
  return addGenericParamsAndFetch(url, fields, bhRestToken);
};

export const getBullhornEntityMeta = (type, fields, bhRestToken, restUrl) => {
  const url = new URL(`meta/${type}`, restUrl);
  url.searchParams.set("fields", fields.length ? fields.join(",") : "*");
  url.searchParams.set("BhRestToken", bhRestToken);
  return fetch(url, { method: "GET", headers: { Accept: "application/json" } })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`bad response status code ${response.status}: ${url}`);
      }
      return response;
    })
    .then((response) => response.json());
};

export const getBullhornFieldOptions = (type, fields, bhRestToken, restUrl) =>
  getBullhornEntityMeta(type, fields, bhRestToken, restUrl).then(
    (res) => res.fields
  );

export const queryBullhornEntity = (
  type,
  fields,
  bhRestToken,
  restUrl,
  where
) => {
  const url = new URL(`query/${type}`, restUrl);
  url.searchParams.set("where", where);
  return addGenericParamsAndFetch(url, fields, bhRestToken);
};

export const getBullhornSetting = (type, bhRestToken, restUrl) => {
  const url = new URL(`settings/${type}`, restUrl);
  url.searchParams.set("BhRestToken", bhRestToken);

  return fetch(url, {
    method: "GET",
    headers: { Accept: "application/json" },
  }).then((response) => response.json());
};

export const getBullhornOption = (type, bhRestToken, restUrl) => {
  const url = new URL(`options/${type}`, restUrl);
  url.searchParams.set("BhRestToken", bhRestToken);

  return fetch(url, {
    method: "GET",
    headers: { Accept: "application/json" },
  }).then((response) => response.json());
};

export const addGenericParamsAndFetch = (url, fields, bhRestToken) => {
  url.searchParams.set("fields", fields.length ? fields.join(",") : "*");
  url.searchParams.set("BhRestToken", bhRestToken);
  url.searchParams.set("count", "500");
  return fetch(url, { method: "GET", headers: { Accept: "application/json" } })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`bad response status code ${response.status}: ${url}`);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => response.data)
    .then((data) => {
      if (typeof data === "undefined") {
        throw new Error(`response did not contain data: ${url}`);
      }
      return data;
    });
};
// --------------------------------------------------------------------
// --------------- END OF BULLHORN API HELP METHODS -------------------
// --------------------------------------------------------------------

export const {
  setBhToken,
  setCorporationId,
  setRelatedZonesExist,
  setZoneId,
  setZoneName,
  setEntityType,
  setEntityId,
  setJobOrderId,
  setStatus,
  setShowAllFileInfos,
  setDefaultCountryCode,
  setSyncFilesOnly,
  setRunFlow,
  setHasPermissions,
  setHasDialogsToShow,
  setNonformattedFiletypes,
  setDisregarded,
} = bullhornSlice.actions;

export const selectContacts = (state) => state.bullhorn?.contacts;

export const selectContactsStatus = (state) => state.bullhorn?.contactStatus;

export const selectBullhornToken = (state) => state.bullhorn?.token;

export const selectCorporationId = (state) => state.bullhorn?.corporationId;

export const selectFileInfos = (state) => state.bullhorn?.fileInfos;

export const selectFileInfosStatus = (state) => state.bullhorn?.fileInfosStatus;

export const selectRelatedZonesExist = (state) =>
  state.bullhorn?.relatedZonesExist;

export const selectZoneName = (state) => state.bullhorn?.zoneName;

export const selectZoneId = (state) => state.bullhorn?.zoneId;

export const selectRelatedZones = (state) => state.bullhorn?.relatedZones;

export const selectRelatedZonesStatus = (state) =>
  state.bullhorn?.relatedZonesStatus;

export const selectContactRole = (state) => state.bullhorn?.contactRole;

export const selectEntityType = (state) => state.bullhorn?.entityType;

export const selectEntityId = (state) => state.bullhorn?.entityId;

export const selectJobOrderId = (state) => state.bullhorn?.jobOrderId;

export const selectStatus = (state) => state.bullhorn?.status;

export const selectShowAllFileInfos = (state) =>
  state.bullhorn?.showAllFileInfos;
export const selectDefaultCountryCode = (state) =>
  state.bullhorn?.defaultCountryCode;

export const selectSyncFilesOnly = (state) => state.bullhorn?.syncFilesOnly;

export const selectRunFlow = (state) => state.bullhorn?.runFlow;

export const selectHasPermissions = (state) => state.bullhorn?.hasPermissions;

export const selectHasDialogsToShow = (state) =>
  state.bullhorn?.hasDialogsToShow;

export const selectNonformattedFiletypes = (state) =>
  state.bullhorn?.nonformattedFiletypes;

export const selectDisregarded = (state) => state.bullhorn?.disregarded;
export const selectDisregardedStatus = (state) =>
  state.bullhorn?.disregarded.status;

//TODO, rewrite so that transformerFn is not needed. It is used for grouping (SHOW GROUP) atm,
//but this decreases readability. Good for now.
export const useFilesDiff = (transformerFn) => {
  const zoneId = useSelector(selectZoneId);
  const zoneName = useSelector(selectZoneName);
  const graph = useSelector(selectGraph);
  const nodes = getNodesFromZone(graph, zoneId);
  const fileInfos = useSelector(selectFileInfos);
  const fileInfosStatus = useSelector(selectFileInfosStatus);
  const {
    fileInfos: disregardedFileInfos,
    status: disregardedStatus,
    active,
  } = useSelector(selectDisregarded);

  const ready =
    zoneId &&
    Object.keys(graph.groups).length &&
    fileInfosStatus === "idle" &&
    disregardedStatus === "idle";

  const added = (!!transformerFn ? transformerFn(fileInfos) : fileInfos).filter(
    (fileInfo) =>
      //!nodes.some((node) => node.id === `bullhorn-fileInfo-${fileInfo.id}`) &&
      !nodes.some((node) => node.meta?.bhFileId === fileInfo.id) &&
      (active
        ? !disregardedFileInfos.some(
            (disregardedFileInfoId) => disregardedFileInfoId === fileInfo.id
          )
        : true)
  );

  const removed = nodes.filter(
    (node) =>
      node.name !== zoneName &&
      !fileInfos.some(
        //(fileInfo) => `bullhorn-fileInfo-${fileInfo.id}` === node.id
        (fileInfo) => node.meta?.bhFileId === fileInfo.id
      )
  );
  return { ready, added, removed };
};

const suffixName = (fullName, firstName, lastName) => {
  const fileName = fullName.substring(0, fullName.lastIndexOf("."));
  const extension = fullName.substring(
    fullName.lastIndexOf(".") + 1,
    fullName.length
  );
  return `${fileName}-${firstName}_${lastName}.${extension}`;
};

export const useUsersDiff = () => {
  const graph = useSelector(selectGraph);
  const contacts = useSelector(selectContacts);
  const contactsStatus = useSelector(selectContactsStatus);
  const zoneId = useSelector(selectZoneId);
  const users = getUsersFromCurrentZoneRootGroup(graph);
  const currentUser = useSelector(selectIdentity);
  const defaultCountryCode = useSelector(selectDefaultCountryCode);
  const contactRole = useSelector(selectContactRole);
  const {
    contacts: disregardedContacts,
    status: disregardedStatus,
    active,
  } = useSelector(selectDisregarded);

  const ready =
    zoneId &&
    currentUser?.id &&
    users.length !== 0 &&
    Object.keys(graph.groups).length !== 0 &&
    contactsStatus === "idle" &&
    contactRole !== undefined &&
    disregardedStatus === "idle";

  const added = contacts
    .filter(
      (contact) =>
        !users.some((zoneUser) => zoneUser.email === contact.email) &&
        (contact.mobile || contact.phone || contact.email) &&
        (active
          ? !disregardedContacts.some(
              (disregardedContactEmail) =>
                contact.email === disregardedContactEmail
            )
          : true)
    )
    .map((contact, index) =>
      makeUserFromContact(
        contact,
        index,
        contactRole,
        defaultCountryCode.countryCode
      )
    );
  const removed = users
    .filter((user) => user.role === contactRole)
    .filter(
      (zoneUser) =>
        zoneUser.user !== currentUser?.id &&
        !contacts.some((contact) => contact.email === zoneUser.email)
    );
  return { ready, added, removed };
};

const makeUserFromContact = (contact, index, role, defaultCountryCode) => {
  const validEmail = validateEmail(contact.email ?? "");
  const rawPhoneNr = contact.mobile || contact.phone || "";
  let phoneNr;
  if (isValidPhoneNumber(rawPhoneNr)) {
    phoneNr = parsePhoneNumber(rawPhoneNr);
  } else {
    try {
      phoneNr = parsePhoneNumber(rawPhoneNr, defaultCountryCode);
    } catch (e) {
      phoneNr = { formatInternational: () => rawPhoneNr, isValid: () => false };
    }
  }
  const validPhoneNr = phoneNr.isValid();

  const valid2fa = validPhoneNr && validEmail;
  return {
    index,
    isJobOrderContact: contact.isJobOrderContact ?? false,
    id: contact.id,
    user: contact.id,
    validEmail,
    validPhoneNr,
    valid2fa,
    phoneNr: contact.mobile || contact.phone || "",
    deprecatedId: `2fa:${contact.email ?? ""}|${rawPhoneNr}`,
    provider: "2fa",
    wellknown: [contact.email ?? "", phoneNr.formatInternational()].map((s) =>
      s.replace(/\s/g, "")
    ),
    email: contact.email ?? "",
    role,
    name: contact.name,
    isContact: true,
  };
};

export const groupFileInfos = (fileInfos) => {
  const map = {};

  for (const fileInfo of fileInfos) {
    const { type, candidate } = fileInfo;
    if (!(candidate.id in map)) {
      map[candidate.id] = [];
    }
    if (!(type in map[candidate.id])) {
      map[candidate.id][type] = [];
    }
    map[candidate.id][type].push(fileInfo);
  }

  const result = [];

  for (const byFileType of Object.values(map)) {
    for (const fileInfos of Object.values(byFileType)) {
      let mostRecent = null;
      for (const fileInfo of fileInfos) {
        if (!mostRecent || mostRecent.dateAdded < fileInfo.dateAdded) {
          mostRecent = fileInfo;
        }
      }
      result.push(mostRecent);
    }
  }

  return result;
};

export const { addFiles, removeFiles, addUsers, removeUsers } =
  createSagaActions(blueprint);

export default bullhornSlice.reducer;
