import React, { useState } from "react";
import { useSelector } from "react-redux";

import { appState } from "../../app/appState.js";

const { derive: { getZones }, selectors: { selectGraph } } = appState;

export default function TeamSelect({ zoneId, setZoneId }) {
  const zones = getZones(useSelector(selectGraph));

  return (
    <div>
      <label htmlFor="create-team">Create from:</label>
      <select
        value={zoneId || ""}
        onChange={event =>
          setZoneId(event.target.value === "none" ? null : event.target.value)
        }
        name="create for"
        id="create-team"
      >
        <option value="none">Blank (Empty)</option>
        {zones.map(z => (
          <option key={"team-invite-pick-" + z.id} value={z.id}>
            {z.name}
          </option>
        ))}
      </select>
    </div>
  );
}
