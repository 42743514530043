import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@blueprintjs/core";
import { classNames } from "utils";
import * as config from "../../config";
import { selectIdentity, getToken } from "../../app/topSlice.js";
import { appState } from "../../app/appState.js";
import { approve, setDialog } from "../../app/appSlice.js";
import { wrapNonSerializableValue } from "../../helpers/nonSerializableValues/index.js";
import { NO_ORDER, NOT_AVAILABLE, NOT_SIGNED } from "./Overlays.js";
import { formatSocialSecurityNumberClearText } from "../format/format.js";
import {
  normalizeNode,
  getEsignatureState,
  esignatureReject,
  formatISOTimeStamp,
} from "../esignature/esignatureSlice.js";
import EsignatureIcon from "../esignature/esignatureIcon.js";

import {
  ESIGNATURE as DIALOG_ESIGNATURE,
  ESIGNATURE_SIGN as DIALOG_ESIGNATURE_SIGN,
} from "../dialogs/Dialogs.js";

import styles from "./Widget.module.css";

const {
  selectors: { selectGraph },
} = appState;

export default ({
  zoneId,
  node,
  blob,
  canRequest,
  canRespond,
  alreadyPackaged,
  pdfNotSupported,
  onDownloadOriginal,
  onClose,
  goToLastPage,
  iDidJustSign,
}) => {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);

  node = normalizeNode(node);

  const esignatureRequest = node.esignature;
  const esignatureState = dispatch(getEsignatureState(esignatureRequest));

  if (!identity) {
    return null;
  }

  const userId = identity.id;

  let {
    notYetStarted,
    signedSealedDelivered,
    isFinished,
    wasApproved,
    wasRejected,
    wasCanceled,
    didExpire,
    readyToSign,
    isOrdered,
    queue,
    signatures,
    stateText,
    color,
    icon,
    submitted,
    deadline,
  } = esignatureState;

  if (readyToSign && iDidJustSign) {
    readyToSign = false;
  }

  const onStart = () => {
    dispatch(
      setDialog({
        dialog: DIALOG_ESIGNATURE,
        blob: wrapNonSerializableValue(blob),
        node: node,
      })
    );
  };

  const onApprove = async () => {
    const token = await dispatch(getToken());
    const referrer = config.API_URL_AUTHORITY;
    const url = `${config.API_URL_PREFIX}/esignature/launch?id=${esignatureRequest.id}&referrer=${referrer}&token=${token}`;
    window.open(url, "_self");
  };

  const onReject = async () => {
    const text = [
      "Are you sure?",
      "The sign order will be canceled for all users.",
    ];
    const { approved } = await dispatch(approve(text));
    if (approved) {
      dispatch(esignatureReject(esignatureRequest));
    }
  };

  //const onFinalize = () => {
  //  dispatch(
  //    finalizeSignatureRequest({
  //      esignatureRequest,
  //      blob,
  //      node,
  //      zoneId,
  //      //zoneId,
  //      //groupId,
  //      //rootNodeId,
  //      //node,
  //      //org,
  //      //completedSignatures
  //    })
  //  );
  //};

  //const onDownload = () => {
  //  dispatch(
  //    downloadSignedDocument({
  //      esignatureRequest,
  //      blob,
  //      node,
  //      zoneId,
  //    })
  //  );
  //};

  //const onDownloadOriginal = () => {
  //  console.log("download original!")
  //  //dispatch(
  //  //  downloadSignedDocument({
  //  //    esignatureRequest,
  //  //    blob,
  //  //    node,
  //  //    zoneId,
  //  //  })
  //  //);
  //};

  if (notYetStarted) {
    let what;
    if (!canRequest) {
      what = (
        <CannotRequest
          text={"This document has no signatures."}
          elementStyle={classNames(styles.status, styles.orange)}
        />
      );
    } else if (pdfNotSupported) {
      what = <NotSupported />;
    } else if (alreadyPackaged) {
      what = <Already />;
    } else {
      what = <Start onClick={onStart} />;
    }
    return (
      <div className={styles.sidekick}>
        <Header onClose={onClose} />
        {what}
      </div>
    );
  }

  //goToLastPage = () => {
  //  const preBlob = wrapNonSerializableValue(blob);
  //  dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, node, blob: preBlob }));
  //}

  let status;
  if (wasRejected) {
    status = <Rejected text={stateText} color={color} icon={icon} />;
  } else if (wasCanceled) {
    status = <Canceled text={stateText} color={color} icon={icon} />;
  } else if (didExpire && !signedSealedDelivered) {
    status = <Expired text={stateText} color={color} icon={icon} />;
  } else if (signedSealedDelivered || isFinished) {
    status = (
      <Completed
        text={stateText}
        icon={icon}
        goToLastPage={goToLastPage}
        onDownloadOriginal={onDownloadOriginal}
      />
    );
  } else if (readyToSign) {
    status = <Sign onApprove={onApprove} onReject={onReject} />;
  } else {
    status = (
      <Waiting
        text={stateText}
        elementStyle={classNames(styles.status, styles.orange)}
        icon={icon}
      />
    );
  }

  return (
    <div className={styles.sidekick}>
      <Header onClose={onClose} />
      {status}
      <div className={styles.verticalSpace} />
      <div className={styles.verticalSpace} />
      <div className={styles.verticalSpace} />
      <Queue
        queue={queue}
        ordered={isOrdered}
        signatures={signatures}
        signedSealedDelivered={signedSealedDelivered}
        submitted={submitted}
        deadline={deadline}
      />
    </div>
  );
};

export const Header = ({ onClose }) => {
  return (
    <div className={styles.header}>
      {/*
      <EsignatureIcon />
      <Icon icon="cross" onClick={onClose} />
      */}
    </div>
  );
};

export const Start = ({ onClick }) => {
  return (
    <div className={classNames(styles.block, styles.center)}>
      <button
        className={classNames(styles.button, styles.pill, styles.yellow)}
        onClick={onClick}
      >
        <EsignatureIcon />
        <span>Request signature</span>
      </button>
    </div>
  );
};

export const Sign = ({ onApprove, onReject }) => {
  return (
    <div className={styles.block}>
      <div className={classNames(styles.label, styles.ready)}>
        Ready to sign:
      </div>
      <div className={classNames(styles.columns, styles.sign)}>
        <button
          className={classNames(styles.button, styles.pill, styles.red)}
          onClick={onReject}
        >
          <Icon icon="delete" />
          <span>Reject</span>
        </button>
        <button
          className={classNames(styles.button, styles.pill, styles.green)}
          onClick={onApprove}
        >
          <EsignatureIcon />
          <span>Sign</span>
        </button>
      </div>
    </div>
  );
};

export const Completed = ({ text, icon, goToLastPage, onDownloadOriginal }) => {
  return (
    <div className={classNames(styles.block, styles.center)}>
      <button
        className={classNames(styles.button, styles.pill, styles.yellow)}
        onClick={goToLastPage}
        style={{ width: "166px" }}
      >
        <EsignatureIcon />
        <span>Show signatures</span>
      </button>
      <div style={{ height: "20px" }} />
      <button
        className={classNames(styles.button, styles.pill, styles.yellow)}
        onClick={onDownloadOriginal}
        style={{ width: "166px" }}
      >
        <Icon icon="download" />
        <span>Download original</span>
      </button>
    </div>
  );
};

export const Rejected = ({ text, icon }) => {
  return (
    <div className={classNames(styles.status, styles.red)}>
      <Icon icon={icon} />
      {text}
    </div>
  );
};

export const Canceled = ({ text, color, icon }) => {
  return (
    <div className={classNames(styles.status, styles.red)}>
      <Icon icon={icon} />
      {text}
    </div>
  );
};

export const Expired = ({ text, color, icon }) => {
  return (
    <div className={classNames(styles.status, styles.red)}>
      <Icon icon={icon} />
      {text}
    </div>
  );
};

export const Waiting = ({ text, elementStyle, icon }) => {
  return (
    <div className={elementStyle}>
      <Icon icon={icon} />
      {text}
    </div>
  );
};

export const Already = () => {
  return (
    <div className={classNames(styles.status, styles.orange)}>
      <Icon icon="info-sign" />
      This document is already signed.
    </div>
  );
};

export const NotSupported = () => {
  return (
    <div className={classNames(styles.status, styles.orange)}>
      <Icon icon="info-sign" />
      This document cannot be signed.
    </div>
  );
};

export const CannotRequest = ({ text, elementStyle }) => {
  return (
    <div className={elementStyle}>
      <Icon icon="info-sign" />
      {text}
    </div>
  );
};

export const Queue = ({
  queue,
  ordered,
  signatures,
  signedSealedDelivered,
  submitted,
  deadline,
}) => {
  const expire = formatISOTimeStamp(deadline);
  queue = queue.map((item, index) => {
    const user = signatures.find(
      (signer) => signer.externalSignerId == item.userId
    );

    item.signedTime = "";
    item.fullName = NOT_SIGNED;
    item.header = item.name;

    const {
      organizationInfo: { companyName, orgNo },
    } = item;

    if (user) {
      const { fullName, signatureMethodUniqueId, signedTime } = user;
      item.fullName = `BankID ${fullName}`;
      item.socialSecurityNumber = signatureMethodUniqueId;
      item.signedTime = signedTime;
    }

    const SSN =
      item.socialSecurityNumber !== ""
        ? formatSocialSecurityNumberClearText(item.socialSecurityNumber)
        : NOT_AVAILABLE;

    const rejected = item.rejected && item.responded;
    const statusIcon = rejected
      ? "ban-circle"
      : item.responded
      ? "tick-circle"
      : "user";

    return (
      <Signature
        key={index}
        email={item.email}
        lastName={item.name}
        fullName={item.fullName}
        SSN={SSN}
        signedTime={item.signedTime}
        companyName={companyName}
        orgNo={orgNo}
        order={ordered}
        queueNumber={item.order}
        expire={expire}
        icon={statusIcon}
        header={item.header}
      />
    );
  });

  const signers = signatures.map((signer, index) => {
    const { fullName, signatureMethodUniqueId, signedTime, externalSignerId } =
      signer;

    const {
      order,
      signerInfo: {
        email,
        lastName,
        organizationInfo: { companyName, orgNo },
      },
    } = submitted.find((user) => user.externalSignerId == externalSignerId);

    const SSN = formatSocialSecurityNumberClearText(signatureMethodUniqueId);
    const statusIcon = "tick-circle";

    return (
      <Signature
        key={index}
        email={email}
        lastName={lastName}
        fullName={`BankID ${fullName}`}
        SSN={SSN}
        signedTime={signedTime}
        companyName={companyName}
        orgNo={orgNo}
        order={ordered}
        queueNumber={order}
        expire={expire}
        icon={statusIcon}
        header={fullName}
      />
    );
  });

  if (signedSealedDelivered) {
    queue = signers;
  }

  return (
    <div className={classNames(styles.block, styles.queue)}>
      <div className={classNames(styles.label, styles.names)}>
        {signedSealedDelivered ? "Signed by parties" : "Parties to sign:"}
      </div>
      <div className={styles.verticalSpace} />
      <div className={classNames(styles.list, ordered && styles.ordered)}>
        {queue}
      </div>
    </div>
  );
};

const Signature = ({
  email,
  lastName,
  fullName,
  SSN,
  signedTime,
  companyName,
  orgNo,
  queueNumber,
  order,
  expire,
  icon,
  header,
}) => {
  const [openSignature, setOpenSignature] = useState(false);

  const timestamp =
    signedTime === "" ? NOT_SIGNED : formatISOTimeStamp(signedTime);

  const signingOrder = order ? queueNumber : NO_ORDER;

  const organizationNumber = orgNo ? orgNo : NOT_AVAILABLE;

  return (
    <div
      className={classNames(
        styles.dropdown,
        openSignature && styles.dropdownOpen
      )}
    >
      <div onClick={() => setOpenSignature(!openSignature)}>
        <Icon icon={icon} className={styles.icon} />
        <span>
          {companyName.replace(/\s/g, "") == "" ? header : companyName}
        </span>
        <Icon icon={"chevron-down"} />
      </div>
      {openSignature && (
        <div className={styles.info}>
          <div>{email}</div>
          <div>Email address </div>
          <div>{lastName}</div>
          <div>Contact person</div>
          <div>{fullName}</div>
          <div>Signature</div>
          <div>{SSN}</div>
          <div>Social security number</div>
          <div>{organizationNumber}</div>
          <div>Organization number</div>
          <div>{timestamp}</div>
          <div>Date and time</div>
          <div>{signingOrder}</div>
          <div>Signing order</div>
          <div>{expire}</div>
          <div>Expiry date</div>
        </div>
      )}
    </div>
  );
};
