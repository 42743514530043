import { Checkbox, Label } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import styles from "../BhDialogs.module.css";
import {
  selectNonformattedFiletypes,
  setNonformattedFiletypes,
} from "../bullhornSlice.js";

export default ({ dispatch }) => {
  const nonformattedFiletypes = useSelector(selectNonformattedFiletypes);

  const handleCheckedNonformattedFiletypes = (clickedOption) => {
    let newFilter;
    if (nonformattedFiletypes.filter.includes(clickedOption)) {
      newFilter = nonformattedFiletypes.filter.filter(
        (option) => option !== clickedOption
      );
    } else {
      newFilter = [...nonformattedFiletypes.filter, clickedOption];
    }
    dispatch(setNonformattedFiletypes(newFilter));
  };

  return (
    <>
      <Label className={styles.dialogHeading}>Preformatted Filetypes</Label>
      {nonformattedFiletypes.status.filter === "idle" &&
      nonformattedFiletypes.status.all === "idle" ? (
        nonformattedFiletypes.all.map((option) => (
          <Checkbox
            key={option}
            inline={true}
            checked={!nonformattedFiletypes.filter.includes(option)}
            label={option}
            onClick={(e) => handleCheckedNonformattedFiletypes(option)}
          />
        ))
      ) : (
        <p>loading...</p>
      )}
    </>
  );
};
