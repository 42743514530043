import { Dialog, Intent } from "@blueprintjs/core";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import appContext from "../../app/appContext.js";
import { selectIdentity, getToken } from "../../app/topSlice.js";
import { AddFile } from "../login/icons.tsx";
import { dispatchToast } from "../toasts/Toasts.js";
import styles from "./BhDialogs.module.css";
import BhSyncFiles from "./BhSyncFiles";
import {
  addFiles,
  groupFileInfos,
  postDisregarded,
  selectCorporationId,
  selectFileInfosStatus,
  selectShowAllFileInfos,
  setDisregarded,
  useFilesDiff,
} from "./bullhornSlice.js";

const ADDING_FILES = "Adding File(s).";
const FILES_ADDED = "File(s) Added.";
const ADD = "Add";

export default function SyncAddedFiles({ dispatch, active, close, stepNr }) {
  const { zoneId, groupId, rootNodeId } = useContext(appContext);
  const corporationId = useSelector(selectCorporationId);
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(false);
  const identity = useSelector(selectIdentity);
  const contentStatus = useSelector(selectFileInfosStatus);
  const showAll = useSelector(selectShowAllFileInfos);
  const { added: fileInfos } = useFilesDiff(!showAll ? groupFileInfos : null);

  if (!identity) {
    return null;
  }

  const syncFiles = async (fileInfosToSync, shouldSkip) => {
    const accessToken = await dispatch(getToken());
    const added = fileInfos
      .filter(
        (fileInfo) =>
          !fileInfosToSync.some(
            (fileInfoToSync) => fileInfo.id === fileInfoToSync.id
          )
      )
      .map((fileInfo) => fileInfo.id);
    const removed = fileInfosToSync.map((fileInfo) => fileInfo.id);
    dispatch(setDisregarded({ fileInfos: added }));
    await postDisregarded(
      zoneId,
      { fileInfos: { added, removed } },
      accessToken
    );

    if (!shouldSkip) {
      setDisableCreateNewTeamButton(true);
      const toast = dispatchToast(dispatch, {
        message: ADDING_FILES,
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0,
      });

      dispatch(
        addFiles({
          fileInfos: fileInfosToSync,
          corporationId,
          zoneId,
          groupId,
          rootNodeId,
        })
      );

      toast.replace({
        message: FILES_ADDED,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
    }

    close();
    setDisableCreateNewTeamButton(false);
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      canOutsideClickClose={false}
      title={
        <div className={styles.title}>
          <AddFile size={34} />
          <span className={styles.dialogTitle}>Sync Added Files</span>
        </div>
      }
      className={styles.dialog}
    >
      <BhSyncFiles
        sync={syncFiles}
        disableCreateNewTeamButton={disableCreateNewTeamButton}
        fileInfos={fileInfos}
        contentStatus={contentStatus}
        buttonText={ADD}
        defaultAllSelected={false}
      />
    </Dialog>
  );
}
