import { without, hash as hshf } from "utils";

//let unlockKeys = 0;
//let cachePubKeys = 0;
//let dispatchKeys = 0;

//setInterval(() => console.log("time spent unlock keys", unlockKeys), 10000);
//setInterval(() => console.log("time spent cachePubKeys", cachePubKeys), 10000);
//setInterval(() => console.log("time spent dispatchKeys", dispatchKeys), 10000);

export default module => ({
  keyShare: async ({ payload }, dispatch, getState) => {
    const {
      actions: { getContext, keyShare },
      auxiliary: { cachePublicKeys, unlockSecKey }
      //reducers: { keyShare },
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, keyId, secKey } = payload;
      //let t0 = new Date;
      const publicKeys = await dispatch(cachePublicKeys(origin));
      //cachePubKeys += (new Date - t0);
      //let t1 = new Date;
      const symKey = await dispatch(unlockSecKey(publicKeys, keyId, secKey));
      //unlockKeys += (new Date - t1);
      //let t2 = new Date;
      dispatch(keyShare({ ...payload, symKey }));
      //dispatchKeys += (new Date - t2);
    } finally {
      release();
    }
  },
  publishRelation: async ({ payload }, dispatch, getState) => {
    const {
      selectors: { selectScope, selectAuthorities },
      actions: { getContext, error, publishRelation },
      //reducers: { error, publishRelation },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { origin, hash, sign } = payload;
      const authorities = selectAuthorities(selectScope(getState(true)))();
      const checks = [
        () => hshf(align(payload)).then(s => s == hash),
        () => authorities.includes(origin),
        () => dispatch(verifySignature(hash, origin, sign))
      ];
      for (const check of checks) {
        if (!await check()) {
          dispatch(error({ ...payload, reason: `${check}` }));
          return;
        }
      }
      dispatch(publishRelation(payload));
    } finally {
      release();
    }
  },
  createGroup: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectScope, selectGroup },
      actions: { getContext, error, createGroup },
      //reducers: { error, createGroup },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, hash, sign, att } = payload;
      const { integrity } = selectGroup(selectScope(getState(true)))(group);
      const checks = [
        () => att != "rejected" && !integrity,
        () => hshf(align(payload)).then(s => s == hash),
        () => dispatch(verifySignature(hash, origin, sign)),
        () => dispatch(verifySignature(hash, ENCLAVE, att))
      ];
      for (let i = 0; i < checks.length; i++) {
        if (!await checks[i]()) {
          dispatch(error({ ...payload, fatal: !i, reason: `${checks[i]}` }));
          return;
        }
      }
      dispatch(createGroup(payload));
    } finally {
      release();
    }
  },
  addMember: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectScope, selectGroup },
      actions: { getContext, error, addMember },
      //reducers: { error, addMember },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, hash, prev, sign, att } = payload;
      const scope = selectScope(getState(true));
      const { integrity, members } = selectGroup(scope)(group);
      const checks = [
        () => att != "rejected",
        () => prev == integrity,
        () => hshf(align(payload)).then(s => s == hash),
        () => dispatch(verifySignature(hash, ENCLAVE, att)),
        () => members && members.includes(origin),
        () => dispatch(verifySignature(hash, origin, sign))
      ];
      for (let i = 0; i < checks.length; i++) {
        if (!await checks[i]()) {
          dispatch(error({ ...payload, fatal: !i, reason: `${checks[i]}` }));
          return;
        }
      }
      dispatch(addMember(payload));
    } finally {
      release();
    }
  },
  shareRes: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectScope, selectGroup },
      actions: { getContext, error, shareRes },
      //reducers: { error, shareRes },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, hash, prev, sign, att } = payload;
      const scope = selectScope(getState(true));
      const { integrity, members } = selectGroup(scope)(group);
      const checks = [
        () => att != "rejected",
        () => prev == integrity,
        () => hshf(align(payload)).then(s => s == hash),
        () => dispatch(verifySignature(hash, origin, sign)),
        () => members && members.includes(origin),
        () => dispatch(verifySignature(hash, ENCLAVE, att))
      ];
      for (let i = 0; i < checks.length; i++) {
        if (!await checks[i]()) {
          dispatch(error({ ...payload, fatal: !i, reason: `${checks[i]}` }));
          return;
        }
      }
      dispatch(shareRes(payload));
    } finally {
      release();
    }
  },
  removeMember: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectScope, selectGroup },
      actions: { getContext, error, removeMember },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, hash, prev, sign, att, at } = payload;
      const scope = selectScope(getState(true));
      const { integrity, members } = selectGroup(scope)(group);
      let signed = await hshf(align(without(payload, "prev")));
      if (at < 1640991600000 && prev) {
        signed = hash;
      }
      const checks = [
        () => !att || att != "rejected",
        () => !prev || prev == integrity,
        () => hshf(align(payload)).then(s => s == hash),
        () => dispatch(verifySignature(signed, origin, sign)),
        () => members && members.includes(origin),
        () => !att || dispatch(verifySignature(hash, ENCLAVE, att))
      ];
      for (let i = 0; i < checks.length; i++) {
        if (!await checks[i]()) {
          dispatch(error({ ...payload, fatal: !i, reason: `${checks[i]}` }));
          return;
        }
      }
      dispatch(removeMember(payload));
    } finally {
      release();
    }
  },
  removeRes: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectScope, selectGroup },
      actions: { getContext, error, removeRes },
      auxiliary: { align, verifySignature }
    } = module;
    const { mutex } = await dispatch(getContext());
    const [release] = await mutex.main.acquire();
    try {
      const { group, origin, hash, prev, sign, att, at } = payload;
      const scope = selectScope(getState(true));
      const { integrity, members } = selectGroup(scope)(group);
      let signed = await hshf(align(without(payload, "prev")));
      if (at < 1640991600000 && prev) {
        signed = hash;
      }
      const checks = [
        () => !att || att != "rejected",
        () => !prev || prev == integrity,
        () => hshf(align(payload)).then(s => s == hash),
        () => dispatch(verifySignature(signed, origin, sign)),
        () => members && members.includes(origin),
        () => !att || dispatch(verifySignature(hash, ENCLAVE, att))
      ];
      for (let i = 0; i < checks.length; i++) {
        if (!await checks[i]()) {
          dispatch(error({ ...payload, fatal: !i, reason: `${checks[i]}` }));
          return;
        }
      }
      dispatch(removeRes(payload));
    } finally {
      release();
    }
  }
});
