import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, IconSize } from "@blueprintjs/core";
import { selectIdentity } from "../../app/topSlice.js";
import { appState } from "../../app/appState.js";
import { doRequest, doRequestDeprecated } from "./slice";
import { useRoles } from "../../app/rolesContext.js";
import { getUsersFromGroup } from "app-state/src/derive.js";
import { formatUser } from "../format/format.js";
import { ATTESTATION_APPROVE, ATTESTATION_REJECT } from "../../app/permissions";
import { Wizard } from "../wizard/Wizard";

const {
  selectors: { selectGraph },
} = appState;

const service = "attestation";

export default ({ active, node, close }) => {
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [signingOrder, setSigningOrder] = useState(false);
  const [expire, setExpire] = useState(null);
  const [loading, setLoading] = useState(false);

  const graph = useSelector(selectGraph);
  const identity = useSelector(selectIdentity);
  const roles = useRoles();

  const { nodes } = graph;

  if (!identity) {
    return null;
  }

  if (active && (node === null || !nodes.hasOwnProperty(node.id))) {
    console.log("Node is not valid or not in nodes!");
    return null;
  }

  const group = node?.group;

  const groupUsers = group && getUsersFromGroup(graph, group);

  const filteredUsers = groupUsers?.filter((user) => {
    const actions = roles?.find((role) => user.role == role.id)?.actions;
    return (
      (user.email && actions?.[ATTESTATION_APPROVE]) ||
      actions?.[ATTESTATION_REJECT]
    );
  });

  const selectableUsers = filteredUsers?.map((user) => {
    const [[primary, secondary], detailed] = dispatch(formatUser(user));
    return {
      id: user.user || user.id,
      text: [[primary, secondary], detailed],
      email: user.email,
      wellknown: user.wellknown[0],
      companyName: "",
      orgNo: "",
    };
  });

  const onSubmit = async () => {
    const signees = createSignees();
    const info = createInfo();

    try {
      await dispatch(
        doRequest(node.id, signees, info, signingOrder, expire, setLoading)
      );
      /* await dispatch(doRequestDeprecated(node.id, signees[0].id, setLoading)); */
      close();
    } catch (error) {
      console.error(error);
    }
  };

  const createSignees = () => {
    return selectedUsers?.map(({ id }) => ({ id }));
  };

  const createInfo = () => {
    return selectedUsers?.map(
      ({ id, name, email, orgNo, companyName }, index) => ({
        id,
        order: index + 1,
        email,
        fullName: name,
        organizationInfo: {
          orgNo,
          companyName,
        },
      })
    );
  };

  const props = {
    selectedUsers,
    setSelectedUsers,
    signingOrder,
    setSigningOrder,
    expire,
    setExpire,
    selectableUsers,
    onSubmit,
    close,
    service,
    SharedIcon: <Icon icon="endorsed" size={IconSize.STANDARD} />,
  };

  return <Wizard {...props} />;
};
