
import styles from "./Skeleton.module.css";

export const Skeleton = () => (
  <div className={styles.Skeleton}>
    <div>
      <div />
      <div />
      <div />
      <div />
    </div>
    <div>
      <div>
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);
