import React from "react";
import { classNames } from "utils";
import styles from "./icons.module.css";

export const QrCodeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3 
  11h8V3H3zm2-6h4v4H5zM3 
  21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 
  6h-4V5h4zm-5.99 
  4h2v2h-2zm2 2h2v2h-2zm-2 
  2h2v2h-2zm4 0h2v2h-2zm2 2h2v2h-2zm-4 
  0h2v2h-2zm2-6h2v2h-2zm2 2h2v2h-2z"
    />
  </svg>
);

export const ZoneUsers = ({ size = 44, className = "", ...props }) => (
  <div className={classNames(className, styles.zoneUsers)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/*<circle cx="7" cy="8" r="2" strokeWidth="1" />*/}
      {/*<rect x="3" y="4" width="19" height="19" rx="2"/>*/}
      <circle cx="13" cy="14" r="10" strokeWidth="1.2" />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //width="14"
      //height="14"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="9" cy="7" r="4" />
      <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v3M16 3.13a4 4 0 010 7.75M21 20v-1a4 4 0 00-3-3.85" />
    </svg>
  </div>
);

export const FolderUsers = ({ size = 44, className = "", ...props }) => (
  <div className={classNames(className, styles.folderUsers)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      strokeWidth="1.2"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 4h4l3 2h3a5 5 0 014 4v9a5 5 0 01-4 4H6a5 5 0 01-4-4V8a5 5 0 014-4" />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //width="14"
      //height="14"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="9" cy="7" r="4" />
      <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v3M16 3.13a4 4 0 010 7.75M21 21v-2a4 4 0 00-3-3.85" />
    </svg>
  </div>
);

export const Blocked = () => (
  <span icon="blocked-person" className="bp3-icon bp3-icon-blocked-person">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-icon="blocked-person"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      className={styles.blocked}
    >
      <path
        d="M11.55 15.92c-1.48-.65-1.28-1.05-1.33-1.59-.01-.07-.01-.15-.01-.23.51-.45.92-1.07 1.19-1.78 0 0 .01-.04.02-.05.06-.15.11-.32.15-.48.34-.07.54-.44.61-.78.06-.11.14-.35.17-.62C10.33 9.42 8.92 7.38 8.92 5c0-.3.05-.58.09-.87-.33-.08-.67-.13-.99-.13-.79 0-1.68.25-2.31.73-.61.47-1.07 1.13-1.29 1.86-.05.16-.09.33-.11.5-.12.6-.17 1.51-.17 2.14v.08c-.24.09-.45.32-.49.83-.04.39.12.73.2.87.08.35.28.72.63.78.04.17.09.33.15.48 0 .01.01.02.01.03l.01.01c.27.72.7 1.35 1.22 1.8 0 .07-.01.14-.01.21-.05.54.1.94-1.38 1.59C3 16.56.77 17.26.32 18.31-.15 19.38.04 20 .04 20h15.95s.18-.62-.27-1.67c-.46-1.06-2.69-1.75-4.17-2.41z"
        fillRule="evenodd"
      />
      <path
        d="M14.97 0c-2.78 0-5.03 2.24-5.03 5s2.25 5 5.03 5S20 7.76 20 5s-2.25-5-5.03-5zm-3.03 5c0-1.66 1.35-3 3.02-3 .47 0 .9.11 1.29.3l-4.01 3.99c-.18-.4-.3-.83-.3-1.29zm3.03 3c-.47 0-.9-.11-1.29-.3l4.01-3.99c.19.39.3.82.3 1.29 0 1.66-1.36 3-3.02 3z"
        fillRule="evenodd"
      />
    </svg>
  </span>
);

export const Restrict = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.restrict
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <circle cx="9" cy="7" r="4" />
      <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M17 9l4 4m0-4l-4 4" />
    </svg>
  </div>
);

export const Change = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.change
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <path d="M9 7H6a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-3" />
      <path d="M9 15h3l8.5-8.5a1.5 1.5 0 00-3-3L9 12v3M16 5l3 3" />
    </svg>
  </div>
);

export const AddUser = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.addUser
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <circle cx="9" cy="7" r="4" />
      <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 11h6m-3-3v6" />
    </svg>
  </div>
);

export const RemoveUser = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.addUser
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // class="icon icon-tabler icon-tabler-user-minus"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="9" cy="7" r="4" />
      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
      <line x1="16" y1="11" x2="22" y2="11" />
    </svg>
  </div>
);

export const AddFile = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.addUser
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // class="icon icon-tabler icon-tabler-file-plus"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      <line x1="12" y1="11" x2="12" y2="17" />
      <line x1="9" y1="14" x2="15" y2="14" />
    </svg>
  </div>
);

export const RemoveFile = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.addUser
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // class="icon icon-tabler icon-tabler-file-minus"
      width={`${size - 8}`}
      height={`${size - 8}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      <line x1="9" y1="14" x2="15" y2="14" />
    </svg>
  </div>
);

export const AddZone = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.addZone
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 14}`}
      height={`${size - 14}`}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 9h9c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-9c-.28 0-.5.22-.5.5s.22.5.5.5zm0 2h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-5c-.28 0-.5.22-.5.5s.22.5.5.5zM19 1H1c-.55 0-1 .45-1 1v16c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1zm-1 16H2V6h16v11zM3.5 13h7c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-7c-.28 0-.5.22-.5.5s.22.5.5.5z"
      />
    </svg>
  </div>
);

export const Users = ({ size = 44, className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
    className={classNames(className, styles.users)}
  >
    <path d="M0 0h24v24H0z" stroke="none" />
    <circle cx="9" cy="7" r="4" />
    <path d="M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 3.13a4 4 0 010 7.75M21 21v-2a4 4 0 00-3-3.85" />
  </svg>
);

export const Trash = ({ size = 44, className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
    className={classNames(className, styles.trash)}
  >
    <path d="M4 7h16" />
    <path d="M10 11v6M14 11v6" />
    <path d="M5 7l1 12a2 2 0 002 2h8a2 2 0 002-2l1-12" />
    <path d="M9 7V4a1 1 0 011-1h4a1 1 0 011 1v3" />
  </svg>
);

export const Fingerprint = ({ size = 44, className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 24 24"
    stroke="#000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
    className={classNames(className, styles.fingerprint)}
  >
    <path d="M12 11v2a14 14 0 002.5 8M8 15a18 18 0 001.8 6M4.9 19a22 22 0 01-.9-7v-1a8 8 0 0112-6.95" />
    <path d="M18.9 7a8 8 0 011.1 5v1a6 6 0 00.8 3M8 11a4 4 0 018 0v1a10 10 0 002 6" />
  </svg>
);

export const Info = ({ size = 44, className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 24 24"
    stroke="#000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
    className={classNames(className, styles.info)}
  >
    <path d="M12 8h.01M11 12h1v4h1" />
    <circle cx="12" cy="12" r="9" />
  </svg>
);

export const Click = ({ size = 44, className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 24 24"
    stroke="#000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
    className={classNames(className, styles.click)}
  >
    <path d="M3 12h3M12 3v3M7.8 7.8L5.6 5.6M16.2 7.8l2.2-2.2M7.8 16.2l-2.2 2.2" />
    <path d="M12 12l9 3-4 2-2 4-3-9" />
  </svg>
);

export const Cancel = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.cancel)}>
    <svg
      fill="none"
      stroke="#2c3e50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width={`${size - 10}`}
      height={`${size - 10}`}
    >
      <path d="M18 6L6 18M6 6l12 12" />
    </svg>
  </div>
);

export const Check1 = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.outer)}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      enableBackground="new 0 0 20 20"
      width={`${size}`}
      height={`${size}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71,5.29C9.53,5.11,9.28,5,9,5C8.45,5,8,5.45,8,6c0,0.28,0.11,0.53,0.29,0.71
              l3,3C11.47,9.89,11.72,10,12,10s0.53-0.11,0.71-0.29l7-7C19.89,2.53,20,2.28,20,2c0-0.55-0.45-1-1-1c-0.28,0-0.53,0.11-0.71,0.29
              L12,7.59L9.71,5.29z M17.64,7.61C17.87,8.36,18,9.17,18,10c0,4.42-3.58,8-8,8s-8-3.58-8-8s3.58-8,8-8c1.38,0,2.67,0.35,3.8,0.96
              l1.46-1.46C13.73,0.56,11.93,0,10,0C4.48,0,0,4.48,0,10c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.4-0.29-2.73-0.81-3.95L17.64,7.61
              z"
      />
    </svg>
  </div>
);

export const Check2 = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.outer)}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      enableBackground="new 0 0 20 20"
      width={`${size}`}
      height={`${size}`}
    >
      <path
        d="M10,20C4.48,20,0,15.52,0,10S4.48,0,10,0s10,4.48,10,10S15.52,20,10,20z M15,6
              c-0.28,0-0.53,0.11-0.71,0.29L8,12.59l-2.29-2.3C5.53,10.11,5.28,10,5,10c-0.55,0-1,0.45-1,1c0,0.28,0.11,0.53,0.29,0.71l3,3
              C7.47,14.89,7.72,15,8,15c0.28,0,0.53-0.11,0.71-0.29l7-7C15.89,7.53,16,7.28,16,7C16,6.45,15.55,6,15,6z"
      />
    </svg>
  </div>
);

export const Cross = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.outer)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#FF5722"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  </div>
);

export const Next = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.outer)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <path d="M9 6l6 6-6 6" />
    </svg>
  </div>
);

export const Send = ({ size = 44, className = "", ...props }) => (
  <div {...props} className={classNames(className, styles.outer)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#FF5722"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="10" y1="14" x2="21" y2="3" />
      <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
    </svg>
  </div>
);

export const Email = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.email
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 4}`}
      height={`${size - 4}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="4" />
      <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
    </svg>
  </div>
);

export const TwoFA = ({ size = 44 }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      styles.twofa
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 4}`}
      height={`${size - 4}`}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M7 16h-4l3.47 -4.66a2 2 0 1 0 -3.47 -1.54" />
      <path d="M10 16v-8h4" />
      <line x1="10" y1="12" x2="13" y2="12" />
      <path d="M17 16v-6a2 2 0 0 1 4 0v6" />
      <line x1="17" y1="13" x2="21" y2="13" />
    </svg>
  </div>
);

export const BankID = ({ size = 44 }) => (
  <div
    className={classNames(
      /*"squircle",*/ styles.outer,
      styles.squircle,
      styles.bankid
    )}
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      /*xmlns:xlink="http://www.w3.org/1999/xlink"*/ x="0px"
      y="0px"
      width={`${size - 4}`}
      height={`${size - 4}`}
      viewBox="-367 247.5 106.8 100.8"
      enableBackground="new -367 247.5 106.8 100.8" /*xml:space="preserve"*/
    >
      <path
        fill="#459BBE"
        d="M-333.3,300.2l4.4-28.1c-1.8,0-4.8,0-4.8,0c-2.2,0-5.1-1.2-6-3.5c-0.3-0.8-0.9-3.5,2.8-6.1
    c1.3-0.9,2.2-1.9,2.3-2.7c0.2-0.8,0-1.5-0.6-2.1c-0.7-0.7-2.3-1.2-4.3-1.2c-3.4,0-5.8,2-6,3.4c-0.2,1.1,0.7,1.9,1.4,2.4
    c2.1,1.6,2.6,3.9,1.3,6.1c-1.4,2.2-4.3,3.7-7.4,3.7c0,0-3.1,0-4.9,0c-0.4,2.7-7,44.7-7.5,48h26.4
    C-336.1,318.7-334.9,310.7-333.3,300.2L-333.3,300.2z"
      />
      <path
        fill="#235971"
        d="M-298,247.5h-26.8l-3.6,22.7h4.6c2.5,0,4.9-1.1,5.9-2.8c0.3-0.5,0.5-1,0.5-1.4c0-0.9-0.6-1.7-1.3-2.1
    c-1.8-1.3-2.1-2.7-2.1-3.7c0-0.2,0-0.4,0-0.5c0.4-2.4,3.6-5,7.9-5c2.6,0,4.5,0.6,5.7,1.7c1,1,1.5,2.4,1.2,3.8
    c-0.4,1.7-2.1,3.1-3.1,3.8c-2.6,1.8-2.3,3.4-2.1,3.9c0.5,1.4,2.6,2.3,4.2,2.3h7l0,0c9.5,0.1,14.6,4.4,12.9,14.6
    c-1.5,9.4-8.7,13.5-17.3,13.6l-3.6,21.7h5c21.3,0,38.6-13.7,42.1-35.2C-256.6,258.2-273.6,247.5-298,247.5z"
      />
      <g>
        <g>
          <path
            fill="#235971"
            d="M-364.1,329.8h10.8c4.6,0,5.7,2.3,5.4,4.5c-0.3,1.7-1.4,3-3.5,3.8c2.6,1,3.6,2.5,3.2,4.9
        c-0.5,3-3.1,5.2-6.5,5.2H-367L-364.1,329.8z M-357,337.5c2.1,0,3.1-1.1,3.3-2.4c0.2-1.4-0.4-2.4-2.5-2.4h-1.9l-0.8,4.8H-357z
        M-358.2,345.5c2.2,0,3.4-0.9,3.7-2.7c0.2-1.5-0.6-2.5-2.7-2.5h-2.1l-0.8,5.2H-358.2z"
          />
          <path
            fill="#235971"
            d="M-333.2,348.4c-2.8,0.2-4.2-0.1-4.8-1.3c-1.5,0.9-3.1,1.4-4.9,1.4c-3.2,0-4.3-1.6-4-3.5
        c0.1-0.9,0.6-1.7,1.5-2.4c1.7-1.5,6.1-1.7,7.8-2.9c0.1-1.3-0.4-1.8-2-1.8c-1.9,0-3.4,0.6-6.1,2.4l0.6-4.2c2.3-1.7,4.5-2.4,7.1-2.4
        c3.3,0,6.2,1.3,5.6,4.9l-0.6,4.1c-0.2,1.4-0.2,1.9,1.4,1.9L-333.2,348.4z M-338,342.1c-1.5,0.9-4.3,0.8-4.6,2.7
        c-0.1,0.9,0.4,1.6,1.3,1.6c0.9,0,2-0.4,2.8-1c-0.1-0.3,0-0.7,0.1-1.3L-338,342.1z"
          />
          <path
            fill="#235971"
            d="M-327.9,334h5.6l-0.3,1.9c1.8-1.5,3.1-2.1,4.9-2.1c3.1,0,4.6,1.9,4.1,5.1l-1.5,9.4h-5.6l1.2-7.8
        c0.2-1.4-0.2-2.1-1.3-2.1c-0.9,0-1.7,0.5-2.5,1.5l-1.3,8.3h-5.6L-327.9,334z"
          />
          <path
            fill="#235971"
            d="M-309.2,329.8h5.6l-1.4,9.1l5.4-4.9h6.9l-6.9,6.1l5.5,8.2h-7.1l-4.3-6.6h-0.1l-1,6.6h-5.6L-309.2,329.8z"
          />
        </g>
        <g>
          <path
            fill="#235971"
            d="M-364.1,329.8h10.8c4.6,0,5.7,2.3,5.4,4.5c-0.3,1.7-1.4,3-3.5,3.8c2.6,1,3.6,2.5,3.2,4.9
        c-0.5,3-3.1,5.2-6.5,5.2H-367L-364.1,329.8z M-357,337.5c2.1,0,3.1-1.1,3.3-2.4c0.2-1.4-0.4-2.4-2.5-2.4h-1.9l-0.8,4.8H-357z
        M-358.2,345.5c2.2,0,3.4-0.9,3.7-2.7c0.2-1.5-0.6-2.5-2.7-2.5h-2.1l-0.8,5.2H-358.2z"
          />
          <path
            fill="#235971"
            d="M-333.2,348.4c-2.8,0.2-4.2-0.1-4.8-1.3c-1.5,0.9-3.1,1.4-4.9,1.4c-3.2,0-4.3-1.6-4-3.5
        c0.1-0.9,0.6-1.7,1.5-2.4c1.7-1.5,6.1-1.7,7.8-2.9c0.1-1.3-0.4-1.8-2-1.8c-1.9,0-3.4,0.6-6.1,2.4l0.6-4.2c2.3-1.7,4.5-2.4,7.1-2.4
        c3.3,0,6.2,1.3,5.6,4.9l-0.6,4.1c-0.2,1.4-0.2,1.9,1.4,1.9L-333.2,348.4z M-338,342.1c-1.5,0.9-4.3,0.8-4.6,2.7
        c-0.1,0.9,0.4,1.6,1.3,1.6c0.9,0,2-0.4,2.8-1c-0.1-0.3,0-0.7,0.1-1.3L-338,342.1z"
          />
          <path
            fill="#235971"
            d="M-327.9,334h5.6l-0.3,1.9c1.8-1.5,3.1-2.1,4.9-2.1c3.1,0,4.6,1.9,4.1,5.1l-1.5,9.4h-5.6l1.2-7.8
              c0.2-1.4-0.2-2.1-1.3-2.1c-0.9,0-1.7,0.5-2.5,1.5l-1.3,8.3h-5.6L-327.9,334z"
          />
          <path
            fill="#235971"
            d="M-309.2,329.8h5.6l-1.4,9.1l5.4-4.9h6.9l-6.9,6.1l5.5,8.2h-7.1l-4.3-6.6h-0.1l-1,6.6h-5.6L-309.2,329.8z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#459BBE"
          d="M-289.4,329.8h6.5l-2.9,18.4h-6.5L-289.4,329.8z"
        />
        <path
          fill="#459BBE"
          d="M-279.8,329.8h9.2c7.1,0,9.2,5.2,8.5,9.5c-0.6,4.2-4,9-10.2,9h-10.3L-279.8,329.8z M-273.8,343.9
      c3.1,0,4.9-1.5,5.4-4.8c0.4-2.4-0.4-4.8-3.8-4.8h-1.8l-1.5,9.7L-273.8,343.9L-273.8,343.9z"
        />
      </g>
    </svg>
  </div>
);

export const PinLock = ({ size = 44 }) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 4}`}
      height={`${size - 4}`}
      viewBox="0 0 20 20"
    >
      <path
        d="M15.93 9H14V4.99c0-2.21-1.79-4-4-4s-4 1.79-4 4V9H3.93c-.55 0-.93.44-.93.99v8c0 .55.38 1.01.93 1.01h12c.55 0 1.07-.46 1.07-1.01v-8c0-.55-.52-.99-1.07-.99zM8 9V4.99c0-1.1.9-2 2-2s2 .9 2 2V9H8z"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

export const SSO = ({ size = 44, background = true }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      background && styles.sso
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 8}`}
      height={`${size - 8}`}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
    >
      <path
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
      />
    </svg>
  </div>
);

export const SelectProvider = ({ size = 44, background = true }) => (
  <div
    className={classNames(
      "squircle",
      styles.outer,
      styles.squircle,
      background
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size - 4}`}
      height={`${size - 4}`}
      fill="none"
      viewBox="0 0 20 20"
      strokeWidth="1.5"
    >
      <path
        fill="#5c7080"
        d="M0 4.01v11.91l6.27-6.27L0 4.01zm18.91-1.03H1.09L10 10.97l8.91-7.99zm-5.18 6.66L20 15.92V4.01l-6.27 5.63zm-3.23 2.9c-.13.12-.31.19-.5.19s-.37-.07-.5-.19l-2.11-1.89-6.33 6.33h17.88l-6.33-6.33-2.11 1.89z"
        fillRule="evenodd"
      ></path>
    </svg>
  </div>
);
