import {
  uid,
  rand,
  pause,
  all,
  touch,
  isInteger,
  distinct,
  createJsonChecksum,
  keys,
  values,
  entries,
  fromEntries,
  without,
  trial,
  jwtPeek,
} from "utils";

import {
  getGroupsFromZone,
  getUsersFromGroup,
  getUsersFromZoneRootGroup,
  getUserByUri,
  getUserById,
  getZoneFromNode,
  getGroupNodes,
  getNodeGroupDescendants,
  getNodeDescendants,
  getNodeWithDescendants,
  getNodeWithChildren,
  getNodeWithGroupDescendants,
} from "./derive.js";

import { createUri } from "./queries.js";

const TEMPLATE_TEAMS = "$TEMPLATES$";
const UNCATEGORIZED_TEAMS = "";

export default (module, config, api, ...rest) => ({
  //intermediate: () => {},
  response: () => {},
  remoteSync: async ({ payload: action }, dispatch) => {
    const {
      actions: { remote },
      meta: { take },
    } = module;
    action.payload.ping = rand();
    dispatch(remote(action));
    const { payload } = await dispatch(
      take((a) => a.payload && a.payload.pong == action.payload.ping)
    );
    return payload;
  },
  remoteAsync: async ({ payload: action }, dispatch) => {
    const {
      actions: { remote },
    } = module;
    action.payload.ping = rand();
    await dispatch(remote(action));
    return action.payload.ping;
  },
  remoteFuture: async ({ payload: action }, dispatch) => {
    const {
      actions: { remote },
      meta: { take },
    } = module;
    action.payload.ping = rand();
    dispatch(remote(action));
    const { payload } = await dispatch(
      take((a) => a.payload && a.payload.pong == action.payload.ping)
    );
    return {
      ...payload,
      next: payload.next || action.payload.ping,
      //ping: action.payload.ping2
    };
  },
  waitResponse: async ({ payload: pong }, dispatch) => {
    const {
      meta: { take },
    } = module;
    return await dispatch(take((action) => action.payload.pong == pong));
  },
  initialize: async ({}, dispatch) => {
    const {
      actions: { initialized },
      server: { initialize },
      client: { remoteFuture },
    } = module;
    dispatch(initialized({ timeZero: 1 }));
    const result = await dispatch(remoteFuture(initialize({})));
    if (result && result.ok) {
      dispatch(initialized(result));
    }
  },
  loadZone: async ({ payload: { id, deep = false } }, dispatch, getState) => {
    const {
      client: { remoteSync },
      actions: { getToken, getChannel },
      server: { fetchZoneFlat, fetchZoneDeep },
    } = module;
    const token = await dispatch(getToken());
    const { user } = trial(jwtPeek)(token);

    // TODO this may need to be synced better...
    // ...should not be that hard.
    const channel = await dispatch(getChannel());
    //await channel.subscribe([-1, user, id]);
    let topics = [user, id];
    [topics] = channel.diff(topics);
    if (topics?.length) {
      await channel.subscribe([-1, ...topics]);
    }
    //await dispatch(remoteSync(fetchZoneFlat({ id })));

    if (deep) {
      await dispatch(remoteSync(fetchZoneDeep({ id })));
    } else {
      await dispatch(remoteSync(fetchZoneFlat({ id })));
    }
  },
  loadZones: async (
    { payload: { category, templates } },
    dispatch,
    getState
  ) => {
    const {
      client: { remoteSync },
      actions: { setNextPageParams },
      server: { fetchZones },
    } = module;
    let paramId;
    if (templates) {
      paramId = TEMPLATE_TEAMS;
    } else if (category) {
      paramId = category;
    } else {
      paramId = UNCATEGORIZED_TEAMS; //uncategorized teams
    }
    const state = getState();
    const params = state.nextPageParams[paramId];
    const comparator = state.zonesSortOrder;
    const filter = state.zonesSearchTerm;
    const payload = {
      category,
      templates,
      quantity: 15,
      ...params,
      comparator,
      filter,
    };
    const result = await dispatch(remoteSync(fetchZones(payload)));
    dispatch(setNextPageParams({ paramId, params: result.next }));
    return result;
  },
  loadNode: async ({ payload: { nodeId, zoneId } }, dispatch, getState) => {
    const {
      client: { remoteSync },
      actions: { getToken, getChannel },
      server: { fetchNode },
    } = module;
    const token = await dispatch(getToken());
    const { user } = trial(jwtPeek)(token);
    // TODO this may need to be synced better...
    // ...should not be that hard.
    const channel = await dispatch(getChannel());
    //await channel.subscribe([-1, user, zoneId]);
    let topics = [user, zoneId];
    [topics] = channel.diff(topics);
    if (topics?.length) {
      await channel.subscribe([-1, ...topics]);
    }
    await dispatch(remoteSync(fetchNode({ id: nodeId, zoneId })));
  },
  searchZones: async ({ payload: filter }, dispatch) => {
    const {
      actions: { setCurrentNode, resetZones, resetZonesPageParams },
    } = module;
    dispatch(setCurrentNode());
    dispatch(resetZones());
    dispatch(resetZonesPageParams({ filter }));
  },
  expandNode: async ({ payload: { id, expanded } }, dispatch, getState) => {
    const {
      actions: { remote, setExpandedNode },
      server: { fetchNode },
    } = module;
    dispatch(setExpandedNode({ id, expanded }));
    if (expanded) {
      const zoneId = getZoneFromNode(getState().graph, id);
      dispatch(remote(fetchNode({ id, zoneId })));
    }
  },
  createCategory: async ({ payload }, dispatch, getState) => {
    const {
      actions: { getToken, error, addCategory },
    } = module;
    const accessToken = await dispatch(getToken());
    const { name } = payload;
    const { user: userId } = trial(jwtPeek)(accessToken);

    let category;
    try {
      const id = uid();
      const result = await api
        .token(accessToken)
        .categories(id)
        .post({
          id,
          name,
          teams: [],
          user: { id: userId },
        });
      category = {
        ...without(result, "link"),
        user: result.user.id,
      };
    } catch (e) {
      if (e.code === 400) {
        return { reason: "BAD_NAME" };
      } else if (e.code === 409) {
        return { reason: "DUPLICATE_NAMES" };
      } else {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }
    }
    dispatch(addCategory(category));
    return {};
  },
  assignCategory: async ({ payload }, dispatch, getState) => {
    const {
      client: { remoteSync },
      //server: { fetchZoneFlat },
      client: { loadZone },
      actions: { error, getToken, addCategory, setCategory, unsetCategory },
    } = module;
    const accessToken = await dispatch(getToken());
    let { zoneId, categoryId } = payload;
    const { user: userId } = trial(jwtPeek)(accessToken);
    dispatch(setCategory({ categoryId, zoneId }));
    //await dispatch(remoteSync(fetchZoneFlat({ id: zoneId })));
    await dispatch(loadZone({ id: zoneId }));
    const members = getUsersFromZoneRootGroup(getState().graph, zoneId);
    const { member: memberId } = members.find(
      (member) => member.user == userId
    );
    try {
      //// If category is assigned to be uncategorized,
      //// then categoryId needs to be null, not undefined.
      if (!categoryId) {
        categoryId = null;
      }
      await api
        .token(accessToken)
        .members(memberId)
        .patch({
          category: { id: categoryId },
        });
    } catch (e) {
      dispatch(unsetCategory({ categoryId, zoneId }));
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }
    return {};
  },
  removeCategory: async ({ payload }, dispatch, getState) => {
    const {
      actions: { getToken, error, rmCategory },
    } = module;
    const accessToken = await dispatch(getToken());
    const { categoryId } = payload;
    let reason;
    try {
      await api.token(accessToken).categories(categoryId).delete();
    } catch (e) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }
    dispatch(rmCategory(categoryId));
    return {};
  },
  createUser: async ({ payload }, dispatch) => {
    const {
      actions: { getContext, error },
      server: { createUser, fetchUserByUri, fetchPlaceholderUsers },
      client: { remoteSync },
    } = module;

    const {
      credentials: { deviceId },
    } = await dispatch(getContext());

    const { provider, wellknown, name, email, roleId, extraAuthenticatorArgs } =
      payload;

    if (provider == "placeholder") {
      const result = await dispatch(
        remoteSync(fetchPlaceholderUsers({ email }))
      );
      const [user] = result.users;
      if (user) {
        return { userId: user.user, created: false };
      }
    } else {
      const uri = `${provider}:${wellknown.join("|")}`;
      const result = await dispatch(remoteSync(fetchUserByUri({ uri })));
      const user = result?.user;
      if (user) {
        return { userId: user.user, created: false };
      }
    }

    const { user, reason } = await dispatch(
      remoteSync(
        createUser({
          deviceId,
          provider,
          wellknown,
          name,
          email,
          roleId,
          extraAuthenticatorArgs,
        })
      )
    );

    if (reason) {
      if (reason == "OUT_OF_SYNC") {
        dispatch(error({ reason }));
      } else {
        dispatch(error({ reason }));
      }
      return { reason: "HANDLED" };
    }

    return { userId: user.user, created: true };
  },
  createZone: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      selectors: { selectZone, selectGraph },
      trustKit: {
        auxiliary: { getAnchor },
        actions: { loadGroup, createGroup, cloneGroups },
        selectors: { selectScope, selectMembers },
      },
      server: { createZone, fetchZoneFlat, fetchZoneDeep },
      client: { remoteFuture, waitResponse, remoteSync },
      actions: { error, getToken, getChannel },
    } = module;

    const {
      name,
      zoneId,
      copyFolders,
      copyFiles,
      copyMessages,
      copyMembers,
      isTemplate,
    } = payload;

    const token = await dispatch(getToken());
    const { user } = trial(jwtPeek)(token);

    const state = getState(true);
    const graph = selectGraph(state);

    let groupIds = [];
    let rootGroupId = uid();
    let virtualRootGroupId = rootGroupId;

    let groupsAction = null;

    const anchor = await dispatch(getAnchor());

    let delegates;

    if (anchor && anchor.delegateGroup) {
      const { delegateGroup } = anchor;
      await dispatch(loadGroup(delegateGroup));
      // TODO was here state => getState(true)
      delegates = selectMembers(selectScope(getState(true)))(
        delegateGroup
      ).filter((dev) => dev != ENCLAVE);
    }

    if (zoneId && (copyFolders || copyMessages || copyMembers)) {
      const channel = await dispatch(getChannel());
      await channel.subscribe([-1, user, zoneId]);
      if (copyFolders) {
        await dispatch(remoteSync(fetchZoneDeep({ id: zoneId })));
      } else {
        await dispatch(remoteSync(fetchZoneFlat({ id: zoneId })));
      }

      const zone = graph.zones[zoneId];

      rootGroupId = zone.rootGroup;
      virtualRootGroupId = zone.virtualRootGroup;

      if (copyFolders) {
        groupIds = getGroupsFromZone(graph, zoneId).map(({ id }) => id);
      } else {
        groupIds.push(zone.rootGroup);
        if (zone.virtualRootGroup != zone.rootGroup) {
          groupIds.push(zone.virtualRootGroup);
        }
      }

      groupIds = groupIds.map((id) => [id, uid()]);

      //await all(...groupIds.flat().map(id => dispatch(loadGroup(id))));
      await dispatch(loadGroup(...groupIds.flat()));

      groupIds = fromEntries(groupIds);

      rootGroupId = groupIds[zone.rootGroup];
      virtualRootGroupId = groupIds[zone.virtualRootGroup];

      groupsAction = cloneGroups({
        groupIds,
        filter: {
          copyFiles,
          copyMembers,
          copyMessages,
          ...(!copyMembers && delegates && { copyURIs: delegates }),
        },
      });
    } else {
      await dispatch(loadGroup(rootGroupId));

      if (anchor && anchor.delegateGroup) {
        const { delegateGroup } = anchor;
        groupsAction = cloneGroups({
          groupIds: { [delegateGroup]: rootGroupId },
          filter: { copyURIsAllBut: [ENCLAVE] },
        });
      } else {
        groupsAction = createGroup({ groupId: rootGroupId });
      }
    }

    while (true) {
      const it = await dispatch(groupsAction);
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = createZone({
        flag,
        name,
        copyFiles,
        copyFolders,
        rootGroupId,
        groupIds,
        virtualRootGroupId,
        copyMessages,
        copyMembers,
        isTemplate,
        ...(zoneId ? { zoneId } : {}),
      });

      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { graph, reason },
        } = await promise;

        if (reason) {
          if (["BAD_NAME", "NO_NAME"].includes(reason)) {
            return { reason };
          }
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }

        const zone = values(graph.zones).find(
          (zone) => zone.rootGroup == rootGroupId
        );

        if (!zone) {
          return { reason: "UNKNOWN" };
        }

        return { id: zone.id };
      }
    }
  },
  addMember: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, addUsers },
      },
      server: { addMember, fetchUserById },
      client: { remoteSync, remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId, userId } = payload;

    const result = await dispatch(remoteSync(fetchUserById({ id: userId })));
    const user = result?.user;

    if (!user) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const { provider, wellknown } = user;

    const uri = `${provider}:${wellknown.join("|")}`;

    const state = getState();

    const zone = state.graph.zones[zoneId];

    if (!zone) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const groupId = zone.rootGroup;

    const isMember = getUsersFromGroup(state.graph, groupId).some(
      (member) => member.user == user.user
    );

    if (isMember) {
      //dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "PRESENT" };
    }

    await dispatch(loadGroup(groupId));

    const memo = {};

    while (true) {
      const it = await dispatch(addUsers({ memo, groupId, userURIs: [uri] }));

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = addMember({ zoneId, userId, flag });

      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  removeMember: async ({ payload }, dispatch, getState) => {
    const {
      actions: { getContext, error },
      trustKit: {
        actions: { loadGroup, removeUsers },
      },
      server: { removeMember },
      client: { remoteFuture, waitResponse },
    } = module;

    const { user: removerId } = await dispatch(getContext());

    const { zoneId, userId } = payload;

    const state = getState();

    const zone = state.graph.zones[zoneId];

    const groupIds = getGroupsFromZone(state.graph, zoneId).map(({ id }) => id);
    const members = groupIds.flatMap((id) =>
      getUsersFromGroup(state.graph, id)
    );
    const removers = members.filter((member) => member.user == removerId);
    const removees = members.filter((member) => member.user == userId);

    if (!removees.length) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    if (removees.some((a) => !removers.some((b) => a.group == b.group))) {
      // The user you are about to remove has access to folders which you are
      // not a part of. Removing the user might lead to irreversible data loss
      return { reason: "ADVERSE_EFFECT" };
    }

    if (userId == removerId) {
      const rootMembers = getUsersFromGroup(state.graph, zone.rootGroup);
      const virtuals = getUsersFromGroup(state.graph, zone.virtualRootGroup);
      if (virtuals.every(({ user }) => user == removerId)) {
        // all members must be the remover for the team to be deleted,
        // otherwise this might lead to irreversible data loss
        if (rootMembers.some(({ user }) => user != removerId)) {
          return { reason: "ADVERSE_EFFECT" };
        }
      }
    }

    //await all(...removees.map(member => dispatch(loadGroup(member.group))));
    await dispatch(loadGroup(...removees.map((member) => member.group)));

    {
      const it = await dispatch(
        removeUsers({
          groupIds: removees.map(({ group }) => group),
          userURIs: [createUri(removees[0])], //all are the same user
        })
      );

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = removeMember({ ...payload, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      //console.log("noop", noop);

      if (!noop) {
        await it();
      }

      {
        //console.log("awaing promise");
        const {
          payload: { reason },
        } = await promise;
        //console.log("did await promise");

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }

        return {};
      }
    }
  },
  createNode: async ({ payload }, dispatch, getState) => {
    const {
      server: { createNode },
      actions: { remote, error },
      meta: { subscribe },
    } = module;

    const ping = rand();
    const action = createNode(payload);

    action.payload.ping = ping;
    dispatch(remote(action));

    let resolve,
      promise = new Promise((r) => (resolve = r));
    let unsubscribe, reason;
    try {
      let first = true;
      unsubscribe = dispatch(
        subscribe((history) => {
          for (const a of history) {
            const match = a.payload && a.payload.pong === action.payload.ping;
            if (match) {
              const { reason } = a.payload;
              if (first) {
                first = false;
                if (reason) {
                  resolve(reason);
                }
              } else {
                resolve(reason);
              }
            }
          }
        })
      );

      reason = await promise;
    } finally {
      if (unsubscribe) {
        unsubscribe();
      }
    }

    if (reason) {
      if (reason == "BAD_NAME") {
        return { reason };
      }
      if (reason == "OUT_OF_SYNC") {
        dispatch(error({ reason }));
      } else {
        dispatch(error({ reason }));
      }
      //return { reason };
      return { reason: "HANDLED" };
    }

    return {};
  },
  removeNode: async ({ payload }, dispatch, getState) => {
    //const flag = uid();

    //const { orphanKeys, next, reason } = await dispatch(
    //  remoteFuture(removeNode({ id: nodeId, zoneId, flag }))
    //);

    //if (reason) {
    //  if (reason == "ADVERSE_EFFECT") {
    //    dispatch(error({ reason }));
    //  } else {
    //    dispatch(error({ reason }));
    //  }
    //  return { reason: "HANDLED" };
    //}

    //if (!orphanKeys) {
    //  //done: server will remove any folders/groups
    //  return {};
    //}

    //const groupIds = distinct(orphanKeys.map(({ groupId }) => groupId));
    const {
      trustKit: {
        actions: { loadGroup, removeResources },
      },
      server: { removeNode, fetchBranch },
      client: { remoteSync, remoteFuture, waitResponse },
      actions: { error, message },
    } = module;

    const { nodeId } = payload;

    const state = getState();
    const node = state.graph.nodes[nodeId];

    if (!node) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const zoneId = getZoneFromNode(state.graph, node.id);
    await dispatch(remoteSync(fetchBranch({ zoneId, id: node.id })));
    const descendants = getNodeWithDescendants(getState().graph, node.id);
    const nodeIds = descendants.map((node) => node.id);

    const groupedKeyIds = {};

    for (const node of descendants) {
      if (node.meta?.key) {
        if (!(node.group in groupedKeyIds)) {
          groupedKeyIds[node.group] = [];
        }
        groupedKeyIds[node.group].push(node.meta.key);
      }
    }

    //console.log(nodeIds, groupedKeyIds);

    await dispatch(loadGroup(...keys(groupedKeyIds)));

    const it = await dispatch(removeResources({ groupedKeyIds }));
    const { nogo, noop, flag } = await it();

    if (nogo) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const effect = removeNode({ zoneId, nodeId, nodeIds, flag });
    const { next, reason } = await dispatch(remoteFuture(effect));

    if (reason) {
      if (reason == "OUT_OF_SYNC") {
        dispatch(error({ reason }));
      } else {
        dispatch(error({ reason }));
      }
      return { reason: "HANDLED" };
    }

    const promise = dispatch(waitResponse(next));

    if (!noop) {
      await it();
    }

    {
      const {
        payload: { reason },
      } = await promise;

      if (reason) {
        if (reason == "TIMEOUT") {
          dispatch(error({ reason: "OUT_OF_SYNC" }));
        } else if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }
    }

    // TODO SOMETHING LIKE DIS (to rebalace index of the siblings left after delete)
    //// make the change instantly available in gui
    //dispatch(moveNodeTemp({ nodeId, parentId, index }));
    // MAY BE ITS SUFFICIENT TO IT SERBER SIDE?

    return {};
  },
  moveNode: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, change },
      },
      selectors: { selectGraph },
      server: { moveNode, fetchGroupNodes },
      client: { remoteSync, remoteFuture, waitResponse },
      actions: { moveNodeTemp, setExpandedNode, error },
    } = module;

    let { nodeId, parentId, index } = payload;

    const state = getState();
    const node = state.graph.nodes[nodeId];

    if (!node) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    if (!parentId) {
      parentId = payload.parentId = node.parent;
    }

    if (!isInteger(index)) {
      index = 0;
      let siblings = values(state.graph.nodes).filter(
        (n) => n.parent == parentId
      );
      if (siblings.length) {
        index = Math.min(...siblings.map((n) => n.index)) - 1;
      }
      payload.index = index;
      //console.log("index became!", index);
    }

    const parent = state.graph.nodes[parentId];
    const group = state.graph.groups[node.group];

    // if no change
    if (node.index == index && node.parent == parentId) {
      return {};
    }

    // make the change instantly available in gui
    dispatch(moveNodeTemp({ nodeId, parentId, index }));

    index = payload.index = getState().graph.nodes[nodeId].index;

    dispatch(setExpandedNode({ id: parentId, expanded: true }));

    if (node.group == parent.group || node.id == group.rootFile) {
      // no need to change group
      await dispatch(remoteSync(moveNode(payload)));
      return {};
    }

    // we need to change group

    const zoneId = getZoneFromNode(state.graph, node.id);

    await dispatch(remoteSync(fetchGroupNodes({ zoneId, id: node.group })));
    await dispatch(loadGroup(node.group, parent.group));

    const memo = {};

    while (true) {
      const state = getState(true);

      const from = group.id;
      const to = parent.group;

      const descendants = getNodeGroupDescendants(selectGraph(state), nodeId);
      const nodeIds = descendants.map((node) => node.id);

      const keys =
        node.meta && node.meta.key
          ? [node.meta.key]
          : descendants
              .filter((node) => node.meta?.key)
              .map((node) => node.meta.key);

      const uris = [];
      const moveR = true;

      const action = change({ memo, state, from, to, keys, uris, moveR });
      const it = await dispatch(action);
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = moveNode({ ...payload, nodeIds, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  restrictZone: async ({ payload }, dispatch, getState) => {
    const {
      config: { ENCLAVE },
      trustKit: {
        auxiliary: { getAnchor },
        actions: { loadGroup, createGroup, cloneGroups },
      },
      server: { restrictZone },
      client: { remoteFuture, waitResponse },
      actions: { error },
    } = module;

    let { zoneId, groupId } = payload;

    if (!groupId) {
      groupId = payload.groupId = uid();
    }

    const state = getState();
    const zone = state.graph.zones[zoneId];

    if (!zone || zone.virtualRootGroup != zone.rootGroup) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const { delegateGroup } = (await dispatch(getAnchor())) || {};
    await dispatch(
      loadGroup(groupId, ...(delegateGroup ? [delegateGroup] : []))
    );

    let action;

    if (delegateGroup) {
      action = cloneGroups({
        groupIds: { [delegateGroup]: groupId },
        filter: { copyURIsAllBut: [ENCLAVE] },
      });
    } else {
      action = createGroup({ groupId });
    }

    {
      const it = await dispatch(action);
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = restrictZone({ ...payload, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        await it();
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  unrestrictZone: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, addUsers },
      },
      //selectors: { selectGraph, selectZone },
      server: { unrestrictZone },
      client: { remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId } = payload;

    const state = getState();
    const zone = state.graph.zones[zoneId];

    if (!zone || zone.virtualRootGroup == zone.rootGroup) {
      dispatch(error({ reason: "Error 2322" }));
      return { reason: "HANDLED" };
    }

    const groupId = zone.virtualRootGroup;

    const rootMembers = getUsersFromGroup(state.graph, zone.rootGroup);
    const virtualRootMembers = getUsersFromGroup(state.graph, groupId);

    const members = rootMembers.filter(
      (a) => !virtualRootMembers.some((b) => a.user == b.user)
    );

    //const userURIs = distinct(members.map(member => createUri(member)));
    //const userURIs = distinct(members.map(createUri));

    // createUri cannot be passed as lambda to map because of args
    const userURIs = members.map((member) => createUri(member));

    await dispatch(loadGroup(groupId));

    const memo = {};

    while (true) {
      const it = await dispatch(addUsers({ memo, groupId, userURIs }));
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = unrestrictZone({ ...payload, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  restrictFolder: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, change },
      },
      server: { restrictFolder, fetchGroupNodes },
      client: { remoteSync, remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId, nodeId } = payload;

    const state = getState();
    const node = state.graph.nodes[nodeId];
    const group = state.graph.groups[node.group];
    const members = getUsersFromGroup(state.graph, group.id);

    if (!zoneId || !node || !parent || !group || group.rootFile == nodeId) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    await dispatch(remoteSync(fetchGroupNodes({ zoneId, id: group.id })));

    const newGroupId = uid();
    const from = group.id;
    const to = newGroupId;
    const descendants = getNodeWithGroupDescendants(getState().graph, node.id);
    const nodeIds = descendants.map((node) => node.id);
    const memberIds = members.map((m) => m.member);
    // createUri cannot be passed as lambda to map because of args
    const userURIs = members.map((m) => createUri(m));
    const keys = descendants.map((node) => node.meta?.key).filter((key) => key);

    await dispatch(loadGroup(from, to));

    const memo = {};

    //console.log(JSON.stringify({ from, to, uris, keys }, null, 2));

    const action = change({
      memo,
      from,
      to,
      uris: userURIs,
      keys,
      make: true,
      moveR: true,
    });

    /*while (true)*/ {
      const it = await dispatch(action);
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = restrictFolder({
        ...payload,
        newGroupId,
        nodeIds,
        memberIds,
        flag,
      });

      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        /*const { rejected } =*/ await it();

        //if (rejected) {
        //  await pause(1000);
        //  continue;
        //}
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  unrestrictFolder: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, change },
      },
      server: { unrestrictFolder, fetchGroupNodes },
      client: { remoteSync, remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId, nodeId } = payload;

    const state = getState();
    const node = state.graph.nodes[nodeId];
    const parent = state.graph.nodes[node.parent];
    const group = state.graph.groups[node.group];

    if (!zoneId || !node || !parent || !group || group.rootFile != nodeId) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    await dispatch(remoteSync(fetchGroupNodes({ zoneId, id: group.id })));

    const from = group.id;
    const to = parent.group;

    await dispatch(loadGroup(from, to));

    const memo = {};

    while (true) {
      const state = getState();
      const descendants = getNodeWithGroupDescendants(state.graph, node.id);
      const nodeIds = descendants.map((node) => node.id);
      const keys = descendants
        .map((node) => node.meta?.key)
        .filter((key) => key);

      const it = await dispatch(change({ memo, from, to, uris: [], keys }));
      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = unrestrictFolder({
        ...payload,
        groupId: group.id,
        parentId: parent.id,
        parentGroup: parent.group,
        nodeIds,
        flag,
      });

      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  restrictMember: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, removeUsers },
      },
      server: { restrictMember },
      client: { remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId, nodeId, memberId } = payload;

    const state = getState();
    const member = state.graph.users[memberId];
    const node = state.graph.nodes[nodeId];

    if (!member || !node || member.group != node.group) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const groupId = member.group;
    const userURIs = [createUri(member)];

    await dispatch(loadGroup(groupId));

    {
      const it = await dispatch(removeUsers({ groupIds: [groupId], userURIs }));

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = restrictMember({ ...payload, groupId, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        await it();
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }
    }

    return {};
  },
  includeMember: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, addUsers },
      },
      server: { includeMember },
      client: { remoteFuture, waitResponse },
      actions: { error },
    } = module;

    const { zoneId, nodeId, memberId } = payload;

    const state = getState();
    const member = state.graph.users[memberId];
    const node = state.graph.nodes[nodeId];

    if (!member || !node || member.group == node.group) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const groupId = node.group;
    const userURIs = [createUri(member)];

    await dispatch(loadGroup(groupId));

    const memo = {};

    while (true) {
      const it = await dispatch(addUsers({ memo, groupId, userURIs }));

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = includeMember({
        ...payload,
        groupId,
        memberGroup: member.group,
        flag,
      });

      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else if (reason == "ADVERSE_EFFECT") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  shareMessage: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, addResources },
      },
      //selectors: { selectGraph, selectNode, selectGroup },
      client: { remoteFuture, waitResponse },
      actions: { error },
    } = module;

    let { groupId, keyId, symKey } = payload;

    const state = getState(true);

    await dispatch(loadGroup(groupId));

    while (true) {
      const res = [[keyId, null, null, symKey]];
      const it = await dispatch(addResources({ groupId, res }));

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ nogo, error: "Error 2318" }));
        return {};
      }

      if (!noop) {
        const { rejected, prev, hash } = await it();

        if (rejected) {
          //console.log("REJECTED! pauseing...", { prev, hash });
          await pause(1000);
          continue;
        }
      }

      return { ok: true };
    }
  },
  uploadNode: async ({ payload }, dispatch, getState) => {
    const {
      trustKit: {
        actions: { loadGroup, addResources },
      },
      server: { createNode },
      client: { remoteFuture, waitResponse },
      actions: { setExpandedNode, error },
    } = module;

    let { name, zoneId, nodeId, parentId, index, keyId, symKey } = payload;

    if (!isInteger(index)) {
      index = 0;
    }

    const state = getState();
    const parent = state.graph.nodes[parentId];

    if (!parent) {
      dispatch(error({ reason: "OUT_OF_SYNC" }));
      return { reason: "HANDLED" };
    }

    const groupId = parent.group;

    dispatch(setExpandedNode({ id: parentId, expanded: true }));

    await dispatch(loadGroup(groupId));

    const memo = {};

    while (true) {
      const res = [[keyId, null, null, symKey]];
      const it = await dispatch(addResources({ memo, groupId, res }));

      const { nogo, noop, flag } = await it();

      if (nogo) {
        dispatch(error({ reason: "OUT_OF_SYNC" }));
        return { reason: "HANDLED" };
      }

      const effect = createNode({ ...payload, flag });
      const { next, reason } = await dispatch(remoteFuture(effect));

      if (reason) {
        if (reason == "OUT_OF_SYNC") {
          dispatch(error({ reason }));
        } else {
          dispatch(error({ reason }));
        }
        return { reason: "HANDLED" };
      }

      const promise = dispatch(waitResponse(next));

      if (!noop) {
        const { rejected } = await it();

        if (rejected) {
          await pause(1000);
          continue;
        }
      }

      {
        const {
          payload: { reason },
        } = await promise;

        if (reason) {
          if (reason == "TIMEOUT") {
            dispatch(error({ reason: "OUT_OF_SYNC" }));
          } else if (reason == "OUT_OF_SYNC") {
            dispatch(error({ reason }));
          } else {
            dispatch(error({ reason }));
          }
          return { reason: "HANDLED" };
        }
      }

      return {};
    }
  },
  setExpire: async ({ payload }, dispatch, getState) => {
    const {
      actions: { error },
      client: { remoteSync },
      server: { setExpire },
    } = module;

    let { zoneId, date } = payload;

    //if (date) {
    //  date = +new Date + 5000;
    //}

    const { reason } = await dispatch(remoteSync(setExpire({ zoneId, date })));

    if (reason) {
      if (reason == "OUT_OF_SYNC") {
        dispatch(error({ reason }));
      } else {
        dispatch(error({ reason }));
      }
      return { reason: "HANDLED" };
    }

    return {};
  },
});
//setExpire: async ({ payload }, dispatch, getState) => {
//  const {
//    server: { setExpire },
//    shared: { remoteFuture, remoteSync, error }
//  } = module;
//  const { zoneId } = payload;
//  let date = payload.date;

//  if (date) {
//    date = new Date(date);
//    date.setHours(23, 59, 59);
//    date = date.toJSON();
//  } else {
//    date = false;
//  }

//  const effect = setExpire({ zoneId, date });
//  const { fail, diff } = await dispatch(remoteSync(effect));
//  if (fail) {
//    dispatch(error({ fail, error: "could not set expire date" }));
//  }
//},

//getAuthenticationSecret: async ({ payload }, dispatch, getState) => {
//  const {
//    actions: { getContext },
//    server: { createUser, loadUserByUri },
//    shared: { remoteSync, error }
//  } = module;

//  const {
//    cryptoClient,
//    credentials: { privateECDHKey, deviceId }
//  } = await dispatch(getContext());

//  const {
//    provider,
//    wellknown,
//    email,
//    roleId,
//    extraAuthenticatorArgs
//  } = payload;

//  const uri = `${provider}:${wellknown.join("|")}`;
//  await dispatch(remoteSync(loadUserByUri({ uri })));
//  if ((getUserByUri(selectGraph(getState(true)), uri) || {}).user) {
//    dispatch(error("User already exists."));
//    return;
//  }

//  await dispatch(
//    remoteSync(
//      createUser({
//        deviceId,
//        provider,
//        wellknown,
//        email,
//        roleId,
//        extraAuthenticatorArgs
//      })
//    )
//  );
//},
