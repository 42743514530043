import { createSlice } from "@reduxjs/toolkit";
import {
  //uid,
  any,
  age,
  nanoid,
  hash,
  jwtPeek,
  //remove,
  createMutex,
  createMetaHooks,
  createSagaActions,
  request,
  authReq,
} from "utils";
import { CryptoClient, deriveKeyPairsFromPrivateKeys } from "hyker-crypto";
//import { connector } from "streamer";
import { requestAccessToken } from "./realworld";

import { API_URL_AUTHORITY, API_URL_PREFIX, KEYPER } from "../config";
import * as Sentry from "@sentry/browser";

import { io } from "socket.io-client";
import createChannel from "./channel.js";

export const initialWindowLocationHref = window.location.href;

export const inBullhorn = new URL(initialWindowLocationHref).searchParams.has(
  "currentBullhornUrl"
);

export const {
  metaActions: { waitFor },
} = createMetaHooks();

const slice = {
  name: "top",
  initialState: {
    //clientId: uid(),
    clientId: nanoid(),
    identity: null,
    token: {},
    offline: false,
    ready: false,
    redirecting: false,
  },
  reducers: {
    ready: (state) => {
      state.ready = true;
    },
    offline: (state) => {
      state.offline = true;
    },
    redirecting: (state) => {
      state.redirecting = true;
    },
    login: (state, { payload }) => {
      Sentry.configureScope((scope) => {
        scope.setUser({ i: payload.id });
      });
      state.identity = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  sagas: {
    redirect: async ({ payload: url }, dispatch) => {
      await dispatch(redirecting());
      window.location.replace(url);
    },
    logout: async ({}, dispatch) => {
      try {
        const sessionId = JSON.parse(localStorage.id).sessionId;
        const url = `${API_URL_PREFIX}/sessions/${sessionId}`;
        const TIMEOUT = {};
        const accessToken = await any(dispatch(getToken()), age(TIMEOUT, 1000));
        if (accessToken != TIMEOUT) {
          const { user: userId } = jwtPeek(accessToken);
          await request(
            url,
            authReq({
              method: "delete",
              accessToken,
              userId,
            })
          );
        }
        delete localStorage.id;
        delete localStorage.login;
        delete localStorage.alert;
        Sentry.configureScope((scope) => scope.clear());
        window.location.reload();
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    getToken: async ({}, dispatch, getState) => {
      const { token, pending, localExpire } = selectToken(getState(true));
      if (pending) {
        return await dispatch(
          waitFor((state) => {
            const { token, pending } = selectToken(state);
            if (!pending) {
              return token;
            }
          })
        );
      }
      if (token && +new Date() < localExpire) {
        return token;
      }
      {
        dispatch(setToken({ pending: true }));
        const identity = await dispatch(waitFor(selectIdentity));
        const { credentials, device, _debugger } = identity;
        const token = await requestAccessToken(credentials, device, _debugger);
        await dispatch(
          setToken({ token, localExpire: +new Date() + 60 * 1000 })
        );
        return token;
      }
    },
    getSocket: (() => {
      let socket,
        params = {};
      return ({}, dispatch, getState) => {
        if (!socket) {
          const { clientId } = getState();
          socket = io(`https://${API_URL_AUTHORITY}`, {
            auth: async (cb) => {
              const id = clientId;
              const token = await dispatch(getToken());
              cb({ id, token, params });
            },
          });
          setInterval(async () => {
            if (socket.connected) {
              const token = await dispatch(getToken());
              if (socket.connected) {
                socket.timeout(30 * 1000).emit("auth", token);
              }
            }
          }, 60 * 1000);
        }
        return [socket, params];
      };
    })(),
    getChannel: (() => {
      let theChannel, setChannel;
      return async (_, dispatch, getState) => {
        if (!theChannel) {
          theChannel = new Promise((r) => (setChannel = r));
          const { clientId } = getState();
          const { id: userId, org } = await dispatch(waitFor(selectIdentity));
          const ns = await hash(org);
          const [socket, params] = dispatch(getSocket());
          const channel = createChannel("app", ns, socket, params);
          channel.onMessage(({ payload, zone }, topics, { id: seq }) => {
            if (payload?.payload?.zone) {
              if (!channel.has([userId, payload.payload.zone])) {
                channel.subscribe([-1, userId, payload.payload.zone]);
              }
            }
            if (payload) {
              if ([clientId, userId].some((topic) => topics.includes(topic))) {
                dispatch({ ...payload /*, seq*/ });
              }
            }
          });
          await channel.sub([
            [0, clientId],
            [-1, userId, ns],
          ]);
          setChannel(channel);
        }
        return await theChannel;
      };
    })(),
    getContext: (() => {
      let theContext, setContext;
      return async (_, dispatch, getState) => {
        if (!theContext) {
          theContext = new Promise((r) => (setContext = r));
          const identity = await dispatch(waitFor(selectIdentity));
          const { id, org, device, credentials: jwk } = identity;
          let { privateECDHKey, privateECDSAKey } = JSON.parse(jwk);
          privateECDHKey = JSON.stringify(privateECDHKey);
          privateECDSAKey = JSON.stringify(privateECDSAKey);
          const credentials = await deriveKeyPairsFromPrivateKeys(
            privateECDHKey,
            privateECDSAKey
          );
          credentials.deviceId = device;
          const ns = await hash(org);
          const [socket, params] = dispatch(getSocket());
          const channel = createChannel("sdk", ns, socket, params);
          const cryptoClient = new CryptoClient(KEYPER);
          const mutex = new Proxy(
            {},
            {
              get: (map, key) => {
                if (!(key in map)) {
                  map[key] = createMutex();
                }
                return map[key];
              },
            }
          );
          let listeners = [];
          channel.onMessage(async (entry, topics, { id, at }) => {
            for (const listener of listeners) {
              listener(entry, topics, { id, at });
            }
          });
          const addListener = (listener) => {
            listeners.push(listener);
            const remove = () => {
              listeners = listeners.filter((l) => l != listener);
            };
            return remove;
          };
          const context = {
            user: id,
            channel,
            cryptoClient,
            credentials,
            mutex,
            addListener,
          };
          setContext(context);
        }
        return await theContext;
      };
    })(),
  },
};

export const topSlice = createSlice(slice);

export const topSagas = slice.sagas;

export const { ready, offline, redirecting, login, setToken } =
  topSlice.actions;

export const { logout, redirect, getToken, getSocket, getChannel, getContext } =
  createSagaActions(slice);

export default topSlice.reducer;

export const selectClientId = (state) => state.top.clientId;

export const selectReady = (state) => state.top.ready;

export const selectOffline = (state) => state.top.offline;

export const selectRedirecting = (state) => state.top.redirecting;

export const selectToken = (state) => state.top.token;

export const selectIdentity = (state) => state.top.identity;

export const selectSpace = (state) => state.top.identity?.org;

export const selectUserURI = (state) =>
  state.top.identity?.org + ";" + state.top.identity?.account;
