import { Dialog, Intent } from "@blueprintjs/core";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import appContext from "../../app/appContext.js";
import { selectIdentity } from "../../app/topSlice.js";
import { RemoveFile } from "../login/icons.tsx";
import { dispatchToast } from "../toasts/Toasts.js";
import styles from "./BhDialogs.module.css";
import BhSyncFiles from "./BhSyncFiles";
import {
  removeFiles,
  selectFileInfosStatus,
  useFilesDiff,
} from "./bullhornSlice.js";

const REMOVING_FILES = "Removing File(s).";
const FILES_REMOVED = "File(s) Removed.";
const REMOVE = "Remove";

export default ({ dispatch, active, close, stepNr }) => {
  const [disableCreateNewTeamButton, setDisableCreateNewTeamButton] =
    useState(false);
  const identity = useSelector(selectIdentity);
  const contentStatus = useSelector(selectFileInfosStatus);
  const { removed: fileInfos } = useFilesDiff();

  if (!identity) {
    return null;
  }

  const syncFiles = async (files, shouldSkip) => {
    if (!shouldSkip) {
      setDisableCreateNewTeamButton(true);
      const toast = dispatchToast(dispatch, {
        message: REMOVING_FILES,
        icon: "SPINNER",
        intent: Intent.PRIMARY,
        timeout: 0,
      });

      dispatch(removeFiles({ files }));

      toast.replace({
        message: FILES_REMOVED,
        icon: "CHECK",
        intent: Intent.PRIMARY,
      });
    }
    close();
    setDisableCreateNewTeamButton(false);
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      canOutsideClickClose={false}
      title={
        <div className={styles.title}>
          <RemoveFile size={34} />
          <span className={styles.dialogTitle}>Sync Removed Files</span>
        </div>
      }
      className={styles.dialog}
    >
      <BhSyncFiles
        sync={syncFiles}
        disableCreateNewTeamButton={disableCreateNewTeamButton}
        fileInfos={fileInfos}
        contentStatus={contentStatus}
        buttonText={REMOVE}
        defaultAllSelected={true}
        defaultAllShown={false}
      />
    </Dialog>
  );
};
