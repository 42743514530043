import jwt from "jsonwebtoken";

import { A } from "psm";

import {
  all,
  uid,
  diff,
  intersect,
  keys,
  entries,
  values,
  fromEntries,
  assign,
  pick,
  without,
  mapObj,
  filterObj,
  sortObject,
  clone,
  sleep,
  trial,
  isInteger,
  json,
  request,
  jwtPeek,
  createJsonChecksum,
  createSagaActions,
  toStringSorted,
  hash,
} from "utils";

import {
  parseUri,
  onSetExpire,
  expireZone,
  getExpiredZones,
  getNextExpire,
  getPermissions,
  getRoles,
  getInitSpace,
  getZones,
  getZoneFlat,
  getZoneDeep,
  getGrafts,
  getGroupFromId,
  getNodeFromId,
  getUserFromUri,
  getUserFromId,
  getPlaceholderUsers,
  getConnectedUsers,
  bumpMember,
  getZoneFromNode,
} from "./queries.js";

import {
  cloneZone,
  invite,
  createFile,
  makeshiftName,
  //resolveNameConflict,
  resolveNameConflict2,
} from "./lib.js";

import {
  idListToMap,
  accumulate,
  pluck,
  getGroupsFromZone,
  getUsersFromZone,
  getUsersFromGroup,
  getNodesFromZone,
  getNodesFromGroup,
  getUserByUri,
  getPersonalState,
  getPersonalStateChange,
  getRootNodeFromZone,
  getNodeGroupDescendants,
  getNodeWithDescendants,
  getNodeWithChildren,
  getNodeWithGroupDescendants,
  getNodeWithAscendants,
  getGroupNodes,
  getSurfaceNodes,
  //constructTree,
  //deconstructTree,
  //removeDeepNodes,
  rebalanceIndices,
  moveNodeInGraph,
  decorateTheTree,
  decorateBranch,
  //getBranchFromLeaf
} from "./derive.js";

//import { reduce } from "./global.js";

const testIntegrity = async (graph, zone) => {
  const actual = pluck(graph, { zones: [zone] });
  const expected = await getZoneDeep(zone);
  decorateTheTree(expected, zone);

  for (const graph of [actual, expected]) {
    for (const entities of values(graph)) {
      for (const entity of values(entities)) {
        delete entity.timestamp;
        delete entity._modified;
        delete entity._removed;
        delete entity._touch;
        delete entity.expirationDate;
      }
    }
  }

  const bad =
    (await createJsonChecksum(actual)) != (await createJsonChecksum(expected));

  if (bad) {
    let badIndex = false,
      reason = "";
    if (!badIndex) {
      for (const [id, node] of entries(actual.nodes)) {
        if (!(id in expected.nodes)) {
          badIndex = true;
          reason = id + " not found in expected nodes";
          break;
        }
      }
    }
    if (!badIndex) {
      for (const [id, node] of entries(expected.nodes)) {
        if (!(id in actual.nodes)) {
          badIndex = true;
          reason = id + " not found in actual nodes";
          break;
        }
      }
    }
    if (!badIndex) {
      for (const [id, node] of entries(actual.nodes)) {
        if (node.index != expected.nodes[id].index) {
          badIndex = true;
          reason = `actual ${id} ${node.index} != ${expected.nodes[id].index} as expected`;
          break;
        }
      }
    }
    if (badIndex) {
      console.log("INDEX IS BAD !!!");
      console.log(reason);
      //const a = values(actual.nodes).sort((a, b) => a.index - b.index);
      //const e = values(expected.nodes).sort((a, b) => a.index - b.index);
      //for (let i = 0; i < Math.max(a.length, e.length); i++) {
      //  console.log(
      //    a[i]?.id.slice(0, 5) || "-----",
      //    `${a[i]?.index || ""}`.padStart(2, "0"),
      //    e[i]?.id.slice(0, 5) || "-----",
      //    `${e[i]?.index || ""}`.padStart(2, "0")
      //  );
      //}
      //console.log(sortObject(actual));
      //console.log(sortObject(expected));
    } else {
      console.log("SOUND THE ALARM, STATE MISSMATCH!");
      console.log("actual", await createJsonChecksum(actual));
      console.log("expected", await createJsonChecksum(expected));
      //console.log(toStringSorted(actual, true));
      //console.log(toStringSorted(expected, true));
    }
  } else {
    console.log("integrity ok");
  }
};

export default (module, config, api) => {
  const {
    API_URL_PREFIX,
    SERVER_DENIED_PERMISSION,
    API_URL_AUTH,
    API_URL_AUTHORITY,
    PUBLIC_KEY_AUTH,
  } = config;

  const takeFlag = (flag) => {
    const {
      meta: { takeRace },
    } = module;
    return takeRace({
      predicate: (a) => a.flag == flag || a.hash == flag || a.blk == flag,
      //challenge: sleep(600000, { _timeout: true })[0]
      challenge: sleep(60 * 1000, { _timeout: true })[0],
    });
  };

  const createLock = () => {
    let counter = 0;
    let locking = Promise.resolve();
    const free = () => counter == 0;
    const lock = (ms = 0) => {
      counter++;
      let unlocked = false;
      let unlocker;
      let pending = new Promise(
        (resolve) =>
          (unlocker = () => {
            if (!unlocked) {
              unlocked = true;
              counter--;
              resolve();
            }
          })
      );
      if (ms) {
        locking.then(() => setTimeout(unlocker, ms));
      }
      let locker = locking.then(() => unlocker);
      locking = locking.then(() => pending);
      return locker;
    };
    return { lock, free };
  };

  const locks = new Map();

  const lockPartial = async (id, ms = 1000) => {
    if (!locks.has(id)) {
      locks.set(id, createLock());
    }
    const { lock, free } = locks.get(id);
    const unlock = await lock(ms);
    return () => {
      unlock();
      if (free()) {
        locks.delete(id);
      }
    };
  };

  const ensured = new Map();

  const garbageCollection = () => async (dispatch, getState) => {
    const {
      actions: { accept },
    } = module;
    // TODO tune (incease) size (and time (interval)?) values
    setInterval(() => {
      const zones = [];
      for (const [id, t] of ensured.entries()) {
        if (ensured.size <= 30 || +new Date() - t < 90 * 1000) {
          break;
        }
        ensured.delete(id);
        zones.push(id);
      }
      const { graph } = getState().appstate;
      for (const [key, ids] of entries(accumulate(graph, { zones }))) {
        for (const id of ids) {
          delete graph[key][id];
        }
      }
      dispatch(accept({ graph }));
      //if (zones.length) {
      //  console.log("app state gc collected", zones.length);
      //  console.log(
      //    "app state nbr of zones after gc",
      //    keys(graph.zones).length
      //  );
      //}
    }, 30 * 1000);
  };

  // only for debug, not safe with regards to ensureLoaded
  const invalidateGraph = (zone) => async (dispatch, getState) => {
    const {
      actions: { accept },
    } = module;
    dispatch(
      accept({ graph: { zones: {}, nodes: {}, users: {}, groups: {} } })
    );
  };

  // only for debug, not safe with regards to ensureLoaded
  const invalidateZone = (zone) => async (dispatch, getState) => {
    const {
      actions: { accept },
    } = module;
    const graph = getState().appstate.graph;
    const remove = { zones: [zone] };
    for (const [key, ids] of entries(accumulate(graph, remove))) {
      for (const id of ids) {
        delete graph[key][id];
      }
    }
    dispatch(accept({ graph }));
  };

  const pending = {};

  const ensureLoaded = (dispatch, getState) => async (zone) => {
    ensured.delete(zone); // last in last out
    ensured.set(zone, +new Date());

    if (zone in getState().graph.zones) {
      return;
    } else if (zone in pending) {
      let t0 = new Date();
      await pending[zone];
      console.log(
        "--- did wait for pending enzure loaded for",
        new Date() - t0,
        "ms"
      );
      return;
    }
    let resolve;
    pending[zone] = new Promise((r) => (resolve = r));
    const {
      actions: { consume },
    } = module;
    const graph = await getZoneDeep(zone);
    decorateTheTree(graph, zone);
    dispatch(consume({ graph }));
    delete pending[zone];
    resolve();
  };

  const myDistribute = ({ ns, to, zone, action }) => {
    const {
      actions: { consume, distribute },
    } = module;
    if (action.type == consume().type && action.payload.graph?.zones) {
      for (const [id, zone] of entries(action.payload.graph.zones)) {
        if (zone.expirationDate && new Date(zone.expirationDate) < new Date()) {
          const { expirationDate, ...theZone } = zone;
          action.payload.graph.zones[id] = theZone;
        }
      }
    }
    return distribute({ ns, to, zone, action });
  };

  const respondGraph =
    (dispatch, getState) => (action, zone, node, group, deep, leaf) => {
      //let t0 = new Date;
      const {
        actions: { accept, consume /*, distribute*/ },
      } = module;
      const { ns, payload: { ping: pong, accessToken } = {} } = action || {};
      const { user } = trial(jwtPeek)(accessToken);

      const graphs = getPersonalState(getState().graph, [zone], [user], true);

      for (let graph of values(graphs)) {
        if (node) {
          let nodes;
          if (leaf) {
            nodes = getNodeWithAscendants(graph, node);
          } else if (deep) {
            nodes = getNodeWithDescendants(graph, node);
          } else {
            nodes = getNodeWithChildren(graph, node);
          }
          graph = { nodes: idListToMap(nodes) };
        } else if (group) {
          const nodes = getGroupNodes(graph, group);
          graph = { nodes: idListToMap(nodes) };
        } else if (!deep) {
          //removeDeepNodes(graph);
          graph.nodes = idListToMap(getSurfaceNodes(graph));
        }
        const action = consume({ graph, pong });
        dispatch(myDistribute({ ns, to: user, zone, action }));
        //console.log("respondGraph took", new Date - t0);
      }
    };

  // simultanious actions must yield the same result regardless of order
  // e.g. a * b == b * a
  // unless otherwise synced
  // e.g. removeMember can be order dependant, but its is synced by ledger
  // and communicated using flags.
  // Boardcast indicates whether or not the action should be distributed to
  // a user regardless of if it is subscribed to the zone in question. This
  // indication is forwarded to the next step in by where the zone flag is
  // placed (it is need either way, as a topics when publishing to client, or
  // to give the client a chance to subscribe to it).
  const applyUpdate =
    (dispatch, getState) =>
    (
      action,
      zone,
      upsert,
      remove,
      broadcast,
      broadcastTo,
      noAccept = false
    ) => {
      //let t0 = new Date;
      const {
        actions: { accept, consume /*, distribute*/ },
      } = module;
      const { ns, payload: { ping: pong, accessToken } = {} } = action || {};
      const { user } = trial(jwtPeek)(accessToken);

      // this will be a copy
      const graph = getState().graph;

      const copy = {
        ...(graph.zones && { zones: { ...graph.zones } }),
        ...(graph.nodes && { nodes: { ...graph.nodes } }),
        ...(graph.users && { users: { ...graph.users } }),
        ...(graph.groups && { groups: { ...graph.groups } }),
      };

      if (remove) {
        for (const [key, ids] of entries(accumulate(graph, remove))) {
          for (const id of ids) {
            delete graph[key][id];
          }
        }
      }

      for (const key of keys(upsert)) {
        if (!(key in graph)) {
          graph[key] = {};
        }
        for (const [id, entry] of entries(upsert[key])) {
          graph[key][id] = entry;
        }
      }

      const prev = { users: {}, groups: {}, nodes: {}, zones: {}, ...copy };
      const next = { users: {}, groups: {}, nodes: {}, zones: {}, ...graph };

      const change = getPersonalStateChange(prev, next, [zone], [], true);

      entries(change).forEach(([to, [sub, add]]) =>
        dispatch(
          myDistribute({
            ns,
            to,
            action: consume({
              graph: add,
              clean: sub, //mapObj(sub, keys),
              ...(to == user && { pong }),
              ...((broadcast || broadcastTo == to) && { zone }),
            }),
            ...(!broadcast && broadcastTo != to && { zone }),
          })
        )
      );

      if (user && pong && !(user in change)) {
        dispatch(
          myDistribute({ ns, to: user, zone, action: consume({ pong }) })
        );
      }

      if (!noAccept) {
        dispatch(accept({ graph }));
      }

      //console.log("applyUpdate took", new Date - t0);
      //testIntegrity(graph, zone);
    };

  const decorate =
    (f /*, ok = false*/) => async (action, dispatch, getState) => {
      const {
        actions: { error },
      } = module;
      const permissions = {};
      if (action.external) {
        try {
          if (!action.type || !("accessToken" in action.payload)) {
            return error({ reason: "UNAUTHORIZED" });
          }
          const {
            permissions: {
              roles: { global: roleId },
            },
          } = jwt.verify(action.payload.accessToken, PUBLIC_KEY_AUTH, {
            issuer: API_URL_AUTH,
            audience: API_URL_AUTHORITY,
          });
          const { actions } = await getPermissions(roleId);
          assign(permissions, actions);
        } catch (e) {
          console.log(e);
          return error({ reason: "UNAUTHORIZED" });
        }
      }
      return await f(action, {
        dispatch,
        getState,
        permissions,
        ensureLoaded: ensureLoaded(dispatch, getState),
        respondGraph: respondGraph(dispatch, getState),
        applyUpdate: applyUpdate(dispatch, getState),
      });
    };

  const internal =
    (f) =>
    (action, ...args) => {
      const {
        actions: { error },
      } = module;
      if (action.external) {
        return error({ reason: "UNAUTHORIZED" });
      }
      //return f({ ...action, internal: true }, ...args);
      return f(action, ...args);
    };

  return {
    garbageCollection: internal(({}, dispatch) => {
      dispatch(garbageCollection());
    }),
    invalidateGraph: internal(({}, dispatch) => {
      dispatch(invalidateGraph());
    }),
    invalidateZone: internal(({ payload }, dispatch) => {
      dispatch(invalidateZone(payload));
    }),
    //syncUser: ({ payload }, dispatch) => {
    //  dispatch(syncUser(payload));
    //},
    //syncZone: ({ payload }, dispatch) => {
    //  dispatch(syncZone(payload));
    //},
    syncHints: internal(
      decorate(
        async (
          { payload },
          { dispatch, getState, ensureLoaded /*, applyUpdate*/ }
        ) => {
          const {
            actions: { consume /*, distribute*/ },
          } = module;

          const {
            ws,
            affectedUsers,
            removedUsers,
            removedMembers,
            removedGroups,
            removedZone,
          } = payload;

          const ns = await hash(ws);

          //const { graph } = getState();

          if (affectedUsers) {
            if (removedUsers) {
              const action = consume({ clean: { users: removedUsers } });
              dispatch(action);
              for (const to of affectedUsers) {
                dispatch(myDistribute({ ns, to, action, zone: null }));
              }
              //for (const to of affectedUsers) {
              //  const groups = values(graph.users)
              //    .filter(
              //      user =>
              //        user.member &&
              //        user.user == to &&
              //        removedUsers.includes(user.user)
              //    )
              //    .map(user => user.group);
              //  // TODO also clean out zones
              //  const action = consume({
              //    clean: { users: removedUsers, groups }
              //  });
              //  dispatch(myDistribute({ ns, to, action, zone: null }));
              //}
            }
            if (removedMembers) {
              const action = consume({ clean: { users: removedMembers } });
              dispatch(action);
              for (const to of affectedUsers) {
                dispatch(myDistribute({ ns, to, action, zone: null }));
              }
              //for (const to of affectedUsers) {
              //  const groups = values(graph.users)
              //    .filter(
              //      user =>
              //        user.member &&
              //        user.user == to &&
              //        removedMembers.includes(user.member)
              //    )
              //    .map(user => user.group);
              //  const action = consume({
              //    clean: { users: removedMembers, groups }
              //  });
              //  dispatch(myDistribute({ ns, to, action, zone: null }));
              //}
            }
            if (removedGroups) {
              const action = consume({ clean: { groups: removedGroups } });
              dispatch(action);
              for (const to of affectedUsers) {
                dispatch(myDistribute({ ns, to, action, zone: null }));
              }
            }
            if (removedZone) {
              const action = consume({ clean: { zones: [removedZone] } });
              dispatch(action);
              for (const to of affectedUsers) {
                //dispatch(myDistribute({ ns, to, action/*, zone: null*/ }));
                //dispatch(myDistribute({ ns, to, action, zone: removedZone }));
                dispatch(myDistribute({ ns, to, action, zone: null }));
              }
            }
          }
        }
        //true
      )
    ),
    syncSmart: internal(
      decorate(
        async (
          { payload },
          { dispatch, getState, ensureLoaded, applyUpdate }
        ) => {
          //console.log("SYNC SMART", payload);
          const {
            actions: { consume /*, distribute*/ },
          } = module;
          let {
            id,
            _ws,
            _zone: zone,
            _entity,
            name,
            attestation,
            esignature,
            _touch: touch,
            _removed: removed,
          } = payload;

          const ns = await hash(_ws);

          let entity = _entity;
          if (entity == "team") {
            entity = "zone";
          } else if (entity == "file") {
            entity = "node";
          } else if (entity == "member") {
            entity = "user";
          }

          if (_entity == "user") {
            let action, params;
            if (removed) {
              params = { clean: { users: [id] } };
              action = consume(params);
            } else {
              const user = {
                ...pick(
                  payload,
                  //"org",
                  "deprecatedId",
                  "name",
                  "provider",
                  "wellknown",
                  "role"
                ),
                user: id,
              };
              const { graph } = getState();
              const users = fromEntries(
                entries(graph.users)
                  .filter(([, prev]) => prev.user == user.user)
                  .map(([id, prev]) => [id, { ...prev, ...user }])
              );
              params = { graph: { users } };
              action = consume({ graph: { users: { [id]: user } } });
            }
            dispatch(consume(params));
            (await getConnectedUsers(id)).map((to) =>
              dispatch(myDistribute({ ns, to, action, zone: null }))
            );
            return;
          }

          if (!id || !zone || !entity) {
            console.log(
              "unable to sync: no id, zone or entity in doc",
              payload
            );
            return;
          }

          const wasLoaded = zone in getState().graph.zones;
          await ensureLoaded(zone);

          let unlock;
          try {
            unlock = await lockPartial(zone);

            let { graph } = getState();
            let clean = { users: [], groups: [], nodes: [], zones: [] };
            let newGraph = { users: {}, groups: {}, nodes: {}, zones: {} };

            // it will work to do the touch way all the time.
            // but it requires an extra getZone if zone was ensured before
            // (which it probably was in most cases)
            if (touch) {
              if (wasLoaded) {
                if (entity == "node") {
                  graph = await getZoneDeep(zone);
                } else {
                  graph = await getZoneFlat(zone);
                }
              }
              const theEntity = graph[entity + "s"][id];
              newGraph[entity + "s"][id] = {
                ...theEntity,
                _touch: +new Date(),
              };
            } else if (removed) {
              // make sure there is something to remove
              const doc = pick(payload, "group", "user");
              dispatch(consume({ graph: { [entity + "s"]: { [id]: doc } } }));
              clean[entity + "s"].push(id);
            } else if (entity == "zone") {
              const oldZone = graph.zones[id];
              newGraph.zones[id] = { ...oldZone, name, _touch: +new Date() };
            } else if (entity == "node") {
              const oldNode = graph.nodes[id];
              if (attestation) {
                const attestations = oldNode.attestations.filter(
                  (doc) => doc.id != attestation.id
                );
                if (!attestation._removed) {
                  attestations.push(
                    without(
                      attestation,
                      "file",
                      "_created",
                      "_modified",
                      "_removed"
                    )
                  );
                }
                newGraph.nodes[id] = {
                  ...oldNode,
                  attestations,
                  _touch: +new Date(),
                };
              } else if (esignature) {
                const esignatures = oldNode.esignatures.filter(
                  (doc) => doc.id != esignature.id
                );
                if (!esignature._removed) {
                  esignatures.push(
                    without(
                      esignature,
                      "file",
                      "_created",
                      "_modified",
                      "_removed"
                    )
                  );
                }
                newGraph.nodes[id] = {
                  ...oldNode,
                  esignatures,
                  _touch: +new Date(),
                };
              } else {
                newGraph.nodes[id] = { ...oldNode, name, _touch: +new Date() };
              }
            }
            applyUpdate({ ns }, zone, newGraph, clean);
          } catch (e) {
            console.log(e);
          } finally {
            if (unlock) {
              unlock();
            }
          }
        }
        //true
      )
    ),
    expireZones: internal(
      decorate(
        async (
          { payload },
          { dispatch, getState, ensureLoaded, applyUpdate }
        ) => {
          const {
            actions: { consume /*, distribute*/ },
          } = module;
          let to = null;
          let flag = false;
          const f = async () => {
            if (!flag) {
              try {
                flag = true;
                if (to) {
                  clearTimeout(to);
                }
                const expiredZones = await getExpiredZones();
                for (let zone of expiredZones) {
                  const ns = await hash(zone.org);
                  await ensureLoaded(zone.id);
                  let unlock;
                  try {
                    unlock = await lockPartial(zone.id);
                    const { graph } = getState();
                    zone = graph.zones[zone.id];
                    delete zone.expirationDate;
                    await expireZone(zone);

                    const groups = getGroupsFromZone(graph, zone.id)
                      .filter(
                        ({ id }) =>
                          id != zone.rootGroup && id != zone.virtualRootGroup
                      )
                      .map((group) => group.id);

                    const nodes = getNodesFromZone(graph, zone.id)
                      .filter((node) => !!node.parent)
                      .map((group) => group.id);

                    applyUpdate(
                      { ns },
                      zone.id,
                      { zones: { [zone.id]: zone } },
                      { groups, nodes }
                    );
                  } finally {
                    if (unlock) {
                      unlock();
                    }
                  }
                }
                const ms = await getNextExpire();
                to = setTimeout(f, Math.max(1000, Math.min(ms, 2 ** 31 - 1)));
              } catch (e) {
                console.log("server/expireZone", e);
              } finally {
                flag = false;
              }
            }
          };
          onSetExpire(f);
          f();
        }
        //true
      )
    ),
    initialize: decorate(async ({ payload }, { dispatch }) => {
      const {
        actions: { response /*intermediate*/ },
      } = module;
      const { accessToken, ping: pong } = payload;
      const { user } = trial(jwtPeek)(accessToken);
      const result = await getInitSpace(user);
      return /*intermediate*/ response({ ...result, ok: true, pong });
    }),
    bumpZone: decorate(async ({ payload }) => {
      const { id, accessToken } = payload;
      const { user } = trial(jwtPeek)(accessToken);
      bumpMember(id, user);
    }),
    fetchZones: decorate(async ({ payload }, { dispatch }) => {
      const {
        actions: { consume },
      } = module;
      const {
        category,
        comparator,
        filter,
        quantity,
        previousId,
        templates,
        accessToken,
        ping: pong,
      } = payload;
      const { user } = jwtPeek(accessToken);
      const { graph, next } = await getZones(
        user,
        comparator,
        filter,
        quantity,
        previousId,
        category,
        templates
      );
      return consume({ graph, next, pong });
    }),
    fetchZoneFlat: decorate(async (action, { ensureLoaded, respondGraph }) => {
      const { id } = action.payload;
      await ensureLoaded(id);
      respondGraph(action, id);
    }),
    fetchZoneDeep: decorate(async (action, { ensureLoaded, respondGraph }) => {
      const { id } = action.payload;
      await ensureLoaded(id);
      respondGraph(action, id, null, null, true);
    }),
    fetchZoneForeign: decorate(
      async (action, { getState, permissions, ensureLoaded, respondGraph }) => {
        const {
          actions: { response, error, consume },
        } = module;
        if (!permissions[A.TEAM_TEAMMATE_ADD_OTHER]) {
          return error({ reason: "UNAUTHORIZED" });
        }
        const { id, ping: pong, accessToken } = action.payload;
        await ensureLoaded(id);
        const graph = getState().graph;
        const newGraph = {
          zones: { [id]: { ...graph.zones[id], foreign: true } },
          groups: idListToMap(getGroupsFromZone(graph, id)),
          users: idListToMap(getUsersFromZone(graph, id)),
        };
        return consume({ graph: newGraph, pong });
      }
    ),
    fetchUserById: decorate(async (action, { dispatch }) => {
      const {
        actions: { response },
      } = module;
      const { id, ping: pong, accessToken } = action.payload;
      const { org } = trial(jwtPeek)(accessToken);
      let user = await getUserFromId(id);
      //user = user && { ...without(user, "id"), user: user.id };
      if (user) {
        const { id, ...rest } = user;
        user = { ...rest, user: user.id };
      }
      return response({ user, pong });
    }),
    fetchUserByUri: decorate(async (action, { dispatch }) => {
      const {
        actions: { response, error },
      } = module;
      let { uri, fquri, ping: pong, accessToken } = action.payload;
      const { org } = trial(jwtPeek)(accessToken);
      if (fquri) {
        const { space } = parseUri(fquri);
        if (space != org) {
          return error({ reason: "UNAUTHORIZED" });
        }
      } else {
        fquri = `${org};${uri}`;
      }
      let user = await getUserFromUri(fquri);
      if (user) {
        const { id, ...rest } = user;
        user = { ...rest, user: user.id };
      }
      return response({ user, pong });
    }),
    fetchPlaceholderUsers: decorate(async (action, { dispatch }) => {
      const {
        actions: { response },
      } = module;
      const { email, ping: pong, accessToken } = action.payload;
      const { org } = trial(jwtPeek)(accessToken);
      let users = await getPlaceholderUsers(org, email);
      users = users.map((user) => {
        const { id, ...rest } = user;
        return { ...rest, user: user.id };
      });
      return response({ users, pong });
    }),
    fetchNode: decorate(async (action, { ensureLoaded, respondGraph }) => {
      let { id, zoneId } = action.payload;
      if (!zoneId) {
        zoneId = await getZoneFromNode(id);
      }
      await ensureLoaded(zoneId);
      respondGraph(action, zoneId, id);
    }),
    fetchGroupNodes: decorate(
      async (action, { ensureLoaded, respondGraph }) => {
        const { zoneId, id } = action.payload;
        await ensureLoaded(zoneId);
        respondGraph(action, zoneId, null, id);
      }
    ),
    fetchBranch: decorate(async (action, { ensureLoaded, respondGraph }) => {
      const { zoneId, id } = action.payload;
      await ensureLoaded(zoneId);
      respondGraph(action, zoneId, id, null, true);
    }),
    fetchLeafPath: decorate(async (action, { ensureLoaded, respondGraph }) => {
      const { zoneId, id } = action.payload;
      await ensureLoaded(zoneId);
      respondGraph(action, zoneId, id, null, null, true);
    }),
    createUser: decorate(async (action, { permissions }) => {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.TEAM_TEAMMATE_ADD]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const {
        deviceId,
        provider,
        wellknown,
        name,
        email,
        roleId,
        extraAuthenticatorArgs,
      } = action.payload;

      const { ping: pong, accessToken } = action.payload;
      const { org, user: userId } = trial(jwtPeek)(accessToken);

      if (provider == "placeholder") {
        const [user] = await getPlaceholderUsers(org, email);
        if (user) {
          return response({ reason: "OUT_OF_SYNC", pong });
        }
      } else {
        const fquri = `${org};${provider}:${wellknown.join("|")}`;
        const user = await getUserFromUri(fquri);
        if (user) {
          return response({ reason: "OUT_OF_SYNC", pong });
        }
      }

      let result;

      try {
        result = await request(
          `${API_URL_PREFIX}/createUser`,
          json(
            {
              deviceId,
              roleId,
              email,
              wellknown,
              provider,
              name: name || makeshiftName({ provider, wellknown, email }),
              extraAuthenticatorArgs,
              orgId: org,
            },
            {
              userId,
              accessToken,
            }
          )
        );
      } catch (e) {
        // TODO LOG! failure regardless of sanity checks, might cause divergence
        if (e == SERVER_DENIED_PERMISSION) {
          return error({ reason: e, pong });
        } else {
          return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
        }
      }

      const user = {
        ...pick(result.user, "deprecatedId", "name", "provider", "wellknown"),
        user: result.user.id,
        org: result.user.org.id,
        role: result.user.role.id,
      };

      return response({ user, pong });
    }),
    createZone: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.TEAM_CREATE]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const {
        flag,
        name,
        virtualRootGroupId,
        rootGroupId,
        zoneId: zoneIdToCopy,
        copyFiles,
        copyFolders,
        copyMembers,
        copyMessages,
        groupIds,
        isTemplate,
        ping: pong,
        accessToken,
      } = action.payload;

      // load state
      if (zoneIdToCopy && (copyFolders || copyMessages || copyMembers)) {
        await ensureLoaded(zoneIdToCopy);
      }

      // validate
      const validate = () => {
        if (zoneIdToCopy && (copyFolders || copyMessages || copyMembers)) {
          const state = getState();
          const zone = state.graph.zones[zoneIdToCopy];
          if (!zone) {
            // create zone by copy: no such zone
            return response({ reason: "OUT_OF_SYNC", pong });
          }
        }
      };

      const e = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ timeout: true, pong });
        } else if ("rejected" == action.att) {
          return response({ rejected: true, pong });
        }
      }

      let unlock;
      try {
        unlock = zoneIdToCopy && (await lockPartial(zoneIdToCopy));

        const e = validate();
        if (e) {
          return e;
        }

        let id;
        try {
          ({ id } = await cloneZone(
            config,
            accessToken,
            name,
            rootGroupId,
            virtualRootGroupId,
            zoneIdToCopy,
            {
              copyFiles,
              copyFolders,
              copyMembers,
              copyMessages,
              isTemplate,
            },
            groupIds
          ));
        } catch (e) {
          if (e?.toString().toLowerCase().includes("provide a team name")) {
            return response({ reason: "NO_NAME", pong });
          }
          if (e?.toString().toLowerCase().includes("project already exists")) {
            return response({ reason: "BAD_NAME", pong });
          }
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }

        const graph = await getZoneFlat(id);
        applyUpdate(action, id, graph, null, true, undefined, true);
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    addMember: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      const { zoneId, userId, flag, ping: pong, accessToken } = action.payload;
      const { user: originId } = trial(jwtPeek)(accessToken);

      if (!zoneId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const zone = state.graph.zones[zoneId];

        if (!zone) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        const groupId = zone.rootGroup;
        const members = getUsersFromGroup(state.graph, groupId);
        const isMember = members.some((m) => m.user == userId);
        const byInsider = members.some((m) => m.user == originId);

        if (byInsider) {
          if (!permissions[A.TEAM_TEAMMATE_ADD]) {
            return [error({ reason: "UNAUTHORIZED" })];
          }
        } else if (!permissions[A.TEAM_TEAMMATE_ADD_OTHER]) {
          return [error({ reason: "UNAUTHORIZED" })];
        }

        if (isMember) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        return [, groupId];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, groupId] = validate();
        if (e) {
          return e;
        }

        try {
          const memberId = uid();

          const result = await api
            .token(accessToken)
            .members(memberId)
            .post({
              id: memberId,
              group: { id: groupId },
              user: { id: userId },
            });

          const newGraph = {
            users: {
              [result.id]: {
                ...pick(
                  result.user,
                  "deprecatedId",
                  "name",
                  "email",
                  "provider",
                  "wellknown"
                ),
                role: result.role.id,
                member: result.id,
                user: result.user.id,
                group: result.group.id,
              },
            },
          };

          applyUpdate(action, zoneId, newGraph, null, false, result.user.id);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    removeMember: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      const { zoneId, userId } = action.payload;
      const { flag, ping: pong, accessToken } = action.payload;
      const {
        user: originId,
        org,
        permissions: _permissions,
      } = trial(jwtPeek)(accessToken);

      const roles = await getRoles(org);

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const zone = state.graph.zones[zoneId];

        if (userId == originId) {
          if (!permissions[A.TEAM_TEAMMATE_LEAVE]) {
            return [error({ reason: "UNAUTHORIZED" })];
          }
        } else if (!permissions[A.TEAM_TEAMMATE_REMOVE]) {
          return [error({ reason: "UNAUTHORIZED" })];
        }

        const groupIds = getGroupsFromZone(state.graph, zoneId).map(
          ({ id }) => id
        );
        const members = groupIds.flatMap((id) =>
          getUsersFromGroup(state.graph, id)
        );
        const removers = members.filter((member) => member.user == originId);
        const removees = members.filter((member) => member.user == userId);
        const remainers = members.filter((member) => member.user != userId);

        if (userId === originId && remainers.length) {
          const CAPTAIN_ORGS = ["hykersec", "mpgnobhtest1", "manpowergroupno"];

          if (CAPTAIN_ORGS.includes(org)) {
            const originRole = _permissions?.roles?.global;
            const roleMap = roles.reduce(
              (a, c) => ({ ...a, [c.id]: c.privilegeOrder }),
              {}
            );
            //if (permissions[A.TEAM_LEAVE_IN_ROLE_ORDER]) {
            if (originRole && roleMap[originRole] > 100) {
              const originRoleOrder = roleMap[originRole];
              const captain = remainers.some(
                (user) => roleMap[user.role] <= originRoleOrder
              );
              if (!captain) {
                return [response({ reason: "ADVERSE_EFFECT", pong })];
              }
            }
          }
        }

        const emptyGroups = groupIds.filter(
          (id) => !remainers.some((m) => m.group == id)
        );

        const isEmptyZone = !groupIds.some((id) => !emptyGroups.includes(id));

        if (!removees.length) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        if (removees.some((a) => !removers.some((b) => a.group == b.group))) {
          // The user you are about to remove has access to folders which you are
          // not a part of. Removing the user might lead to irreversible data loss
          return [response({ reason: "ADVERSE_EFFECT", pong })];
        }

        if (userId == originId) {
          const rootMembers = getUsersFromGroup(state.graph, zone.rootGroup);
          const virtuals = getUsersFromGroup(
            state.graph,
            zone.virtualRootGroup
          );
          if (virtuals.every(({ user }) => user == originId)) {
            // all members must be the remover for the team to be deleted,
            // otherwise this might lead to irreversible data loss
            if (rootMembers.some(({ user }) => user != originId)) {
              //return { fail: true, lost: true };
              return [response({ reason: "ADVERSE_EFFECT", pong })];
            }
          }
        }

        return [, removees, emptyGroups, isEmptyZone];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, removees, emptyGroups, isEmptyZone] = validate();
        if (e) {
          return e;
        }

        try {
          if (userId == originId) {
            await request(
              `${API_URL_PREFIX}/team/leave`,
              json({ teamId: zoneId }, { userId: originId, accessToken })
            );
          } else {
            for (const { member } of removees) {
              const result = await api
                .token(accessToken)
                .members(member)
                .delete();
            }
          }
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (
            e
              ?.toString()
              .toLowerCase()
              .startsWith("unrestrict team or allow another user in")
          ) {
            return response({ reason: "ADVERSE_EFFECT", pong });
          }
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }

        const clean = {
          groups: emptyGroups,
          users: removees.map((member) => member.member),
          ...(isEmptyZone && { zones: [zoneId] }),
        };

        applyUpdate(action, zoneId, {}, clean);
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    createNode: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      //let t0 = new Date();
      const {
        actions: { response, error },
      } = module;

      let {
        zoneId,
        name,
        groupId,
        parentId,
        storageId,
        version,
        id,
        index,
        meta,
        update,
        flag,
        ping: pong,
        accessToken,
      } = action.payload;

      //console.log("THIS SHOULD BE UNDEFINED, AT LEAST NOT AN INTEGER", index);

      const isFile = !!storageId;

      // load state
      await ensureLoaded(zoneId);

      // validate
      const validate = () => {
        if (isFile) {
          if (!permissions[A.FILE_CREATE]) {
            return [error({ reason: "UNAUTHORIZED" })];
          }
        } else {
          if (!permissions[A.FOLDER_CREATE]) {
            return [error({ reason: "UNAUTHORIZED" })];
          }
        }
        const state = getState();
        const parent = state.graph.nodes[parentId];

        if (!parent || parent.group != groupId) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }
        return [, state.graph];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        //console.log("lock", zoneId, "free?", !!locks.get(zoneId)?.free());
        //let t0 = new Date();
        unlock = await lockPartial(zoneId);
        //console.log("---------- took lock in", new Date - t0, "ms");

        const [e, graph] = validate();
        if (e) {
          return e;
        }

        try {
          let node,
            nodes = {};
          if (update) {
            if (!isInteger(index)) {
              index = 0;
            }
            const { key, size } = meta;
            const result = await api
              .token(accessToken)
              .files(id)
              .patch({
                timestamp: +new Date(),
                version,
                meta: {
                  key: key,
                  size,
                },
              });
            node = {
              ...pick(
                result,
                "id",
                "name",
                "storageId",
                "version",
                "timestamp",
                "index",
                "meta"
              ),
              creator: result.creator.id,
              group: result.group.id,
              parent: result.parent.id,
            };
          } else {
            if (!isInteger(index)) {
              index = 0;
              let siblings = values(graph.nodes).filter(
                (n) => n.parent == parentId
              );
              if (siblings.length) {
                index = Math.min(...siblings.map((n) => n.index)) - 1;
              }
            }
            //index = -1000;
            //let t0 = new Date();
            //console.log("INDEX BEFORE", index)
            node = await createFile(
              api,
              accessToken,
              name,
              groupId,
              parentId,
              storageId,
              version,
              id,
              index,
              meta
            );
            //console.log("INDEX AFTER", node.index)
            //console.log("---------- create node took", new Date - t0)
            nodes = filterObj(graph.nodes, (_, n) => n.parent == node.parent);
            // node needs to be inserted here in order for move to work
            nodes[node.id] = node;
            // TODO must rebalance indexes
            // must update moveNodeInGraph to do the new way with negative
            // sibling nodes other than the one created / moved must also get new indexed al the way to the client
            // if moving from an other parent, that old parents sibling must also get new indexed all the way to the client
            try {
              //moveNodeInGraph({ nodes }, node.id, node.parent, index);
              moveNodeInGraph({ nodes }, node.id, node.parent, node.index);
            } catch (e) {
              console.log(e);
            }
          }
          node.attestations = graph.nodes[node.id]?.attestations || [];
          node.esignatures = graph.nodes[node.id]?.esignatures || [];
          nodes[node.id] = node;
          const rootId = getRootNodeFromZone(graph, zoneId);
          const branch = [node, ...getNodeWithAscendants(graph, node.parent)];
          decorateBranch(branch, graph.nodes[rootId]);
          assign(nodes, idListToMap(branch));
          //console.log("create node before applyUpdate took", new Date - t0, "ms");
          applyUpdate(action, zoneId, { nodes });
        } catch (e) {
          if (e.code === 400) {
            return response({ reason: "BAD_NAME", pong });
          }
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    removeNode: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      const {
        zoneId,
        nodeId,
        nodeIds,
        flag,
        ping: pong,
        accessToken,
      } = action.payload;

      const { user: userId } = trial(jwtPeek)(accessToken);

      // load state
      await ensureLoaded(zoneId);

      // validate
      const validate = (errorOnly = false) => {
        const { graph } = getState();
        const node = graph.nodes[nodeId];

        if (!userId || !node) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        const isOwn = node.creator == userId;
        const isFile = !!node.storageId;

        if (isFile) {
          if (!permissions[A.FILE_DELETE]) {
            if (!isOwn || !permissions[A.FILE_DELETE_OWN]) {
              return [error({ reason: "UNAUTHORIZED" })];
            }
          }
        } else {
          if (!permissions[A.FOLDER_DELETE]) {
            if (!isOwn || !permissions[A.FOLDER_DELETE_OWN]) {
              return [error({ reason: "UNAUTHORIZED" })];
            }
          }
        }

        // personal will contain a graph will all nodes in zone that the user is
        // related to via some group, including graft branches (however they
        // will have had their parent attribute removed).
        let personal = getPersonalState(graph, [zoneId], [userId], true);

        personal = personal[userId];

        // the user might no longer have access to the node
        if (!(node.id in personal.nodes)) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        // Cannot delete a root group root file or a graft branch root file
        // (such nodes will have had their parent removed).
        if (!personal.nodes[node.id]?.parent) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        if (errorOnly) {
          return [];
        }

        // usersNodes will not contain any graft branches, since graft brances
        // will lack a parent attribute ( and they wont be able to be visited
        // going from node.id)
        const usersNodes = getNodeWithDescendants(personal, node.id).map(
          (node) => node.id
        );

        // descendants will be the true branch that starts with node.id,
        // regardless of users personal view and graft branches will for this
        // reason not exist
        const descendants = getNodeWithDescendants(graph, node.id).map(
          (node) => node.id
        );

        // building a map of parents pointing to their kids, to help optimizing
        // down the line
        const parents = {};
        for (const node of descendants) {
          const parent = graph.nodes[node].parent;
          if (parent) {
            if (parent in parents) {
              parents[parent].push(node);
            } else {
              parents[parent] = [node];
            }
          }
        }

        // we also at some point needs the siblings of the node.id, and we happen
        // to have them convniently in the personal state graph
        parents[node.parent] = values(personal.nodes)
          .filter((n) => n.parent == node.parent)
          .map((node) => node.id);

        // nodesToBeDeleted are nodes that can be found both in the list of
        // nodes requested to be removed, and also found on the requesters
        // personal node graph. note that graft branches wont be included,
        // and this is because of the fact that from the requesters perspective
        // graft branches are strictly not contained in node.id, since they are
        // always top level nodes (hoverever a graft branch may contain node.id)
        let nodesToBeDeleted = intersect(nodeIds, usersNodes);

        // some node in nodesToBeDeleted may in reality contain a branch
        // invisible to the requester. in this case we cannot deleted that
        // node since it would lead to the automatic deletion of nodes that
        // the requester is not aware of. For this reason we need to know which
        // nodes to keep in order to use them for filtering out nodes needed to
        // reach those that sould be kept.
        const nodesNotToBeDeleted = diff(descendants, nodesToBeDeleted);

        // Now we will filter out nodes that are need to reach other nodes that
        // not should be deleted automaticly.
        let ansestorsOfRemaining = new Set();
        for (let nodeId of nodesNotToBeDeleted) {
          while (true) {
            nodeId = graph.nodes[nodeId]?.parent;
            if (!nodeId || ansestorsOfRemaining.has(nodeId)) {
              break;
            }
            ansestorsOfRemaining.add(nodeId);
          }
        }

        ansestorsOfRemaining = [...ansestorsOfRemaining];

        // This flag will be used to tell the client that all nodes requested
        // were in fact deleted
        const partial = !!intersect(nodesToBeDeleted, ansestorsOfRemaining)
          .length;

        // the very filter described above
        nodesToBeDeleted = diff(nodesToBeDeleted, ansestorsOfRemaining);

        // if node.id is not longer part if nodesToBeDeleted, it must be
        // replace with a list of top level nodes do be reomved apart from
        // node.id
        const nodesToBeActuallyDeleted = [];
        const map = { ...parents };
        const kids = [node.id];
        for (const kid of kids) {
          if (nodesToBeDeleted.includes(kid)) {
            nodesToBeActuallyDeleted.push(kid);
          } else if (kid in map) {
            kids.push(...map[kid]);
            delete map[kid];
          }
        }

        ////// Filter out folder nodes from nodesToBeDeleted that are needed to
        ////// reach nodes not to be deleted.
        ////// Should be circular dependecy proof.
        ////for (let nodeId of nodesNotToBeDeleted) {
        ////  const nodes = { ...state.graph.nodes };
        ////  while (nodeId in nodes && nodeId != node.id) {
        ////    const index = nodesToBeDeleted.indexOf(nodes[nodeId].parent);
        ////    if (index != -1) {
        ////      partial = true;
        ////      nodesNotToBeDeleted.push(nodesToBeDeleted.splice(index, 1)[0]);
        ////      //console.log("this folder must not be delted!", nodes[nodeId].parent);
        ////    }
        ////    nodeId = nodes[nodeId].parent;
        ////    delete nodes[nodeId];
        ////  }
        ////}

        ////console.log("nodesToBeDeleted", nodesToBeDeleted)
        ////console.log("nodesNotToBeDeleted", nodesNotToBeDeleted)

        ////// Not used but "lat sta" for clarity.
        ////const nodesToBeDeletedAuto = [];

        ////// Files does not need to be deleted if an ansestor folder is.
        ////for (let nodeId of nodesToBeDeleted) {
        ////  const nodes = { ...state.graph.nodes };
        ////  while (nodeId in nodes && nodeId != node.id) {
        ////    const index = nodesToBeDeleted.indexOf(nodes[nodeId].parent);
        ////    if (index != -1) {
        ////      console.log("this file will be deleted auto!", nodeId, "because of this will be removed", nodes[nodeId].parent);
        ////      console.log("the auto deleted one you will find at index", index, "in", nodesToBeDeleted)
        ////      nodesToBeDeletedAuto.push(nodesToBeDeleted.splice(index, 1)[0]);
        ////      console.log("now the same list look like this after the AUTO DELETED have been moved", nodesToBeDeleted);
        ////    }
        ////    nodeId = nodes[nodeId].parent;
        ////    delete nodes[nodeId];
        ////  }
        ////}
        ////
        ////let groupsToBeDeleted = [
        ////  ...new Set(
        ////    nodesToBeDeleted
        ////      .map(nodeId => state.graph.nodes[nodeId].group)
        ////      .filter(groupId => groupId)
        ////  )
        ////];

        ////if (node.id != state.graph.groups[node.group].rootFile) {
        ////  groupsToBeDeleted = groupsToBeDeleted.filter(id => id != node.group);
        ////}

        let groupsToBeDeleted = [];

        for (const nodeId of nodesToBeDeleted) {
          if (nodeId == graph.groups[graph.nodes[nodeId].group].rootFile) {
            groupsToBeDeleted.push(nodeId);
          }
        }

        //console.log("nodesToBeDeleted", nodesToBeDeleted)
        //console.log("nodesNotToBeDeleted", nodesNotToBeDeleted)
        //console.log("nodesToBeActuallyDeleted", nodesToBeActuallyDeleted)
        //console.log("groupsToBeDeleted", groupsToBeDeleted);

        //console.log("users nodes:", usersNodes);
        //console.log("nodes to be deleted:", nodesToBeDeleted)
        //console.log("nodes NOT to be deleted:", nodesNotToBeDeleted);
        //console.log("someFolderCouldNotBeRemoved", someFolderCouldNotBeRemoved);
        //console.log("groupsToBeDeleted", groupsToBeDeleted)

        return [
          ,
          graph,
          node,
          parents,
          nodesToBeDeleted,
          nodesToBeActuallyDeleted,
          groupsToBeDeleted,
          partial,
        ];
      };

      const [e] = validate(true);
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [
          e,
          graph,
          node,
          parents,
          nodesToBeDeleted,
          nodesToBeActuallyDeleted,
          groupsToBeDeleted,
          partial,
        ] = validate();
        if (e) {
          return e;
        }

        try {
          //console.log("@@@@@", "nodesToBeDeleted", nodesToBeDeleted);

          //console.log("nodesToBeDeleted.length", nodesToBeDeleted.length);
          for (const nodeId of nodesToBeActuallyDeleted) {
            //let t0 = new Date;
            await api.token(accessToken).files(nodeId).delete();
            //console.log("delete file took", new Date - t0)
          }

          //console.log("@@@@@", "groupsToBeDeleted", groupsToBeDeleted);

          try {
            for (const groupId of groupsToBeDeleted) {
              await api.token(accessToken).groups(groupId).delete();
            }
          } catch (e) {
            // TODO given the below, is it even nessesary to delete groups? how was this done before?
            console.log(
              "this should be ok since deletion of the last node in group deletes the group",
              e
            );
          }

          //const siblings = values(graph.nodes).filter(
          //  n => n.id != node.id && n.parent == node.parent
          //);
          //const siblings = values(graph.nodes).filter(
          //  n => !nodesToBeDeleted.includes(n.id) && nodesToBeDeleted.includes(n.parent)
          //);
          //rebalanceIndices(siblings);

          const siblings = [];

          for (const nodeId of nodesToBeDeleted) {
            const node = graph.nodes[nodeId];
            if (node && node.parent && node.parent in parents) {
              let kids = diff(parents[node.parent], nodesToBeDeleted);
              if (kids.length) {
                kids = kids.map((kid) => graph.nodes[kid]);
                rebalanceIndices(kids);
                siblings.push(...kids);
              }
            }
          }

          //console.log("siblings", siblings);

          const upsert = { nodes: idListToMap(siblings) };
          //const remove = { nodes: [node.id] };
          const remove = { nodes: nodesToBeDeleted };

          // TODO: might need to cascade delete nodes and possibly groups and users too
          // TODO: piggyback the partial flag to the client for him to show a message
          applyUpdate(action, zoneId, upsert, remove);
        } catch (e) {
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }

      //let nodeToDelete;

      //try {
      //  nodeToDelete = await api
      //    .token(accessToken)
      //    .files(node.id)
      //    .get({
      //      fields: {
      //        id: true,
      //        meta: true,
      //        name: true,
      //        index: true,
      //        group: true,
      //        storageId: true,
      //        version: true,
      //        timestamp: true,
      //        parent: true,
      //        files: {
      //          "*": true
      //        }
      //      }
      //    });
      //} catch (e) {
      //  // this file contains files which you dont have access to
      //  return response({ reason: "ADVERSE_EFFECT", pong });
      //}

      //const getKeyIds = (node, includeGroups) => {
      //  if (node.meta && node.meta.key && node.group.id in includeGroups) {
      //    return [{ keyId: node.meta.key, groupId: node.group.id }];
      //  } else {
      //    return node.files.flatMap(kid => getKeyIds(kid, includeGroups));
      //  }
      //};

      //const getGroupIds = (node, groups) => {
      //  if (!groups.includes(node.group.id)) {
      //    groups.push(node.group.id);
      //  }
      //  for (const kid of node.files || []) {
      //    getGroupIds(kid, groups);
      //  }
      //  return groups;
      //};

      //const partOfGroups = {};
      //const notPartOfGroups = {};
      //const groupIds = getGroupIds(nodeToDelete, []);

      //for (const groupId of groupIds) {
      //  try {
      //    const groupThatImMemberOf = await api
      //      .token(accessToken)
      //      .groups(groupId)
      //      .get({
      //        fields: {
      //          rootFile: true
      //        }
      //      });
      //    partOfGroups[groupId] = groupThatImMemberOf;
      //  } catch (e) {
      //    notPartOfGroups[groupId] = { id: groupId };
      //  }
      //}

      //const orphanKeys = getKeyIds(nodeToDelete, partOfGroups);
      //const isRootFileOfGroup = (node, group) => group.rootFile.id === node.id;

      //let groupsToDelete = groupIds;
      //if (
      //  !isRootFileOfGroup(nodeToDelete, partOfGroups[nodeToDelete.group.id])
      //) {
      //  groupsToDelete = groupsToDelete.filter(
      //    groupId => groupId != nodeToDelete.group.id
      //  );
      //}

      //if (orphanKeys.length) {
      //  yield response({ orphanKeys, pong });
      //  const action = await dispatch(takeFlag(flag));
      //  if ("_timeout" in action) {
      //    return response({ reason: "TIMEOUT", pong });
      //  } else if ("rejected" == action.att) {
      //    return response({ reason: "REJECTED", pong });
      //  }
      //}

      //await api
      //  .token(accessToken)
      //  .files(node.id)
      //  .delete();

      //const deleteGroup = groupId =>
      //  api
      //    .token(accessToken)
      //    .groups(groupId)
      //    .delete();

      //await all(...groupsToDelete.map(deleteGroup));

      //// TODO: might need to cascade delete nodes and possibly groups and users too
      //applyUpdate(action, zoneId, {}, { nodes: [node.id] });
    }),
    moveNode: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      let {
        zoneId,
        nodeId,
        parentId,
        index,
        nodeIds,
        flag,
        ping: pong,
        accessToken,
      } = action.payload;

      const { user: userId } = trial(jwtPeek)(accessToken);

      if (!isInteger(index)) {
        index = 0;
      }

      // load state
      await ensureLoaded(zoneId);

      // validate
      const validate = () => {
        const state = getState();
        const node = state.graph.nodes[nodeId];
        const parent = state.graph.nodes[parentId];
        const group = node && state.graph.groups[node.group];

        if (!node || !parent || !group) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        const isOwn = node.creator == userId;
        const isFile = !!node.storageId;

        if (isFile) {
          if (!permissions[A.FILE_MOVE]) {
            if (!isOwn || !permissions[A.FILE_MOVE_OWN]) {
              return [error({ reason: "UNAUTHORIZED" })];
            }
          }
        } else {
          if (!permissions[A.FOLDER_MOVE]) {
            if (!isOwn || !permissions[A.FOLDER_MOVE_OWN]) {
              return [error({ reason: "UNAUTHORIZED" })];
            }
          }
        }

        if (node.index == index && node.parent == parentId) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        // if should change group
        const swap = node.group != parent.group && group.rootFile != node.id;

        // if should change group, nodeIds (descendants) should be specified
        if (!swap != !nodeIds) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        let nodesToSwapGroup = [],
          addedInParallel = [],
          removedInParallel = [];

        if (swap) {
          const descendants = getNodeGroupDescendants(state.graph, node.id).map(
            (node) => node.id
          );
          nodesToSwapGroup = intersect(descendants, nodeIds);
          addedInParallel = diff(descendants, nodeIds);
          // not used but "lat sta" for clarity
          removedInParallel = diff(nodeIds, descendants);
        }

        return [
          ,
          state.graph,
          node,
          parent,
          swap,
          nodesToSwapGroup,
          addedInParallel,
        ];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [
          e,
          graph,
          node,
          parent,
          swap,
          nodesToSwapGroup,
          addedInParallel,
        ] = validate();
        if (e) {
          return e;
        }

        let name = "";
        while (true) {
          try {
            //let t0 = new Date;
            //console.log({ name, swap, nodesToSwapGroup: nodesToSwapGroup.length }, +new Date);
            await api
              .token(accessToken)
              .files()
              .patch([
                {
                  id: node.id,
                  parent: { id: parent.id },
                  index,
                  ...(name ? { name } : {}),
                  ...(swap ? { group: { id: parent.group } } : {}),
                },
                ...nodesToSwapGroup.map((nodeId) => ({
                  id: nodeId,
                  group: { id: parent.group },
                })),
              ]);
            //console.log("api patch took", new Date - t0);

            // TODO reconstruct tree for addedInParallel.
            //   Make a copy of the tree branch (with new node ids)
            //   so that all added nodes are reachable.

            const nodes = filterObj(
              graph.nodes,
              (id, n) =>
                id == node.id ||
                n.parent == node.parent ||
                n.parent == parent.id ||
                nodesToSwapGroup.includes(id)
            );

            //assign(nodes, idListToMap(descendants));

            // TODO must renalance old parents kids aswell if move to new parent
            // moveNodeInGrah will take care of this but nodes must contain them

            moveNodeInGraph({ nodes }, node.id, parent.id, index);

            if (name) {
              node.name = name;
            }

            if (swap) {
              node.group = parent.group;
            }

            //for (const node of descendants) {
            //  node.group = parent.group;
            //}

            for (const id of nodesToSwapGroup) {
              nodes[id].group = parent.group;
            }

            applyUpdate(action, zoneId, { nodes });
          } catch (e) {
            if (e.code === 409) {
              name = await resolveNameConflict2(
                //api,
                //action.payload.accessToken,
                name || node.name,
                parentId
              );
              continue;
            }
            if (e == SERVER_DENIED_PERMISSION) {
              return error({ reason: e, pong });
            } else {
              return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
            }
          }
          break;
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    restrictZone: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.TEAM_RESTRICT]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const { zoneId, groupId, flag, ping: pong, accessToken } = action.payload;
      const { user: userId } = trial(jwtPeek)(accessToken);

      if (!zoneId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const zone = state.graph.zones[zoneId];

        if (!zone || zone.virtualRootGroup != zone.rootGroup) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        return [, state.graph, zone];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, graph, zone] = validate();
        if (e) {
          return e;
        }

        try {
          const result = await request(
            `${API_URL_PREFIX}/restrictteam`,
            json(
              {
                teamId: zoneId,
                newRootGroupId: groupId,
              },
              {
                userId,
                accessToken,
              }
            )
          );

          //console.log(
          //  "RESULT",
          //  result.team.rootGroup.id,
          //  result.group.rootFile.id,
          //  result.group.members
          //);

          const users = getUsersFromGroup(graph, zone.rootGroup).reduce(
            (acc, cur) => ({ ...acc, [cur.user]: cur }),
            {}
          );

          const newUsers = {};

          for (const member of result.group.members) {
            newUsers[member.id] = {
              ...users[member.user.id],
              member: member.id,
              group: member.group.id,
            };
          }

          //console.log(newUsers);

          const newGraph = {
            zones: {
              [zone.id]: {
                ...zone,
                rootGroup: result.team.rootGroup.id,
              },
            },
            groups: {
              [result.group.id]: {
                id: result.group.id,
                rootFile: result.group.rootFile.id,
                zone: zoneId,
              },
            },
            users: newUsers,
          };

          applyUpdate(action, zoneId, newGraph);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    unrestrictZone: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.TEAM_UNRESTRICT]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const { zoneId, flag, ping: pong, accessToken } = action.payload;
      const { user: userId } = trial(jwtPeek)(accessToken);

      if (!zoneId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const zone = state.graph.zones[zoneId];

        if (!zone || zone.virtualRootGroup == zone.rootGroup) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        return [, state.graph, zone];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, graph, zone] = validate();
        if (e) {
          return e;
        }

        try {
          const result = await request(
            `${API_URL_PREFIX}/unrestrictteam`,
            json(
              {
                teamId: zoneId,
              },
              {
                userId,
                accessToken,
              }
            )
          );

          const members = getUsersFromGroup(graph, zone.rootGroup);
          const virtual = getUsersFromGroup(graph, zone.virtualRootGroup);

          const users = members
            .filter((a) => !virtual.some((b) => a.user == b.user))
            .reduce((acc, cur) => ({ ...acc, [cur.user]: cur }), {});

          const newUsers = {};

          // TODO can i use member.id here?

          for (const member of result) {
            newUsers[member.id] = {
              ...users[member.user],
              member: member.id,
              group: member.group,
            };
          }

          const newGraph = {
            zones: {
              [zone.id]: {
                ...zone,
                rootGroup: zone.virtualRootGroup,
              },
            },
            users: newUsers,
          };

          const clean = {
            groups: [zone.rootGroup],
            users: members.map((member) => member.member),
          };

          applyUpdate(action, zoneId, newGraph, clean);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    restrictFolder: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.FOLDER_RESTRICT]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const {
        zoneId,
        nodeId,
        newGroupId,
        nodeIds,
        memberIds,
        flag,
        ping: pong,
        accessToken,
      } = action.payload;

      const { user: userId } = trial(jwtPeek)(accessToken);

      if (!zoneId || !nodeId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const node = state.graph.nodes[nodeId];
        const group = node && state.graph.groups[node.group];

        if (!newGroupId || !node || !group || group.rootFile == node.id) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        let members = getUsersFromGroup(state.graph, group.id);
        let descendants = getNodeWithGroupDescendants(state.graph, node.id).map(
          (node) => node.id
        );

        const addedInParallel = diff(descendants, nodeIds);

        members = members.filter((m) => memberIds.includes(m.member));
        descendants = intersect(descendants, nodeIds);

        return [, state.graph, members, descendants, addedInParallel];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, graph, members, descendants, addedInParallel] = validate();
        if (e) {
          return e;
        }

        try {
          const newMembers = members.map((member) => ({
            id: uid(),
            user: {
              id: member.user,
            },
            group: {
              id: newGroupId,
            },
          }));

          const newNodes = descendants.map((id) => ({
            id,
            group: { id: newGroupId },
          }));

          //console.log(newMembers);
          //console.log(newNodes);

          //console.log({
          //  id: newGroupId,
          //  //members: newMembers,
          //  //files: [],
          //  rootFile: { id: nodeId },
          //  team: { id: zoneId }
          //});

          await api
            .token(accessToken)
            .groups(newGroupId)
            .post({
              id: newGroupId,
              members: newMembers,
              files: [],
              rootFile: { id: nodeId },
              team: { id: zoneId },
            });

          if (newNodes.length) {
            await api.token(accessToken).files().patch(newNodes);
          }

          // TODO reconstruct tree for addedInParallel.
          //   Make a copy of the tree branch (with new node ids)
          //   so that all added nodes are reachable.

          const newGraph = {
            groups: {
              [newGroupId]: {
                id: newGroupId,
                zone: zoneId,
                rootFile: nodeId,
              },
            },
            //nodes: branch
            //  .map(({ kids, ...node }) => ({ ...node, group: newGroupId }))
            //  .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
            nodes: descendants
              .map((id) => ({ ...graph.nodes[id], group: newGroupId }))
              .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
            users: members
              .map((member, index) => ({
                ...member,
                member: newMembers[index].id,
                group: newGroupId,
              }))
              .reduce((acc, cur) => ({ ...acc, [cur.member]: cur }), {}),
          };

          applyUpdate(action, zoneId, newGraph);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    unrestrictFolder: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.FOLDER_UNRESTRICT]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const {
        zoneId,
        nodeId,
        groupId,
        parentId,
        parentGroup,
        nodeIds,
        flag,
        ping: pong,
        accessToken,
      } = action.payload;
      const { user: userId } = trial(jwtPeek)(accessToken);

      if (!zoneId || !nodeId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const node = state.graph.nodes[nodeId];
        const group = node && state.graph.groups[node.group];
        const parent = node && state.graph.nodes[node.parent];

        if (
          !node ||
          !group ||
          !parent ||
          group.id != groupId ||
          parent.id != parentId ||
          parent.group != parentGroup ||
          group.rootFile != node.id
        ) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        //?const members = getUsersFromGroup(state.graph, group.id);

        ///const before = clone(getState(true));
        ///const parent = selectNode(before)(node.parent);
        ///let nodes = getGroupNodes(selectGraph(before), group.id);

        //const nodes = getGroupNodes(state.graph, group.id);

        let descendants = getNodeWithGroupDescendants(state.graph, node.id).map(
          (node) => node.id
        );

        const addedInParallel = diff(descendants, nodeIds);

        descendants = intersect(descendants, nodeIds);

        //const fromGroupId = group.id;
        //const toGroupId = parent.group;

        //const tree = constructTree(node, nodes);
        //const branch = deconstructTree(tree);

        return [
          ,
          state.graph,
          node,
          parent,
          group,
          descendants,
          addedInParallel,
        ];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, graph, node, parent, group, descendants, addedInParallel] =
          validate();
        if (e) {
          return e;
        }

        try {
          const newNodes = descendants.map((id) => ({
            id,
            group: { id: parent.group },
          }));

          if (newNodes.length) {
            await api
              .token(accessToken)
              .files()
              //.patch(branch.map(({ id }) => ({ id, group: { id: toGroupId } })));
              .patch(newNodes);
          }

          // TODO reconstruct tree for addedInParallel.
          //   Make a copy of the tree branch (with new node ids)
          //   so that all added nodes are reachable.

          // TODO the group should be be deleted if has addedInParallel
          await api.token(accessToken).groups(group.id).delete();

          const clean = {
            groups: [group.id],
          };

          const newGraph = {
            nodes: descendants
              .map((id) => ({ ...graph.nodes[id], group: parent.group }))
              .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
          };

          applyUpdate(action, zoneId, newGraph, clean);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),
    restrictMember: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.GROUP_TEAMMATE_REMOVE]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const { zoneId, nodeId, memberId, groupId } = action.payload;
      const { flag, ping: pong, accessToken } = action.payload;

      if (!zoneId || !nodeId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const node = state.graph.nodes[nodeId];
        const member = state.graph.users[memberId];

        if (
          !node ||
          !member ||
          node.group != groupId ||
          member.group != node.group
        ) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        return [, member];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, member] = validate();
        if (e) {
          return e;
        }

        try {
          const result = await api
            .token(accessToken)
            .members(member.member)
            .delete();

          const clean = { users: [member.member] };

          applyUpdate(action, zoneId, {}, clean);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "unknown"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
          //console.log("done with it");
        }
      }
    }),
    includeMember: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.GROUP_TEAMMATE_ADD]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      const { zoneId, nodeId, memberId, groupId, memberGroup } = action.payload;
      const { flag, ping: pong, accessToken } = action.payload;

      if (!zoneId || !nodeId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const node = state.graph.nodes[nodeId];
        const member = state.graph.users[memberId];

        if (
          !node ||
          !member ||
          node.group != groupId ||
          member.group != memberGroup ||
          member.group == node.group
        ) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }

        return [, node, member];
      };

      const [e] = validate();
      if (e) {
        return e;
      }

      yield response({ ok: true, pong });

      if (flag) {
        const action = await dispatch(takeFlag(flag));
        if ("_timeout" in action) {
          return response({ reason: "TIMEOUT", pong });
        } else if ("rejected" == action.att) {
          return response({ reason: "REJECTED", pong });
        }
      }

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, node, member] = validate();
        if (e) {
          return e;
        }

        try {
          const newMemberId = uid();

          const result = await api
            .token(accessToken)
            .members(newMemberId)
            .post({
              id: newMemberId,
              user: { id: member.user },
              group: { id: node.group },
            });

          const graph = {
            users: {
              [result.id]: {
                ...pick(
                  result.user,
                  "deprecatedId",
                  "name",
                  "email",
                  "provider",
                  "wellknown"
                ),
                role: member.role,
                member: result.id,
                user: result.user.id,
                group: result.group.id,
              },
            },
          };
          applyUpdate(action, zoneId, graph);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            const reason = e.message ? e.message + "" : "unknown";
            console.log(e);
            return error({ reason, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
          //console.log("done with it");
        }
      }
    }),
    setExpire: decorate(async function* (
      action,
      { dispatch, getState, permissions, ensureLoaded, applyUpdate }
    ) {
      const {
        actions: { response, error },
      } = module;

      if (!permissions[A.TEAM_EXPIRE]) {
        return error({ reason: "UNAUTHORIZED" });
      }

      let { zoneId, date, ping: pong, accessToken } = action.payload;

      if (!zoneId) {
        return response({ reason: "OUT_OF_SYNC", pong });
      }

      await ensureLoaded(zoneId);

      const validate = () => {
        const state = getState();
        const zone = state.graph.zones[zoneId];
        if (!zone) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }
        const max = new Date();
        max.setFullYear(max.getFullYear() + 20);
        if (!isInteger(date) || 0 > date || date > max) {
          return [response({ reason: "OUT_OF_SYNC", pong })];
        }
        return [, zone];
      };

      let unlock;
      try {
        unlock = await lockPartial(zoneId);

        const [e, zone] = validate();
        if (e) {
          return e;
        }

        try {
          let expirationDate = null;
          if (date) {
            expirationDate = Math.round(date / 1000);
            date = new Date(date).toJSON();
          }

          await api.token(accessToken).teams(zoneId).patch({
            expirationDate,
          });

          const newZone = { ...zone };
          if (date) {
            newZone.expirationDate = date;
          } else {
            delete newZone.expirationDate;
          }
          const graph = { zones: { [zoneId]: newZone } };
          applyUpdate(action, zoneId, graph);
        } catch (e) {
          // TODO LOG! failure regardless of sanity checks, might cause divergence
          if (e == SERVER_DENIED_PERMISSION) {
            return error({ reason: e, pong });
          } else {
            return error({ reason: `${e?.message || e || "UNKNOWN"}`, pong });
          }
        }
      } finally {
        if (unlock) {
          unlock();
        }
      }
    }),

    ///createCategory: decorate(async action => {
    ///  const { actions: { intermediate, error } } = module;

    ///  let { name } = action.payload;

    ///  const { ping, accessToken } = action.payload;
    ///  const { user: userId } = trial(jwtPeek)(accessToken);

    ///  try {
    ///    const id = uid();
    ///    const result = await api
    ///      .token(accessToken)
    ///      .categories(id)
    ///      .post({
    ///        id,
    ///        name,
    ///        teams: [],
    ///        user: { id: userId }
    ///      });

    ///    const category = {
    ///      ...without(result, "link"),
    ///      user: result.user.id
    ///    };

    ///    return intermediate({ category, pong: ping });
    ///  } catch (e) {
    ///    if (e.code === 400) {
    ///      return intermediate({ fail: true, name: true, pong: ping });
    ///    }
    ///    return error(e);
    ///  }
    ///}),
    ///assignCategory: decorate(async action => {
    ///  const { actions: { intermediate, error } } = module;

    ///  let { memberId, categoryId } = action.payload;

    ///  const { ping, accessToken } = action.payload;
    ///  const { user: userId } = trial(jwtPeek)(accessToken);

    ///  try {
    ///    const result = await api
    ///      .token(accessToken)
    ///      .members(memberId)
    ///      .patch({
    ///        category: { id: categoryId || null }
    ///      });

    ///    const category = {};

    ///    //const category = {
    ///    //  ...without(result, "link"),
    ///    //  user: result.user.id
    ///    //};

    ///    return intermediate({ category, pong: ping });
    ///  } catch (e) {
    ///    if (e.code === 400) {
    ///      return intermediate({ fail: true, name: true, pong: ping });
    ///    }
    ///    return error(e);
    ///  }
    ///}),
    ///removeCategory: decorate(async action => {
    ///  const { actions: { intermediate, error } } = module;

    ///  let { categoryId } = action.payload;

    ///  const { ping, accessToken } = action.payload;
    ///  const { user: userId } = trial(jwtPeek)(accessToken);

    ///  await api
    ///    .token(accessToken)
    ///    .categories(categoryId)
    ///    .delete();

    ///  return intermediate({ ok: true, pong: ping });
    ///}),
    ///setExpire: decorate(
    ///  async (action, { dispatch, getState, cascade, react, permissions }) => {
    ///    if (!permissions[A.TEAM_EXPIRE]) {
    ///      return unauthorized();
    ///    }

    ///    const {
    ///      actions: { error, consume, intermediate },
    ///      selectors: { selectGraph },
    ///      server: { loadZone }
    ///    } = module;
    ///    const {
    ///      zoneId,
    ///      date,
    ///      intent: expect,
    ///      flag,
    ///      ping,
    ///      accessToken
    ///    } = action.payload;

    ///    await cascade(loadZone({ id: zoneId }));
    ///    const oldState = clone(getState(true));

    ///    let expirationDate = null;
    ///    if (date) {
    ///      expirationDate = new Date(date) / 1000;
    ///    }

    ///    const result = await api
    ///      .token(accessToken)
    ///      .teams(zoneId)
    ///      .patch({
    ///        expirationDate
    ///      });

    ///    const newZone = clone(selectGraph(oldState).zones[result.id]);
    ///    const graph = {
    ///      zones: { [result.id]: { ...newZone, expirationDate: date } }
    ///    };

    ///    dispatch(consume({ graph }));
    ///    react(action, oldState, getState(true), [zoneId]);
    ///  }
    ///)
  };
};
