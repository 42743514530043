import { RIKS_KEY_LENGTH_IN_BYTES, RIKS_KEY_ID_LENGTH_IN_BYTES } from "./";

import { Data } from "../";

import { random, importSecretKey } from "../crypto";

import { assertInstanceOf, isInstanceOf } from "../util";

/*******************/

export default class RiksKey {
  constructor(keyID, keyData) {
    this.keyID = keyID || random(RIKS_KEY_ID_LENGTH_IN_BYTES);
    this.keyData = keyData || random(RIKS_KEY_LENGTH_IN_BYTES);

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], this.keyID)) {
      this.keyID = new Data(this.keyID);
    } else if (!isInstanceOf([Data], this.keyID)) {
      throw new Error(`RiksKey ID must be a Data object.`);
    }

    if (this.keyID.length !== RIKS_KEY_ID_LENGTH_IN_BYTES) {
      throw new Error(
        `RiksKey ID must be ${RIKS_KEY_ID_LENGTH_IN_BYTES} bytes long.`
      );
    }

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], this.keyData)) {
      this.keyData = new Data(this.keyData);
    } else if (!isInstanceOf([Data], this.keyData)) {
      throw new Error(`RiksKey data must be a Data object.`);
    }

    if (this.keyData.length !== RIKS_KEY_LENGTH_IN_BYTES) {
      throw new Error(
        `RiksKey data must be ${RIKS_KEY_LENGTH_IN_BYTES} bytes long.`
      );
    }
  }

  async getSecretKey() {
    if (this.key == null) {
      this.key = await importSecretKey(this.keyData, "raw");
    }
    return this.key;
  }
}
