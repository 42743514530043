import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOverlay, selectOverlay } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import Preview from "./Preview.js";

const {
  selectors: { selectPermissions },
} = appState;

export const PREVIEW = "PREVIEW";
export const NOT_SIGNED = "Not signed";
export const NOT_AVAILABLE = "Not available";
export const NO_ORDER = "No order";

export default () => {
  const dispatch = useDispatch();
  const { overlay, ...args } = useSelector(selectOverlay);
  const permissions = useSelector(selectPermissions);

  const props = {
    ...args,
    dispatch,
    permissions,
    close: () => dispatch(setOverlay()),
  };

  return (
    <>
      <Preview active={overlay === PREVIEW} {...props} />
    </>
  );
};
