import React, { useState, useContext } from "react";
import { Dialog, Icon, Switch, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";
import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";
import { dispatchToast } from "../toasts/Toasts.js";
const {
  actions: { createCategory },
} = appState;

export default ({ dispatch, active, close }) => {
  const { zoneId, groupId, folderId: parentId } = useContext(appContext);
  const [name, setName] = useState("");
  const [error, setError] = useState();

  const nameIsOk = name.length > 0;

  const doIt = async () => {
    if (200 < name.length) {
      dispatchToast(dispatch, {
        message: `Name too long.`,
        icon: "warning-sign",
        intent: Intent.DANGER,
      });
      return;
    }

    const toast = await dispatchToast(dispatch, {
      message: "Creating category.",
      icon: "bookmark",
      intent: Intent.PRIMARY,
      timeout: 0,
    });

    const action = createCategory({ name });
    const { reason } = (await dispatch(action)) || {};

    if (reason) {
      setError(true);
      if (reason == "BAD_NAME") {
        toast.replace({
          message: "Failed to create category.",
          icon: "bookmark",
          intent: Intent.DANGER,
        });
      } else if (reason == "DUPLICATE_NAMES") {
        toast.replace({
          message: "That name already exists, pick another one.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
        return;
      } else if (reason != "HANDLED") {
        toast.replace({
          message: "Something went wrong, please try again.",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      }
    } else {
      toast.replace({
        message: "Category created!",
        icon: "bookmark",
        intent: Intent.SUCCESS,
      });
    }
    setName("");
    close();
  };

  return (
    <Dialog
      isOpen={active}
      onClose={close}
      title={
        <>
          <Icon icon="bookmark" iconSize={20} />
          <span className={styles.dialogTitle}>Create Category</span>
        </>
      }
      className={styles.dialog}
    >
      <div className={styles.dialogBody}>
        <input
          autoFocus
          value={name}
          className={classNames(styles.dialogInput, error && styles.errorInput)}
          placeholder="Category Name"
          onKeyDown={(e) => e.key == "Enter" && nameIsOk && doIt()}
          onChange={(e) => setName(e.target.value) | setError()}
        />
        <div className={styles.verticalSpace} />
        <div className={styles.verticalSpace} />
        <button
          disabled={!nameIsOk}
          className={styles.dialogButton}
          onClick={doIt}
        >
          Create
        </button>
      </div>
    </Dialog>
  );
};
