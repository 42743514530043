
import React, { useRef, useEffect } from 'react';
import { VALID_PIN_REGEX, VALID_PIN_REGEX_MOBILE } from './pin';
import { classNames } from "utils";
import { selectIsMobile } from 'features/layout/layoutSlice';
import { useSelector } from 'react-redux';
import styles from "./Login.module.css";
interface PinInput {
    pinValue: Array<string> | string;
    setPinValue: (value: string[] | string) => void;
    pinLength: number;
    isLoading?: boolean;
    isPin: boolean;
    next: () => void;
    showErrorMessage: boolean;
    setShowErrorMessage: (value: boolean) => void;
    isAttemptsExceeded: boolean;
}

interface MobilePinInput extends Pick<PinInput, "isAttemptsExceeded" | "pinLength" | "isLoading"> {
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    index: number;
    pinValue: string;
}

const BACKSPACE_KEY: string = 'Backspace';
const ARROW_LEFT_KEY: string = 'ArrowLeft';
const ARROW_RIGHT_KEY: string = 'ArrowRight';
const ENTER_KEY: string = 'Enter';
const PIN_INPUT_PLACEHOLDER = 'XXXXXX';

const PinInput: React.FC<PinInput> = ({ pinValue, setPinValue, pinLength, isLoading, isPin, next, showErrorMessage, setShowErrorMessage, isAttemptsExceeded }) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const isMobile = useSelector(selectIsMobile);

    const handleChangePinFocus = (pinIndex: number) => {
        const ref = inputRefs.current[pinIndex];
        if (ref) {
            ref.focus();
        }
    };

    useEffect(() => {
        handleChangePinFocus(0);
    }, [isLoading]);

    const handlePinChange = (pinEntry: string, index: number) => {
        const newPin = [...pinValue as string[]];
        newPin[index] = pinEntry;
        setPinValue(newPin);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (showErrorMessage) {
            setShowErrorMessage(false);
        }

        let pastedValue: string = event.target.value;
        let previousValue: string = event.target.defaultValue;

        const sanitizedValue: string = handlePastedValue(pastedValue);

        if (isMobile) {
            setPinValue(sanitizedValue);
        } else {
            let valueArray = sanitizedValue.split('');

            if (sanitizedValue.length < 6) {
                removeValuesFromArray(valueArray, previousValue);
            }

            let newPin: string[] = [...pinValue as string[]];

            for (let i = 0; i < valueArray.length; i++) {
                newPin[index + i] = valueArray[i];
            }

            setPinValue(newPin);

            if (index + valueArray.length <= pinLength - 1) {
                handleChangePinFocus(index + valueArray.length);
            }
        }
    };

    const handlePastedValue = (pastedValue: string) => {
        let sanitizedValue: string = '';
        if (pastedValue.length > 7) {
            const hexValues = handleHexValues(pastedValue);
            pastedValue = hexValues ? hexValues.join('-') : '';
            sanitizedValue = pastedValue.trim().replace(/-/g, "").substring(0, pinLength);
        } else {
            sanitizedValue = pastedValue.trim().replace(/-/g, "").substring(0, pinLength);
        }
        return sanitizedValue;
    };

    const handleHexValues = (input: string) => {
        const hyphenIndex = input.indexOf('-');
        if (hyphenIndex === -1) {
            return null;
        }
        const hexValuesBeforeHyphen = input.substring(hyphenIndex - 3, hyphenIndex);
        const hexValuesAfterHyphen = input.substring(hyphenIndex + 1, hyphenIndex + 4);
        return [hexValuesBeforeHyphen, hexValuesAfterHyphen];
    }

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (showErrorMessage) {
            setShowErrorMessage(false);
        }
        const keyboardKeyCode = event.nativeEvent.key;
        if (keyboardKeyCode === BACKSPACE_KEY) {
            if (pinValue[index] === "") {
                handleChangePinFocus(index - 1);
            } else {
                handlePinChange("", index);
            }
        } else if (keyboardKeyCode === ARROW_LEFT_KEY && index > 0) {
            handleChangePinFocus(index - 1);
        } else if (keyboardKeyCode === ARROW_RIGHT_KEY && index < pinLength - 1) {
            handleChangePinFocus(index + 1);
        }
        else if (keyboardKeyCode === ENTER_KEY && isPin) {
            next();
        }
    };

    const removeValuesFromArray = (valueArray: string[], value: string) => {
        const valueIndex = valueArray.findIndex(entry => entry === value);
        if (valueIndex === -1) {
            return;
        }
        valueArray.splice(valueIndex, 1);
    };

    return (
        <>
            {isMobile ?
                <MobilePinInput
                    isLoading={isLoading}
                    isAttemptsExceeded={isAttemptsExceeded}
                    handleOnChange={handleOnChange}
                    index={0}
                    pinLength={pinLength}
                    pinValue={pinValue as string}
                />
                :
                <div className={styles.pin}>
                    {Array.from({ length: pinLength }, (_, index) => (
                        <React.Fragment key={index}>
                            <input
                                disabled={isLoading || isAttemptsExceeded}
                                onKeyDown={(event) => handleOnKeyDown(event, index)}
                                key={index}
                                ref={(el) => {
                                    if (el) {
                                        inputRefs.current[index] = el;
                                    }
                                }}
                                onChange={(event) => handleOnChange(event, index)}
                                value={pinValue[index] || ''}
                                className={pinValue[index] !== "" && !VALID_PIN_REGEX.test(pinValue[index]) ? styles.invalid : ''}
                                type="text"
                                autoComplete="off"
                                autoCapitalize='characters'
                            />
                            {index === 2 && <span className={styles.separator}>-</span>}
                        </React.Fragment>
                    ))}
                </div>
            }
        </>
    );
};

const MobilePinInput: React.FC<MobilePinInput> = ({
    isLoading,
    isAttemptsExceeded,
    handleOnChange,
    index,
    pinLength,
    pinValue
}) => {
    return (
        <input
            className={classNames(styles.mobilePin, pinValue !== "" && !VALID_PIN_REGEX_MOBILE.test(pinValue) ? styles.invalid : '')}
            disabled={isLoading || isAttemptsExceeded}
            onChange={(event) => handleOnChange(event, index)}
            value={pinValue}
            maxLength={pinLength + 1}
            placeholder={PIN_INPUT_PLACEHOLDER}
            type="text"
            autoComplete="off"
            autoCapitalize='characters'
        />
    )
}

export default PinInput;