import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIdentity,
  setProvider,
  setView,
  selectView,
  setHub,
  startBankIdAuthentication,
} from "./loginSlice";
import { selectIsMobile } from "../layout/layoutSlice.js";
import { BankID } from "./icons";
import styles from "./Login.module.css";

const Option: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectIsMobile);
  const view = useSelector(selectView);

  const next = async () => {
    dispatch(setIdentity([]));
    dispatch(setProvider("bankid"));

    if (view === "HUB") {
      dispatch(setHub(true));
    }

    await dispatch(startBankIdAuthentication(true));
  };

  return (
    <div className={styles.option} onClick={() => next()}>
      {isMobile && view === "HUB" ? (
        <BankID />
      ) : (
        <span>BankID Only Verification</span>
      )}
    </div>
  );
};

export default Option;
