import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { classNames } from "utils";
import {
  setIdentity,
  selectProcess,
  selectIdentity,
  sendEmailToKeyper,
  setView,
} from "./loginSlice";

import { Email, Send } from "./icons";
import { validateEmail } from "../invite/email";
import Option from "./option";
import { inBullhorn } from "app/topSlice";
import styles from "./Login.module.css";

export default ({ }) => {
  return (
    <div
      className={classNames(
        styles.method,
        styles.email,
        styles.active
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <Form />
    </div>
  );
};

const Form: React.FC = ({ }) => {
  const dispatch = useDispatch();
  const process = useSelector(selectProcess);
  const [email = ""] = useSelector(selectIdentity);
  const [showError, setShowError] = useState<boolean>(false);

  const error: boolean = !validateEmail(email);

  const next = async (): Promise<void> => {
    if (error) {
      setShowError(true);
      return;
    }
    if (process == "LOGIN") {
      dispatch(sendEmailToKeyper({ email }));
      dispatch(setView("PIN"));
    }
  };

  return (
    <div className={styles.dialog}>
      <div className={styles.header}>
        <Email size={34} />
        <div className={styles.name}>Email</div>
      </div>
      <div className={styles.middle}>
        <input
          autoFocus
          type="email"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck={false}
          value={email}
          className={classNames(
            styles.input,
            showError && error && styles.error
          )}
          placeholder="email address"
          onKeyDown={(e) => e.key === "Enter" && (e.preventDefault(), next())}
          onChange={(e) => dispatch(setIdentity([e.target.value]))}
        />
      </div>
      <div className={classNames(styles.footer, !inBullhorn && styles.optionAndSubmit)}>
        {!inBullhorn && <Option />}
        <div
          className={classNames(
            styles.button,
            styles.green,
            error && styles.disabled
          )}
          onClick={(e) => next()}
        >
          <Send />
          <span>send pin code</span>
        </div>
      </div>
    </div>
  );
};