import React from "react";

import { classNames } from "utils";

import styles from "./main.module.css";

export default class Logo extends React.Component {
  render() {
    return (
      <div className={styles.outer}>
        <div className={styles.wrapper}>
          <h1 className={styles.pageTitle}>
            We don't support this version of your browser.
          </h1>

          <p>
            Your browser does not support security features that we require.
            <br />
            We highly recommend that you update your browser.
          </p>

          <div>
            <h2>These are the ones we recommend:</h2>

            <ul className={styles.supportedBrowsersList}>
              <li
                className={classNames(
                  styles.supportedBrowsersListItem,
                  styles.browserChrome
                )}
              >
                <div className={styles.supportedBrowserInformation}>
                  Google Chrome
                </div>
                <div className={styles.supportedBrowserDownload}>
                  <a
                    href="https://www.google.com/intl/en/chrome/browser/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download latest
                  </a>
                </div>
                <div className={styles.clearfix} />
              </li>
              <li
                className={classNames(
                  styles.supportedBrowsersListItem,
                  styles.browserEdge
                )}
              >
                <div className={styles.supportedBrowserInformation}>
                  Microsoft Edge
                </div>
                <div className={styles.supportedBrowserDownload}>
                  <a
                    href="https://www.microsoft.com/en-us/edge"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download latest
                  </a>
                </div>
                <div className={styles.clearfix} />
              </li>
              <li
                className={classNames(
                  styles.supportedBrowsersListItem,
                  styles.browserFirefox
                )}
              >
                <div className={styles.supportedBrowserInformation}>
                  Mozilla Firefox
                </div>
                <div className={styles.supportedBrowserDownload}>
                  <a
                    href="https://www.mozilla.org/firefox/all/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download latest
                  </a>
                </div>
                <div className={styles.clearfix} />
              </li>
              {/*
              <li
                className={classNames(
                  styles.supportedBrowsersListItem,
                  styles.browserSafari
                )}
              >
                <div className={styles.supportedBrowserInformation}>
                  Apple Safari
                </div>
                <div className={styles.supportedBrowserDownload}>
                  <a
                    href="https://itunes.apple.com/us/app/os-x-yosemite/id915041082"
                    target="_blank"
                    rel="noopener"
                  >
                    Upgrade your OS
                  </a>
                </div>
                <div className={styles.clearfix} />
              </li>
              */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
