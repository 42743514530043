import "./Slide.css";

import { loadScript } from "./utils";

export const createBackend = (el, bytes, mime) => {
  let theInstance;

  const create = async () => {
    //const jq = "jquery.1.12.3.min.js";
    const jq = "jquery-3.6.0.min.js";
    //const src = "SlideJS.bundle.js";
    const src = "SlideJS.bundle.min.js";

    let resolve,
      promise = new Promise((r) => (resolve = r));

    loadScript(jq, () => {
      loadScript(src, () => {
        resolve(window.SlideJS);
      });
    });

    const SlideJS = await promise;

    if (typeof SlideJS === "undefined") {
      throw new Error("failed to load doc viewer");
    }
    const instance = new SlideJS();

    let div = document.createElement("div");

    el.innerHTML = "";
    el.appendChild(div);

    let blob = new Blob([bytes], { type: mime });

    instance.parse(
      blob,
      () =>
        instance.render(
          div,
          () => {
            theInstance = instance;
          },
          3
        ),
      (e) => console.error(e)
    );
  };

  let destroyed = false;

  const destroy = () => {
    if (!destroyed) {
      destroyed = true;
      el.innerHTML = "";
    }
  };

  let currentValue = 1;

  const zoomOut = () => {
    let intervalValue = 0.25;
    if (currentValue > 3) {
      intervalValue = 1;
    } else if (currentValue > 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.max(1, currentValue - intervalValue);
    theInstance.setZoom(currentValue);
  };

  const zoomIn = () => {
    let intervalValue = 0.25;
    if (currentValue >= 3) {
      intervalValue = 1;
    } else if (currentValue >= 2) {
      intervalValue = 0.5;
    }
    currentValue = Math.min(3, currentValue + intervalValue);
    theInstance.setZoom(currentValue);
  };

  const navPrev = () => {
    const currentId = theInstance.getCurrentId();
    const fileInfo = theInstance.getFileInfo();
    const total = fileInfo.total || fileInfo.totalPage;
    const backPageId = (currentId - 1 + total) % total;

    theInstance.gotoPage(backPageId);
  };

  const navNext = () => {
    const currentId = theInstance.getCurrentId();
    const fileInfo = theInstance.getFileInfo();
    const total = fileInfo.total || fileInfo.totalPage;

    const nextPageId = (currentId + 1 + total) % total;
    theInstance.gotoPage(nextPageId);
  };

  const navLast = () => {
    const fileInfo = theInstance.getFileInfo();
    const total = fileInfo.total || fileInfo.totalPage;

    theInstance.gotoPage(total - 1);
  };

  return {
    promise: create(),
    destroy,
    zoomOut,
    zoomIn,
    navPrev,
    navNext,
    navLast,
  };
};
