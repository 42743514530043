import { createSlice } from "@reduxjs/toolkit";

// TODO add to configure store to enable
export const counterSlice = createSlice({
  name: "debug",
  initialState: {
    value: 0
  },
  reducers: {}
});

export const {} = counterSlice.actions;

export default counterSlice.reducer;
