import {
  uid,
  all,
  getDeep,
  diff,
  distinct,
  entries,
  fromEntries,
  values,
  keys,
  index,
  hash as hashFunc,
  createMemo,
  nonEmptyObj
} from "utils";

//const hashEq = hash => action => action.payload?.hash == hash;

//let tWaitForPin = 0;

const pinEq = id => a => {
  //let t0 = new Date;
  let x = !a.type.startsWith("!") && a.payload.id == id;
  //tWaitForPin += (new Date - t0);
  return x;
};

//const createCondition = prev => `hash=${prev}&att!pending&att!rejected`;

export default module => {
  const decorate = f => (args = {}) => async (dispatch, getState) => {
    const {
      selectors: { selectScope },
      actions: { getContext },
      auxiliary: { align, signHash }
    } = module;
    const memo = createMemo((args.memo = args.memo || {}));
    const state = selectScope(args.state || getState());
    const context = await dispatch(getContext());
    const seal = async entry => {
      const hash = await hashFunc(align(entry));
      const sign = await dispatch(signHash(hash));
      return { ...entry, hash, sign };
    };
    const it = f({ ...args, dispatch, getState, memo, state, context, seal });
    return async () => (await it.next()).value;
  };
  //const topics = [];
  //const subscribe = (...topic) => async dispatch => {
  //  const { actions: { getContext }, meta: { take } } = module;
  //  const { channel } = await dispatch(getContext());
  //  topic = diff(topic, topics);
  //  if (!topic.length) {
  //    return {};
  //  }
  //  distinct(topics, ...topic);
  //  for (const t of topic) {
  //    const { pin } = await channel.subscribe(t, 1);
  //    if (pin) {
  //      // TODO verify here that this works (takes some time).
  //      console.log("verify here that PIN works (takes some time)")
  //      await dispatch(take(pinEq(pin)));
  //    }
  //  }
  //};
  const subscribe = (...topics) => async dispatch => {
    const {
      actions: { getContext },
      selectors: { selectScope, selectSeq },
      meta: { take, waitFor }
    } = module;

    const { channel } = await dispatch(getContext());

    topics = channel.diff(...topics);
    topics = topics.flat();

    //let t0 = new Date;
    const { end } = await channel.pull(...topics);
    //console.log("channel pull took (subscribe)", new Date - t0);

    if (end) {
      //let t0 = new Date();
      //tWaitForPin = 0;
      //await dispatch(waitFor(s => selectSeq(selectScope(s))(groupId) >= end));
      await dispatch(take(pinEq(end)));
      //console.log("WAIT FOR TAKE PIN TOOK", new Date() - t0);
      //console.log("checking when waiting for pin took", tWaitForPin);
    }
  };
  return {
    loadGroup: (...groupIds) => async (dispatch, getState) => {
      const {
        auxiliary: { getPreState },
        actions: { getContext, acceptState },
        selectors: { selectScope, selectSeq },
        meta: { waitFor }
      } = module;
      const context = await dispatch(getContext());
      const { channel, credentials: { deviceId } } = context;

      let topics = groupIds.map(groupId => `grp/${groupId}`);
      topics = channel.diff(...topics);
      groupIds = topics.map(([topic]) => topic.slice("grp/".length));

      for (let i = 0; i < groupIds.length; i++) {
        const groupId = groupIds[i];
        const state = await dispatch(getPreState(groupId));

        if (state) {
          dispatch(acceptState(state));
        }

        topics[i].unshift(state?.grp[groupId]?.seq || 0);
      }

      //const { seq } = state?.grp[groupId] || {};
      //let t0 = new Date;
      const { end } = await channel.pull(...topics);
      //console.log("channel pull took (loadGroup)", new Date - t0);

      if (end) {
        //let t0 = new Date();
        //let tWaitForSeq = 0;
        //await dispatch(waitFor(s => selectSeq(selectScope(s))(groupId) >= end));
        await dispatch(
          waitFor(state => {
            //let t0 = new Date;
            let x = groupIds.some(
              groupId => selectSeq(selectScope(state))(groupId) >= end
            );
            //tWaitForSeq += (new Date - t0);
            return x;
          })
        );
        //console.log("WAIT FOR SELECT SEQ TOOK", new Date() - t0);
        //console.log("checking when waiting for seq took", tWaitForSeq);
      }
    },
    createGroup: decorate(async function*(args) {
      const { selectors: { selectHash }, meta: { take } } = module;
      const { groupId } = args;
      const { state, dispatch, context, seal } = args;
      const { channel: { publish }, credentials: { deviceId } } = context;
      const prev = selectHash(state)(groupId);
      if (prev) {
        return { nogo: true };
      }
      const flag = uid();
      const entry = await seal({
        type: "CREATE_GROUP",
        group: groupId,
        origin: deviceId,
        att: "pending",
        flag
      });
      const { hash } = entry;
      //yield { flag: hash };
      yield { flag };
      const trip = dispatch(take(({ payload = {} }) => payload.hash == hash));
      await publish([`grp/${groupId}`], entry);
      const { payload: { att } } = await trip;
      if (att == "rejected") {
        return { rejected: true };
      }
      return { ok: true };
    }),
    addUsers: decorate(async function*(args) {
      //let t0 = new Date;
      const {
        selectors: {
          selectScope,
          selectResources,
          selectRelation,
          selectMembers,
          selectKeys,
          selectHash
        },
        auxiliary: { cachePublicKeys, lockManyKeysForOneId },
        meta: { take }
      } = module;

      const { groupId /*= null*/, userURIs = [] } = args;
      const { dispatch, getState, context, seal, memo } = args;
      const {
        channel: { publish, commit },
        credentials: { deviceId }
      } = context;

      let { state } = args;

      const res = selectResources(state)(groupId);

      //console.log("took", new Date - t0, "to get pass selectResources, of which there are", res.length);

      const unknown = userURIs.filter(uri => !(uri in state.rel));
      const topics = (await all(...unknown.map(hashFunc))).map(s => "pr/" + s);
      await dispatch(subscribe(...topics, `ks/${deviceId}/${groupId}`));
      state = selectScope(getState());

      const memberIds = userURIs
        .flatMap(uri => selectRelation(state)(uri))
        .filter(memberId => !selectMembers(state)(groupId).includes(memberId));

      if (!memberIds.length) {
        return { noop: true };
      }

      const symKeys = selectKeys(state)(groupId);

      //console.log("took", new Date - t0, "to get pass selectKeys, of which there are", keys(symKeys).length, symKeys);

      for (let [keyId] of res) {
        if (!symKeys[keyId]) {
          return { nogo: true };
        }
      }

      let prev = selectHash(state)(groupId);
      if (!prev) {
        return { nogo: true };
      }

      //let t1 = new Date;
      const ops = [];
      const ifThis = uid();
      const thenThat = uid();
      for (const memberId of memberIds) {
        const entry = await seal({
          type: "ADD_MEMBER",
          group: groupId,
          member: memberId,
          origin: deviceId,
          //salt, // TODO to ensure no clients can produce the same hashes
          att: "pending",
          //blk: ifThis,
          prev
        });
        ops.push([[`grp/${groupId}`], ({ hash: prev } = entry), ifThis]);
        // only get public key if we really need it
        const [pubKey] = keys(symKeys).length
          ? await dispatch(cachePublicKeys(memberId))
          : [];
        const secKeys = await dispatch(lockManyKeysForOneId(symKeys, pubKey));
        for (const [keyId, secKey] of entries(secKeys)) {
          const key = `sk/${await hashFunc(memberId + keyId)}`;
          const topics = [`ks/${memberId}`, `ks/${memberId}/${groupId}`];
          ops.push([
            topics,
            await memo(memberId + keyId, () =>
              seal({
                key,
                type: "KEY_SHARE",
                keyId,
                secKey,
                origin: deviceId,
                group: groupId
              })
            ),
            thenThat
          ]);
        }
      }
      //console.log("building ops took", new Date - t1);
      //let t2 = new Date;
      //yield { flag: prev };
      yield { flag: ifThis };
      const trip = dispatch(take(({ payload = {} }) => payload.hash == prev));
      await all(...ops.map(args => publish(...args)));
      //publish([], { cmt: thenThat, cnd: createCondition(prev) });
      //await publish([], { cmt: ifThis });
      await commit(ifThis, thenThat);
      const { payload: { att } } = await trip;
      //console.log("writing to the server took", new Date - t2);
      //console.log("addUsers took", new Date - t0);
      if (att == "rejected") {
        return { rejected: true };
      }
      return { ok: true };
    }),
    removeUsers: decorate(async function*(args) {
      const {
        selectors: {
          selectScope,
          selectRelation,
          selectGroup,
          selectMembers,
          selectHash
        },
        meta: { take }
      } = module;
      const { groupIds = [], userURIs = [] } = args;
      const { dispatch, getState, context, seal } = args;
      const {
        channel: { publish, commit },
        credentials: { deviceId }
      } = context;

      let { state } = args;

      const unknown = userURIs.filter(uri => !(uri in state.rel));
      const topics = (await all(...unknown.map(hashFunc))).map(s => "pr/" + s);
      await dispatch(subscribe(...topics));
      state = selectScope(getState());

      const groupMembers = fromEntries(
        groupIds.map(groupId => [
          groupId,
          userURIs
            .flatMap(uri => selectRelation(state)(uri))
            .filter(memId => selectMembers(state)(groupId).includes(memId))
        ])
      );
      if (!values(groupMembers).flatMap(members => members).length) {
        return { noop: true };
      }
      if (groupIds.some(groupId => !selectHash(state)(groupId))) {
        return { nogo: true };
      }
      const ops = [];
      const blk = uid();
      for (const [groupId, memberIds] of entries(groupMembers)) {
        //const { sequence } = selectGroup(state)(groupId);
        for (const memberId of memberIds) {
          ops.push([
            [`grp/${groupId}`],
            await seal({
              type: "REMOVE_MEMBER",
              //pos: sequence,
              group: groupId,
              member: memberId,
              origin: deviceId,
              att: "pending"
            }),
            blk
          ]);
        }
      }
      yield { flag: blk };
      await all(...ops.map(args => publish(...args)));
      //await publish([], { cmt: blk });
      await commit(blk);
      return { ok: true };
    }),
    removeResources: decorate(async function*(args) {
      const {
        selectors: { selectGroup, selectResources, selectHash },
        auxiliary: { cachePublicKeys, lockOneKeyForManyIds },
        meta: { take }
      } = module;
      const { groupedKeyIds } = args;
      const { state, dispatch, context, seal } = args;
      const {
        channel: { publish, commit },
        credentials: { deviceId }
      } = context;

      const res = keys(groupedKeyIds).flatMap(groupId =>
        selectResources(state)(groupId)
      );
      for (const [groupId, keyIds] of entries(groupedKeyIds)) {
        groupedKeyIds[groupId] = res.filter(([keyId]) =>
          keyIds.includes(keyId)
        );
      }
      if (!values(groupedKeyIds).some(keyIds => keyIds.length)) {
        return { noop: true };
      }
      if (keys(groupedKeyIds).some(groupId => !selectHash(state)(groupId))) {
        return { nogo: true };
      }

      const ops = [];
      const blk = uid();
      for (const [groupId, keyIds] of entries(groupedKeyIds)) {
        for (const [keyId, checksum, salt, symKey] of keyIds) {
          ops.push([
            [`grp/${groupId}`],
            await seal({
              type: "REMOVE_RES",
              group: groupId,
              keyId,
              checksum,
              salt,
              origin: deviceId,
              att: "pending"
              //pos
              //blk: flag
            }),
            blk
          ]);
        }
      }
      yield { flag: blk };
      await all(...ops.map(args => publish(...args)));
      //await publish([], { cmt: flag });
      await commit(blk);
      return { ok: true };
    }),
    //moveResources: decorate(async function*(args) {
    //  const {
    //    selectors: {
    //      selectScope,
    //      selectKeys,
    //      selectGroup,
    //      selectMembers,
    //      selectResources,
    //      selectHash
    //    },
    //    auxiliary: { cachePublicKeys, lockOneKeyForManyIds },
    //    meta: { take }
    //  } = module;
    //  const { fromGroupId, toGroupId, keyIds } = args;
    //  const { state, dispatch, getState, context, seal, memo } = args;
    //  const { channel: { publish }, credentials: { deviceId } } = context;

    //  await dispatch(subscribe(`ks/${deviceId}/${fromGroupId}`));

    //  const symKeys = selectKeys(selectScope(getState()))(fromGroupId);

    //  const pos = selectGroup(state)(fromGroupId).sequence;
    //  const members = selectMembers(state)(toGroupId);
    //  const to = selectResources(state)(toGroupId);
    //  const from = selectResources(state)(fromGroupId);
    //  const rm = from.filter(([keyId]) => !keyIds || keyIds.includes(keyId));
    //  const add = rm.filter(([a, b]) => !to.some(([c, d]) => a == c && b == d));
    //  if (!rm.length && !add.length) {
    //    return { noop: true };
    //  }
    //  let prev = selectHash(state)(toGroupId);
    //  if (!prev) {
    //    return { nogo: true };
    //  }
    //  const ops = [];
    //  const task = uid();
    //  const then = uid();
    //  for (const [keyId, checksum, salt, symKey] of add) {
    //    const entry = await seal({
    //      type: "SHARE_RES",
    //      //group: fromGroupId,
    //      group: toGroupId,
    //      keyId,
    //      checksum,
    //      salt,
    //      origin: deviceId,
    //      prev,
    //      blk: task,
    //      att: "pending"
    //      //flag
    //    });
    //    ops.push([[`grp/${toGroupId}`], ({ hash: prev } = entry)]);
    //  }
    //  for (const [keyId, checksum, salt, symKey] of rm) {
    //    ops.push([
    //      //[`grp/${groupId}`],
    //      [`grp/${fromGroupId}`],
    //      await seal({
    //        type: "REMOVE_RES",
    //        group: fromGroupId,
    //        keyId,
    //        checksum,
    //        salt,
    //        origin: deviceId,
    //        pos,
    //        blk: then
    //        //flag
    //      })
    //    ]);
    //  }
    //  //for (let [[keyId, checksum, salt, symKey], i] of index(res)) {
    //  //  checksum = checksum || uid();
    //  //  salt = salt || (await hashFunc(checksum));
    //  //  if (!keyId || !symKey) {
    //  //    ({ keyId, symKey } = await cryptoClient.generateKey());
    //  //  }
    //  //  res[i] = [keyId, checksum, salt, symKey];
    //  //}
    //  for (let [keyId, checksum, salt, symKey] of add) {
    //    if (!symKey) {
    //      symKey = symKeys[keyId];
    //      if (!symKey) {
    //        return { nogo: true };
    //      }
    //    }
    //    const pubKeyByUid = {};
    //    for (const member of members) {
    //      [pubKeyByUid[member]] = await dispatch(cachePublicKeys(member));
    //    }
    //    const secKeysToShare = await dispatch(
    //      lockOneKeyForManyIds(keyId, symKey, pubKeyByUid)
    //    );
    //    for (const [target, secKey] of entries(secKeysToShare)) {
    //      const key = `sk/${await hashFunc(target + keyId)}`;
    //      const topics = [`ks/${target}`, `ks/${target}/${toGroupId}`];
    //      ops.push([
    //        topics,
    //        {
    //          ...(await memo(keyId + target, () =>
    //            seal({
    //              key,
    //              type: "KEY_SHARE",
    //              keyId,
    //              secKey,
    //              origin: deviceId,
    //              group: toGroupId
    //            })
    //          )),
    //          blk: then
    //        }
    //      ]);
    //    }
    //  }
    //  const flag = add.length ? prev : then;
    //  //yield { flag: thenThat };
    //  yield { flag };

    //  await all(...ops.map(args => publish(...args)));
    //  if (!add.length) {
    //    await publish([], { cmt: then });
    //  } else {
    //    const trip = dispatch(take(({ payload = {} }) => payload.hash == prev));
    //    publish([], { cmt: then, cnd: createCondition(prev) });
    //    await publish([], { cmt: task });
    //    const { payload: { att } } = await trip;
    //    if (att == "rejected") {
    //      return { rejected: true };
    //    }
    //  }
    //  return { ok: true };
    //}),
    addResources: decorate(async function*(args) {
      const {
        selectors: { selectGroup, selectMembers, selectResources, selectHash },
        auxiliary: { cachePublicKeys, lockOneKeyForManyIds },
        meta: { take }
      } = module;
      let { groupId, res = [] } = args;
      const { state, dispatch, context, seal, memo } = args;
      const {
        cryptoClient,
        channel: { publish, commit },
        credentials: { deviceId }
      } = context;
      const start = selectHash(state)(groupId);
      const members = selectMembers(state)(groupId);
      const added = selectResources(state)(groupId);
      res = res.filter(([a, b]) => !added.some(([c, d]) => a == c && b == d));
      if (!res.length) {
        return { noop: true };
      }
      let prev = selectHash(state)(groupId);
      if (!prev) {
        return { nogo: true };
      }
      const ops = [];
      const task = uid();
      const then = uid();
      for (let [[keyId, checksum, salt, symKey], i] of index(res)) {
        checksum = checksum || uid();
        salt = salt || (await hashFunc(checksum));
        if (!keyId || !symKey) {
          ({ keyId, symKey } = await cryptoClient.generateKey());
        }
        res[i] = [keyId, checksum, salt, symKey];
      }
      for (const [keyId, checksum, salt, symKey] of res) {
        const entry = await seal({
          type: "SHARE_RES",
          group: groupId,
          keyId,
          checksum,
          salt,
          origin: deviceId,
          prev,
          //blk: task,
          att: "pending"
          //flag
        });
        ops.push([[`grp/${groupId}`], ({ hash: prev } = entry), task]);
      }
      for (const [keyId, checksum, salt, symKey] of res) {
        const pubKeyByUid = {};
        for (const member of members) {
          [pubKeyByUid[member]] = await dispatch(cachePublicKeys(member));
        }
        const secKeysToShare = await dispatch(
          lockOneKeyForManyIds(keyId, symKey, pubKeyByUid)
        );
        for (const [target, secKey] of entries(secKeysToShare)) {
          const key = `sk/${await hashFunc(target + keyId)}`;
          const topics = [`ks/${target}`, `ks/${target}/${groupId}`];
          ops.push([
            topics,
            await memo(keyId + target, () =>
              seal({
                key,
                type: "KEY_SHARE",
                keyId,
                secKey,
                origin: deviceId,
                group: groupId
              })
            ),
            then
          ]);
        }
      }
      yield { flag: task };
      await all(...ops.map(args => publish(...args)));
      const trip = dispatch(take(({ payload = {} }) => payload.hash == prev));
      //publish([], { cmt: then, cnd: createCondition(prev) });
      //await publish([], { cmt: task });
      //await publish([], { cmt: task, csc: then });
      await commit(task, then);
      const { payload: { att, prev: p, hash } } = await trip;
      if (att == "rejected") {
        return { rejected: true, prev: p, hash };
      }
      return { ok: true };
    }),
    cloneGroups: decorate(async function*(args) {
      const { meta: { take } } = module;
      const {
        groupIds,
        filter: {
          copyFiles,
          copyMessages,
          copyMembers,
          copyURIs,
          copyURIsAllBut
        } = {}
      } = args;
      const { state, dispatch, context, seal } = args;
      const { channel: { publish, commit, extend }, credentials } = context;
      const { deviceId: origin } = credentials;

      if (!nonEmptyObj(groupIds)) {
        return { noop: true };
      }

      const ops = [];
      const blk = uid();
      const att = "pending";
      const shares = [];

      let prev, share;

      for (const [from, group] of entries(groupIds)) {
        const { mem = [], res = [] } = state.grp[from] || {};

        // TODO what is creator and why is it never used?
        //const [creator] = mem;

        shares.push((share = { next: group, from, mem: [origin], res: [] }));

        const entry = await seal({
          type: "CREATE_GROUP",
          group,
          origin,
          att
          //blk
        });

        ops.push([[`grp/${group}`], ({ hash: prev } = entry), blk]);

        if (copyMembers || copyURIs || copyURIsAllBut) {
          for (const member of mem) {
            if (copyURIs && !copyURIs.includes(member)) {
              continue;
            }
            if (copyURIsAllBut && copyURIsAllBut.includes(member)) {
              continue;
            }
            if (member == origin) {
              continue;
            }
            share.mem.push(member);
            const entry = await seal({
              type: "ADD_MEMBER",
              group,
              member,
              origin,
              prev,
              //blk,
              att
            });
            ops.push([[`grp/${group}`], ({ hash: prev } = entry), blk]);
          }
        }

        if (copyFiles || copyMessages) {
          for (const [keyId, checksum, salt, symKey] of res) {
            const entry = await seal({
              type: "SHARE_RES",
              group,
              keyId,
              checksum,
              salt,
              origin,
              prev,
              //blk,
              att
            });
            ops.push([[`grp/${group}`], ({ hash: prev } = entry), blk]);
            share.res.push(keyId);
          }
        }
      }

      yield { flag: blk };

      await all(...ops.map(args => publish(...args)));

      const query = shares
        .filter(({ mem, res }) => mem.length && res.length)
        .map(({ from, next, mem, res }) => [
          mem.map(mem => [`ks/${mem}/${from}`, `ks/${mem}/${next}`]),
          "keyId",
          res
        ]);

      if (query.length) {
        await extend(query, blk);
      }

      const trip = dispatch(take(({ payload = {} }) => payload.hash == prev));
      //await publish([], { cmt: blk });
      await commit(blk);
      /*const { payload: { att } } =*/ await trip;
      return { ok: true };
    }),
    // TODO bug! if make is not true, uris must be an empty array (will otherwise lead to bad state)
    change: decorate(async function*(args) {
      const {
        selectors: {
          selectScope,
          selectKeys,
          selectGroup,
          selectMembers,
          selectResources,
          selectRelation,
          selectHash
        },
        auxiliary: { cachePublicKeys, lockOneKeyForManyIds },
        meta: { take }
      } = module;
      const { from, to, keys, uris, make, moveU, moveR } = args;
      const { state, dispatch, getState, context, seal, memo } = args;
      const { channel: { publish, commit }, credentials } = context;
      const { deviceId: origin } = credentials;

      //const pos = selectGroup(state)(from).sequence;

      const unknown = uris ? uris.filter(uri => !(uri in state.rel)) : [];
      const topics = (await all(...unknown.map(hashFunc))).map(s => "pr/" + s);
      await dispatch(subscribe(...topics, `ks/${origin}/${from}`));
      // TODO optimize subscribe only if symKeys are needed
      const symKeys = selectKeys(selectScope(getState()))(from);
      const ids = uris && uris.flatMap(selectRelation(selectScope(getState())));

      const citizens = selectMembers(state)(from);
      const foreigners = selectMembers(state)(to);
      const emigrants = citizens.filter(x => !ids || ids.includes(x));
      const migrants = emigrants.filter(x => !foreigners.includes(x));
      const integrateds = [...foreigners, ...migrants];

      const ok = selectResources(state)(to);
      const of = selectResources(state)(from);
      const rm = of.filter(([key]) => !keys || keys.includes(key));
      const add = rm.filter(([a, b]) => !ok.some(([c, d]) => a == c && b == d));

      // TODO document why make bool is not part of any checks such as below

      if (
        !add.length &&
        !migrants.length &&
        (!moveR || !rm.length) &&
        (!moveU || !emigrants.length)
      ) {
        return { noop: true };
      }

      let prev = selectHash(state)(to);

      if (!prev && !make) {
        return { nogo: true };
      }

      const ops = [];
      const task = uid();
      const then = uid();
      const att = "pending";

      if (make) {
        const entry = await seal({
          type: "CREATE_GROUP",
          group: to,
          origin,
          //blk: task,
          att
        });
        ops.push([[`grp/${to}`], ({ hash: prev } = entry), task]);
      }

      // TODO need to share keys of target group to the users being added!
      for (const member of migrants) {
        if (make && member == origin) {
          continue;
        }
        const entry = await seal({
          type: "ADD_MEMBER",
          group: to,
          member,
          origin,
          prev,
          //blk: task,
          att
        });
        ops.push([[`grp/${to}`], ({ hash: prev } = entry), task]);
      }

      for (const [keyId, checksum, salt, symKey] of add) {
        const entry = await seal({
          type: "SHARE_RES",
          group: to,
          keyId,
          checksum,
          salt,
          origin,
          prev,
          //blk: task,
          att
        });
        ops.push([[`grp/${to}`], ({ hash: prev } = entry), task]);
      }

      if (moveU) {
        for (const member of emigrants) {
          ops.push([
            [`grp/${groupId}`],
            await seal({
              type: "REMOVE_MEMBER",
              group: from,
              member,
              origin,
              att: "pending"
              //pos
              //blk: then
            }),
            then
          ]);
        }
      }
      if (moveR) {
        for (const [keyId, checksum, salt, symKey] of rm) {
          ops.push([
            [`grp/${from}`],
            await seal({
              type: "REMOVE_RES",
              group: from,
              keyId,
              checksum,
              salt,
              origin,
              att: "pending"
              //pos
              //blk: then
            }),
            then
          ]);
        }
      }

      for (let [keyId, checksum, salt, symKey] of add) {
        if (!symKey) {
          symKey = symKeys[keyId];
          if (!symKey) {
            return { nogo: true };
          }
        }
        const pubKeyByUid = {};
        for (const member of integrateds) {
          [pubKeyByUid[member]] = await dispatch(cachePublicKeys(member));
        }
        const secKeysToShare = await dispatch(
          lockOneKeyForManyIds(keyId, symKey, pubKeyByUid)
        );
        for (const [target, secKey] of entries(secKeysToShare)) {
          const key = `sk/${await hashFunc(target + keyId)}`;
          const topics = [`ks/${target}`, `ks/${target}/${to}`];
          ops.push([
            topics,
            await memo(keyId + target, () =>
              seal({
                type: "KEY_SHARE",
                group: to,
                keyId,
                secKey,
                origin,
                key
              })
            ),
            then
          ]);
        }
      }

      ////yield { flag: add.length || migrants.length ? prev : then };

      //if (!ops.length) {
      //  return { noop: true };
      //}
      //
      //yield { flag: ops[0][2] };

      yield { flag: add.length || migrants.length ? task : then };

      await all(...ops.map(args => publish(...args)));

      if (!add.length && !migrants.length) {
        //await publish([], { cmt: then });
        await commit(then);
      } else {
        const trip = dispatch(take(({ payload = {} }) => payload.hash == prev));

        //publish([], { cmt: then, cnd: createCondition(prev) });
        //await publish([], { cmt: task });
        await commit(task, then);

        const { payload: { att } } = await trip;

        if (att == "rejected") {
          return { rejected: true };
        }
      }

      return { ok: true };
    })
  };
};
