export default {
  FILE_CREATE: undefined,
  FILE_DELETE: undefined,
  FILE_DELETE_OWN: undefined,
  FILE_DOWNLOAD: undefined,
  FILE_PREVIEW: undefined,
  FILE_MOVE: undefined,
  FILE_MOVE_OWN: undefined,
  FILE_UPDATE: undefined,
  FILE_UPDATE_OWN: undefined,
  FILE_READ: undefined,
  FILE_READ_OWN: undefined,
  ATTESTATION_REQUEST: undefined,
  ATTESTATION_APPROVE: undefined,
  ATTESTATION_REJECT: undefined,
  ESIGNATURE_REQUEST: undefined,
  ESIGNATURE_APPROVE: undefined,
  ESIGNATURE_REJECT: undefined,
  FOLDER_CREATE: undefined,
  FOLDER_DELETE: undefined,
  FOLDER_DELETE_OWN: undefined,
  FOLDER_MOVE: undefined,
  FOLDER_MOVE_OWN: undefined,
  FOLDER_RESTRICT: undefined,
  FOLDER_UNRESTRICT: undefined,
  GROUP_TEAMMATE_ADD: undefined,
  GROUP_TEAMMATE_REMOVE: undefined,
  TEAM_MESSAGE_READ: undefined,
  TEAM_MESSAGE_CREATE: undefined,
  TEAM_MESSAGE_DELETE: undefined,
  TEAM_MESSAGE_EDIT: undefined,
  TEAM_CREATE: undefined,
  TEAM_EXPIRE: undefined,
  TEAM_RENAME: undefined,
  TEAM_TEAMMATE_ADD: undefined,
  TEAM_TEAMMATE_ADD_OTHER: undefined,
  TEAM_TEAMMATE_LEAVE: undefined,
  TEAM_TEAMMATE_REMOVE: undefined,
  TEAM_RESTRICT: undefined,
  TEAM_UNRESTRICT: undefined,
  WORKSPACE_TEAMMATE_REMOVE: undefined,
  WORKSPACE_TEAMMATE_INVITE: undefined,
  WORKSPACE_ROLE_ASSIGN: undefined,
  WORKSPACE_ROLE_ADD: undefined,
  WORKSPACE_DELETE_LOGS: undefined,
  WORKSPACE_EDIT_LOGS: undefined,
  WORKSPACE_EXPORT_LOGS: undefined,
  WORKSPACE_EXPORT_REPORTS: undefined,
  WORKSPACE_LIST_TEAMS: undefined,
  WORKSPACE_UPLOAD_LOGO: undefined,
  WORKSPACE_GET_CONFIG: undefined,
  WORKSPACE_SET_CONFIG: undefined,
  WORKSPACE_GET_BULLHORN_ZONE: undefined,
  WORKSPACE_SET_BULLHORN_ZONE: undefined,
  LOG_ATTESTATION_REQUEST: "Attestation requested",
  LOG_ATTESTATION_APPROVE: "Attestation approved",
  LOG_ATTESTATION_REJECT: "Attestation rejected",
  LOG_ESIGNATURE_REQUEST: "Esignature requested",
  LOG_ESIGNATURE_APPROVE: "Esignature approved",
  LOG_ESIGNATURE_REJECT: "Esignature rejected",
  LOG_FILE_CREATE: "File uploaded",
  LOG_FILE_DELETE: "File deleted",
  LOG_FILE_DOWNLOAD: "File downloaded",
  LOG_FILE_MOVE: "File moved",
  LOG_FILE_UPDATE: "File updated",
  LOG_FILE_PREVIEW: "File previewed",
  LOG_FILE_READ: "File read",
  LOG_FOLDER_CREATE: "Folder created",
  LOG_FOLDER_DELETE: "Folder deleted",
  LOG_FOLDER_MOVE: "Folder moved",
  LOG_FOLDER_RESTRICT: "Restricted folder",
  LOG_FOLDER_UNRESTRICT: "Unrestricted folder",
  LOG_MESSAGE_CREATE: "New message",
  LOG_MESSAGE_DELETE: undefined,
  LOG_MESSAGE_EDIT: undefined,
  LOG_MESSAGE_READ: undefined,
  LOG_TEAM_CREATE: "Team created",
  LOG_TEAM_EXPIRE: "Team expiration",
  LOG_TEAM_DID_EXPIRE: "Team expired",
  LOG_TEAM_RENAME: "Team renamed",
  LOG_TEAM_TEAMMATE_ADD: "Teammate added",
  LOG_TEAM_TEAMMATE_REMOVE: "Teammate removed",
  LOG_TEAM_RESTRICT: "Team restricted",
  LOG_TEAM_UNRESTRICT: "Team unrestricted",
  LOG_GROUP_TEAMMATE_ADD: undefined,
  LOG_GROUP_TEAMMATE_REMOVE: "Teammate restricted",
  LOG_WORKSPACE_DELETE_LOGS: undefined,
  LOG_WORKSPACE_EDIT_LOGS: undefined,
  LOG_WORKSPACE_EXPORT_LOGS: undefined,
  LOG_WORKSPACE_EXPORT_REPORTS: undefined,
  LOG_WORKSPACE_LIST_TEAMS: undefined,
  LOG_WORKSPACE_ROLE_ADD: undefined,
  LOG_WORKSPACE_ROLE_ASSIGN: "Role assigned",
  LOG_WORKSPACE_TEAMMATE_INVITE: "Teammate invited",
  LOG_WORKSPACE_UPLOAD_LOGO: "Logo uploaded",
  LOG_WORKSPACE_TEAMMATE_REMOVE: "User deleted",
  LOG_WORKSPACE_GET_CONFIG: undefined,
  LOG_WORKSPACE_SET_CONFIG: undefined,
  LOG_WORKSPACE_GET_BULLHORN_ZONE: undefined,
  LOG_WORKSPACE_SET_BULLHORN_ZONE: undefined,
};
