import { Checkbox, Label } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { selectStatus, setStatus } from "../bullhornSlice.js";
import styles from "../BhDialogs.module.css";

export default function StatusConfig({ dispatch }) {
  const status = useSelector(selectStatus);

  const handleChecked = (name, clickedOption) => {
    let newFilter;
    if (status[name].filter.includes(clickedOption)) {
      newFilter = status[name].filter.filter(
        (option) => option !== clickedOption
      );
    } else {
      newFilter = [...status[name].filter, clickedOption];
    }
    dispatch(setStatus({ name, filter: newFilter }));
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <>
      <Label className={styles.dialogHeading}>Status</Label>
      {Object.entries(status).map(([name, { all, filter, status }]) => (
        <div className={styles.dialogSubList} key={name}>
          <Label className={styles.dialogSubHeading}>
            {capitalizeFirstLetter(name)}
          </Label>
          {status.all === "idle" && status.filter === "idle" ? (
            all.map((option) => (
              <Checkbox
                key={option}
                inline={true}
                checked={!filter.includes(option)}
                label={option}
                onClick={(e) => handleChecked(name, option)}
              />
            ))
          ) : (
            <p>loading...</p>
          )}
          <br />
        </div>
      ))}
    </>
  );
}
