import {
  // Classes
  KDSClient,
  PublicKeyCache,

  // Functions
  resolveAddress,

  // Configuration
  KDS_HOST,
  KDS_PORT,
  KDS_API_KEY,
  KDS_ROOT_CERTIFICATE,

  // Constants
  CIPHER_TEXT,
  VERSION,
  VERSION_VALUE,
} from "./";

import { CBOR, Data, Notifier } from "../";

import { assertString, assertInstanceOf, isInstanceOf } from "../util";

import * as crypto from "../crypto";

//import { Outbox } from "../outbox"

//import { importPublicNegotiationKey, importVerificationKey } from "../crypto"

/*******************/

function initKDSClient(config) {
  return new KDSClient(
    config[KDS_HOST],
    config[KDS_PORT],
    config[KDS_API_KEY],
    crypto.Certificate.importPEM(config[KDS_ROOT_CERTIFICATE])
  );
}

export default class Cryptobox {
  static async register(uid, password, config) {
    return initKDSClient(config).register(await resolveAddress(uid), password);
  }

  static async start(uid, credentials, config) {
    return new Cryptobox(await resolveAddress(uid), credentials, config);
  }

  constructor(address, credentials, config) {
    this.address = address;
    this.kdsClient = initKDSClient(config);
    this.credentials = credentials;
    //this.outbox = new Outbox(this.address, config[MSG_HOST], config[MSG_PORT], config[MSG_API_KEY]);
    this.cache = new PublicKeyCache(this.kdsClient);
    this.notifier = new Notifier();

    //this.outbox.attachInbox(this.decryptbox.inbox);
  }

  attachInbox(inbox, filter) {
    this.notifier.addInbox(inbox, filter);
  }

  removeInbox(inbox, filter) {
    this.notifier.removeInbox(inbox, filter);
  }

  start() {
    //this.outbox.restore(this.cache.ticket);
    //this.outbox.remember(this.cache.updateTicket);
    //this.outbox.start();
  }

  close() {
    //this.outbox.close();
  }

  async negotiate(uid) {
    assertString(uid, "uid");

    const address = await resolveAddress(uid);

    // Make a lookup if public ECDH key is not in cache
    if (!this.cache.has(address)) {
      if (!(await _loadFromPublicKeyLookupPreCache_(this.cache, address))) {
        this.cache.fetch(address).catch((e) => null);
        // or await this.cache.fetch(address)
      }
    }

    const publicECDHKey = await this.cache.publicECDHKeys.get(address);

    return crypto.agree(publicECDHKey, this.credentials.privateECDHKey);
  }

  async decrypt(from, data) {
    assertString(from, "from");
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      data,
      "data"
    );

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], data))
      data = new Data(data);

    const message = CBOR.decode(data);

    // Decrypt the ciphertext
    return crypto.decrypt(
      message[CIPHER_TEXT],
      await this.negotiate(from),
      Data.fromUTF8(message[VERSION])
    );
  }

  async encrypt(to, data) {
    assertString(to, "to");
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      data,
      "data"
    );

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], data))
      data = new Data(data);

    return CBOR.encode({
      [CIPHER_TEXT]: await crypto.encrypt(
        data,
        await this.negotiate(to),
        Data.fromUTF8(VERSION_VALUE)
      ),
      [VERSION]: VERSION_VALUE,
    });
  }

  async put(to, data, options) {
    //const outbox = this.outbox;
    //this.encrypt(to, data).then(message => outbox.put(await resolveAddress(to), message, options));
  }

  async sign(data) {
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      data,
      "data"
    );

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], data))
      data = new Data(data);

    return this.credentials.privateECDSAKey.sign(data);
  }

  async verify(data, signature, uid) {
    assertString(uid, "uid");
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      data,
      "data"
    );
    assertInstanceOf(
      [Data, Uint8Array, ArrayBuffer, Array, Buffer],
      signature,
      "signature"
    );

    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], data))
      data = new Data(data);
    if (isInstanceOf([Uint8Array, ArrayBuffer, Array], signature))
      signature = new Data(signature);

    const address = await resolveAddress(uid);

    // Make a lookup if key is not in cache
    if (!this.cache.has(address)) {
      if (!(await _loadFromPublicKeyLookupPreCache_(this.cache, address))) {
        this.cache.fetch(address).catch((e) => null);
        // or await this.cache.fetch(address)
      }
    }

    const publicECDSAKey = await this.cache.publicECDSAKeys.get(address);

    return publicECDSAKey.verify(data, signature);
  }
}

// Testability

const _loadFromPublicKeyLookupPreCache_ = async (cache, address) => {
  const scope =
    (typeof window !== "undefined" && window) ||
    (typeof global !== "undefined" && global) ||
    {};
  if (
    scope._publicKeyLookupPreCache_ &&
    address in scope._publicKeyLookupPreCache_
  ) {
    let { negotiation: n, verification: v } =
      scope._publicKeyLookupPreCache_[address];
    const publicECDHKey = await crypto.importPublicECDHKey(
      decodeHex(n),
      "spki"
    );
    const publicECDSAKey = await crypto.importPublicECDSAKey(
      decodeHex(v),
      "spki"
    );
    cache.publicECDHKeys.get(address).resolve(publicECDHKey);
    cache.publicECDSAKeys.get(address).resolve(publicECDSAKey);
    return true;
  }
};
